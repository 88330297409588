import React from "react";
import { BangBangTangLayout } from "../../layout/layout";
import style from "./index.module.scss";
import { Tabs, Badge, Cell, Pull, ActivityIndicator } from "zarm";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";
import { finalize } from "rxjs/operators";
const { Panel } = Tabs;

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
};

export class NegativeComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      load_state: LOAD_STATE.normal,
      ListCombination: [
        {
          title: "等待处理",
          count: 0,
        },
        {
          title: "处理中",
          count: 0,
        },
        {
          title: "处理完成",
          count: 0,
        },
        {
          title: "审核完成",
          count: 0,
        },
      ],
      list: [],
      currentPage: 1,
    };
  }

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
  };

  titleShow = (title, count) => {
    return (
      <div className={style.comments_tab_title}>
        <span className="tab-title">{title}</span>
        {count !== 0 && <Badge shape="round" text={count} />}
      </div>
    );
  };
  componentDidMount() {
    window.document.title = "客诉列表";
    store.globalUiStore.changeCurrentActive("person-center");
    store.globalUiStore.startMaskLoading();
    const {
      match: {
        params: { progress },
      },
    } = this.props;
    this.setState(
      {
        tabValue: Number(progress),
      },
      () => {
        this.getCommentList(this.state.tabValue);
      }
    );

  }

  getCommentList = (progress, page = 1) => {
    this.setState({
      load_state: LOAD_STATE.loading,
    });
    let that = this;
    BangBangTangRequest({
      url: "/wap/data/getCustomerComplaints",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        page: page,
        progress: progress,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        let ListClone = that.state.list;
        let ListCombinationClone = that.state.ListCombination;
        ListCombinationClone[0].count = result.num.Unprocessed;
        ListCombinationClone[1].count = result.num.Inprocess;
        ListCombinationClone[2].count = result.num.ProcessingCompleted;
        if (page == 1) {
          ListClone = result.list;
        } else {
          ListClone = ListClone.concat(result.list);
        }
        console.log('列表', ListClone.length, ListClone)
        that.setState({
          ListCombination: ListCombinationClone,
          list: ListClone,
          currentPage: page,
          load_state:
            result.list.length < 10 ? LOAD_STATE.complete : LOAD_STATE.normal,
        });
      });
  };

  tabValueChange = (value) => {
    this.setState({
      tabValue: value,
    }, () => {
      this.getCommentList(value, 1);
    });
  };

  render() {
    const { tabValue, ListCombination, list, currentPage, load_state } = this.state;
    console.log('load_state', load_state)
    return (
      <BangBangTangLayout title={"客诉列表"}>
        <Tabs
          className={style.comment_tabs}
          value={tabValue}
          onChange={this.tabValueChange}
          lineWidth={74}
        >
          {ListCombination.map((item, index) => {
            return (
              <Panel title={this.titleShow(item.title, item.count)} key={index}>

              </Panel>
            );
          })}
        </Tabs>

        <div className={style.comment_list}>
          <Pull
            className="comment-list-container"
            refresh={{ state: 0 }}
            load={{
              state: load_state,
              handler: () => {
                // console.log('触发加载', tabValue, currentPage + 1);
                this.getCommentList(tabValue, currentPage + 1);
              },
              distance: 200,
              render: (loadState) => {
                const cls = "custom-control";
                switch (loadState) {
                  case LOAD_STATE.loading:
                    return (
                      <div className={cls}>
                        <ActivityIndicator type="spinner" />
                      </div>
                    );
                  case LOAD_STATE.failure:
                    return <div className={cls}>加载失败</div>;
                  case LOAD_STATE.complete:
                    return <div className={cls}>没有更多数据了</div>;
                }
              },
            }}
          >
            {list.map((comment,index) => {
              return (
                <Cell
                  key={index}
                  title={
                    <div className={style.comment_item}>
                      <div className="comment-title">
                        {comment.type}
                      </div>
                      <div className="comment-description">
                        {comment.name} {comment.mobile}
                      </div>
                      <div className="comment-description">
                        {comment.feedback_describe}
                      </div>
                      <div className="comment-description">
                        {comment.submit_time}
                      </div>
                    </div>
                  }
                  icon={
                    <img
                      alt=""
                      src={comment.avatar}
                      style={{ width: 48, height: 48 }}
                    />
                  }
                  onClick={() => {
                    this.props.history.push(
                      "/build/negative-comments-detail/" +
                      comment.feedback_id
                    );
                  }}
                />
              );
            })}
          </Pull>
        </div>
      </BangBangTangLayout>
    );
  }
}
export default withRouter(NegativeComments);
