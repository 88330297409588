import React from "react";
import { store } from "../../../../store/store.root";
import { Button, Calendar, Popup } from "zarm";
import { preorderScheduleActions } from "../../../../store/preorder-schedule/preorder-schedule.action";
import { observer } from "mobx-react";
import { add, setDate, setDay } from "date-fns";

@observer
export class EmployeePreorderListCalendar extends React.Component<
  any,
  {
    selected: null | Date;
    min: Date;
    max: Date;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      selected: null,
      max: this.getMonthByDate(new Date()).max,
      min: this.getMonthByDate(new Date()).min,
    };
  }
  getMonthByDate(value: Date): { max: Date; min: Date } {
    const firstDay = setDate(value, 1);
    const nextMonth = add(firstDay, { months: 1 });
    const lastDay = add(nextMonth, { days: -1 });
    return { max: lastDay, min: firstDay };
  }
  componentDidMount() {}

  changeMonth(value: "prev" | "next") {
    const next = add(this.state.min, { months: value === "prev" ? -1 : 1 });
    this.setState({
      min: next,
      max: this.getMonthByDate(next).max,
    });
  }
  render() {
    return store.employeePreorderListStore.displayCalendar ? (
      <Popup
        visible={true}
        className="calendar-month"
        onMaskClick={() =>
          store.employeePreorderListStore.toggleDisplayCalendar()
        }
      >
        <Calendar
          defaultValue={store.employeePreorderListStore.date}
          onChange={(value) => {
            store.employeePreorderListStore.changeDate(value![0]);
            store.employeePreorderListStore.toggleDisplayCalendar();
          }}
          multiple={false}
          min={this.state.min}
          max={this.state.max}
        />
        <div className="month-button-row">
          <Button
            className="month-button"
            onClick={() => {
              this.changeMonth("prev");
              this.setState({ selected: null });
            }}
          >
            上个月
          </Button>
          <Button
            className="month-button"
            onClick={() => {
              this.changeMonth("next");
              this.setState({ selected: null });
            }}
          >
            下个月
          </Button>
        </div>
      </Popup>
    ) : null;
  }
}
