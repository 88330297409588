import { action, computed, makeAutoObservable, runInAction, toJS } from "mobx";
import { BangBangTangRequest } from "../utils/request";
import { store } from "./store.root";
import { PHONE_RE } from "../utils";
import { finalize } from "rxjs/operators";
import { toast } from "react-toastify";

export type ValidCloseRelationNumber = "1" | "2" | "3" | "4" | "5" | "6";
export type ValidCloseRelationship =
  | "father"
  | "mother"
  | "mother_s_mother"
  | "mother_s_father"
  | "father_s_mother"
  | "father_s_father";
const closeRelationNumberToNameMap = {
  1: "father",
  2: "mother",
  3: "father_s_father",
  4: "father_s_mother",
  5: "mother_s_father",
  6: "mother_s_mother",
};
const closeRelationNameToNumberMap = {
  father: "1",
  mother: "2",
  father_s_father: "3",
  father_s_mother: "4",
  mother_s_father: "5",
  mother_s_mother: "6",
};

export class CloseRelationshipNumberStore {
  loading: boolean = false;
  updateTime: null | Date = null;
  father: string | null = null;
  mother: string | null = null;
  mother_s_mother: string | null = null;
  mother_s_father: string | null = null;
  father_s_father: string | null = null;
  father_s_mother: string | null = null;
  getValue(name: ValidCloseRelationship | "" | null): string | null {
    switch (name) {
      case "father":
        return this.father;
      case "mother":
        return this.mother;
      case "father_s_father":
        return this.father_s_father;
      case "father_s_mother":
        return this.father_s_mother;
      case "mother_s_father":
        return this.mother_s_father;
      case "mother_s_mother":
        return this.mother_s_mother;
      default:
        return null;
    }
  }

  @computed get validValues(): Array<{
    name: ValidCloseRelationship;
    value: string;
  }> {
    const _list: Array<{ name: ValidCloseRelationship; value: string }> = [];
    if (this.father) _list.push({ name: "father", value: this.father });

    if (this.mother) _list.push({ name: "mother", value: this.mother });

    if (this.father_s_father) {
      _list.push({ name: "father_s_father", value: this.father_s_father });
    }

    if (this.father_s_mother) {
      _list.push({ name: "father_s_mother", value: this.father_s_mother });
    }

    if (this.mother_s_father) {
      _list.push({ name: "mother_s_father", value: this.mother_s_father });
    }

    if (this.mother_s_mother) {
      _list.push({ name: "mother_s_mother", value: this.mother_s_mother });
    }

    return _list;
  }

  setNumber(name: ValidCloseRelationship, value: string) {
    switch (name) {
      case "father":
        this.father = value;
        break;
      case "father_s_father":
        this.father_s_father = value;
        break;
      case "father_s_mother":
        this.father_s_mother = value;
        break;
      case "mother":
        this.mother = value;
        break;
      case "mother_s_father":
        this.mother_s_father = value;
        break;
      case "mother_s_mother":
        this.mother_s_mother = value;
        break;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }

  clear(name: ValidCloseRelationship) {
    this.startLoading();
    return new Promise((resolve) =>
      BangBangTangRequest({
        url: "/wap/data/delFamilyData",
        method: "POST",
        data: {
          token: store.userStore.token,
          role_id: closeRelationNameToNumberMap[name],
        },
      })
        .pipe(
          finalize(() => {
            this.hideLoading();
            resolve(null);
          })
        )
        .subscribe(() => this.setNumber(name, ""))
    );
  }

  startLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  update(name: ValidCloseRelationship, value: string) {
    return new Promise((resolve) => {
      this.startLoading();
      if (value.match(PHONE_RE)) {
        return BangBangTangRequest({
          url: "/wap/data/addFamilyData",
          method: "POST",
          data: {
            token: store.userStore.token,
            role_id: closeRelationNameToNumberMap[name],
            mobile: value,
          },
        })
          .pipe(
            finalize(() => {
              this.hideLoading();
              resolve(null);
            })
          )
          .subscribe(() => this.setNumber(name, value));
      }

      toast("不是一个有效的电话号码");
    });
  }

  list() {
    console.log(toJS(this.updateTime));
    if (this.updateTime !== null) {
      return;
    }
    this.startLoading();
    return BangBangTangRequest({
      url: "/wap/data/getFamilyData",
      method: "POST",
      data: { token: store.userStore.token },
    })
      .pipe(finalize(() => this.hideLoading()))
      .subscribe((data) => {
        const keys = Object.keys(data ? data : {});
        runInAction(() => {
          this.updateTime = new Date();
        });
        keys.forEach((key) => {
          if (key !== "myself") {
            this.setNumber(key as ValidCloseRelationship, data[key]);
          }
        });
      });
  }
}
