import { makeAutoObservable } from "mobx";

export class EmployeeQueryPreorderClotheSearchStore {
  phone: string = "";
  type: string = "";
  picker_visible: boolean = false;
  data: any[] = [];
  setPhone(value: string) {
    this.phone = value;
  }
  setType(value: string) {
    this.type = value;
  }
  setData(value: any[]) {
    this.data = value;
  }
  setPickerVisible(value: boolean) {
    this.picker_visible = value;
  }
  constructor() {
    makeAutoObservable(this);
  }
}
