import React from 'react'
import { Carousel } from 'zarm'
import './index-carousel.css'
import { observer } from 'mobx-react'
import { store } from '../../../../store/store.root'

@observer
export class IndexCarousel extends React.Component {
  contentRender = (): any =>
    store.sliderShowStore.data.map((item, i) => (
      <div className="carousel__item__pic" key={item.pic}>
        <img
          key={item.id}
          src={item.pic}
          alt=""
          draggable={true}
          // onClick={() => (item.link ? window.open(item.link) : null)}
        />
      </div>
    ))

  render() {
    return (
      <Carousel
        className={'carousel'}
        autoPlayIntervalTime={3000}
        loop={true}
        autoPlay={true}
      >
        {this.contentRender()}
      </Carousel>
    )
  }
}
