import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { Form, Field, Button, Uploader } from 'react-vant'
import { store } from '../../store/store.root'
import { cosUploadFile } from '../../utils/upload'
import { ActivityIndicator, Loading, Toast } from 'zarm'
import { FetchRequest } from '../../utils/fetch'

export default () => {
  useEffect(() => {
    document.title = '编辑个人信息'
  }, [])

  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    const data = {
      ...values,
    }
    if (values.avatar.length === 0) {
      Toast.show('请先上传头像')
      return
    }
    if (typeof values.avatar[0].url === 'string') {
      // 已经上传过
      data['avatar'] = values.avatar[0].url
    } else {
      const r = await cosUploadFile(
        values.avatar[0].file,
        `/uploads/1/img/user/avatar/${store.userStore.id}`
      )
      data['avatar'] = r
    }
    Loading.show({
      content: <ActivityIndicator size="lg" />,
    })
    let res = await FetchRequest('wap/wap2/changeUserInfo', {
      ...data,
      token: localStorage.getItem('token'),
    })
    Loading.hide()
    if (res.code === 1) {
      store.routerStore.replace('/build/my-info')
      setTimeout(() => {
        location.reload()
      }, 500)
    } else {
      Toast.show(res.msg)
    }
  }

  return (
    <div style={{ padding: 10 }}>
      <h3>用户信息编辑</h3>
      <Form
        onFinish={onFinish}
        form={form}
        footer={
          <div style={{ margin: '16px 16px 0' }}>
            <Button round nativeType="submit" color="#00bc71" block>
              提交
            </Button>
          </div>
        }
      >
        <Form.Item
          name="avatar"
          label="用户头像"
          initialValue={[
            {
              url: store.userStore.avatar + '#.jpg',
              status: 'done',
              name: '图片名称',
            },
          ]}
        >
          <Uploader maxCount={1} />
        </Form.Item>
        <Form.Item
          name="openname"
          label="用户昵称"
          initialValue={store.userStore.username}
        >
          <Field />
        </Form.Item>
      </Form>
    </div>
  )
}
