import React, { useEffect, useState } from "react"
import { Button } from "zarm"
import { store } from "../store/store.root"
import { handleOnChangShare } from "../utils/index"

const style= {
    position: 'fixed',
    width: '70%',
    left: 0,
    right: 0,
    margin: '0 auto',
    bottom: '13%',
}

// const style_AppletGift= {
//     position: "fixed",
//     bottom: "16%",
//     right: "3%",
//     width: "45px",
//     height: "45px",
//     fontSize: "13px",
//     color: "#fff",
//     borderRadius: "50%",
//     backgroundColor: "#00B26A",
//     textAlign: "center",
//     padding: "9px"
// }

// const style_AppletGiftWeapp= {
//     position: "fixed",
//     bottom: "16%",
//     right: "3%",
//     width: "45px",
//     height: "45px",
//     fontSize: "13px",
//     color: "#fff",
//     borderRadius: "50%",
//     backgroundColor: "#00B26A",
//     textAlign: "center",
//     padding: "9px",
//     opacity: 0,
// }

export function Applet() {
    const [data, setdata] = useState({})
    useEffect(async () => {
        const data_res = await handleOnChangShare();
        setdata(data_res)
    }, [])
    if (!data.gh_appid) {
        return (
            <div style={{ fontSize: 16, textAlign: 'center', marginTop: 20, color: '#ececec' }}>疯狂加载中...</div>
        )
    }
    return (
        <div>
            <div>
                <Button style={style} theme="primary">获取分享链接</Button>
            </div>
            <wx-open-launch-weapp
                id="launch-btn"
                username={localStorage.getItem("gh_appid") || data.gh_appid}
                path="pagesA/transfer/transfer?type=shar"
                style={{  position: 'fixed', width: '70%', left: 0, right: 0, margin: '0px auto', bottom: '13%', height: 44 }}
            >
                <script type="text/wxtag-template">
                    <div style={{ position: 'fixed', width: '70%', left: 0, right: 0, margin: '0px auto', bottom: '13%', height: 44, opacity: 0 }} />
                </script>
            </wx-open-launch-weapp>
        </div>
    )
}

// export function AppletGift(props) {
//     const [data, setdata] = useState({})
//     useEffect(async () => {
//         const data_res = await handleOnChangShare(props.shar);
//         setdata(data_res)
//     }, [])
//     if (!data.gh_appid) {
//         return (
//             <div style={{ fontSize: 16, textAlign: 'center', marginTop: 20, color: '#ececec' }}>疯狂加载中...</div>
//         )
//     }
//     return (
//         <div>
//             <div>
//                 <div style={style_AppletGift}>分享<br/>链接</div>
//             </div>
//             <wx-open-launch-weapp
//                 id="launch-btn"
//                 username={localStorage.getItem("gh_appid") || data.gh_appid}
//                 path="pagesA/transfer/transfer?type=shar"
//                 style={style_AppletGiftWeapp}
//             >
//                 <script type="text/wxtag-template">
//                     <div style={style_AppletGiftWeapp} />
//                 </script>
//             </wx-open-launch-weapp>
//         </div>
//     )
// }