import { BangBangTangRequest } from '../../utils/request'
import { store } from '../store.root'
import { EmployeePreorderClotheResponse } from './customer-preorder-clothe.api'
import { from, Observable, of } from 'rxjs'
import cache from './result.json'

export const CustomerGetPreorderClothe = (
  store_id: string | null,
  selection_id: number
): Observable<EmployeePreorderClotheResponse> => {
  // return of(cache) as any;
  return BangBangTangRequest<EmployeePreorderClotheResponse>({
    url: '/wap/clothing/getStandardListNew',
    data: { token: store.userStore.token, store_id, selection_id },
    method: 'POST',
  })
}
