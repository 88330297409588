import React, { useState, useEffect, Fragment } from "react";
import { store } from "../../store/store.root";
import { Button } from "antd-mobile";
import { Icon } from "antd-mobile";
interface questionListItemObjType {
  question_id?: number;
  question?: string;
  selection?: string;
}
const ButtonList = ["A", "B", "C", "D", "E", "F", "G"];
//考试中心页面
export const Assessment = () => {
  const [questiondata, setQuestionData] = useState<questionListItemObjType>({
    question_id: 0,
    question: "",
    selection: "6",
  });
  const [select, setSelect] = useState<string[]>([]);
  const [nowPage, setNowPage] = useState<number>(0);
  const [selectStatus, setSelectStatus] = useState<boolean[]>([]);
  useEffect(() => {
    // console.log(store.Contrarytest.getTheInitialListOfQuestions({plan_id:11,store_id:1}));
    setSelectStatus(new Array(Number(questiondata.selection)).fill(false));
  }, [nowPage]);
  const handlerClick = (i: string) => {
    let _selectStatus = selectStatus;
    let _select = select;
    _select.push(i);
    _selectStatus[ButtonList.indexOf(i)] = !_selectStatus[
      ButtonList.indexOf(i)
    ];
    setSelect(_select);
    setSelectStatus(_selectStatus);
  };
  const checkIsEmpty = (obj: any): boolean => {
    return JSON.stringify(obj) !== "[]";
  };
  const next = () => {
    setNowPage(nowPage + 1);
  };
  const prev = () => {
    setNowPage(nowPage > 0 ? nowPage - 1 : 0);
  };
  if (checkIsEmpty(store.Contrarytest.SetOfProcessedAndUnprocessedIssues)) {
    return (
      <Fragment>
        <div
          dangerouslySetInnerHTML={{ __html: questiondata.question as string }}
        ></div>
        <div>
          <div onClick={prev}>上一题</div>
          <div>
            {ButtonList.slice(0, Number(questiondata.selection)).map(
              (i, key) => (
                <Button
                  type={
                    selectStatus[ButtonList.indexOf(i)] ? "primary" : undefined
                  }
                  key={key}
                  onClick={() => handlerClick(i)}
                >
                  {i}
                </Button>
              )
            )}
          </div>
          <div onClick={next}>下一题</div>
        </div>
      </Fragment>
    );
  }
  return <div>空白</div>;
};
