import React, { useState, useEffect } from "react";
import { Tabs, Badge, Toast } from "zarm";
import style from "./index.module.scss";
import Header from "../../components/Header";
import List from "./list";
import { FetchRequest } from "../../utils/fetch";
import { store } from "../../store/store.root";

const { Panel } = Tabs;

const tabs = [
  {
    id: 1,
    title: "等待处理",
  },
  {
    id: 2,
    title: "处理中",
  },
  {
    id: 3,
    title: "处理完成",
  },
  {
    id: 4,
    title: "审核完成",
  },
];

/**
 * 设置tab标题样式
 * @param title
 * @param count
 */
const titleRead = (title: string, count: number) => {
  return (
    <div className={style.titleRead}>
      <span>{title}</span>
      {count !== 0 && <Badge shape="round" text={count} />}
    </div>
  );
};

export default (props: any) => {
  // 判断当前处于那一页
  const [value, setValue] = useState(0);
  // 存放总条数
  const [counts, setCount] = useState({
    Inprocess: 0,
    ProcessingCompleted: 0,
  });

  useEffect(() => {
    getCustomerComplaintsList();
  }, [value]);

  async function getCustomerComplaintsList() {
    let data = await FetchRequest("/wap/data/getCustomerComplaints", {
      token: "ae1a826c-dbff-4383-b66d-882ef3a1e981",
      page: 1,
      progress: value,
      store_id: store.storeInfoStore.value,
    });
    if (data.code === 1) {
      if (data.data.length === 0) {
        Toast.show("暂无更多");
      }
    } else {
      Toast.show("请求失败");
    }
  }

  return (
    <div>
      <Header title="客诉列表" />
      <Tabs
        value={value}
        onChange={(e) => {
          setValue(e === undefined ? 0 : e);
        }}
      >
        {tabs.map((v) => {
          return (
            <Panel title={titleRead(v.title, 100)} key={v.id}>
              <List tab={v.id} />
            </Panel>
          );
        })}
      </Tabs>
    </div>
  );
};
