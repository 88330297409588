import { BangBangTangLayout } from '../../layout/layout'
import React from 'react'
import './satisfaction-research.css'
import { Button, Modal } from 'zarm'
import { BangBangTangRequest } from '../../utils/request'
import { store } from '../../store/store.root'
import { finalize } from 'rxjs/operators'
import { toast } from 'react-toastify'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'
import { getParamValue } from '../../utils/url'
import { FetchRequest } from '../../utils/fetch'

export class SatisfactionResearch extends React.Component<
  any,
  {
    current_select: 'A' | 'B' | 'C' | 'D' | null
    value: string
    text: string
    feedback_id: number | null
    complete: boolean
    visible: boolean
    relation_id: null | number
  }
> {
  constructor(props: any) {
    super(props)
    this.state = {
      current_select: null,
      value: '',
      text: '',
      feedback_id: null,
      complete: false,
      visible: false,
      relation_id: null,
    }
  }

  componentDidMount() {
    const url = localStorage.getItem('redirect_url') || ''
    const searchParams = new URLSearchParams(url.split('?')[1])
    appIsFirstReadySubject.subscribe(() => {
      store.globalUiStore.startMaskLoading()
      BangBangTangRequest({
        url: '/wap/data/getQuestionnaireSurvey',
        method: 'POST',
        data: {
          token: store.userStore.token,
          workbench_id:
            getParamValue('workbench_id') || searchParams.get('workbench_id'),
          project_id:
            getParamValue('project_id') || searchParams.get('project_id'),
        },
      })
        .pipe(finalize(() => store.globalUiStore.hideLoading()))
        .subscribe(
          (result: {
            feedback_id?: any
            word: any
            is_submit: number
            onoff: number
          }) => {
            if (result.is_submit === 1) {
              if (
                store.websiteRuleStore.has_module('marketing') &&
                result.onoff === 1
              ) {
                store.routerStore.replace(
                  `/build/collect-bag?relation_id=${getParamValue(
                    'workbench_id'
                  )}`
                )
              } else {
                store.routerStore.replace('/build')
              }
              localStorage.removeItem('redirect_url')
            } else {
              this.setState({
                complete: true,
                text: result.word,
                feedback_id: result.feedback_id || getParamValue('feedback_id'),
              })
            }
          }
        )
    })
  }

  render() {
    if (!this.state.complete) return null

    return (
      <BangBangTangLayout>
        <div className="satisfaction-research">
          <div className="page-header">满意度调查</div>
          <div className="divider" />
          <div className="paper">
            <div className="paper-line">{this.state.text}</div>
            <div
              className="paper-line radio"
              onClick={() => this.setState({ current_select: 'A' })}
            >
              <span>A:非常满意</span>
              <input
                type="radio"
                checked={this.state.current_select === 'A'}
                onChange={() => this.setState({ current_select: 'A' })}
              />
            </div>
            <div
              className="paper-line radio"
              onClick={() => this.setState({ current_select: 'B' })}
            >
              <span>B: 满意</span>
              <input
                type="radio"
                checked={this.state.current_select === 'B'}
                onChange={() => this.setState({ current_select: 'B' })}
              />
            </div>
            <div
              className="paper-line radio"
              onClick={() => this.setState({ current_select: 'C' })}
            >
              <span>C: 一般</span>
              <input
                checked={this.state.current_select === 'C'}
                type="radio"
                onChange={() => this.setState({ current_select: 'C' })}
              />
            </div>
            <div
              className="paper-line radio"
              onClick={() => this.setState({ current_select: 'D' })}
            >
              <span>D: 不满意</span>
              <input
                type="radio"
                onChange={() => this.setState({ current_select: 'D' })}
                checked={this.state.current_select === 'D'}
              />
            </div>
          </div>
          <div className="suggestion">
            <div className="title">建议和意见 :</div>
            <textarea
              value={this.state.value}
              onChange={(event) => this.setState({ value: event.target.value })}
            />
          </div>
          <div className="button-row">
            <Button onClick={() => store.routerStore.replace('/build')}>
              取消
            </Button>
            <Button
              theme="primary"
              disabled={!this.state.current_select}
              onClick={this.submit}
            >
              确认
            </Button>
          </div>
        </div>
        {/* 卡券弹窗 */}
        <Modal
          visible={this.state.visible}
          footer={
            <Button
              block
              theme="primary"
              onClick={() =>
                store.routerStore.replace(
                  `/build/collect-bag?relation_id=${this.state.feedback_id}`
                )
              }
            >
              获取大礼包
            </Button>
          }
        >
          <p>感谢您的参与，恭喜您获得回访大礼包,快去选择吧</p>
        </Modal>
      </BangBangTangLayout>
    )
  }

  private readonly submit = async () => {
    let res = await FetchRequest('/wap/data/submitQuestionnaireSurvey', {
      token: store.userStore.token,
      feedback_id: this.state.feedback_id,
      submit_option: this.state.current_select,
      feedback_describe: this.state.value,
    })
    if (res.code === 1) {
      toast('提交成功')
      if (
        store.websiteRuleStore.has_module('marketing') &&
        res.data.onoff === 1
      ) {
        // 弹窗展示卡券
        this.setState({ visible: true })
      } else {
        store.routerStore.replace('/build')
      }
    } else {
      toast(res.msg)
    }

    // store.globalUiStore.startMaskLoading();
    // BangBangTangRequest({
    //   url: "/wap/data/submitQuestionnaireSurvey",
    //   data: {
    //     token: store.userStore.token,
    //     feedback_id: this.state.feedback_id,
    //     submit_option: this.state.current_select,
    //     feedback_describe: this.state.value,
    //   },
    //   method: "POST",
    // })
    //   .pipe(finalize(() => store.globalUiStore.hideLoading()))
    //   .subscribe((result: number) => {
    //     if (result === 0) {
    //       toast("提交失败");
    //     } else if (result === 1) {
    //       toast("提交成功");
    //       if (store.websiteRuleStore.has_module("marketing") && result.onoff === 1) {
    //         // 弹窗展示卡券
    //         this.setState({ visible: true })
    //       } else {
    //         store.routerStore.replace("/build");
    //       }
    //     }
    //   });
  }
}
