import { Icon } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { store } from '../../store/store.root'
import { BangBangTangRequest } from '../../utils/request'
import style from './index.module.scss'
import { Modal } from 'zarm'

function TestCenter(props: any) {
  // 存放考试信息
  const [data, setdata] = useState([])

  useEffect(() => {
    // 设置标题
    ;(document.title = '考试中心'),
      // 请求考试列表
      getTestCenterList()
    // 监听返回
    // window.addEventListener("popstate", function () {
    //     props.history.push('/build/person-center')
    // }, false);
    // return () => {
    //     window.removeEventListener("popstate", () => {
    //         props.history.push('/build/person-center')
    //     })
    // };
  }, [])

  /**
   * 获取用户考试列表
   */
  function getTestCenterList() {
    if (!localStorage.getItem('admin_store_id')) {
      window.history.back()
      return
    }
    BangBangTangRequest({
      url: '/wap/exam/getExamCenter',
      method: 'POST',
      data: {
        token: localStorage.getItem('token'),
        store_id: localStorage.getItem('admin_store_id'),
      },
    }).subscribe((result) => {
      if (result) {
        setdata(result)
      }
    })
  }

  /**
   * 点击开始考试
   * @param id
   */
  function handleClickItem(data: { plan_id: number; endtime: string }) {
    // 判断当前科目是否截止
    var myDate = new Date()
    let date = data.endtime
    date = date.substring(0, 19)
    date = date.replace(/-/g, '/')
    var timestamp = new Date(date).getTime()
    //
    if (timestamp <= new Date().getTime()) {
      console.log('考试已过期')
      // alert("考试已过期");
      const modal = Modal.alert({
        title: '提示',
        content: '考试已过期',
        onCancel: () => {
          modal.hide()
        },
      })
    } else {
      const modal = Modal.confirm({
        title: '提示',
        content: '点击开始考试',
        onCancel: () => {
          console.log('点击cancel')
        },
        onOk: () => {
          props.history.push(`/build/test-examination?plan_id=${data.plan_id}`)
        },
      })
      // const modal = Modal.alert({
      //   title: '提示',
      //   content: '点击开始考试',
      //   onCancel: () => {
      //     modal.hide();

      //   },
      // });
      // if (!confirm("准备好考试了吗?")) {
      //     return
      // } else {
      //     props.history.push(`/build/test-examination?plan_id=${data.plan_id}`)
      // }
    }
  }

  return (
    <div>
      {/* 搜索框 */}
      <div className={style.Search_warp}>
        <input className={style.Search_input} type="text" />
        <Icon type="search" className={style.Search_icon} />
      </div>
      {/* 列表 */}
      <div className={style.Test_content}>
        {data.map(
          (v: {
            author: string
            id: number
            paper_name: string
            pass_score: number
            createtime: string
            endtime: string
            totalscore: number
            plan_id: number
          }) => {
            return (
              <div className={style.Test_item} key={v.id}>
                <div className={style.Test_info}>
                  <div className={style.Test_name}>
                    试卷名称: {v.paper_name}
                  </div>
                  <div className={style.Test_num_time}>
                    <div style={{ width: '40%' }}>
                      合格分数: {v.pass_score}分
                    </div>
                    <div>创建时间: {v.createtime}</div>
                  </div>
                  <div className={style.Test_num_time}>
                    <div style={{ width: '40%' }}>计划制定人: {v.author}</div>
                    <div>截止时间: {v.endtime}</div>
                  </div>
                  <div className={style.Test_btn_list}>
                    <div
                      className={style.Test_btn_style}
                      onClick={() => handleClickItem(v)}
                    >
                      立即考试
                    </div>
                    <div
                      className={style.Test_btn_style}
                      onClick={() =>
                        props.history.push(
                          `/build/test-records?plan_id=${v.plan_id}&type=plan_id`
                        )
                      }
                    >
                      查看错题
                    </div>
                    <div style={{ color: 'red' }}>
                      状态: {v.totalscore === 0 ? '未考' : '已考'}
                    </div>
                    <div style={{ color: 'red' }}>最高得分: {v.totalscore}</div>
                  </div>
                </div>
              </div>
            )
          }
        )}
        {data.length === 0 && (
          <div className={style.Test_Text_null}>暂无考试~</div>
        )}
      </div>
    </div>
  )
}

export default withRouter(TestCenter)
