import React from "react";
import { BangBangTangLayout } from "../../layout/layout";
import style from "./index.module.scss";
import {
  Tabs,
  ImagePreview,
  Button,
  Input,
  FilePicker,
  Icon,
  Modal,
} from "zarm";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Toast } from "zarm";
import { finalize } from "rxjs/operators";
import addPicture from "../../assets/add-picture.png";
import OSS from "ali-oss";

const { Panel } = Tabs;

export class RectificationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OssConfig: {},
      reform_id: "",
      visible: false,
      reformVisible: false,
      pictureIndex: 0,
      reformIndex: 0,
      content: "",
      detail: {},
      images: [],
      reform_image: [],//上传数据用
      reform_images: [],//浏览图片用
      steps: [],
      result: "",
      resultModal: false,
      confirmModal: false,
      showBtn: false,
    };
  }

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
  };

  componentDidMount() {
    // console.log(this.props)
    const {
      match: {
        params: { id, type },
      },
    } = this.props;
    // console.log(type);
    this.setState(
      {
        reform_id: id,
        showBtn: type == 0 ? true : false,
      },
      () => {
        this.getDetail(id);
        this.getSteps(id);
        this.getOssConfig();
      }
    );
    window.document.title = "工作单详情";
  }

  getDetail = (id) => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getReformDetail",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        reform_id: id,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        that.setState({
          detail: result,
          images: result.images,
        });
      });
  };

  getSteps = (id) => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getReformLog",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        reform_id: id,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        const journal = result.reverse();
        that.setState({
          steps: journal || [],
        });
      });
  };

  getOssConfig = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getOssConfig",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        that.setState({
          OssConfig: result,
        });
      });
  };

  show = (index) => {
    this.setState({
      visible: true,
      pictureIndex: index,
    });
  };

  reformShow = (index) => {
    this.setState({
      reformVisible: true,
      reformIndex: index,
    });
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  reformHide = () => {
    this.setState({
      reformVisible: false,
    });
  };

  setContent = (val) => {
    this.setState({
      content: val,
    });
  };

  onSelect = (file) => {
    //console.log(file)
    if (this.canUpload(file.file)) {
      this.transformFile(file.file).then((res) => {
        // console.log(res)
        this.upload(file.fileName, res);
      });
    }
  };

  canUpload = (file) => {
    const reg = /image/;
    const isImage = reg.test(file.type);
    if (!isImage) {
      Toast.show("请上传图片文件");
    }
    return isImage;
  };

  transformFile = (file) => {
    //判断是否是图片类型
    // if (this.canUpload(file)) {
    const pictureQuality = 0.92;
    //判断浏览器内核是否支持base64图片压缩
    if (typeof FileReader === "undefined") {
      return file;
    } else {
      try {
        return new Promise((resolve) => {
          //声明FileReader文件读取对象
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            // 生成canvas画布
            const canvas = document.createElement("canvas");
            // 生成img
            const img = document.createElement("img");
            img.src = reader.result;
            img.onload = () => {
              const ctx = canvas.getContext("2d");
              //原始图片宽度、高度
              let originImageWidth = img.width,
                originImageHeight = img.height;
              //默认最大尺度的尺寸限制在（600 * 600）
              let maxWidth = 600,
                maxHeight = 600,
                ratio = maxWidth / maxHeight;
              //目标尺寸
              let targetWidth = originImageWidth,
                targetHeight = originImageHeight;
              //当图片的宽度或者高度大于指定的最大宽度或者最大高度时,进行缩放图片
              if (
                originImageWidth > maxWidth ||
                originImageHeight > maxHeight
              ) {
                //超过最大宽高比例
                if (originImageWidth / originImageHeight > ratio) {
                  //宽度取最大宽度值maxWidth,缩放高度
                  targetWidth = maxWidth;
                  targetHeight = Math.round(
                    maxWidth * (originImageHeight / originImageWidth)
                  );
                } else {
                  //高度取最大高度值maxHeight,缩放宽度
                  targetHeight = maxHeight;
                  targetWidth = Math.round(
                    maxHeight * (originImageWidth / originImageHeight)
                  );
                }
              }
              // canvas对图片进行缩放
              canvas.width = targetWidth;
              canvas.height = targetHeight;
              // 清除画布
              ctx.clearRect(0, 0, targetWidth, targetHeight);
              // 绘制图片
              ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
              // quality值越小,图像越模糊,默认图片质量为0.92
              const imageDataURL = canvas.toDataURL(
                file.type || "image/jpeg",
                pictureQuality
              );
              // 去掉URL的头,并转换为byte
              const imageBytes = window.atob(imageDataURL.split(",")[1]);
              // 处理异常,将ascii码小于0的转换为大于0
              const arrayBuffer = new ArrayBuffer(imageBytes.length);
              const uint8Array = new Uint8Array(arrayBuffer);
              for (let i = 0; i < imageBytes.length; i++) {
                uint8Array[i] = imageBytes.charCodeAt(i);
              }
              let mimeType = imageDataURL.split(",")[0].match(/:(.*?);/)[1];
              let newFile = new File([uint8Array], file.name, {
                type: mimeType || "image/jpeg",
              });
              // console.log('after compress, the file size is : ', (newFile.size / 1024 / 1024) + "M");
              resolve(newFile);
            };
          };
          reader.onerror = () => {
            return file;
          };
        })
          .then((res) => {
            return res;
          })
          .catch(() => {
            return file;
          });
      } catch (e) {
        //压缩出错,直接返回原file对象
        return file;
      }
    }
    // } else {
    //     //非图片文件,不进行压缩,直接返回原file对象
    //     return file;
    // }
  };

  upload = async (fileName, lrzFile) => {
    const { OssConfig } = this.state;
    let client = new OSS({
      endpoint: "https://" + OssConfig.endpoint, //本地使用"http://",线上使用"https://",地域（在创建 Bucket 的时候指定的中心位置），这里可能不知道具体地域怎么填其实就是 oss-cn-中心位置 ，例：region:'oss-cn-chengdu'，chengdu则是创建bucket是指定的位置成都。
      accessKeyId: OssConfig.app_id, //阿里云产品的通用id
      accessKeySecret: OssConfig.app_key, //密钥
      bucket: OssConfig.bucket, //OSS 存储区域名
    });
    const today = this.timestampToTime();
    const uploadFileName = this.random_20();
    const suffix = fileName.substring(fileName.lastIndexOf(".") + 1);
    const objectName =
      "uploads/" +
      localStorage.getItem("admin_store_id") +
      "/img/sanction/" +
      today +
      "/" +
      uploadFileName +
      "." +
      suffix;
    // console.log(objectName);
    try {
      let result = await client.put(objectName, lrzFile);
      // console.log(result);
      let imageList = this.state.reform_images;
      let reform_image = this.state.reform_image;
      reform_image.push("/" + objectName);
      imageList.push(result.url);
      // console.log(reform_image);
      this.setState({
        reform_images: imageList,
        reform_image,
      });
    } catch (err) {
      console.log(err);
    }
  };

  timestampToTime = () => {
    var date = new Date(); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear();
    var M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var D = date.getDate();
    return Y + M + D;
  };

  random_20 = () => {
    var data = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
    ];
    var result = "";
    for (var i = 0; i < 20; i++) {
      const r = Math.floor(Math.random() * 16);
      result += data[r];
    }
    return result;
  };

  showConfirmModal = () => {
    this.setState({
      confirmModal: true,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      confirmModal: false,
    });
  };

  closeResultModal = () => {
    this.setState({
      resultModal: false,
      result: "",
    });
  };

  submit = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/submitReformOnComplete",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        reform_id: this.state.reform_id,
        reform_desc: that.state.content,
        reform_image: that.state.reform_image,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        if (result == "提交成功") {
          this.closeConfirmModal();
          that.setState({
            result,
            resultModal: true,
            showBtn: false,
          });
        } else {
          this.closeConfirmModal();
          that.setState({
            result,
            resultModal: true,
          });
        }
      });
  };

  deleteImg=(index,e)=>{
    e.stopPropagation();
    let {reform_image,reform_images}=this.state;
    reform_images.splice(index, 1);
    reform_image.splice(index, 1);
    this.setState({
      reform_images,
      reform_image,
    })
  }

  render() {
    const {
      visible,
      reformVisible,
      pictureIndex,
      reformIndex,
      content,
      detail,
      images,
      reform_images,
      steps,
      showBtn,
      result,
      resultModal,
      confirmModal,
    } = this.state;
    return (
      <BangBangTangLayout title={"执行单详情"}>
        <div className={style.container}>
          <div className="detai-container">
            <div className="section">
              <div className="k">工单类别</div>
              <div className="v">{detail.type_name}</div>
            </div>
            <div className="section">
              <div className="k">工单描述</div>
              <div className="v">{detail.reason}</div>
            </div>
          </div>
          {images.length > 0 ? (
            <div className="photo-list">
              {images.map((pic, index) => (
                <div
                  className="picture-item"
                  onClick={() => this.show(index)}
                  key={+index}
                >
                  <img src={pic} />
                </div>
              ))}
            </div>
          ) : null}
          {images.length > 0 ? (
            <ImagePreview
              visible={visible}
              images={detail.images}
              onClose={this.hide}
              activeIndex={pictureIndex}
            />
          ) : null}
          <div className="detai-container">
            <div className="section">
              <div className="k">扣除执行力</div>
              <div className="v">{detail.integral}</div>
            </div>
            <div className="section">
              <div className="k">处罚金额</div>
              <div className="v">{detail.money}</div>
            </div>
          </div>

          {steps.length > 0 ? (
            <div className="journal-steps step-vertical">
              {steps.map((item, index) => {
                return (
                  <div
                    className={
                      index == 0
                        ? "step-container step-doing"
                        : "step-container step-finished"
                    }
                    key={index}
                  >
                    <div className="step-line"></div>
                    <div className="step-dot">
                      <div className="dot"></div>
                    </div>
                    <div className="step-content">
                      <div className="content-section">
                        <div className="k">操作日志：</div>
                        <div className="v ellipsis ellipsis-3">{item.log}</div>
                      </div>
                      <div className="handle-section">
                        <div className="k">{item.createtime}</div>
                        <div className="v">{item.updater}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          <div className="detai-container">
            <div className="section">
              <div className="k">执行完成</div>
              <div className="v">
                {showBtn?
                  <Input
                    type="text"
                    rows={2}
                    placeholder="请输入"
                    value={content}
                    onChange={this.setContent}
                  />
                :
                  (detail.reform_desc?detail.reform_desc:'无')
                }
              </div>
            </div>
          </div>

          {reform_images.length > 0 || showBtn ? (
            <div className="photo-list">
              {reform_images.map((pic, index) => (
                <div
                  className="picture-item"
                  onClick={() => this.reformShow(index)}
                  key={+index}
                >
                  <img src={pic} />
                  <Icon className="delete-btn" type="wrong-round-fill"  theme="primary" size="md" onClick={(e)=>{this.deleteImg(index,e)}}/>
                </div>
              ))}
              {reform_images.length < 3 && showBtn ? (
                <FilePicker
                  className="file-picker-btn"
                  accept="image/*"
                  onChange={this.onSelect}
                >
                  {/* <Icon type="add" size="lg" style={{ color: "#aaaaaa" }} /> */}
                  <img src={addPicture} />
                </FilePicker>
              ) : null}
            </div>
          ) : null}
          {reform_images.length > 0 ? (
            <ImagePreview
              visible={reformVisible}
              images={reform_images}
              onClose={this.reformHide}
              activeIndex={reformIndex}
            />
          ) : null}
          {showBtn ? (
            <div className="foot-option">
              <div className="button">
                <Button
                  style={{ width: "316px" }}
                  theme="primary"
                  size="sm"
                  onClick={this.showConfirmModal}
                >
                  整改完成
                </Button>
              </div>
            </div>
          ) : null}

          <Modal
            className={style.detailModal}
            visible={resultModal}
            footer={
              <Button block className="sure" onClick={this.closeResultModal}>
                知道了
              </Button>
            }
          >
            <div className="t-c">{result}</div>
          </Modal>

          <Modal
            className={style.detailModal}
            visible={confirmModal}
            footer={
              <div className="confirmBtns t-c">
                <div className="btn" onClick={this.closeConfirmModal}>
                  取消
                </div>
                <div className="btn" onClick={this.submit}>
                  确认
                </div>
              </div>
            }
          >
            <div className="t-c">确认整改完成？</div>
          </Modal>
        </div>
      </BangBangTangLayout>
    );
  }
}
export default withRouter(RectificationDetail);
