import React from "react";
import { observer } from "mobx-react";
import { EmployeePreorderDetail } from "../../../store/employee-preorder-list/employee-preorder-list.api";
import { store } from "../../../store/store.root";
import "./employee-preorder-card.scss";
import { PreorderStatusRender } from "../../../components/preorder-status-render";

@observer
export class EmployeePreorderCardComponent extends React.Component<
  { id: number },
  {}
> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const data:
      | EmployeePreorderDetail
      | undefined = store.employeePreorderListStore.displayData.find(
      (value) => value.id === this.props.id
    );
    if (data) {
      return (
        <div className="employee-preorder-card">
          <div
            className="info-container"
            onClick={() =>
              store.routerStore.push(
                `/build/preorder-detail-from-employee/${data?.id}`
              )
            }
          >
            <div className="info-row">
              <div className="title">档期类型:</div>
              <div className="value">{data.type_name}</div>
            </div>
            <div className="info-row">
              <div className="title">客户手机:</div>
              <div className="value">{data.mobile}</div>
            </div>
            <div className="info-row">
              <div className="title">档期日期:</div>
              <div className="value">{data.schedule_date}</div>
            </div>
            <div className="info-row">
              <div className="title">宝宝姓名:</div>
              <div className="value">{data.baby_name}</div>
            </div>
            <div className="info-row">
              <div className="title">档期时间:</div>
              <div className="value">{data.schedule_time}</div>
            </div>
            <div className="info-row">
              <div className="title">档期状态:</div>
              <div className="value">
                <PreorderStatusRender value={data.status} />
              </div>
            </div>
            <div className="info-row">
              <div className="title">拍摄进度:</div>
              <div className="value">
                <PreorderStatusRender value={data.schedule_status} />
              </div>
            </div>
          </div>
          {data.user_tag.length > 0 ? (
            <div className="tag-container">
              {data.user_tag.map((value) => (
                <span
                  className="tag"
                  style={{
                    color: value.tag_color,
                    borderColor: value.tag_color,
                  }}
                  key={value.tag_name}
                >
                  {value.tag_name}
                </span>
              ))}
            </div>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  }
}
