import 'core-js'
import React from 'react'
import './App.css'
import 'zarm/dist/zarm.min.css'
import 'react-vant/lib/index.css'
import { Route, Router } from 'react-router-dom'
import Index from './pages/index'
import { PhotographicPlateDownload } from './pages/photographic-plate-download/photographic-plate-download'
import { PhotoFamilyCollection } from './pages/photo-family-collection/photo-family-collection'
import { Register } from './pages/register/register'
import MyInfo from './pages/my-info/my-info'
import { createBrowserHistory } from 'history'
import { store } from './store/store.root'
import { CloseRelationshipNumber } from './pages/close-relationship-number/close-relationship-number'
import { InfoReport } from './pages/info-report/info-report'
import { SatisfactionResearch } from './pages/satisfaction-research/satisfaction-research'
import { CustomerConfirm } from './pages/customer-confirm/customer-confirm'
import { syncHistoryWithStore } from '@superwf/mobx-react-router'
import { CustomerFeedback } from './pages/customer-feedback/customer-feedback'
// 个人中心页面
import PersonCenter from './pages/person-center/index'
// 负面评价页面
// import NegativeComment from "./pages/negative-comment";
import NegativeComments from './pages/negative-comments/index'
import NegativeCommentsDetail from './pages/negative-comments-detail/detail'
// 差评详情
// import CommentDetail from "./pages/negative-comment/CommentDetail";
// 执行力管理
import ExecutiveManagement from './pages/executive-management'
//查看奖励/处罚单
import BonusPenalty from './pages/bonus-penalty'
//工作单列表
import Rectification from './pages/rectification'
//工作单详情
import RectificationDetail from './pages/rectification-detail'
//审核工作单
import RectificationAudit from './pages/rectification-audit'
//审核工作单详情
import RectificationAuditDetail from './pages/rectification-audit-detail'
//执行力排行榜
import ExecutiveForce from './pages/executive-force'
//执行力明细
import ExecutiveForceList from './pages/executive-force-list'
//执行力明细
import ExecutiveForceDetail from './pages/executive-force-detail'
//开工作单
import DrawRectification from './pages/draw-rectification'
//开奖励单
import DrawAward from './pages/draw-award'
// 预约服装
import BookingClothes from './pages/booking-clothes'
// 预约提醒
import AppointmentReminder from './pages/appointment-reminder'
import { PreorderSchedule } from './pages/preorder-schedule/preorder-schedule'
import { MyPreorder } from './pages/my-preorder/my-preorder'
import { EmployeePreorderList } from './pages/employee-preorder-list/employee-preorder-list'

// 考试中心
import TestCenter from './pages/test-center/index'
// 员工中心
import EmployeeCenter from './pages/employee-center'
// 考试记录
import TestRecords from './pages/test-records'
// 考试
import TestExamination from './pages/test-examination'
// 考试结算
import TestClose from './pages/test-close'
// 客诉
import Customer from './pages/customer'
// 服装预选
import ClothingPrimary from './pages/clothing-primary'
// 服装预选菜单
import ClothingPrimaryMenu from './pages/clothing-primary/clothingMenu'
// 服装预选列表
import ClothingPrimaryList from './pages/clothing-primary/clothingList'
import FilmDownload from './pages/film-download'
import FilmDownloadNew from './pages/film-download-new'

import { Assessment } from './pages/assessments'
import { LayoutEnsureStoreSelected } from './layout/layout-ensure-store-selected'
import { MyPreorderDetail } from './pages/my-preorder-detail/my-preorder-detail'
import { PreorderDetailFromEmployee } from './pages/preorder-detail-from-employee/preorder-detail-from-employee'
import VerificationVoucher from './pages/verification-voucher'
import ExamPreview from './pages/exam-preview'

//我的分销活动
import MyDistribution from './pages/my-distribution/index'
//我的结算
import MySettlement from './pages/my-settlement'
import MySettlementList from './pages/my-settlement/list'
import { EmployeeQueryPreorderClotheList } from './pages/employee-query-preorder-clothe-list/employee-query-preorder-clothe-list'
import { CustomerPreorderClotheDetail } from './pages/customer-preorder-clothe-detail/customer-preorder-clothe-detail'
import { CustomerQueryPreorderClotheList } from './pages/customer-query-preorder-clothe-list/customer-query-preorder-clothe-list'
import { EmployeePreorderSearch } from './pages/employee-preorder-search/employee-preorder-search'
import { EmployeePreorderClotheBriefDetail } from './pages/employee-preorder-clothe-brief-detail/employee-preorder-clothe-brief-detail'
import EmployeePreorderClothePreview from './pages/customer-preorder-clothe-detail/employee-preorder-clothe-detail-preview'
import CollectBag from './pages/collect-bag'
import CardCouponDetaile from './pages/card-coupon-detaile'
import MyTicket from './pages/my-ticket'
import MyTicketDetaile from './pages/my-ticket-detaile'
import { EmployeePreorderClothe } from './pages/employee-preorder-clothe/employee-preorder-clothe'
import Agreement from './pages/agreement'
import SelectionActivities from './pages/selection-activities'
import SelectionActivitiesPay from './pages/selection-activities/selection-activities-pay'
import SelectionActivitiesTest from './pages/selection-activities/test'
import { VoucherList } from './pages/selection-activities/voucher'
import ActivityRegistration from './pages/activity-registration'
import ActivityRegistrationPay from './pages/activity-registration/pay'
import SmilingFace, { SmilingFaceOrder } from './pages/smiling-face'
import SelectionSuccessful from './pages/selection-activities/selection-successful'
import ConfirmationPage from './pages/confirmation-page'
import ConfirmationEditPage from './pages/confirmation-page/edit'
import DesignPage from './pages/design'
import Express from './pages/express'
import PageAuth from './pages/pageAuth'
import ExpressCheckTrack from './pages/expressCheckTrack'
import ExpressPregnantCheckTrack from './pages/expressCheckTrack/pregnant'
import GenealogicalReminder from './pages/genealogical-reminder'
import GiftCenter from './pages/gift-center'
import GiftCenterDetaile from './pages/gift-center/detaile'
import IntegralMan from './pages/integral-man'
import IntegralMall from './pages/gift-center/mall'
import Selection from './pages/selection'
import GraduationSign from './pages/graduation-sign'
import GraduationSignPay from './pages/graduation-sign/pay'
import GraduationSelection from './pages/graduation-sign/graduation'
import GraduationMenu from './pages/graduation-sign/graduationMenu'
import SelectionView from './pages/selection/Selectview'
import RedEnvelopes from './pages/red-envelopes'
import AgreementSigned from './pages/agreement-signed'
import EntryAuthorization from './components/EntryAuthorization'
import { Dialog } from 'react-vant'
import DescriptionInformation from './pages/descriptionInformation'
import EquityReview from './pages/equity-review'
import MyCommission from './pages/my-commission'
import ScheduleAppointment from './pages/schedule-appointment'
import ScheduleAppointmentList from './pages/schedule-appointment/list'
import {
  TheCommissionSubsidiary,
  TheWithdrawalOfSubsidiary,
} from './pages/my-commission/list'
import UpdateUserInfo from './pages/update-user-info'
import { getParamValue } from './utils/url'

const history = createBrowserHistory()
const customHistory = syncHistoryWithStore(history, store.routerStore)

class App extends React.Component {
  componentDidMount() {
    console.log('APP初始化')
    // 'adb6de25-a7a3-4be8-9511-d36267b44e9e' 测试
    let localToken =
      window.localStorage.getItem('token') || getParamValue('token')
    if (localToken) {
      // 如果有localToken则可以直接进行登陆
      store.userStore.changeToken(localToken)
      store.userStore.getUserInfo()
    } else {
      store.userStore.loginFromWechat()
    }
    history.block((location: { pathname: string }, action: any) => {
      // 访问以下路由一定要登录
      const allowedToGo = [
        // '/build/customer-query-preorder-clothe-list',
        '/build/film-download',
      ]
      if (
        store.userStore.need_info &&
        allowedToGo.indexOf(location.pathname) > -1
      ) {
        Dialog.confirm({
          title: '提示',
          message:
            '为了方便客服与您联系，提供更优质的服务，需要获取您的微信头像和昵称',
        })
          .then(() => {
            localStorage.removeItem('token')
            localStorage.setItem('scope', 'snsapi_userinfo')
            store.userStore.loginFromWechat()
          })
          .catch(() => {
            // on cancel
          })
        return
      }
    })
  }

  render() {
    return (
      <Router history={customHistory}>
        <Route exact path="/build">
          <Index />
        </Route>

        <Route exact path="/build/employee-preorder-list">
          <LayoutEnsureStoreSelected>
            <EmployeePreorderList />
          </LayoutEnsureStoreSelected>
        </Route>

        <Route exact path="/build/employee-query-preorder-clothe-list">
          <LayoutEnsureStoreSelected>
            <EmployeeQueryPreorderClotheList />
          </LayoutEnsureStoreSelected>
        </Route>
        <Route exact path="/build/customer-query-preorder-clothe-list">
          <CustomerQueryPreorderClotheList />
        </Route>

        {/* <Route exact path="/build/negative-comment">
          <NegativeComment />
        </Route>
        <Route exact path="/build/comment-detail">
          <CommentDetail />
        </Route> */}
        <Route exact path="/build/person-center">
          <LayoutEnsureStoreSelected>
            <PersonCenter />
          </LayoutEnsureStoreSelected>
        </Route>
        <Route exact path="/build/assessment">
          <Assessment />
        </Route>
        <Route exact path="/build/close-relationship-number">
          <CloseRelationshipNumber />
        </Route>
        <Route exact path="/build/photo-graph-plate-download">
          <PhotographicPlateDownload />
        </Route>
        <Route exact path="/build/photo-family-collection">
          <PhotoFamilyCollection />
        </Route>
        <Route exact path="/build/register">
          <Register />
        </Route>
        <Route exact path="/build/my-info">
          <MyInfo />
        </Route>
        <Route exact path="/build/info-report">
          <InfoReport />
        </Route>
        <Route exact path="/build/satisfaction-research">
          <SatisfactionResearch />
        </Route>
        <Route exact path="/build/customer-confirm">
          <CustomerConfirm />
        </Route>
        <Route exact path="/build/negative-comments/:progress">
          <NegativeComments />
        </Route>
        <Route exact path="/build/negative-comments-detail/:id">
          <NegativeCommentsDetail />
        </Route>

        <Route path="/build/executive-management">
          <ExecutiveManagement />
        </Route>
        <Route path="/build/bonus-penalty">
          <BonusPenalty />
        </Route>
        <Route path="/build/rectification">
          <Rectification />
        </Route>
        <Route path="/build/rectification-detail/:id/:type">
          <RectificationDetail />
        </Route>
        <Route path="/build/rectification-audit">
          <RectificationAudit />
        </Route>
        <Route path="/build/rectification-audit-detail/:id/:type">
          <RectificationAuditDetail />
        </Route>
        <Route path="/build/executive-force">
          <ExecutiveForce />
        </Route>
        <Route path="/build/executive-force-list">
          <ExecutiveForceList />
        </Route>
        <Route path="/build/executive-force-detail/:id/:type">
          <ExecutiveForceDetail />
        </Route>
        <Route path="/build/draw-rectification">
          <DrawRectification />
        </Route>
        <Route path="/build/draw-award">
          <DrawAward />
        </Route>
        <Route exact path="/build/customer-feedback">
          <CustomerFeedback />
        </Route>
        <Route exact path="/build/booking-clothes">
          <BookingClothes />
        </Route>
        <Route exact path="/build/appointment-reminder">
          <AppointmentReminder />
        </Route>
        <Route exact path="/build/preorder-schedule">
          <PreorderSchedule />
        </Route>
        <Route exact path="/build/my-preorder">
          <MyPreorder />
        </Route>
        <Route path="/build/my-preorder-detail/:id">
          <MyPreorderDetail />
        </Route>
        <Route path="/build/preorder-detail-from-employee/:id">
          <PreorderDetailFromEmployee />
        </Route>
        <Route exact path="/build/test-center">
          <LayoutEnsureStoreSelected>
            <TestCenter />
          </LayoutEnsureStoreSelected>
        </Route>
        <Route exact path="/build/employee-preorder-clothe-detail">
          <EmployeePreorderClothe />
        </Route>

        <Route exact path="/build/employee-center">
          <LayoutEnsureStoreSelected>
            <EmployeeCenter />
          </LayoutEnsureStoreSelected>
        </Route>
        <Route exact path="/build/test-records">
          <LayoutEnsureStoreSelected>
            <TestRecords />
          </LayoutEnsureStoreSelected>
        </Route>
        <Route exact path="/build/test-close">
          <LayoutEnsureStoreSelected>
            <TestClose />
          </LayoutEnsureStoreSelected>
        </Route>
        <Route exact path="/build/test-examination">
          <LayoutEnsureStoreSelected>
            <TestExamination />
          </LayoutEnsureStoreSelected>
        </Route>
        <Route exact path="/build/customer">
          <LayoutEnsureStoreSelected>
            <Customer />
          </LayoutEnsureStoreSelected>
        </Route>
        <Route exact path="/build/employee-preorder-clothe-brief-detail">
          <LayoutEnsureStoreSelected>
            <EmployeePreorderClotheBriefDetail />
          </LayoutEnsureStoreSelected>
        </Route>
        <Route exact path="/build/employee-preorder-clothe-detail-preview">
          <EmployeePreorderClothePreview />
        </Route>

        <Route exact path="/build/clothing-primary">
          <ClothingPrimary />
        </Route>
        <Route exact path="/build/clothing-primary-menu">
          <ClothingPrimaryMenu />
        </Route>

        <Route exact path="/build/my-distribution">
          <MyDistribution />
        </Route>
        <Route exact path="/build/my-settlement/list">
          <MySettlement />
        </Route>
        <Route exact path="/build/my-settlement/detaile/list">
          <MySettlementList />
        </Route>

        <Route exact path="/build/verification-voucher/:code">
          <VerificationVoucher />
        </Route>
        <Route exact path="/build/exam-preview/:id">
          <ExamPreview />
        </Route>

        <Route exact path="/build/customer-preorder-clothe-detail">
          <CustomerPreorderClotheDetail />
        </Route>
        <Route exact path="/build/employee-preorder-search">
          <EmployeePreorderSearch />
        </Route>
        <Route exact path="/build/film-download">
          <EntryAuthorization>
            <FilmDownload />
          </EntryAuthorization>
        </Route>
        <Route exact path="/build/film-download-new/:uid">
          <FilmDownloadNew />
        </Route>
        <Route exact path="/build/collect-bag">
          <CollectBag />
        </Route>
        <Route exact path="/build/card-coupon-detaile/:cardId">
          <CardCouponDetaile />
        </Route>
        <Route exact path="/build/my-ticket">
          <MyTicket />
        </Route>
        <Route exact path="/build/my-ticket-detaile/:id">
          <MyTicketDetaile />
        </Route>
        <Route exact path="/build/confirmation-page/detaile/:photopage_id">
          <ConfirmationPage />
        </Route>
        <Route exact path="/build/confirmation-page/edit">
          <ConfirmationEditPage />
        </Route>
        <Route exact path="/build/agreement/:id">
          <Agreement />
        </Route>
        <Route exact path="/build/selection-activities/:eroll_id">
          <SelectionActivities />
        </Route>

        {/* test */}
        <Route exact path="/build/selection-activities/test/:eroll_id">
          <SelectionActivitiesTest />
        </Route>

        <Route exact path="/build/selection-activities-pay">
          <SelectionActivitiesPay />
        </Route>
        <Route exact path="/build/activity-registration/:id">
          <ActivityRegistration />
        </Route>
        <Route exact path="/build/activity-registration-pay">
          <ActivityRegistrationPay />
        </Route>
        <Route exact path="/build/smiling-face">
          <SmilingFace />
        </Route>
        <Route exact path="/build/smiling-face-order">
          <SmilingFaceOrder />
        </Route>
        <Route exact path="/build/selection-successful">
          <SelectionSuccessful />
        </Route>
        <Route exact path="/build/design">
          <DesignPage />
        </Route>
        <Route exact path="/build/express/confirmAddress/:id">
          <Express />
        </Route>
        <Route exact path="/build/page/auth">
          <PageAuth />
        </Route>
        <Route exact path="/build/express/:id">
          <ExpressCheckTrack />
        </Route>
        <Route exact path="/build/express_pregnant/:order">
          <ExpressPregnantCheckTrack />
        </Route>
        <Route exact path="/build/genealogical-reminder">
          <GenealogicalReminder />
        </Route>
        <Route exact path="/build/gift-center">
          <GiftCenter />
        </Route>
        <Route exact path="/build/gift-center/:id">
          <GiftCenterDetaile />
        </Route>
        <Route exact path="/build/integral-man">
          <IntegralMan />
        </Route>
        <Route exact path="/build/mall">
          <IntegralMall />
        </Route>
        <Route exact path="/build/selection/:id">
          <Selection />
        </Route>
        {/* 生成预览链接 */}
        <Route exact path="/build/selection-view/:activity_id/:eroll_id">
          <SelectionView />
        </Route>
        {/* 报名页 */}
        <Route exact path="/build/graduation-sign/:id">
          <GraduationSign />
        </Route>
        <Route exact path="/build/graduation-sign-pay">
          <GraduationSignPay />
        </Route>
        <Route exact path="/build/graduation/:eroll_id">
          <GraduationSelection />
        </Route>

        {/* 入口 ?eroll_id=14 */}
        <Route exact path="/build/graduation-select/:id">
          <GraduationMenu />
        </Route>

        <Route exact path="/build/red-envelopes">
          <RedEnvelopes />
        </Route>

        <Route exact path="/build/theAgreementSigned/:id">
          <AgreementSigned />
        </Route>

        <Route exact path="/build/DescriptionInformation/:id">
          <DescriptionInformation />
        </Route>

        <Route exact path="/build/equity/review/:id">
          <EquityReview />
        </Route>

        <Route exact path="/build/my/commission">
          <MyCommission />
        </Route>

        <Route exact path="/build/commission/subsidiary">
          <TheCommissionSubsidiary />
        </Route>

        <Route exact path="/build/withdrawal/subsidiary">
          <TheWithdrawalOfSubsidiary />
        </Route>

        <Route exact path="/build/update/userinfo">
          <UpdateUserInfo />
        </Route>

        <Route exact path="/build/schedule-appointment">
          <LayoutEnsureStoreSelected>
            <ScheduleAppointment />
          </LayoutEnsureStoreSelected>
        </Route>

        <Route exact path="/build/schedule-appointment/list">
          <ScheduleAppointmentList />
        </Route>
      </Router>
    )
  }
}

export default App
