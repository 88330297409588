import React from 'react';
import style from './index.module.scss'
import LazyLoad from 'react-lazyload';

interface ImgInterface {
    url: string;
    onClick: () => void
}

export default (props: ImgInterface) => {
    const { url, onClick } = props;
    return (
        <div className={style.img_content} onClick={onClick}>
            <LazyLoad
                once
                scroll={true}
                height={100}
                offset={100}
                placeholder={<div>13</div>}
            >
                {
                    url ?
                        (<img src={url} alt="" />)
                        :
                        // 图片加载失败处理
                        (<img src="http://localhost:8000/1.jpg" alt="" />)
                }
            </LazyLoad>
        </div>
    )
}