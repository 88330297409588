import React from "react";
import { Button, Calendar, Picker, Popup } from "zarm";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";
import { add, isBefore, isSameDay } from "date-fns";
import { preorderScheduleActions } from "../../store/preorder-schedule/preorder-schedule.action";
import { toJS } from "mobx";

@observer
export class MonthCalendar extends React.Component<
  {},
  {
    selected: null | Date;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { selected: null };
  }
  render() {
    const dataSource = store.preorderScheduleStore.calendarDataSource;
    const today = new Date();
    return (
      <Popup
        visible={store.preorderScheduleStore.available_date.picker_visible}
        onMaskClick={() => preorderScheduleActions.togglePickerVisible("date")}
        className="calendar-month"
      >
        {store.preorderScheduleStore.available_date.loading ? (
          <div className="loading">
            <span className="iconfont">&#xe608;</span>
          </div>
        ) : null}
        {dataSource ? (
          <Calendar
            multiple={false}
            min={dataSource.start}
            max={dataSource.end}
            value={this.state.selected || undefined}
            dateRender={(date) => {
              if (isBefore(date!, add(today, { days: -1 }))) {
                return date?.getDate();
              }
              const dateInStore = store.preorderScheduleStore.available_date.choices?.find(
                (v) => isSameDay(date!, new Date(v.year, v.month, v.day))
              );
              if (dateInStore) {
                return (
                  <div className="custom">
                    <div className="custom__date">{date!.getDate()}</div>
                    {dateInStore.status === "3" ? (
                      <div className="custom__text green">消毒</div>
                    ) : dateInStore.status === "2" ? (
                      <div className="custom__text red">已满</div>
                    ) : null}
                  </div>
                );
              } else {
                return "";
              }
            }}
            onChange={(value) =>
              this.setState({ selected: value ? value[0] : null })
            }
            disabledDate={(date) => {
              const dateInStore = store.preorderScheduleStore.available_date.choices?.find(
                (v) => isSameDay(date!, new Date(v.year, v.month, v.day))
              );
              if (isBefore(date!, add(today, { days: -1 }))) {
                return true;
              }
              if (dateInStore) {
                if (dateInStore.status === "1" || dateInStore.status === "2") {
                  return false;
                }
                {
                  return true;
                }
              } else {
                return true;
              }
            }}
          />
        ) : null}
        <div className="month-button-row">
          <Button
            className="month-button"
            disabled={
              store.preorderScheduleStore.changeCurrentMonthToPrevDisabled
            }
            onClick={() => {
              preorderScheduleActions.changeCurrentMonth("prev");
              this.setState({ selected: null });
            }}
          >
            上个月
          </Button>
          <Button
            theme="primary"
            className="main"
            onClick={() =>
              this.state.selected
                ? preorderScheduleActions.setDateValue(this.state.selected)
                : null
            }
            disabled={
              !(
                this.state.selected &&
                this.state.selected.getMonth() ===
                  store.preorderScheduleStore.current_selected_month &&
                this.state.selected.getFullYear() ===
                  store.preorderScheduleStore.current_selected_year
              )
            }
          >
            {this.state.selected === null ? "选择可用日期" : "点击查询档期"}
          </Button>
          <Button
            className="month-button"
            onClick={() => {
              preorderScheduleActions.changeCurrentMonth("next");
              this.setState({ selected: null });
            }}
          >
            下个月
          </Button>
        </div>
      </Popup>
    );
  }
}
