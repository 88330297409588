import React from "react";
import "./customer-feedback.scss";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";
import { getParamValue } from "../../utils/url";
import { BangBangTangLayout } from "../../layout/layout";
import { ArriveShopConfirm } from "./feedback-page/arrive-shop-confirm";
import { RetrieveItem } from "./feedback-page/retrieve-item";
import { FilmSelectConfirm } from "./feedback-page/film-select-confirm";
import { toast } from "react-toastify";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";

export type CustomerFeedbackType =
  | "arrive-shop-confirm"
  | "retrieve-item"
  | "film-select-confirm";
const customerFeedbackType: CustomerFeedbackType[] = [
  "arrive-shop-confirm",
  "retrieve-item",
  "film-select-confirm",
];
interface Props {}
interface State {
  pageType: CustomerFeedbackType | null;
  feedback_id: number;
}

@observer
export class CustomerFeedback extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { pageType: null, feedback_id: 0 };
  }

  componentDidMount() {
    const type = getParamValue("type");
    const feedback_id = getParamValue("feedback_id");
    if (
      type &&
      feedback_id &&
      customerFeedbackType.find((value) => value === type)
    ) {
      appIsFirstReadySubject.subscribe(() => {
        this.setState({
          pageType: type as CustomerFeedbackType,
          feedback_id: parseInt(feedback_id, 10),
        });
      });
    } else {
      toast("未声明回馈类型");
      store.routerStore.replace("/build");
    }
  }

  renderTitle = () => {
    switch (this.state.pageType) {
      case "film-select-confirm":
        return "选片确认提醒";
      case "arrive-shop-confirm":
        return "到店确认提醒";
      case "retrieve-item":
        return "取件提醒";
    }
  };

  render() {
    return (
      <BangBangTangLayout>
        <div className="customer-feedback">
          <div className="page-header">{this.renderTitle()}</div>
          <div className="divider" />
          {this.renderComponent()}
        </div>
      </BangBangTangLayout>
    );
  }

  private readonly renderComponent = () => {
    switch (this.state.pageType) {
      case "film-select-confirm":
        return <FilmSelectConfirm feedback_id={this.state.feedback_id} />;
      case "arrive-shop-confirm":
        return <ArriveShopConfirm feedback_id={this.state.feedback_id} />;
      case "retrieve-item":
        return <RetrieveItem feedback_id={this.state.feedback_id} />;
    }
  };
}
