import React from "react";
import "./footer-buttons.scss";
import { Button } from "zarm";

export class FooterButtons extends React.Component<
  {
    buttons: {
      label: string;
      onClick: () => any;
      background?: string;
      color?: string;
      disabled?: boolean;
      hidden?: boolean;
    }[];
  },
  any
> {
  render() {
    if (this.props.buttons.filter((value) => !value.hidden).length === 0) {
      return null;
    }
    return (
      <div className="footer-buttons">
        {this.props.buttons
          .filter((value) => !value.hidden)
          .map((value, index, array) => {
            return (
              <Button
                onClick={value.onClick}
                style={{
                  color: value.color || "black",
                  background: value.background || "white",
                }}
                key={value.label}
                disabled={value.disabled}
              >
                <p>{value.label}</p>
              </Button>
            );
          })}
      </div>
    );
  }
}
