import React from "react";
import { BangBangTangLayout } from "../../layout/layout";
import style from "./index.module.scss";
import { ImagePreview, Button, Modal, Picker, Cell, Collapse, Checkbox, Input, Icon, FilePicker, Stepper, DateSelect, Toast, SearchBar, Radio } from "zarm";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";
import { finalize } from "rxjs/operators";
import { RuleListRequired } from "../../components/module-required/rule-list-required";
import { type } from "os";
import addPicture from "../../assets/add-picture.png";
import OSS from "ali-oss";

export class DrawRectification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OssConfig: {},
      reformVisible: false,
      reformIndex: 0,
      typeVisible: false,
      typeValue: '',
      typeLabel: '',
      typeDataSource: [],
      searchKey: '',
      selectId: '',
      enforcerVisible: false,
      enforcerValue: '',
      enforcerLabel: '',
      enforcerDateSource: [],
      enforcerDateSearch: [],
      activeKey: '',
      related: [],
      relatedList: [],
      specificReasons: '',
      reform_image: [],//上传数据用
      reform_images: [],//浏览图片用
      integralValue: 0,
      moneyValue: 0,
      reform: false,
      date: '',
      result: "",
      resultModal: false,
      confirmModal: false,
    };
  }

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
  };

  componentDidMount() {
    this.getType();
    this.getLitigant();
    this.getOssConfig();
    window.document.title = "开工作单";
  }

  getType = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getType",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        award_type: 4,
        store_id: localStorage.getItem("admin_store_id"),

      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        let typeDate = result
        for (let k in typeDate) {
          typeDate[k].value = typeDate[k].id
          typeDate[k].label = typeDate[k].award_name
        }
        that.setState({
          typeDataSource: typeDate
        });
      });
  };

  getLitigant = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getLitigant",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        that.setState({
          enforcerDateSource: result,
          enforcerDateSearch: result || []
        });
      });
  };

  getOssConfig = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getOssConfig",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        that.setState({
          OssConfig: result,
        });
      });
  };

  getRelated = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getRelated",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        admin_id: that.state.enforcerValue,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        if (result.length > 0) {
          that.setState({
            activeKey: '1',
            related: result
          })
        } else {
          that.setState({
            activeKey: '',
            related: result
          })
        }

      });
  };

  setSearchKey = (value) => {
    this.setState({
      searchKey: value,
    });
  };

  handlerSearch = () => {
    const { enforcerDateSource, searchKey } = this.state;
    let enforcerDateSearch = [];
    if (!searchKey) {
      enforcerDateSearch = enforcerDateSource;
    } else {
      for (let i = 0; i < enforcerDateSource.length; i++) {
        if (enforcerDateSource[i].nickname.indexOf(searchKey) != -1) {
          enforcerDateSearch.push(enforcerDateSource[i]);
        }
      }
    }
    this.setState({
      enforcerDateSearch,
      searchKey: "",
    });
  };

  selectEnforcer = (id) => {
    this.setState({
      selectId: id
    })
  };

  changeEnforcer = () => {
    const { enforcerDateSource } = this.state
    for (let i in enforcerDateSource) {
      if (enforcerDateSource[i].id == this.state.selectId) {
        this.setState({
          enforcerValue: enforcerDateSource[i].id,
          enforcerLabel: enforcerDateSource[i].nickname
        },()=>{
          this.setEnforcer(false);
          this.getRelated()
        })
      }
    }

  }

  showConfirmModal = () => {
    this.setState({
      confirmModal: true,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      confirmModal: false,
    });
  };

  showResult = (result) => {
    this.setState({
      result: result,
      resultModal: true,
    });
  };

  closeResultModal = () => {
    this.setState({
      result: "",
      resultModal: false,
    });
  };

  setType = (value) => {
    this.setState({
      typeVisible: value
    })
  }

  setEnforcer = (value) => {
    this.setState({
      enforcerVisible: value
    })
  }

  setActiveKey = (activeKey) => {
    this.setState({
      activeKey
    })
  }

  changeSpecificReasons = (value) => {
    this.setState({
      specificReasons: value
    })
  }

  reformShow = (index) => {
    this.setState({
      reformVisible: true,
      reformIndex: index,
    });
  };

  reformHide = () => {
    this.setState({
      reformVisible: false,
    });
  };

  deleteImg = (index, e) => {
    e.stopPropagation();
    let { reform_image, reform_images } = this.state;
    reform_images.splice(index, 1);
    reform_image.splice(index, 1);
    this.setState({
      reform_images,
      reform_image,
    })
  }

  onSelect = (file) => {
    //console.log(file)
    if (this.canUpload(file.file)) {
      this.transformFile(file.file).then((res) => {
        // console.log(res)
        this.upload(file.fileName, res);
      });
    }
  };

  canUpload = (file) => {
    const reg = /image/;
    const isImage = reg.test(file.type);
    if (!isImage) {
      Toast.show("请上传图片文件");
    }
    return isImage;
  };

  transformFile = (file) => {
    //判断是否是图片类型
    // if (this.canUpload(file)) {
    const pictureQuality = 0.92;
    //判断浏览器内核是否支持base64图片压缩
    if (typeof FileReader === "undefined") {
      return file;
    } else {
      try {
        return new Promise((resolve) => {
          //声明FileReader文件读取对象
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            // 生成canvas画布
            const canvas = document.createElement("canvas");
            // 生成img
            const img = document.createElement("img");
            img.src = reader.result;
            img.onload = () => {
              const ctx = canvas.getContext("2d");
              //原始图片宽度、高度
              let originImageWidth = img.width,
                originImageHeight = img.height;
              //默认最大尺度的尺寸限制在（600 * 600）
              let maxWidth = 600,
                maxHeight = 600,
                ratio = maxWidth / maxHeight;
              //目标尺寸
              let targetWidth = originImageWidth,
                targetHeight = originImageHeight;
              //当图片的宽度或者高度大于指定的最大宽度或者最大高度时,进行缩放图片
              if (
                originImageWidth > maxWidth ||
                originImageHeight > maxHeight
              ) {
                //超过最大宽高比例
                if (originImageWidth / originImageHeight > ratio) {
                  //宽度取最大宽度值maxWidth,缩放高度
                  targetWidth = maxWidth;
                  targetHeight = Math.round(
                    maxWidth * (originImageHeight / originImageWidth)
                  );
                } else {
                  //高度取最大高度值maxHeight,缩放宽度
                  targetHeight = maxHeight;
                  targetWidth = Math.round(
                    maxHeight * (originImageWidth / originImageHeight)
                  );
                }
              }
              // canvas对图片进行缩放
              canvas.width = targetWidth;
              canvas.height = targetHeight;
              // 清除画布
              ctx.clearRect(0, 0, targetWidth, targetHeight);
              // 绘制图片
              ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
              // quality值越小,图像越模糊,默认图片质量为0.92
              const imageDataURL = canvas.toDataURL(
                file.type || "image/jpeg",
                pictureQuality
              );
              // 去掉URL的头,并转换为byte
              const imageBytes = window.atob(imageDataURL.split(",")[1]);
              // 处理异常,将ascii码小于0的转换为大于0
              const arrayBuffer = new ArrayBuffer(imageBytes.length);
              const uint8Array = new Uint8Array(arrayBuffer);
              for (let i = 0; i < imageBytes.length; i++) {
                uint8Array[i] = imageBytes.charCodeAt(i);
              }
              let mimeType = imageDataURL.split(",")[0].match(/:(.*?);/)[1];
              let newFile = new File([uint8Array], file.name, {
                type: mimeType || "image/jpeg",
              });
              // console.log('after compress, the file size is : ', (newFile.size / 1024 / 1024) + "M");
              resolve(newFile);
            };
          };
          reader.onerror = () => {
            return file;
          };
        })
          .then((res) => {
            return res;
          })
          .catch(() => {
            return file;
          });
      } catch (e) {
        //压缩出错,直接返回原file对象
        return file;
      }
    }
    // } else {
    //     //非图片文件,不进行压缩,直接返回原file对象
    //     return file;
    // }
  };

  upload = async (fileName, lrzFile) => {
    const { OssConfig } = this.state;
    let client = new OSS({
      endpoint: "https://" + OssConfig.endpoint, //本地使用"http://",线上使用"https://",地域（在创建 Bucket 的时候指定的中心位置），这里可能不知道具体地域怎么填其实就是 oss-cn-中心位置 ，例：region:'oss-cn-chengdu'，chengdu则是创建bucket是指定的位置成都。
      accessKeyId: OssConfig.app_id, //阿里云产品的通用id
      accessKeySecret: OssConfig.app_key, //密钥
      bucket: OssConfig.bucket, //OSS 存储区域名
    });
    const today = this.timestampToTime();
    const uploadFileName = this.random_20();
    const suffix = fileName.substring(fileName.lastIndexOf(".") + 1);
    const objectName =
      "uploads/" +
      localStorage.getItem("admin_store_id") +
      "/img/sanction/" +
      today +
      "/" +
      uploadFileName +
      "." +
      suffix;
    // console.log(objectName);
    try {
      let result = await client.put(objectName, lrzFile);
      // console.log(result);
      let imageList = this.state.reform_images;
      let reform_image = this.state.reform_image;
      reform_image.push("/" + objectName);
      imageList.push(result.url);
      // console.log(reform_image);
      this.setState({
        reform_images: imageList,
        reform_image,
      });
    } catch (err) {
      console.log(err);
    }
  };

  timestampToTime = () => {
    var date = new Date(); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear();
    var M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var D = date.getDate();
    return Y + M + D;
  };

  random_20 = () => {
    var data = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
    ];
    var result = "";
    for (var i = 0; i < 20; i++) {
      const r = Math.floor(Math.random() * 16);
      result += data[r];
    }
    return result;
  };

  setIntegralValue = (value) => {
    // console.log(value);
    this.setState({
      integralValue: value
    })
  }

  setMoneyValue = (value) => {
    // console.log(value);
    this.setState({
      moneyValue: value
    })
  }

  changeReform = () => {
    this.setState({
      reform: !this.state.reform
    })
  }

  setDate = (date) => {
    // console.log(this.transDate(date))
    this.setState({
      date: this.transDate(date)
    })
  }

  transDate = (date) => {
    const d = new Date(date) || new Date();
    const resDate = d.getFullYear() + '-' + this.p((d.getMonth() + 1)) + '-' + this.p(d.getDate());
    // const resTime = this.p(d.getHours()) + ':' + this.p(d.getMinutes()) + ':' + this.p(d.getSeconds());
    return resDate
  }

  p = (s) => {
    return s < 10 ? "0" + s : s;
  };

  submit = () => {
    let that = this;
    let params = {
      token: localStorage.getItem("token"),
      type_id: that.state.typeValue,
      admin_id: that.state.enforcerValue,
      reason: that.state.specificReasons,
      images: that.state.reform_image,
      integral: that.state.integralValue,
      money: that.state.moneyValue,
      store_id: localStorage.getItem("admin_store_id"),
    }
    // console.log(that.state.relatedList)
    if (that.state.relatedList.length > 0) {
      let relatedList = that.state.relatedList
      let related = ""
      for (let i = 0; i < relatedList.length; i++) {
        if (i == 0) {
          related = relatedList[i]
        } else {
          related += ',' + relatedList[i]
        }

      }
      params.related = related
    }
    if (that.state.reform == true) {
      if (that.state.date) {
        params.is_reform = 1;
        params.overdue = that.state.date;
      } else {
        this.closeConfirmModal();
        Toast.show("请填写逾期时间");
        return
      }
    }
    console.log('params', params);
    BangBangTangRequest({
      url: "/wap/sanction/submitReform",
      method: "POST",
      data: params,

    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        if (result == "开单成功") {
          this.closeConfirmModal();
          that.setState({
            result,
            resultModal: true,
          }, () => {
            setTimeout(() => {
              this.props.history.goBack()
            }, 2000);
          });
        } else {
          this.closeConfirmModal();
          that.setState({
            result,
            resultModal: true,
          });
        }
      });
  };

  render() {
    const {
      reformVisible,
      reformIndex,
      typeVisible,
      typeValue,
      typeLabel,
      typeDataSource,
      enforcerVisible,
      enforcerValue,
      enforcerLabel,
      enforcerDateSearch,
      activeKey,
      related,
      specificReasons,
      reform_images,
      integralValue,
      moneyValue,
      reform,
      date,
      resultModal,
      confirmModal,
      result,
    } = this.state;
    return (
      <BangBangTangLayout title={"开工作单"}>
        <div className={style.container}>
          <Cell hasArrow title="工单类别" description={typeLabel} onClick={() => this.setType(true)} />
          <Cell hasArrow title="执行人" description={enforcerLabel} onClick={() => this.setEnforcer(true)} />
          {related.length > 0 ?
            <Collapse
              className="related"
              animated='true'
              activeKey={activeKey}
              onChange={(activeKey) => { this.setActiveKey(activeKey) }}
            >
              <Collapse.Item key="1" title="连带处罚人">
                <Checkbox.Group
                  type="cell"
                  onChange={(value) => {
                    // console.log(value);
                    this.setState({
                      relatedList: value
                    })
                  }}
                >
                  {related.map((item, index) => {
                    return (
                      <Checkbox className="related-list" value={item.id} key={index}>{item.nickname}</Checkbox>
                    )
                  })}
                </Checkbox.Group>
              </Collapse.Item>
            </Collapse>
            : null}
          <Cell title="具体事由">
            <Input
              type="text"
              name="specific-reasons"
              value={specificReasons}
              maxLength={800}
              autoHeight
              rows={4}
              showLength
              placeholder="请输入具体事由"
              onChange={(value) => this.changeSpecificReasons(value)}
            />
          </Cell>
          <div className="reform_images">事由图片</div>
          <div className="photo-list">
            {reform_images.map((pic, index) => (
              <div
                className="picture-item"
                onClick={() => this.reformShow(index)}
                key={+index}
              >
                <img src={pic} />
                <Icon className="delete-btn" type="wrong-round-fill" size="md" onClick={(e) => { this.deleteImg(index, e) }} />
              </div>
            ))}
            {reform_images.length < 3 ? (
              <FilePicker
                className="file-picker-btn"
                accept="image/*"
                onChange={this.onSelect}
              >
                <img src={addPicture} />
              </FilePicker>
            ) : null}
          </div>

          {reform_images.length > 0 ? (
            <ImagePreview
              visible={reformVisible}
              images={reform_images}
              onClose={this.reformHide}
              activeIndex={reformIndex}
            />
          ) : null}

          <Cell
            title="处罚积分"
            description={
              <Stepper
                value={integralValue}
                min={0}
                onChange={this.setIntegralValue}
                onInputChange={(value) => {
                  // console.log('onInputChange:', value.replace(/\b(0+)/gi,""));
                  this.setIntegralValue(value.replace(/\b(0+)/gi, ""))
                }}
              />
            }
          />
          <Cell
            title="处罚金额"
            description={
              <Stepper
                value={moneyValue}
                min={0}
                onChange={this.setMoneyValue}
                onInputChange={(value) => {
                  // console.log('onInputChange:', value.replace(/\b(0+)/gi,""));
                  this.setMoneyValue(value.replace(/\b(0+)/gi, ""))
                }}
              />
            }
          />
          <Cell
            title="需要整改"
            description={
              <Checkbox className="reform" checked={reform} onClick={this.changeReform} ></Checkbox>
            }
          />
          {reform ?
            <Cell
              title="逾期时间"
              description={
                <DateSelect
                  className="test-dateSelect"
                  title="选择日期"
                  placeholder="请选择日期"
                  mode="date"
                  min="2000-01-01"
                  max="2040-12-31"
                  value={date}
                  onOk={(value) => {
                    // console.log('DateSelect onOk: ', value);
                    this.setDate(value);
                  }}
                />
              }
            />
            : null}

          <div className="foot-option">
            <div className="button" onClick={this.showConfirmModal}>
              确认开工作单
            </div>
          </div>
        </div>

        <Picker
          visible={typeVisible}
          value={typeValue}
          dataSource={typeDataSource}
          onOk={(selected) => {
            console.log('Single Picker onOk: ', selected);
            // Toast.show(JSON.stringify(selected));
            this.setState({
              typeValue: selected.map((item) => item.value),
              typeLabel: selected.map((item) => item.label)
            }, () => {
              this.setType(false);
            })
          }}
          onCancel={() => this.setType(false)}
        />

        {/* <Picker
          visible={enforcerVisible}
          value={enforcerValue}
          dataSource={enforcerDateSource}
          onOk={(selected) => {
            // console.log('Single Picker onOk: ', selected);
            // Toast.show(JSON.stringify(selected));
            this.setState({
              enforcerValue: selected.map((item) => item.value),
              enforcerLabel: selected.map((item) => item.label),
            }, () => {
              this.setEnforcer(false);
              this.getRelated()
            })
          }}
          onCancel={() => this.setEnforcer(false)}
        /> */}

        <Modal
          visible={enforcerVisible}
          footer={
            <div className={style.progressManBtns}>
              <div className="btn t-c">
                <Button
                  style={{
                    width: "100px",
                    borderColor: "#00bc71",
                    color: "#00bc71",
                  }}
                  size="sm"
                  onClick={() => {
                    this.setState({
                      selectId:''
                    })
                    this.setEnforcer(false)
                  }}
                >
                  取消
                </Button>
              </div>
              <div className="btn t-c">
                <Button
                  style={{ width: "100px" }}
                  theme="primary"
                  size="sm"
                  onClick={() => { this.changeEnforcer() }
                  }
                >
                  确认
                </Button>
              </div>
            </div>
          }
        >
          <div className={style.selectProgressMan}>
            <SearchBar
              showCancel
              cancelText="搜索"
              onChange={this.setSearchKey}
              onCancel={this.handlerSearch}
            />
            <div className="select-form">
              <Radio.Group type="cell" onChange={(id) => { this.selectEnforcer(id) }}>
                {enforcerDateSearch &&
                  enforcerDateSearch.map((item, index) => {
                    return (
                      <Radio value={item.id} key={index}>
                        {item.nickname}
                      </Radio>
                    );
                  })}
              </Radio.Group>
            </div>
          </div>
        </Modal>

        <Modal
          className={style.detailModal}
          visible={resultModal}
          footer={
            <Button block className="sure" onClick={this.closeResultModal}>
              知道了
            </Button>
          }
        >
          <div className="t-c">{result}</div>
        </Modal>

        <Modal
          className={style.detailModal}
          visible={confirmModal}
          footer={
            <div className="confirmBtns t-c">
              <div className="btn" onClick={this.closeConfirmModal}>
                取消
              </div>
              <div className="btn" onClick={this.submit}>
                确认
              </div>
            </div>
          }
        >
          <div className="t-c">
            确认开工作单吗？
          </div>
        </Modal>
      </BangBangTangLayout>
    );
  }
}
export default withRouter(DrawRectification);
