import React from "react";
import "./employee-query-preorder-clothe-list.scss";
import { EmployeePreorderClotheListDataInterface } from "../../store/employee-preorder-clothe-list/employee-preorder-clothe-list.interface";
import { format } from "date-fns";
import { store } from "../../store/store.root";
import { PreorderStatusRender } from "../../components/preorder-status-render";
import { PreorderClotheStatusRender } from "../../components/preorder-clothe-status-render";

export class EmployeeQueryPreorderClotheCard extends React.Component<
  { data: EmployeePreorderClotheListDataInterface },
  any
> {
  render() {
    return (
      <div
        className="employee-query-preorder-clothe-list-card"
        onClick={() =>
          store.routerStore.push(
            "employee-preorder-clothe-brief-detail?id=" + this.props.data.id
          )
        }
      >
        {/*<div className="head">*/}
        {/*  <img src={this.props.data.id} alt="" />*/}
        {/*</div>*/}
        {/*<div className="avatar">*/}
        {/*  <img src={this.props.data.user_wechat_avatar} alt="" />*/}
        {/*</div>*/}
        <div className="content">
          <div className="row">
            <span>客户姓名:</span>
            <span>{this.props.data.username}</span>
          </div>
          <div className="row">
            <span>电话</span>
            <span>{this.props.data.mobile}</span>
          </div>
          <div className="row">
            <span>拍摄类型:</span>
            <span>{this.props.data.schedule_type_text}</span>
          </div>
          <div className="row">
            <span>性别:</span>
            <span>{this.props.data.gender_tag_text}</span>
          </div>
          <div className="row">
            <span>拍摄日期:</span>
            <span>{this.props.data.schedule_date_text}</span>
          </div>
          <div className="row">
            <span>年龄标签:</span>
            <span>{this.props.data.age_tag_text}</span>
          </div>
          <div className="row">
            <span>状态:</span>
            <span>
              <PreorderClotheStatusRender value={this.props.data.status} />
            </span>
          </div>
          <div className="row">
            <span>{this.props.data.server_staff}</span>
          </div>
          {/*<div className="tag">{this.props.data.tags_text}</div>*/}
          {/*<div className="tag">*/}
          {/*  <PreorderClotheStatusRender value={this.props.data.status} />*/}
          {/*</div>*/}
        </div>
        <div className="img-container">
          {this.props.data.clothingimages_list?.map((value) => (
            <img src={value} alt="" key={value} />
          ))}
        </div>
        {/*<div className="time">*/}
        {/*  {this.props.data.create_time*/}
        {/*    ? format(this.props.data.create_time * 1000, "yyyy.MM.dd")*/}
        {/*    : ""}*/}
        {/*</div>*/}
      </div>
    );
  }
}
