import React, { useState, useEffect } from 'react'
import { FetchRequest } from '../../utils/fetch'
import style from './index.module.scss'
import { withRouter } from 'react-router-dom'
import { Toast } from 'zarm'

export default withRouter(
  (props: {
    match: {
      params: {
        order: string
      }
    }
  }) => {
    useEffect(() => {
      GetPerssData()
      console.log(props)
    }, [])
    const [data, setdata] = useState<{
      address?: string
      code?: number
      createtime?: number
      expressCode?: String
      expressName?: String
      express_id?: number
      id?: number
      logisticsList?: {
        context: string
        time: string
      }[]
      logo?: string
      msg?: string
      number?: string
      status?: number
      status_text?: string
      updatetime?: number
    }>({})

    const [tipes, setTipes] = useState('')

    async function GetPerssData() {
      let res = await FetchRequest('/wap/marketing/gravidgroup/gravidExpress', {
        token: localStorage.getItem('token'),
        number: props.match.params.order,
      })
      if (res.code === 1) {
        setdata(res.data)
      } else {
        Toast.show(res.msg)
        setTipes(res.msg)
      }
    }

    if (tipes) {
      return <div className={style.Tipes_text}>{tipes}</div>
    }

    return (
      <div>
        <div className={style.warp_top}>{data.status_text}</div>
        <div className={style.express_info}>
          <img src={data.logo} alt="" className={style.logo} />
          <div>{data.expressName}</div>
          <div>{data.number}</div>
        </div>
        <div className={style.Express}>
          <div className={style.Express_item} style={{ color: '#00bc70' }}>
            <div>{data.address}</div>
            <div className={style.time_link_ov}>收</div>
          </div>
          {data.logisticsList?.map((v, index) => {
            let content = v.context
            const reg = /\b([0-9]{7,8}|0[0-9]{10,11}|1[0-9]{10})\b/
            const re = new RegExp(reg)
            const res = re.exec(v.context)
            if (!!res) {
              content = content.replace(
                res[0],
                `<a href="tel:${res[0]}" style="text-decoration: revert;">${res[0]}</a>`
              )
            }
            return (
              <div
                className={style.Express_item}
                key={index}
                style={index == 0 ? { color: '#00bc70' } : {}}
              >
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
                <div>{v.time}</div>
                <div className={style.time_link}>
                  <div className={style.node_time}></div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
)
