import { action, makeAutoObservable, observable } from "mobx";
import { BangBangTangRequest } from "../utils/request";
import { store } from "./store.root";
import { finalize } from "rxjs/operators";

export interface IPhotographicPlate {
  id?: number;
  url?: string;
  username?: string;
  create_time?: number | null;

  lasttime?: string;
  activity_name?: string;
  baby_name?: string;
  data?: Array<{
    images: {
      id: number;
      or_w_img: string;
      origin_img: string;
    }
  }>
}

export class PhotographicPlateStore {
  loading: boolean = false;
  updateTime: Date | null = null;
  data: IPhotographicPlate[] = [];

  startLoading() {
    this.loading = true;
  }

  setUpdated() {
    this.updateTime = new Date();
  }

  hideLoading() {
    this.loading = false;
  }

  update() {
    if (this.updateTime !== null) return;
    this.startLoading();
    BangBangTangRequest({
      url: "/wap/data/getFamilyNegativeData",
      method: "POST",
      data: { token: store.userStore.token },
    })
      .pipe(finalize(() => this.hideLoading()))
      .subscribe(
        (
          value: Array<{
            id?: number;
            url?: string;
            username?: string;
            create_time?: number | null;

            activity_name?: string;
            baby_name?: string;
            data?: Array<{
              images: {
                id: number;
                or_w_img: string;
                origin_img: string;
              }
            }>
          }>
        ) => {
          if (value) {
            this.setData(value);
          } else {
            this.setData([]);
          }
        }
      );
  }

  constructor() {
    makeAutoObservable(this);
  }

  private setData(
    value: Array<{
      id?: number;
      url?: string;
      username?: string;
      create_time?: number | null;

      activity_name?: string;
      baby_name?: string;
      data?: Array<{
        images: {
          id: number;
          or_w_img: string;
          origin_img: string;
        }
      }>
    }>
  ) {
    this.setUpdated();
    this.data = value;
  }
}
