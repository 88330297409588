import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { Cell } from "zarm";
import { store } from "../../store/store.root";

export default (props: any) => {
  useEffect(() => {
    document.title = "服装预选";
  }, []);

  return (
    <div>
      <Cell
        hasArrow
        title="生成预选单"
        onClick={() => {
          store.routerStore.push("/build/employee-preorder-clothe-detail");
        }}
      />
      <Cell
        hasArrow
        title="预选单管理"
        onClick={() => {
          store.routerStore.push("/build/employee-query-preorder-clothe-list");
        }}
      />
    </div>
  );
};
