import React from 'react'
import './employee-preorder-clothe.scss'
import { Button, Modal, Popup, Toast } from 'zarm'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { store } from '../../store/store.root'
import { toJS } from 'mobx'
export type PreorderClotheTagModalOptions = {
  id: number
  name: string
  is_user_search: 'yes' | 'no'
  selection_is_required?: 'yes' | 'no'
  types: { id: number; name: string; is_show: 'yes' | 'no' }[]
}[]

@observer
export class PreorderClotheTagModal extends React.Component<
  {
    visible: boolean
    onCancel: () => void
    options: PreorderClotheTagModalOptions // 弹窗显示的标签列表
    onTypeCheck: (value: number) => void
    onGenderCheck: (value: number) => void
    onClotheTypeCheck: (value: number) => void
    onColorTypeCheck: (value: number) => void
    onStyleTypeCheck: (value: number) => void
    onRegionTypeCheck: (value: number) => void
    selected_options: Set<number> // 用户选择的标签id
    onClear: () => void
    onSave: () => void
    saveButtonDisabled: boolean
    option_filtered?: string[]
  },
  {}
> {
  private filtered_map: Set<string> = new Set()
  componentDidMount() {
    if (this.props.option_filtered) {
      this.props.option_filtered.forEach((name) => this.filtered_map.add(name))
    }
  }

  render() {
    return (
      <Popup
        visible={this.props.visible}
        direction={'bottom'}
        onMaskClick={this.props.onCancel}
      >
        <div className="employee-preorder-clothe-popup">
          <div className="tag-container">
            {this.props.options
              .filter((value) => {
                if (this.filtered_map.size === 0) {
                  return true
                } else {
                  return this.filtered_map.has(value.name)
                }
              })
              .map((value) => {
                if (value.is_user_search === 'no') {
                  return null
                }
                return (
                  <div key={value.id} className="tag-row">
                    {value.name !== '' && (
                      <div className="title">{value.name}:</div>
                    )}
                    <div className="button-row">
                      {value.types.map((type) => {
                        return (
                          <div
                            key={type.id}
                            className={classNames({
                              button: true,
                              active: this.props.selected_options.has(type.id),
                            })}
                            onClick={() => {
                              switch (value.name) {
                                case '年龄':
                                  this.props.onTypeCheck(type.id)
                                  break
                                case '性别':
                                  this.props.onGenderCheck(type.id)
                                  break
                                case '客户可见区域':
                                  this.props.onRegionTypeCheck(type.id)
                                  break
                                case '尺码':
                                  this.props.onClotheTypeCheck(type.id)
                                  break
                                case '色系':
                                  this.props.onColorTypeCheck(type.id)
                                  break
                                case '风格':
                                  this.props.onStyleTypeCheck(type.id)
                                  break

                                default:
                                  Toast.show('不知道名字的标签')
                              }
                            }}
                          >
                            {type.name}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
          </div>

          <div className="submit-row">
            <Button onClick={this.props.onClear}>清空</Button>
            <Button
              onClick={this.props.onSave}
              theme="primary"
              disabled={this.props.saveButtonDisabled}
            >
              保存
            </Button>
          </div>
        </div>
      </Popup>
    )
  }
}
