import React, { useEffect, useState } from "react";
import "./preorder-detail-from-employee.scss";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { MyPreorderDetailInterface } from "../my-preorder/my-preorder.api";
import { Button, Cell, ImagePreview, Modal, Toast } from "zarm";
import { BangBangTangLayout } from "../../layout/layout";
import { FooterButtons } from "../../components/footer-buttons/footer-buttons";
import { PreorderDetailFromEmployeeConfirmModal } from "./preorder-detail-from-employee.confirm-modal";
import { catchError, finalize } from "rxjs/operators";
import { preorderDetailFromEmployeeStore } from "./preorder-detail-from-employee.store";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";
import { PreorderDetailFromEmployeeClothePicker } from "./preorder-detail-from-employee.clothe-picker";
import { PreorderDetailFromEmployeeChangeStatus } from "./preorder-detail-from-employee.change-status";
import { PreorderStatusRender } from "../../components/preorder-status-render";
import { PreorderClotheStatusRender } from "../../components/preorder-clothe-status-render";
import { PreorderDetailFromEmployeeSetPhotographerModal } from "./preorder-detail-from-employee.set-photographer-modal";
import { toJS } from "mobx";

export const PreorderDetailFromEmployee = observer(() => {
  let { id } = useParams<{ id: string }>();
  const [
    displayConfirmModalVisible,
    setDisplayConfirmModalVisible,
  ] = useState<boolean>(false);
  useEffect(() => {
    window.document.title = "预约单详情";
    appIsFirstReadySubject.subscribe(() => {
      if (preorderDetailFromEmployeeStore.photographer_data === null) {
        preorderDetailFromEmployeeStore.update_photographer_data();
      }
      if (
        preorderDetailFromEmployeeStore.sex_type === undefined ||
        preorderDetailFromEmployeeStore.age_type === undefined
      ) {
        preorderDetailFromEmployeeStore.getStandardTagType();
      }
    });
    return () => preorderDetailFromEmployeeStore.clear();
  }, [null]);
  useEffect(() => {
    preorderDetailFromEmployeeStore.requestDataFromServer(id);
  }, [id]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [
    createPreorderClotheModalVisible,
    setCreatePreorderClotheModalVisible,
  ] = useState<boolean>(false);
  const [
    changeStatusModalVisible,
    setChangeStatusModalVisible,
  ] = useState<boolean>(false);
  const [
    changeStatusModalVisibleLoading,
    setChangeStatusModalVisibleLoading,
  ] = useState<boolean>(false);

  const [imagePreview, setImagePreview] = useState<number | null>(null);
  const changeStatus = (value: string) => {
    setChangeStatusModalVisibleLoading(true);
    BangBangTangRequest({
      url: "/wap/schedule/editScheduleBookingProcess",
      data: {
        token: store.userStore.token,
        booking_id: parseInt(id, 10),
        schedule_status:
          value === "全未拍" ? "3" : value === "未拍完" ? "2" : "1",
      },
      method: "POST",
    })
      .pipe(finalize(() => setChangeStatusModalVisibleLoading(false)))
      .subscribe(() => {
        setChangeStatusModalVisible(false);
        preorderDetailFromEmployeeStore.requestDataFromServer(id);
      });
  };
  const data = preorderDetailFromEmployeeStore.data;
  const setData = preorderDetailFromEmployeeStore.setData;
  const changeConfirmPreorder = (value: "已审核" | "未审核") => {
    setConfirmLoading(true);
    BangBangTangRequest({
      url: "/wap/schedule/editScheduleBookingStatus",
      data: {
        token: store.userStore.token,
        booking_id: parseInt(id, 10),
        status: value === "已审核" ? "1" : "0",
      },
      method: "POST",
    })
      .pipe(finalize(() => setConfirmLoading(false)))
      .subscribe(() => {
        setDisplayConfirmModalVisible(false);
        if (value === "已审核") {
          setData({ ...data, status: "已审核" } as any);
          Modal.confirm({
            title: "审核完成",
            content: "是否现在生成预选单?",
            okText: "生成预选",
            onOk: () => setCreatePreorderClotheModalVisible(true),
          });
        }
      });
  };
  const cancelPreorderFromServer = () => {
    BangBangTangRequest({
      url: "/wap/clothing/staffCancelSelection",
      method: "POST",
      data: { token: store.userStore.token, selection_id: data?.selection_id },
    }).subscribe(() => {
      store.routerStore.replace("/build/employee-preorder-list");
    });
  };
  if (imagePreview && data?.standard.find((v) => v.id === imagePreview)) {
    return (
      <>
        <div className="employee-preorder-clothe-brief-detail-img">
          {data?.standard
            ?.find((v) => v.id === imagePreview)!
            .showimages.map((value: any) => (
              <div key={value} className="img">
                <img src={value} alt="" />
              </div>
            ))}
        </div>
        <FooterButtons
          buttons={[
            {
              label: "返回",
              hidden: false,
              color: "white",
              background: "#12C287",
              onClick: () => setImagePreview(null),
            },
          ]}
        />
      </>
    );
  }
  return (
    <BangBangTangLayout title={"预约详情"} maskLoading={!data}>
      <div className="employee-preorder-detail">
        <div className="base-info">
          <div>
            <span>所属门店: </span>
            <span>{data?.store_name}</span>
          </div>
          <div>
            <span>客户手机: </span>
            <span>{data?.mobile}</span>
          </div>
          <div>
            <span>档期类型: </span>
            <span>{data?.type_name}</span>
          </div>
          <div>
            <span>宝宝姓名: </span>
            <span>{data?.baby_name}</span>
          </div>
          <div>
            <span>档期日期: </span>
            <span>{data?.schedule_date}</span>
          </div>
          <div>
            <span>宝宝生日: </span>
            <span>{data?.schedule_date}</span>
          </div>
          <div>
            <span>档期时间: </span>
            <span>{data?.schedule_time}</span>
          </div>
          <div>
            <span>宝宝性别: </span>
            <span>{data?.baby_gender}</span>
          </div>
        </div>
        <div className="base-info">
          <div>
            <span>审核人: </span>
            <span>{data?.auditor}</span>
          </div>
          <div>
            <span>档期状态: </span>
            <span>
              <PreorderStatusRender value={data?.status || ""} />
            </span>
          </div>
          <div>
            <span>摄影师: </span>
            <span>
              {preorderDetailFromEmployeeStore.photographer_data?.photographer
                .filter((v) => {
                  if (!data?.photographer_list) {
                    return false;
                  }
                  return (
                    data?.photographer_list.findIndex(
                      (value: any) => parseInt(value, 10) === v.id
                    ) >= 0
                  );
                })
                .map((v) => v.nickname)
                .join(",")}
            </span>
          </div>
          <div>
            <span>拍摄进度: </span>
            <span>{data?.schedule_status}</span>
          </div>
          <div>
            <span>妆引师: </span>
            <span>
              {preorderDetailFromEmployeeStore.photographer_data?.makeup_guide
                .filter((v) => {
                  if (!data?.makeup_guide_list) {
                    return false;
                  }
                  return (
                    data?.makeup_guide_list.findIndex(
                      (value: any) => parseInt(value, 10) === v.id
                    ) >= 0
                  );
                })
                .map((v) => v.nickname)
                .join(",")}
            </span>
          </div>
          <div>
            <span>预选状态: </span>
            <span>
              <PreorderClotheStatusRender
                value={data?.selection_status || ""}
              />
            </span>
          </div>
        </div>
        {data?.user_tag && data?.user_tag.length > 0 ? (
          <div className="tag-container">
            {data?.user_tag?.map((value) => (
              <span
                className="tag"
                style={{
                  color: value.tag_color,
                  borderColor: value.tag_color,
                }}
                key={value.tag_name}
              >
                {value.tag_name}
              </span>
            ))}
          </div>
        ) : null}
        {data?.selection_types && data?.selection_types.length > 0 ? (
          <>
            <div className={"divider"} />
            <div className="selection_info">
              <p>最大预选服装数: {data?.standard_num_limit}</p>
              <div className="tag-container">
                {data?.selection_types.map((value) => (
                  <span
                    className="tag"
                    style={{
                      borderColor: "black",
                    }}
                    key={value.name}
                  >
                    {value.name}
                  </span>
                ))}
              </div>
            </div>
          </>
        ) : null}
        {data?.standard && data.standard.length > 0 ? (
          <>
            <div className={"divider"} />
            <div className="standard-image-container">
              {data.standard.map((v) => (
                <div
                  key={v.id}
                  className="row"
                  onClick={() => {
                    setImagePreview(v.id);
                  }}
                >
                  <div>
                    <img alt="" src={v.showimages[0]} />
                  </div>
                  <div className="tags">
                    {v.costume?.map((costume) => (
                      <span key={costume.id}>
                        {costume.material_cat_name}:{costume.storehouse_number}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
        <FooterButtons
          buttons={[
            {
              color: "red",
              hidden: !(
                data?.status === "未审核" &&
                store.websiteRuleStore.has_rule("admin_get_booking")
              ),
              onClick: () =>
                Modal.confirm({
                  title: "提示",
                  content: "是否审核预约单？",
                  okText: "审核",
                  onOk: () => changeConfirmPreorder("已审核"),
                }),
              label: "审核预约单",
            },
            {
              color: "#349FEB",
              hidden: !(
                data?.status === "已审核" &&
                !data?.selection_id &&
                store.websiteRuleStore.has_rule("edit_clothing_selection")
              ),
              onClick: () => setCreatePreorderClotheModalVisible(true),
              label: "生成预选单",
            },
            {
              color: "#98aefc",
              hidden: !(
                data?.status === "已审核" &&
                data?.selection_id &&
                data?.selection_status !== "committed" &&
                store.websiteRuleStore.has_rule("schedule_center")
              ),
              onClick: () => {
                preorderDetailFromEmployeeStore.change_set_photographer_modal_visible(
                  "photographer"
                );
              },
              label: "分配",
            },
            {
              color: "#309EEB",
              hidden: !(
                data?.status === "已审核" &&
                data?.selection_id &&
                data?.selection_status !== "committed" &&
                store.websiteRuleStore.has_rule("edit_clothing_selection")
              ),
              onClick: () => setCreatePreorderClotheModalVisible(true),
              label: "编辑预选单",
            },
            {
              color: "#12C287",
              hidden: !store.websiteRuleStore.has_rule("edit_booking_status"),
              onClick: () => setChangeStatusModalVisible(true),
              label: "修改拍摄进度",
            },
          ]}
        />
      </div>
      <PreorderDetailFromEmployeeConfirmModal
        visible={displayConfirmModalVisible}
        onClick={(value) => changeConfirmPreorder(value)}
        loading={confirmLoading}
        onCancel={() => setDisplayConfirmModalVisible(false)}
      />
      <PreorderDetailFromEmployeeChangeStatus
        visible={changeStatusModalVisible}
        onClick={(value) => changeStatus(value)}
        loading={changeStatusModalVisibleLoading}
        onCancel={() => setChangeStatusModalVisible(false)}
      />
      {preorderDetailFromEmployeeStore.set_photographer_modal_visible ===
      "none" ? null : (
        <PreorderDetailFromEmployeeSetPhotographerModal />
      )}
      {createPreorderClotheModalVisible ? (
        <PreorderDetailFromEmployeeClothePicker
          date={data?.shooting_date}
          id={parseInt(id, 10)}
          selection_id={data?.selection_id}
          visible={createPreorderClotheModalVisible}
          limit={data?.standard_num_limit}
          selected={data?.selection_types?.map((v) => v.id)}
          onCancel={() => setCreatePreorderClotheModalVisible(false)}
        />
      ) : null}
    </BangBangTangLayout>
  );
});
