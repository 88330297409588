import React from 'react'
import './my-info.css'
import { store } from '../../store/store.root'
import { observer, PropTypes } from 'mobx-react'
import { ActivityIndicator, Button, Cell, Icon, Loading, Toast } from 'zarm'
import { BangBangTangLayout } from '../../layout/layout'
import { withRouter } from 'react-router-dom'
import { ModuleRequired } from '../../components/module-required/module-required'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'
import { FetchRequest } from '../../utils/fetch'
import { BangBangTangRequest } from '../../utils/request'
import { AliIcon } from '../../layout/bangbangtang-tab-bar'
import YJImages from '../../assets/yj.png'
import { regexpURL } from '../../utils/regex'
import SetImage from '../../assets/set.png'

// import wx from 'weixin-js-sdk'

@observer
class MyInfo extends React.Component<any, any> {
  state = {
    authCode: 1,
    alertShop: false,
    isSaleman: 0,
  }

  componentDidMount() {
    localStorage.removeItem('logon_size_s')
    this.setState({
      isSaleman:
        store.userStore.is_saleman || localStorage.getItem('is_saleman'),
    })
    appIsFirstReadySubject.subscribe(() => {
      store.globalUiStore.changeCurrentActive('my-info')
      window.document.title = '个人中心'
      this.props
    })
    /////
    if (store.userStore.username === '微信用户') {
      /// 授权
      store.userStore.changeToken('')
      localStorage.removeItem('token')
      location.reload()
    }
  }

  /**
   * 扫码
   * 销核订单
   * 后台登录
   */
  handleClickScanCode = async () => {
    // 加载提示
    Loading.show({
      content: <ActivityIndicator size="lg" />,
    })
    // 调用获取扫码配置参数
    let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
      currenturl: window.location.href,
    })
    if (SDKParams.code === 1) {
      const { wx }: any = window

      // 扫码调用
      const {
        appId,
        nonceStr,
        signature,
        timestamp,
        jsApiList,
      } = SDKParams.data
      // 校验参数
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList, // 必填，需要使用的JS接口列表
      })
      // 获取校验结果
      wx.ready(() => {
        console.log('校验成功')
        Loading.hide()
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
          success: async (res: any) => {
            let result = res.resultStr
            let codeArray = String(result).split(',')
            const r = result.match(regexpURL)
            if (r) {
              if (result.indexOf(location.host) > -1) {
                location.href = result
              } else {
                Toast.show('二维码与当前商户不匹配')
              }
            } else {
              Toast.show(result)
            }
            if (codeArray.length != 2) {
              Toast.show('字符串异常：' + result)
            } else {
              // 正常
              // 优惠券 coupon,XXXX
              // 登录 adminlogin,xxxxxxxx
              switch (codeArray[0]) {
                case 'coupon':
                  this.props.history.push(
                    `/build/verification-voucher/${codeArray[1]}`
                  )
                  break

                case 'adminlogin':
                  Toast.show('后台登录,暂未实现')
                  break

                default:
                  Toast.show('扫码结果不符合匹配项')
                  break
              }
            }
          },
        })
      })
      wx.error(function (res: any) {
        console.log('校验失败:', res)
      })
    } else {
      Toast.show('jsSdk获取失败')
    }
  }

  render() {
    const { isSaleman } = this.state
    return (
      <BangBangTangLayout showTabBar={true}>
        <div className="my-info">
          <div className="page-header">
            <img src={store.userStore.avatar} alt="" className="avatar" />
          </div>
          <div className="user-Title">
            <span className="username">{store.userStore.username}</span>
            <Button
              onClick={this.handleClickScanCode}
              theme="primary"
              size="xs"
            >
              扫码
            </Button>
          </div>
          {/* 会员描述 */}
          <div className="vip_info">
            <p>
              绑定号码: {store.userStore.mobile}{' '}
              <span
                className="iconfont"
                style={{ color: '#1e2225', fontSize: '1.5em' }}
                onClick={() =>
                  this.props.history.push('/build/register?type=change')
                }
              >
                &#xe6f6;
              </span>
            </p>
            <p>加入时间: {store.userStore.create_time}</p>
          </div>
          {/* <VipCard /> */}

          <div className="divider" />
          {/* <ModuleRequired name={"film-selection"}>
            <Cell
              hasArrow
              title={"环创笑脸墙"}
              className="cell"
              icon={<span className="iconfont">&#xe656;</span>}
              onClick={() =>
                this.props.history.push("/build/smiling-face")
              }
            />
          </ModuleRequired>
          <Cell
            hasArrow
            title={"底片下载"}
            className="cell"
            icon={<span className="iconfont">&#xe609;</span>}
            onClick={() =>
              this.props.history.push("/build/film-download")
            }
          />
          <Cell
            hasArrow
            title={"亲情号码"}
            className="cell"
            icon={<span className="iconfont">&#xe60e;</span>}
            onClick={() =>
              this.props.history.push("/build/close-relationship-number")
            }
          />
          <ModuleRequired name={"genealogy"}>
            <Cell
              hasArrow
              title={"影像家谱"}
              className="cell"
              onClick={() =>
                this.props.history.push("/build/photo-family-collection")
              }
              icon={<span className="iconfont">&#xe662;</span>}
            />
          </ModuleRequired>
          <ModuleRequired name={"schedule"}>
            <Cell
              title={"档期预约"}
              hasArrow
              onClick={() => this.props.history.push("/build/my-preorder")}
              className="cell"
              icon={<span className="iconfont">&#xe77d;</span>}
            />
          </ModuleRequired> */}
          <ModuleRequired name="clothing">
            <Cell
              hasArrow
              title="服装预选"
              icon={<span className="iconfont">&#xe60c;</span>}
              className="cell"
              onClick={() => {
                store.routerStore.push(
                  '/build/customer-query-preorder-clothe-list'
                )
              }}
            />
          </ModuleRequired>
          {Number(isSaleman) == 1 ? (
            <>
              <Cell
                hasArrow
                title="我的分销"
                icon={<AliIcon type="iconfenxiao" className="iconfont" />}
                className="cell"
                onClick={() => {
                  // store.routerStore.push('/build/my-distribution')
                  store.routerStore.push('/build/my-settlement/list')
                }}
              />
            </>
          ) : null}
          <ModuleRequired name="marekting-red_packet">
            <Cell
              hasArrow
              title="我的佣金"
              icon={
                // <AliIcon type="icon-gouwuche_yongjin" className="iconfont" />
                <img src={YJImages} alt="" style={{ width: 26 }} />
              }
              className="cell"
              onClick={() => {
                store.routerStore.push('/build/my/commission')
              }}
            />
          </ModuleRequired>
          <Cell
            hasArrow
            title="我的卡券"
            icon={<AliIcon type="iconqiaquan" className="iconfont" />}
            className="cell"
            onClick={() => {
              store.routerStore.push('/build/my-ticket')
            }}
          />
          <ModuleRequired name="schedule">
            <Cell
              hasArrow
              title="档期预约"
              icon={<span className="iconfont">&#xe656;</span>}
              className="cell"
              onClick={() => {
                store.routerStore.push('/build/schedule-appointment/list')
              }}
            />
          </ModuleRequired>

          {/* <Cell
            hasArrow
            title="积分管理"
            icon={<span className="iconfont">&#xe656;</span>}
            className="cell"
            onClick={() => {
              store.routerStore.push('/build/integral-man')
            }}
          /> */}
          <Cell
            hasArrow
            title="编辑个人信息"
            icon={<img src={SetImage} alt="" style={{ width: 25 }} />}
            className="cell"
            onClick={() => {
              store.routerStore.push('/build/update/userinfo')
            }}
          />
          <Cell
            hasArrow
            title="退出登陆"
            icon={<span className="iconfont">&#xe715;</span>}
            className="cell"
            onClick={() => {
              store.userStore.changeToken('')
              localStorage.removeItem('token')
              localStorage.removeItem('need_info_expiretime')
              window.close()
            }}
          />
        </div>
      </BangBangTangLayout>
    )
  }
}

// @ts-ignore
export default withRouter(MyInfo)
