import React, { useState, useImperativeHandle, forwardRef } from "react";
import { useMount } from "@umijs/hooks";
import { Button, Icon, Toast } from "zarm"
import style from "./index.module.scss";
import { BangBangTangRequest } from "../../utils/request";

const Menu = (
  props: {
    selectImgList: (param: string) => void;
    bookingID: string;
    oosURL: string;
    maxClothing: string | null;
    ref: any;
  },
  ref: any
) => {
  const { selectImgList, bookingID, oosURL, maxClothing } = props;

  const [isSearch, setisSearch] = useState(false);

  const [isImgList, setisImgList] = useState(false);

  const [mask, setMask] = useState(false);

  const [listImg, setlistImg] = useState<any>([]);

  const [TagList, setTagList] = useState<any>([]);

  const [selectCount, setselectCount] = useState<any>([]);

  const [tagParam, settagParam] = useState<any>([]);

  // 初始化数据
  useMount(() => {
    // 获取分类标签
    getClassifyTag();
    // 获取用户已选服装
    getSelectedStandardList();
  });

  /**
   * 父组件调用
   */
  useImperativeHandle(ref, () => {
    return {
      _childFn() {
        // 刷新
        getSelectedStandardList();
      },
    };
  });

  /**
   * 获取预约单已选服装
   */
  function getSelectedStandardList() {
    BangBangTangRequest({
      url: "/wap/schedule/getSelectedStandard",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        booking_id: bookingID,
      },
    }).subscribe((result) => {
      setlistImg(result);
    });
  }

  /**
   * 获取分类标签
   */
  function getClassifyTag() {
    BangBangTangRequest({
      url: "/wap/schedule/getClothingTag",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
      },
    }).subscribe((result) => {
      setTagList(result);
      // let newArray = []
      // for (let i = 0; i < result.length; i++) {
      //     console.log(result[i])
      //     newArray.push([])
      // }
      // setselectCount(newArray)
    });
  }

  /**
   * 点击筛选
   * @param id
   * @param tag_id
   * @param type_tag
   * @param f
   */
  function handleClickSelectTag(
    id: number,
    tag_id: number,
    type_tag: number,
    f: number
  ) {
    let newArray: any = [].concat(TagList);
    newArray[tag_id - 1].type[f].state = !newArray[tag_id - 1].type[f].state;

    if (newArray[tag_id - 1].type[f].state) {
      tagParam.push(`${tag_id}.${type_tag}`);
      // selectImgList(Array(tagParam).join(","));
      settagParam(newArray);
    } else {
      let newArray = [].concat(tagParam);
      for (let i = 0; i < newArray.length; i++) {
        const element = newArray[i];
        if (`${tag_id}.${type_tag}` == element) {
          newArray.splice(i, 1);
        }
      }
      settagParam(newArray);
      // selectImgList(Array(newArray).join(","));
    }
  }

  /**
   * 处理请求参数
   */
  function paramsJoin() {
    console.log(tagParam)
    let arr = []
    for (let i = 0; i < tagParam.length; i++) {
      const element = tagParam[i];
      for (let j = 0; j < element.type.length; j++) {
        const item = element.type[j];
        if (item.state) {
          console.log(i, item.id)
          arr.push(`${element.id}.${item.id}`)
        } else {
          continue
        }
      }
    }
    return arr.join(",")
  }

  /**
   * 清空选中
   */
  function handleClickClearClassify() {
    settagParam([]); // 清空数据
    setTimeout(() => {
      // 等待组件数据清空
      getClassifyTag(); // 重新请求数据 覆盖
      closeAlert();
      selectImgList("");
    }, 100);
  }

  /**
   * 取消选中
   */
  function handleClickItemClose(id: number) {
    BangBangTangRequest({
      url: "/wap/schedule/userCancelStandard",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        booking_id: bookingID,
        standard_id: id,
      },
    }).subscribe((result) => {
      if (!result) {
        Toast.show("取消成功");
        getSelectedStandardList();
      }
      closeAlert();
    });
  }

  /**
   * 关闭弹出
   */
  function closeAlert() {
    setisSearch(false);
    setisImgList(false);
    setMask(false);
  }

  return (
    <div className={style.Search_Content}>
      <button
        className={style.Search_btn}
        onClick={() => {
          setisSearch(!isSearch);
          setMask(!isSearch ? true : false);
          setisImgList(false);
        }}
      >
        编辑
        {/* <Icon type="search" size="md" className={style.icon_search_img} /> */}
      </button>
      <button
        className={style.Number_btn}
        onClick={() => {
          setisImgList(!isImgList);
          setMask(!isImgList ? true : false);
          setisSearch(false);
          getSelectedStandardList();
        }}
      >
        信息
      </button>
      {/* 搜索 */}
      <div
        className={`${style.Search_res} ${isSearch && style.action_search}`}
        style={{
          height: "70%",
        }}
      >
        {isSearch && (
          <div className={style.btn_action}>
            <Button
              style={{
                width: 100
              }}
              ghost
              theme="primary"
              onClick={handleClickClearClassify}
            >清空</Button>
            <Button
              style={{
                width: 100
              }}
              theme="primary"
              onClick={
                () => {
                  selectImgList(paramsJoin());
                  closeAlert();
                }
              }
            >保存</Button>
          </div>
        )}
        {TagList.map((v: any, s: number) => {
          return (
            <div className={style.Search_Color} key={s}>
              <div className={style.Table_name}>{v.name}:</div>
              <div className={style.Item_Content}>
                {v.type.map((j: any, f: number) => {
                  return (
                    <span
                      className={`${style.item_card} ${j.state && style.action
                        }`}
                      key={f}
                      onClick={() => handleClickSelectTag(j.id, v.id, j.id, f)}
                    >
                      {j.name}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {/* 图片选择 */}
      <div className={`${style.Img_res} ${isImgList && style.action_search}`}>
        <div className={style.Img_res_list}>
          {listImg.map((v: { clothingimages: string; id: number }) => {
            return (
              <div className={style.item_img} key={v.id}>
                <img
                  src={v.clothingimages}
                  alt=""
                  className={style.item_img_img}
                />
                <Icon
                  type="wrong-round"
                  theme="primary"
                  className={style.icon_img}
                  onClick={() => handleClickItemClose(v.id)}
                />
              </div>
            );
          })}
        </div>
        <div className={style.fot_text}>
          <span>孕妇档期</span>
          <span>2020-11-24</span>
          <span>12:47</span>
        </div>
      </div>
      {/* 遮罩 */}
      {mask && <div className={style.mask} onClick={closeAlert} />}
    </div>
  );
};

export default forwardRef(Menu);
