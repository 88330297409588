import React from "react";
import { BangBangTangLayout } from "../../layout/layout";
import style from "./index.module.scss";
import { Tabs, Cell, Pull, ActivityIndicator } from "zarm";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";
import { finalize } from "rxjs/operators";
const { Panel } = Tabs;

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
};

export class ExecutiveForceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load_state: LOAD_STATE.normal,
      list: [],
      currentPage: 1,
    };
  }

  typeList = {
    2: "奖励类型",
    3: "处罚类型",
    4: "整改类型",
  };

  tagList = {
    2: "奖励",
    3: "处罚",
    4: "整改",
  };

  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {
    window.document.title = "执行力明细";
    this.getList();
  }

  getList = (page = 1) => {
    this.setState({
      load_state: LOAD_STATE.loading,
    });
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getExecutionDetail",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        page: page,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        console.log(result);
        let ListClone = that.state.list;
        if (page == 1) {
          ListClone = result;
        } else {
          ListClone = ListClone.concat(result);
        }
        that.setState({
          list: ListClone,
          currentPage: page,
          load_state:
            result.length < 10 ? LOAD_STATE.complete : LOAD_STATE.normal,
        });
      });
  };

  getListMore = (page) => {
    this.getList(page);
  };

  titleShow = (title, type, fraction) => {
    return (
      <div className="detailType">
        <span className="type">{title}</span>
        {fraction > 0 ? (
          <span className="plus">+{fraction}</span>
        ) : (
          <span className="reduce">{fraction}</span>
        )}
        <span className={"tag_" + type}>{this.tagList[type]}</span>
      </div>
    );
  };

  render() {
    const { load_state, list, currentPage } = this.state;
    return (
      <BangBangTangLayout title={"执行力明细"}>
        <div className={style.container}>
          <div className="list">
            <Pull
              className="list-container"
              refresh={{ state: 0 }}
              load={{
                state: load_state,
                handler: () => {
                  console.log(123);
                  this.getListMore(currentPage + 1);
                },
                distance: 200,
                render: (loadState) => {
                  const cls = "custom-control";
                  switch (loadState) {
                    case LOAD_STATE.loading:
                      return (
                        <div className={cls}>
                          <ActivityIndicator type="spinner" />
                        </div>
                      );
                    case LOAD_STATE.failure:
                      return <div className={cls}>加载失败</div>;
                    case LOAD_STATE.complete:
                      return <div className={cls}>没有更多数据了</div>;
                  }
                },
              }}
            >
              {list.map((item, index) => {
                return (
                  <Cell
                    key={index}
                    title={this.titleShow(
                      item.type_name,
                      item.award_type,
                      item.integral
                    )}
                    description={item.createtime}
                    onClick={() => {
                      this.props.history.push(
                        "./executive-force-detail/" +
                          item.id +
                          "/" +
                          item.award_type
                      );
                    }}
                  />
                );
              })}
            </Pull>
          </div>
        </div>
      </BangBangTangLayout>
    );
  }
}
export default withRouter(ExecutiveForceList);
