import { BangBangTangLayout } from "../../layout/layout";
import React from "react";
import "./customer-confirm.css";
import { Button, Cell } from "zarm";
import { BangBangTangRequest } from "../../utils/request";
import { store } from "../../store/store.root";
import { finalize } from "rxjs/operators";
import { toast } from "react-toastify";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";

export class CustomerConfirm extends React.Component<
  any,
  {
    text: string;
    feedback_id: number | null;
    mobile: string | null;
    key1: string | null;
    key2: string | null;
    key3: string | null;
    value1: string | null;
    value2: string | null;
    value3: string | null;
    okText: string | null;
    cancelText: string | null;
    complete: boolean;
    customername: string | null;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      okText: "",
      complete: false,
      cancelText: "",
      text: "",
      feedback_id: null,
      customername: "",
      mobile: "",
      key1: "",
      key2: "",
      key3: "",
      value1: "",
      value2: "",
      value3: "",
    };
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      store.globalUiStore.startMaskLoading();
      BangBangTangRequest({
        url: "/wap/data/getCustomerConfirm",
        method: "POST",
        data: { token: store.userStore.token },
      })
        .pipe(finalize(() => store.globalUiStore.hideLoading()))
        .subscribe((result) => {
          if (result && result.data_feedback) {
            this.setState({
              text: result.word,
              complete: true,
              feedback_id: result.data_feedback.id,
              customername: result.staging.customername,
              mobile: result.staging.mobile,
              key1: result.staging.key1,
              key2: result.staging.key2,
              key3: result.staging.key3,
              value1: result.staging.value1,
              value2: result.staging.value2,
              value3: result.staging.value3,
              okText: result.staging.okText,
              cancelText: result.staging.cancelText,
            });
          } else store.routerStore.replace("/build");
        });
    });
  }

  render() {
    if (!this.state.complete) return null;

    return (
      <BangBangTangLayout>
        <div className="customer-confirm">
          <div className="page-header">{this.state.text}</div>
          <div className="divider" />
          <div className="paper">
            <div className="paper-line left">
              客户姓名:{this.state.customername}
            </div>
            <div className="paper-line left">手机号码: {this.state.mobile}</div>
            <div className="paper-line left">
              {this.state.key1}: {this.state.value1}
            </div>
            <div className="paper-line left">
              {this.state.key2}: {this.state.value2}
            </div>
            <div className="paper-line left">
              {this.state.key3}: {this.state.value3}
            </div>
          </div>
          <div className="button-row">
            <Button onClick={this.confirm.bind(this, false)}>
              {this.state.cancelText}
            </Button>
            <Button onClick={this.confirm.bind(this, true)}>
              {this.state.okText}
            </Button>
          </div>
        </div>
      </BangBangTangLayout>
    );
  }

  private readonly confirm = (value: boolean) => {
    store.globalUiStore.startMaskLoading();
    BangBangTangRequest({
      url: "/wap/data/submitCustomerConfirm",
      data: {
        token: store.userStore.token,
        feedback_id: this.state.feedback_id,
        feedback_status: value ? "1" : "2",
      },
      method: "POST",
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe(() => {
        toast("提交成功");
        store.routerStore.replace("/build");
      });
  };
}
