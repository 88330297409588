import { computed, makeAutoObservable, runInAction } from "mobx";
import { BangBangTangRequest } from "../../utils/request";

class EmployeePreorderClotheBriefDetailStore {
  servers: { id: number; name: string }[] | undefined = undefined;
  value: Set<number> = new Set<number>();
  search_value: string = "";

  @computed get valid_servers(): { id: number; name: string }[] {
    if (this.search_value) {
      return (
        this.servers?.filter((v) => v.name.indexOf(this.search_value) >= 0) ||
        []
      );
    } else {
      return this.servers || [];
    }
  }
  setSearchValue(v: string) {
    this.search_value = v;
  }

  clearValue() {
    this.value.clear();
  }

  toggleValue(v: number) {
    if (this.value.has(v)) {
      this.value.delete(v);
    } else {
      this.value.add(v);
    }
  }

  getServers() {
    BangBangTangRequest({
      url: "/wap/clothing/getSelectionServerStaff",
      data: {},
      method: "POST",
    }).subscribe((value) => runInAction(() => (this.servers = value)));
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const employeePreorderClotheBriefDetailStore = new EmployeePreorderClotheBriefDetailStore();
