import React from 'react'
import styled from 'styled-components'
import Card from './components/Card'
import SuperCard from './components/SuperCard'
import TitleCard from './components/TitleCard'
import { BangBangTangLayout } from '../../layout/layout'
import { store } from '../../store/store.root'
import { observer } from 'mobx-react'
import style from './index.module.scss'
import { Button, Cell } from 'zarm'
import { readAuth, readAuthMenu } from '../../utils/readAuth'
import { ModuleRequired } from '../../components/module-required/module-required'
import { RuleListRequired } from '../../components/module-required/rule-list-required'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'
import { AliIcon } from '../../layout/bangbangtang-tab-bar'

const PersonContainer = styled.section`
  width: 100%;
  height: calc(100vh - 50px);
`

@observer
class PersonCenter extends React.Component {
  componentDidMount() {
    localStorage.removeItem('register_size')
    appIsFirstReadySubject.subscribe(() => {
      store.globalUiStore.changeCurrentActive('person-center')
      window.document.title = '个人中心'
    })
  }

  /**
   * 切换门店
   */
  handleClickStores() {
    store.storeInfoStore.toggleModalDisplay(true)
  }

  render() {
    return (
      <BangBangTangLayout showTabBar={true} title={'员工中心'}>
        <PersonContainer>
          {/* <TitleCard avatar={avatar} mobile={mobile} username={username} /> */}
          {/* 管理员信息 */}
          <div className={style.information}>
            <div className={style.information_warp}>
              <div className={style.information_warp_ava}>
                <img src={store.userStore.avatar} alt="" />
                <ModuleRequired name={'sanction'}>
                  <p>{store.storeInfoStore.rank}</p>
                </ModuleRequired>
              </div>
              <div className={style.information_warp_info}>
                <p>{store.userStore.username}</p>
                <p>{store.userStore.staff_name}</p>
                <p style={{ fontSize: 17 }}>{store.userStore.mobile}</p>
              </div>
            </div>
            <div className={style.btn_sw}>
              {/* <p>当前门店: </p> */}
              <Button
                theme="primary"
                size="xs"
                onClick={this.handleClickStores}
              >
                {store.storeInfoStore.store_name}
              </Button>
            </div>
          </div>

          <ModuleRequired name={'schedule'}>
            <Cell
              hasArrow
              title="预约单管理"
              icon={<AliIcon type="iconnaozhong" />}
              onClick={() => {
                store.routerStore.push('/build/employee-preorder-list')
              }}
            />
          </ModuleRequired>

          <Cell
            hasArrow
            title="客诉管理"
            icon={<AliIcon type="icontousujianyi" />}
            onClick={() => {
              store.routerStore.push('/build/negative-comments/0')
            }}
          />

          <ModuleRequired name={'sanction'}>
            <Cell
              hasArrow
              title="执行管理"
              icon={<AliIcon type="iconjiangbei" />}
              onClick={() => {
                store.routerStore.push('/build/executive-management')
              }}
            />
          </ModuleRequired>

          <ModuleRequired name={'exam'}>
            <Cell
              hasArrow
              icon={<AliIcon type="iconpeixunkaoshi" />}
              title="考试中心"
              onClick={() => {
                store.routerStore.push('/build/test-center')
              }}
            />
          </ModuleRequired>
          {/* training */}
          <ModuleRequired name={'exam'}>
            <Cell
              hasArrow
              title="岗位基础培训"
              icon={<AliIcon type="iconpeixun" />}
              onClick={() => {
                store.routerStore.push('/build/employee-center')
              }}
            />
          </ModuleRequired>
          <ModuleRequired name="clothing">
            <RuleListRequired name="clothing_selection">
              <Cell
                hasArrow
                title="服装预选"
                icon={<AliIcon type="iconfuzhuang" />}
                onClick={() => {
                  store.routerStore.push(
                    '/build/employee-query-preorder-clothe-list'
                  )
                }}
              />
            </RuleListRequired>
          </ModuleRequired>
        </PersonContainer>
      </BangBangTangLayout>
    )
  }
}

export default PersonCenter
