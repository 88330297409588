import { Toast } from 'zarm'
import { FetchRequest } from './fetch'
import { getParamValue } from './url'
import { store } from '../store/store.root'

export { PHONE_RE } from './regex'

// 分享
export async function handleOnChangShare(sharData?: {
  title?: string
  link?: string
  desc?: string
}) {
  console.log('link:', sharData?.link)
  let getShareInfo = await FetchRequest('/wap/plate/getShareInfo', {
    token: localStorage.getItem('token'),
  })
  if (getShareInfo.code === 1) {
    let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
      currenturl: window.location.href,
    })
    if (SDKParams.code === 1) {
      const { wx }: any = window
      const {
        appId,
        nonceStr,
        signature,
        timestamp,
        jsApiList,
      } = SDKParams.data
      wx.config({
        debug: false, // 开启调试模式
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList, // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-weapp'],
      })

      wx.ready(function () {
        //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: sharData?.title || getShareInfo.data.title, // 分享标题
          desc: sharData?.desc || getShareInfo.data.content, // 分享描述
          link: sharData?.link || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: getShareInfo.data.image, // 分享图标
          success: function (res: any) {
            // 设置成功
          },
        })
      })

      wx.error(function (res: any) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })
    } else {
      Toast.show('JsSdk获取失败')
    }
    if (SDKParams.data.gh_appid) {
      localStorage.setItem('gh_appid', SDKParams.data.gh_appid)
    }
    return SDKParams.data
  } else {
    // Toast.show("分享参数获取失败")
    return ''
  }
}

export function CHECK_URL(url: string) {
  //url= 协议://(ftp的登录信息)[IP|域名](:端口号)(/或?请求参数)
  var strRegex =
    '^((https|http|ftp)://)?' + //(https或http或ftp):// 可有可无
    "(([\\w_!~*'()\\.&=+$%-]+: )?[\\w_!~*'()\\.&=+$%-]+@)?" + //ftp的user@  可有可无
    '(([0-9]{1,3}\\.){3}[0-9]{1,3}' + // IP形式的URL- 3位数字.3位数字.3位数字.3位数字
    '|' + // 允许IP和DOMAIN（域名）
    '(localhost)|' + //匹配localhost
    "([\\w_!~*'()-]+\\.)*" + // 域名- 至少一个[英文或数字_!~*\'()-]加上.
    '\\w+\\.' + // 一级域名 -英文或数字  加上.
    '[a-zA-Z]{1,6})' + // 顶级域名- 1-6位英文
    '(:[0-9]{1,5})?' + // 端口- :80 ,1-5位数字
    '((/?)|' + // url无参数结尾 - 斜杆或这没有
    "(/[\\w_!~*'()\\.;?:@&=+$,%#-]+)+/?)$" //请求参数结尾- 英文或数字和[]内的各种字符

  var strRegex1 =
    '^(?=^.{3,255}$)((http|https|ftp)?://)?(www.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:d+)*(/)?(?:/(.+)/?$)?(/w+.w+)*([?&]w+=w*|[\u4e00-\u9fa5]+)*$'
  var re = new RegExp(strRegex, 'i') //i不区分大小写
  // console.log(re);
  //将url做uri转码后再匹配，解除请求参数中的中文和空字符影响
  if (re.test(encodeURI(url))) {
    return true
  } else {
    return false
  }
}

export function elementInViewport(el: any) {
  var top = el.offsetTop
  var left = el.offsetLeft
  var width = el.offsetWidth
  var height = el.offsetHeight

  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop
    left += el.offsetLeft
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    top + height <= window.pageYOffset + window.innerHeight &&
    left + width <= window.pageXOffset + window.innerWidth
  )
}
let timer: any

export function handleIFGoToSelect(url: string) {
  // let timer: any
  timer = setInterval(() => {
    if (localStorage.getItem('token') || getParamValue('token')) {
      if (
        store.websiteRuleStore.website_rule_module_all &&
        store.websiteRuleStore.website_rule_module_all.length > 0
      ) {
        const host = store.websiteRuleStore.selection_domain
        if (host) {
          if (location.host !== store.websiteRuleStore.selection_domain) {
            location.href = `http://${host}${url}${location.search}${
              location.search ? '&' : '?'
            }token=${localStorage.getItem('token') || getParamValue('token')}`
          } else {
            clearInterval(timer)
          }
        } else {
          Toast.show('暂未配置跳转域名')
        }
      }
    }
  }, 500)
}
