import { action, computed, makeAutoObservable, toJS } from "mobx";
import { BangBangTangRequest } from "../utils/request";
import { finalize } from "rxjs/operators";
import { store } from "./store.root";

export class PhotoFamilyCollectionStore {
  loading: boolean = false;
  updateTime: Date | null = null;
  @computed get data_flatten(): Array<{
    pic: string;
    takeTime: string;
    id: string;
  }> {
    const _list: Array<{ pic: string; takeTime: string; id: string }> = [];
    const data = this.data;
    data.forEach((list) => {
      list.pic.forEach((pic) => {
        _list.push({ pic, id: list.id + pic, takeTime: list.takeTime });
      });
    });
    return _list;
  }

  data: Array<{
    pic: string[];
    takeTime: string;
    id: number;
    setDisplayIndex: number;
  }> = [];

  constructor() {
    makeAutoObservable(this);
  }

  startLoading() {
    this.loading = true;
  }

  setUpdated() {
    this.updateTime = new Date();
  }

  hideLoading() {
    this.loading = false;
  }

  update() {
    if (this.updateTime !== null) return;
    this.startLoading();
    return BangBangTangRequest({
      url: "/wap/data/getFamilyImageGenealogyData",
      method: "POST",
      data: { token: store.userStore.token },
    })
      .pipe(finalize(() => this.hideLoading()))
      .subscribe(
        (result: Array<{ id: number; pic: string[]; takeTime: string }>) => {
          if (!result) {
            this.setData([]);
          } else {
            this.setData(
              result.map((value) => ({
                id: value.id,
                pic: value.pic,
                takeTime: value.takeTime,
                setDisplayIndex: 0,
              }))
            );
          }
        }
      );
  }

  setData(
    data: Array<{
      pic: string[];
      takeTime: string;
      id: number;
      setDisplayIndex: number;
    }>
  ) {
    this.data = data;
    this.updateTime = new Date();
  }
}
