import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
  Cell,
  DatePicker,
  FilePicker,
  Button,
  Input,
  Loading,
  ActivityIndicator,
  Toast,
  Modal,
} from 'zarm'
import style from './index.module.scss'
import { Plus } from '@zarm-design/icons'
import IDCard from '../../assets/id_card.png'
import OrderImages from '../../assets/order_b.png'
import ServiceImages from '../../assets/service_2.png'
import { FetchRequest } from '../../utils/fetch'
import COS from 'cos-js-sdk-v5'
import { random_20 } from '../../utils/upload'
import { getParamValue } from '../../utils/url'
import { store } from '../../store/store.root'
import { CHECK_URL } from '../../utils'
import { withRouter } from 'react-router-dom'
import { Dialog } from 'react-vant'
import { observer } from 'mobx-react'

export default observer(
  withRouter((props: any) => {
    useEffect(() => {
      document.title = '权益审核'
      if (store.userStore.need_info) {
        Dialog.alert({
          title: '提示',
          message:
            '为了方便客服与您联系，提供更优质的服务，需要获取您的微信头像和昵称',
        }).then(() => {
          localStorage.removeItem('token')
          localStorage.setItem('scope', 'snsapi_userinfo')
          store.userStore.loginFromWechat()
        })
        return
      }
    }, [])

    const activity_id = props.match.params.id

    useEffect(() => {
      if (store.userStore.token) {
        handleGravidgroupCheckGravidGroup()
      }
    }, [store.userStore.token])

    const [birthdayOpen, setBirthdayOpen] = useState(false)

    const [warp_gift, set_warp_gift] = useState('')
    const [service_mobile, set_service_mobile] = useState('')

    const [tipes_content, set_tipes_content] = useState('')
    const [isShowTipes, setIsShowTipes] = useState(false)
    const [show_cancel, set_show_cancel] = useState(false)

    const [check, set_check] = useState(false)
    const [tipes, set_tipes] = useState('')

    const [name, setName] = useState('')
    const [birthday, setBirthday] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')

    const [filesIDCard, setIDCardFiles] = useState<any>({})
    const [tmp_idcard, set_tmp_idcard] = useState('')

    const onSelectIDCard = (file: any) => {
      setIDCardFiles(file)
    }

    const [filesOrder, setOrderFiles] = useState<any>({})
    const [tmp_bchao, set_tmp_bchao] = useState('')

    const onSelectOrder = (file: any) => {
      setOrderFiles(file)
    }

    const handleSubAudit = async () => {
      if (!name || !birthday || !address || !phone) {
        Toast.show('请填写完整')
        return
      }
      if (Object.keys(filesIDCard).length === 0) {
        Toast.show('请上传身份证正面照或孕产妇保健手册封面图')
        return
      }
      if (Object.keys(filesOrder).length === 0) {
        Toast.show('请上传B超单')
        return
      }
      ////// 上传
      let data = {
        name,
        baby_birthday: birthday,
        address,
        phone,
        bchao: '',
        idcard: '',
        activity_id,
        token: localStorage.getItem('token') || store.userStore.token,
      }

      if (CHECK_URL(tmp_idcard)) {
        // 链接不上传
      } else {
        // 上传
        //// 上传身份证
        const res_id_card = await cosUpload(filesIDCard.file, 800)
        if (res_id_card === '') {
          Toast.show('身份证或孕产妇保健手册上传失败!')
          return
        } else {
          data['idcard'] = res_id_card
        }
      }

      if (CHECK_URL(tmp_bchao)) {
        // 链接不上传
      } else {
        // 上传
        //// 上传单子
        const res_id_bchao = await cosUpload(filesOrder.file, 1200)
        if (res_id_bchao === '') {
          Toast.show('B超单上传失败!')
          return
        } else {
          data['bchao'] = res_id_bchao
        }
      }
      console.log(data)
      // 提交数据
      Loading.show({
        content: <ActivityIndicator size="lg" />,
      })
      const res = await FetchRequest(
        '/wap/marketing/gravidgroup/submitGravidGroup',
        data
      )
      Loading.hide()
      if (res.code === 1) {
        handleGravidgroupCheckGravidGroup(false)
      } else {
        handleGravidgroupCheckGravidGroup()
        Toast.show(res.msg)
      }
    }

    const handleGravidgroupCheckGravidGroup = async (type = true) => {
      const res = await FetchRequest(
        '/wap/marketing/gravidgroup/checkGravidGroup',
        { activity_id }
      )

      if (res.code === 1) {
        set_warp_gift(res.data.warp_gift)
        setPhone(res.data.mobile)
        set_service_mobile(res.data.service)
        if (!type) {
          if (res.data.status === '人工审核通过') {
            set_tipes_content(res.data.status)
            setIsShowTipes(true)
            set_show_cancel(false)
          }
          if (res.data.status === '系统审核通过') {
            set_tipes_content(res.data.status)
            setIsShowTipes(true)
            set_show_cancel(false)
          }
          //===
          if (res.data.status === '等待人工审核') {
            set_tipes_content('系统审核通过,' + res.data.status)
            setIsShowTipes(true)
            set_show_cancel(false)
          }
          if (res.data.status === '人工审核不通过') {
            const modal = Modal.alert({
              title: '提示',
              content: res.data.status + ',重新提交',
              onCancel: () => {
                modal.hide()
              },
            })
          }
        }
        set_check(false)
        if (!!res.data.check) {
          setAddress(res.data.check.address)
          setName(res.data.check.name)
          setBirthday(res.data.check.birthday)

          set_tmp_idcard(res.data.check.idcard)
          set_tmp_bchao(res.data.check.bchao)
        } else {
          setName(res.data.name)
          setBirthday(res.data.birthday)
        }
      } else {
        set_tipes(res.msg)
        set_check(true)
      }
    }

    // 上传到cos
    async function cosUpload(File: File, px: number = 800): Promise<string> {
      return new Promise(async (res, rej) => {
        Loading.show({
          content: <ActivityIndicator size="lg" />,
        })

        const configOOS = await FetchRequest('/wap/graduation/getCosConfig', {
          token: localStorage.getItem('token') || store.userStore.token,
        })

        const cos = new COS({
          getAuthorization: async function (options, callback) {
            // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
            // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
            // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
            const data = await FetchRequest('/addons/cos/index/getSTS', {
              token: localStorage.getItem('token') || store.userStore.token,
            })
            callback({
              TmpSecretId: data.data.credentials.tmpSecretId,
              TmpSecretKey: data.data.credentials.tmpSecretKey,
              SecurityToken: data.data.credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.data.expiredTime, // 时间戳，单位秒，如：1580000900
            })
          },
        })

        const uploadFileName = random_20()
        const suffix = 'png'

        const objectName = `/uploads/${
          localStorage.getItem('admin_store_id') || '1'
        }/img/spellGroup/${uploadFileName}.${suffix}`

        cos.putObject(
          {
            Bucket: configOOS.data.bucket,
            Region: configOOS.data.region,
            Key: objectName,
            Body: File,
            onProgress: function (progressData) {
              console.log(JSON.stringify(progressData))
            },
          },
          async function (err, data_push) {
            if (err) {
              rej('')
              Loading.hide()
              return
            }
            if (data_push.statusCode === 200) {
              //// 存入
              cos.request(
                {
                  Bucket: configOOS.data.bucket,
                  Region: configOOS.data.region,
                  Key: objectName,
                  Method: 'POST',
                  Action: 'image_process',
                  Headers: {
                    'Pic-Operations':
                      '{"is_pic_info": 1, "rules": [{"fileid": "' +
                      uploadFileName +
                      '.png", "rule": "imageMogr2/thumbnail/' +
                      px +
                      'x"}]}',
                    'Cache-Controll': 'no-cache',
                  },
                },
                function (err, data) {
                  if (err) {
                    Toast.show('图片处理失败')
                    rej('file: 失败')
                    Loading.hide()
                    return
                  }
                  if (data.statusCode === 200) {
                    res('https://' + data_push.Location)
                  }
                  Loading.hide()
                }
              )
            }
            if (err) {
              rej('file: 失败')
              Loading.hide()
            }
          }
        )
        return objectName
      })
    }

    if (check) {
      return tipes
    }

    return (
      <div className={style.content}>
        <div className={style.title}>
          {' '}
          {warp_gift && `[${warp_gift}]`} 礼包领取审核
        </div>
        <Cell title="姓名">
          <Input
            type="text"
            placeholder="请输入姓名"
            value={name}
            onChange={(e: any) => setName(e)}
          />
        </Cell>
        <Cell title="预产期" onClick={() => setBirthdayOpen(true)}>
          <Input
            type="text"
            placeholder="请输入预产期"
            value={birthday}
            disabled
          />
        </Cell>
        <Cell title="收货地址">
          <Input
            type="text"
            rows={3}
            placeholder="请输入收货地址"
            value={address}
            onChange={(e: any) => setAddress(e)}
          />
        </Cell>
        <Cell title="收货号码">
          <Input
            type="text"
            placeholder="请输入收货号码"
            maxLength={11}
            value={phone}
            disabled
            onChange={(e: any) => setPhone(e)}
          />
        </Cell>

        <div>
          <div className={style.id_title}>身份核验</div>
          <div className={style.images_upload_content}>
            <div className={style.images_left}>
              <FilePicker accept="image/*" onChange={onSelectIDCard}>
                <div className={style.images_icon}>
                  {filesIDCard.thumbnail ? (
                    <div className={style.images_thumbnail}>
                      <img
                        src={filesIDCard.thumbnail}
                        alt=""
                        style={{ width: '100%' }}
                      />
                    </div>
                  ) : (
                    <div style={{ padding: '10px 5px', color: '#686868' }}>
                      <Plus size="lg" />
                      <div style={{ fontSize: 12 }}>
                        上传身份证正面照
                        <br />
                        或孕产妇保健手册封面图
                      </div>
                    </div>
                  )}
                </div>
              </FilePicker>
            </div>
            <div className={style.images_right}>
              <img src={IDCard} alt="" />
            </div>
          </div>
        </div>

        <div>
          <div className={style.id_title}>孕期B超单核验</div>
          <div className={style.images_upload_content}>
            <div className={style.images_left}>
              <FilePicker accept="image/*" onChange={onSelectOrder}>
                <div className={style.images_icon}>
                  {filesOrder.thumbnail ? (
                    <div className={style.images_thumbnail}>
                      <img
                        src={filesOrder.thumbnail}
                        alt=""
                        style={{ width: '100%' }}
                      />
                    </div>
                  ) : (
                    <div style={{ padding: '20px 10px', color: '#686868' }}>
                      <Plus size="lg" />
                      <div style={{ fontSize: 12 }}>点击上传B超单照片</div>
                    </div>
                  )}
                </div>
              </FilePicker>
            </div>
            <div className={style.images_right}>
              <img src={OrderImages} alt="" />
            </div>
          </div>
        </div>

        <div className={style.sub_btn} onClick={handleSubAudit}>
          <Button block theme="primary">
            提交审核
          </Button>
        </div>

        <a href={`tel:${service_mobile}`} className={style.service_mobile}>
          <img src={ServiceImages} alt="" />
        </a>

        <Modal visible={isShowTipes} title="提示">
          <div className={style.tipes_show_Text}>{tipes_content}</div>
        </Modal>

        <DatePicker
          visible={birthdayOpen}
          mode="date"
          // value={state.date.value}
          onOk={(value: any) => {
            setBirthday(format(value, 'yyyy-MM-dd'))
            setBirthdayOpen(false)
          }}
          onCancel={() => setBirthdayOpen(false)}
        />
      </div>
    )
  })
)
