import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { BangBangTangRequest } from '../../utils/request'
import { store } from '../store.root'
import { BehaviorSubject } from 'rxjs'
import { delay } from 'rxjs/operators'
interface WebsiteRuleResponse {
  selection_domain: string
  orginal_domain: string
  module: {
    id: number
    module_name: ValidWebsiteModuleString
    module_title: string
    module_status: 'open' | 'close'
  }[]
  rulelist: ValidWebsiteRuleListString[]
  staffauth: ValidWebsiteStaffAuthString[]
  fillIcon: ValidWebsiteFillIconString[]
}
export type ValidWebsiteFillIconString =
  | 'plate'
  | 'family'
  | 'dissatisfied'
  | 'share'

export type ValidWebsiteRuleListString =
  | '*'
  | 'create_clothing_selection2'
  | 'reset_clothing_selection2'
  | 'edit_clothing_selection2'
  | 'employee_center'
  | 'select_selection_server_staff'
  | 'clothing_selection'
  | 'clothing_operation_admin'
  | 'schedule_operation_admin'
  | 'customer_complaints_management'
  | 'complaints_operation_designate '
  | 'complaints_operation_handle'
  | 'exam_center'
  | 'training_center'
  | 'complaints_operation_check'
  | 'schedule_center'
  | 'admin_get_booking'
  | 'admin_cancel_booking'
  | 'edit_booking_status'
  | 'edit_clothing_selection'
  | 'reset_clothing_selection'
  | 'cancel_clothing_selection'
  | 'sanction'
  | 'sanction_adjust_award'
  | 'sanction_award_all'
  | 'sanction_award_close'
  | 'sanction_adjust_reform'
  | 'sanction_reform_all'
  | 'sanction_reform_close'

export type ValidWebsiteModuleString =
  | 'genealogy'
  | 'schedule'
  | 'clothing'
  | 'invitation'
  | 'exam'
  | 'training'
  | 'sanction'
  | 'film-selection'
  | 'photopage'
  | 'marketing-transfer'
  | 'marketing-graduation'
  | 'marketing'
  | 'marekting-red_packet'
  | 'filmdownload'
export type ValidWebsiteStaffAuthString =
  | 'staff_center'
  | 'different_industry'
  | 'gift-center'
  | 'marketing-transfer'
  | ''

type ValidWebsiteStaffAuthModel = {
  module_name: string
  module_status: 'open' | 'close'
}

export class WebsiteRuleStore {
  // 获取网站开启模块是否结束
  $websiteRuleComplete = new BehaviorSubject<boolean>(false)
  website_rule_module: ValidWebsiteModuleString[] | null = null
  website_rule_module_all: ValidWebsiteStaffAuthModel[] | null = null
  staff_auth: ValidWebsiteStaffAuthString[] | null = null
  rule_list: ValidWebsiteRuleListString[] = []
  fill_icon: ValidWebsiteFillIconString[] | null = null
  selection_domain: string = ''
  orginal_domain: string = ''
  constructor() {
    makeAutoObservable(this)
  }
  index_valid_icon: Set<ValidWebsiteModuleString> = new Set<ValidWebsiteModuleString>(
    ['schedule', 'genealogy', 'clothing', 'photopage']
  )
  has_rule(value: ValidWebsiteRuleListString): boolean {
    if (this.rule_list) {
      return !!this.rule_list.find((v) => v === value || v === '*')
    } else {
      return false
    }
  }
  has_fill_icon(value: ValidWebsiteFillIconString): boolean {
    if (this.fill_icon) {
      return !!this.fill_icon.find((v) => v === value)
    } else {
      return false
    }
  }
  has_staff_auth(value: ValidWebsiteStaffAuthString): boolean {
    if (this.staff_auth) {
      return !!this.staff_auth.find((v) => v === value)
    } else {
      return false
    }
  }
  has_module(value: ValidWebsiteModuleString): boolean {
    if (this.website_rule_module) {
      return !!this.website_rule_module.find((v) => v === value)
    } else {
      return false
    }
  }
  updateFrontendAuth() {
    // 在注册页不执行
    if (location.pathname === '/build/register') return
    // 毕业季不执行
    if (location.pathname.indexOf('/build/graduation-sign') > -1) {
      return false
    }
    // 笑脸墙不执行
    if (location.pathname.indexOf('/build/activity-registration') > -1) {
      return false
    }
    const token = store.userStore.token
    this.$websiteRuleComplete.next(false)
    BangBangTangRequest<WebsiteRuleResponse>({
      url: '/wap/wap2/getFrontendAuth',
      data: {
        token: token,
        store_id: localStorage.getItem('admin_store_id')
          ? parseInt(localStorage.getItem('admin_store_id')!, 10)
          : null,
      },
      method: 'POST',
    }).subscribe((value) => {
      runInAction(() => {
        this.website_rule_module_all = value.module
        this.website_rule_module = value.module
          .filter((v) => v.module_status === 'open')
          .map((value) => value.module_name)
        this.rule_list = value.rulelist
        this.staff_auth = value.staffauth
        this.fill_icon = value.fillIcon
        this.selection_domain = value.selection_domain
        this.orginal_domain = value.orginal_domain
        this.$websiteRuleComplete.next(true)
      })
    })
  }
}
