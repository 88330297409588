// water-maker.tsx

import React, { memo, useMemo } from 'react'
import styles from './index.module.scss'
import { renderToString } from 'react-dom/server'
const Index = (
  props: React.PropsWithChildren<{
    text: string
    fillColor?: string
    fillOpacity?: number
    fontSize?: number
  }>
) => {
  const svgUrl = useMemo(() => {
    const svgRes = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="300px"
        height="180px"
        viewBox="0 0 300 180"
      >
        <text
          x="-160"
          y="-30"
          fill={props.fillColor || '#333'}
          transform="rotate(-35 220 -220)"
          fillOpacity={props.fillOpacity || '0.1'}
          fontSize={`${props.fontSize || '28'}px`}
        >
          {' '}
          {props.text}
        </text>
      </svg>
    )
    return URL.createObjectURL(
      new Blob([renderToString(svgRes)], {
        type: 'image/svg+xml',
      })
    )
  }, [props])

  return (
    <div
      className={styles.waterMaker}
      style={{ backgroundImage: `url(${svgUrl})` }}
    >
      {props.children}
    </div>
  )
}

export default memo(Index)
