import { observer } from "mobx-react";
import React from "react";
import { store } from "../../store/store.root";
import classNames from "classnames";
import { Button, Cell, DatePicker, Input, Modal } from "zarm";
import { preorderScheduleActions } from "../../store/preorder-schedule/preorder-schedule.action";
import { format } from "date-fns";

@observer
export class TimePicker extends React.Component<any, {}> {
  constructor(props: any) {
    super(props);
  }

  renderTitleContent(value: number): string {
    switch (value) {
      case 1:
        return "可预约";
      case 2:
        return "可备档";
      case 3:
        return "已过期";
      case 4:
        return "已预约";
      case 5:
        return "审核中";
      default:
        return "又该需求了";
    }
  }

  render() {
    if (
      store.preorderScheduleStore.schedule_type.picker_visible ||
      store.preorderScheduleStore.available_date.picker_visible ||
      store.preorderScheduleStore.store.picker_visible
    ) {
      return null;
    }
    if (!store.preorderScheduleStore.schedule_inquiry.picker_visible) {
      return null;
    }
    if (store.preorderScheduleStore.schedule_inquiry.loading) {
      return (
        <div className="loading time-picker-loading">
          <span className="iconfont">&#xe608;</span>
        </div>
      );
    }
    if (store.preorderScheduleStore.schedule_inquiry.choices?.length === 0) {
      return (
        <div className="time-picker-no-result">
          当前日期无可用档期,请选择其他日期后再试
        </div>
      );
    } else {
      return (
        <>
          <div className="time-picker">
            {store.preorderScheduleStore.schedule_inquiry.choices?.map(
              (value) => (
                <div
                  key={value.id}
                  onClick={() =>
                    value.status === 1 || value.status === 2
                      ? store.preorderScheduleStore.schedule_inquiry.value ===
                        value.id
                        ? store.preorderScheduleStore.set_schedule_inquiry_selected(
                            null
                          )
                        : store.preorderScheduleStore.set_schedule_inquiry_selected(
                            value.id
                          )
                      : null
                  }
                  className={classNames({
                    "time-block": true,
                    "time-block-selected":
                      value.id ===
                      store.preorderScheduleStore.schedule_inquiry.value,
                    "time-block-can-preorder": value.status === 1,
                    "time-block-can-queue": value.status === 2,
                    "time-block-already-out-date": value.status === 3,
                    "time-block-already-preorder": value.status === 4,
                    "time-block-check": value.status === 5,
                  })}
                >
                  <div className="time-block-panel">
                    <p>{this.renderTitleContent(value.status)}</p>
                  </div>
                  <div className="time-block-content">
                    <div className="first-line">
                      <p className="time-note">档期时间</p>
                      <p className="time-value">{value.schedule_time}</p>
                    </div>
                    <div className="second-line">
                      <p>{value.background}</p>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="button-row">
            {store.preorderScheduleStore.selected_schedule_inquiry &&
            store.preorderScheduleStore.selected_schedule_inquiry.status ===
              2 ? (
              <Button
                loading={store.preorderScheduleStore.submit_pending}
                style={{ color: "#F97B7C" }}
                onClick={() => {
                  store.preorderScheduleStore.change_customer_info_modal_visible(
                    true
                  );
                }}
              >
                备档排队
              </Button>
            ) : null}
            {store.preorderScheduleStore.selected_schedule_inquiry &&
            store.preorderScheduleStore.selected_schedule_inquiry.status ===
              1 ? (
              <Button
                loading={store.preorderScheduleStore.submit_pending}
                theme={"primary"}
                style={{ color: "white" }}
                onClick={() => {
                  store.preorderScheduleStore.change_customer_info_modal_visible(
                    true
                  );
                }}
              >
                立即预约
              </Button>
            ) : null}
          </div>
        </>
      );
    }
  }
}
