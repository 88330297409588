import React, { useEffect } from 'react'
import RegistImages from "../../assets/btn.jpg"

const styleBtn = {
  position: 'relative',
  // padding: 10,
  // color: '#fff',
  // background: 'hsl(156deg 100% 37%)',
  fontSize: 34,
  width: 200,
  letterSpacing: '4px',
  // height: 150,
  // lineHeight: '124px',
  // textAlign: 'center',
  // borderRadius: '50%'
}

const styleWxOpen = {
  position: 'absolute',
  width: 200,
  height: 200,
  top: 0,
  zIndex: 11,
  borderRadius: '50%'
}

const styleRegistText = {
  textAlign: 'center',
  position: 'absolute',
  left: 0,
  right: 0,
  top: '22%',
  color: '#fff',
  fontWeight: 'bold',
  lineHeight: 1.5
}

export function RegisterJump(props) {
  return (
      <div style={{ position: "fixed", top: '30%' }}>
          <div style={styleBtn}>
              <img src={RegistImages} alt="" style={{ borderRadius: '51%', width: '100%' }} />
              <div style={styleRegistText}>点击<br />注册</div>
          </div>
          <wx-open-launch-weapp
              id="launch-btn"
              username={localStorage.getItem("gh_appid") || props.gh_appid}
              path="pagesA/register/register?needMessage=no"
              style={styleWxOpen}
          >
              <script type="text/wxtag-template">
                  <div style={styleWxOpen} />
              </script>
          </wx-open-launch-weapp>
      </div>
  )
}