import React from "react";
import { Button, Modal } from "zarm";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";
import CopyToClipboard from "react-copy-to-clipboard";

@observer
export class EmployeeClotheShareModal extends React.Component<any, any> {
  render() {
    return (
      <Modal
        visible={store.employeePreorderClotheStore.share_modal_visible}
        title="分享"
        width={"90%"}
        closable={true}
        maskClosable={true}
        onCancel={() =>
          store.employeePreorderClotheStore.toggle_share_modal(false)
        }
      >
        <p>请点击下方按钮复制分享链接,并手动发送给客户</p>
        <CopyToClipboard
          text={`${store.employeePreorderClotheStore.customer_name.slice(
            0,
            1
          )}先生/女士，您好，您的预约单已经创建，请点击下方蓝色链接，开始为您的宝贝选择心仪的服装吧！您一共可以选择
           ${store.employeePreorderClotheStore.limit} 套服装.
          ${
            document.location.origin
          }/build/customer-preorder-clothe-detail?id=${
            store.employeePreorderClotheStore.preorder_id
          }`}
          onCopy={() => {
            alert("复制成功");
            store.employeePreorderClotheStore.toggle_share_modal(false);
          }}
        >
          <Button theme="primary" className="button" block>
            点击复制
          </Button>
        </CopyToClipboard>
      </Modal>
    );
  }
}
