import React from "react";
import "./photographic-plate-download.css";
import { PhotographicPlateDownloadComponent } from "../../components/photographic-plate-download/photographic-plate-download.component";
import { store } from "../../store/store.root";
import { observer } from "mobx-react";
import { Button, Modal } from "zarm";
import CopyToClipboard from "react-copy-to-clipboard";
import { BangBangTangLayout } from "../../layout/layout";
import { toast } from "react-toastify";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";

interface Props {}
interface State {
  current_display?: number;
  modal_visible_url: string;
}

@observer
export class PhotographicPlateDownload extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { modal_visible_url: "" };
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      store.photographicPlateStore.update();
      window.document.title = "底片下载";
    });
  }

  render() {
    return (
      <BangBangTangLayout>
        <div className="photographic-plate-download-page">
          <div className="tooltip">
            <p>您的底片会在公众号内永久保存，方便您随时下载查阅。</p>
          </div>
          <Modal
            width={"80%"}
            visible={!!this.state.modal_visible_url}
            maskClosable={true}
            onCancel={() => this.setState({ modal_visible_url: "" })}
          >
            <div className="photographic-plate-download-modal">
              <p>请复制云盘地址通过电脑浏览器打开，可进行批量操作</p>
              <p>云盘地址:</p>
              <p id="url">{this.state.modal_visible_url}</p>
              <CopyToClipboard
                text={this.state.modal_visible_url}
                onCopy={() => {
                  toast("复制成功");
                  this.setState({ modal_visible_url: "" });
                }}
              >
                <Button theme="primary" className="button">
                  点击复制
                </Button>
              </CopyToClipboard>
              <Button
                className="button"
                onClick={() => this.setState({ modal_visible_url: "" })}
              >
                关闭
              </Button>
            </div>
          </Modal>
          {store.photographicPlateStore.data.map((value) => (
            <PhotographicPlateDownloadComponent
              data={value}
              onSingleDownloadClicked={() => {
                window.open(value.url);
              }}
              onMultiDownloadClicked={() =>
                this.setState({ modal_visible_url: value.url! })
              }
              onCardSelected={() =>
                this.setState({
                  current_display:
                    this.state.current_display === value.id
                      ? undefined
                      : value.id,
                })
              }
              key={value.id}
              className="with-border"
            />
          ))}
        </div>
        <div className="plate-button-row">
          <Button onClick={() => store.routerStore.push("/build")}>
            返回首页
          </Button>
        </div>
      </BangBangTangLayout>
    );
  }
}
