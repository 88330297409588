import React from 'react'
import { observer } from 'mobx-react'
import { BangBangTangLayout } from '../../layout/layout'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'
import { store } from '../../store/store.root'
import './employee-preorder-clothe.scss'

import { PreorderClotheTagModal } from './preorder-clothe-tag-modal'
import { EmployeeClotheButtonRow } from './employee-clothe-button-row'
import { EmployeeClotheSaveModal } from './employee-clothe-save-modal'
import { EmployeeClotheShareModal } from './employee-clothe-share-modal'
import { EmployeeLargePicDisplay } from './employee-large-pic-display'
import classNames from 'classnames'
import { Pull, Popup } from 'zarm'

type LOAD_STATE = {
  normal: 0 // 普通
  abort: 1 // 中止
  loading: 2 // 加载中
  success: 3 // 加载成功
  failure: 4 // 加载失败
  complete: 5 // 加载完成（无新数据）
}

const LOAD_STATE_VALUE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
}

@observer
export class EmployeePreorderClothe extends React.Component<any, any> {
  containerRef = React.createRef<HTMLDivElement>()

  componentWillUnmount() {
    store.employeePreorderClotheStore.clearOtherData()
  }

  componentDidMount() {
    document.title = '员工服装预选'
    appIsFirstReadySubject.subscribe(() => {
      const id = new URLSearchParams(location.search).get('id')
      store.employeePreorderClotheStore.getScheduleType()
      if (id) {
        store.employeePreorderClotheStore.set_preorder_id(parseInt(id, 10))
      } else {
      }
      if (store.employeePreorderClotheStore.isEmpty) {
        store.employeePreorderClotheStore.employeeGetPreorderClotheData()
      }
    })
  }

  render() {
    return (
      <BangBangTangLayout
        maskLoading={store.employeePreorderClotheStore.loading}
        title={'员工预选服装'}
      >
        <Pull
          style={{ overflow: 'auto', maxHeight: '100vh' }}
          load={{
            state: LOAD_STATE_VALUE.normal,
            distance: 200,
            handler: store.employeePreorderClotheStore.loadMore,
          }}
        >
          <div className="employee-preorder-clothe" ref={this.containerRef}>
            <div className="first_col">
              {store.employeePreorderClotheStore.data
                ?.filter((value, index) => index % 2 === 0)
                .map((value) => (
                  <img
                    src={
                      value.clothingimages.length > 0
                        ? value.clothingimages[0]
                        : ''
                    }
                    alt=""
                    key={value.id}
                    className={classNames({
                      'active-photo': store.employeePreorderClotheStore.selected_photos.has(
                        value.id
                      ),
                    })}
                    onClick={() => {
                      store.employeePreorderClotheStore.toggle_show_large(
                        value.id
                      )
                      store.routerStore.push(
                        '/build/employee-preorder-clothe-detail-preview?type=Employee'
                      )
                    }}
                  />
                ))}
            </div>
            <div className="second_col">
              {store.employeePreorderClotheStore.data
                ?.filter((value, index) => index % 2 === 1)
                .map((value) => (
                  <img
                    src={
                      value.clothingimages.length > 0
                        ? value.clothingimages[0]
                        : ''
                    }
                    alt=""
                    key={value.id}
                    onClick={() => {
                      store.employeePreorderClotheStore.toggle_show_large(
                        value.id
                      )
                      store.routerStore.push(
                        '/build/employee-preorder-clothe-detail-preview?type=Employee'
                      )
                    }}
                  />
                ))}
            </div>
          </div>
        </Pull>
        <EmployeeClotheButtonRow />
        <EmployeeClotheSaveModal />
        <EmployeeClotheShareModal />
        {/* <EmployeeLargePicDisplay /> */}
        <Popup
          visible={store.employeePreorderClotheStore.photo_modal_visible}
          direction="bottom"
          onMaskClick={() =>
            store.employeePreorderClotheStore.toggle_photo_modal_visible(false)
          }
          destroy={false}
          mountContainer={() => document.body}
        >
          <div className="customer-clothe-popup-container">
            {store.employeePreorderClotheStore.selected_photos_flatten.map(
              (value) => (
                <>
                  <div className="image" key={value.id}>
                    <img src={value.pic[0] || ''} alt="" />
                  </div>
                </>
              )
            )}
          </div>
        </Popup>
        <PreorderClotheTagModal
          option_filtered={['年龄', '性别', '尺码', '色系', '风格']}
          visible={store.employeePreorderClotheStore.popup_visible}
          selected_options={
            new Set([
              ...Array.from(store.employeePreorderClotheStore.type_selected),
              ...Array.from(store.employeePreorderClotheStore.gender_selected),
              ...Array.from(
                store.employeePreorderClotheStore.clothe_type_selected
              ),
              ...Array.from(
                store.employeePreorderClotheStore.color_type_selected
              ),
              ...Array.from(
                store.employeePreorderClotheStore.color_style_selected
              ),
            ])
          }
          onCancel={() =>
            store.employeePreorderClotheStore.toggle_popup_visible(false)
          }
          onGenderCheck={(value) =>
            store.employeePreorderClotheStore.toggle_gender_selected(value)
          }
          onTypeCheck={(value) =>
            store.employeePreorderClotheStore.toggle_type_selected(value)
          }
          onClotheTypeCheck={(value) =>
            store.employeePreorderClotheStore.toggle_clothe_type_selected(value)
          }
          onColorTypeCheck={(value) =>
            store.customerPreorderClotheStore.toggle_color_type_selected(value)
          }
          onStyleTypeCheck={(value) =>
            store.customerPreorderClotheStore.toggle_style_type_selected(value)
          }
          onRegionTypeCheck={(value) =>
            store.customerPreorderClotheStore.toggle_clothe_region_selected(
              value
            )
          }
          onClear={() => store.employeePreorderClotheStore.clear_options()}
          options={store.employeePreorderClotheStore.options}
          onSave={() => {
            store.employeePreorderClotheStore.onlySaveTags()
          }}
          saveButtonDisabled={
            store.employeePreorderClotheStore.type_selected.size === 0 ||
            store.employeePreorderClotheStore.gender_selected.size === 0
          }
        />
      </BangBangTangLayout>
    )
  }
}
