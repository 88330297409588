import React, { ReactComponentElement, useEffect, useMemo, useRef, useState } from "react"
import { Tabs, Cell, Pull, Button, Toast, ImagePreview } from 'zarm';
import { store } from "../../store/store.root";
import { FetchRequest } from "../../utils/fetch";
import { getParamValue } from "../../utils/url";
import style from "./index.module.scss"
import "./style.css"

const { Panel } = Tabs;

export default () => {

    useEffect(() => {
        document.title = "订单页"
    }, [])

    const [value, setValue] = useState("");


    // switch (value) {
    //     case '0':
    //         return <RegistrationOrder />

    //     case '1':
    //         return <FilmSelectionOrder />

    //     default:
    //         break;
    // }


    return (
        <>
            <Cell hasArrow title="报名订单" onClick={() => store.routerStore.push("/build/smiling-face-order?type=0")} />
            <Cell hasArrow title="选片订单" onClick={() => store.routerStore.push("/build/smiling-face-order?type=1")} />
        </>
    )
}

export function SmilingFaceOrder() {
    const type = getParamValue("type");

    switch (type) {
        case '0':
            return <RegistrationOrder />

        case '1':
            return <FilmSelectionOrder />

        default:
            return null
    }
}

const LOAD_STATE = {
    normal: 0, // 普通
    abort: 1, // 中止
    loading: 2, // 加载中
    success: 3, // 加载成功
    failure: 4, // 加载失败
    complete: 5, // 加载完成（无新数据）
};

/**
 * 
 * @returns 
 * /warp/selectioneroll/orderList  post
 * order_type 1 报名 2选片
 * pay_status "nopay" 未支付  "payed" 已支付
 * page
 */

// 报名订单
function RegistrationOrder() {
    const pullRef = useRef();
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(LOAD_STATE.normal);

    // 定义请求的参数
    const [reqParams, setreqParams] = useState({
        nopay: {
            pay_status: "nopay",
            page: 1
        },
        payed: {
            pay_status: "payed",
            page: 1
        }
    })

    useEffect(() => {
        loadData()
    }, [value])

    // 请求的数据
    const [listDate, setListDate] = useState([[], []])

    // 模拟加载更多数据
    const loadData = async () => {
        console.log("请求数据")
        setLoading(LOAD_STATE.loading);
        let data = value === 0 ? reqParams.nopay : reqParams.payed;
        let res = await FetchRequest("/wap/selectioneroll/orderList", {
            token: localStorage.getItem("token"),
            order_type: 1,
            ...data
        })
        if (res.code === 1) {
            if (res.data.length === 0) {
                setLoading(LOAD_STATE.complete);
                return
            }
            if (value === 0) {
                let newAray = [...listDate]
                newAray[0] = newAray[0].concat(res.data)
                setListDate(newAray)
                data.page += 1
                setreqParams({
                    ...reqParams,
                    nopay: data
                })
            } else if (value === 1) {
                let newAray = [...listDate]
                newAray[1] = newAray[1].concat(res.data)
                setListDate(newAray)
                data.page += 1
                setreqParams({
                    ...reqParams,
                    payed: data
                })
            }
            setLoading(LOAD_STATE.success);
        } else {
            Toast.show(res.msg)
            setLoading(LOAD_STATE.complete);
        }
    };

    return (
        <div className="smiling_content">
            <Tabs value={value} onChange={(e) => setValue(Number(e))}>
                <Panel title="未支付">
                    <Pull
                        ref={pullRef}
                        load={{
                            state: loading,
                            distance: 200,
                            handler: loadData,
                        }}
                    >
                        <div className={style.content}>

                            {listDate[0].map((v: { activity_name: string; class: string; baby_name: string; order_number: number; eroll_id: number }, index: number) => {
                                return (
                                    <div className={style.item} key={index}>
                                        <div>活动名称: {v.activity_name}</div>
                                        <div>班级: {v.class}</div>
                                        <div>姓名: {v.baby_name}</div>
                                        <div>订单编号: {v.order_number}</div>
                                        <Button theme="primary" size="sm" className={style.go_pay} onClick={() => store.routerStore.push(`/build/activity-registration-pay?eroll_id=${v.eroll_id}`)}>去支付</Button>
                                    </div>
                                )
                            })}

                        </div>
                    </Pull>
                </Panel>
                <Panel title="已支付">
                    <Pull
                        ref={pullRef}
                        load={{
                            state: loading,
                            distance: 200,
                            handler: loadData,
                        }}
                    >
                        <div className={style.content}>

                            {listDate[1].map((v: { activity_name: string; class: string; baby_name: string; order_number: number; activity_id: string }, index: number) => {
                                return (
                                    <div className={style.item} key={index}>
                                        <div>活动名称: {v.activity_name}</div>
                                        <div>班级: {v.class}</div>
                                        <div>姓名: {v.baby_name}</div>
                                        <div>订单编号: {v.order_number}</div>
                                        <Button theme="primary" size="sm" className={style.go_pay} onClick={() => store.routerStore.push(`/build/selection-activities/${v.activity_id}`)}>去选片</Button>
                                    </div>
                                )
                            })}

                        </div>
                    </Pull>
                </Panel>
            </Tabs>
        </div>
    )
}
// 选片订单
function FilmSelectionOrder() {
    const pullRef = useRef();
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(LOAD_STATE.normal);

    // 定义请求的参数
    const [reqParams, setreqParams] = useState({
        nopay: {
            pay_status: "nopay",
            page: 1
        },
        payed: {
            pay_status: "payed",
            page: 1
        }
    })

    useEffect(() => {
        loadData()
    }, [value])

    // 请求的数据
    const [listDate, setListDate] = useState([[], []])

    // 判断是否展示更多
    const [IsMore, setIsMore] = useState(false)

    // 模拟加载更多数据
    const loadData = async () => {
        console.log("请求数据")
        setLoading(LOAD_STATE.loading);
        let data = value === 0 ? reqParams.nopay : reqParams.payed;
        let res = await FetchRequest("/wap/selectioneroll/orderList", {
            token: localStorage.getItem("token"),
            order_type: 2,
            ...data
        })
        if (res.code === 1) {
            if (res.data.length === 0) {
                setLoading(LOAD_STATE.complete);
                return
            }
            if (value === 0) {
                let newAray = [...listDate]
                newAray[0] = newAray[0].concat(res.data)
                setListDate(newAray)
                data.page += 1
                setreqParams({
                    ...reqParams,
                    nopay: data
                })
            } else if (value === 1) {
                let newAray = [...listDate]
                newAray[1] = newAray[1].concat(res.data)
                setListDate(newAray)
                data.page += 1
                setreqParams({
                    ...reqParams,
                    payed: data
                })
            }
            setLoading(LOAD_STATE.success);
        } else {
            Toast.show(res.msg)
            setLoading(LOAD_STATE.complete);
        }
    };

    return (
        <div className="smiling_content">
            <Tabs value={value} onChange={(e) => setValue(Number(e))}>
                <Panel title="未支付">
                    <Pull
                        ref={pullRef}
                        load={{
                            state: loading,
                            distance: 200,
                            handler: loadData,
                        }}
                    >
                        <div className={style.content}>

                            {listDate[0].map((v: { order_number: number; activity_name: string; eroll_id: number; meal_type: string; datalist: { cut_price: number; sum_num: number; total_price: number; images: Array<{ id: number; object?: string[]; or_w_img: string; }> } }, index: number) => {
                                return (
                                    <div className={style.item} key={index}>
                                        <div>活动名称: {v.activity_name}</div>
                                        <div>订单编号: {v.order_number}</div>
                                        <div className={style.item_images} style={IsMore ? {} : { height: 155 }}>
                                            <ImagesLook images={v.datalist.images} />
                                            {v.datalist.images.length > 4 && (
                                                <div className={style.loadingMore} onClick={() => setIsMore(!IsMore)} style={IsMore ? { bottom: 0, top: 'inherit' } : {}}>{IsMore ? '点击收起' : '加载更多'}</div>
                                            )}
                                        </div>
                                        {v.datalist.cut_price !== 0 && (
                                            <div className={style.item_price}>
                                                <div>共计 (已优惠{v.datalist.cut_price}元)</div>
                                                <div>¥{v.datalist.total_price}元</div>
                                            </div>
                                        )}
                                        <div className={style.item_go_pay}>
                                            <Button theme="primary" size="sm" onClick={() => store.routerStore.push(`/build/selection-activities-pay?eroll_id=${v.eroll_id}&meal_type=${v.meal_type}`)}>去支付</Button>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </Pull>
                </Panel>
                <Panel title="已支付">
                    <Pull
                        ref={pullRef}
                        load={{
                            state: loading,
                            distance: 200,
                            handler: loadData,
                        }}
                    >
                        <div className={style.content}>

                            {listDate[1].map((v: { order_number: number; activity_name: string; datalist: { cut_price: number; sum_num: number; total_price: number; images: Array<{ id: number; object?: string[]; or_w_img: string; }> } }, index: number) => {
                                return (
                                    <>
                                        <div className={style.item} key={index}>
                                            <div>活动名称: {v.activity_name}</div>
                                            <div style={{ padding: '7px 0' }}>订单编号: {v.order_number}</div>
                                            <div className={style.item_images}>
                                                <ImagesLook images={v.datalist.images} />
                                            </div>
                                            <div className={style.item_price}>
                                                <div>共计 (已优惠{v.datalist.cut_price}元)</div>
                                                <div>¥{v.datalist.total_price}元</div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}

                        </div>
                    </Pull>
                </Panel>
            </Tabs>
        </div>
    )
}


function ImagesLook(props: {
    images: Array<{ id: number; object?: string[]; or_w_img: string; }>
}) {
    const [visible, setVisible] = useState(false);
    const [pictureIndex, setPictureIndex] = useState(0);
    const hide = () => setVisible(false);

    const dd = useMemo(() => {
        let img: string[] = []
        for (let i = 0; i < props.images.length; i++) {
            const item = props.images[i];
            img.push(item.or_w_img)
        }
        return img
    }, props.images)

    return (
        <>
            {/* {props.images.map((j, index) => {
                return (
                    <div key={j.id} style={{ height: 92 }} onClick={() => {
                        setPictureIndex(index)
                        setVisible(true)
                    }}>
                        <img src={j.or_w_img} alt="" />
                        {j.object?.map((s, index_s: number) => {
                            return <div key={index_s}>{s}</div>
                        })}
                    </div>
                )
            })} */}

            {props.images.map((j, index: number) => {
                return (
                    <div key={j.id} style={{ width: 80, height: 160 }} onClick={() => {
                        setPictureIndex(index)
                        setVisible(true)
                    }}>
                        <div style={{ height: 80, border: '1px solid #c3c3c3', borderRadius: 8 }}>
                            <img src={j.or_w_img} alt="" />
                        </div>
                        {j.object?.map((s, index_s: number) => {
                            return <div key={index_s}>{s}</div>
                        })}
                    </div>
                )
            })}


            <ImagePreview
                visible={visible}
                images={dd}
                onClose={hide}
                activeIndex={pictureIndex}
            />
        </>
    )
}


function MoreLoadingLook(props: {
    children: ReactComponentElement<any>
}) {
    const ref: any = useRef()

    const [isStatus, setisStatus] = useState(false)

    useEffect(() => {
        if (ref.current.offsetHeight > 200) {
            setisStatus(true)
        }
    }, [props.children])

    return props.children
    // return (
    //     <div ref={ref} style={isStatus ? { height: 200, position: 'relative', width: '100%' } : {}}>
    //         {props.children}
    //         <div className={style.action_bg}>
    //             <div onClick={() => setisStatus(!isStatus)}>{isStatus ? '点击加载更多' : '点击收起'}</div>
    //         </div>
    //     </div>
    // )
}