import React from 'react'
import './preorder-schedule.scss'
import { store } from '../../store/store.root'
import { observer } from 'mobx-react'
import { BangBangTangLayout } from '../../layout/layout'
import {
  ActivityIndicator,
  Button,
  Calendar,
  Cell,
  Checkbox,
  DatePicker,
  Input,
  Loading,
  Modal,
  Picker,
  Popup,
  Radio,
  Toast,
} from 'zarm'
import { format } from 'date-fns'
import { MonthCalendar } from './calendar-month'
import { TimePicker } from './time-picker'
import { preorderScheduleActions } from '../../store/preorder-schedule/preorder-schedule.action'
import { ScheduleAlreadyPreorder } from './schedule-already-preorder'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'
import { CustomerConfirmModal } from './customer-confirm-modal'
import { CustomerInfoModal } from './customer-info-modal'
import { toJS } from 'mobx'
import dayjs from 'dayjs'
import { FetchRequest } from '../../utils/fetch'

@observer
export class PreorderSchedule extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      date_min_time: dayjs().add(1, 'day').format('YYYY-MM-DD'), // 最小选择的日期
      date_show: false, // 选择档期日期弹窗
      date_value: '', // 档期时间
      schedule_time_list: [], // 档期时间列表
      schedule_time_show: false, // 档期时间开关
      baby_name: '', // 宝宝姓名
      baby_gender: '1', // 宝宝性别 1=男，2=女
      baby_birthday: '', // 宝宝生日
      baby_birthday_show: false, // 生日日期选择器
    }
  }

  componentWillUnmount() {
    store.preorderScheduleStore.initial()
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      window.document.title = '预约档期'
      // 当页面载入时获取门店列表
      preorderScheduleActions.getScheduleOptions()
      // preorderScheduleActions.getUserScheduleData()
      // preorderScheduleActions.getStoreData()
    })
  }

  handleGetAgeTagList(): any[] {
    const find_select = (
      toJS(store.preorderScheduleStore.schedule_type.choices) || []
    ).find((v: any) => v.id === store.preorderScheduleStore.schedule_type.value)
    if (!find_select) {
      return []
    }
    return (find_select as any).agetTagList
  }

  handleTagIdToName() {
    const s = store.preorderScheduleStore.package_age_tag.value
    if (!s) {
      return '请选择'
    }
    let t = ''
    const base_list = this.handleGetAgeTagList()
    const id_list = s.split(',').filter((v) => parseInt(v) > 0)

    for (let i = 0; i < id_list.length; i++) {
      const item = id_list[i]
      for (let j = 0; j < base_list.length; j++) {
        const item_j = base_list[j]
        if (item === item_j.id + '') {
          t = t + item_j.name + ','
        }
      }
    }
    return t
  }

  handleFormToName() {
    let t = ''
    const base_list = store.preorderScheduleStore.form_type.value
    const id_list = store.preorderScheduleStore.form_type.list

    for (let i = 0; i < id_list.length; i++) {
      const item = id_list[i]
      for (let j = 0; j < base_list.length; j++) {
        const item_j = base_list[j]
        if (item.id === item_j) {
          t = t + item.name + ','
        }
      }
    }
    if (!t) {
      return '请选择形式'
    }
    return t
  }

  handleGetScheduleInquiry = async () => {
    if (!store.preorderScheduleStore.package_age_tag.value) {
      Toast.show('请先选择年龄')
      return
    }
    Loading.show({
      content: <ActivityIndicator size="lg" />,
      stayTime: 1000,
    })
    const r = await FetchRequest('/wap/schedule/getScheduleInquiry', {
      schedule_type: store.preorderScheduleStore.schedule_type.value,
      schedule_date: this.state.date_value,
      age_tag: store.preorderScheduleStore.package_age_tag.value,
      shoot_form: store.preorderScheduleStore.form_type.value.join(','),
      token: localStorage.getItem('token'),
    })
    Loading.hide()
    if (r.code === 1) {
      this.setState({ schedule_time_list: r.data, schedule_time_show: true })
    } else {
      Toast.show(r.msg)
    }
  }

  handleSelectTimeItem = async (item: any) => {
    if (item.surplus_num1 === 0) {
      Toast.show('档期已满，无法预约')
      return
    }
    if (item.rule_limit_desc) {
      const modal = Modal.alert({
        title: '提示',
        content: item.rule_limit_desc,
        onCancel: () => {
          modal.hide()
        },
      })
      return
    }
    if (!this.state.baby_name) {
      Toast.show('请输入宝宝姓名')
      return
    }
    if (!this.state.baby_birthday) {
      Toast.show('请输入宝宝生日')
      return
    }
    Loading.show({
      content: <ActivityIndicator size="lg" />,
      stayTime: 1000,
    })
    let application_type = ''
    if (item.surplus_num1 > 0) {
      application_type = '1'
    }
    if (item.surplus_num1 === 0 && item.surplus_num3 > 0) {
      application_type = '3'
    }
    const r = await FetchRequest('/wap/schedule/submitScheduleApplication', {
      token: localStorage.getItem('token'),
      inquiry_id: item.id,
      application_type: application_type,
      age_tag: store.preorderScheduleStore.package_age_tag.value,
      shoot_form: store.preorderScheduleStore.form_type.value,
      baby_name: this.state.baby_name,
      baby_gender: this.state.baby_gender,
      baby_birthday: this.state.baby_birthday,
      has_package: store.preorderScheduleStore.schedule_package.value,
    })
    Loading.hide()
    if (r.code === 1) {
      Toast.show(r.msg)
      setTimeout(() => {
        this.setState(
          {
            schedule_time_show: false,
          },
          () => {
            store.routerStore.replace('/build')
          }
        )
      }, 2000)
    } else {
      Toast.show(r.msg)
    }
  }

  render() {
    const { preorderScheduleStore } = store
    return (
      <BangBangTangLayout maskLoading={store.preorderScheduleStore.pageLoading}>
        <div className="preorder-schedule">
          <ScheduleAlreadyPreorder />
          <Cell
            onClick={() =>
              preorderScheduleStore.selectedStoreCellDisabled
                ? null
                : preorderScheduleActions.togglePickerVisible('store')
            }
            title={'选择门店'}
            disabled={preorderScheduleStore.selectedStoreCellDisabled}
            description={preorderScheduleStore.selectedStoreCellDisplayValue}
          />
          {preorderScheduleStore.store.value !== null && (
            <Cell
              onClick={() =>
                preorderScheduleActions.togglePickerVisible('package')
              }
              title={'是否有套餐'}
              description={
                preorderScheduleStore.schedule_package.value === 'yes'
                  ? '有套餐'
                  : preorderScheduleStore.schedule_package.value === 'no'
                  ? '无套餐'
                  : '点我选择'
              }
            />
          )}
          {preorderScheduleStore.store.value &&
            preorderScheduleStore.schedule_package.value && (
              <Cell
                disabled={
                  preorderScheduleStore.selectedScheduleTypeCellIsDisabled
                }
                onClick={() =>
                  preorderScheduleStore.selectedScheduleTypeCellIsDisabled
                    ? null
                    : preorderScheduleActions.togglePickerVisible(
                        'scheduleType'
                      )
                }
                title={'选择档期类型'}
                description={
                  store.preorderScheduleStore
                    .selectedScheduleTypeCellDisplayValue
                }
              />
            )}
          {/* 请选择年龄 */}
          {preorderScheduleStore.store.value &&
            store.preorderScheduleStore.selectedScheduleTypeCellDisplayValue !==
              '点我选择' && (
              <Cell
                onClick={() => {
                  preorderScheduleActions.togglePickerVisible('package_age_tag')
                }}
                title={<div style={{ width: 100 }}>选择年龄</div>}
                description={
                  preorderScheduleStore.package_age_tag.value
                    ? this.handleTagIdToName()
                    : '点我选择'
                }
              />
            )}

          {preorderScheduleStore.store.value &&
            preorderScheduleStore.schedule_package.value &&
            preorderScheduleStore.package_age_tag.value && (
              <Cell
                onClick={() => {
                  preorderScheduleActions.togglePickerVisible('from')
                }}
                title={<div style={{ width: 100 }}>选择形式</div>}
                description={this.handleFormToName()}
              />
            )}
          {preorderScheduleStore.store.value &&
            preorderScheduleStore.schedule_package.value &&
            preorderScheduleStore.schedule_type.value &&
            preorderScheduleStore.package_age_tag.value &&
            preorderScheduleStore.form_type.value.length > 0 && (
              <Cell
                onClick={() => {
                  this.setState({
                    date_show: true,
                  })
                }}
                title={<div style={{ width: 100 }}>预约日期</div>}
                description={
                  this.state.date_value
                    ? this.state.date_value
                    : '请选择预约日期'
                }
              />
            )}
          {this.state.date_value && (
            <>
              {/* <div
                style={{
                  height: 10,
                }}
              ></div> */}
              <Cell
                title="宝宝姓名"
                description={
                  <div style={{ width: 120 }}>
                    <Input
                      style={{
                        textAlign: 'right',
                      }}
                      type="text"
                      placeholder="请输入宝宝姓名"
                      value={this.state.baby_name}
                      onChange={(e) => {
                        this.setState({ baby_name: e })
                      }}
                    />
                  </div>
                }
              ></Cell>
              <Cell
                description={
                  <Radio.Group
                    type="button"
                    value={this.state.baby_gender}
                    onChange={(value) => {
                      this.setState({
                        baby_gender: value,
                      })
                    }}
                  >
                    <Radio value="1">男</Radio>
                    <Radio value="2">女</Radio>
                  </Radio.Group>
                }
              >
                宝宝性别
              </Cell>

              <Cell
                title="宝宝生日"
                description={
                  this.state.baby_birthday
                    ? this.state.baby_birthday
                    : '点我选择'
                }
                onClick={() => this.setState({ baby_birthday_show: true })}
              ></Cell>
            </>
          )}

          {this.state.date_value && (
            <div className="view_schedule">
              <Button
                shape="round"
                theme="primary"
                block
                size="sm"
                onClick={this.handleGetScheduleInquiry}
              >
                查看档期
              </Button>
            </div>
          )}

          <Popup
            visible={this.state.schedule_time_show}
            direction="top"
            afterClose={() => {}}
          >
            <div className="view_schedule_content">
              <div className="view_schedule_content_top">
                <div className="schedule_text">请选择档期时间</div>
                <div
                  className="close_schedule_content"
                  onClick={() => this.setState({ schedule_time_show: false })}
                >
                  关闭
                </div>
              </div>
              {this.state.schedule_time_list.map((v: any) => {
                return (
                  <Cell
                    key={v.id}
                    title={
                      <div>
                        <div
                          style={{
                            color: v.surplus_num1 === 0 ? 'red' : '#000',
                          }}
                        >
                          {v.schedule_time} {v.surplus_num1 === 0 && '/满'}
                        </div>
                      </div>
                    }
                    description={
                      <div>
                        {v.surplus_num1 === 0 && v.surplus_num3 > 0 && (
                          <div>【点击备档】</div>
                        )}
                      </div>
                    }
                    onClick={() => this.handleSelectTimeItem(v)}
                  />
                )
              })}
            </div>
          </Popup>

          <DatePicker
            visible={this.state.baby_birthday_show}
            mode="date"
            value={this.state.baby_birthday}
            onOk={(value: any) => {
              this.setState({
                baby_birthday_show: false,
                baby_birthday: dayjs(value).format('YYYY-MM-DD'),
              })
            }}
            onCancel={() => this.setState({ baby_birthday_show: false })}
          />

          <DatePicker
            visible={this.state.date_show}
            title="预约日期"
            okText="确定"
            cancelText="取消"
            mode="date"
            min={this.state.date_min_time}
            max="2050-1-1"
            value={this.state.date_value}
            onOk={(value: any) => {
              this.setState({
                date_show: false,
                date_value: dayjs(value).format('YYYY-MM-DD'),
              })
            }}
            onCancel={() => this.setState({ date_show: false })}
          />
          {/* {preorderScheduleStore.schedule_type.value !== null ? (
            <Cell
              disabled={preorderScheduleStore.selectedDateCellIsDisabled}
              onClick={() =>
                preorderScheduleStore.selectedDateCellIsDisabled
                  ? null
                  : preorderScheduleActions.togglePickerVisible('date')
              }
              title={'选择档期日期'}
              description={
                preorderScheduleStore.available_date.value
                  ? format(
                      preorderScheduleStore.available_date.value,
                      'yyyy-MM-dd'
                    )
                  : preorderScheduleStore.available_date.picker_visible
                  ? '隐藏'
                  : '显示'
              }
            />
          ) : null} */}
          <MonthCalendar />
          <TimePicker />
          <CustomerConfirmModal />
          <CustomerInfoModal />
          <Modal
            visible={preorderScheduleStore.form_type.modal_visible}
            title="请选择形式(多选)"
            closable
            onCancel={() => preorderScheduleActions.togglePickerVisible('from')}
          >
            <div>
              <div>
                <Checkbox.Group
                  type="cell"
                  value={store.preorderScheduleStore.form_type.value}
                  onChange={(e) => {
                    store.preorderScheduleStore.form_type.value = e as number[]
                  }}
                >
                  {store.preorderScheduleStore.form_type.list.map((v, i) => {
                    return (
                      <Checkbox key={i} value={v.id}>
                        {v.name}
                      </Checkbox>
                    )
                  })}
                </Checkbox.Group>
              </div>
              <div style={{ marginTop: 30 }}>
                <Button
                  block
                  theme="primary"
                  size="xs"
                  onClick={() =>
                    preorderScheduleActions.togglePickerVisible('from')
                  }
                >
                  确定
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            visible={preorderScheduleStore.package_age_tag.modal_visible}
            title="请选择年龄标签"
            closable
            onCancel={() =>
              preorderScheduleActions.togglePickerVisible('package_age_tag')
            }
          >
            <div className="tag_content_">
              {this.handleGetAgeTagList().map((v, i) => {
                return (
                  <span
                    className={`tag_item ${
                      preorderScheduleStore.package_age_tag.value.indexOf(
                        v.id + ''
                      ) === -1
                        ? ''
                        : 'tag_item_activit'
                    }`}
                    key={i}
                    onClick={() =>
                      preorderScheduleActions.handleSelectAgeTag(v)
                    }
                  >
                    {v.name}
                  </span>
                )
              })}
            </div>
            <div style={{ marginTop: 30 }}>
              <Button
                block
                theme="primary"
                size="xs"
                onClick={() =>
                  preorderScheduleActions.togglePickerVisible('package_age_tag')
                }
              >
                确定
              </Button>
            </div>
          </Modal>
          <Picker
            destroy={false}
            visible={preorderScheduleStore.schedule_package.picker_visible}
            title={'请选择是否有套餐'}
            itemRender={(data: any) => data.label}
            onCancel={() =>
              preorderScheduleActions.togglePickerVisible('package')
            }
            onOk={(selected: any) => {
              preorderScheduleActions.setSchedulePackageValue(
                selected[0].value as string
              )
            }}
            dataSource={[
              { value: 'yes', label: '有套餐' },
              { value: 'no', label: '无套餐' },
            ]}
          />
          <Picker
            destroy={false}
            visible={preorderScheduleStore.store.picker_visible}
            onCancel={() =>
              preorderScheduleActions.togglePickerVisible('store')
            }
            title={'请选择门店'}
            itemRender={(data: any) => data.label}
            onOk={(selected: any) => {
              const selected_value = selected[0] ? selected[0].value : null
              preorderScheduleActions.setStoreValue(selected_value as any)
              // 清空操作
              this.setState({
                date_value: '',
              })
            }}
            defaultValue={
              preorderScheduleStore.store.value
                ? [preorderScheduleStore.store.value]
                : undefined
            }
            dataSource={preorderScheduleStore.selectedStoreCellDataSource}
          />
          <Picker
            destroy={false}
            itemRender={(item: any) => item.label}
            visible={preorderScheduleStore.schedule_type.picker_visible}
            defaultValue={
              preorderScheduleStore.schedule_type.value
                ? [preorderScheduleStore.schedule_type.value]
                : undefined
            }
            onCancel={() =>
              preorderScheduleActions.togglePickerVisible('scheduleType')
            }
            title={'请选择档期类型'}
            onOk={(selected) => {
              const selected_value = selected[0] ? selected[0].value : null
              preorderScheduleActions.setScheduleTypeValue(
                selected_value as any
              )
            }}
            dataSource={
              preorderScheduleStore.selectedScheduleTypeCellDataSource
            }
          />
        </div>
      </BangBangTangLayout>
    )
  }
}
