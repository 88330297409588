import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { store } from "./store.root";
import { delay, filter, take, takeUntil, tap } from "rxjs/operators";

export const appIsReadySubject = new BehaviorSubject(false); // 当此subject发出true时，代表app后台权限验证流程已经结束，可以渲染，当发出false时，代表又进入了权限验证流程
export const pageChangedSubject = new Subject<null>();
export const appIsFirstReadySubject = appIsReadySubject.pipe(
  filter((value) => value),
  take(1),
  takeUntil(pageChangedSubject)
); // 当此Observer发出任何值时，代表首次app后台权限验证流程已经结束，可以做初始化请求
setTimeout(() => {
  combineLatest([
    store.websiteRuleStore.$websiteRuleComplete,
    store.userStore._loginComplete,
  ])
    .pipe(
      tap(console.log),
      filter((value) => value[0] && value[1])
    )
    .subscribe(() => appIsReadySubject.next(true));
}, 0);
