import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import {
  Button,
  Cell,
  Icon,
  Message,
  Modal,
  NoticeBar,
  Radio,
  Toast,
} from "zarm";
import style from "./index.module.scss";
import { DatetimePicker, Overlay } from "react-vant";
import { FetchRequest } from "../../utils/fetch";
import { store } from "../../store/store.root";
import { observer } from "mobx-react";


export default observer(() => {
  const [select, setSelect] = useState("0");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (store.userStore.token) {
      GetSetTime();
    }
  }, [store.userStore.token]);

  const [NoticeData, setNoticeData] = useState<{
    notice_status?: string;
    notice_date?: string;
  }>({});

  async function GetSetTime() {
    let result = await FetchRequest(
      `/wap/data/getGenealogyNoticeData?token=${store.userStore.token || localStorage.getItem("token")}`,
      {},
      "GET"
    );
    if (result.code === 1) {
      setNoticeData(result.data);
      if (result.data.notice_status === "yes") {
        setSelect("0");
      } else {
        setSelect("1");
      }
    } else {
      Toast.show(result.msg);
    }
  }

  function handleChang(e: any) {
    switch (e) {
      case "1":
        //  关闭
        const modal = Modal.confirm({
          title: "确认信息",
          content: "确认关闭提醒吗?",
          onCancel: () => {
            console.log("点击cancel");
          },
          onOk: async () => {
            let result = await FetchRequest(
              "/wap/data/setGenealogyNoticeData",
              {
                token: localStorage.getItem("token"),
                notice_status: "no",
                notice_date: NoticeData.notice_date,
              }
            );
            if (result.code === 1) {
              Toast.show("修改成功");
              setSelect(String(e));
              GetSetTime();
            } else {
              Toast.show(result.msg);
            }
          },
        });
        break;

      case "0":
        // 允许
        setVisible(true);
        setSelect(String(e));
        break;

      default:
        break;
    }
  }

  return (
    <>
      <div className={style.content_time}>
        <div className={style.time_title}>下次提醒时间:</div>
        <div className={style.time_count} style={select === "1" ? {color: '#eee'} : {}}>
          <div className={style.t_X}>
            {NoticeData.notice_date}
          </div>
          {/* <div className={style.t_s}>月</div>
          <div className={style.t_X}>
            {NoticeData.notice_date?.split("-")[1] || "-"}
          </div>
          <div className={style.t_s}>日</div> */}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span className={style.update_time} onClick={() => setVisible(true)}>
            点击修改提醒时间
          </span>
        </div>
        {/* 按钮 */}
        <div className={style.btn_fix}>
          <div style={{ marginBottom: 30, textAlign: 'center' }}>
            <Radio.Group
              value={select}
              onChange={(e) => handleChang(e)}
            >
              <Radio value="1">关闭提醒</Radio>
              <Radio value="0">允许提醒</Radio>
            </Radio.Group>
          </div>
          <Button block theme="primary" onClick={() => {
            store.routerStore.replace("/build/photo-family-collection")
          }}>
            查看往年照片
          </Button>
        </div>
      </div>
      {/* 时间选择 */}
      <Overlay visible={visible}>
        <div>
          <DatetimePicker
            onConfirm={async (v: any) => {
              const time = format(new Date(v), "yyyy-MM-dd");
              const date = `${time.split("-")[1]}-${time.split("-")[2]}`;

              if (select === "1") {
                const modal = Modal.confirm({
                  title: "确认信息",
                  content: "确认开启提醒吗?",
                  onCancel: () => {
                    console.log("点击cancel");
                  },
                  onOk: async () => {
                    let result = await FetchRequest(
                      "/wap/data/setGenealogyNoticeData",
                      {
                        token: localStorage.getItem("token"),
                        notice_status: "yes",
                        notice_date: date,
                      }
                    );
                    if (result.code === 1) {
                      Toast.show("修改成功");
                      GetSetTime();
                    } else {
                      Toast.show(result.msg);
                    }
                  },
                });
                setVisible(false);
                return
              }

              let result = await FetchRequest(
                "/wap/data/setGenealogyNoticeData",
                {
                  token: localStorage.getItem("token"),
                  notice_status: select === "0" ? "yes" : "no",
                  notice_date: date,
                }
              );
              if (result.code === 1) {
                Toast.show("修改成功");
                GetSetTime();
              } else {
                Toast.show(result.msg);
              }

              setVisible(false);
            }}
            cancelButtonText=" "
            className={style.DatetimePicker_v}
            type="month-day"
            minDate={new Date(2020, 0, 1)}
            maxDate={new Date(2040, 10, 1)}
            value={new Date()}
            formatter={(type: string, val: string) => {
              if (type === "month") {
                return `${val}月`;
              }
              if (type === "day") {
                return `${val}日`;
              }
              return val;
            }}
          />
        </div>
      </Overlay>
    </>
  );

  return (
    <div className={style.content}>
      <div style={{ marginTop: 40 }}>
        <Cell
          help={
            select === "0" && (
              <div
                onClick={() => {
                  setVisible(true);
                }}
              >
                <Message
                  size="lg"
                  theme="success"
                  icon={<Icon type="warning-round" size="sm" />}
                >
                  <div className={style.tipes_time}>
                    <div>下次提醒时间: {NoticeData.notice_date}</div>
                    <div>修改提醒时间</div>
                  </div>
                </Message>
              </div>
            )
          }
        >
          <Radio.Group
            type="cell"
            style={{ width: "100%" }}
            value={select}
            onChange={(e) => handleChang(e)}
          >
            <Radio value="1">关闭提醒</Radio>
            <Radio value="0">允许提醒</Radio>
          </Radio.Group>
        </Cell>
      </div>
      {/* 按钮 */}
      <div className={style.btn_fix}>
        <Button block theme="primary">
          查看往年照片
        </Button>
      </div>
      {/* 时间选择 */}
      <Overlay visible={visible}>
        <div>
          <DatetimePicker
            onConfirm={async (v: any) => {
              const time = format(new Date(v), "yyyy-MM-dd");
              const date = `${time.split("-")[1]}-${time.split("-")[2]}`;

              let result = await FetchRequest(
                "/wap/data/setGenealogyNoticeData",
                {
                  token: localStorage.getItem("token"),
                  notice_status: select === "0" ? "yes" : "no",
                  notice_date: date,
                }
              );
              if (result.code === 1) {
                Toast.show("修改成功");
                GetSetTime();
              } else {
                Toast.show(result.msg);
              }

              setVisible(false);
            }}
            cancelButtonText=" "
            className={style.DatetimePicker_v}
            type="month-day"
            minDate={new Date(2020, 0, 1)}
            maxDate={new Date(2025, 10, 1)}
            value={new Date()}
            formatter={(type: string, val: string) => {
              if (type === "month") {
                return `${val}月`;
              }
              if (type === "day") {
                return `${val}日`;
              }
              return val;
            }}
          />
        </div>
      </Overlay>
    </div>
  );
});
