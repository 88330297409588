import React, { useState, useEffect } from "react";
import { FetchRequest } from "../../utils/fetch";
import { Cell, Toast, Loading } from "zarm";
import { store } from "../../store/store.root";
import style from "./index.module.scss";
import { getParamValue } from "../../utils/url";
import { observer } from "mobx-react";

const menuStatusPage = {
  wait_for_confirm: "等待确认",
  wait_for_upload: "等待制作",
  revising: "修改中",
  finalized: "定稿",
  making: "已送厂",
};

export default observer(() => {
  useEffect(() => {
    document.title = "设计稿";
    if (store.userStore.token) {
      handlGetPageList();
    }
  }, [store.userStore.token]);

  const [pageList, setpageList] = useState<
    Array<{
      lists?: {
        auto_delete_time_text: string;
        create_time_text: string;
        customer_name: string;
        id: number;
        is_overdue_upload_text: string;
        mobile: string;
        notice_status_text: string;
        order_sn: string;
        page_status:
          | "wait_for_confirm"
          | "wait_for_upload"
          | "revising"
          | "finalized"
          | "making";
        page_status_text: string;
        update_time_text: string;
        upload_time_text: string;
        user_id: number;
      };
      page_image?: string;
      wei_que_ren?: number;
      yi_que_ren?: number;
    }>
  >([]);

  async function handlGetPageList() {
    Loading.show();
    let result = await FetchRequest("/wap/Photopage/getPhotopageLists", {
      token: store.userStore.token,
    });
    if (result.code === 1) {
      setpageList(result.data.listsdata);
      // 判断是否为一条数据 如果是则直接跳
      if (result.data.listsdata.length === 1) {
        // store.routerStore.replace(`/build/confirmation-page/detaile/${result.data.listsdata[0].lists.id}`)
        window.location.href = `http://page.mjz365.top/page?photopage_id=${result.data.listsdata[0].lists.id}&token=${store.userStore.token}&origin=${window.location.origin}`
      }
    } else {
      Toast.show(result.msg);
    }
    Loading.hide();
  }

  return (
    <div>
      <div className={style.confirm_Page}>
        {pageList.map((v, index: number) => {
          return (
            <Cell
              key={index}
              style={{
                width: "100%",
              }}
              onClick={() => {
                // window.open(
                //   `http://page.mjz365.top/page/confirmation-page/edit?photopage_id=${v.lists?.id}&token=${store.userStore.token}`
                // );
                window.location.href = `http://page.mjz365.top/page?photopage_id=${v.lists?.id}&token=${store.userStore.token}&origin=${window.location.origin}`
                // store.routerStore.push(`/build/confirmation-page/detaile/`)
              }}
              title={
                <div className="box" style={{ position: "relative" }}>
                  <div>客户姓名: {v.lists?.customer_name}</div>
                  <div className={style.confirm_name}>
                    订单编号: {v.lists?.order_sn}
                  </div>
                  <div className="box-description">
                    {/* <div className={style.confirm_name}>状态: {menuStatusPage[v.lists!.page_status]}</div> */}
                    <div className={style.confirm_name}>
                      确认{v.yi_que_ren}/未确认{v.wei_que_ren}
                    </div>
                    <div className={style.confirm_name}>
                      上传日期:{v.lists?.upload_time_text}
                    </div>
                  </div>
                  <div
                    className={`${style.triangle_topright} ${
                      menuStatusPage[v.lists!.page_status] === "等待确认"
                        ? style.WaitingConfirmation_index
                        : ""
                    } ${
                      menuStatusPage[v.lists!.page_status] === "等待制作"
                        ? style.wait_for_revising_index
                        : ""
                    } ${
                      menuStatusPage[v.lists!.page_status] === "修改中"
                        ? style.updateing_index
                        : ""
                    } ${
                      menuStatusPage[v.lists!.page_status] === "定稿"
                        ? style.Confirmed_index
                        : ""
                    } ${
                      menuStatusPage[v.lists!.page_status] === "已送厂"
                        ? style.Confirmed_index
                        : ""
                    }`}
                  >
                    <span className={style.tag_text}>
                      {menuStatusPage[v.lists!.page_status]}
                    </span>
                  </div>
                </div>
              }
              icon={
                <img
                  src={v.page_image}
                  alt=""
                  style={{ width: 127, borderRadius: 5 }}
                />
              }
            />
          );
        })}
        {pageList.length === 0 && (
          <div className={style.data_null}>您还没有可确认的设计稿</div>
        )}
      </div>
    </div>
  );
});
