import React, { useState, useEffect } from "react"
import { FetchRequest } from "../../utils/fetch"
import { getParamValue } from "../../utils/url"
import style from "./index.module.scss"
import { Toast, Button } from "zarm"
import { store } from "../../store/store.root"

type EvaCouponListType = {
    card_image: string;
    card_intro: string;
    card_name: string;
    coupon_id: number;
    createtime: string;
    id: number
    inform: string;
    inform_text: string;
    is_select: number;
    max_join: number;
    shop_id: number;
    status: number;
    tags_id: string;
    tags_id_text: string;
}

export default () => {

    useEffect(() => {
        document.title = "领取大礼包"
        if (getParamValue("relation_id")) {
            getEvaCouponList()
        } else {
            store.routerStore.replace(`/build`)
        }
    }, [])

    const [collectBagList, setCollectBagList] = useState<EvaCouponListType[]>([])

    // 用户选中数
    const [count, setCount] = useState<number[]>([])
    // 接口默认已经选中
    const [countDefault, setcountDefault] = useState<number[]>([])

    async function getEvaCouponList() {
        let res: {
            code: number;
            data: EvaCouponListType[],
            msg: null | string;
        } = await FetchRequest("/wap/evaluate/getEvaCouponList", {
            token: localStorage.getItem("token"),
            relation_id: getParamValue("relation_id"),
        })
        if (res.code === 1) {
            setCollectBagList(res.data)
            let count: number[] = []
            for (let i = 0; i < res.data.length; i++) {
                const item = res.data[i];
                if (item.is_select === 1) {
                    count.push(item.id)
                }
            }
            setCount(count)
            setcountDefault(count)
            // store.routerStore.replace("/build")
        } else {
            Toast.show({
                content: res.msg,
                stayTime: 5000,
            });
        }
    }

    function handleClickSelect(id: number) {
        if (countDefault.indexOf(id) > -1) {
            Toast.show("您已领取不可取消")
            return
        }
        if (count.indexOf(id) > -1) {
            let newCunt = [...count]
            newCunt.splice(count.indexOf(id), 1)
            setCount(newCunt)
        } else {
            if (count.length === 3) {
                Toast.show("您不可选取")
            } else{
                setCount([ ...count, id ])
            }
        }
    }

    async function handleClickSubForm() {
        let res = await FetchRequest("/wap/evaluate/drawCoupon", {
            token: localStorage.getItem("token"),
            activity_ids: count.join(","),
            relation_id: getParamValue("relation_id"),
        })
        if (res.code === 1) {
            Toast.show(res.msg)
            setCount([])
            store.routerStore.replace("/build")
        } else {
            Toast.show({
                content: res.msg,
                stayTime: 5000,
            });
        }
    }

    return (
        <div className={style.list}>
            <div className={style.collect_list}>
                {collectBagList.map((v: EvaCouponListType) => {
                    return (
                        <div className={`${style.collect_item} ${count.indexOf(v.id) > -1 && style.select_action}`} key={v.id} onClick={() => handleClickSelect(v.id)}>
                            <p className={style.collect_see}>
                                <span onClick={() => store.routerStore.push(`/build/card-coupon-detaile/${v.id}?relation_id=${getParamValue("relation_id")}`)}>查看详情</span>
                            </p>
                            <div className={style.collect_img}>
                                <img src={v.card_image} alt="" />
                                <span className={style.icon_border_left}></span>
                                <span className={style.icon_border_right}></span>
                            </div>
                            <div className={style.collect_name}>
                                <p className={style.collect_title}>{v.card_name}</p>
                                <p className={style.collect_desc}>{v.card_intro}</p>
                            </div>
                            <div className={style.collect_see}>
                                <span style={{ color: count.indexOf(v.id) > -1 ? 'rgb(199 199 199)' : "#f5a006" }}>{count.indexOf(v.id) > -1 ? '已选中' : '点击选中'}</span>
                            </div>
                        </div>
                    )
                })}
                <div className={style.collect_btn_form}>
                    <div className={style.collect_btn_text}>
                        <div className={style.collect_btn_warp_text}>{count.length}/3</div>
                        <div className={style.collect_btn_text}>已选择/可领取</div>
                    </div>
                    <div className={style.btn_sub}>
                        <Button block theme="primary" disabled={count.length == 0 ? true : false} onClick={handleClickSubForm}>立即领取</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}