import React from "react"
import style from "./index.module.scss"
import { NavBar, Icon } from 'zarm';

export default (props: {
    link?: string | undefined,
    title: string
}) => {
    const { link, title } = props;
    return (
        <NavBar
            left={
                <Icon
                    type="arrow-left"
                    theme="primary"
                    onClick={() => {
                        if (link) {
                            window.location.replace("props.link")
                        } else {
                            window.history.back()
                        }
                    }}
                />
            }
            title={(
                <div className={style.Header_warp}>
                    {title}
                </div>
            )}
        />
    )
}