import { action, autorun, makeAutoObservable, observable } from "mobx";

export class clothingStore {

    username = ""
    mobile = ""
    limit = ""
    tag = ""

    constructor() {
        makeAutoObservable(this);
    }

    // 写入服装列表
    setBookingOrderInformation(username = "", mobile = "", limit = "") {
        this.username = username
        this.mobile = mobile
        this.limit = limit
    }

    setTag(tag = "") {
        this.tag = tag
    }
}
