import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { store } from "../../store/store.root";
import { FetchRequest } from "../../utils/fetch";
import { BangBangTangRequest } from "../../utils/request";
import { getParamValue } from "../../utils/url";
import style from "./index.module.scss";
import { Toast, Loading, ActivityIndicator } from "zarm"

function TestExamination(props: any) {
  // 当前考试的类型 0 -> 考试中心  1 -> 基础培训
  // const [type, setType] = useState<number>(1);
  // 定义选项
  const [option, setOption] = useState<any>([
    {
      op: "A",
      state: false,
    },
    {
      op: "B",
      state: false,
    },
    {
      op: "C",
      state: false,
    },
    {
      op: "D",
      state: false,
    },
    {
      op: "E",
      state: false,
    },
    {
      op: "F",
      state: false,
    },
  ]);
  // 定义题目限制选项
  const [optionCurr, setOptionCurr] = useState<any>([]);
  // 存放路由参数
  const [params, setParams] = useState<{
    plan_id?: number;
    subject_id?: number;
  }>({
    plan_id: Number(getParamValue("plan_id")),
    subject_id: Number(getParamValue("subject_id")),
  });
  // 存放用户选择的题目 答案
  const [answer, setAnswer] = useState<any>([]);
  // 存放用户考试试题数据
  const [List, setList] = useState<any>([]);
  // 用户所处当前题目数据
  const [current, setCurrent] = useState("");
  // 用户答题步骤
  const [step, setStep] = useState(1);
  // 页面加载状态
  const [loading, setLoading] = useState(true);
  // 警告提示
  const [warning, setWarning] = useState(false);

  // 请求题目
  useEffect(() => {
    getTheUserExamQuestions();
  }, []);

  useEffect(() => {
    if (List.length > 0) {
      if (List.length === step - 1) return;
      getCurrentOptions(List[step - 1].selection, List[step - 1].question_id);
    }
  }, [step]);

  /**
   * 处理当前选项
   * @param index
   */
  function getCurrentOptions(index: string | number, question_id: number) {
    let newArray = [];
    // 判断是都为第一题 // 判断改题用户是否已选
    if (answer.length === 0 || answer.length < step) {
      for (let i = 0; i < option.length; i++) {
        const element = option[i];
        newArray.push({
          op: element.op,
          state: false,
        });
        if (i === Number(index) - 1) {
          break;
        }
      }
      setOptionCurr(newArray);
      return;
    }
    // 说明这个题目用户已经选了
    let userAnswer = "";
    // 遍历用户选择的答案集合找出相同题号id 拿出选项并且渲染出效果
    for (let i = 0; i < answer.length; i++) {
      const element = answer[i];
      if (element.question_id === question_id) {
        userAnswer = element.answer.split("");
      }
    }
    // 生成选项数据
    for (let i = 0; i < option.length; i++) {
      const element = option[i];
      newArray.push({
        op: element.op,
        state: userAnswer.indexOf(element.op) > -1 ? true : false,
      });
      if (i === Number(index) - 1) {
        break;
      }
    }
    setOptionCurr(newArray);
  }

  /**
   * 请求用户数据
   */
  async function getTheUserExamQuestions() {
    // 请求路由
    let requestRouting = "";
    // 请求参数
    let requestParams: { plan_id?: number; subject_id?: number } = {};
    // 参数赋值
    if (params.plan_id) {
      requestParams["plan_id"] = params.plan_id;
      requestRouting = "/wap/exam/getStartExam";
    } else if (params.subject_id) {
      requestParams["subject_id"] = params.subject_id;
      requestRouting = "/wap/exam/getBasicTrainingPaper";
    } else {
      // props.history.push('/build')
      console.log("路由参数不正确");
    }
    // 执行网络请求
    let result = await FetchRequest(requestRouting, Object.assign(
      {
        token: localStorage.getItem("token"),
        store_id: localStorage.getItem("admin_store_id"),
      },
      requestParams
    ))
    if (result.code === 1) {
        setList(result.data);
        setCurrent(result.data[0].question);
        getCurrentOptions(result.data[0].selection, result.data[0].question_id);
        setLoading(false);
    } else {
      Toast.show(result.msg)
      store.routerStore.replace("/build/test-center")
    }
  }

  /**
   * 点击选项
   * @param index
   */
  function handleClickOption(index: number) {
    let newArray: Array<{ op: string; state: boolean }> = [];
    let s = newArray.concat(optionCurr);
    s[index].state = !s[index].state;
    setOptionCurr(s);
  }

  /**
   * 选项检查是否为空
   * @returns true 通过
   */
  function checkForNull() {
    let arr = [];
    for (let i = 0; i < optionCurr.length; i++) {
      const item = optionCurr[i];
      if (item.state) {
        arr.push(item.op);
        continue;
      }
    }
    if (arr.length === 0) {
      return "";
    } else {
      return arr.join("");
    }
  }

  /**
   * 存放用户选择的答案及其题号
   */
  function toGetTheAnswer(op: string) {
    const { question_id } = List[step - 1];
    // 判断如果question_id相同则覆盖
    for (let i = 0; i < answer.length; i++) {
      const element = answer[i];
      if (element.question_id === question_id) {
        answer.splice(i, 1);
      }
    }
    answer.push({
      question_id: question_id,
      answer: op,
    });
  }

  /**
   * 下一题
   */
  function handleClickNext() {
    if (!checkForNull()) {
      setWarning(true);
      setTimeout(() => {
        setWarning(false);
      }, 2000);
      return;
    }
    setCurrent(List[step].question);
    setStep(step + 1);

    toGetTheAnswer(checkForNull());
  }
  /**
   * 上一题
   */
  function handleClickUpNext() {
    setCurrent(List[step - 2].question);
    setStep(step - 1);
  }

  /**
   * 提交数据
   * 考试中心计算得分
   */
  function handleClickSub() {
    // 最后一题执行
    if (!checkForNull()) {
      setWarning(true);
      setTimeout(() => {
        setWarning(false);
      }, 2000);
      return;
    }
    Loading.show({
      content: <ActivityIndicator size="lg" />,
      mask: true
    });
    toGetTheAnswer(checkForNull());
    // 执行网络请求
    // 请求路由
    let requestRouting = "";
    // 请求参数
    let requestParams: { plan_id?: number; subject_id?: number } = {};
    // 参数赋值
    if (params.plan_id) {
      requestParams["plan_id"] = params.plan_id;
      requestRouting = "/wap/exam/getExamScore";
    } else if (params.subject_id) {
      requestParams["subject_id"] = params.subject_id;
      requestRouting = "/wap/exam/getBasicTrainingScore";
    } else {
      // props.history.push('/build')
      console.log("路由参数不正确");
    }
    // 执行网络请求
    BangBangTangRequest({
      url: requestRouting,
      method: "POST",
      data: Object.assign(
        {
          token: localStorage.getItem("token"),
          answer: JSON.stringify(answer),
          store_id: localStorage.getItem("admin_store_id"),
        },
        requestParams
      ),
    }).subscribe((result) => {
      if (params.plan_id) {
        props.history.push(
          `/build/test-close?plan_id=${result.plan_id}&score=${
            result.score
          }&type=${Object.keys(requestParams)[0]}`
        );
      } else if (params.subject_id) {
        props.history.push(
          `/build/test-close?subject_id=${result.subject_id}&score=${
            result.score
          }&type=${Object.keys(requestParams)[0]}`
        );
      }
      Loading.hide();
    });
  }

  /**
   * 纠错
   */
  function handleClickErrorCorrection() {
    if (confirm("此题有错误,确定提交错误题目?")) {
      const { question_id } = List[step - 1];
      BangBangTangRequest({
        url: "/wap/exam/submitErrorCorrection",
        method: "POST",
        data: {
          token: localStorage.getItem("token"),
          store_id: localStorage.getItem("admin_store_id"),
          question_id,
        },
      }).subscribe((result) => {
        alert("纠错成功");
      });
      console.log("提交", question_id);
    } else {
      console.log("您取消了提交");
    }
  }

  if (loading) {
    return <div className={style.Test_Loading}>题目正在加载中...</div>;
  }

  return (
    <div>
      {/* 考题 */}
      <div
        className={style.Test_Content}
        dangerouslySetInnerHTML={{ __html: current }}
      ></div>
      {/* 选项 */}
      <div className={style.Test_option}>
        <div className={style.Test_option_schedule}>
          {step}/{List.length}
        </div>
        <div className={style.Test_option_content}>
          {step === 1 ? (
            <div style={{ width: 56, height: 30 }}></div>
          ) : (
            <button onClick={handleClickUpNext}>上一题</button>
          )}
          <div className={style.Test_option_item}>
            {optionCurr.map(
              (v: { op: string; state: boolean }, index: number) => {
                return (
                  <span
                    key={v.op}
                    className={`${v.state && style.action}`}
                    onClick={() => handleClickOption(index)}
                  >
                    {v.op}
                  </span>
                );
              }
            )}
          </div>
          {List.length === step ? (
            <button onClick={handleClickSub}>提交</button>
          ) : (
            <button onClick={handleClickNext}>下一题</button>
          )}
        </div>
      </div>
      {warning && <div className={style.Test_warning}>请选择答案</div>}
      <div
        className={style.Test_error_correction}
        onClick={handleClickErrorCorrection}
      >
        纠错
      </div>
    </div>
  );
}

export default withRouter(TestExamination);
