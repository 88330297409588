import { autorun, computed, makeAutoObservable, runInAction } from "mobx";
import { BangBangTangRequest } from "../utils/request";
import { store } from "./store.root";
import { filter, finalize } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject } from "rxjs";
interface StoreInfoInterface {
  id: number;
  name: string;
  mobile: string;
  address: string;
}

export class StoreInfoStore {
  // @ts-ignore
  private storeSelected: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public storeSelectObservable: Observable<any> = this.storeSelected.pipe(
    filter((value1) => value1)
  );
  public store_choice: StoreInfoInterface[] | null = null;
  public loading: boolean = false;
  public value: number | null = null;
  public display_modal: boolean = false;
  public rank:string = '';
  @computed public get store_name() {
    if (this.value === null) {
      return "未选择";
    } else {
      if (this.loading) {
        return "载入中";
      }
      return (
        this.store_choice?.find((v) => v.id === this.value)?.name ||
        "请选择门店"
      );
    }
  }
  constructor() {
    makeAutoObservable(this);
    const admin_store_id = localStorage.getItem("admin_store_id");
    if (admin_store_id) {
      this.value = parseInt(admin_store_id, 10);
    }
    autorun(() => {
      this.storeSelected.next(!!this.value);
    });
  }
  selectStore(value: number) {
    if (this.value === value) {
    } else {
      this.value = value;
      localStorage.setItem("admin_store_id", value.toString());
      store.websiteRuleStore.updateFrontendAuth();
      store.storeInfoStore.getMyRanking();
    }
    this.toggleModalDisplay(false);
  }
  toggleModalDisplay(value: boolean) {
    this.display_modal = value;
  }
  getStoreDataFromServer() {
    this.loading = true;
    BangBangTangRequest({
      data: { token: store.userStore.token },
      url: "/wap/rule/getStoreData",
      method: "POST",
    })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((value) => {
        runInAction(() => {
          this.store_choice = value;
          if (value.length === 1 && this.value === null) {
            this.selectStore(value[0].id);
          }
        });
      });
  }

  getMyRanking() {
    BangBangTangRequest({
      data: { token: store.userStore.token,store_id: localStorage.getItem("admin_store_id") },
      url: "/wap/sanction/getMyRanking",
      method: "POST",
    })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((value) => {
        console.log(value)
        this.rank = value
      });
  }

  clear() {
    this.value = null;
  }
}
