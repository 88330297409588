import React from "react";
import { MyPreorderDetailInterface } from "../my-preorder.api";
import { store } from "../../../store/store.root";
import { PreorderStatusRender } from "../../../components/preorder-status-render";

export function MyPreorderCard(props: { data: MyPreorderDetailInterface }) {
  return (
    <div
      className="my-preorder-card"
      onClick={() =>
        store.routerStore.push("/build/my-preorder-detail/" + props.data.id)
      }
    >
      <div className="text-area">
        <div className="title">
          {props.data.store_name} {props.data.type_name}
        </div>
        <div className="date">
          {props.data.schedule_date} {props.data.schedule_time}
        </div>
      </div>
      <div className="type-area">
        <PreorderStatusRender value={props.data.status} />
      </div>
    </div>
  );
}
