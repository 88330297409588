import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { FetchRequest } from '../../utils/fetch'
import { withRouter } from 'react-router-dom'
import style from './index.module.scss'
import {
  ActivityIndicator,
  Button,
  ImagePreview,
  Loading,
  Modal,
  Popup,
  Tabs,
  Toast,
} from 'zarm'
import CloseImages from '../../assets/close.png'
import { ImagesUpdateNew } from './ImagesUpdate'
import { getParamValue } from '../../utils/url'
import SelectionImages, { matcht } from './SelectionImages'
import { store } from '../../store/store.root'
import DHImages from '../../assets/dh.png'
import COS from 'cos-js-sdk-v5'
import { store as store_tmp } from './SelectionImages'
import { IresultType } from './Selectview'
import WaterMaker from './water-maker'

const { Panel } = Tabs

export type interData = {
  basic?: any
  fileType?: number
  modeUrl?: string
  width?: number
  height?: number
  title?: string
  name?: string
  baby_name?: string
  class?: string
  gtime?: string
  cut?: object
  ori?: object
  combineUrl?: string
  combineUrl_2?: string
  position1_pic?: string
  position2_pic?: string
  position3_pic?: string
  position4_pic?: string
  position5_pic?: string
}

export type positionPic =
  | 'position1_pic'
  | 'position2_pic'
  | 'position3_pic'
  | 'position4_pic'
  | 'position5_pic'

export default withRouter(
  (props: {
    match: {
      params: {
        id: string
      }
    }
  }) => {
    const imgRef: any = useRef()
    //// 模板数据
    const [data, setData] = useState<interData[]>([])
    const [getModel, setGetModel] = useState<any>({})
    //// 空状态
    const [nullStatus, setNullStatus] = useState('数据加载中...')
    //// 照片选择
    const [selectAlbum, setSelectAlbum] = useState<{ origin_img: string }[]>([])
    /// 当前处理索引
    /// 当前索引
    const [currentHandleIndex, setCurrentHandleIndex] = useState(0)
    // 当前索引数据
    const [currentHandleData, setCurrentHandleDate] = useState<interData>({})
    ///////////// 基础弹窗状态控制
    // 控制选择图片弹窗
    const [isSelectImages, setIsSelectImages] = useState(false)
    // 控制裁剪弹窗
    const [isCuttingImages, setIsCuttingImages] = useState(false)

    ////// 裁剪模板数据索引
    const [TemplateFillingindex, setTemplateFillingindex] = useState(0) /// 当前模板 带填充数的索引值
    const [fillImagesUrl, setFillImagesUrl] = useState<any>('') //// 当前待裁剪的图片链接
    const [ImagesProportionSize, setImagesProportionSize] = useState({
      width: 200,
      height: 200,
    })

    ////// 用于存放裁剪的base64
    const [saveIamgesBase64, setSaveIamgesBase64] = useState<any>({})

    /// 已经保存的 完整图
    const [CombineUrlList, setCombineUrlList] = useState<string[]>([])
    /// 已经保存预览
    const [CombineUrlListView, setCombineUrlListView] = useState(false)
    // 索引
    const [pictureIndex, setPictureIndex] = useState(0)

    const [operationGuide, setOperationGuide] = useState(0)

    const [IamgesBase64, setIamgesBase64] = useState('')

    const [graduation, setGraduation] = useState<any>({
      is_select: 0,
      consultation_telephone: '',
    })
    const [packageStatus, setPackageStatus] = useState(false)
    const [meal, setMeal] = useState<
      {
        c_price: number
        detail?: null | string[]
        info?: string
        pinfo?: string
        price?: string
        meal?: string
      }[]
    >([])

    const [direction, setDirection] = useState<'left' | 'right'>('right')

    useEffect(() => {
      document.title = '毕业季选版'
      GetPageData()
      return () => {
        clearTimeout(timer)
      }
    }, [])

    useEffect(() => {
      if (data.length > 0) {
        // Loading.show({
        //   content: <ActivityIndicator size="lg" />,
        // })
        console.log('执行')
        if (
          false
          // data[currentHandleIndex].fileType === 2 ||
          // data[currentHandleIndex].fileType === 3 ||
          // data[currentHandleIndex].fileType === 4
        ) {
          GETBase()
        } else {
          setCurrentHandleDate(data[currentHandleIndex])
        }
        if (direction === 'left') {
          // Loading.hide()
          // return
        }
        // Loading.hide()
        // imgRef.current.onload = function (e: any) {
        //   Loading.hide()
        // }
      }
    }, [currentHandleIndex])

    const [imagesStatus, setImagesStatus] = useState(true)

    useEffect(() => {
      if (saveIamgesBase64[currentHandleData.name as string]) {
        if (!saveIamgesBase64[currentHandleData.name as string]['1']) {
          Loading.hide()
          return
        }
        const images = new Image()
        images.setAttribute('crossOrigin', 'Anonymous')
        images.src = saveIamgesBase64[currentHandleData.name as string]['1']
        images.onload = function (e: any) {
          if (images.width > images.height) {
            setImagesStatus(true)
          } else {
            setImagesStatus(false)
          }
          Loading.hide()
        }
      }
    }, [currentHandleData, saveIamgesBase64])

    // useEffect(() => {
    //   if (isSelectImages) {
    //     handleGetMeal()
    //   }
    // }, [isSelectImages])

    async function handleGetMeal() {
      Loading.show({
        content: <ActivityIndicator size="lg" />,
      })
      let result = await FetchRequest('/wap/graduation/getMealNum', {
        token: localStorage.getItem('token'),
        eroll_id: getParamValue('eroll_id'),
      })
      Loading.hide()
      if (result.code === 1) {
        setGetMealNum(result.data)
      }
    }

    async function GetPageData() {
      Loading.show({
        content: <ActivityIndicator size="lg" />,
      })
      await getMode()
      // await getSelectAlbum()
      await GetPageGraduation()
      // await GetCode()
      Loading.hide()
    }

    const [getMealNum, setGetMealNum] = useState<{
      alreadymeal: number
      photomeal: number
      shouldmeal: number
    }>({
      alreadymeal: 0,
      photomeal: 0,
      shouldmeal: 0,
    })

    async function getSelectCount(
      cb: (e: {
        alreadymeal: number
        photomeal: number
        shouldmeal: number
      }) => any
    ) {
      let result = await FetchRequest('/wap/graduation/getMealNum', {
        token: localStorage.getItem('token'),
        eroll_id: getParamValue('eroll_id'),
      })
      if (result.code === 1) {
        // setGetMealNum(result.data)
        if (Number(result.data.shouldmeal) !== 0 && meal.length > 1) {
          cb(result.data)
        }
      } else {
        Toast.show(result.msg)
      }
    }

    async function GetPageGraduation() {
      // if (getParamValue('is_update')) return
      let result = await FetchRequest('/wap/graduation/graduationIndex/', {
        token: localStorage.getItem('token'),
        activity_id: props.match.params.id,
        eroll_id: getParamValue('eroll_id'),
      })
      if (result.code === 1) {
        if (!getParamValue('is_update')) {
          setGraduation({
            ...result.data,
            consultation_telephone: result.data.consultation_telephone || '',
          })
        }
        if (result.data.watermark_settings) {
          localStorage.setItem(
            'watermark_settings',
            result.data.watermark_settings
          )
        }
        if (result.data.pellucidity) {
          localStorage.setItem('watermark_pellucidity', result.data.pellucidity)
        }
        if (result.data.meal) {
          setMeal((result.data.meal as any[]).reverse())
        }
      } else {
        Toast.show(result.msg)
      }
    }
    async function upgradePackage(type: string, price: number) {
      let result = await FetchRequest('/wap/graduation/mealPay', {
        token: localStorage.getItem('token'),
        // activity_id: props.match.params.id,
        eroll_id: getParamValue('eroll_id'),
        meal: type,
        price,
      })
      if (result.code === 1) {
        let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
          currenturl: window.location.href,
        })
        const { wx }: any = window
        const {
          appId,
          nonceStr,
          signature,
          timestamp,
          jsApiList,
        } = SDKParams.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名
          jsApiList, // 必填，需要使用的JS接口列表
        })

        wx.ready(function () {
          const {
            appId,
            nonceStr,
            order_id,
            paySign,
            signType,
            timestamp,
          } = result.data.data
          wx.chooseWXPay({
            timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
            package: result.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: paySign, // 支付签名
            success: function (res: any) {
              // 支付成功后的回调函数
              location.reload()
            },
          })
        })

        wx.error(function (res: any) {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          Toast.show('支付失败')
        })
      } else {
        Toast.show(result.msg)
      }
    }

    async function GETBase(item?: interData) {
      Loading.show({
        content: <ActivityIndicator size="lg" />,
      })
      if (!item?.modeUrl) {
        const r = await rotateBase64Img(data[currentHandleIndex].modeUrl!, 90)
        setCurrentHandleDate({
          ...data[currentHandleIndex],
          modeUrl: r,
        })
      } else {
        ///// 初始化
        const r = await rotateBase64Img(item?.modeUrl, 90)
        setCurrentHandleDate({
          ...item,
          modeUrl: r,
        })
      }
      Loading.hide()
    }

    async function getSelectAlbum() {
      // Loading.show({
      //   content: <ActivityIndicator size="lg" />,
      // });
      let result = await FetchRequest('/wap/graduation/getAlbum', {
        token: localStorage.getItem('token'),
        eroll_id: getParamValue('eroll_id'),
        page: 1,
        limit: 100,
      })
      // Loading.hide();
      if (result.code === 1) {
        setSelectAlbum(result.data)
      } else {
        Toast.show(result.msg)
      }
    }

    async function getMode() {
      let result = await FetchRequest('/wap/graduation/getMode', {
        token: localStorage.getItem('token'),
        activity_id: props.match.params.id,
        eroll_id: getParamValue('eroll_id'),
      })
      if (result.code === 1) {
        if (!getParamValue('is_update')) {
          if (result.data.is_submit === 1) {
            // store.routerStore.replace(
            //   `/build/selection-view/${props.match.params.id}/${getParamValue(
            //     'eroll_id'
            //   )}`
            // )
            const modal = Modal.alert({
              title: '提示',
              content: '相册已设计成功',
              onCancel: () => {
                modal.hide()
                // 跳转下载页
                // store.routerStore.replace(
                //   `/build/graduation-select/${props.match.params.id}`
                // )
              },
            })
            // if (result.data.plate === 2) {
            //   const modal = Modal.alert({
            //     title: '制作成功',
            //     content: '点击关闭跳转到【下载页】',
            //     onCancel: () => {
            //       modal.hide()
            //       // 跳转下载页
            //       store.routerStore.replace('/build/film-download')
            //     },
            //   })
            // }
            // return
          }
        }
        if (
          // result.data.modes[0].fileType === 2
          // result.data.modes[0].fileType === 3
          false
        ) {
          GETBase(result.data.modes[0])
        } else {
          setCurrentHandleDate(result.data.modes[0])
        }
        //// 生成
        const init_o: any = {}
        const combineUrlList: string[] = []
        for (let i = 0; i < result.data.modes.length; i++) {
          const element = result.data.modes[i]
          init_o[element.name] = {
            params: {},
            data: element,
            index: i,
            save: false,
          }

          /// 生成图片字段
          if (element.basic && element.basic.count && element.cut) {
            for (let i = 0; i < element.basic.count; i++) {
              init_o[element.name][(i + 1).toString()] =
                element.cut[`position${i + 1}_pic_cut`]
              if (element.cut) {
                init_o[element.name]['save'] = true
              } else {
                init_o[element.name]['save'] = false
              }
            }
          }
          /// 回显图片 url => base64
          if (element.combineUrl) {
            combineUrlList.push(element.combineUrl)
            element['combineUrl_2'] = element.combineUrl
            // element.combineUrl = await rotateBase64Img(element.combineUrl, 90);
          }
        }
        console.log(init_o, '<--test---test---test')
        setSaveIamgesBase64(init_o)
        setCombineUrlList(combineUrlList)
        setData(result.data.modes)
        setGetModel(result.data)
      } else {
        setNullStatus(result.msg)
        const modal = Modal.alert({
          title: '提示',
          content: result.msg,
          onCancel: () => {
            modal.hide()
          },
        })
      }
    }

    const [timer, setTimer] = useState<any>(null)

    function verification() {
      /////
      // 判断所有区块是否选择完毕
      /////
      const select_url = Object.values(
        saveIamgesBase64[currentHandleData.name!]['params']
      ).filter((v) => new RegExp(matcht).test(String(v)))
      ////// 选择区块判断

      //相册封面
      if (currentHandleData.name === '相册封面') {
        if (Object.keys(currentHandleData.cut || {}).length === 0) {
          if (select_url.length < 1 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // 产品
      if (currentHandleData.fileType === 4) {
        if (Object.keys(currentHandleData.cut || {}).length === 0) {
          if (select_url.length < 1 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // two
      if (currentHandleData.name === 'two') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 5 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // one
      if (currentHandleData.name === 'one') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 1) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // three
      if (currentHandleData.name === 'three') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 2 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // four
      if (currentHandleData.name === 'four') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // five
      if (currentHandleData.name === 'five') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 3 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // six
      if (currentHandleData.name === 'six') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 2 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // seven
      if (currentHandleData.name === 'seven') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 1) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // eight
      if (currentHandleData.name === 'eight') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 3 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // nine
      if (currentHandleData.name === 'nine') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 2 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // ten
      if (currentHandleData.name === 'ten') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 3 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // eleven
      if (currentHandleData.name === 'eleven') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // twelve
      if (currentHandleData.name === 'twelve') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // thirteen
      if (currentHandleData.name === 'thirteen') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 2 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // fourteen
      if (currentHandleData.name === 'fourteen') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 5 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // fifteen
      if (currentHandleData.name === 'fifteen') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 3 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // sixteen
      if (currentHandleData.name === 'sixteen') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // seventeen
      if (currentHandleData.name === 'seventeen') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 5 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // eighteen
      if (currentHandleData.name === 'eighteen') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 3 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // nineteen
      if (currentHandleData.name === 'nineteen') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 5 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // twenty
      if (currentHandleData.name === 'twenty') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 3 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // twenty
      if (currentHandleData.name === 'twentyone') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 3 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // twentytwo
      if (currentHandleData.name === 'twentytwo') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 8 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // twentythree
      if (currentHandleData.name === 'twentythree') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 8 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // twentyfour
      if (currentHandleData.name === 'twentyfour') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 8 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      // twentyfive
      if (currentHandleData.name === 'twentyfive') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 3 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // twentysix
      if (currentHandleData.name === 'twentysix') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // twentyseven
      if (currentHandleData.name === 'twentyseven') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 2 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // twentyeight
      if (currentHandleData.name === 'twentyeight') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // twentynine
      if (currentHandleData.name === 'twentynine') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // thirty
      if (currentHandleData.name === 'thirty') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // thirtyone
      if (currentHandleData.name === 'thirtyone') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 2 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // thirtytwo
      if (currentHandleData.name === 'thirtytwo') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 5 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // thirtythree
      if (currentHandleData.name === 'thirtythree') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // thirtyfour
      if (currentHandleData.name === 'thirtyfour') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // thirtyfive
      if (currentHandleData.name === 'thirtyfive') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 2 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // thirtysix
      if (currentHandleData.name === 'thirtysix') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 5 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // thirtyseven
      if (currentHandleData.name === 'thirtyseven') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // thirtyeight
      if (currentHandleData.name === 'thirtyeight') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }
      // thirtynine
      if (currentHandleData.name === 'thirtynine') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 3 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      if (currentHandleData.name === 'fourty') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      if (currentHandleData.name === 'fourtyone') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 4 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      if (currentHandleData.name === 'fourtytwo') {
        if (
          !currentHandleData.combineUrl &&
          Object.keys(currentHandleData.cut || {}).length === 0
        ) {
          if (select_url.length < 5 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
      }

      return true
    }

    async function handleClickSave() {
      // 判断当前页有没有选择完毕

      console.log('11111', saveIamgesBase64[currentHandleData.name!])

      if (saveIamgesBase64[currentHandleData.name!]['save']) {
        console.log('保存过')
        setCurrentHandleIndex(currentHandleIndex + 1)
        return
      }

      if (
        currentHandleData.fileType === 1 &&
        saveIamgesBase64[currentHandleData.name!]['1']
      ) {
        const s = [] // 这里是选择后的
        // 编辑了
        for (let i = 0; i < currentHandleData.basic.count; i++) {
          const t = saveIamgesBase64[currentHandleData.name!][`${i + 1}`]
          if (t) {
            s.push(t)
          }
        }
        if (s.length === currentHandleData.basic.count) {
          console.log('需要保存')
        } else {
          setCurrentHandleIndex(currentHandleIndex + 1)
          return
        }
      } else {
        // 封面必须选择
        if (currentHandleData.fileType === 2) {
          // 没保存 没选择过
          if (
            !saveIamgesBase64[currentHandleData.name!]['save'] &&
            JSON.stringify(
              saveIamgesBase64[currentHandleData.name!]['params']
            ) === '{}' &&
            currentHandleData.width &&
            currentHandleData.height
          ) {
            // 必须要保存
            Toast.show(
              '请先选择' +
                saveIamgesBase64[currentHandleData.name!]['data']['name']
            )
            return
          }
        }
        // 正常版面
        if (currentHandleData.fileType === 1) {
          // 没编辑
          setCurrentHandleIndex(currentHandleIndex + 1)
          return
        }
      }

      ///// 缓存中判断是否频繁提交
      // if (timer) {
      //   if (data.length - 1 === currentHandleIndex) {
      //     Toast.show('请等待一分钟后再次提交')
      //     return
      //   }
      // }
      ///// 是否为 2 3

      if (currentHandleData.fileType !== 1) {
        if (!currentHandleData.width && !currentHandleData.height) {
          /// 下一页
          setCurrentHandleIndex(currentHandleIndex + 1)
          return
        }
      }

      // if (!verification()) {
      //   return
      // }
      // console.log(
      //   '是否修改 true=>没有修改, false=>修改了:',
      //   saveIamgesBase64[currentHandleData.name!]['save'],
      //   currentHandleData
      // )
      // console.log(saveIamgesBase64[currentHandleData.name!])
      /// 判断是否修改 和 存在图片
      /// 如果存在 cut 然后 用户么有修改
      // if (
      //   saveIamgesBase64[currentHandleData.name!]['save'] // && Object.keys(currentHandleData.cut || {}).length !== 0
      // ) {
      //   //// 判断如果是最后一个则继续
      //   if (data.length !== currentHandleIndex + 1) {
      //     setCurrentHandleIndex(currentHandleIndex + 1)
      //     return
      //   }
      // }
      /// 最后一个
      if (data.length === currentHandleIndex + 1) {
        const modal = Modal.confirm({
          title: '提示',
          content: '提交后将不可修改，确认提交？',
          onCancel: () => {
            console.log('点击cancel')
          },
          onOk: async () => {
            Loading.show({
              content: <ActivityIndicator size="lg" />,
            })
            let result = await FetchRequest('/wap/graduation/saveMode', {
              token: localStorage.getItem('token'),
              // modeData: encodeURI(JSON.stringify(currentHandleData)),
              // basic: JSON.stringify(currentHandleData.basic),
              basic: JSON.stringify(
                saveIamgesBase64[currentHandleData.name!]['params']
              ),
              modeUrl: currentHandleData.modeUrl,
              name: currentHandleData.name,
              eroll_id: getParamValue('eroll_id'),
              activity_id: props.match.params.id,
              page: currentHandleIndex + 1,
            })
            Loading.hide()
            if (result.code === 1) {
              ///// 提交
              saveIamgesBase64[currentHandleData.name!]['save'] = true
              if (data.length === currentHandleIndex + 1) {
                ///// 提交
                let result = await FetchRequest('/wap/graduation/submitMode', {
                  token: localStorage.getItem('token'),
                  eroll_id: getParamValue('eroll_id'),
                  activity_id: props.match.params.id,
                })
                if (result.code === 1) {
                  //// 定时器
                  const t = setTimeout(() => {
                    setTimer(null)
                  }, 60000)
                  setTimer(t)

                  // IamgesBase64
                  const modal = Modal.alert({
                    title: '制作成功',
                    content: (
                      <div>
                        <div>
                          <img
                            src={IamgesBase64}
                            style={{ width: '60%' }}
                            alt=""
                          />
                        </div>
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          【下载底片】需长按关注此公众号
                        </div>
                      </div>
                    ),
                    onCancel: () => {
                      // window.location.reload()
                      // getMode()
                      // store.routerStore.replace(
                      //   `/build/selection-view/${
                      //     props.match.params.id
                      //   }/${getParamValue('eroll_id')}`
                      // )
                      modal.hide()
                      store.routerStore.replace('/build/film-download')
                    },
                  })
                } else {
                  // Toast.show(result.msg)
                  const modal = Modal.alert({
                    title: '提示',
                    content: result.msg,
                    onCancel: () => {
                      modal.hide()
                    },
                  })
                }
              }
              ////
            } else {
              saveIamgesBase64[currentHandleData.name!]['save'] = true
              Toast.show(result.msg)
            }
          },
        })
      } else {
        if (
          currentHandleData.name === '相册封面' ||
          (currentHandleData.fileType === 4 &&
            currentHandleData.width &&
            currentHandleData.height)
        ) {
          Loading.show({
            content: <ActivityIndicator size="lg" />,
          })
          let result = await FetchRequest('/wap/graduation/saveProduct', {
            token: localStorage.getItem('token'),
            basic: JSON.stringify(
              saveIamgesBase64[currentHandleData.name!]['params']
            ),
            // modeUrl: currentHandleData.modeUrl,
            name: currentHandleData.name,
            eroll_id: getParamValue('eroll_id'),
            activity_id: props.match.params.id,
            product: currentHandleData.title,
            // page: currentHandleIndex + 1,
          })
          Loading.hide()
          if (result.code === 1) {
            ///// 提交
            saveIamgesBase64[currentHandleData.name!]['save'] = true
            // currentHandleData.cut = { position1_pic_cut }
            Toast.show(result.msg)
            /// 下一页
            setCurrentHandleIndex(currentHandleIndex + 1)
            ////
          } else {
            saveIamgesBase64[currentHandleData.name!]['save'] = true
            Toast.show(result.msg)
          }
          return
        }
        //// 保存
        Loading.show({
          content: <ActivityIndicator size="lg" />,
        })
        let result = await FetchRequest('/wap/graduation/saveMode', {
          token: localStorage.getItem('token'),
          // modeData: encodeURI(JSON.stringify(currentHandleData)),
          // basic: JSON.stringify(currentHandleData.basic),
          basic: JSON.stringify(
            saveIamgesBase64[currentHandleData.name!]['params']
          ),
          modeUrl: currentHandleData.modeUrl,
          name: currentHandleData.name,
          eroll_id: getParamValue('eroll_id'),
          activity_id: props.match.params.id,
          page: currentHandleIndex + 1,
        })
        Loading.hide()
        if (result.code === 1) {
          ///// 提交
          saveIamgesBase64[currentHandleData.name!]['save'] = true
          Toast.show(result.msg)
          /// 下一页
          setCurrentHandleIndex(currentHandleIndex + 1)
          ////
        } else {
          saveIamgesBase64[currentHandleData.name!]['save'] = true
          Toast.show(result.msg)
        }
      }
    }

    // 旋转
    function rotateBase64Img(src: string, edg: number): Promise<string> {
      return new Promise((res, rej) => {
        var canvas: any = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        var imgW //图片宽度
        var imgH //图片高度
        var size //canvas初始大小
        if (edg % 90 != 0) {
          console.error('旋转角度必须是90的倍数!')
          throw '旋转角度必须是90的倍数!'
        }
        edg < 0 && (edg = (edg % 360) + 360)
        const quadrant = (edg / 90) % 4 //旋转象限
        const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } //裁剪坐标
        var image = new Image()
        image.crossOrigin = 'anonymous'
        image.src = src
        image.onload = function () {
          imgW = image.width
          imgH = image.height
          size = imgW > imgH ? imgW : imgH
          canvas.width = size * 2
          canvas.height = size * 2
          switch (quadrant) {
            case 0:
              cutCoor.sx = size
              cutCoor.sy = size
              cutCoor.ex = size + imgW
              cutCoor.ey = size + imgH
              break
            case 1:
              cutCoor.sx = size - imgH
              cutCoor.sy = size
              cutCoor.ex = size
              cutCoor.ey = size + imgW
              break
            case 2:
              cutCoor.sx = size - imgW
              cutCoor.sy = size - imgH
              cutCoor.ex = size
              cutCoor.ey = size
              break
            case 3:
              cutCoor.sx = size
              cutCoor.sy = size - imgW
              cutCoor.ex = size + imgH
              cutCoor.ey = size + imgW
              break
          }
          ctx.translate(size, size)
          ctx.rotate((edg * Math.PI) / 180)
          ctx.drawImage(image, 0, 0)
          var imgData = ctx.getImageData(
            cutCoor.sx,
            cutCoor.sy,
            cutCoor.ex,
            cutCoor.ey
          )
          if (quadrant % 2 == 0) {
            canvas.width = imgW
            canvas.height = imgH
          } else {
            canvas.width = imgH
            canvas.height = imgW
          }
          ctx.putImageData(imgData, 0, 0)
          res(canvas.toDataURL('image/jpeg', 0.4))
        }
      })
    }

    /// 相册预览
    async function photoAlbumPreview() {
      // 检测版面是否选择完毕
      // console.log(currentHandleData)
      // console.log(saveIamgesBase64)

      let _tag = false
      const t = []
      const keys = Object.keys(saveIamgesBase64)

      // console.log(saveIamgesBase64)

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        if (saveIamgesBase64[key].data.fileType === 1) {
          if (saveIamgesBase64[key]['save']) {
            t.push(saveIamgesBase64[key])
          } else {
            // 最后一个是版面
            if (keys.length === i + 1) {
              // 如果最后是个版面跳过最后
            } else {
              _tag = true
              Toast.show(`第${saveIamgesBase64[keys[i]].index}页存在未选完版面`)
              break
            }
          }
        }
      }
      if (_tag) return
      // return
      //// 保存
      // if (!verification()) {
      //   return
      // }
      // =====
      // if (
      //   JSON.stringify(saveIamgesBase64[currentHandleData.name!]['params']) ===
      //   '{}'
      // ) {
      //   const modal = Modal.alert({
      //     title: '制作成功',
      //     content: '点击关闭跳转到【下载页】',
      //     onCancel: () => {
      //       modal.hide()
      //       // 跳转下载页
      //       store.routerStore.replace('/build/film-download')
      //     },
      //   })
      //   return
      // }
      if (currentHandleData.fileType === 4) {
        if (saveIamgesBase64[currentHandleData.name!]['save']) {
          const modal = Modal.confirm({
            title: '提示',
            content:
              '请检查一遍相册，照片有无分割人物，重复的情况，一旦提交，无法修改',
            confirmText: '确认提交',
            cancelText: '去检查',
            onCancel: () => {
              console.log('点击cancel')
            },
            onOk: async () => {
              let result = await FetchRequest('/wap/graduation/submitMode', {
                token: localStorage.getItem('token'),
                activity_id: props.match.params.id,
                eroll_id: getParamValue('eroll_id'),
              })
              Loading.hide()
              if (result.code === 1) {
                let r: IresultType = await FetchRequest(
                  '/wap/graduation/getMode/',
                  {
                    token: localStorage.getItem('token'),
                    activity_id: props.match.params.id,
                    eroll_id: getParamValue('eroll_id'),
                  }
                )
                if (r.code === 1) {
                  if (r.data.plate !== 2) {
                    let images_res = await FetchRequest(
                      '/wap/graduation/getImage',
                      {
                        token: localStorage.getItem('token'),
                      }
                    )
                    const modal = Modal.alert({
                      title: '制作成功',
                      content: (
                        <div>
                          <div>
                            <img
                              src={images_res.data}
                              style={{ width: '60%' }}
                              alt=""
                            />
                          </div>
                          <div style={{ color: 'red', textAlign: 'center' }}>
                            【下载底片】需长按关注此公众号
                          </div>
                        </div>
                      ),
                      onCancel: () => {
                        modal.hide()
                        if (r.data.plate === 1) {
                          // 跳转下载页
                          store.routerStore.replace('/build/film-download')
                        }
                      },
                    })
                  } else {
                    store.routerStore.replace(
                      `/build/graduation-select/${props.match.params.id}`
                    )
                  }
                } else {
                  Toast.show(r.msg)
                }
              } else {
                // Toast.show(result.msg)
                const modal = Modal.alert({
                  title: '提示',
                  content: result.msg,
                  onCancel: () => {
                    modal.hide()
                  },
                })
              }
            },
          })
          return
        }
        const select_url = Object.values(
          saveIamgesBase64[currentHandleData.name!]['params']
        ).filter((v) => new RegExp(matcht).test(String(v)))
        //// 判断
        if (Object.keys(currentHandleData.cut || {}).length === 0) {
          if (select_url.length < 1 * 2) {
            Toast.show('请将灰色部分全部选择完再保存')
            return false
          }
        }
        Loading.show({
          content: <ActivityIndicator size="lg" />,
        })
        let result = await FetchRequest('/wap/graduation/saveProduct', {
          token: localStorage.getItem('token'),
          basic: JSON.stringify(
            saveIamgesBase64[currentHandleData.name!]['params']
          ),
          // modeUrl: currentHandleData.modeUrl,
          name: currentHandleData.name,
          eroll_id: getParamValue('eroll_id'),
          activity_id: props.match.params.id,
          product: currentHandleData.title,
          // page: currentHandleIndex + 1,
        })
        Loading.hide()
        if (result.code === 1) {
          /////
          const modal = Modal.confirm({
            title: '提示',
            content:
              '请检查一遍相册，照片有无分割人物，重复的情况，一旦提交，无法修改',
            confirmText: '确认提交',
            cancelText: '去检查',
            onCancel: () => {
              console.log('点击cancel')
            },
            onOk: async () => {
              if (result.code === 1) {
                let result = await FetchRequest('/wap/graduation/submitMode', {
                  token: localStorage.getItem('token'),
                  activity_id: props.match.params.id,
                  eroll_id: getParamValue('eroll_id'),
                })
                Loading.hide()
                if (result.code === 1) {
                  let r: IresultType = await FetchRequest(
                    '/wap/graduation/getMode/',
                    {
                      token: localStorage.getItem('token'),
                      activity_id: props.match.params.id,
                      eroll_id: getParamValue('eroll_id'),
                    }
                  )
                  if (r.code === 1) {
                    if (r.data.plate !== 2) {
                      let images_res = await FetchRequest(
                        '/wap/graduation/getImage',
                        {
                          token: localStorage.getItem('token'),
                        }
                      )
                      const modal = Modal.alert({
                        title: '制作成功',
                        content: (
                          <div>
                            <div>
                              <img
                                src={images_res.data}
                                style={{ width: '60%' }}
                                alt=""
                              />
                            </div>
                            <div style={{ color: 'red', textAlign: 'center' }}>
                              【下载底片】需长按关注此公众号
                            </div>
                          </div>
                        ),
                        onCancel: () => {
                          modal.hide()
                          if (r.data.plate === 1) {
                            // 跳转下载页
                            store.routerStore.replace('/build/film-download')
                          }
                        },
                      })
                    } else {
                      store.routerStore.replace(
                        `/build/graduation-select/${props.match.params.id}`
                      )
                    }
                  } else {
                    Toast.show(r.msg)
                  }
                } else {
                  // Toast.show(result.msg)
                  const modal = Modal.alert({
                    title: '提示',
                    content: result.msg,
                    onCancel: () => {
                      modal.hide()
                    },
                  })
                }
              } else {
                saveIamgesBase64[currentHandleData.name!]['save'] = true
                Toast.show(result.msg)
              }
            },
          })
          /////
        } else {
          saveIamgesBase64[currentHandleData.name!]['save'] = true
          Toast.show(result.msg)
        }
        return
      }
      //// 如果最后一个是版面且保存了
      if (
        data.length === currentHandleIndex + 1 &&
        currentHandleData.fileType === 1
      ) {
        // 最后也一版面
        const s = t.filter((v) => v.data.name === currentHandleData.name)
        if (s.length > 0 && s[0]['save']) {
          // 最后一个是版面并且保存了
          const modal = Modal.confirm({
            title: '提示',
            content:
              '请检查一遍相册，照片有无分割人物，重复的情况，一旦提交，无法修改',
            confirmText: '确认提交',
            cancelText: '去检查',
            onCancel: () => {
              console.log('点击cancel')
            },
            onOk: async () => {
              let result = await FetchRequest('/wap/graduation/submitMode', {
                token: localStorage.getItem('token'),
                activity_id: props.match.params.id,
                eroll_id: getParamValue('eroll_id'),
              })
              Loading.hide()
              if (result.code === 1) {
                let r: IresultType = await FetchRequest(
                  '/wap/graduation/getMode/',
                  {
                    token: localStorage.getItem('token'),
                    activity_id: props.match.params.id,
                    eroll_id: getParamValue('eroll_id'),
                  }
                )
                if (r.code === 1) {
                  if (r.data.plate !== 2) {
                    let images_res = await FetchRequest(
                      '/wap/graduation/getImage',
                      {
                        token: localStorage.getItem('token'),
                      }
                    )
                    const modal = Modal.alert({
                      title: '制作成功',
                      content: (
                        <div>
                          <div>
                            <img
                              src={images_res.data}
                              style={{ width: '60%' }}
                              alt=""
                            />
                          </div>
                          <div style={{ color: 'red', textAlign: 'center' }}>
                            【下载底片】需长按关注此公众号
                          </div>
                        </div>
                      ),
                      onCancel: () => {
                        modal.hide()
                        if (r.data.plate === 1) {
                          // 跳转下载页
                          store.routerStore.replace('/build/film-download')
                        }
                      },
                    })
                  } else {
                    store.routerStore.replace(
                      `/build/graduation-select/${props.match.params.id}`
                    )
                  }
                } else {
                  Toast.show(r.msg)
                }
              } else {
                // Toast.show(result.msg)
                const modal = Modal.alert({
                  title: '提示',
                  content: result.msg,
                  onCancel: () => {
                    modal.hide()
                  },
                })
              }
            },
          })
          return
        }
      }

      // =====
      Loading.show({
        content: <ActivityIndicator size="lg" />,
      })
      let result = await FetchRequest('/wap/graduation/saveMode', {
        token: localStorage.getItem('token'),
        // modeData: encodeURI(JSON.stringify(currentHandleData)),
        // basic: JSON.stringify(currentHandleData.basic),
        basic: JSON.stringify(
          saveIamgesBase64[currentHandleData.name!]['params']
        ),
        modeUrl: currentHandleData.modeUrl,
        name: currentHandleData.name,
        eroll_id: getParamValue('eroll_id'),
        activity_id: props.match.params.id,
        page: currentHandleIndex + 1,
      })
      Loading.hide()
      // ======
      const modal = Modal.confirm({
        title: '提示',
        content:
          '请检查一遍相册，照片有无分割人物，重复的情况，一旦提交，无法修改',
        confirmText: '确认提交',
        cancelText: '去检查',
        onCancel: () => {
          console.log('点击cancel')
        },
        onOk: async () => {
          if (result.code === 1) {
            let result = await FetchRequest('/wap/graduation/submitMode', {
              token: localStorage.getItem('token'),
              activity_id: props.match.params.id,
              eroll_id: getParamValue('eroll_id'),
            })
            Loading.hide()
            if (result.code === 1) {
              let r: IresultType = await FetchRequest(
                '/wap/graduation/getMode/',
                {
                  token: localStorage.getItem('token'),
                  activity_id: props.match.params.id,
                  eroll_id: getParamValue('eroll_id'),
                }
              )
              if (r.code === 1) {
                if (r.data.plate !== 2) {
                  let images_res = await FetchRequest(
                    '/wap/graduation/getImage',
                    {
                      token: localStorage.getItem('token'),
                    }
                  )
                  const modal = Modal.alert({
                    title: '制作成功',
                    content: (
                      <div>
                        <div>
                          <img
                            src={images_res.data}
                            style={{ width: '60%' }}
                            alt=""
                          />
                        </div>
                        <div style={{ color: 'red', textAlign: 'center' }}>
                          【下载底片】需长按关注此公众号
                        </div>
                      </div>
                    ),
                    onCancel: () => {
                      modal.hide()
                      if (r.data.plate === 1) {
                        // 跳转下载页
                        store.routerStore.replace('/build/film-download')
                      }
                    },
                  })
                } else {
                  store.routerStore.replace(
                    `/build/graduation-select/${props.match.params.id}`
                  )
                }
              } else {
                Toast.show(r.msg)
              }
            } else {
              // Toast.show(result.msg)
              const modal = Modal.alert({
                title: '提示',
                content: result.msg,
                onCancel: () => {
                  modal.hide()
                },
              })
            }
          } else {
            saveIamgesBase64[currentHandleData.name!]['save'] = true
            Toast.show(result.msg)
          }
        },
      })
    }

    const [isMenPop, setIsMenPop] = useState(true)

    //// 中间提示
    useEffect(() => {
      if (
        isSelectImages &&
        isMenPop &&
        data.length / 2 < currentHandleIndex &&
        data.length !== currentHandleIndex + 1
      ) {
        getSelectCount((value) => {
          return Modal.confirm({
            title: '提醒',
            content: (
              <div style={{ lineHeight: 1.6 }}>
                <div>您的套餐包含入册{value.shouldmeal}张</div>
                <div style={{ color: 'red' }}>
                  预计您还有{value.photomeal - value.shouldmeal}张无法入册
                </div>
                <div>如需增加入册请点击【查看套餐】按钮</div>
              </div>
            ),
            okText: '查看套餐',
            cancelText: '无需升级',
            onCancel: () => {
              console.log('点击cancel')
              setIsMenPop(false)
            },
            onOk: () => {
              console.log('点击ok')
              setPackageStatus(true)
            },
          })
        })
      }
    }, [isSelectImages])

    const [isMenPopEnd, setIsMenPopEnd] = useState(true)

    //// 最后提示
    useEffect(() => {
      if (
        isSelectImages &&
        isMenPopEnd &&
        data.length === currentHandleIndex + 1
      ) {
        getSelectCount((value) => {
          return Modal.confirm({
            title: '提醒',
            content: (
              <div style={{ lineHeight: 1.6 }}>
                <div>您的套餐包含入册{value.shouldmeal}张</div>
                <div style={{ color: 'red' }}>
                  预计您还有{value.photomeal - value.shouldmeal}张无法入册
                </div>
                <div>如需增加入册请点击【查看套餐】按钮</div>
              </div>
            ),
            okText: '查看套餐',
            cancelText: '无需升级',
            onCancel: () => {
              console.log('点击cancel')
              setIsMenPopEnd(false)
            },
            onOk: () => {
              console.log('点击ok')
              setPackageStatus(true)
            },
          })
        })
      }
    }, [isSelectImages])

    // true 通过
    function handleBloclTipes(): boolean {
      if (getModel.is_submit === 1 && !getParamValue('is_update')) {
        Toast.show('相册已经送厂制作不能修改')
        return false
      }
      return true
    }

    if (data.length === 0) {
      return <div className={style.tipes_null}>{nullStatus}</div>
    }

    if (isSelectImages) {
      let select_count = 0
      Object.values(saveIamgesBase64).forEach((v: any) => {
        select_count =
          select_count +
          Object.values(v).filter((v) => typeof v === 'string').length
      })
      return (
        <div>
          <SelectionImages
            select_count={select_count}
            getMealNum={getMealNum}
            direction={direction}
            originImages={
              Object.keys(currentHandleData.ori || {}).length > 0
                ? (currentHandleData.ori as any)[
                    `position${TemplateFillingindex}_pic`
                  ]
                : ''
            }
            select={saveIamgesBase64}
            currentHandleData={currentHandleData}
            TemplateFillingindex={TemplateFillingindex}
            isSelect={async (e) => {
              // setFillImagesUrl(await getBase64(e))
              setFillImagesUrl(e + `&time=${Date.now()}`)
              setIsSelectImages(false)
              setIsCuttingImages(true)
            }}
            isClose={() => setIsSelectImages(false)}
          />
          <Popup
            visible={packageStatus}
            direction="center"
            onMaskClick={() => {
              setPackageStatus(false)
              setIsMenPop(true)
            }}
            afterOpen={() => console.log('打开')}
            afterClose={() => console.log('关闭')}
            destroy={true}
            mountContainer={() => document.body}
          >
            <div className={style.upgradePackageContent}>
              <div className={style.closeThePackages}>
                <Button
                  theme="primary"
                  size="sm"
                  onClick={() => {
                    setPackageStatus(false)
                    setIsMenPop(true)
                  }}
                >
                  关闭
                </Button>
              </div>
              <div className={style.thePackageList}>
                {meal.map((v, i) => {
                  return (
                    <>
                      <div className={style.packageContent} key={i}>
                        <span className={style.packageName}>
                          {v.meal}套餐{v.price}元
                        </span>
                        {v.pinfo === '您已购买此套餐' && (
                          <span className={style.packageName}>当前套餐</span>
                        )}
                        <img src={v.info} alt="" />
                        <div className={style.thatList}>
                          {v.detail &&
                            (v.detail || []).map((v, index) => {
                              if (v) {
                                return <div key={index}>{v}</div>
                              }
                            })}
                        </div>
                        <Button
                          shape="round"
                          theme="danger"
                          block
                          disabled={v.pinfo === '您已购买此套餐'}
                          className={style.paymentButton}
                          onClick={() =>
                            upgradePackage(v.meal!, Number(v.price))
                          }
                        >
                          {v.pinfo}
                        </Button>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </Popup>
        </div>
      )
    }

    return (
      <div className={style.content_body}>
        {/* 图片 */}
        <WaterMaker text={localStorage.getItem('watermark_settings') || ''} />
        <div
          className={style.content_images}
          id="content"
          style={{
            marginTop: (document.body.clientHeight - 500) / 2,
            height:
              currentHandleData.fileType === 1 ||
              currentHandleData.fileType === 2 ||
              currentHandleData.fileType === 3
                ? 500
                : 'auto',
          }}
        >
          {currentHandleData.basic?.rightUrl ? (
            <img src={currentHandleData.basic?.rightUrl} alt="" />
          ) : currentHandleData.fileType === 2 ||
            currentHandleData.fileType === 4 ? (
            Boolean(currentHandleData.width) &&
            Boolean(currentHandleData.height) ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: 150,
                    border: '1px solid #000',
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  {/* 编辑 */}
                  <img
                    src={currentHandleData.modeUrl}
                    alt=""
                    style={{ width: '100%' }}
                  />
                </div>
                <div
                  style={{
                    position: 'relative',
                    border: '1px solid #000',
                    width: 200,
                    height: 200,
                    margin: '0 auto',
                    marginTop: '34%',
                    background: '#b8b8b8',
                    display: 'flex',
                    overflow: 'hidden',
                    justifyContent: imagesStatus ? 'normal' : 'center',
                    alignItems: imagesStatus ? 'center' : 'normal',
                  }}
                  onClick={async () => {
                    if (!handleBloclTipes()) {
                      return
                    }
                    setTemplateFillingindex(1)
                    setImagesProportionSize({
                      width: (currentHandleData.height || 1) * 10,
                      height: (currentHandleData.width || 1) * 10,
                    })
                    await handleGetMeal()
                    setIsSelectImages(true)
                  }}
                >
                  {!saveIamgesBase64[currentHandleData.name as string]['1'] && (
                    <span
                      style={{
                        transform: 'rotateZ(90deg)',
                        lineHeight: '200px',
                        fontSize: 13,
                      }}
                    >
                      点击选择
                    </span>
                  )}
                  <img
                    src={
                      saveIamgesBase64[currentHandleData.name as string]['1']
                    }
                    ref={imgRef}
                    alt=""
                    style={
                      imagesStatus ? { width: '100%' } : { height: '100%' }
                    }
                  />
                </div>
              </div>
            ) : (
              <img
                src={currentHandleData.modeUrl}
                alt=""
                // ref={imgRef}
                style={{
                  width: 250,
                  height: 250,
                  position: 'fixed',
                  top: (document.documentElement.clientHeight - 250) / 2,
                }}
              />
            )
          ) : (
            <img src={currentHandleData.modeUrl} alt="" />
          )}

          {/* {currentHandleData.combineUrl && (
            <div className={style.images_combineUrl}>
              <img src={currentHandleData.combineUrl} alt="" ref={imgRef} />
            </div>
          )} */}

          {/* 图层覆盖 */}
          {currentHandleData.name === 'two' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              >
                {/* <span className={style.text_update}>点击编辑</span> */}
              </div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 + 1,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 + 1,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 - 2,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10 + 1,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 - 2,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10 + 1,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10 - 1,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10 - 1,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 5,
                    height: currentHandleData.basic.position4_width / 5,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第五个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10 - 3,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10 - 1,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['5']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10 - 3,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10 - 1,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(5)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position5_height / 5,
                    height: currentHandleData.basic.position5_width / 5,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {/* 有一个块 */}
          {currentHandleData.name === 'one' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 字体 */}
              <div className={style.username_one}>
                {currentHandleData.baby_name}
              </div>
              {/* <div className={style.time_one}>{currentHandleData.gtime}</div> */}
              {/* <div className={style.class_one}>{currentHandleData.class}</div> */}
            </>
          )}

          {/* 有二个块 */}
          {currentHandleData.name === 'three' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {/* 四块 */}
          {currentHandleData.name === 'four' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10 + 4,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10 + 3,
                  zIndex: 2,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10 + 4,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10 + 3,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10 - 2,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10 - 2,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10 - 2,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10 - 2,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 5,
                    height: currentHandleData.basic.position4_width / 5,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {/* 五块 */}
          {currentHandleData.name === 'fourteen' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                  transform: 'rotate(331deg)',
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                  transform: 'rotate(331deg)',
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                  transform: 'rotate(12deg)',
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                  transform: 'rotate(12deg)',
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                  transform: 'rotate(351deg)',
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                  transform: 'rotate(351deg)',
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 5,
                    height: currentHandleData.basic.position4_width / 5,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第五个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  // top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  // right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  // width: currentHandleData.basic.position5_height / 10,
                  // height: currentHandleData.basic.position5_width / 10,
                  top: '264.9px',
                  right: '24.5px',
                  width: '58.6px',
                  height: '65.1px',
                  transform: 'rotate(333deg)',
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['5']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  // top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  // right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  // width: currentHandleData.basic.position5_height / 10,
                  // height: currentHandleData.basic.position5_width / 10,
                  top: '264.9px',
                  right: '24.5px',
                  width: '58.6px',
                  height: '65.1px',
                  transform: 'rotate(333deg)',
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(5)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position5_height / 5,
                    height: currentHandleData.basic.position5_width / 5,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {/* 有三个块 */}
          {currentHandleData.name === 'five' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10 + 4,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10 + 4,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'six' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'seven' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'eight' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'nine' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'ten' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10 + 1,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10 + 1,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 - 1,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 - 1,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'eleven' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 4,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 4,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 + 1,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10 + 4,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 + 1,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10 + 4,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10 - 3,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10 + 3,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10 - 3,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10 + 3,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 5,
                    height: currentHandleData.basic.position4_width / 5,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twelve' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10 + 1,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10 + 1,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 + 1,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10 + 4,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 + 1,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10 + 4,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10 - 1,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10 - 1,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10 + 1,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10 + 1,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10 + 1,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 5,
                    height: currentHandleData.basic.position4_width / 5,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirteen' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10 - 2,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10 - 2,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'fifteen' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10 + 1,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10 + 1,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 - 1,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 - 1,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'sixteen' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 3,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 + 2,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10 - 2,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10 - 2,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 5,
                    height: currentHandleData.basic.position4_width / 5,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'seventeen' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 - 3,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 - 3,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10 - 1,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10 - 1,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10 - 1,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 5,
                    height: currentHandleData.basic.position4_width / 5,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第五个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10 - 2,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10 - 2,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['5']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10 - 2,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10 - 2,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(5)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position5_height / 5,
                    height: currentHandleData.basic.position5_width / 5,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'eighteen' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 4,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10 + 5,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10 + 4,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10 + 5,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 8,
                    height: currentHandleData.basic.position1_width / 8,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 + 2,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10 + 2,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 - 3,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10 - 2,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10 - 3,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10 - 2,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}
          {currentHandleData.name === 'nineteen' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第五个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10 - 2,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10 - 2,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['5']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(5)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position5_height / 10,
                    height: currentHandleData.basic.position5_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twenty' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twentyone' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twentytwo' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第五个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['5']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(5)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position5_height / 10,
                    height: currentHandleData.basic.position5_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第六个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position6_x) / 10,
                  right: Math.abs(currentHandleData.basic.position6_y) / 10,
                  width: currentHandleData.basic.position6_height / 10,
                  height: currentHandleData.basic.position6_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['6']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position6_x) / 10,
                  right: Math.abs(currentHandleData.basic.position6_y) / 10,
                  width: currentHandleData.basic.position6_height / 10,
                  height: currentHandleData.basic.position6_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(6)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position6_height / 10,
                    height: currentHandleData.basic.position6_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第七个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position7_x) / 10,
                  right: Math.abs(currentHandleData.basic.position7_y) / 10,
                  width: currentHandleData.basic.position7_height / 10,
                  height: currentHandleData.basic.position7_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['7']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position7_x) / 10,
                  right: Math.abs(currentHandleData.basic.position7_y) / 10,
                  width: currentHandleData.basic.position7_height / 10,
                  height: currentHandleData.basic.position7_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(7)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position7_height / 10,
                    height: currentHandleData.basic.position7_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第八个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position8_x) / 10,
                  right: Math.abs(currentHandleData.basic.position8_y) / 10,
                  width: currentHandleData.basic.position8_height / 10,
                  height: currentHandleData.basic.position8_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['8']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position8_x) / 10,
                  right: Math.abs(currentHandleData.basic.position8_y) / 10,
                  width: currentHandleData.basic.position8_height / 10,
                  height: currentHandleData.basic.position8_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(8)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position8_height / 10,
                    height: currentHandleData.basic.position8_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twentythree' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第五个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['5']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(5)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position5_height / 10,
                    height: currentHandleData.basic.position5_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第六个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position6_x) / 10,
                  right: Math.abs(currentHandleData.basic.position6_y) / 10,
                  width: currentHandleData.basic.position6_height / 10,
                  height: currentHandleData.basic.position6_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['6']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position6_x) / 10,
                  right: Math.abs(currentHandleData.basic.position6_y) / 10,
                  width: currentHandleData.basic.position6_height / 10,
                  height: currentHandleData.basic.position6_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(6)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position6_height / 10,
                    height: currentHandleData.basic.position6_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第七个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position7_x) / 10,
                  right: Math.abs(currentHandleData.basic.position7_y) / 10,
                  width: currentHandleData.basic.position7_height / 10,
                  height: currentHandleData.basic.position7_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['7']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position7_x) / 10,
                  right: Math.abs(currentHandleData.basic.position7_y) / 10,
                  width: currentHandleData.basic.position7_height / 10,
                  height: currentHandleData.basic.position7_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(7)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position7_height / 10,
                    height: currentHandleData.basic.position7_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第八个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position8_x) / 10,
                  right: Math.abs(currentHandleData.basic.position8_y) / 10,
                  width: currentHandleData.basic.position8_height / 10,
                  height: currentHandleData.basic.position8_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['8']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position8_x) / 10,
                  right: Math.abs(currentHandleData.basic.position8_y) / 10,
                  width: currentHandleData.basic.position8_height / 10,
                  height: currentHandleData.basic.position8_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(8)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position8_height / 10,
                    height: currentHandleData.basic.position8_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twentyfour' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第五个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['5']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(5)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position5_height / 10,
                    height: currentHandleData.basic.position5_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第六个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position6_x) / 10,
                  right: Math.abs(currentHandleData.basic.position6_y) / 10,
                  width: currentHandleData.basic.position6_height / 10,
                  height: currentHandleData.basic.position6_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['6']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position6_x) / 10,
                  right: Math.abs(currentHandleData.basic.position6_y) / 10,
                  width: currentHandleData.basic.position6_height / 10,
                  height: currentHandleData.basic.position6_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(6)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position6_height / 10,
                    height: currentHandleData.basic.position6_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第七个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position7_x) / 10,
                  right: Math.abs(currentHandleData.basic.position7_y) / 10,
                  width: currentHandleData.basic.position7_height / 10,
                  height: currentHandleData.basic.position7_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['7']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position7_x) / 10,
                  right: Math.abs(currentHandleData.basic.position7_y) / 10,
                  width: currentHandleData.basic.position7_height / 10,
                  height: currentHandleData.basic.position7_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(7)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position7_height / 10,
                    height: currentHandleData.basic.position7_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第八个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position8_x) / 10,
                  right: Math.abs(currentHandleData.basic.position8_y) / 10,
                  width: currentHandleData.basic.position8_height / 10,
                  height: currentHandleData.basic.position8_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['8']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position8_x) / 10,
                  right: Math.abs(currentHandleData.basic.position8_y) / 10,
                  width: currentHandleData.basic.position8_height / 10,
                  height: currentHandleData.basic.position8_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(8)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position8_height / 10,
                    height: currentHandleData.basic.position8_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twentyfive' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twentysix' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twentyseven' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twentyeight' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'twentynine' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirty' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirtyone' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirtytwo' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第五个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['5']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(5)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position5_height / 10,
                    height: currentHandleData.basic.position5_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirtythree' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirtyfour' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirtyfive' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirtysix' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第五个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['5']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(5)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position5_height / 10,
                    height: currentHandleData.basic.position5_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirtyseven' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirtyeight' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'thirtynine' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'fourty' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'fourtyone' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}

          {currentHandleData.name === 'fourtytwo' && (
            <>
              {/* 第一个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['1']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position1_x) / 10,
                  right: Math.abs(currentHandleData.basic.position1_y) / 10,
                  width: currentHandleData.basic.position1_height / 10,
                  height: currentHandleData.basic.position1_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(1)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position1_height / 10,
                    height: currentHandleData.basic.position1_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第二个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['2']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position2_x) / 10,
                  right: Math.abs(currentHandleData.basic.position2_y) / 10,
                  width: currentHandleData.basic.position2_height / 10,
                  height: currentHandleData.basic.position2_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(2)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position2_height / 10,
                    height: currentHandleData.basic.position2_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第三个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['3']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position3_x) / 10,
                  right: Math.abs(currentHandleData.basic.position3_y) / 10,
                  width: currentHandleData.basic.position3_height / 10,
                  height: currentHandleData.basic.position3_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(3)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position3_height / 10,
                    height: currentHandleData.basic.position3_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第四个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['4']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position4_x) / 10,
                  right: Math.abs(currentHandleData.basic.position4_y) / 10,
                  width: currentHandleData.basic.position4_height / 10,
                  height: currentHandleData.basic.position4_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(4)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position4_height / 10,
                    height: currentHandleData.basic.position4_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
              {/* 第五个 块 */}
              <div
                className={style.block_1_1}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
              >
                <img
                  src={saveIamgesBase64[currentHandleData.name]['5']}
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className={style.block_1_1_top}
                style={{
                  top: Math.abs(currentHandleData.basic.position5_x) / 10,
                  right: Math.abs(currentHandleData.basic.position5_y) / 10,
                  width: currentHandleData.basic.position5_height / 10,
                  height: currentHandleData.basic.position5_width / 10,
                }}
                onClick={async () => {
                  if (!handleBloclTipes()) {
                    return
                  }
                  setTemplateFillingindex(5)
                  setImagesProportionSize({
                    width: currentHandleData.basic.position5_height / 10,
                    height: currentHandleData.basic.position5_width / 10,
                  })
                  await handleGetMeal()
                  setIsSelectImages(true)
                }}
              ></div>
            </>
          )}
        </div>

        {/* 版面名称 */}
        {currentHandleData.fileType && (
          <div className={style.guding_text}>
            {currentHandleData.fileType === 3 && (
              <div style={{ fontSize: 15, fontWeight: 'bold' }}>
                {currentHandleData.name}
              </div>
            )}

            {currentHandleData.fileType === 1 && (
              <div>点击灰色部分可选择您喜爱的照片上传</div>
            )}

            {currentHandleData.name === 'one' && (
              <div>
                此页中有您孩子的姓名，请核实是否有误，如有误请拨打客服电话修改
              </div>
            )}

            {currentHandleData.fileType === 3 && (
              <div>
                此页为学校指定版面不可编辑设计，仅供查看，继续设计相册请点击【下一页】按钮
              </div>
            )}

            {currentHandleData.fileType === 2 &&
              Number(currentHandleData.width || 0) > 0 &&
              Number(currentHandleData.height || 0) > 0 && (
                <div>此页为封面样式，请点击右边灰色部分选择封面照片</div>
              )}

            {currentHandleData.fileType === 4 && (
              <div>
                此页为{currentHandleData.name}
                的样式，请点击右边灰色的部分，选择您喜欢的照片制作该产品
              </div>
            )}

            {currentHandleData.fileType === 2 &&
              !currentHandleData.width &&
              !currentHandleData.height && ( // 固定版面
                <div style={{ position: 'fixed', top: 42 }}>
                  <div style={{ fontSize: 15, fontWeight: 'bold' }}>
                    {currentHandleData.name}
                  </div>
                  <div className={style.theAlbumCover}>
                    此页是相册封面不可编辑设计，仅供查看，继续设计相册请点击【下一页】按钮
                  </div>
                </div>
              )}
          </div>
        )}

        {/* 按钮 */}
        <div
          className={style.btn_menu}
          style={
            currentHandleData.fileType === 2
              ? {
                  bottom: '49%',
                }
              : {
                  bottom: '50%',
                }
          }
        >
          <Button
            size="xs"
            theme="primary"
            onClick={() => {
              setDirection('left')
              setCurrentHandleIndex(currentHandleIndex - 1)
            }}
            disabled={currentHandleIndex === 0 ? true : false}
          >
            上一页
          </Button>
          <div className={style.pageSize_text}>
            {currentHandleData.fileType === 2
              ? '相册封面'
              : currentHandleData.fileType === 4
              ? currentHandleData.name
              : `第${
                  data.length > 0 && data[0].fileType === 2
                    ? currentHandleIndex
                    : currentHandleIndex + 1
                }页`}
            {currentHandleData.fileType !== 4 && (
              <>
                <span style={{ margin: '0 5px' }}>/</span>共
              </>
            )}
            {currentHandleData.fileType !== 4 && (
              <>
                {data.filter((v) => v.fileType != 2 && v.fileType != 4).length}
                页
              </>
            )}
          </div>
          <Button
            size="xs"
            theme="primary"
            onClick={async () => {
              setDirection('right')
              /// 判断当前用户是否修改
              await handleClickSave()
            }}
            disabled={data.length - 1 === currentHandleIndex ? true : false}
          >
            下一页
          </Button>
        </div>

        {/* currentHandleData.fileType !== 2 &&
          currentHandleData.fileType !== 3 && */}
        {data.length === currentHandleIndex + 1 &&
          (getModel.is_submit === 0 || getParamValue('is_update')) && (
            <Button
              size="xs"
              theme={
                data.length === currentHandleIndex + 1 ? 'danger' : 'primary'
              }
              disabled={
                timer
                  ? data.length - 1 === currentHandleIndex
                    ? true
                    : false
                  : false
              }
              className={style.btn_menu_save}
              style={{ right: '79%', bottom: '18%', width: 100 }}
              // onClick={handleClickSave}
              onClick={photoAlbumPreview}
            >
              下一步
            </Button>
          )}

        {/* <Button
          size="sm"
          theme="primary"
          className={style.btn_menu_save}
          style={{ right: '5%' }}
          onClick={() => {
            setOperationGuide(1)
          }}
        >
          获取帮助
        </Button> */}

        {!getParamValue('is_update') && (
          <a
            href={`tel:${graduation.consultation_telephone}`}
            className={style.customerServiceTelephone}
            style={{
              transform: 'rotate(90deg)',
              top: '90%',
              right: '10%',
            }}
          >
            <img src={DHImages} alt="" />
          </a>
        )}

        {meal.length > 1 && getModel.is_submit === 0 && (
          <div
            className={style.customerServiceTelephone}
            style={{
              transform: 'rotate(90deg)',
              top: '90%',
              right: '25%',
              fontSize: 12,
              padding: 10,
              color: '#fff',
            }}
            onClick={() => {
              setPackageStatus(true)
            }}
          >
            升级
            <br />
            套餐
          </div>
        )}

        {CombineUrlList.length > 0 && (
          <ImagePreview
            visible={CombineUrlListView}
            images={CombineUrlList}
            onClose={() => setCombineUrlListView(false)}
            maxScale={5}
            activeIndex={pictureIndex}
          />
        )}

        {/* <Popup
          visible={isSelectImages}
          direction="bottom"
          onMaskClick={() => setIsSelectImages(false)}
          afterOpen={() => console.log('打开')}
          afterClose={() => console.log('关闭')}
          destroy={false}
          mountContainer={() => document.body}
        >
          <div>
            <SelectionImages
              direction={direction}
              originImages={
                Object.keys(currentHandleData.ori || {}).length > 0
                  ? (currentHandleData.ori as any)[
                      `position${TemplateFillingindex}_pic`
                    ]
                  : ''
              }
              select={saveIamgesBase64}
              currentHandleData={currentHandleData}
              TemplateFillingindex={TemplateFillingindex}
              isSelect={async (e) => {
                // setFillImagesUrl(await getBase64(e))
                setFillImagesUrl(e + `&time=${Date.now()}`)
                setIsSelectImages(false)
                setIsCuttingImages(true)
              }}
              isClose={() => setIsSelectImages(false)}
            />
          </div>
        </Popup> */}

        {/* 裁剪 */}
        <Popup
          visible={isCuttingImages}
          direction="left"
          onMaskClick={() => setIsCuttingImages(false)}
          afterOpen={() => console.log('打开')}
          afterClose={() => console.log('关闭')}
          destroy={true}
          mountContainer={() => document.body}
        >
          <div className={style.selec_list}>
            <ImagesUpdateNew
              originImages={
                Object.keys(currentHandleData.ori || {}).length > 0
                  ? (currentHandleData.ori as any)[
                      `position${TemplateFillingindex}_pic`
                    ]
                  : ''
              }
              fillImagesUrl={fillImagesUrl}
              ImagesProportionSize={ImagesProportionSize}
              isok={(s, p) => {
                /////// 回显数据
                const newObj = Object.assign({}, saveIamgesBase64)
                newObj[currentHandleData.name!][`${TemplateFillingindex}`] = s

                newObj[currentHandleData.name!]['params'][
                  `position${TemplateFillingindex}_pic`
                ] = ((fillImagesUrl.split('-water').join('') ||
                  fillImagesUrl) as string).split('&time=')[0]
                newObj[currentHandleData.name!]['params'][
                  `position${TemplateFillingindex}_pic_x`
                ] = p.x
                newObj[currentHandleData.name!]['params'][
                  `position${TemplateFillingindex}_pic_y`
                ] = p.y

                newObj[currentHandleData.name!]['params'][
                  `position${TemplateFillingindex}_pic_width`
                ] = p.width
                newObj[currentHandleData.name!]['params'][
                  `position${TemplateFillingindex}_pic_height`
                ] = p.height

                newObj[currentHandleData.name!]['save'] = false

                ////// 存放区块图片
                newObj[currentHandleData.name!]['params'][
                  `position${TemplateFillingindex}_pic_cut`
                ] =
                  saveIamgesBase64[currentHandleData.name!][
                    TemplateFillingindex.toString()
                  ]

                setSaveIamgesBase64(newObj)
                /////// 数据还原处理
                setIsCuttingImages(false)
              }}
            />
            <div
              className={style.close_icon}
              onClick={() => setIsCuttingImages(false)}
            >
              <Button theme="danger" block>
                关闭
              </Button>
              {/* <img src={CloseImages} alt="" /> */}
            </div>
          </div>
        </Popup>

        {/* 升级套餐 */}
        <Popup
          visible={packageStatus}
          direction="center"
          onMaskClick={() => setPackageStatus(false)}
          afterOpen={() => console.log('打开')}
          afterClose={() => console.log('关闭')}
          destroy={true}
          mountContainer={() => document.body}
        >
          <div className={style.upgradePackageContent}>
            <div className={style.closeThePackages}>
              <Button
                theme="primary"
                size="sm"
                onClick={() => setPackageStatus(false)}
              >
                关闭
              </Button>
            </div>
            <div className={style.thePackageList}>
              {meal.map((v, i) => {
                return (
                  <>
                    <div className={style.packageContent} key={i}>
                      <span className={style.packageName}>
                        {v.meal}套餐{v.price}元
                      </span>
                      {v.pinfo === '您已购买此套餐' && (
                        <span className={style.packageName}>当前套餐</span>
                      )}
                      <img src={v.info} alt="" />
                      <div className={style.thatList}>
                        {v.detail &&
                          (v.detail || []).map((v, index) => {
                            if (v) {
                              return <div key={index}>{v}</div>
                            }
                          })}
                      </div>
                      <Button
                        shape="round"
                        theme="danger"
                        block
                        disabled={v.pinfo === '您已购买此套餐'}
                        className={style.paymentButton}
                        onClick={() => upgradePackage(v.meal!, Number(v.price))}
                      >
                        {v.pinfo}
                      </Button>
                    </div>
                    {/* {v.pinfo === '您已购买此套餐' && (
                      <div className={style.text_tipes}>
                        您还可以升级以下套餐
                      </div>
                    )} */}
                  </>
                )
              })}
            </div>
          </div>
        </Popup>
      </div>
    )
  }
)
