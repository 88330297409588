import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Toast, Button, Modal, Loading, Cell } from 'zarm'
import { store } from '../../store/store.root'
import { FetchRequest } from '../../utils/fetch'
import style from './index.module.scss'
import { format } from 'date-fns'
import { MyDistribution } from '../my-distribution'

export default withRouter((props) => {
  useEffect(() => {
    forActivityDetailsData()
  }, [])

  const [forActivityDetailsDataListm, setForActivityDetailsData] = useState([])

  async function forActivityDetailsData() {
    const res = await FetchRequest(
      `/wap/marketing/saleman/getSalemansActivityLists2?token=${localStorage.getItem(
        'token'
      )}`,
      {},
      'GET'
    )
    if (res.code === 1) {
      // if (res.data.lists.length === 1) {
      //   store.routerStore.push(
      //     `/build/my-settlement/detaile/list?is_leader=${res.data.lists[0].is_leader}&id=${res.data.lists[0].id}&activity_type=${props.match.params.name}`
      //   )
      // }
      setForActivityDetailsData(res.data.lists)
    } else {
      Toast.show(res.msg)
    }
  }

  const [selectActionState, setSelectActionState] = useState(false)

  function handleClickMyHref() {
    if (forActivityDetailsDataListm.length === 1) {
      handleShowkMyHref(forActivityDetailsDataListm[0])
    } else {
      setSelectActionState(true)
    }
  }

  async function handleShowkMyHref(item) {
    Loading.show()
    const res = await FetchRequest(
      `wap/marketing/saleman/getShareQrImage?token=${localStorage.getItem(
        'token'
      )}&activity_type=${item.activity_type}&activity_id=${item.id}`,
      {},
      'GET'
    )
    Loading.hide()
    if (res.code === 1) {
      const modal = Modal.alert({
        title: '我的专属链接',
        content: (
          <div>
            <img
              src={res.data.url}
              alt="我的专属链接"
              style={{ width: '100%', marginBottom: 10 }}
            />
            <div style={{ color: 'red' }}>长按识别进入我的专属链接</div>
          </div>
        ),
        onCancel: () => {
          modal.hide()
        },
      })
    } else {
      Toast.show(res.msg)
    }
  }

  function handleTimeDetermine(v) {
    const theCurrentTime = parseInt(new Date().getTime() / 1000)
    if (v.start_time < theCurrentTime < v.end_time) {
      return <span style={{ color: '#00bc71' }}>进行中</span>
    }
    if (v.start_time > theCurrentTime) {
      return <span>尚未开始</span>
    }
    if (v.end_time < theCurrentTime) {
      return <span style={{ color: 'red' }}>活动已结束</span>
    }
    return ''
  }

  return (
    <>
      <div className={style.contentDistribution}>
        {forActivityDetailsDataListm.map((v) => {
          return (
            <div
              className={style.item}
              key={v.id}
              onClick={() => {
                store.routerStore.push(
                  `/build/my-settlement/detaile/list?is_leader=${v.is_leader}&id=${v.id}&activity_type=${v.activity_type}`
                )
              }}
            >
              <div className={style.images_left}>
                <img src={v.share_image} alt="" />
              </div>
              <div className={style.name}>
                <div className={style.title_text}>{v.title}</div>
                <div className={style.desc_text}>{v.share_desc}</div>
                <div style={{ fontSize: 12, marginTop: 36, color: '#a3a3a3' }}>
                  <div>
                    开始时间: {format(v.start_time * 1000, 'yyyy-MM-dd')}
                  </div>
                  <div className={style.end_time}>
                    结束时间: {format(v.end_time * 1000, 'yyyy-MM-dd')}
                    {handleTimeDetermine(v)}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {/* 悬浮按钮 */}
      <div className={style.btn_href}>
        <Button block theme="primary" onClick={handleClickMyHref}>
          我的专属链接
        </Button>
      </div>
      {/* 列表 */}
      <Modal title="请选择活动" visible={selectActionState}>
        <div>
          {forActivityDetailsDataListm.map((v) => {
            return (
              <Cell
                key={v.id}
                title={v.share_desc}
                icon={
                  <img
                    alt=""
                    src={v.share_image}
                    style={{ width: 24, height: 24 }}
                  />
                }
                onClick={() => {
                  setSelectActionState(false)
                  handleShowkMyHref(v)
                }}
              />
            )
          })}
        </div>
      </Modal>
      <MyDistribution
        isshow={forActivityDetailsDataListm.length === 0 ? true : false}
      />
    </>
  )
})
