import React from "react";
import "./employee-query-preorder-clothe-list.scss";
import { BangBangTangLayout } from "../../layout/layout";
import { EmployeeQueryPreorderClotheListHeaderComponent } from "./employee-query-preorder-clothe-list-header.component";
import { Button, Pull } from "zarm";
import { store } from "../../store/store.root";
import { observer } from "mobx-react";
import { EmployeeQueryPreorderClotheCard } from "./employee-query-preorder-clothe-card";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";

@observer
export class EmployeeQueryPreorderClotheList extends React.Component<any, any> {
  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      store.employeePreorderClotheListStore.get_schedule_type();
      document.title = "预选单管理";
    });
  }

  render() {
    return (
      <BangBangTangLayout>
        <EmployeeQueryPreorderClotheListHeaderComponent />
        <div
          style={{
            marginTop: `${store.employeePreorderClotheListStore.header_height}px`,
            height: `calc(100vh - ${store.employeePreorderClotheListStore.header_height}px)`,
          }}
        >
          <Pull
            load={{
              state: store.employeePreorderClotheListStore.data.load_state,
              handler: store.employeePreorderClotheListStore.load_more,
              distance: 100,
            }}
          >
            {store.employeePreorderClotheListStore.current_active.data?.map(
              (value) => (
                <EmployeeQueryPreorderClotheCard key={value.id} data={value} />
              )
            )}
          </Pull>
        </div>
        <div className="reset-button">
          <Button
            onClick={() => {
              store.employeePreorderClotheListStore.setSearchText("");
              store.employeePreorderClotheListStore.setSearchTime("");
              store.employeePreorderClotheListStore.resetData();
              store.employeePreorderClotheListStore.load_more();
            }}
          >
            重置搜索
          </Button>
          {store.websiteRuleStore.has_rule("create_clothing_selection2") ? (
            <Button
              onClick={() => {
                store.routerStore.push(
                  "/build/employee-preorder-clothe-detail"
                );
              }}
            >
              生成预选单
            </Button>
          ) : null}
        </div>
      </BangBangTangLayout>
    );
  }
}
