import React from "react";
import { observer } from "mobx-react";
import { SelectGlobalStore } from "../components/select-global-store/select-global-store";

@observer
export class LayoutEnsureStoreSelected extends React.Component<any, any> {
  componentDidMount() {}

  render() {
    return (
      <>
        {this.props.children}
        <SelectGlobalStore />
      </>
    );
  }
}
