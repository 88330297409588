import React from 'react'
import './close-relationship-number-widget.css'
import { FunctionalComponentHeader } from '../../pages/index/components/functional-component-header/functional-component-header'
import { store } from '../../store/store.root'
import { observer } from 'mobx-react'
import { CloseRelationshipNumberEditModal } from './close-relationship-number-edit-modal/close-relationship-number-edit-modal'
import { ValidCloseRelationship } from '../../store/close-relationship-number.store'
import { CloseRelationshipSelectModal } from './close-relationship-select-modal/close-relationship-select-modal'
import classNames from 'classnames'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'
import { Modal } from 'zarm'

@observer
export class CloseRelationshipNumberWidget extends React.Component<
  { display_whole: boolean },
  { modal_display: boolean; set_display: ValidCloseRelationship | null }
> {
  constructor(props: any) {
    super(props)
    this.state = { modal_display: false, set_display: null }
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      store.closeRelationshipNumberStore.list()
    })
  }

  renderIcon = (value: ValidCloseRelationship) => {
    switch (value) {
      case 'father':
        return <use xlinkHref="#iconbaba" />
      case 'mother':
        return <use xlinkHref="#iconmama" />
      case 'father_s_father':
      case 'mother_s_father':
        return <use xlinkHref="#iconyeye" />
      case 'father_s_mother':
      case 'mother_s_mother':
        return <use xlinkHref="#iconnainai" />
    }
  }

  render() {
    return (
      <div className="close-relationship-number">
        <FunctionalComponentHeader title={'亲情号码'} />
        {this.props.display_whole ? (
          store.closeRelationshipNumberStore.loading ? null : store
              .closeRelationshipNumberStore.validValues.length === 0 ? (
            <div
              className={classNames({ iconfont: true, button: true })}
              onClick={this.toggleDisplay}
            >
              &#xe675;
            </div>
          ) : (
            <div className="avatar-list">
              {store.closeRelationshipNumberStore.validValues.map((value) => (
                <svg className="iconfont-svg" key={value.name}>
                  {this.renderIcon(value.name)}
                </svg>
              ))}
              {store.closeRelationshipNumberStore.validValues.length < 6 ? (
                <div
                  className={classNames({
                    iconfont: true,
                    'inline-button': true,
                  })}
                  onClick={() => {
                    if (localStorage.getItem('subscribe') === '0') {
                      const modal = Modal.confirm({
                        title: '确认信息',
                        content: (
                          <div>
                            您还未关注公众号
                            <img
                              src={localStorage.getItem('follow_qr') || ''}
                              alt=""
                              style={{ width: '100%' }}
                            />
                          </div>
                        ),
                        onCancel: () => {
                          console.log('点击cancel')
                        },
                        okText: (
                          <span style={{ color: '#00bc70' }}>已关注</span>
                        ),
                        onOk: () => {
                          localStorage.clear()
                          window.location.replace(
                            window.location.pathname + window.location.search
                          )
                        },
                      })
                    } else {
                      this.toggleDisplay()
                    }
                  }}
                >
                  &#xe675;
                </div>
              ) : null}
            </div>
          )
        ) : (
          <div className="button iconfont" onClick={this.toggleDisplay}>
            &#xe675;
          </div>
        )}
        <CloseRelationshipNumberEditModal
          display={this.state.set_display}
          onCancel={() => this.setState({ set_display: null })}
        />
        <CloseRelationshipSelectModal
          display={this.state.modal_display && !this.state.set_display}
          onCancel={this.toggleDisplay}
          onSelected={this.toggleSet}
        />
      </div>
    )
  }

  private readonly toggleDisplay = () => {
    this.setState({ modal_display: !this.state.modal_display })
  }

  private readonly toggleSet = (value: ValidCloseRelationship) => {
    this.setState({ set_display: value })
  }

  private readonly closeSet = () => {
    this.setState({ modal_display: true, set_display: null })
  }
}
