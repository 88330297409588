import { observer } from "mobx-react";
import "./close-relationship-number.css";
import { CloseRelationshipNumberWidget } from "../../widgets/close-relationship-number-widget/close-relationship-number-widget";
import { BangBangTangLayout } from "../../layout/layout";
import { store } from "../../store/store.root";
import { ValidCloseRelationship } from "../../store/close-relationship-number.store";
import React from "react";

@observer
export class CloseRelationshipNumber extends React.Component<any, any> {
  renderIcon = (value: ValidCloseRelationship) => {
    switch (value) {
      case "father":
        return <use xlinkHref="#iconbaba" />;
      case "mother":
        return <use xlinkHref="#iconmama" />;
      case "father_s_father":
      case "mother_s_father":
        return <use xlinkHref="#iconyeye" />;
      case "father_s_mother":
      case "mother_s_mother":
        return <use xlinkHref="#iconnainai" />;
    }
  };

  render() {
    return (
      <BangBangTangLayout>
        <div className="close-relationship-number-page">
          <div className="tooltip">
            <p>绑定亲情号码,全家共享服务</p>
            <p>绑定的亲情号码在自己手机上关注公众号，可以查看底片等信息</p>
          </div>
          <div className="line">
            <div className="name">
              <svg className="iconfont-svg">{this.renderIcon("father")}</svg>
              <span>爸爸</span>
            </div>
            <span>{store.closeRelationshipNumberStore.father || "未设置"}</span>
          </div>
          <div className="line">
            <div className="name">
              <svg className="iconfont-svg">{this.renderIcon("mother")}</svg>
              <span>妈妈</span>
            </div>
            <span>{store.closeRelationshipNumberStore.mother || "未设置"}</span>
          </div>
          <div className="line">
            <div className="name">
              <svg className="iconfont-svg">
                {this.renderIcon("father_s_father")}
              </svg>
              <span>爷爷</span>
            </div>
            <span>
              {store.closeRelationshipNumberStore.father_s_father || "未设置"}
            </span>
          </div>
          <div className="line">
            <div className="name">
              <svg className="iconfont-svg">
                {this.renderIcon("father_s_mother")}
              </svg>
              <span>奶奶</span>
            </div>
            <span>
              {store.closeRelationshipNumberStore.father_s_mother || "未设置"}
            </span>
          </div>
          <div className="line">
            <div className="name">
              <svg className="iconfont-svg">
                {this.renderIcon("mother_s_father")}
              </svg>
              <span>外公</span>
            </div>
            <span>
              {store.closeRelationshipNumberStore.mother_s_father || "未设置"}
            </span>
          </div>
          <div className="line">
            <div className="name">
              <svg className="iconfont-svg">
                {this.renderIcon("mother_s_mother")}
              </svg>
              <span>外婆</span>
            </div>
            <span>
              {store.closeRelationshipNumberStore.mother_s_mother || "未设置"}
            </span>
          </div>
        </div>
        <CloseRelationshipNumberWidget display_whole={false} />
      </BangBangTangLayout>
    );
  }
}
