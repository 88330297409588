import React, { useState } from "react"
import { withRouter } from "react-router-dom";
import { getParamValue } from "../../utils/url"
import style from "./index.module.scss"

function TestClose(props: any) {

    const [type, setType] = useState(getParamValue("type"));

    // 查看错误考题
    function handleClickWrongItem() {
        if (type === "plan_id") {
            props.history.push(`/build/test-records?plan_id=${getParamValue("plan_id")}&type=plan_id`)
        } else if (type === "subject_id") {
            props.history.push(`/build/test-records?subject_id=${getParamValue("subject_id")}&type=subject_id`)
        }
    }

    // 立即重考
    function handleClickRepetition() {
        if (type === "plan_id") {
            props.history.push(`/build/test-examination?plan_id=${getParamValue("plan_id")}&type=plan_id`)
        } else if (type === "subject_id") {
            props.history.push(`/build/test-examination?subject_id=${getParamValue("subject_id")}&type=subject_id`)
        }
    }

    // 返回考试列表
    function handleClickBackToTheExamList() {
        if (type === "plan_id") {
            props.history.push('/build/test-center')
        } else if (type === "subject_id") {
            props.history.push('/build/employee-center')
        }
    }

    return (
        <div className={style.Test_Close}>
            <div className={style.Test_Close_tot}>得分: {getParamValue("score")}分</div>
            <div className={style.Test_Close_btn}>
                <button onClick={handleClickWrongItem}>查看错误考题</button>
                <button onClick={handleClickRepetition}>立即重考</button>
                <button onClick={handleClickBackToTheExamList}>返回考试列表</button>
                <button onClick={() => props.history.push('/build') }>返回主页</button>
            </div>
        </div>
    )
}

export default withRouter(TestClose);