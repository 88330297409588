import { Map, Marker } from 'react-amap'
import React from 'react'
import { observer } from 'mobx-react'
import { FetchRequest } from '../../utils/fetch'
import { Toast } from 'zarm'
import MobileIcon from '../../assets/service.png'
import { withRouter } from 'react-router-dom'

@observer
class DescriptionInformation extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      data: {
        tipsList: [],
      },
    }
  }

  async componentDidMount() {
    let res = await FetchRequest(
      `/wap/clothing/getTips?id=${this.props.match.params.id}`,
      {},
      'GET'
    )
    if (res.code === 1) {
      this.setState({
        data: res.data || {},
      })
    } else {
      Toast.show(res.msg)
    }
  }

  render() {
    if (Object.keys(this.state.data).length === 0) {
      return <div>数据加载中...</div>
    }
    return (
      <div
        style={{
          padding: 10,
        }}
      >
        <div>
          {this.state.data.tipsList.map(
            (v: any, index: React.Key | null | undefined) => {
              return (
                <div key={index} dangerouslySetInnerHTML={{ __html: v }}></div>
              )
            }
          )}
        </div>
        <div>
          {(this.state.data.store_image || []).map((v: string, i: number) => {
            return <img key={i} src={v} alt="" style={{ width: '100%' }} />
          })}
        </div>
        <div
          style={{
            margin: '10px 0',
            fontSize: 14,
            color: '#000',
            marginTop: 40,
          }}
        >
          {(this.state.data.service_mobile || []).map(
            (v: string, i: number) => {
              return (
                <a
                  key={i}
                  href={`tel:${v}`}
                  style={{
                    color: '#000',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    margin: '10px 0',
                  }}
                >
                  电话： {v}{' '}
                  <span style={{ color: '#0014ff', marginLeft: 10 }}>
                    ( 点击拨打 )
                  </span>
                </a>
              )
            }
          )}

          <div
            onClick={() => {
              window.open(
                `https://uri.amap.com/marker?position=${this.state.data.lng},${this.state.data.lat}&name=${this.state.data.address_target}&src=mypage&coordinate=gaode&callnative=0`
              )
            }}
            style={{
              marginTop: 10,
              fontWeight: 'bold',
            }}
          >
            地址： {this.state.data.address_target}{' '}
            <span style={{ color: '#0014ff', marginLeft: 10 }}>
              ( 点击导航 )
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(DescriptionInformation)
