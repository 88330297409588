import { autorun, computed, makeAutoObservable, runInAction, toJS } from "mobx";
import { BangBangTangRequest } from "../../utils/request";
import { store } from "../../store/store.root";
import { MyPreorderDetailInterface } from "../my-preorder/my-preorder.api";

export class PreorderDetailFromEmployeeStore {
  data: MyPreorderDetailInterface | undefined = undefined;
  sex_type: { id: number; name: string }[] | undefined = undefined;
  age_type: { id: number; name: string }[] | undefined = undefined;
  set_photographer_modal_visible: "none" | "photographer" | "markup_guide" =
    "none";
  set_photographer_loading: boolean = false;
  setData = (value: MyPreorderDetailInterface) => {
    this.data = value;
  };
  update_photographer_data() {
    BangBangTangRequest({
      url: "/wap/schedule/getScheduleStaffList",
      data: {},
      method: "POST",
    }).subscribe((value) =>
      runInAction(() => (this.photographer_data = value))
    );
  }
  photographer_data: {
    photographer: { id: number; nickname: string }[];
    makeup_guide: { id: number; nickname: string }[];
  } | null = null;
  change_set_photographer_loading(value: boolean) {
    this.set_photographer_loading = value;
  }
  photographer_search_text: string = "";
  photographer_value: Set<number> = new Set();
  markup_value: Set<number> = new Set();
  toggle_markup_value(value: number) {
    if (this.markup_value.has(value)) {
      this.markup_value.delete(value);
    } else {
      this.markup_value.add(value);
    }
  }
  toggle_photographer_value(value: number) {
    if (this.photographer_value.has(value)) {
      this.photographer_value.delete(value);
    } else {
      this.photographer_value.add(value);
    }
  }
  set_photographer_search_text(value: string) {
    this.photographer_search_text = value;
  }
  change_set_photographer_modal_visible(
    value: "none" | "photographer" | "markup_guide"
  ) {
    this.set_photographer_modal_visible = value;
  }
  @computed get valid_photographer(): { id: number; nickname: string }[] {
    return (
      this.photographer_data?.photographer.filter((value) => {
        if (!this.photographer_search_text) {
          return true;
        } else {
          return value.nickname.indexOf(this.photographer_search_text) >= 0;
        }
      }) || []
    );
  }
  @computed get valid_markup_guide(): { id: number; nickname: string }[] {
    return (
      this.photographer_data?.makeup_guide.filter((value) => {
        if (!this.photographer_search_text) {
          return true;
        } else {
          return value.nickname.indexOf(this.photographer_search_text) >= 0;
        }
      }) || []
    );
  }
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (this.set_photographer_modal_visible) {
        runInAction(() => {
          this.set_photographer_search_text("");
        });
      }
    });
  }
  getStandardTagType() {
    BangBangTangRequest({
      url: "/wap/clothing/getStandardTagType?token=" + store.userStore.token,
      method: "GET",
      data: {},
    }).subscribe((value) => {
      value.forEach((v: any) => {
        if (v.name === "性别") {
          runInAction(() => (this.sex_type = v.type));
        }
        if (v.name === "年龄") {
          runInAction(() => (this.age_type = v.type));
        }
      });
    });
  }

  clear() {
    this.photographer_search_text = "";
    this.set_photographer_loading = false;
    this.set_photographer_modal_visible = "none";
    this.photographer_value.clear();
    this.markup_value.clear();
  }
  requestDataFromServer = (id: string | number) => {
    BangBangTangRequest({
      url: "/wap/schedule/adminGetUserBookingDetails",
      data: { token: store.userStore.token, booking_id: id },
      method: "POST",
    }).subscribe((value) => {
      runInAction(() => (this.data = value[0]));
    });
  };

  submitScheduleStaff() {
    BangBangTangRequest({
      url: "/wap/schedule/submitScheduleStaff",
      method: "POST",
      data: {
        photographer: Array.from(this.photographer_value).join(","),
        makeup_guide: Array.from(this.markup_value).join(","),
        booking_id: this.data?.id,
      },
    }).subscribe(() => {
      this.requestDataFromServer(this.data!.id);
      this.change_set_photographer_modal_visible("none");
    });
  }
}
export const preorderDetailFromEmployeeStore = new PreorderDetailFromEmployeeStore();
