import React from "react";
import { Button, Cell, DatePicker, Input, Modal, Picker } from "zarm";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";
import { format } from "date-fns";

@observer
export class EmployeeClotheSaveModal extends React.Component<
  any,
  { type_visible: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { type_visible: false };
  }
  render() {
    return (
      <Modal
        visible={store.employeePreorderClotheStore.save_modal_visible}
        onCancel={() =>
          store.employeePreorderClotheStore.toggle_save_modal_visible(false)
        }
        width="90%"
        title="输入用户信息"
      >
        {!store.employeePreorderClotheStore.preorder_id ? (
          <Cell title="用户姓名">
            <Input
              placeholder={"输入用户姓名"}
              value={store.employeePreorderClotheStore.customer_name}
              onChange={(value: string | undefined) =>
                store.employeePreorderClotheStore.set_customer_name(value || "")
              }
            />
          </Cell>
        ) : null}
        {!store.employeePreorderClotheStore.preorder_id ? (
          <Cell title="用户手机">
            <Input
              placeholder={"输入用户手机"}
              onChange={(value: string | undefined) =>
                store.employeePreorderClotheStore.set_mobile(value || "")
              }
              value={store.employeePreorderClotheStore.mobile}
            />
          </Cell>
        ) : null}
        {!store.employeePreorderClotheStore.preorder_id ? (
          <Cell
            title={"拍摄日期"}
            onClick={() => {
              store.employeePreorderClotheStore.setDateVisible(true);
            }}
          >
            {store.employeePreorderClotheStore.date}
          </Cell>
        ) : null}
        <Cell
          title="档期类型"
          onClick={() => this.setState({ type_visible: true })}
        >
          {store.employeePreorderClotheStore.schedule_type?.find(
            (v) =>
              v.id === store.employeePreorderClotheStore.schedule_type_value
          )?.name || "点击选择"}
        </Cell>
        <Cell title={"最大可选"}>
          <Input
            placeholder={"最大的服装可选数量"}
            value={store.employeePreorderClotheStore.limit}
            onChange={(value: string | undefined) =>
              store.employeePreorderClotheStore.set_limit(value || "")
            }
          />
        </Cell>
        <div className="employee-clothe-save-modal-button-row">
          <Button
            block
            onClick={() =>
              store.employeePreorderClotheStore.toggle_save_modal_visible(false)
            }
          >
            返回
          </Button>
          <Button
            block
            theme={"primary"}
            disabled={
              !store.employeePreorderClotheStore.form_data_is_valid ||
              store.employeePreorderClotheStore.submit_loading
            }
            onClick={() => {
              store.employeePreorderClotheStore.submit();
            }}
          >
            提交
          </Button>
        </div>
        <DatePicker
          mode={"date"}
          onOk={(value: any) => {
            store.employeePreorderClotheStore.setDateVisible(false);
            store.employeePreorderClotheStore.setDate(
              format(value, "yyyy-MM-dd")
            );
          }}
          visible={store.employeePreorderClotheStore.date_visible}
          onCancel={() =>
            store.employeePreorderClotheStore.setDateVisible(false)
          }
        />
        <Picker
          destroy
          itemRender={(item) => item?.label as any}
          dataSource={
            store.employeePreorderClotheStore.schedule_type?.map((value) => ({
              value: value.id,
              label: value.name,
            })) || []
          }
          onOk={(value: any) => {
            store.employeePreorderClotheStore.set_schedule_type_value(
              value[0].value
            );
            this.setState({ type_visible: false });
          }}
          visible={this.state.type_visible}
          onCancel={() => this.setState({ type_visible: false })}
        />
      </Modal>
    );
  }
}
