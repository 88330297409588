import React from "react";
import { Button, Cell, Modal, Radio } from "zarm";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";
@observer
export class SelectGlobalStore extends React.Component<
  any,
  { selected: number | null }
> {
  constructor(props: any) {
    super(props);
    this.state = { selected: null };
  }
  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      this.setState({ selected: store.storeInfoStore.value });
      if (store.storeInfoStore.store_choice === null) {
        store.storeInfoStore.getStoreDataFromServer();
        store.storeInfoStore.getMyRanking();
      }
    });
  }

  render() {
    // 判断状态为 null
    if (store.storeInfoStore.store_choice === null) return null;
    return (
      <Modal
        closable={false}
        visible={
          !store.storeInfoStore.value || store.storeInfoStore.display_modal
        }
        width={"100%"}
      >
        <p style={{ textAlign: "center" }}>门店选择</p>
        {store.storeInfoStore.store_choice?.map((value) => (
          <Cell key={value.id}>
            <Radio
              key={value.id}
              onClick={() => this.setState({ selected: value.id })}
              checked={
                this.state.selected !== null && this.state.selected === value.id
              }
            >
              {value.name}
            </Radio>
          </Cell>
        ))}
        <Button
          theme="primary"
          block
          disabled={this.state.selected === null}
          style={{ width: "80%", marginLeft: "10%", marginTop: "2em" }}
          onClick={() => store.storeInfoStore.selectStore(this.state.selected!)}
        >
          切换门店
        </Button>
      </Modal>
    );
  }
}
