import React, { useEffect, useState } from "react";
import { BangBangTangRequest } from "../../../utils/request";
import { store } from "../../../store/store.root";
import { Button, Cell, Input } from "zarm";
import { finalize } from "rxjs/operators";
import { toast } from "react-toastify";
import { appIsFirstReadySubject } from "../../../store/login-complete-subject";

interface Props {
  feedback_id: number;
}
interface State {
  res: {
    feedback_id: number;
    mobile: string;
    name: string;
    schedule_date: string;
    schedule_time: string;
    service_telephone: string;
    store_name: string;
  };
  note_value: string;
  disabled: boolean;
}

export class FilmSelectConfirm extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      disabled: false,
      note_value: "",
      res: {
        feedback_id: 0,
        mobile: "",
        name: "",
        schedule_date: "",
        schedule_time: "",
        service_telephone: "",
        store_name: "",
      },
    };
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      if (this.props.feedback_id) {
        this.setState({ disabled: true });
        BangBangTangRequest(
          {
            url: "/wap/data/getFilmSelectioNotice",
            data: {
              token: store.userStore.token,
              feedback_id: this.props.feedback_id,
            },
            method: "POST",
          },
          { disableErrorHandler: true }
        )
          .pipe(finalize(() => this.setState({ disabled: false })))
          .subscribe(
            (value) => {
              this.setState({ res: value });
            },
            () => setTimeout(() => store.routerStore.replace("/build"), 1)
          );
      }
    });
  }

  render() {
    const res = this.state.res;
    return (
      <div className="content">
        <Cell title="客户姓名" description={res.name} />
        <Cell title="手机号码" description={res.mobile} />
        <Cell title="预约门店" description={res.store_name} />
        <Cell title="预约日期" description={res.schedule_date} />
        <Cell title="预约时间" description={res.schedule_time} />
        <p className="textarea-note">给客服留言</p>
        <Input
          autoHeight
          type="text"
          rows={3}
          placeholder="请在此输入您想交代客服的话，可不填"
          value={this.state.note_value}
          onChange={this.changeTextArea}
          maxLength={190}
          className="textarea"
        />
        <div className="button-row">
          <Button
            theme="primary"
            onClick={this.confirm.bind(this, "confirm")}
            disabled={this.state.disabled}
          >
            确认到店
          </Button>
          <Button
            theme="primary"
            onClick={this.confirm.bind(this, "cancel")}
            disabled={this.state.disabled}
          >
            取消档期
          </Button>
          <Button
            theme="primary"
            onClick={this.confirm.bind(this, "other")}
            disabled={this.state.disabled}
          >
            其他
          </Button>
        </div>
        <a className="phone-row" href={`tel:${this.state.res.mobile}`}>
          <span className="iconfont">&#xe601;</span>拨打客服电话
        </a>
      </div>
    );
  }

  private readonly confirm = (type: "confirm" | "cancel" | "other") => {
    this.setState({ disabled: true });
    let feedback_status: string;
    switch (type) {
      case "cancel":
        feedback_status = "2";
        break;
      case "confirm":
        feedback_status = "1";
        break;
      case "other":
        feedback_status = "3";
        break;
    }
    BangBangTangRequest({
      url: "/wap/data/submitFilmSelectioNotice",
      method: "POST",
      data: {
        token: store.userStore.token,
        feedback_id: this.props.feedback_id,
        feedback_status,
        feedback_word: this.state.note_value,
      },
    })
      .pipe(finalize(() => this.setState({ disabled: false })))
      .subscribe(() => {
        toast("反馈成功,谢谢!");
        store.routerStore.replace("/build");
      });
  };

  private readonly changeTextArea = (value?: string) => {
    this.setState({
      note_value: value || "",
      disabled: !!value && value.length > 190,
    });
  };
}
