import React, { useState, useEffect, useRef } from 'react'
import { FetchRequest } from '../../utils/fetch'
import { withRouter } from 'react-router-dom'
import { Button, ImagePreview, Loading, Modal, Pull, Toast } from 'zarm'
import { getParamValue } from '../../utils/url'
import resso from 'resso'
import style from './index.module.scss'
import { interData, positionPic } from '.'
import { toJS } from 'mobx'

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
}

const styleContent: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-around',
  height: '100vh',
  overflowY: 'scroll',
  // paddingTop: 50,
}

const styleItemSon: React.CSSProperties = {
  width: '50%',
}

const styleItem: React.CSSProperties = {
  width: '100%',
  position: 'relative',
  padding: '0 5px',
}

const styleisSelect: React.CSSProperties = {
  position: 'absolute',
  zIndex: 10,
  inset: 0,
  background: 'rgb(0, 0, 0)',
  opacity: 0.5,
  color: 'rgb(255, 255, 255)',
  fontSize: 20,
  display: 'flex',
  // width: '62%',
  left: '15%',
  right: '15%',
  justifyContent: 'center',
  borderRadius: 15,
  letterSpacing: 7,
  alignItems: 'center',
  top: '14%',
  bottom: '14%',
  margin: '0 auto',
}

const styleClose: React.CSSProperties = {
  position: 'fixed',
  bottom: 70,
  right: 18,
  zIndex: 10,
  width: 50,
  height: 50,
  borderRadius: '50%',
  background: 'hsl(156deg 100% 37%)',
  textAlign: 'center',
  color: '#fff',
  fontSize: 16,
  lineHeight: '50px',
}

export const store: {
  tmpImages: { Key: string }[]
  dataLeft: {
    origin_img: string
    url: string
    originUrl: string
    is_select: number | undefined
    or_w_img: string
  }[]
  dataRight: {
    origin_img: string
    url: string
    originUrl: string
    is_select: number | undefined
    or_w_img: string
  }[]
  paramsAll: string[]
  page: number
  loading: boolean
} = resso({
  tmpImages: [],
  dataLeft: [],
  dataRight: [],
  paramsAll: [],
  page: 1,
  loading: false,
})

let scroll = 0

/// 匹配链接
export const matcht = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i

export default (props: {
  isSelect: (url: string) => void
  select: any
  isClose: () => void
  currentHandleData: interData
  TemplateFillingindex: number
  originImages: string
  direction: 'left' | 'right'
  getMealNum: {
    alreadymeal: number
    photomeal: number
    shouldmeal: number
  }
  select_count: number
}) => {
  ///// 获取用户所选的块图片链接
  const paramsAll: string[] = []
  const baseInit = Object.values(props.select || {})
  const isData = baseInit.filter((v: any) => Object.keys(v.params).length > 0)
  for (let i = 0; i < isData.length; i++) {
    const element: any = isData[i]
    const select_url: any = Object.values(element.params || {}).filter(
      (v) =>
        // new RegExp(matcht).test(String(v))
        typeof v === 'string'
    )
    for (let s = 0; s < select_url.length; s++) {
      const url: string = select_url[s]
      paramsAll.push(url.split('&')[0])
    }
  }
  store.paramsAll = paramsAll

  useEffect(() => {
    document.title = '设计相册'
    if (store.dataLeft.length === 0) {
      GetPageGraduation()
    } else {
      document.getElementsByClassName('za-pull')[0].scrollTop = scroll
    }
    document
      .getElementsByClassName('za-pull')[0]
      .addEventListener('scroll', handleScroll)
  }, [])

  function handleScroll() {
    //滚动条高度
    scroll = document.getElementsByClassName('za-pull')[0].scrollTop //滚动条滚动高度
  }

  async function GetPageGraduation() {
    if (store.loading) return
    Loading.show()
    let result = await FetchRequest('/wap/graduation/getAlbum/', {
      eroll_id: getParamValue('eroll_id'),
      page: store.page,
      limit: 16,
    })
    if (result.code === 1) {
      if (result.data.length === 0) {
        store.loading = true
        Loading.hide()
        return
      }
      store.page = store.page + 1

      let left = [] as {
        origin_img: string
        url: string
        originUrl: string
        or_w_img: string
        is_select: number
      }[]
      let right = [] as {
        origin_img: string
        url: string
        originUrl: string
        is_select: number
        or_w_img: string
      }[]

      for (let i = 0; i < result.data.length; i++) {
        const element = result.data[i]
        if (i % 2 === 0) {
          left.push({
            ...element,
            url: element.origin_img,
            originUrl: element.origin_img,
            or_w_img: element.or_w_img,
            is_select: element.is_select,
          })
        } else {
          right.push({
            ...element,
            url: element.origin_img,
            originUrl: element.origin_img,
            or_w_img: element.or_w_img,
            is_select: element.is_select,
          })
        }
      }

      store.dataLeft = store.dataLeft.concat(
        left
        // .filter((v) => v.is_select !== 1)
        // .concat(left.filter((v) => v.is_select === 1))
      )

      store.dataRight = store.dataRight.concat(
        right
        // .filter((v) => v.is_select !== 1)
        // .concat(right.filter((v) => v.is_select === 1))
      )
      // setLoading(LOAD_STATE.success)
    } else {
      Toast.show(result.msg)
    }
    Loading.hide()
  }

  function handleSelect(or_w_img: string) {
    props.isSelect(or_w_img)
  }

  const [pageSizeLoading, setPageSizeLoading] = useState(false)

  return (
    <div style={{ background: '#eee', paddingBottom: 50 }}>
      <Pull
        id="select_list"
        style={{
          overflowY: 'auto',
          maxHeight: '95vh',
        }}
        load={{
          state: LOAD_STATE.normal,
          distance: 200,
          handler: async () => {
            if (pageSizeLoading) {
              console.log('上个请求未完成')
              return
            }
            setPageSizeLoading(true)
            await GetPageGraduation()
            setPageSizeLoading(false)
          },
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={styleItemSon}>
            {store.dataLeft.map((v, i) => {
              return (
                <div
                  style={styleItem}
                  key={i}
                  onClick={() => {
                    if (v.is_select && v.is_select === 1) {
                      /// 提示已经选过了
                      const modal = Modal.confirm({
                        title: '提示',
                        content: '此照片已被选择，点击确认可重复选择?',
                        onCancel: () => {
                          console.log('点击cancel')
                        },
                        onOk: () => {
                          console.log('点击ok')
                          handleSelect(v.or_w_img)
                          // props.isSelect(v.or_w_img)
                        },
                      })
                    } else {
                      // props.isSelect(v.or_w_img)
                      handleSelect(v.or_w_img)
                    }
                  }}
                >
                  <img
                    src={v.or_w_img}
                    onContextMenu={() => false}
                    alt=""
                    style={{ width: '100%', border: '5px solid #fff' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      fontSize: 13,
                      color: '#d5d5d5',
                      lineHeight: 2,
                      overflow: 'hidden',
                      padding: 10,
                      opacity: parseFloat(
                        localStorage.getItem('watermark_pellucidity') || '0.5'
                      ),
                    }}
                    className={style.watermarkText}
                  >
                    {Array.from(Array(300), (v, k) =>
                      localStorage.getItem('watermark_settings')
                    )}
                  </div>
                  {paramsAll.length > 0 &&
                    paramsAll.indexOf(v.origin_img) > -1 && (
                      <div style={styleisSelect}>
                        {(
                          (props.select[props.currentHandleData.name!][
                            'params'
                          ][
                            `position${props.TemplateFillingindex}_pic`
                          ] as string) || ''
                        ).indexOf(v.origin_img) > -1 ? (
                          <span style={{ color: '#00ff14' }}>当前所选</span>
                        ) : (
                          '已选'
                        )}
                      </div>
                    )}
                  {(props.direction === 'left' || paramsAll.length === 0) &&
                  props.originImages === v.origin_img ? (
                    <div style={styleisSelect}>
                      <span style={{ color: '#00ff14' }}>当前所选</span>
                    </div>
                  ) : (
                    v.is_select &&
                    v.is_select === 1 && <div style={styleisSelect}>已选</div>
                  )}
                </div>
              )
            })}
          </div>
          <div style={styleItemSon}>
            {store.dataRight.map((v, i) => {
              return (
                <div
                  style={styleItem}
                  key={i}
                  onClick={() => {
                    if (v.is_select && v.is_select === 1) {
                      /// 提示已经选过了
                      const modal = Modal.confirm({
                        title: '提示',
                        content: '此照片已被选择，点击确认可重复选择?',
                        onCancel: () => {
                          console.log('点击cancel')
                        },
                        onOk: () => {
                          console.log('点击ok')
                          // props.isSelect(v.or_w_img)
                          handleSelect(v.or_w_img)
                          //// 重新排序
                          /// 找到该条记录纸并且 写入 is_select = 1
                        },
                      })
                    } else {
                      // props.isSelect(v.or_w_img)
                      handleSelect(v.or_w_img)
                    }
                  }}
                >
                  <img
                    src={v.or_w_img}
                    onContextMenu={() => false}
                    alt=""
                    style={{
                      width: '100%',
                      border: '5px solid #fff',
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      fontSize: 13,
                      color: '#d5d5d5',
                      lineHeight: 2,
                      overflow: 'hidden',
                      padding: 10,
                      opacity: parseFloat(
                        localStorage.getItem('watermark_pellucidity') || '0.5'
                      ),
                    }}
                    className={style.watermarkText}
                  >
                    {Array.from(Array(300), (v, k) =>
                      localStorage.getItem('watermark_settings')
                    )}
                  </div>
                  {paramsAll.length > 0 &&
                    paramsAll.indexOf(v.origin_img) > -1 && (
                      <div style={styleisSelect}>
                        {(
                          (props.select[props.currentHandleData.name!][
                            'params'
                          ][
                            `position${props.TemplateFillingindex}_pic`
                          ] as string) || ''
                        ).indexOf(v.origin_img) > -1 ? (
                          <span style={{ color: '#00ff14' }}>当前所选</span>
                        ) : (
                          '已选'
                        )}
                      </div>
                    )}
                  {(props.direction === 'left' || paramsAll.length === 0) &&
                  props.originImages === v.origin_img ? (
                    <div style={styleisSelect}>
                      <span style={{ color: '#00ff14' }}>当前所选</span>
                    </div>
                  ) : (
                    v.is_select &&
                    v.is_select === 1 && <div style={styleisSelect}>已选</div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </Pull>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          color: '#fff',
          fontSize: 12,
          padding: '3px 0',
          textAlign: 'center',
          background: 'hsl(156deg 100% 37%)',
        }}
      >
        应入册
        <span style={{ fontSize: 25 }}>{props.getMealNum.shouldmeal}</span>
        张&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;已选
        <span style={{ fontSize: 25 }}>
          {props.select_count > props.getMealNum.alreadymeal
            ? props.select_count
            : props.getMealNum.alreadymeal}
        </span>
        张&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;底片
        <span style={{ fontSize: 25 }}>{props.getMealNum.photomeal}</span>张
      </div>
      {/* 关闭 */}
      <div
        style={styleClose}
        onClick={() => {
          props.isClose()
        }}
      >
        返回
      </div>
    </div>
  )
}
