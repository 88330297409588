import React from 'react'
import './function-selector.css'
import { Link } from 'react-router-dom'
import { ModuleRequired } from '../../../../components/module-required/module-required'
import { observer } from 'mobx-react'
import {
  ValidWebsiteFillIconString,
  ValidWebsiteModuleString,
} from '../../../../store/website-rule/website-rule.store'
import { store } from '../../../../store/store.root'
import * as _ from 'lodash'
import { Modal } from 'zarm'
import XGImages from '../../../../assets/xg.png'
import { toJS } from 'mobx'
import { Toast } from 'antd-mobile'

@observer
export class FunctionSelector extends React.Component<{}, { count: number }> {
  state = {
    count: 0,
  }

  handleClickAttention(e: any, path: string) {
    if (localStorage.getItem('subscribe') === '0') {
      const modal = Modal.confirm({
        title: '确认信息',
        content: (
          <div>
            您还未关注公众号
            <img
              src={localStorage.getItem('follow_qr') || ''}
              alt=""
              style={{ width: '100%' }}
            />
          </div>
        ),
        onCancel: () => {
          console.log('点击cancel')
        },
        okText: <span style={{ color: '#00bc70' }}>已关注</span>,
        onOk: () => {
          localStorage.clear()
          window.location.replace(
            window.location.pathname + window.location.search
          )
        },
      })
      e.preventDefault()
    } else {
      store.routerStore.push(path)
      // store.routerStore.push('/build/photo-family-collection')
    }
  }

  render() {
    const current_function_length = Array.from(
      store.websiteRuleStore.index_valid_icon
    )
      .map((value) => store.websiteRuleStore.has_module(value))
      .filter((value) => value).length

    const menuAuth = ['genealogy', 'clothing', 'schedule', 'photopage']

    const menuAuthIcon = ['dissatisfied', 'family', 'plate', 'share']

    let cou = 0

    Array.from(store.websiteRuleStore.index_valid_icon).map((value) => {
      if (menuAuth.indexOf(value) > -1) {
        if (store.websiteRuleStore.has_module(value)) {
          cou += 1
        }
      }
    })

    _.range(0, 4 - (current_function_length % 4)).map((value) => {
      if (store.websiteRuleStore.fill_icon) {
        const fill_string = store.websiteRuleStore.fill_icon[value]
        if (menuAuthIcon.indexOf(fill_string) > -1) {
          cou += 1
        }
      }
    })

    const MenuComp = () => {
      return (
        <>
          {Array.from(store.websiteRuleStore.index_valid_icon).map((value) => {
            switch (value) {
              case 'genealogy':
                return (
                  <ModuleRequired name="genealogy" key={value}>
                    <Link
                      className="selector-icon"
                      style={styleIcon}
                      to="/build/photo-family-collection"
                      // onClick={(e) => this.handleClickAttention(e)}
                    >
                      <div className="iconfont">&#xe662;</div>
                      <div className="text">影像家谱</div>
                    </Link>
                  </ModuleRequired>
                )
              case 'clothing':
                return (
                  <ModuleRequired name="clothing" key={value}>
                    {/* selection.mjz365.top */}
                    <a
                      className="selector-icon"
                      style={styleIcon}
                      // to={'/build/customer-query-preorder-clothe-list'}
                      // onClick={(e) => this.handleClickAttention(e)}
                      onClick={() => {
                        const host = store.websiteRuleStore.selection_domain
                        if (host) {
                          location.href = `http://${host}/build/customer-query-preorder-clothe-list?token=${localStorage.getItem(
                            'token'
                          )}`
                        } else {
                          Toast.show('暂未配置跳转域名')
                        }
                      }}
                    >
                      <div className="iconfont">&#xe60c;</div>
                      <div className="text">服装预选</div>
                    </a>
                  </ModuleRequired>
                )
              case 'schedule':
                return (
                  <ModuleRequired name="schedule" key={value}>
                    <Link
                      className="selector-icon"
                      style={styleIcon}
                      to={'/build/preorder-schedule'}
                    >
                      <div className="iconfont">&#xe77d;</div>
                      <div className="text">档期预约</div>
                    </Link>
                  </ModuleRequired>
                )
              case 'photopage':
                return (
                  <ModuleRequired name="photopage">
                    <Link
                      className="selector-icon"
                      style={styleIcon}
                      to={'###'}
                      onClick={() => {
                        window.location.href = `http://page.mjz365.top/page/list?token=${store.userStore.token}&origin=${window.location.origin}`
                      }}
                    >
                      <div>
                        <img src={XGImages} alt="" style={{ width: 44 }} />
                      </div>
                      <div className="text">确认设计稿</div>
                    </Link>
                  </ModuleRequired>
                )
            }
          })}
          {_.range(0, 4 - (current_function_length % 4)).map((value) => {
            if (store.websiteRuleStore.fill_icon) {
              const fill_string = store.websiteRuleStore.fill_icon[value]
              switch (fill_string) {
                case 'dissatisfied':
                  return (
                    <Link
                      key={fill_string}
                      className="selector-icon"
                      to={'/build/info-report'}
                    >
                      <div className="iconfont">&#xe6cb;</div>
                      <div className="text">反馈建议</div>
                    </Link>
                  )
                case 'family':
                  return (
                    <div
                      key={fill_string}
                      className="selector-icon"
                      onClick={(e) =>
                        this.handleClickAttention(
                          e,
                          '/build/close-relationship-number'
                        )
                      }
                    >
                      <div className="iconfont">&#xe60e;</div>
                      <div className="text">亲情号码</div>
                    </div>
                  )
                case 'plate':
                  const d =
                    toJS(store.websiteRuleStore.website_rule_module_all) || []
                  const s = []
                  for (let i = 0; i < d.length; i++) {
                    const item = d[i]
                    if (item.module_name === 'filmdownload') {
                      if (item.module_status === 'open') {
                        s.push('open')
                      } else {
                        s.push('close')
                      }
                    }
                  }
                  if (s.length === 1 && s[0] === 'close') {
                    return null
                  } else {
                    return (
                      <div
                        key={fill_string}
                        className="selector-icon"
                        onClick={(e) =>
                          this.handleClickAttention(e, '/build/film-download')
                        }
                      >
                        <div className="iconfont">&#xe609;</div>
                        <div className="text">底片下载</div>
                      </div>
                    )
                  }
                case 'share':
                  return (
                    <Link
                      key={fill_string}
                      className="selector-icon"
                      to={'/build'}
                    >
                      <div className="iconfont">&#xe764;</div>
                      <div className="text">分享</div>
                    </Link>
                  )
              }
            } else {
              return null
            }
          })}
        </>
      )
    }

    let styleIcon: React.CSSProperties = {
      width: cou === 4 ? '25%' : '20%',
    }

    console.log('菜单数:', cou)

    return (
      <div className="function-selector" id="function-selector">
        {MenuComp()}
      </div>
    )
  }
}
