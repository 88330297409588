import React from "react";
import { BangBangTangLayout } from "../../layout/layout";
import style from "./index.module.scss";
import { Tabs, Badge, Cell, Pull, ActivityIndicator } from "zarm";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";
import { finalize } from "rxjs/operators";
import Default from "../../assets/default-list-image.png";
const { Panel } = Tabs;

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
};

export class RectificationAudit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      RectificationAuditList: [
        {
          title: "未完成",
          count: 0,
        },
        {
          title: "已完成  ",
          count: 0,
        },
        {
          title: "审核完成",
          count: 0,
        },
      ],
      tabValue: 0,
      load_state: LOAD_STATE.normal,
      list: [],
      currentPage: 1,
    };
  }

  static propTypes = {
    history: PropTypes.object,
  };

  titleShow = (title, count) => {
    return (
      <div className={style.comments_tab_title}>
        <span className="tab-title">{title}</span>
        {count !== 0 && <Badge shape="round" text={count} />}
      </div>
    );
  };

  componentDidMount() {
    window.document.title = "审核工作单";
    this.getList();
  }

  getList = (page = 1) => {
    this.setState({
      load_state: LOAD_STATE.loading,
    });
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getReformAdjustList",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        page: page,
        reform_status: that.state.tabValue,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        let tabList = that.state.RectificationAuditList;
        let ListClone = that.state.list;
        // for(let i=0; i<result.total.length; i++){
        //   tabList[i].count = result.total[i]
        // }
        tabList[0].count = result.total[0];
        tabList[1].count = result.total[1];
        if (page == 1) {
          ListClone = result.data;
        } else {
          ListClone = ListClone.concat(result.data);
        }
        that.setState({
          RectificationAuditList: tabList,
          list: ListClone,
          currentPage: page,
          load_state:
            result.data.length < 10 ? LOAD_STATE.complete : LOAD_STATE.normal,
        });
      });
  };

  tabValueChange = (value) => {
    // this.getList(value, 1);
    this.setState(
      {
        tabValue: value,
      },
      () => {
        this.getList();
      }
    );
  };

  render() {
    const {
      RectificationAuditList,
      tabValue,
      load_state,
      list,
      currentPage,
    } = this.state;
    return (
      <BangBangTangLayout title={"审核工作单"}>
        <div className={style.container}>
          <Tabs
            className="tabs"
            value={tabValue}
            onChange={this.tabValueChange}
            lineWidth={105}
          >
            {RectificationAuditList.map((item, index) => {
              return (
                <Panel
                  title={this.titleShow(item.title, item.count)}
                  className="panel"
                  key={index}
                ></Panel>
              );
            })}
          </Tabs>

          <div className="list">
            <Pull
              className="list-container"
              refresh={{ state: 0 }}
              load={{
                state: load_state,
                handler: () => {
                  this.getList(currentPage + 1);
                },
                distance: 200,
                render: (loadState) => {
                  const cls = "custom-control";
                  switch (loadState) {
                    case LOAD_STATE.loading:
                      return (
                        <div className={cls}>
                          <ActivityIndicator type="spinner" />
                        </div>
                      );
                    case LOAD_STATE.failure:
                      return <div className={cls}>加载失败</div>;
                    case LOAD_STATE.complete:
                      return <div className={cls}>没有更多数据了</div>;
                  }
                },
              }}
            >
              {list.map((item, index) => {
                return (
                  <Cell
                    key={index}
                    title={
                      <div className="list_item">
                        <div className="title">
                          <span className="syllable">
                            执行人：{item.admin_name}
                          </span>
                          <span>工单类型：{item.type_name}</span>
                        </div>
                        <div className="description">
                          <span className="syllable">
                            执行积分：{item.integral}
                          </span>
                          <span>金额：{item.money}</span>
                        </div>
                        <div className="description">详情：{item.reason}</div>
                        <div className="description">
                          <span className="syllable">
                            开单时间：{item.createtime}
                          </span>
                          <span>开单人：{item.updater}</span>
                        </div>
                        {item.is_reform == 1?
                        <div className="description">
                          <span className="syllable">
                            逾期时间：{item.overdue}
                          </span>
                          <span>{item.is_overdue}</span>
                        </div>
                        :null}
                      </div>
                    }
                    icon={
                      <img
                        alt=""
                        src={item.image || Default}
                        style={{ width: 48, height: 48 }}
                      />
                    }
                    onClick={() => {
                      this.props.history.push(
                        "./rectification-audit-detail/" +
                          item.id +
                          "/" +
                          tabValue
                      );
                    }}
                  />
                );
              })}
            </Pull>
          </div>
        </div>
      </BangBangTangLayout>
    );
  }
}
export default withRouter(RectificationAudit);
