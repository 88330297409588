import React from "react";
import { BangBangTangLayout } from "../../layout/layout";
import style from "./index.module.scss";
import {
  Tabs,
  Badge,
  Cell,
  Pull,
  ActivityIndicator,
  Radio,
  Modal,
  Button,
} from "zarm";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";
import { finalize } from "rxjs/operators";
const { Panel } = Tabs;

export class ExecutiveForce extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: "2",
      group: "",
      groupList: [],
      group_all: [],
      group_all_select: '0',
      list: [],
      mine: {},
      result: "",
      resultModal: false,
    };
  }

  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {
    window.document.title = "执行力排行";
    this.getGroupList();
  }

  getGroupList = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getGroupList",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        console.log(result);
        that.setState(
          {
            groupList: result.myself || [],
            group: result.myself.length > 0 ? result.myself[0].id : '0',
            group_all: result.group || []
          },
          () => {
            this.getList();
          }
        );
      });
  };

  getList = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getRankingList",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        group_id: that.state.group_all_select || that.state.group,
        time_type: that.state.time,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        console.log(result);
        that.setState({
          list: result.list,
          mine: result.mine,
        });
      });
  };

  changeTime = (value) => {
    this.setState(
      {
        time: value,
      },
      () => {
        this.getList();
      }
    );
  };

  changeGroup = (value) => {
    this.setState(
      {
        group: value,
      },
      () => {
        this.getList();
      }
    );
  };

  selectGroup = () => {
    const modal = Modal.confirm({
      title: '部门选择',
      content: (
        <Radio.Group style={{ width: '100%', overflowY: 'scroll', height: 300 }} defaultValue={this.state.group_all_select} onChange={(e) => {
          this.setState({
            group_all_select: e
          })
        }}>
          {this.state.group_all.map((v, index) => {
            return (
              <Radio key={index} value={v.id} style={{ width: '100%', textAlign: 'left', padding: '10px 0' }}>{v.group_name}</Radio>
            )
          })}
          <Radio value={0} style={{ width: '100%', textAlign: 'left', padding: '10px 0' }}>全员</Radio>
        </Radio.Group>
      ),
      onCancel: () => {
        console.log('点击cancel');
      },
      onOk: () => {
        console.log('点击ok');
        this.getList();
      },
    });
  }

  textShow = () => {
    for (let i = 0; i < this.state.group_all.length; i++) {
      const element = this.state.group_all[i];
      if (element.id === this.state.group_all_select) {
        return  element.group_name
      }
    }
    return "全员"
  }

  like = (id) => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/submitPraise",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        praise_id: id,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        console.log(result);
        const ListClone = this.state.list;
        const mineClone = this.state.mine;
        if (result == "点赞成功") {
          this.getList();
          // if(mineClone.admin_id == id){
          //   mineClone.is_praise = 1;
          //   mineClone.praise_sum++;
          // }
          // for(let k in ListClone){
          //   if(ListClone[k].admin_id == id){
          //     ListClone[k].is_praise = 1;
          //     ListClone[k].praise_sum++;
          //   }
          // }
          // this.setState({
          //   list:ListClone,
          //   mine:mineClone,
          // })
        }
      });
  };

  unlike = (id) => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/submitDispel",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        dispel_id: id,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        console.log(result);
        const ListClone = this.state.list;
        const mineClone = this.state.mine;
        if (result == "取消成功") {
          this.getList();
          // if(mineClone.admin_id == id){
          //   mineClone.is_praise = 0;
          //   mineClone.praise_sum--;
          // }
          // for(let k in ListClone){
          //   if(ListClone[k].admin_id == id){
          //     ListClone[k].is_praise = 0;
          //     ListClone[k].praise_sum--;
          //   }
          // }
          // this.setState({
          //   list:ListClone,
          //   mine:mineClone,
          // })
        }
      });
  };

  render() {
    const {
      time,
      group,
      groupList,
      list,
      mine,
      result,
      resultModal,
    } = this.state;
    return (
      <BangBangTangLayout title={"执行力排行"}>
        <div className={style.container}>
          <div className="ranking-list">
            {mine.nickname ? (
              <div className="ranking-item clearfix">
                <span className="ranking">{mine.deep}</span>
                <img className="avatar" src={mine.avatar} alt="" />
                <span className="name">{mine.nickname}</span>
                <span className="likes">
                  <div className="likes-count">{mine.praise_sum}</div>
                  {mine.is_praise == 0 ? (
                    <div
                      className="no-like"
                      onClick={() => {
                        this.like(mine.admin_id);
                      }}
                    ></div>
                  ) : (
                    <div
                      className="like"
                      onClick={() => {
                        this.unlike(mine.admin_id);
                      }}
                    ></div>
                  )}
                </span>
                <span className="count">{mine.sum}</span>
              </div>
            ) : (
              <div className="ranking-item clearfix">
                <span className="ranking"></span>
                <img className="avatar" src={store.userStore.avatar} alt="" />
                <span className="name">{store.userStore.username}</span>
                <span className="not-on">未上榜</span>
              </div>
            )}
          </div>
          <div className="ranking-list">
            {list.map((item, index) => {
              return (
                <div className="ranking-item clearfix" key={index}>
                  <span className="ranking">{item.deep}</span>
                  <img className="avatar" src={item.avatar} alt="" />
                  <span className="name">{item.nickname}</span>
                  <span className="likes">
                    <div className="likes-count">{item.praise_sum}</div>
                    {item.is_praise == 0 ? (
                      <div
                        className="no-like"
                        onClick={() => {
                          this.like(item.admin_id);
                        }}
                      ></div>
                    ) : (
                      <div
                        className="like"
                        onClick={() => {
                          this.unlike(item.admin_id);
                        }}
                      ></div>
                    )}
                  </span>
                  <span className="count">{item.sum}</span>
                </div>
              );
            })}

            <div
              className="executive-detail"
              onClick={() => {
                this.props.history.push("./executive-force-list");
              }}
            >
              明细
            </div>

            <div className="combinatorial-screening">
              <div className="item-one">
                <Radio.Group
                  compact
                  type="button"
                  defaultValue={time}
                  onChange={this.changeTime}
                >
                  <Radio value="1">今天</Radio>
                  <Radio value="2">本月</Radio>
                  <Radio value="3">本年</Radio>
                </Radio.Group>
              </div>
              <div className="item-two">
                <Radio.Group
                  compact
                  type="button"
                  value={group}
                  onChange={this.changeGroup}
                >
                  <Radio value="0" onClick={() => {
                    this.selectGroup()
                  }}>
                    {this.textShow()}
                  </Radio>
                  {groupList.map((item, index) => {
                    return (
                      <Radio value={item.id} key={index}>
                        {item.group_name}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </div>
            </div>

            <Modal
              className={style.detailModal}
              visible={resultModal}
              footer={
                <Button block className="sure" onClick={this.closeResultModal}>
                  知道了
                </Button>
              }
            >
              <div className="t-c">{result}</div>
            </Modal>
          </div>
        </div>
      </BangBangTangLayout>
    );
  }
}
export default withRouter(ExecutiveForce);
