import React from "react";
import { Button, Modal } from "zarm";

export const MyPreorderCancelModal = (props: {
  visible: boolean;
  onConfirmCancel: () => void;
  onCancel: () => void;
  cancelPending: boolean;
}) => {
  return (
    <Modal
      visible={props.visible || false}
      maskClosable={true}
      title={"警告"}
      closable
      onCancel={props.onCancel}
      width={"90%"}
    >
      <p style={{ lineHeight: "1.6", textAlign: "center" }}>
        取消预约后不可恢复,
        <br />
        是否确认取消？
      </p>
      <div className="modal-buttons">
        <Button onClick={props.onCancel}>返回</Button>
        <Button
          theme="danger"
          onClick={props.onConfirmCancel}
          loading={props.cancelPending}
        >
          确认取消
        </Button>
      </div>
    </Modal>
  );
};
