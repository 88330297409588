/**
 * 对象公开方法：
 * 依赖questionType，该type命名规范如下：`问题还是答案_请求还是提交_所属类别考试中心还是员工培训`
 * getTheInitialListOfQuestions：所有请求数据，
 * submitAnswer：所有提交数据
 */
import { action } from "mobx";
import { BangBangTangRequest } from "../utils/request";
import { store } from "./store.root";
//定义枚举，控制请求分类
const tuple = <T extends string[]>(...args: T) => args;
//考试中心：centre，基础培训：training
const questionTypes = tuple(
  "question_Get_Centre",
  "question_Get_Training",
  "answer_Submit_Centre",
  "answer_Submit_Training"
);
const address = new Map<questionType, string>([
  ["question_Get_Centre", "/wap/exam/getStartExam"],
  ["question_Get_Training", "/wap/exam/getBasicTrainingPaper"],
  ["answer_Submit_Centre", "/wap/exam/getExamScore"],
  ["answer_Submit_Training", "/wap/exam/getBasicTrainingScore"],
]);
//不确定的参数类型
type UncertainParameterType<T> = T | undefined | null;
type questionType = typeof questionTypes[number];
interface questionListItemObjType {
  question_id: number;
  question: string;
  selection: string;
}
interface answerListItemObjType {
  question_id: number;
  answer: string[];
}
interface returnStatusParameterFlagDataType {
  code: number;
  msg: string;
  time: string;
  data: questionListItemObjType[];
}
export class Contrarytest {
  constructor(propsConcurrentlyQuestion_id = 0) {
    //初始化id
    this.concurrentlyQuestion_id = propsConcurrentlyQuestion_id;
  }
  //不依赖id排序，当前做的题目id，唯一，与question_id一一对应
  public concurrentlyQuestion_id;
  //构建请求参数
  protected buildPostParamsDataObj: any = {};
  //构建请求url
  protected buildPostUrl: string = "";
  //列表，题目的集合
  public SetOfProcessedAndUnprocessedIssues: questionListItemObjType[] = [];
  //列表，答案的集合
  public SetOfProcessedAndUnprocessedAnswer: answerListItemObjType[] = [];
  //prevSetOfProcessedAndUnprocessedIssues
  protected prevSetOfProcessedAndUnprocessedIssues: questionListItemObjType[] = [];
  //更改prevSetOfProcessedAndUnprocessedIssues
  public setPrevSetOfProcessedAndUnprocessedIssues(
    initPrev = this.SetOfProcessedAndUnprocessedIssues
  ) {
    this.prevSetOfProcessedAndUnprocessedIssues = initPrev;
    return this;
  }
  //更改 SetOfProcessedAndUnprocessedIssues
  public setSetOfProcessedAndUnprocessedIssues(
    init = this.prevSetOfProcessedAndUnprocessedIssues
  ) {
    this.SetOfProcessedAndUnprocessedIssues = init;
    return this;
  }
  //验证内容是否未作修改，优化目的
  public verifyContent(
    prev = this.prevSetOfProcessedAndUnprocessedIssues,
    next = this.SetOfProcessedAndUnprocessedIssues
  ): boolean {
    return JSON.stringify(prev.sort()) == JSON.stringify(next.sort());
  }
  //兼容类型定义,确保上下文中对应内容存在，必返回字符串
  private compatibleType<T extends string>(
    content: UncertainParameterType<T>
  ): T | string {
    return content || "";
  }
  //构建参数
  private buildParameters(...args: any) {
    this.buildPostParamsDataObj = args[0];
    return this;
  }
  //操作state
  private StateOperation(data: questionListItemObjType[]) {
    this.SetOfProcessedAndUnprocessedIssues = data;
    //更改concurrentlyQuestion_id
    this.setConcurrentlyQuestion_id(
      this.SetOfProcessedAndUnprocessedIssues[0].question_id
    );
    //this.setPrevSetOfProcessedAndUnprocessedIssues(this.SetOfProcessedAndUnprocessedIssues)
  }
  //构建url
  private buildUrl() {
    this.buildPostUrl = this.compatibleType<string>(
      address.get(this.buildPostParamsDataObj.address)
    );
    return this;
  }
  //请求函数
  private pureRequest() {
    let _buildPostParamsDataObj = this.buildPostParamsDataObj;
    delete _buildPostParamsDataObj.address;
    BangBangTangRequest({
      url: this.buildPostUrl,
      method: "POST",
      data: _buildPostParamsDataObj,
    }).subscribe((data: questionListItemObjType[]) => {
      data && this.StateOperation(data);
    });
  }
  /**更改SetOfProcessedAndUnprocessedIssues，
   *做题后，该方法建议在需要对this对象复杂操作时使用
   *会返回包含题目的单个对象，建议事先使用setPrevSetOfProcessedAndUnprocessedIssues保存，使用完后使用复原
   *setSetOfProcessedAndUnprocessedIssues
   */

  public modifyTheCurrentList(
    answer: string,
    currentQuestionId = this.concurrentlyQuestion_id
  ) {
    Object.assign(this.findCorrespondingIdData(currentQuestionId), answer);
  }
  //依据查找单项数据

  public findCorrespondingIdData(currentId = this.concurrentlyQuestion_id) {
    let backArray = this.SetOfProcessedAndUnprocessedIssues.filter((i) => {
      i.question_id == currentId;
    });
    if (!backArray[0]) {
      return {};
    }
    return backArray[0];
  }
  //构建答案列表

  public buildAnswerParams(question_id: number, answer: string[]) {
    this.SetOfProcessedAndUnprocessedAnswer.push({ question_id, answer });
    return this;
  }
  //构建初始anser格式json

  public buildOutAnswerJson() {
    return JSON.stringify(this.SetOfProcessedAndUnprocessedAnswer);
  }
  //处理当前concurrentlyQuestion_id

  public setConcurrentlyQuestion_id(questionFirstId: number): void {
    this.concurrentlyQuestion_id = questionFirstId;
  }
  //通用请求，可扩展
  //
  // public generalRequest(SbOrGet: string,re){

  // }

  //获取题目数据，默认考试中中心试题

  public getTheInitialListOfQuestions(...args: any) {
    let _address = args.address || "question_Get_Centre";
    console.log(
      { address: _address, token: store.userStore.token, ...args[0] },
      "{ address: _address,  token: store.userStore.token,...args[0]}"
    );
    this.buildParameters({
      address: _address,
      token: "f6646a61-c50d-4c76-b1e3-feee06e3c729",
      ...args[0],
    })
      .buildUrl()
      .pureRequest();
  }
  //提交考试答案,默认考试中心

  public submitAnswer(...args: any) {
    let _address = args.address || "answer_Submit_Centre";
    this.buildParameters({
      address: _address,
      token: store.userStore.token,
      ...args[0],
    })
      .buildUrl()
      .pureRequest();
  }
}
