import React from "react";
import "./functional-component-header.css";

interface Props {
  title: string;
  more?: string;
  onMoreClicked?: () => void;
}
export class FunctionalComponentHeader extends React.Component<Props, any> {
  render() {
    return (
      <div className="function-component-header">
        <div className="header-content">
          <div className="cycle small" />
          <div className="cycle big" />
          <div className="text">{this.props.title}</div>
          <div className="cycle big" />
          <div className="cycle small" />
        </div>
        {this.props.more ? (
          <span className="text more" onClick={this.props.onMoreClicked}>
            {this.props.more}
          </span>
        ) : null}
      </div>
    );
  }
}
