import React, { useState, useEffect } from 'react'
import style from './index.module.scss'
import { Cell, Icon, Toast } from 'zarm'
import { AliIcon } from '../../layout/bangbangtang-tab-bar'
import { FetchRequest } from '../../utils/fetch'
import { ModuleRequired } from '../../components/module-required/module-required'
import { store } from '../../store/store.root'
import PageImnages from '../../assets/page.png'

const menuStatusPage = {
  wait_for_confirm: '等待确认',
  wait_for_upload: '等待制作',
  revising: '修改中',
  finalized: '定稿',
  making: '已送厂',
}

export default () => {
  const [data, setdata] = useState<
    Array<{
      lists?: {
        auto_delete_time_text: string
        create_time_text: string
        customer_name: string
        id: number
        is_overdue_upload_text: string
        mobile: string
        notice_status_text: string
        order_sn: string
        page_status:
          | 'wait_for_confirm'
          | 'wait_for_upload'
          | 'revising'
          | 'finalized'
          | 'making'
        page_status_text: string
        update_time_text: string
        upload_time_text: string
        user_id: number
      }
      page_image?: string
      wei_que_ren?: number
      yi_que_ren?: number
      upload_time_text: string
    }>
  >([])

  const [] = useState({})

  useEffect(() => {
    GetIndexPageInfo()
  }, [])

  async function GetIndexPageInfo() {
    let result = await FetchRequest(
      `/wap/Photopage/getIndexData?token=${localStorage.getItem('token')}`,
      {},
      'GET'
    )
    if (result.code === 1) {
      setdata(result.data)
    } else {
      Toast.show(result.msg)
    }
  }

  return (
    <ModuleRequired name={'photopage'}>
      <div className="divider" />
      <div className="page_content" style={{ width: '100%' }}>
        <div className={style.confirm_Page}>
          {data.length !== 0 && (
            <Cell
              title={
                <div
                  style={{ height: 35, lineHeight: '35px', color: '#fc4f4f' }}
                >
                  确认设计稿
                </div>
              }
              icon={
                <Icon
                  type="broadcast"
                  theme="danger"
                  size="sm"
                  style={{ lineHeight: 1.5 }}
                />
              }
              style={{ height: 35 }}
            />
          )}
          {data.map((v, index: number) => {
            return (
              <Cell
                key={index}
                style={{
                  width: '100%',
                }}
                onClick={() => {
                  // store.routerStore.push(`/build/confirmation-page/detaile/${v.lists?.id}`)
                  window.location.href = `http://page.mjz365.top/page?photopage_id=${v.lists?.id}&token=${store.userStore.token}&origin=${window.location.origin}`
                }}
                title={
                  <div className="box" style={{ position: 'relative' }}>
                    <div className={style.confirm_name}>
                      客户姓名: {v.lists?.customer_name}
                    </div>
                    <div className={style.confirm_name}>
                      订单编号: {v.lists?.order_sn}
                    </div>
                    <div className="box-description">
                      {/* <div>状态: {menuStatusPage[v.lists!.page_status]}</div> */}
                      <div>
                        确认{v.yi_que_ren}/未确认{v.wei_que_ren}
                      </div>
                      <div>上传日期:{v.upload_time_text}</div>
                    </div>
                    <div
                      className={`${style.triangle_topright} ${
                        menuStatusPage[v.lists!.page_status] === '等待确认'
                          ? style.WaitingConfirmation_index
                          : ''
                      } ${
                        menuStatusPage[v.lists!.page_status] === '等待制作'
                          ? style.wait_for_revising_index
                          : ''
                      } ${
                        menuStatusPage[v.lists!.page_status] === '修改中'
                          ? style.updateing_index
                          : ''
                      } ${
                        menuStatusPage[v.lists!.page_status] === '定稿'
                          ? style.Confirmed_index
                          : ''
                      } ${
                        menuStatusPage[v.lists!.page_status] === '已送厂'
                          ? style.Confirmed_index
                          : ''
                      }`}
                    >
                      <span className={style.tag_text}>
                        {menuStatusPage[v.lists!.page_status]}
                      </span>
                    </div>
                  </div>
                }
                icon={
                  <img
                    src={PageImnages}
                    alt=""
                    style={{ width: 48, borderRadius: 5 }}
                  />
                }
              />
            )
          })}
        </div>
      </div>
    </ModuleRequired>
  )
}
