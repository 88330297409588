import React, { useEffect, useState } from "react";
import { observer, useLocalObservable } from "mobx-react";
import { Button, DatePicker, Picker, Popup, Stepper } from "zarm";
import classNames from "classnames";
import { preorderDetailFromEmployeeStore } from "./preorder-detail-from-employee.store";
import { computed, makeAutoObservable, runInAction, toJS } from "mobx";
import { BangBangTangRequest } from "../../utils/request";
import { store } from "../../store/store.root";
import { finalize } from "rxjs/operators";
import { Subject } from "rxjs";
import { format, parse, parseISO } from "date-fns";

class LocalStore {
  max: number = 1;
  date: string = format(new Date(), "yyyy-MM-dd");
  dateVisible: boolean = false;
  setDateVisible(v: boolean) {
    this.dateVisible = v;
  }

  setDate(value: string) {
    this.date = value;
  }
  setMax(value: number) {
    this.max = value;
  }

  sex_selected: Set<number> = new Set<number>();
  age_selected: Set<number> = new Set<number>();
  loading: boolean = false;

  @computed get form_is_valid(): boolean {
    return (
      this.max > 0 && this.sex_selected.size > 0 && this.age_selected.size > 0
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  submit = (id: number, selection_id?: number): Promise<null> => {
    this.loading = true;
    return new Promise<null>((resolve) => {
      if (selection_id) {
        BangBangTangRequest({
          url: "/wap/clothing/updateClothingSelection",
          data: {
            token: store.userStore.token,
            booking_id: id,
            schedule_date:
              parse(this.date, "yyyy-MM-dd", new Date()).getTime() / 1000,
            tag_type: Array.from(this.sex_selected)
              .concat(Array.from(this.age_selected))
              .join(","),
            standard_num_limit: this.max,
            selection_id: selection_id,
          },
          method: "POST",
        })
          .pipe(finalize(() => runInAction(() => (this.loading = false))))
          .subscribe(() => {
            preorderDetailFromEmployeeStore.requestDataFromServer(id);
            resolve();
          });
      } else {
        BangBangTangRequest({
          url: "/wap/Clothing/userCreateSelectionByBooking",
          data: {
            token: store.userStore.token,
            booking_id: id,
            schedule_date:
              parse(this.date, "yyyy-MM-dd", new Date()).getTime() / 1000,
            type_ids: Array.from(this.sex_selected)
              .concat(Array.from(this.age_selected))
              .join(","),
            standard_num_limit: this.max,
          },
          method: "POST",
        })
          .pipe(finalize(() => runInAction(() => (this.loading = false))))
          .subscribe(() => {
            preorderDetailFromEmployeeStore.requestDataFromServer(id);
            resolve();
          });
      }
    });
  };
}

export const PreorderDetailFromEmployeeClothePicker = observer(
  (props: {
    onCancel: () => any;
    visible: boolean;
    id: number;
    selected?: number[];
    limit?: number;
    selection_id?: number;
    date?: string;
  }) => {
    const [localStore] = useState(() => new LocalStore());
    useEffect(() => {
      runInAction(() => {
        props.limit ? localStore.setMax(props.limit) : null;
        props.date ? localStore.setDate(props.date) : null;
        preorderDetailFromEmployeeStore.sex_type?.forEach((v) =>
          props.selected?.find((value) => value === v.id)
            ? localStore.sex_selected.add(v.id)
            : null
        );
        preorderDetailFromEmployeeStore.age_type?.forEach((v) =>
          props.selected?.find((value) => value === v.id)
            ? localStore.age_selected.add(v.id)
            : null
        );
      });
    }, [props.limit, props.selected]);
    // noinspection DuplicatedCode
    return (
      <Popup visible={props.visible}>
        <div className={"preorder-detail-from-employee-clothe-picker"}>
          <DatePicker
            mode={"date"}
            visible={localStore.dateVisible}
            onCancel={() => localStore.setDateVisible(false)}
            defaultValue={localStore.date}
            onOk={(value: any) => {
              localStore.setDate(format(value || new Date(), "yyyy-MM-dd"));
              localStore.setDateVisible(false);
            }}
          />
          <div className="row">
            <div className="title">最大预选服装数</div>
            <div className="content">
              <Stepper
                value={localStore.max}
                min={1}
                onChange={(value?: number | string) =>
                  value ? localStore.setMax(parseInt(value as any, 10)) : null
                }
              />
            </div>
          </div>
          <div className="row" onClick={() => localStore.setDateVisible(true)}>
            <div className="title">拍摄日期</div>
            <div className="content">{localStore.date}</div>
          </div>
          <div className="employee-preorder-clothe-popup">
            <div className="tag-container">
              <div className="tag-row">
                <div className="title">性别:</div>
                <div className="button-row">
                  {preorderDetailFromEmployeeStore.sex_type?.map((type) => (
                    <div
                      key={type.id}
                      className={classNames({
                        button: true,
                        active: localStore.sex_selected.has(type.id),
                      })}
                      onClick={() => {
                        const existed = localStore.sex_selected.has(type.id);
                        if (existed) {
                          runInAction(() =>
                            localStore.sex_selected.delete(type.id)
                          );
                        } else {
                          runInAction(() =>
                            localStore.sex_selected.add(type.id)
                          );
                        }
                      }}
                    >
                      {type.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className="tag-row">
                <div className="title">年龄:</div>
                <div className="button-row">
                  {preorderDetailFromEmployeeStore.age_type?.map((type) => (
                    <div
                      key={type.id}
                      className={classNames({
                        button: true,
                        active: localStore.age_selected.has(type.id),
                      })}
                      onClick={() => {
                        const existed = localStore.age_selected.has(type.id);
                        if (existed) {
                          runInAction(() =>
                            localStore.age_selected.delete(type.id)
                          );
                        } else {
                          runInAction(() =>
                            localStore.age_selected.add(type.id)
                          );
                        }
                      }}
                    >
                      {type.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-buttons">
            <Button onClick={props.onCancel}>取消</Button>
            <Button
              theme="primary"
              disabled={!localStore.form_is_valid}
              onClick={() =>
                localStore
                  .submit(props.id, props.selection_id)
                  .then(() => props.onCancel())
              }
            >
              {props.limit ? "编辑" : "生成"}
            </Button>
          </div>
        </div>
      </Popup>
    );
  }
);
