import React from "react";
import { observer } from "mobx-react";
import { store } from "../../../store/store.root";
import { EmployeePreorderCardComponent } from "./employee-preorder-card.component";
import "./employee-preorder-card-list.scss";
import { ActivityIndicator, Pull } from "zarm";

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
};

@observer
export class EmployeePreorderCardListComponent extends React.Component<
  any,
  any
> {
  render() {
    return (
      <Pull
        className="employee-preorder-card-list"
        load={{
          state: store.employeePreorderListStore.currentDataSet?.loading,
          handler: store.employeePreorderListStore.getNextData,
          distance: 100,
          render: (loadState: any) => {
            const cls = "custom-control";
            switch (loadState) {
              case LOAD_STATE.loading:
                return (
                  <div className={cls}>
                    <ActivityIndicator type="spinner" />
                  </div>
                );
              case LOAD_STATE.failure:
                return <div className={cls}>加载失败</div>;
              case LOAD_STATE.complete:
                return <div className={cls}>没有更多数据了</div>;
            }
          },
        }}
      >
        {store.employeePreorderListStore.displayData.map((value) => (
          <EmployeePreorderCardComponent key={value.id} id={value.id} />
        ))}
      </Pull>
    );
  }
}
