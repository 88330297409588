import React from "react";
import "./customer-query-preorder-clothe-list.scss";
import { format } from "date-fns";
import { store } from "../../store/store.root";
import { CustomerPreorderClotheListDataInterface } from "../../store/customer-preorder-clothe-list/customer-preorder-clothe-list.interface";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";
import { Button, Toast } from "zarm";

export class CustomerQueryPreorderClotheCard extends React.Component<
  { data: CustomerPreorderClotheListDataInterface },
  any
> {
  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      document.title = "我的预选";
    });
  }

  render() {
    return (
      <div
        className="customer-query-preorder-clothe-card"
        onClick={() => {
          if (this.props.data.status === "expired") {
            Toast.show("预选单已过期")
          } else {
            //// 清除缓存
            store.customerPreorderClotheStore.clearOtherData()
            store.routerStore.push(
              "/build/customer-preorder-clothe-detail?id=" +
                this.props.data.id + "&store_id=" + this.props.data.store_id
            )
          }
        }}
      >
        <div className="content">
          <div className="label">
            <span>客户姓名：</span>
            <span>{this.props.data.username}</span>
          </div>
          <div className="label">
            <span>电话：</span>
            <span>{this.props.data.mobile}</span>
          </div>
          <div className="label">
            <span>拍摄类型：</span>
            <span>{this.props.data.schedule_type_text}</span>
          </div>
          <div className="label">
            <span>拍摄日期：</span>
            <span>
              {format(
                new Date(this.props.data.schedule_date * 1000),
                "yyyy-MM-dd"
              )}
            </span>
          </div>
          <div className="label">
            <span>门店名称：</span>
            <span>{this.props.data.store_name}</span>
          </div>
        </div>
        {/*<div className="time">*/}
        {/*  <div style={{ fontSize: "13px" }}>*/}
        {/*    {this.props.data.create_time*/}
        {/*      ? format(this.props.data.create_time * 1000, "yyyy.MM.dd")*/}
        {/*      : "尚未选择"}*/}
        {/*  </div>*/}
        {/*  <div>最大预选数:{this.props.data.standard_num_limit}</div>*/}
        {/*</div>*/}
        {this.props.data.clothingimages_list?.length > 0 ? (
          <div className="image-row">
            {this.props.data.clothingimages_list.map((value) => (
              <img src={value} alt="" key={value} />
            ))}
          </div>
        ) : null}
        <Button size={"xs"} style={{ margin: "10px" }}>
          点击预选服装
        </Button>
      </div>
    );
  }
}
