import React, { useState, useEffect } from "react";
import { Button } from "zarm";
import style from "./index.module.scss";
import GuideImages_1 from "../../assets/1.jpg";
import GuideImages_2 from "../../assets/2.jpg";
import GuideImages_3 from "../../assets/3.jpg";
import { FetchRequest } from "../../utils/fetch";
import { Applet } from "../../utils/Applet";

export default () => {
  const [count, setCount] = useState(1);
  const [showGuide, setshowGuide] = useState(false);
  const [imagesList, setimagesList] = useState<string[]>([]);
  useEffect(() => {
    transfermallGoodsgetSettingMehod();
  }, []);

  async function transfermallGoodsgetSettingMehod() {
    let result = await FetchRequest(
      `/wap/marketing/transfermall/Goods/getSetting`,
      {},
      "GET"
    );
    if (result.code === 1) {
      setimagesList(result.data.setting_description);
    }
  }
  return (
    <div style={{ paddingBottom: 100 }} className={style.proudct_item_area}>
      {imagesList.map((v, index) => {
        return (
          <img
            key={index}
            className={style.proudct_item_img}
            style={{ width: "100%" }}
            src={v}
          />
        );
      })}
      {/* 获取按钮 */}
      <Button
        className={style.shar_btn}
        onClick={() => {
          setshowGuide(true);
        }}
      >
        操作指引
      </Button>
      {/* 指引按钮 */}
      {/* <Button className={style.shar_btn_guide} theme="primary">获取分享链接</Button> */}
      <Applet />

      {/* 指引操作 */}
      {showGuide && (
        <div className={style.guide}>
          {count === 1 && (
            <img src={GuideImages_1} onClick={() => setCount(2)} />
          )}
          {count === 2 && (
            <img src={GuideImages_2} onClick={() => setCount(3)} />
          )}
          {count === 3 && (
            <img
              src={GuideImages_3}
              onClick={() => {
                setCount(1);
                setshowGuide(false);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
