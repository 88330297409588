import React from 'react'
import { BangBangTangLayout } from '../../layout/layout'
import style from './detail.module.scss'
import {
  Cell,
  Button,
  Input,
  Modal,
  SearchBar,
  Radio,
  Picker,
  Loading,
  ActivityIndicator,
  Checkbox,
  Toast,
} from 'zarm'
import { store } from '../../store/store.root'
import { BangBangTangRequest } from '../../utils/request'
// import { toast } from "react-toastify";
import { RuleListRequired } from '../../components/module-required/rule-list-required'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { finalize } from 'rxjs/operators'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'
import { FetchRequest } from '../../utils/fetch'
import PunishImages from '../../assets/icon/punish.png'
import RewardImages from '../../assets/icon/reward.png'
import WrenchImages from '../../assets/icon/wrench.png'
import { ModuleRequired } from '../../components/module-required/module-required'
import PhoneImages from '../../assets/icon/phone.png'

export class NegativeCommentsDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      detail: {},
      handler: [],
      handlerSearchList: [],
      searchKey: '',
      handlerSelected: '',
      journal: [],
      journalDetailModal: false,
      journalDetail: '',
      content: '',
      finalback: '',
      improvement: '',
      punish: '',
      contentShow: false,
      finishShow: false,
      finishProgress: 2,
      confirmModal: false,
      asignHandlerModal: false,
      btnActive: 0,
      progressSteps: [
        {
          index: 0,
          title: '等待处理',
          text: 'step-waiting',
        },
        {
          index: 1,
          title: '处理中',
          text: 'step-waiting',
        },
        {
          index: 2,
          title: '处理完成',
          text: 'step-waiting',
        },
        {
          index: 3,
          title: '审核完成',
          text: 'step-waiting',
        },
      ],
      isStatus: false,
      BUTTONS: [],
      feedType: null,
      personLiable: [],
      isPerson: false,
      responser: [],
      searchPersonLiable: [], // 存放搜索
      index: 0,
      customer_type: '', // 客户类别
      isCustomerType: false, // 控制客户类别弹窗
      correction: false, // 评价纠正弹窗
      correctionValue: null, // 评价纠正值
    }
  }

  descriptionShow = (description) => {
    return <div className="description-show">{description}</div>
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      const {
        match: {
          params: { id },
        },
      } = this.props
      // console.log(id)
      // const id = store.routerStore.location.state
      // console.log(store.routerStore.location.state)
      this.setState(
        {
          id,
        },
        () => {
          this.loadData()
        }
      )
      store.globalUiStore.changeCurrentActive('person-center')
      window.document.title = '客诉详情'
      this.getCommentDetail(id)
      this.getFeedbackLog(id)
      // this.getFrontendAuth();
      this.getPersonLiable()
    })
  }

  componentWillUnmount() {
    this.saveData()
  }

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
  }

  saveData = () => {
    const { content, finalback, improvement, punish } = this.state
    if (
      content.length > 0 ||
      finalback.length > 0 ||
      improvement.length > 0 ||
      punish.length > 0
    ) {
      const item = {
        id: this.state.id,
        content: content,
        finalback: finalback,
        improvement: improvement,
        punish: punish,
      }
      let data = JSON.parse(localStorage.getItem('commentDetail')) || []
      data.push(item)
      localStorage.setItem('commentDetail', JSON.stringify(data))
    }
  }

  loadData = () => {
    let data = JSON.parse(localStorage.getItem('commentDetail'))
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (this.state.id == data[i].id) {
          this.setState({
            content: data[i].content,
            finalback: data[i].finalback,
            improvement: data[i].improvement,
            punish: data[i].punish,
          })
          data.splice(i, 1)
          localStorage.setItem('commentDetail', JSON.stringify(data))
        }
      }
    }
  }

  getCommentDetail = (id) => {
    let that = this
    BangBangTangRequest({
      url: '/wap/data/getCustomerComplaintsDetail',
      method: 'POST',
      data: {
        token: localStorage.getItem('token'),
        feedback_id: id,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        let newFeedTypeData = []
        let progress = this.state.progressSteps
        const current = Number(result.progress)
        progress[current].text = 'step-doing'
        for (let k = 0; k < current; k++) {
          progress[k].text = 'step-finished'
        }
        for (let k = current + 1; k < 4; k++) {
          progress[k].text = 'step-waiting'
        }
        for (let i = 0; i < result.feedTypeData.length; i++) {
          const item = result.feedTypeData[i]
          newFeedTypeData.push({
            value: item.id,
            label: item.value,
          })
        }
        that.setState({
          detail: result,
          progressSteps: progress,
          BUTTONS: newFeedTypeData,
        })
      })
  }

  getFeedbackLog = (id) => {
    let that = this
    BangBangTangRequest({
      url: '/wap/data/getFeedbackLog',
      method: 'POST',
      data: {
        token: localStorage.getItem('token'),
        feedback_id: id,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        console.log(result)
        if (result) {
          const journal = result.reverse() || []
          that.setState({
            journal,
          })
        }
      })
  }

  getProgressMan = () => {
    let that = this
    BangBangTangRequest({
      url: '/wap/data/getProgressMan',
      method: 'POST',
      data: {
        token: localStorage.getItem('token'),
        store_id: localStorage.getItem('admin_store_id'),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        console.log(result)
        that.setState({
          handler: result,
          handlerSearchList: result || [],
        })
      })
  }

  showJournalDetail = (detail) => {
    this.setState({
      journalDetail: detail,
      journalDetailModal: true,
    })
  }

  showResult = (result) => {
    this.setState({
      result: result,
      resultModal: true,
    })
  }

  closeResultModal = () => {
    this.setState({
      result: '',
      resultModal: false,
    })
  }

  closeJournalDetailModal = () => {
    this.setState({
      journalDetail: '',
      journalDetailModal: false,
    })
  }

  changeIpt = (value, key) => {
    this.setState({
      [key]: value,
    })
  }

  submitContent = () => {
    Loading.show()
    let that = this
    if (this.state.content.length == 0) {
      that.showResult('请填写处理描述')
      return
    }
    BangBangTangRequest({
      url: '/wap/data/submitProgress',
      method: 'POST',
      data: {
        token: localStorage.getItem('token'),
        feedback_id: that.state.id,
        progress: 1,
        content: that.state.content,
      },
    })
      .pipe(
        finalize(() => {
          store.globalUiStore.hideLoading()
          Loading.hide()
        })
      )
      .subscribe((result) => {
        that.clearContent()
        that.showResult('提交成功')
        that.getCommentDetail(that.state.id)
        that.getFeedbackLog(that.state.id)
      })
  }

  clearContent = () => {
    this.setState({
      content: '',
    })
  }

  /**
   * 提交
   * @returns
   */
  confirmFinish = () => {
    // if (this.state.punish.length == 0) {
    //   this.showResult("请填写审核备注");
    //   return;
    // } else if (!this.state.feedType) {
    //   this.showResult("请选择投诉建议类型");
    //   return;
    // }

    if (
      (this.state.detail.progress === '0') |
      (this.state.detail.progress === '1')
    ) {
      if (this.state.punish.length == 0) {
        this.showResult('请填写审核备注')
        return
      }
    }

    if (
      (this.state.detail.progress === '2') |
      (this.state.detail.progress === '3')
    ) {
      if (this.state.punish.length == 0) {
        this.showResult('请填写审核备注')
        return
      } else if (!this.state.feedType) {
        this.showResult('请选择投诉建议类型')
        return
      }
    }
    this.setState({
      confirmModal: true,
    })
  }

  getPersonLiable = async () => {
    let result = await FetchRequest('/wap/data/getResponser', {
      token: localStorage.getItem('token'),
    })
    if (result.code === 1) {
      this.setState({
        personLiable: result.data,
      })
    }
  }

  closeConfirmModal = () => {
    this.setState({
      confirmModal: false,
    })
  }

  submitFinish = () => {
    Loading.show({
      content: <ActivityIndicator size="lg" />,
      mask: true,
    })
    let that = this
    let data = {
      token: localStorage.getItem('token'),
      feedback_id: that.state.id,
      progress: that.state.finishProgress,
      finalback: that.state.finalback,
      improvement: that.state.improvement,
      punish: that.state.punish,
      feedtype: that.state.feedType,
      responser: that.state.responser.join(','),
    }
    if (!that.state.feedType) {
      delete data['feedtype']
    }
    BangBangTangRequest({
      url: '/wap/data/submitProgress',
      method: 'POST',
      data,
    })
      .pipe(
        finalize(() => {
          store.globalUiStore.hideLoading()
          Loading.hide()
        })
      )
      .subscribe((result) => {
        this.setState({
          finishShow: false,
        })

        // 判断msg ** success //  feed_id admin_id

        try {
          if (result.admin_id) {
            const modal = Modal.confirm({
              title: '确认信息',
              content: '需要给责任人开工作单吗？',
              onCancel: () => {
                location.reload()
              },
              onOk: () => {
                console.log('点击ok')
                store.routerStore.replace(
                  `/qiye/draw-rectification?feed_id=${result.feed_id}&admin_id=${result.admin_id}&reform_type=${result.reform_type}`
                )
              },
            })
          }
        } catch (error) {
          that.clearFinish()
          that.closeConfirmModal()
          that.showResult('提交成功')
          that.getCommentDetail(that.state.id)
          that.getFeedbackLog(that.state.id)
          Loading.hide()
        }
      })
  }

  clearFinish = () => {
    this.setState({
      finalback: '',
      improvement: '',
      punish: '',
    })
  }

  changeToFinish = (value) => {
    this.setState({
      contentShow: false,
      finishShow: true,
      finishProgress: value,
      btnActive: value,
    })
  }

  changeToContent = () => {
    this.setState({
      contentShow: true,
      finishShow: false,
      btnActive: 1,
    })
  }

  handleclickGoToReward = () => {
    store.routerStore.push('/qiye/draw-award')
  }

  handleclickGoToPunish = () => {
    store.routerStore.push('/qiye/draw-rectification')
  }

  assignHandler = () => {
    const s = [null, '无', '未分配']
    if (s.indexOf(this.state.detail.progressMan) === -1) {
      const modal = Modal.confirm({
        title: '提示',
        okText: '分配',
        content: (
          <div>
            当前处理人是:{' '}
            <span style={{ color: 'red' }}>
              {this.state.detail.progressMan}
            </span>{' '}
            <br />
          </div>
        ),
        onCancel: () => {
          console.log('点击cancel')
        },
        onOk: () => {
          console.log('点击ok')
          this.getProgressMan()
          this.setState({
            handlerSearchList: this.state.handler,
            asignHandlerModal: true,
          })
        },
      })
      return
    } else {
      this.getProgressMan()
      this.setState({
        handlerSearchList: this.state.handler,
        asignHandlerModal: true,
      })
    }
  }

  closeAsignHandlerModal = () => {
    this.setState({
      asignHandlerModal: false,
      handlerSelected: '',
    })
  }

  setSearchKey = (value) => {
    this.setState({
      searchKey: value,
    })
  }

  handlerSearch = () => {
    const { handler, searchKey } = this.state
    let handlerSearchList = []
    if (!searchKey) {
      handlerSearchList = handler
    } else {
      for (let i = 0; i < handler.length; i++) {
        if (handler[i].nickname.indexOf(searchKey) != -1) {
          handlerSearchList.push(handler[i])
        }
      }
    }
    this.setState({
      handlerSearchList,
      searchKey: '',
    })
  }

  selectProgressMan = (value) => {
    console.log(value)
    this.setState({
      handlerSelected: value,
    })
  }

  submitProgressMan = () => {
    let that = this
    BangBangTangRequest({
      url: '/wap/data/submitProgressMan',
      method: 'POST',
      data: {
        token: localStorage.getItem('token'),
        feedback_id: that.state.id,
        progress_id: that.state.handlerSelected,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        that.closeAsignHandlerModal()
        that.showResult(result)
        that.getCommentDetail(that.state.id)
      })
  }

  handleLable(id) {
    for (let i = 0; i < this.state.BUTTONS.length; i++) {
      const item = this.state.BUTTONS[i]
      if (item.value === id) {
        return item.label
      }
    }
    return ''
  }

  // 搜索
  handleonChangSearch() {
    if (this.state.searchPersonLiable.length > 0) {
      return this.state.searchPersonLiable
    }
    return this.state.personLiable
  }

  // 渲染
  handleOnchangRender() {
    console.log(this.state.personLiable)
    console.log(this.state.responser)
    let newNickName = []
    for (let i = 0; i < this.state.responser.length; i++) {
      const item = this.state.responser[i]
      for (let j = 0; j < this.state.personLiable.length; j++) {
        const element = this.state.personLiable[j]
        if (element.id === item) {
          console.log(element.nickname)
          newNickName.push(element.nickname)
        }
      }
    }
    return newNickName
  }

  // 评价纠正
  handleClickCorrection = async () => {
    let res = await FetchRequest('/wap/data/rectify', {
      token: localStorage.getItem('token'),
      feedback_id: this.state.id,
      submit_option: this.state.correctionValue,
    })
    if (res.code === 1) {
      Toast.show(res.msg)
      this.setState({ correction: false, correctionValue: null })
      const {
        match: {
          params: { id },
        },
      } = this.props
      this.getCommentDetail(id)
    } else {
      Toast.show(res.msg)
    }
  }

  // 条件渲染判断
  handleIfRender() {
    if (this.state.finishShow) {
      // 判断当前tab 是否为 "1" "2"
      if (
        (this.state.detail.progress === '0') |
        (this.state.detail.progress === '1')
      ) {
        return (
          <div className="description-ipt">
            <Cell title="客户最后反馈">
              <Input
                type="text"
                name="finalback"
                value={this.state.finalback}
                maxLength={800}
                autoHeight
                rows={4}
                showLength
                placeholder="请输入客户最后反馈"
                onChange={(value) => this.changeIpt(value, 'finalback')}
              />
            </Cell>
            <Cell title="改进流程">
              <Input
                type="text"
                name="improvement"
                value={this.state.improvement}
                maxLength={800}
                autoHeight
                rows={4}
                showLength
                placeholder="请输入改进流程"
                onChange={(value) => this.changeIpt(value, 'improvement')}
              />
            </Cell>
            <Cell title="处罚备注">
              <Input
                type="text"
                name="punish"
                value={this.state.punish}
                maxLength={800}
                autoHeight
                rows={4}
                showLength
                placeholder="请输入处罚备注"
                onChange={(value) => this.changeIpt(value, 'punish')}
              />
            </Cell>
            <div className="btns">
              <div className="btn">
                <Button
                  style={{
                    width: '140px',
                    borderColor: '#00bc71',
                    color: '#00bc71',
                  }}
                  size="sm"
                  onClick={this.clearFinish}
                >
                  清空
                </Button>
              </div>
              <div className="btn">
                <Button
                  style={{ width: '140px' }}
                  theme="primary"
                  size="sm"
                  onClick={this.confirmFinish}
                >
                  提交
                </Button>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="description-ipt">
            <Cell
              title="投诉建议类型"
              onClick={() => this.setState({ isStatus: !this.state.isStatus })}
            >
              <span style={{ color: '#939393' }}>
                {this.state.feedType === null
                  ? '请选择投诉建议类型'
                  : this.handleLable(this.state.feedType)}
              </span>
            </Cell>
            <Cell
              title="责任人"
              onClick={() => this.setState({ isPerson: true })}
            >
              <div className={style.tab_content}>
                {this.handleOnchangRender().map((v, index) => {
                  return (
                    <span key={index} className={style.tab_item}>
                      {v}
                    </span>
                  )
                })}
                {this.handleOnchangRender().length === 0 && (
                  <span style={{ color: '#939393' }}>请选择责任人</span>
                )}
              </div>
            </Cell>
            <Cell title="审核备注">
              <Input
                type="text"
                name="punish"
                value={this.state.punish}
                maxLength={800}
                autoHeight
                rows={4}
                showLength
                placeholder="请输入审核备注"
                onChange={(value) => this.changeIpt(value, 'punish')}
              />
            </Cell>
            <div className="btns">
              <div className="btn">
                <Button
                  style={{
                    width: '140px',
                    borderColor: '#00bc71',
                    color: '#00bc71',
                  }}
                  size="sm"
                  onClick={this.clearFinish}
                >
                  清空
                </Button>
              </div>
              <div className="btn">
                <Button
                  style={{ width: '140px' }}
                  theme="primary"
                  size="sm"
                  onClick={this.confirmFinish}
                >
                  提交
                </Button>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  render() {
    const {
      detail,
      handler,
      handlerSearchList,
      journal,
      progressSteps,
      content,
      finalback,
      improvement,
      punish,
      contentShow,
      finishShow,
      resultModal,
      confirmModal,
      asignHandlerModal,
      result,
      journalDetailModal,
      journalDetail,
      btnActive,
    } = this.state
    return (
      <BangBangTangLayout title={'客诉详情'}>
        <div className={style.page_container}>
          <div className="progress-steps step-horizontal">
            {progressSteps.map((step, key) => {
              return (
                <div className={'step-container ' + step.text} key={step.index}>
                  <div className="step-line"></div>
                  <div className="step-dot">
                    <div className="dot"></div>
                  </div>
                  <div className="step-content">{step.title}</div>
                </div>
              )
            })}
          </div>

          <div className="comment-detail">
            <Cell title="来源" description={detail.type} />
            <Cell title="客户类别" description={detail.customer_type ?? '无'} />
            <Cell title="处理人" description={detail.progressMan || '未分配'} />
            <Cell
              title="所属门店"
              description={detail.store_name ? detail.store_name : '无'}
            />
            <Cell title="客户姓名" description={detail.name} />
            <Cell
              style={{ position: 'relative' }}
              title="手机号码"
              description={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a href={`tel:${detail.mobile}`} className={style.phoneCall}>
                    <img src={PhoneImages} alt style={{ width: 30 }} />
                    {detail.mobile}
                  </a>
                </div>
              }
            />
            <Cell title="客户评价" description={detail.submit_option} />
            <Cell title="提交时间" description={detail.submit_time} />
            <Cell
              title="客户描述"
              style={{ minHeight: '104px' }}
              description={this.descriptionShow(detail.feedback_describe)}
            />
          </div>

          {contentShow ? (
            <div className="description-ipt">
              <Cell title="客户类别">{/* <div>点击选择客户类别</div> */}</Cell>
              <Cell title="处理描述">
                <Input
                  type="text"
                  name="content"
                  value={content}
                  maxLength={800}
                  autoHeight
                  rows={4}
                  showLength
                  placeholder="请输入处理描述"
                  onChange={(value) => this.changeIpt(value, 'content')}
                />
              </Cell>
              <div className="btns">
                <div className="btn">
                  <Button
                    style={{
                      width: '140px',
                      borderColor: '#00bc71',
                      color: '#00bc71',
                    }}
                    size="sm"
                    onClick={this.clearContent}
                  >
                    清空
                  </Button>
                </div>
                <div className="btn">
                  <Button
                    style={{ width: '140px' }}
                    theme="primary"
                    size="sm"
                    onClick={this.submitContent}
                  >
                    提交
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          {/* {finishShow ? (this.state.detail.progress === "1" | this.state.detail.progress === "2") ? (
            <div>1111</div>
          ) :  (
            <div className="description-ipt">
              <Cell
                title="投诉建议类型"
                onClick={() =>
                  this.setState({ isStatus: !this.state.isStatus })
                }
              >
                <span style={{ color: "#939393" }}>
                  {this.state.feedType === null
                    ? "请选择投诉建议类型"
                    : this.handleLable(this.state.feedType)}
                </span>
              </Cell>
              <Cell
                title="责任人"
                onClick={() => this.setState({ isPerson: true })}
              >
                <div className={style.tab_content}>
                  {this.handleOnchangRender().map((v, index) => {
                    return (
                      <span key={index} className={style.tab_item}>
                        {v}
                      </span>
                    );
                  })}
                  {this.handleOnchangRender().length === 0 && (
                    <span style={{ color: "#939393" }}>请选择责任人</span>
                  )}
                </div>
              </Cell>



              <Cell title="客户最后反馈">
                <Input
                  type="text"
                  name="finalback"
                  value={finalback}
                  maxLength={800}
                  autoHeight
                  rows={4}
                  showLength
                  placeholder="请输入客户最后反馈"
                  onChange={(value) => this.changeIpt(value, "finalback")}
                />
              </Cell>
              <Cell title="改进流程">
                <Input
                  type="text"
                  name="improvement"
                  value={improvement}
                  maxLength={800}
                  autoHeight
                  rows={4}
                  showLength
                  placeholder="请输入改进流程"
                  onChange={(value) => this.changeIpt(value, "improvement")}
                />
              </Cell>


              <Cell title="审核备注">
                <Input
                  type="text"
                  name="punish"
                  value={punish}
                  maxLength={800}
                  autoHeight
                  rows={4}
                  showLength
                  placeholder="请输入审核备注"
                  onChange={(value) => this.changeIpt(value, "punish")}
                />
              </Cell>
              <div className="btns">
                <div className="btn">
                  <Button
                    style={{
                      width: "140px",
                      borderColor: "#00bc71",
                      color: "#00bc71",
                    }}
                    size="sm"
                    onClick={this.clearFinish}
                  >
                    清空
                  </Button>
                </div>
                <div className="btn">
                  <Button
                    style={{ width: "140px" }}
                    theme="primary"
                    size="sm"
                    onClick={this.confirmFinish}
                  >
                    提交
                  </Button>
                </div>
              </div>
            </div>
          ) : null} */}
          {this.handleIfRender()}

          {journal.length > 0 ? (
            <div className="journal-steps step-vertical">
              {journal.map((step, key) => {
                return (
                  <div
                    className={
                      key == 0
                        ? 'step-container step-doing'
                        : 'step-container step-finished'
                    }
                    key={key}
                  >
                    <div className="step-line"></div>
                    <div className="step-dot">
                      <div className="dot"></div>
                    </div>
                    {step.progress === '1' && (
                      <div className="step-content">
                        <div className="content-section">
                          <div className="k">处理描述：</div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.content)
                            }}
                          >
                            {step.content}
                          </div>
                        </div>
                        <div className="handle-section">
                          <div className="k">{step.createtime}</div>
                          <div className="v">{step.admin_name}</div>
                        </div>
                      </div>
                    )}

                    {step.progress === '2' && (
                      <div className="step-content">
                        <div className="content-section">
                          <div className="k">客户最后反馈：</div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.finalback)
                            }}
                          >
                            {step.finalback}
                          </div>
                        </div>
                        <div className="content-section">
                          <div className="k">改进流程：</div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.improvement)
                            }}
                          >
                            {step.improvement}
                          </div>
                        </div>
                        <div className="content-section">
                          <div className="k">处罚备注：</div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.punish)
                            }}
                          >
                            {step.punish}
                          </div>
                        </div>

                        <div className="handle-section">
                          <div className="k">{step.createtime}</div>
                          <div className="v ">{step.admin_name}</div>
                        </div>
                      </div>
                    )}

                    {step.progress === '3' && (
                      <div className="step-content">
                        <div className="content-section">
                          <div className="k">投诉建议类型: </div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.finalback)
                            }}
                          >
                            {step.feedtype || '无'}
                          </div>
                        </div>
                        <div className="content-section">
                          <div className="k">责任人:</div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.improvement)
                            }}
                          >
                            {step.responser_text || '无'}
                          </div>
                        </div>
                        <div className="content-section">
                          <div className="k">审核备注：</div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.punish)
                            }}
                          >
                            {step.punish || '无'}
                          </div>
                        </div>

                        <div className="handle-section">
                          <div className="k">{step.createtime}</div>
                          <div className="v ">{step.admin_name}</div>
                        </div>
                      </div>
                    )}

                    {/* {step.finalback ? (
                      <div className="step-content">
                        <div className="content-section">
                          <div className="k">投诉建议类型: </div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.finalback);
                            }}
                          >
                            {step.feedtype || "无"}
                          </div>
                        </div>
                        <div className="content-section">
                          <div className="k">责任人:</div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.improvement);
                            }}
                          >
                            {step.responser_text || "无"}
                          </div>
                        </div>
                        <div className="content-section">
                          <div className="k">审核备注：</div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.punish);
                            }}
                          >
                            {step.punish || "无"}
                          </div>
                        </div>

                        <div className="handle-section">
                          <div className="k">{step.createtime}</div>
                          <div className="v ">{step.admin_name}</div>
                        </div>
                      </div>
                    ) : (
                      <div className="step-content">
                        <div className="content-section">
                          <div className="k">处理描述：</div>
                          <div
                            className="v ellipsis ellipsis-3"
                            onClick={() => {
                              this.showJournalDetail(step.content);
                            }}
                          >
                            {step.content}
                          </div>
                        </div>
                        <div className="handle-section">
                          <div className="k">{step.createtime}</div>
                          <div className="v">{step.admin_name}</div>
                        </div>
                      </div>
                    )} */}
                  </div>
                )
              })}
            </div>
          ) : null}

          {/* <Picker
            visible={state.single.isCustomerType}
            value={true}
            dataSource={SINGLE_DATA}
            onOk={(selected) => {
              console.log('Single Picker onOk: ', selected)
            }}
            onCancel={() => this.setState({ isCustomerType: false })}
          /> */}

          <Modal
            title="请选择责任人"
            visible={this.state.isPerson}
            closable
            onCancel={() => this.setState({ isPerson: false })}
            footer={
              <Button
                block
                theme="primary"
                onClick={() =>
                  this.setState({ isPerson: false, searchPersonLiable: [] })
                }
              >
                确认
              </Button>
            }
          >
            <div>
              <SearchBar
                onSubmit={(value) => {
                  console.log(`搜索内容为${value}`)
                }}
                onCancel={() => {
                  console.log('点击了取消')
                  this.setState({
                    searchPersonLiable: [],
                  })
                }}
                onChange={(value) => {
                  let searchValue = []
                  for (let i = 0; i < this.state.personLiable.length; i++) {
                    const item = this.state.personLiable[i]
                    if (item.nickname.indexOf(value) > -1) {
                      searchValue.push(item)
                    }
                  }
                  if (searchValue.length === 0) {
                    Toast.show('暂未搜索到该用户')
                  }
                  this.setState({
                    searchPersonLiable: searchValue,
                  })
                }}
              />
              <Cell>
                <div style={{ minHeight: '20vh', overflowY: 'scroll' }}>
                  <Checkbox.Group
                    value={this.state.responser}
                    onChange={(e) => {
                      this.setState({ responser: e })
                    }}
                  >
                    {this.handleonChangSearch().map((v) => {
                      return (
                        <Checkbox
                          key={v.id}
                          style={{ padding: '5px 0' }}
                          value={v.id}
                        >
                          {v.nickname}
                        </Checkbox>
                      )
                    })}
                  </Checkbox.Group>
                </div>
              </Cell>
            </div>
          </Modal>

          <Picker
            visible={this.state.isStatus}
            dataSource={this.state.BUTTONS}
            onOk={(selected) => {
              this.setState({
                feedType: selected[0].value,
                isStatus: false,
              })
            }}
            onCancel={() => this.setState({ isStatus: false })}
          />

          <Modal
            className={style.detailModal}
            visible={journalDetailModal}
            footer={
              <Button
                block
                className="sure"
                onClick={this.closeJournalDetailModal}
              >
                关闭
              </Button>
            }
          >
            <div className="journal-detail">{journalDetail}</div>
          </Modal>

          <Modal
            className={style.detailModal}
            visible={resultModal}
            footer={
              <Button block className="sure" onClick={this.closeResultModal}>
                知道了
              </Button>
            }
          >
            <div className="t-c">{result}</div>
          </Modal>

          <Modal
            className={style.detailModal}
            visible={confirmModal}
            footer={
              <div className="confirmBtns t-c">
                <div className="btn" onClick={this.closeConfirmModal}>
                  取消
                </div>
                <div className="btn" onClick={this.submitFinish}>
                  确认
                </div>
              </div>
            }
          >
            <div className="t-c">
              {this.state.finishProgress == 2
                ? '确认处理完成？'
                : '确认审核完成？'}
            </div>
          </Modal>

          <Modal
            visible={asignHandlerModal}
            footer={
              <div className={style.progressManBtns}>
                <div className="btn t-c">
                  <Button
                    style={{
                      width: '100px',
                      borderColor: '#00bc71',
                      color: '#00bc71',
                    }}
                    size="sm"
                    onClick={this.closeAsignHandlerModal}
                  >
                    取消
                  </Button>
                </div>
                <div className="btn t-c">
                  <Button
                    style={{ width: '100px' }}
                    theme="primary"
                    size="sm"
                    onClick={this.submitProgressMan}
                  >
                    确认
                  </Button>
                </div>
              </div>
            }
          >
            <div className={style.selectProgressMan}>
              <div className="title">分配处理人</div>
              <SearchBar
                showCancel
                cancelText="搜索"
                onChange={this.setSearchKey}
                onCancel={this.handlerSearch}
              />
              <div className="select-form">
                <Radio.Group type="cell" onChange={this.selectProgressMan}>
                  {handlerSearchList &&
                    handlerSearchList.map((item, index) => {
                      return (
                        <Radio value={item.id} key={index}>
                          {item.nickname}
                        </Radio>
                      )
                    })}
                </Radio.Group>
              </div>
            </div>
          </Modal>

          <div className="option-buttons">
            <RuleListRequired name={'complaints_operation_designate'}>
              <div className="button">
                <Button
                  style={{ width: '110px' }}
                  theme="danger"
                  size="sm"
                  onClick={this.assignHandler}
                >
                  分配处理人
                </Button>
              </div>
            </RuleListRequired>
            {detail.progress == 0 || detail.progress == 1 ? (
              <RuleListRequired name={'complaints_operation_handle'}>
                <div className="button">
                  <Button
                    className={btnActive == 1 ? 'btn active' : 'btn'}
                    size="sm"
                    onClick={this.changeToContent}
                  >
                    填写处理描述
                  </Button>
                </div>
              </RuleListRequired>
            ) : null}
            {detail.progress == 0 || detail.progress == 1 ? (
              <RuleListRequired name={'complaints_operation_handle'}>
                <div className="button">
                  <Button
                    className={btnActive == 2 ? 'btn active' : 'btn'}
                    size="sm"
                    onClick={() => {
                      this.changeToFinish(2)
                    }}
                  >
                    处理完成
                  </Button>
                </div>
              </RuleListRequired>
            ) : null}
            {detail.progress == 2 ? (
              <RuleListRequired name={'complaints_operation_check'}>
                <div className="button">
                  <Button
                    style={{
                      borderColor: '#ff0000',
                      color: '#ff0000',
                      width: '110px',
                    }}
                    size="sm"
                    onClick={() => {
                      this.changeToFinish(3)
                    }}
                  >
                    审核完成
                  </Button>
                </div>
              </RuleListRequired>
            ) : null}
          </div>
        </div>

        {/* <div className={style.right_menu}>
          <ModuleRequired name={'sanction'}>
            <div onClick={this.handleclickGoToReward}>
              <img src={RewardImages} alt="RewardImages" />
            </div>
          </ModuleRequired>

          <ModuleRequired name={'sanction'}>
            <div onClick={this.handleclickGoToPunish}>
              <img src={PunishImages} alt="PunishImages" />
            </div>
          </ModuleRequired>

          <div onClick={() => this.setState({ correction: true })}>
            <img src={WrenchImages} alt style={{ width: '85%' }} />
          </div>
        </div> */}

        <Modal
          title="满意度"
          visible={this.state.correction}
          closable
          onCancel={() => this.setState({ correction: false })}
        >
          <div>
            <div style={{ padding: '10px 0' }}>
              <Radio.Group
                value={this.state.correctionValue}
                onChange={(e) => {
                  this.setState({ correctionValue: e })
                }}
                type="cell"
              >
                <Radio value="F">满意</Radio>
                <Radio value="C">一般</Radio>
                <Radio value="D">不满意</Radio>
              </Radio.Group>
            </div>
            <Button
              block
              theme="primary"
              disabled={this.state.correctionValue ? false : true}
              onClick={this.handleClickCorrection}
            >
              确定
            </Button>
          </div>
        </Modal>
      </BangBangTangLayout>
    )
  }
}
export default withRouter(NegativeCommentsDetail)
