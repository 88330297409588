import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import { Tabs, Cell, Toast } from "zarm";
import { withRouter } from "react-router";
import { FetchRequest } from "../../utils/fetch";
import { format } from "date-fns";
import { store } from "../../store/store.root";

const { Panel } = Tabs;

export default withRouter((props: any) => {
  // 当前所处的tab
  const [value, setValue] = useState<number>(0);
  // 枚举
  const [enumList, setEnum] = useState(["uncommitted", "committed"]);
  // 枚举
  const [enumListString, setEnumString] = useState([
    "未提交",
    "已提交",
    "全部",
  ]);
  // 存放分页
  const [page, setPage] = useState(1);
  // 存放列表数据集
  const [list, setList] = useState([]);
  // 存放 标准tag
  const [tagList, settagList] = useState<any>([]);
  // 底部加载提示
  const [loadingMassage, setloadingMassage] = useState("点击加载更多");

  useEffect(() => {
    document.title = "服装预选";
    StaffPresetList();
    if (tagList.length === 0) {
      getTheStandardTag();
    }
  }, [value]);

  // 请求员预选单
  async function StaffPresetList() {
    setPage(page + 1);
    let resList = await FetchRequest(
      "/wap/clothing/getStaffSelectionList.html",
      value === 2
        ? {
            token: localStorage.getItem("token"),
            page: page.toString(),
          }
        : {
            token: localStorage.getItem("token"),
            page: page.toString(),
            type: enumList[value],
          }
    );
    if (resList.code === 1) {
      setList(list.concat(resList.data));
    } else {
      Toast.show(resList.msg);
    }
    if (resList.data.length === 0) {
      setloadingMassage("暂无更多");
    }
  }

  // 获取标签tag
  async function getTheStandardTag() {
    let tagList = await FetchRequest("/wap/clothing/getStandardTagType", {
      token: localStorage.getItem("token"),
    });
    settagList(tagList.data);
  }

  /**
   *
   * @param str { 服装 字符串 }
   */
  function processingClothingString(str: string) {
    let newArray = [];
    for (let i = 0; i < tagList.length; i++) {
      const element = tagList[i];
      for (let j = 0; j < element.type.length; j++) {
        const item = element.type[j];
        for (let x = 0; x < str.split(",").length; x++) {
          const x_item = str.split(",")[x];
          if (x_item == item.id) {
            newArray.push(item.name);
          }
        }
      }
    }

    return newArray.join(",");
  }

  // 点击加载更多
  function handleClickLoadingAll() {
    setPage(page + 1);
    setTimeout(() => {
      StaffPresetList();
    }, 500);
  }

  return (
    <Tabs
      value={value}
      onChange={(e = 0) => {
        setPage(1);
        setValue(e);
        setList([]);
      }}
    >
      <Panel title="未提交">
        <ListClothing
          data={list}
          processingClothingString={processingClothingString}
        />
        <div className={style.click_all} onClick={handleClickLoadingAll}>
          {loadingMassage}
        </div>
      </Panel>
      <Panel title="已提交">
        <ListClothing
          data={list}
          processingClothingString={processingClothingString}
        />
        <div className={style.click_all} onClick={handleClickLoadingAll}>
          {loadingMassage}
        </div>
      </Panel>
      <Panel title="全部">
        <ListClothing
          data={list}
          processingClothingString={processingClothingString}
        />
        <div className={style.click_all} onClick={handleClickLoadingAll}>
          {loadingMassage}
        </div>
      </Panel>
    </Tabs>
  );
});

const ListClothing = (props: {
  data: Array<{
    id: number;
    username: string;
    create_time: number;
    typeids: string;
    mobile: string;
    standard_num_limit: number;
  }>;
  processingClothingString: (str: string) => string;
}) => {
  const { data, processingClothingString } = props;
  return (
    <>
      {data.map((v) => {
        return (
          <Cell
            key={v.id}
            title={
              <div className="box">
                <div className="box-title">客户姓名: {v.username}</div>
                <div className="box-description">
                  {processingClothingString(v.typeids)}
                </div>
              </div>
            }
            description={format(v.create_time * 1000, "yyyy-MM-dd")}
            icon={
              <img
                alt=""
                src="https://static.zhongan.com/website/health/zarm/images/icons/state.png"
                style={{ width: 48, height: 48 }}
              />
            }
            onClick={() => {
              store.routerStore.push(
                `/build/clothing-primary-list?paramString=${v.typeids}&username=${v.username}&mobile=${v.mobile}&limit=${v.standard_num_limit}&id=${v.id}`
              );
            }}
          />
        );
      })}
    </>
  );
};
