import { action, autorun, makeAutoObservable } from "mobx";
import { BangBangTangRequest } from "../utils/request";

import { AsyncSubject, BehaviorSubject, Subject, combineLatest } from "rxjs";
import { filter, take, tap } from "rxjs/operators";
import { store } from "./store.root";
import { toStream } from "mobx-utils";

export class PersonStore {
  app_id: string = "";
  constructor() {
    autorun(() => {});
  }

  redirectToWechatLogin() {}

  getAppId() {}
  getUserInfo() {
    // console.log("get user info");
    // return BangBangTangRequest({
    //   url: "/wap/wap2/getUserInfo",
    //   method: "POST",
    //   data: { token: store.userStore.token },
    // }).subscribe((value) => {
    //   this.changePhone(value.mobile);
    //   this.changeUserInfo(value.avatar, value.openname);
    //   this.loginCompleted();
    // });
  }

  loginCompleted() {}
  login(code: string) {}

  changePhone(mobile: string) {}

  changeUserInfo(avatar: string, openname: string) {}

  changeToken(value: string) {}
}
