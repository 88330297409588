import React, { useState, useEffect } from 'react'
import style from '../confirmation-page/index.module.scss'
import { Cell, Icon, Toast } from 'zarm'
import { AliIcon } from '../../layout/bangbangtang-tab-bar'
import { FetchRequest } from '../../utils/fetch'
import { ModuleRequired } from '../../components/module-required/module-required'
import { store } from '../../store/store.root'
import DoctorialHatIamgse from '../../assets/DoctorialHat.png'
import './style.css'
import { format } from 'date-fns'

export default () => {
  const [data, setdata] = useState<
    Array<{
      activity_id: number
      end_time: number
      meal: string
      name: string
      phone: string
      price: string
      school: string
      eroll_id: string
    }>
  >([])

  const [] = useState({})

  useEffect(() => {
    if (store.websiteRuleStore.has_module('marketing-graduation')) {
      GetIndexPageInfo()
    }
  }, [])

  async function GetIndexPageInfo() {
    let result = await FetchRequest(`/wap/Graduation/getGErollIndex`, {
      token: localStorage.getItem('token'),
    })
    if (result.code === 1) {
      setdata(result.data)
    } else {
      Toast.show(result.msg)
    }
  }

  if (data.length === 0) return null

  return (
    <ModuleRequired name={'marketing-graduation'}>
      <div className="divider" />
      <div className="page_content" style={{ width: '100%' }}>
        <div className={style.confirm_Page}>
          <Cell
            title={
              <div style={{ height: 35, lineHeight: '35px', color: '#fc4f4f' }}>
                毕业季点击制作相册
              </div>
            }
            icon={
              <Icon
                type="broadcast"
                theme="danger"
                size="sm"
                style={{ lineHeight: 1.5 }}
              />
            }
            style={{ height: 35 }}
          />
          {data.map((v, index) => {
            return (
              <Cell
                key={index}
                style={{
                  width: '100%',
                }}
                onClick={() => {
                  localStorage.setItem('eroll_id_gra', v.eroll_id)
                  store.routerStore.push(
                    `/build/graduation-select/${v.activity_id}`
                  )
                }}
                title={
                  <div
                    className="box"
                    style={{ position: 'relative', padding: '8px 0' }}
                  >
                    <div
                      className={style.confirm_name}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingTop: 2,
                      }}
                    >
                      <div>姓名: {v.name}</div>
                      {/* <div>报名手机号: {v.phone}</div> */}
                    </div>
                    <div
                      className={style.confirm_name}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingTop: 2,
                      }}
                    >
                      <div>学校: {v.school}</div>
                      <div> 套餐: 套餐{v.meal}</div>
                    </div>
                    <div
                      className={style.confirm_name}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingTop: 2,
                      }}
                    >
                      <div>结束时间: {v.end_time}</div>
                    </div>
                  </div>
                }
                icon={
                  <div>
                    <img
                      src={DoctorialHatIamgse}
                      alt=""
                      style={{ width: 48, borderRadius: 5 }}
                    />
                  </div>
                }
              />
            )
          })}
        </div>
      </div>
    </ModuleRequired>
  )
}
