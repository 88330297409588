import Icon from 'antd-mobile/lib/icon';
import React from 'react'
import style from './index.module.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

export default (props: { details: Array<string> }) => {
  const { details } = props;
  return (
    <>
      <div className={style.mask}></div>
      <div className={style.wrap}>
        <Swiper
          className="main-container"
          effect="coverflow"
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          coverflowEffect={{ slideShadows: false }}
        >
          {
            details.map((v, i) => {
              return (
                <SwiperSlide key={v} className="swiper-item">
                  <img src={v} alt="1" style={{
                    width: '80%',
                    height: 'auto'
                  }} />
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>
    </>
  )
}