import React, { useEffect } from "react"
import { Cell } from "zarm";
import { FetchRequest } from "../../utils/fetch";
import style from "./index.module.scss"

export default (props: {
    tab: number
}) => {

    // 解构参数
    const { tab } = props;

    // 数据请求
    // useEffect(() => {
    //     FetchRequest("/wap/data/getCustomerComplaints", {
    //         token: "ae1a826c-dbff-4383-b66d-882ef3a1e981",
    //         page: 1,
    //         progress: 0
    //     })
    // }, []);

    return (
        <div>
            <div className="content">选项卡{tab}内容</div>
            <Cell
                title={
                    <div className={style.comment}>
                        <div className={style.comment_Title}>2222</div>
                        <div className={style.comment_Description}>5555</div>
                    </div>
                }
                icon={
                    <img alt="" src="https://static.zhongan.com/website/health/zarm/images/icons/state.png" style={{ width: 48, height: 48 }} />
                }
                onClick={() => {
                    // window.localStorage.setItem(
                    //     "feedback_id",
                    //     (item as any).feedback_id
                    // );
                    // store.routerStore.push({
                    //     pathname: "/build/comment-detail",
                    // });
                }}
            />
        </div>
    )
}