import React, { useEffect, useState } from "react";
import { BangBangTangLayout } from "../../layout/layout";
import "./my-preorder.scss";
import { MyPreorderDetailInterface } from "./my-preorder.api";
import { ActivityIndicator, Modal, Panel, Pull, Tabs, Toast } from "zarm";
import { MyPreorderRequest } from "./my-preorder.request";
import { MyPreorderCard } from "./components/my-preorder-card";
import { MyPreorderHeaderComponent } from "./components/my-preorder-header.component";
import { store } from "../../store/store.root";
import { observer } from "mobx-react";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";
// noinspection NonAsciiCharacters

declare enum LOAD_STATE {
  normal = 0,
  abort = 1,
  loading = 2,
  success = 3,
  failure = 4,
  complete = 5
}

export enum MyPreorderPanelEnum {
  当前申请 = 0,
  预约成功 = 1,
  未拍完 = 2,
  全部已拍完 = 3,
  全部 = 4,
}

@observer
export class MyPreorder extends React.Component<any> {
  componentDidMount() {
    document.title = "我的预约";
    appIsFirstReadySubject.subscribe(() => {
      if (store.myPreorderStore.data === null) {
        store.myPreorderStore.set_selected_panel(MyPreorderPanelEnum.当前申请);
      }
    });
  }

  render() {
    return (
      <BangBangTangLayout title={"我的预约"}>
        <div className="my-preorder">
          {/*<MyPreorderHeaderComponent*/}
          {/*  onChange={(index) => {*/}
          {/*    store.myPreorderStore.set_selected_panel(index);*/}
          {/*  }}*/}
          {/*  activeTab={store.myPreorderStore.selectedPanel}*/}
          {/*/>*/}
          <Pull
            className="my-preorder-container"
            refresh={{ state: 0 }}
            load={{
              state: store.myPreorderStore.loading,
              handler: store.myPreorderStore.loadData,
              distance: 200,
              render: (loadState: any) => {
                const cls = "custom-control";
                switch (loadState) {
                  case LOAD_STATE.loading:
                    return (
                      <div className={cls}>
                        <ActivityIndicator type="spinner" />
                      </div>
                    );
                  case LOAD_STATE.failure:
                    return <div className={cls}>加载失败</div>;
                  case LOAD_STATE.complete:
                    return <div className={cls}>没有更多数据了</div>;
                }
              },
            }}
          >
            {store.myPreorderStore.data?.map((value) => (
              <MyPreorderCard
                data={value}
                key={value.id + new Date().getTime()}
              />
            ))}
          </Pull>
        </div>
      </BangBangTangLayout>
    );
  }
}
