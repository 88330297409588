import React, { ReactComponentElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper } from 'react-vant';

import { ImagePreview, Carousel, NoticeBar, Popup, Pull, Toast, Button, Loading,  } from 'zarm';
import AddImages from "../../assets/add.png";
import CloseImages from "../../assets/close.png";
import PackageImages from "../../assets/package.png";
import ServiceImages from "../../assets/service.png";
import ColseImages from "../../assets/x.png";
import XlImages from "../../assets/xl.png";
import YesImages from "../../assets/yes.png";
import { store } from "../../store/store.root";
import { FetchRequest } from "../../utils/fetch";
import { getParamValue } from "../../utils/url";
import style from "./index.module.scss";
import "./style.css";
import { observer } from "mobx-react";

const LOAD_STATE = {
    normal: 0, // 普通
    abort: 1, // 中止
    loading: 2, // 加载中
    success: 3, // 加载成功
    failure: 4, // 加载失败
    complete: 5, // 加载完成（无新数据）
};

type resType = {
    activity_name?: null;
    consultation_telephone?: null;
    eroll_id?: number;
    link?: string;
    images?: {
        id: number;
        is_order: 0 | 1;
        is_selection: number;
        o_object: string;
        or_w_img: string;
        original: number;
        p_object: string;
        package: number;
        w_object: string;
        wrap: number;
    }[];
    origimages?: {
        id: number;
        is_selection: number;
        o_object: string;
        or_w_img: string;
        original: number;
        p_object: string;
        is_order: 0 | 1;
        package: number;
        w_object: string;
        wrap: number;
    }[];
    is_select?: number;
    meal?: Array<{ alreadynum: number; cutprice: number; free: string; name: string; price: number; type: "original" | "package" | "wrap"; detail: { all: Array<string>; object: Array<{ alreadynum: number; name: string; num: number }>;  desc: string[] }; wrap_image?: string[]; }> // Array<{ type: "original" | "package" | "wrap"; name: string; detail: Array<string> }>;
    msg?: string;
    order_number?: null | number;
    rotation?: Array<{ link: string; img: string }>;
    select_time?: string;
    views?: Array<{ id: number; openname: string; detail: string; avatar: string }>;
    sum_images?: number;
    meal_type?: "original" | "package" | "wrap"
}

const Context = React.createContext({}); // 初始化

export default observer(withRouter((props: {
    match: {
        params: {
            eroll_id: string
        }
    }
}) => {
    const eroll_id = props.match.params.eroll_id;

    useEffect(() => {
        document.title = "选片活动"
        
    }, [])

        useEffect(() => {
            if (store.userStore.token) {
                console.log("初始化请求数据")
                const packageName: any = localStorage.getItem("packageName") || "original"
                if (packageName) {
                    getActivitiesList(false, packageName)
                } else {
                    getActivitiesList()
                }
            }
        }, [store.userStore.token])

    const [CarouselList, setCarouselList] = useState<Array<{ link: string; img: string }>>([]) // 轮播图

    const [data, setData] = useState<resType>({
        images: [],
        origimages: []
    }) // 页面数据

    const [IsListNull, setIsListNull] = useState(false) // 是否无数据

    const [IsPackageShow_3, setIsPackageShow_3] = useState(false)

    const [componentDate, setcomponentDate] = useState<{ IsListNull: boolean ;alreadynum?: number; cutprice?: number; free?: string; name?: string; price?: number; type: "original" | "package" | "wrap"; detail?: { all: Array<string>; object: Array<{ alreadynum: number; name: string; num: number }> } }>({
        type: "original",
        detail: {
            all: [],
            object: []
        },
        IsListNull: IsListNull
    })

    useEffect(() => {
        if (IsListNull) {
            setcomponentDate({
                ...componentDate,
                IsListNull: true
            })
        }
    }, [IsListNull])

    // 数据分页
    const [page, setPage] = useState(1)

    async function setComponentDate(data: any) {
        setcomponentDate({
            ...componentDate,
            ...data
        })
        console.log("1准备请求数据套餐参数为:", data.type)
        await getActivitiesList(false, data.type)
    }

    async function isRefresh(t: number) {
        setIsListNull(false)
        console.log("2准备请求数据套餐参数为:", componentDate.type)
        // await getActivitiesList(true, componentDate.type)
        let res = await FetchRequest("/wap/selectioneroll/selectionFilmIndex", {
            token: store.userStore.token,
            page: 1,
            limit: data.images!.length,
            eroll_id,
            meal_type: componentDate.type
        })
        if (res.code === 1) {
            setData(res.data)
            // 套餐选择
            // if (res.data.meal) {
                for (let i = 0; i < res.data.meal.length; i++) {
                    const item = res.data.meal[i];
                    if (item.type === res.data.meal_type) {
                        setcomponentDate({
                            ...componentDate,
                            ...item
                        })
                        break
                    }
                }
            // }
            // setData({
            //     ...data,
            //     images: res.data.images,
            //     origimages: res.data.origimages,
            //     meal: res.data.meal
            // })
        }
    }

    async function isRefreshPack(type: "original" | "package" | "wrap") {
        // setData({
        //     origimages: [],
        //     images: []
        // })
        // setIsListNull(false)
        // setPage(1)
        // document.getElementById("content")!.scrollTop = 0
        // console.log("3准备请求数据套餐参数为:", type)
        // await getActivitiesList(true, type)
        localStorage.setItem("packageName", type)
        Toast.show("正在为您切换套餐中...")
        setTimeout(() => {
            location.reload()
        }, 500);
    }


        // false正常请求 true 代表刷新不进行图片控制判断
        async function getActivitiesList(type: boolean = false, packageName: "original" | "package" | "wrap" = "original") {
            // 相同说明更换了套餐则不走分页校验
            if (packageName === componentDate.type) {
                if (!type) {
                    if (IsListNull) return
                }
            }
            Loading.show();
            let res = await FetchRequest("/wap/selectioneroll/selectionFilmIndex", {
                token: store.userStore.token,
                page: type ? 1 : page,
                limit: 50,
                eroll_id,
                meal_type: packageName
            })
            Loading.hide()
            if (res.code === 1) {
                setPage(page + 1)
                
                localStorage.setItem("packageName", packageName)
                if (res.data.is_select !== 0) {
                    if (res.data.images.length === 0) {
                        setIsListNull(true)
                        return
                    }
                }
                
                if (!type) {
                    if (res.data.is_select !== 0) {
                        if (res.data.images.length === 0) {
                            setIsListNull(true)
                            setPage(1)
                            return
                        } else {
                            setIsListNull(false)   
                        }
                    } else {
                        setData(res.data)
                        return
                    }
                }
                
                if (res.data.images.length > 0) {
                    if (page === 1) {
                        setData(res.data)
                    } else {
                        // 分页加
                        let newArratData = { ...data }
                        newArratData.images = newArratData.images?.concat(res.data.images)
                        // origimages 分页
                        newArratData.origimages = newArratData.origimages?.concat(res.data.origimages)
                        setData(newArratData)
                    }
                }
                
                setCarouselList(res.data.rotation)
                selectTime(res.data.select_time)
                // 套餐选择
                if (res.data.meal) {
                    for (let i = 0; i < res.data.meal.length; i++) {
                        const item = res.data.meal[i];
                        if (item.type === res.data.meal_type) {
                            setcomponentDate({
                                ...componentDate,
                                ...item
                            })
                            break
                        }
                    }
                    if ((res.data.images as Array<{ id: number; is_selection: number; or_w_img: string }>).length === 0) {
                        setIsListNull(true)
                    } else {
                        setIsListNull(false)
                    }
                }
            } else {
                Toast.show(res.msg)
            }
        }

    // false正常请求 true 代表刷新不进行图片控制判断
    // async function getActivitiesList(type: boolean = false, packageName: "original" | "package" | "wrap" = "original") {
    //     // if (IsListNull) return
    //     // 相同说明更换了套餐则不走分页校验
    //     if (packageName === componentDate.type) {
    //         if (!type) {
    //             if (IsListNull) return
    //         }
    //     }
        
    //     let res = await FetchRequest("/wap/selectioneroll/selectionFilmIndex", {
    //         token: store.userStore.token,
    //         page: type ? 1 : page,
    //         limit: 20,
    //         eroll_id,
    //         meal_type: packageName
    //     })
    //     if (res.code === 1) {
    //         localStorage.setItem("packageName", packageName)
    //         if (!type) {
    //             if (res.data.is_select !== 0) {
    //                 if (res.data.images.length === 0) {
    //                     setIsListNull(true)
    //                     setPage(1)
    //                     return
    //                 } else {
    //                     setIsListNull(false)   
    //                 }
    //             } else {
    //                 setData(res.data)
    //                 return
    //             }
    //         }
    //         if (page > 1) { // !type && Object.keys(data).length > 0
    //             // 分页加
    //             console.log("分页加")
    //             let newArratData = { ...data }
    //             newArratData.images = newArratData.images?.concat(res.data.images)
    //             // origimages 分页
    //             newArratData.origimages = newArratData.origimages?.concat(res.data.origimages)
    //             setData(newArratData)
    //         } else {
    //             // 第一页
    //             console.log("第一页")
    //             setData(res.data)
    //         }
    //         setPage(page + 1)
    //         // 处理轮播图数据
    //         // let CarouseNew: string[] = []
    //         // for (let i = 0; i < res.data.rotation.length; i++) {
    //         //     const item = res.data.rotation[i];
    //         //     CarouseNew.push(item.img)
    //         // }
    //         setCarouselList(res.data.rotation)
    //         selectTime(res.data.select_time)
    //         // 套餐选择
    //         if (res.data.meal) {
    //             for (let i = 0; i < res.data.meal.length; i++) {
    //                 const item = res.data.meal[i];
    //                 if (item.type === res.data.meal_type) {
    //                     setcomponentDate(item)
    //                     break
    //                 }
    //             }
    //             if ((res.data.images as Array<{ id: number; is_selection: number; or_w_img: string }>).length === 0) {
    //                 setIsListNull(true)
    //             } else {
    //                 setIsListNull(false)
    //             }
    //         }
    //     } else {
    //         Toast.show(res.msg)
    //     }
    // }

    return (
        <div id="content" style={{ overflowY: 'auto', maxHeight: '100vh', paddingTop: 40 }}>
            <Context.Provider
                value={componentDate}
            >
                <>
                    {data.views && (
                        <NoticeBar className={style.NoticeBar}>
                            {data.views?.map((v) => {
                                return (
                                    <span key={v.id}>
                                        <img src={v.avatar} alt="" style={{ width: 25, borderRadius: '50%', margin: '0 10px' }} />
                                        <span style={{ fontSize: 17 }}>{v.detail}</span>
                                    </span>
                                )
                            })}
                        </NoticeBar>
                    )}
                        
                    
                    <CarouselCompnent list={CarouselList} />
                    <ISSelect data={data}>
                        <>  
                            <WaterfallFlow list={data.images!} origimages={data.origimages!} onChang={(e, s) => getActivitiesList(e, (s as "original" | "package" | "wrap"))} IsListNull={IsListNull} isRefresh={isRefresh} eroll_id={data.eroll_id!} />
                            <CustomerService consultation_telephone={data.consultation_telephone!} />
                            <PackageBtn meal={data.meal! || []} setComponentDate={setComponentDate} isRefresh={isRefreshPack} IsPackageShow_3={IsPackageShow_3} />
                            <Settlement list={data.images!} sum_images={data.sum_images!} eroll_id={data.eroll_id!} priceOnchang={(type: boolean) => setIsPackageShow_3(type)} />
                        </>
                    </ISSelect>
                </>
            </Context.Provider>
        </div>
    )
}))

//// 轮播图
function CarouselCompnent(props: {
    list: { img: string; link: string }[];
}) {
    const [index, setIndex] = useState(0)
    return (
        <Carousel
            autoPlay
            loop
            onChange={(e) => {
                setIndex(Number(e))
            }}
        >
            {props.list.map((item, index_) => {
                return (
                    <div className="carousel__item__pic" key={index_} onClick={() => {
                        if (props.list[index].link) {
                            window.location.href = props.list[index].link
                        }
                    }}>
                        <img src={item.img} alt="" draggable={false} style={{ height: "auto" }} />
                    </div>
                )
            })}
        </Carousel>
    )
}

//// 瀑布流
function WaterfallFlow({ list, origimages, onChang, isRefresh, IsListNull, eroll_id }: {
    list: {
        id: number;
        is_order: 0 | 1;
        is_selection: number;
        o_object: string;
        or_w_img: string;
        original: number;
        p_object: string;
        package: number;
        w_object: string;
        wrap: number;
    }[];
    origimages: {
        id: number;
        is_order: 0 | 1;
        is_selection: number;
        o_object: string;
        or_w_img: string;
        original: number;
        p_object: string;
        package: number;
        w_object: string;
        wrap: number;
    }[];
    onChang: (e: boolean, s: string) => void; 
    isRefresh: (e: number) => void; 
    IsListNull: boolean;
    eroll_id: number;
}) {
    const pullRef = useRef();

    const data = useContext<any>(Context);

    const data_new = useCallback(() => {
        return data
      }, [data]);

    const [loading, setLoading] = useState(LOAD_STATE.normal);

    const newList = useMemo(() => {
        let left = []
        let right = []
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (i % 2 === 0) {
                left.push(item)
            } else {
                right.push(item)
            }
        }
        return [left, right]
    }, [list])

    useEffect(() => {
        if (data.IsListNull) {
            setLoading(LOAD_STATE.complete);
        } 
    }, [data.IsListNull])

    // 加载数据
    function loadData() {
        if (data.IsListNull) {
            setLoading(LOAD_STATE.complete);
        } else {
            setLoading(LOAD_STATE.loading);
            onChang(false, data.type)
            setLoading(LOAD_STATE.success);
        }
    }

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    useEffect(() => {
        settopSelectType("")
    }, [data.type])

    useEffect(() => {
        // console.log("套餐发生变化")
        if (data.detail.object.length > 0) {
            if (topSelectType.length == 0) {
                settopSelectType(data.detail.object[0].name)
            }
        }
    }, [data.detail.object])

    useEffect(() => {
        if (origimages.length > 0) {
            for (let i = 0; i < origimages.length; i++) {
                const item = origimages[i];
                if (item.id === ImagesItem.id) {
                    setImagesItem(item)
                    console.log("找到上次所选！")
                    // setTnitialSlideCount(i)
                }
            }
        }
    }, [origimages])

    useEffect(() => {
        for (let i = 0; i < list.length; i++) {
                const item = list[i];
                if (item.id === ImagesItem.id) {
                    setImagesItem(item)
                    console.log("找到上次所选！")
                }
            }
    }, [list])

    const [popupState, setpopupState] = useState(false) // 图片预览开关

    const [initialSlideCount, setTnitialSlideCount] = useState(0)

    const [ImagesItem, setImagesItem] = useState<{
        id?: number;
        is_selection?: number;
        o_object?: string;
        or_w_img?: string;
        original?: number;
        p_object?: string;
        package?: number;
        w_object?: string;
        wrap?: number;
    }>({})

    const { SetSettlement } = useData();

    const [fillData, setFaillDate] = useState<any>({})

    useEffect(() => {
        if (Object.keys(fillData).length > 0) {
            const filterArray = origimages.filter(v => {
                return v.id === fillData.id
            })
            if (filterArray.length > 0) {
                handleClickLookImages(filterArray[0])
                setFaillDate({})
            } else {
                setTimeout(() => {
                    Toast.show("请稍后，正在查找数据")
                    loadData()
                }, 1000);
            }
        }
    }, [origimages])

    function handleClickLookImages(item: { id: number; is_selection: number; or_w_img: string }) {
        // handleOnChangImages(item.id)
        setImagesItem(item)
        SetSettlement(false)
        // 筛选
        const filterArray = origimages.filter(v => {
            return v.id === item.id
        })
        if (filterArray.length === 0) {
            setFaillDate(item)
            loadData()
            Toast.show("正在加载该图片资源...")
        } else {
            setpopupState(true)
            for (let i = 0; i < origimages.length; i++) {
                const itemSon = origimages[i];
                if (itemSon.id === item.id) {
                    // console.log("索引为:", i)
                    setTnitialSlideCount(i)
                }
            }
        }
    }

    const [topSelectType, settopSelectType] = useState("")

    // 选择照片
    async function handleClickSelectPhotos(name: string) {
        Loading.show();
        let res = await FetchRequest("/wap/selectioneroll/filmSelect", {
            token: store.userStore.token,
            image_id: ImagesItem.id,
            meal_type: data.type,
            object: name, // topSelectType,
            eroll_id,
        })
        if (res.code === 1) {
            isRefresh(1)
            Toast.show(`选择 ${name} 成功`)
        } else {
            Toast.show(res.msg)
        }
        Loading.hide();
    }

    // 取消照片
    async function handleClickCancelPhotos(name: string) {
        Loading.show();
        let res = await FetchRequest("/wap/selectioneroll/filmQuit", {
            token: store.userStore.token,
            eroll_id,
            image_id: ImagesItem.id,
            meal_type: data.type,
            object: name // topSelectType
        })
        if (res.code === 1) {
            isRefresh(0)
            Toast.show(`取消 ${name} 成功`)
        } else {
            Toast.show(res.msg)
        }
        Loading.hide();
    }

    // 文字动态更改
    function handleChangText(type: string, name: string) {
        switch (type) {
            case "original":
                if (String(ImagesItem.o_object).split(",").indexOf(name) > -1 ) {
                    return "取消"
                } return "选为"
        
            case "package":
                if (String(ImagesItem.p_object).split(",").indexOf(name) > -1 ) {
                    return "取消"
                } return "选为"

            case "wrap":
                if (String(ImagesItem.w_object).split(",").indexOf(name) > -1 ) {
                    return "取消"
                } return "选为"

            default:
                return ''
        }
    }

    const [ImagesSize, setImagesSize] = useState<{
        width?: number | string;
        height?: number | string;
    }>({
        width: "100%"
    })

    function handleOnChangImages(id: number) {
        const dom_base = document.getElementsByClassName("imagesContent");
        for (let i = 0; i < dom_base.length; i++) {
            const item: any = dom_base[i];
            if (item.id === `img_${id}`) {
                const viewWi = window.screen.width;
                const viewHe = window.screen.height;

                const imgWi = item.offsetWidth;
                const imgHe = item.offsetHeight;

                const w = viewWi / imgWi;
                const h = viewHe / imgHe;

                if (viewWi > imgWi && viewHe > imgHe) {
                    // 图片宽高都小于屏幕
                    setImagesSize({
                        width: "100%",
                    })
                }

                // 图片宽度大于屏幕宽度
                // 图片高度小于屏幕高度
                if (viewWi < imgWi && viewHe > imgHe) {
                    setImagesSize({
                        width: "100%",
                    })        
                }

                 // 图片宽度小于屏幕宽度
                // 图片高度大于屏幕高度
                if (viewWi > imgWi && viewHe < imgHe) {
                    setImagesSize({
                        width: imgWi* h,
                        height: "100%",
                    })
                }
                    
                
                // 图片宽度大于屏幕宽度
                // 图片高度大于屏幕高度
                if (viewWi < imgWi && viewHe < imgHe) {
                    setImagesSize({
                        width: "100%",
                        height: imgHe* w,
                    })
                }

                    // setImagesSize({
                    //     width: imgHe / w,
                    //     height: imgWi / h
                    // })

                // console.log("检测到图片宽度:", item.offsetWidth)
                // console.log("检测到图片高度:", item.offsetHeight)
            }
        }
    }
    
    return (
        <>
            <Pull
                ref={pullRef}
                load={{
                    state: loading,
                    distance: 30,
                    handler: loadData,
                }}
            > 
                <div className={style.list_Content}>
                    <div className={style.list_item}>
                        {newList[0].map((v, index) => {
                            return (
                                <div key={v.id} className={style.images_content} onClick={() => handleClickLookImages(v)}>
                                    <img src={v.or_w_img} alt="" />
                                    {data.type === "original" ? v.original === 1 ? (<img src={YesImages} alt="" className={style.add_images} />) : (<img src={AddImages} alt="" className={style.add_images} />) : ""}
                                    {data.type === "package" ? v.package === 1 ? (<img src={YesImages} alt="" className={style.add_images} />) : (<img src={AddImages} alt="" className={style.add_images} />) : ""}
                                    {data.type === "wrap" ? v.wrap === 1 ? (<img src={YesImages} alt="" className={style.add_images} />) : (<img src={AddImages} alt="" className={style.add_images} />) : ""}

                                    {data.type === "original" && v.o_object?.split(",").length > 0 && (<span className={style.select_tag_text}>{v.o_object}</span>)}
                                    {data.type === "package" && v.p_object?.split(",").length > 0 && (<span className={style.select_tag_text}>{v.p_object}</span>)}
                                    {data.type === "wrap" && v.w_object?.split(",").length > 0 && (<span className={style.select_tag_text}>{v.w_object}</span>)}
                                </div>
                            )
                        })}
                    </div>
                    <div className={style.list_item}>
                        {newList[1].map((v, index) => {
                            return (
                                <div key={index} className={style.images_content} onClick={() => handleClickLookImages(v)}>
                                    <img src={v.or_w_img} alt="" />
                                    {data.type === "original" ? v.original === 1 ? (<img src={YesImages} alt="" className={style.add_images} />) : (<img src={AddImages} alt="" className={style.add_images} />) : ""}
                                    {data.type === "package" ? v.package === 1 ? (<img src={YesImages} alt="" className={style.add_images} />) : (<img src={AddImages} alt="" className={style.add_images} />) : ""}
                                    {data.type === "wrap" ? v.wrap === 1 ? (<img src={YesImages} alt="" className={style.add_images} />) : (<img src={AddImages} alt="" className={style.add_images} />) : ""}

                                    {data.type === "original" && v.o_object?.split(",").length > 0 && (<span className={style.select_tag_text}>{v.o_object}</span>)}
                                    {data.type === "package" && v.p_object?.split(",").length > 0 && (<span className={style.select_tag_text}>{v.p_object}</span>)}
                                    {data.type === "wrap" && v.w_object?.split(",").length > 0 && (<span className={style.select_tag_text}>{v.w_object}</span>)}
                                </div>
                            )
                        })}
                    </div>
                    <div className={style.more_text}>{data.IsListNull ? "暂无更多" : "加载中..."}</div>
                </div >
            </Pull>

            <Popup
                className={style.PopupSwiper}
                visible={popupState}
                direction="center"
                mask={true}
                afterClose={() => setpopupState(false)}
            >

                <div style={{ height: '76vh', position: 'relative' }}>
                    <Swiper className="my-swipe" indicator={() => {
                        return null
                    }} initialSwipe={initialSlideCount} onChange={(e) => {
                        handleClickLookImages(origimages[e])
                    }}>
                    {origimages.map((s, index) => {
                        if (!popupState) {
                            return <></>
                        }
                        if (!s.or_w_img) {
                            return <></>
                        }
                        return (
                            <Swiper.Item key={index}>
                                 <div className={style.warp_images} style={{ height: '10vh' }}>
                                        <div className={style.warp_btn}>
                                        
                                            {s.is_order === 1 ? (
                                                <div className={style.null_text}>您已经购买过此张照片</div>
                                            ) : (
                                                <>
                                                    {data.detail.object.map((v: { name: string; alreadynum: number; num: number; o_object: string; p_object: string; w_object: string }, index: number) => {
                                                    return (
                                                        <div key={index} onClick={() => {
                                                            settopSelectType(v.name)
                                                        }}>
                                                            <div
                                                                className={`${style.tag_name} ${
                                                                    data.type === "original" && String(ImagesItem.o_object || "").split(",").indexOf(v.name) > -1 && style.tag_name_select} ${data.type === "package" && String(ImagesItem.p_object  || "").split(",").indexOf(v.name) > -1 && style.tag_name_select} ${data.type === "wrap" && String(ImagesItem.w_object  || "").split(",").indexOf(v.name) > -1 && style.tag_name_select}
                                                                `}
                                                                onClick={async () => {
                                                                    Loading.show();
                                                                    switch (data.type) {
                                                                        case "original":
                                                                            if (String(ImagesItem.o_object).split(",").indexOf(v.name) > -1) {
                                                                                handleClickCancelPhotos(v.name)
                                                                            } else {
                                                                                handleClickSelectPhotos(v.name)
                                                                            }
                                                                            break;
                                                                        case "package":
                                                                            if (String(ImagesItem.p_object).split(",").indexOf(v.name) > -1) {
                                                                                handleClickCancelPhotos(v.name)
                                                                            } else {
                                                                                handleClickSelectPhotos(v.name)
                                                                            }
                                                                            break;

                                                                        case "wrap":
                                                                            if (String(ImagesItem.w_object).split(",").indexOf(v.name) > -1) {
                                                                                await handleClickCancelPhotos(v.name)
                                                                            } else {
                                                                                await handleClickSelectPhotos(v.name)
                                                                            }
                                                                            break;
                                                                        default:
                                                                            break;
                                                                    }
                                                                    Loading.hide();
                                                                    return
                                                                }}
                                                            >
                                                                <div style={{ fontSize: 12, width: 52, textAlign: 'center' }}>
                                                                    点击{handleChangText(data.type, v.name)}
                                                                </div>
                                                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 80 }}>{v.name}</div>
                                                            </div>
                                                            <div>{v.alreadynum}/{v.num}</div>
                                                        </div>
                                                    )
                                                    })}
                                                </>   
                                            )}

                                            
                                            </div>
                                        </div>
                                
                                <img src={s.or_w_img} alt="" style={{ width: '80%', display: 'block', margin: '0 auto',  border: '8px solid #fff' }} />
                            </Swiper.Item>
                            )
                        })}
                    </Swiper>
                    <img src={ColseImages} alt="" className={style.closeIcon} onClick={() => setpopupState(false)} />

                    {/* <Swiper
                        className="main-container"
                        effect="coverflow"
                        scrollbar={{ draggable: true }} 
                        coverflowEffect={{ slideShadows: false }}
                        initialSlide={initialSlideCount} // 初始值
                        onSlideChange={(e: any) => {
                            if (origimages.length > e.activeIndex) {
                                handleClickLookImages(origimages[e.activeIndex])
                            }
                        }}
                    >
                        {origimages.map((s) => {
                            if (!popupState) {
                                return null
                            }
                            if (!s.or_w_img) {
                                return null
                            }
                            return (
                                <SwiperSlide className="swiper-item" key={s.id} style={{ position: 'relative' }}>
                                    <div className={style.warp_images}>
                                        <div className={style.warp_btn}>
                                            {s.is_order === 1 ? (
                                                <div className={style.null_text}>您已经购买过此张照片</div>
                                            ) : (
                                                <>
                                                    {data.detail.object.map((v: { name: string; alreadynum: number; num: number; o_object: string; p_object: string; w_object: string }, index: number) => {
                                                    return (
                                                        <div key={index} onClick={() => {
                                                            settopSelectType(v.name)
                                                        }}>
                                                            <div
                                                                className={`${style.tag_name} ${
                                                                    data.type === "original" && String(ImagesItem.o_object || "").split(",").indexOf(v.name) > -1 && style.tag_name_select} ${data.type === "package" && String(ImagesItem.p_object  || "").split(",").indexOf(v.name) > -1 && style.tag_name_select} ${data.type === "wrap" && String(ImagesItem.w_object  || "").split(",").indexOf(v.name) > -1 && style.tag_name_select}
                                                                `}
                                                                onClick={async () => {
                                                                    Loading.show();
                                                                    switch (data.type) {
                                                                        case "original":
                                                                            if (String(ImagesItem.o_object).split(",").indexOf(v.name) > -1) {
                                                                                handleClickCancelPhotos(v.name)
                                                                            } else {
                                                                                handleClickSelectPhotos(v.name)
                                                                            }
                                                                            break;
                                                                        case "package":
                                                                            if (String(ImagesItem.p_object).split(",").indexOf(v.name) > -1) {
                                                                                handleClickCancelPhotos(v.name)
                                                                            } else {
                                                                                handleClickSelectPhotos(v.name)
                                                                            }
                                                                            break;

                                                                        case "wrap":
                                                                            if (String(ImagesItem.w_object).split(",").indexOf(v.name) > -1) {
                                                                                await handleClickCancelPhotos(v.name)
                                                                            } else {
                                                                                await handleClickSelectPhotos(v.name)
                                                                            }
                                                                            break;
                                                                        default:
                                                                            break;
                                                                    }
                                                                    Loading.hide();
                                                                    return
                                                                }}
                                                            >
                                                                <div style={{ fontSize: 12, width: 52, textAlign: 'center' }}>
                                                                    点击{handleChangText(data.type, v.name)}
                                                                </div>
                                                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 80 }}>{v.name}</div>
                                                            </div>
                                                            <div>{v.alreadynum}/{v.num}</div>
                                                        </div>
                                                    )
                                                    })}
                                                </>   
                                            )}
                                            
                                        </div>
                                        <div className={style.images_content}>
                                        
                                            <img className={`${style.images_content_img} imagesContent`} style={{ width: "100%" }} id={`img_${s.id}`}  src={s.or_w_img} alt="" />
                                            {data.type === "original" && String(s.o_object).split(",").indexOf(topSelectType) > -1 && (<img src={YesImages} alt="" className={style.yesIcon} />)}
                                            {data.type === "package" && String(s.p_object).split(",").indexOf(topSelectType) > -1 && (<img src={YesImages} alt="" className={style.yesIcon} />)}
                                            {data.type === "wrap" && String(s.w_object).split(",").indexOf(topSelectType) > -1 && (<img src={YesImages} alt="" className={style.yesIcon} />)}
                                        </div>
                                        <img src={ColseImages} alt="" className={style.closeIcon} onClick={() => setpopupState(false)} />
                                    </div>
                                </SwiperSlide>
                            )
                        })}

                    </Swiper> */}

                </div>

            </Popup>

        </>
    )
}

//// 底部结算
function Settlement(props: {
    sum_images: number;
    list: Array<{
        id: number;
        is_selection: number;
        o_object: string;
        or_w_img: string;
        original: number;
        p_object: string;
        package: number;
        w_object: string;
        wrap: number;
    }>
    eroll_id: number;
    priceOnchang: (type: boolean) => void;
}) {

    const data = useContext<any>(Context);

    const eroll_id = getParamValue("eroll_id");

    const { Settlement, SetSettlement } = useData();

    const [popupState, setpopupState] = useState(false)

    useEffect(() => {
        if (popupState) {
            SetSettlement(false)
        } else {
            SetSettlement(true)
        }
    }, [popupState])

    const [shopCat, setShopCat] = useState<Array<{
        or_w_img: string;
        is_selection: number;
        id: number;
    }>>([])

    useEffect(() => {
        if (props.list.length > 0) {
            let newArray = []
            for (let i = 0; i < props.list.length; i++) {
                const item = props.list[i];
                if (item.is_selection === 1) {
                    newArray.push(item)
                }
            }
            setShopCat(newArray)
        }
    }, [props.list])

    useEffect(() => {
        if (data.alreadynum === 3) {
            // alert("弹窗提示")
            // setpopupState(true)
            props.priceOnchang(true)
        } else {
            props.priceOnchang(false)
        }
    }, [data.alreadynum])

    // 取消照片
    async function handleClickCancelPhotos(item: {
        or_w_img: string;
        is_selection: number;
        id: number;
    }) {
        let res = await FetchRequest("/wap/selectioneroll/filmQuit", {
            token: store.userStore.token,
            eroll_id,
            image_id: item.id,
            meal_type: data.type,
        })
        if (res.code === 1) {
            // isRefresh()
            // Toast.show(`取消 ${topSelectType} 成功`)
        } else {
            Toast.show(res.msg)
        }
    }

    async function handleClickSubform() {
        let res = await FetchRequest("/wap/selectioneroll/settle", {
            token: localStorage.getItem("token"),
            eroll_id: props.eroll_id,
            meal_type: data.type
        })
        if (res.code === 1) {
            store.routerStore.push(`/build/selection-activities-pay?eroll_id=${props.eroll_id}&meal_type=${data.type}`)
            // let result = await FetchRequest("/wap/selectioneroll/settle", {
            //     token: localStorage.getItem("token"),
            //     eroll_id: props.eroll_id,
            //     meal_type: data.type
            // })
            // if (result.code === 1) {
            //     store.routerStore.push(`/build/selection-activities-pay?eroll_id=${props.eroll_id}&meal_type=${data.type}`)
            // } else {
            //     Toast.show(result.msg)
            // }
        } else {
            Toast.show({
                content: res.msg,
                stayTime: 5000,
            });
        }
    }

    return (
        <>
            <div className={style.Settlement} style={!Settlement ? { zIndex: 10 } : {}}>
                {/* onClick={() => setpopupState(!popupState)} */}
                <div className={style.Settlement_left}>
                    <div>
                        <span className={style.Settlement_left_select}>{data.alreadynum}</span>
                        <span className={style.Settlement_left_all}>/{props.sum_images}</span>
                    </div>
                    <div className={style.Settlement_left_text}>已选择</div>
                </div>
                <div className={style.Settlement_center}>
                    <div className={style.Settlement_center_price}>¥{data.price}元</div>
                    <div className={style.Settlement_center_price_info}>
                        {/* <div>已有1452位家长选择</div> */}
                        {data.type === "original" && (
                            <div className={style.action_color}>已为您节省{data.cutprice}元</div>
                        )}
                    </div>
                </div>
                <div className={style.Settlement_right_text} onClick={handleClickSubform}>
                    去结算
                </div>
                {data.type === "original" && (<div className={style.Settlement_top_tipe}>{data.free}</div>)}
            </div>

            {/* 提示 */}
            {/* <Popup
                visible={popupState}
                direction="center"
                mask={true}
                onMaskClick={() => setpopupState(false)}
                afterClose={() => setpopupState(false)}
            >
                <div className={style.tipe_content}>
                    213123123
                    <img src={ColseImages} alt="" onClick={() => setpopupState(false)} />
                    <div className={style.btn_text}>立即选择</div>
                </div>
            </Popup> */}

        </>
    )
}

//// 悬浮客服按钮
function CustomerService(props: {
    consultation_telephone: string;
}) {
    return (
        <a href={`tel:${props.consultation_telephone}`} className={style.service}>
            <img src={ServiceImages} alt="" />
        </a>
    )
}

//// 悬浮套餐按钮
function PackageBtn(props: {
    meal: Array<{ 
        alreadynum: number; 
        cutprice: number; 
        free: string; 
        name: string; 
        price: number; 
        type: "original" | "package" | "wrap"; 
        detail: { all: Array<string>; object: Array<{ alreadynum: number; name: string; num: number }>, desc: string[] } 
        wrap_image?: string[]
    }>
    setComponentDate: (e: object) => void
    isRefresh: (type: "original" | "package" | "wrap") => void;
    IsPackageShow_3: boolean;
}) {

    const data = useContext<any>(Context);

    const [popupState, setpopupState] = useState(false)

    useEffect(() => {
        if (props.IsPackageShow_3) {
            setpopupState(true)
        }
    }, [props.IsPackageShow_3])

    async function handleClickSelect(item: { alreadynum: number; cutprice: number; free: string; name: string; price: number; type: "original" | "package" | "wrap"; detail: { all: Array<string>; object: Array<{ alreadynum: number; name: string; num: number }> } }) {
        props.setComponentDate(item)
        props.isRefresh(item.type)
    }

    const [visible_Images, setVisible_Images] = useState(false);
    const [imgList, setImgList] = useState<string[]>([])
    const [indexImg, setIndexImg] = useState(0)

    function handleClickPreview(list: string[], index: number) {
        setIndexImg(index)
        setImgList(list)
        setVisible_Images(true)
    }
    if (visible_Images) {
        return (
            <ImagePreview
                visible={visible_Images}
                images={imgList}
                onClose={() => setVisible_Images(false)}
                activeIndex={indexImg}
            />
        )
    }
    
    return (
        <>
            <div className={style.PackageBtn} onClick={() => setpopupState(!popupState)}>
                {/* <img src={PackageImages} alt="" /> */}
                套餐<br />选择
            </div>
            <Popup
                className={style.Popup_box}
                visible={popupState}
                direction="bottom"
                mask={true}
                onMaskClick={() => setpopupState(false)}
                afterClose={() => setpopupState(false)}
            >
                <div className={style.popupState_content}>
                    <div className={style.close_btn}>
                        <Button size="sm" style={{ float: 'right' }} onClick={() => setpopupState(false)}>关闭</Button>
                    </div>
                    {props.meal.map((v, index: number) => {
                        return (
                            <div className={style.item} key={index}>
                                <h4>{v.name}</h4>
                                {v.detail.all.map((j, index_j) => {
                                    return (
                                        <>
                                            <p key={index_j} dangerouslySetInnerHTML={{ __html: j }}></p>
                                            {v.detail.desc && j !== "" && (
                                                <p>{v.detail.desc[index_j]}</p>
                                            )}
                                        </>
                                    )
                                })}
                                <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    
                                    <div>
                                    {v.wrap_image && v.wrap_image!.map((s, index_1) => {
                                        return <img src={s} alt="" key={index_1} style={{ width: 50, margin: '0 15px' }} onClick={() => handleClickPreview(v.wrap_image!, index_1)} />
                                    })}
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                        {v.wrap_image && (
                                            <>
                                                <div>实物图片</div>
                                                <div>点击可放大</div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                
                                <div className={style.select_action} onClick={() => handleClickSelect(v)}>{data.type === v.type ? (<><img src={YesImages} alt="" style={{ width: '100%' }} /></>) : (<>点我<br />选择</>)} </div>
                            </div>
                        )
                    })}
                    
                </div>
            </Popup>
        </>
    )
}

// is_select 权限
function ISSelect(props: {
    children: ReactComponentElement<any>
    data: resType
}) {

    function handleClickSkipResg() {
        if (props.data.link) {
            store.routerStore.replace(props.data.link)
        }
    }

    if (Object.keys(props.data).length === 0) {
        return null
    }
    if (props.data.is_select === 1) {
        return props.children
    } return (
        <div className={style.null_content} onClick={handleClickSkipResg}>
            <h3>{props.data.activity_name}</h3>
            {props.data.select_time && (<p>选片时间: {props.data.select_time}</p>)}
            {props.data.order_number && (<p>订单编号: {props.data.order_number}</p>)}
            <p style={{ fontSize: 25 }}>{props.data.msg}</p>
            {props.data.link && (<div style={{ textAlign: "right", fontSize: 12 }}>点我去报名</div>)}
            <div className={style.nul_warp_top}>
                <img src={XlImages} alt="" />
                <span>需要我选择的照片</span>
            </div>
        </div>
    )
}

function useData() {
    // 在遮罩情况下是否显示底部计算
    const [Settlement, setSettlement] = useState(true);

    const SetSettlement = useCallback((e) => setSettlement((c) => e), []);

    return { Settlement, SetSettlement };
}

// 1970-01-01 08:00:00 ~ 1970-01-01 08:00:00
function selectTime(time: string): boolean {
    try {
        const start = time.split(" ~ ")[0]
        const end = time.split(" ~ ")[1]

        const startTime = new Date(start).getTime();
        const endTime = new Date(end).getTime();

        const currTime = new Date().getTime(); // 当前时间

        if (startTime < currTime) {
            if (endTime > currTime) {
                // 正常活动
                return true
            } else {
                // 活动结束
                return false
            }
        } else {
            // 活动暂未开始
            return false
        }
    } catch (error) {
        Toast.show("选片时间解析失败")
        return false
    }
}

