import React, { useState, useEffect, useRef, useCallback } from 'react'
import style from './index.module.scss'
import {
  Button,
  Cell,
  Input,
  Marquee,
  Popup,
  Toast,
  Radio,
  Checkbox,
  Modal,
  Loading,
  DatePicker,
} from 'zarm'
import MusicIamges from '../../assets/music.png'
import { store } from '../../store/store.root'
import { withRouter } from 'react-router-dom'
import { FetchRequest } from '../../utils/fetch'
import './test.css'
import { format } from 'date-fns'
import TelImages from '../../assets/service.png'
import { observer } from 'mobx-react'
import { handleOnChangShare } from '../../utils'
import { Cascader, Tabs } from 'react-vant'
import city from './data.json'

// const { Panel } = Tabs

type resultType = {
  A_info?: string
  A_price?: string
  B_info?: string
  eroll?: number
  B_price?: string
  C_info?: string
  C_price?: string
  A_pinfo?: string
  B_pinfo?: string
  C_pinfo?: string
  consult_phone?: string
  act_id?: string
  details?: string[]
  end_time?: number
  head_diagram?: string
  kindergart?: string[]
  address?: number
  music?: string
  partake?: number
  partake_user?: Array<{ avatar: string; openname: string; id: number }>
  start_time?: number
  user_entry?: string[]
  underline_pay?: number
  views?: number
  xy?: string
  wechat_sharing_instructions?: string
  wechat_sharing_pic?: string
  wechat_sharing_title?: string
  user?: {
    baby_class: string
    baby_name: string
    is_eroll: number
    extra: any
    order_status: 1 | 2
  }
}

export default observer(
  withRouter(
    (props: {
      match: {
        params: {
          id: string
        }
      }
    }) => {
      // 页面详情数据
      const [data, setData] = useState<resultType>({})

      useEffect(() => {
        document.title = '活动报名'
        // GetCode()
        GetErollDetailMethod()
        if (store.userStore.token) {
          console.log('开始请求数据')
          // handleOnChangShare()
          GetCode()
          // 延时等待缓存写入
          setTimeout(() => {
            setInterval(ActionCountTime, 1000)
          }, 1000)
        }
      }, [store.userStore.token])

      const [visible, setvisible] = useState(false)
      // 验证码
      const [CountDownSecond, setCountDownSecond] = useState(60)
      // 活动倒计时
      const [actionTime, setactionTime] = useState('-天-时-分-秒')
      // 播放
      const [musicPlay, setMusicPlay] = useState(false)
      // 活动状态 0 未开始 1 结束 2 开放
      const [actionStatus, setactionStatus] = useState<0 | 1 | 2>(0)
      // 出生日期状态
      const [startTime, setStartTime] = useState(false)
      // 出生日期值
      const [startTimeValue, setStartTartTimeValue] = useState('')
      // 音乐实例
      const [AudioInit, setAudioInit] = useState<any>(null)
      // 地区选择
      const [localAddress, set_local_address] = useState(false)
      // 省市区
      const [address, set_address] = useState({
        address: '',
        province: '',
        city: '',
        area: '',
        region: '',
      })

      useEffect(() => {
        if (AudioInit) {
          if (musicPlay) {
            AudioInit.play()
            console.log('开始')
          } else {
            AudioInit.pause()
            console.log('关闭')
          }
        }
      }, [musicPlay])

      //60s倒计时
      const handleCountDown = async (seconds = 60) => {
        // 判断是否存在手机
        var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
        if (!myreg.test(signUp.phone)) {
          Toast.show('手机号码格式不正确')
          return
        }
        // 发送请求
        let resultCode = await FetchRequest('/api/sms/send', {
          mobile: signUp.phone,
          event: 'register',
        })
        if (resultCode.code !== 1) {
          Toast.show(resultCode.msg)
          return
        }
        Toast.show(resultCode.msg)
        // let second = 60;
        let second = seconds
        const countDown = () => {
          // timer && clearTimeout(timer); //清除计时器
          if (second > 0) {
            // let second =
            // countDownSecond--;
            // let a  = countDownSecond - 1;
            second--
            setCountDownSecond(second)
          }
          if (second === 0) {
            /*
                    _this.countDown = 60;//恢复60s设定
                    _this.isVerify = false;//不显示60s计时器
                    clearTimeout(count60Timeout)//清除计时器
                    return //结束方法的调用
                */
            second = 60
            setCountDownSecond(second)
            // clearTimeout( timer ); //清除计时器
            return
          }
          // let timer = setTimeout( countDown,1000 );
          setTimeout(countDown, 1000)
        }
        setTimeout(countDown, 1000)
      }

      function ActionCountTime() {
        var oDate = new Date() //获取日期对象
        var oldTime = oDate.getTime() //现在距离1970年的毫秒数

        let newTime = Number(localStorage.getItem('action_time')) || 0
        var second = Math.floor((newTime - oldTime) / 1000) //未来时间距离现在的秒数
        var day = Math.floor(second / 86400) //整数部分代表的是天；一天有24*60*60=86400秒 ；
        second = second % 86400 //余数代表剩下的秒数；
        var hour = Math.floor(second / 3600) //整数部分代表小时；
        second %= 3600 //余数代表 剩下的秒数；
        var minute = Math.floor(second / 60)
        second %= 60
        setactionTime(
          `${tow(day)}天${tow(hour)}时${tow(minute)}分${tow(second)}秒`.replace(
            /-/g,
            ''
          )
        )
        // console.log(tow(day),tow(hour),tow(minute),tow(second))
      }

      function tow(n: string | number) {
        return n >= 0 && n < 10 ? '0' + n : '' + n
      }

      const [pickClass, setPickClass] = useState([])

      const [NewListS, setNewListS] = useState<any>([])

      async function GetErollDetailMethod() {
        const params: { id: string; token?: string } = {
          id: props.match.params.id,
        }
        // if (localStorage.getItem('token')) {
        //   params['token'] = localStorage.getItem('token') || ''
        // }
        Loading.show()
        let result = await FetchRequest(
          '/wap/graduation/getErollDetail',
          params
        )
        Loading.hide()
        if (result.code === 1) {
          setData(result.data)

          if (result.data.user.birthday) {
            setStartTartTimeValue(
              format(
                result.data.user.birthday * 1000 || new Date().getTime(),
                'yyyy-MM-dd'
              )
            )
          }

          let newArray = []

          for (let i = 0; i < result.data.partake_user!.length; i++) {
            if (result.data.partake_user!.length >= 5) {
              newArray.push(result.data.partake_user!.splice(0, 5))
            } else {
              // newArray.push(ArrayStart)
              break
            }
          }
          setNewListS(newArray)
          if (new Date().getTime() > result.data.start_time * 1000) {
            if (new Date().getTime() < result.data.end_time * 1000) {
              // 已经开始了
              console.log('已经开始了')
              setactionStatus(2)
              localStorage.setItem(
                'action_time',
                `${result.data.end_time! * 1000}`
              )
            } else {
              // 活动结束了
              console.log('活动结束了')
              setactionStatus(1)
              return
            }
          } else {
            // 未开始
            console.log('未开始')
            setactionStatus(0)
            localStorage.setItem(
              'action_time',
              `${result.data.start_time! * 1000}`
            )
          }
          let arr: any = []
          const list = result.data.kindergart || []
          for (let i = 0; i < list.length; i++) {
            const element = list[i]
            arr.push({
              value: element,
              label: element,
            })
          }
          setPickClass(arr)
          // 生日
          if (result.data.user_entry) {
            if (result.data.user_entry.indexOf('出生') > -1) {
              if (result.data.user.extra) {
                setStartTartTimeValue(result.data.user.extra['出生'])
              }
            }
          }
          // if (result.data.address === 1) {
          //   set_address({
          //     address: `${result.data.user.province} ${result.data.user.city} ${result.data.user.area}`,
          //     province: result.data.user.province,
          //     city: result.data.user.city,
          //     area: result.data.user.area,
          //     region: result.data.user.region,
          //   })
          // }
          let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
            currenturl: window.location.href,
          })
          if (SDKParams.code === 1) {
            const { wx }: any = window
            const {
              appId,
              nonceStr,
              signature,
              timestamp,
              jsApiList,
            } = SDKParams.data
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId, // 必填，公众号的唯一标识
              timestamp, // 必填，生成签名的时间戳
              nonceStr, // 必填，生成签名的随机串
              signature, // 必填，签名
              jsApiList, // 必填，需要使用的JS接口列表
            })

            wx.ready(function () {
              // 分享到朋友圈
              wx.onMenuShareTimeline({
                title: result.data.wechat_sharing_instructions, // 分享标题
                link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                desc: result.data.wechat_sharing_title, // 分享描述
                imgUrl: result.data.wechat_sharing_pic, // 分享图标
                success: function (res: any) {
                  console.log('分享朋友圈设置成功')
                },
                cancel: function (err: any) {
                  console.log('分享朋友圈设置失败:' + JSON.stringify(err))
                },
              })

              wx.updateAppMessageShareData({
                title: result.data.wechat_sharing_instructions, // 分享标题
                link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                desc: result.data.wechat_sharing_title, // 分享描述
                imgUrl: result.data.wechat_sharing_pic, // 分享图标
                success: function (res: any) {
                  console.log('分享朋友设置成功')
                },
                cancel: function (err: any) {
                  console.log('分享朋友设置失败:' + JSON.stringify(err))
                },
              })

              const u = navigator.userAgent
              const isAudroid =
                u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
              const isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

              if (isAudroid) {
                setTimeout(() => {
                  audioAutoPlay()
                }, 1500)
              }

              if (isIos) {
                audioAutoPlay()
              }
            })

            wx.error(function (res: any) {
              // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            })
          } else {
            Toast.show('JsSdk获取失败')
          }
        } else {
          Toast.show(result.msg)
        }
        // Loading.hide()
      }

      const [signUp, setSignUp] = useState<{
        act_id: string
        baby_name: string
        phone: string
        extra: string[]
        baby_class?: string
        code: string
      }>({
        act_id: props.match.params.id,
        baby_name: '',
        phone: '',
        extra: [],
        baby_class: '',
        code: '',
      })

      useEffect(() => {
        if (data.user?.is_eroll === 1) {
          setSignUp({
            ...signUp,
            baby_name: data.user.baby_name,
            baby_class: data.user.baby_class,
          })
        }
      }, [data.user])

      const [classPickSstatus, setclassPickSstatus] = useState(false)

      const [checked, setChecked] = useState(false)

      const [IamgesBase64, setIamgesBase64] = useState('')

      function audioAutoPlay() {
        const audio: any = document.getElementById('bg-music')
        audio.play()
        setAudioInit(audio)
        setMusicPlay(true)
      }

      async function GetCode() {
        let res = await FetchRequest('/wap/graduation/filmSuccess', {
          token: localStorage.getItem('token'),
        })
        if (res.code === 1) {
          setIamgesBase64(res.data)
        }
      }

      function handleSuccessAlertTips(type?: string) {
        setvisible(false)
        const modal = Modal.alert({
          title: '您已报名成功',
          content: (
            <div>
              <div>
                <img src={IamgesBase64} style={{ width: '60%' }} alt="" />
              </div>
              <div style={{ color: 'red', textAlign: 'center' }}>
                【下载底片】需长按关注此公众号
              </div>
            </div>
          ),
          onCancel: () => {
            if (type === 'pay') {
              location.reload()
            }
            modal.hide()
          },
        })
        return
      }

      async function handleClickSunForm() {
        if (data.address === 1) {
          if (!address.address) {
            Toast.show('请选择收货地址')
            return
          }
          if (!address.region) {
            Toast.show('请输入详细地址')
            return
          }
        }
        if (!checked) {
          Toast.show('请先同意用户协议')
          return
        }
        let isOk = false
        let str = ''
        for (let i = 0; i < signUp.extra.length; i++) {
          const item: any = signUp.extra[i]
          if (!item) continue
          for (let j = 0; j < Object.keys(item).length; j++) {
            const element = Object.keys(item)[j]
            if (!item[element]) {
              Toast.show(`请填写${element}`)
              isOk = true
              break
            }
            str += `"${element}":"${item[element]}",`
          }
        }

        if (isOk) return

        // 参数校验
        if (!signUp.baby_name) {
          Toast.show('请输入宝贝名称')
          return
        }

        if (!startTimeValue) {
          Toast.show('请输入出生年月')
          return
        }

        if (!store.userStore.mobile && data.user?.is_eroll !== 1) {
          // 没有报过名
          if (!signUp.phone) {
            Toast.show('请输入手机')
            return
          }
          // 验证码
          let resultCodeStaus = await FetchRequest('/api/sms/check', {
            mobile: signUp.phone,
            event: 'register',
            captcha: signUp.code,
          })
          if (resultCodeStaus.code === 0) {
            Toast.show(resultCodeStaus.msg)
            return
          }
          // 保存手机号
          // if (!store.userStore.mobile) {
          //   await FetchRequest('/wap/wap2/saveMobile', {
          //     mobile: signUp.phone,
          //   })
          // }
        }
        Loading.show()
        // alert(store.userStore.mobile || localStorage.getItem('mobile'))
        // 提交
        let result = await FetchRequest('/wap/graduation/submitEroll', {
          ...signUp,
          ...address,
          address:
            data.address === 1
              ? `${address.province} ${address.city} ${address.area} ${address.region}`
              : '',
          birthday: new Date(startTimeValue).getTime() / 1000,
          act_id: props.match.params.id,
          extra: `{${str}}`.replace(/,}/, '}'),
          meal: pack,
          phone:
            signUp.phone ||
            store.userStore.mobile ||
            localStorage.getItem('mobile'),
          token: localStorage.getItem('token'),
        })
        Loading.hide()
        setvisible(false)
        setIsSelectPackVis(false)
        if (result.code === 2) {
          const mobile_string = result.data.phone
          const modal = Modal.alert({
            title: '',
            content: (
              <div style={{ lineHeight: 3 }}>
                <div style={{ color: 'red' }}>该学生已报名, 请勿重复报名</div>
                <div>
                  报名信息: {result.data.baby_name}{' '}
                  {mobile_string.substring(0, 3) +
                    '****' +
                    mobile_string.substring(
                      mobile_string.length - 4,
                      mobile_string.length
                    )}
                </div>
                <div style={{ fontSize: 13 }}>
                  如与其他学生重名，请联系客服报名 {data.consult_phone}
                </div>
              </div>
            ),
            onCancel: () => {
              modal.hide()
            },
          })
          return
        }
        if (result.code === 1) {
          if (!store.userStore.mobile) {
            // 空
            if (
              result.data.price === '0.00' ||
              (pack === 'A' && data.underline_pay === 1)
            ) {
              let res = await FetchRequest('/wap/graduation/erollPay', {
                eroll_id: result.data.eroll_id,
                token: localStorage.getItem('token'),
                meal: pack,
              })
              if (res.code === 1) {
                Toast.show('报名成功')
                // store.routerStore.replace(`/build/selection-activities/${result.data.act_id}`)
                handleSuccessAlertTips()
              }
            } else {
              handleClickPay(result.data.eroll_id)
              // store.routerStore.push(`/build/graduation-sign-pay?eroll_id=${result.data.eroll_id}`)
            }
          } else {
            // 不为空
            if (data.user?.is_eroll === 0) {
              // 未支付
              if (
                result.data.price === '0.00' ||
                (pack === 'A' && data.underline_pay === 1)
              ) {
                let res = await FetchRequest('/wap/graduation/erollPay', {
                  eroll_id: result.data.eroll_id,
                  token: localStorage.getItem('token'),
                  meal: pack,
                })
                if (res.code === 1) {
                  Toast.show('报名成功')
                  // store.routerStore.replace(`/build/selection-activities/${result.data.act_id}`)
                  handleSuccessAlertTips()
                }
              } else {
                handleClickPay(result.data.eroll_id)
                // store.routerStore.push(`/build/graduation-sign-pay?eroll_id=${result.data.eroll_id}`)
              }
            } else {
              if (data.user?.order_status !== 2) {
                // store.routerStore.replace(`/build/selection-activities/${result.data.act_id}`)
                handleSuccessAlertTips()
              } else {
                if (
                  result.data.price === '0.00' ||
                  (pack === 'A' && data.underline_pay === 1)
                ) {
                  let res = await FetchRequest('/wap/graduation/erollPay', {
                    eroll_id: result.data.eroll_id,
                    token: localStorage.getItem('token'),
                    meal: pack,
                  })
                  if (res.code === 1) {
                    Toast.show('报名成功')
                    // store.routerStore.replace(`/build/selection-activities/${result.data.act_id}`)
                    handleSuccessAlertTips()
                  }
                } else {
                  handleClickPay(result.data.eroll_id)
                  // store.routerStore.push(`/build/graduation-sign-pay?eroll_id=${result.data.eroll_id}`)
                }
              }
            }
          }
        } else {
          Toast.show(result.msg)
        }
      }

      async function handleClickPay(eroll_id: string) {
        Loading.show()
        let res = await FetchRequest('/wap/graduation/erollPay', {
          eroll_id,
          token: localStorage.getItem('token'),
          meal: pack,
        })
        if (res.code === 1) {
          if (res.data === 'success') {
            store.routerStore.replace(`/build/graduation/${eroll_id}`)
            return
          }
          let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
            currenturl: window.location.href,
          })
          const { wx }: any = window
          const {
            appId,
            nonceStr,
            signature,
            timestamp,
            jsApiList,
          } = SDKParams.data
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId, // 必填，公众号的唯一标识
            timestamp, // 必填，生成签名的时间戳
            nonceStr, // 必填，生成签名的随机串
            signature, // 必填，签名
            jsApiList, // 必填，需要使用的JS接口列表
          })

          wx.ready(function () {
            const {
              appId,
              nonceStr,
              order_id,
              paySign,
              signType,
              timestamp,
            } = res.data.data
            wx.chooseWXPay({
              timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
              package: res.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: paySign, // 支付签名
              success: function (res: any) {
                // 支付成功后的回调函数
                //   alert("支付成功")
                // store.routerStore.replace(`/build/graduation/${eroll_id}`)
                handleSuccessAlertTips('pay')
              },
            })
          })

          wx.error(function (res: any) {
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            Toast.show('支付失败')
          })
        } else {
          Toast.show(res.msg)
        }
        Loading.hide()
      }

      const [pack, setPack] = useState('')

      async function handleClickPayPack(pack: string, type: boolean = false) {
        setPack(pack)
        if (type) {
          setIsSelectPackVis(true)
        } else {
          setvisible(true)
        }
      }

      const [isSelectPackVis, setIsSelectPackVis] = useState(false)

      const [TabCountValue, setTabCountValue] = useState(0)

      function handleTabPanel() {
        const dom = []

        if (data.C_price) {
          dom.push(
            <Tabs.TabPane title={`${data.C_price}套餐`}>
              <div className={style.packageContentItem}>
                <img src={data.C_info} alt="" style={{ width: '100%' }} />
                <Button
                  theme="danger"
                  block
                  className={style.paymentButton}
                  onClick={() => handleClickPayPack('C')}
                >
                  {data.C_pinfo}
                </Button>
              </div>
            </Tabs.TabPane>
          )
        }

        if (data.B_price) {
          dom.push(
            <Tabs.TabPane title={`${data.B_price}套餐`}>
              <div className={style.packageContentItem}>
                <img src={data.B_info} alt="" style={{ width: '100%' }} />
                <Button
                  theme="danger"
                  block
                  className={style.paymentButton}
                  onClick={() => handleClickPayPack('B')}
                >
                  {data.B_pinfo}
                </Button>
              </div>
            </Tabs.TabPane>
          )
        }

        if (data.A_price) {
          dom.push(
            <Tabs.TabPane
              renderTitle={
                <>
                  <div
                  // style={TabCountValue === 2 ? { color: '#00bc70' } : {}}
                  >
                    {`${data.A_price}套餐`}{' '}
                    {data.underline_pay === 1 && (
                      <span
                        style={{
                          color: 'red',
                          fontSize: 12,
                          fontWeight: 'bold',
                        }}
                      >
                        已付款
                      </span>
                    )}
                  </div>
                </>
              }
              //   <>
              //     <div
              //     // style={TabCountValue === 2 ? { color: '#00bc70' } : {}}
              //     >
              //       {}{' '}
              //       {data.underline_pay === 1 && (
              //         <span
              //           style={{ color: 'red', fontSize: 12, fontWeight: 'bold' }}
              //         >
              //           已付款
              //         </span>
              //       )}
              //     </div>
              //   </>
              // }
            >
              <div className={style.packageContentItem}>
                <img src={data.A_info} alt="" style={{ width: '100%' }} />
                <Button
                  theme="danger"
                  block
                  className={style.paymentButton}
                  onClick={() => handleClickPayPack('A')}
                >
                  {data.A_pinfo}
                </Button>
              </div>
            </Tabs.TabPane>
          )
        }

        return dom
      }

      if (Object.keys(data).length === 0) {
        return <div style={{ textAlign: 'center', paddingTop: 10 }}>加载中</div>
      }

      if (data.end_time! <= 0) {
        return null
      }

      return (
        <div className={style.ActivityRegistration}>
          <audio
            style={{ display: 'none', height: 0 }}
            id="bg-music"
            preload="auto"
            loop
            src={data.music}
          >
            您的浏览器不支持音频播放
          </audio>
          <div className={style.ActivityRegistration_warp}>
            <img src={data.head_diagram} alt="" />
          </div>
          <div className="bodyBox">
            <div className="title_count">已有 {data.partake} 人参与</div>
            <div className="title_count">浏览量 {data.views} 人</div>
            <Marquee direction="up" width={'100%'} height={180}>
              {NewListS.map((v: any[], index: React.Key | null | undefined) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignContent: 'center',
                      padding: 5,
                    }}
                    key={index}
                  >
                    {v?.map((j, index_son) => {
                      return (
                        <img
                          src={j.avatar}
                          key={index_son}
                          alt=""
                          style={{ width: 60, height: 60, borderRadius: '50%' }}
                        />
                      )
                    })}
                  </div>
                )
              })}
              <div style={{ width: '100%', height: 120 }}></div>
            </Marquee>
          </div>
          <div>
            {data.details?.map((v, index) => {
              return (
                <div className={style.images_list} key={index}>
                  <img src={v} alt="" />
                </div>
              )
            })}
          </div>
          {/* {data.A_price && (
          <div className={style.packageContent}>
            <span className={style.packageName}>{data.A_price}套餐</span>
            <img src={data.A_info} alt="" />
            <Button
              shape="round"
              theme="danger"
              block
              className={style.paymentButton}
              onClick={() => handleClickPayPack('A')}
            >
              点击支付{data.A_price}元
            </Button>
          </div>
        )}

        {data.B_price && (
          <div className={style.packageContent}>
            <span className={style.packageName}>{data.B_price}套餐</span>
            <img src={data.B_info} alt="" />
            <Button
              shape="round"
              theme="danger"
              block
              className={style.paymentButton}
              onClick={() => handleClickPayPack('B')}
            >
              点击支付{data.B_price}元
            </Button>
          </div>
        )}

        {data.C_price && (
          <div className={style.packageContent}>
            <span className={style.packageName}>{data.C_price}套餐</span>
            <img src={data.C_info} alt="" />
            <Button
              shape="round"
              theme="danger"
              block
              className={style.paymentButton}
              onClick={() => handleClickPayPack('C')}
            >
              点击支付{data.C_price}元
            </Button>
          </div>
        )} */}
          <div className={style.buttonAtTheBottomOfTheFixed}>
            <Button
              shape="round"
              theme="danger"
              block
              className={style.paymentButton}
              onClick={() => {
                if (data.eroll === 1) {
                  store.routerStore.replace(
                    `/build/graduation-select/${data.act_id}`
                  )
                } else {
                  if (!store.userStore.token) {
                    store.userStore.loginFromWechat(true)
                  } else {
                    setIsSelectPackVis(true)
                  }
                }
              }}
            >
              {data.eroll === 1 ? '制作相册' : '点击报名'}
            </Button>
          </div>
          <a
            className={style.Music_icon}
            href={`tel:${data.consult_phone}`}
            style={{ bottom: '12%' }}
          >
            <img src={TelImages} alt="" style={{ animation: 'none' }} />
          </a>
          <div
            className={style.Music_icon}
            onClick={(e) => {
              e.stopPropagation()
              setMusicPlay(!musicPlay)
            }}
          >
            <img
              src={MusicIamges}
              alt=""
              style={!musicPlay ? { animation: 'none' } : {}}
            />
          </div>
          <Popup
            visible={isSelectPackVis}
            direction="bottom"
            mask={true}
            afterClose={() => console.log('关闭')}
            onMaskClick={() => setIsSelectPackVis(false)}
          >
            <div className={style.popupWindowContentPackage}>
              <Tabs
                color="red"
                titleActiveColor="red"
                lineWidth={80}
                active={TabCountValue}
                onChange={(e: any) => setTabCountValue(e)}
              >
                {handleTabPanel().map((v) => {
                  return v
                })}
              </Tabs>
            </div>
          </Popup>
          <Popup
            visible={visible}
            direction="bottom"
            mask={true}
            afterClose={() => console.log('关闭')}
            onMaskClick={() => setvisible(false)}
          >
            <div className={style.popuo_Content}>
              <div className={style.packageName_title}>
                您已选择{' '}
                {(pack === 'A' && data.A_price) ||
                  (pack === 'B' && data.B_price) ||
                  (pack === 'C' && data.C_price)}
                套餐
              </div>
              <Cell
                title={
                  <div>
                    宝贝姓名
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Input
                  type="text"
                  placeholder="请输入宝贝姓名"
                  value={signUp.baby_name}
                  onChange={(e: any) => setSignUp({ ...signUp, baby_name: e })}
                />
              </Cell>

              <Cell
                title={
                  <div>
                    出生年月
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                onClick={() => {
                  setStartTime(true)
                }}
              >
                <Input
                  type="text"
                  placeholder="请选择出生日期"
                  value={startTimeValue}
                />
              </Cell>

              {data.address === 1 && (
                <>
                  <Cell
                    title={
                      <div>
                        收货地址
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                    onClick={() => {
                      set_local_address(true)
                    }}
                  >
                    <Input
                      type="text"
                      placeholder="请选择收货地址"
                      value={address.address}
                    />
                  </Cell>
                  <Cell
                    title={
                      <div>
                        详细地址
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                  >
                    <Input
                      type="text"
                      placeholder="请填写详细地址"
                      value={address.region}
                      onChange={(e: any) => {
                        set_address({
                          ...address,
                          region: e,
                        })
                      }}
                    />
                  </Cell>
                </>
              )}

              {data.user_entry?.map((v, index: number) => {
                return (
                  <Cell
                    key={index}
                    title={
                      <div>
                        {v}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                  >
                    <Input
                      type="text"
                      placeholder={`请输入${v}`}
                      defaultValue={data.user?.extra ? data.user?.extra[v] : ''}
                      onChange={(e: any) => {
                        let obj: any = {}
                        obj[v] = e
                        signUp.extra[index] = obj
                      }}
                    />
                  </Cell>
                )
              })}
              {!store.userStore.mobile && data.user?.is_eroll !== 1 && (
                <>
                  <Cell
                    title={
                      <div>
                        手机
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                  >
                    <Input
                      type="text"
                      placeholder="请输入手机号码"
                      value={signUp.phone}
                      onChange={(e: any) => setSignUp({ ...signUp, phone: e })}
                    />
                    {CountDownSecond !== 60 ? (
                      <Button theme="primary" size="xs" style={{ width: 120 }}>
                        {CountDownSecond}s
                      </Button>
                    ) : (
                      <Button
                        theme="primary"
                        size="xs"
                        style={{ width: 120 }}
                        onClick={() => handleCountDown()}
                      >
                        发送验证码
                      </Button>
                    )}
                  </Cell>
                  <Cell title="验证码">
                    <Input
                      type="text"
                      placeholder="请输入验证码"
                      value={signUp.code}
                      onChange={(e: any) => setSignUp({ ...signUp, code: e })}
                    />
                  </Cell>
                </>
              )}
              <Cell>
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e!.target.checked)
                  }}
                />
                <label htmlFor="agreement" style={{ marginLeft: 5 }}>
                  阅读并同意
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault()
                      const modal = Modal.alert({
                        title: '用户协议条款',
                        content: (
                          <div
                            dangerouslySetInnerHTML={{ __html: data.xy! }}
                          ></div>
                        ),
                        onCancel: () => {
                          modal.hide()
                        },
                      })
                    }}
                  >
                    《用户协议条款》
                  </a>
                  中的相关规定
                </label>
              </Cell>
              <Button
                block
                theme="primary"
                style={{ marginTop: 50 }}
                onClick={handleClickSunForm}
              >
                立即报名
              </Button>
            </div>
          </Popup>
          <DatePicker
            visible={startTime}
            mode="date"
            value={startTimeValue}
            onOk={(value: any) => {
              setStartTartTimeValue(format(value, 'yyyy-MM-dd'))
              setStartTime(false)

              if (data.user_entry!.indexOf('出生日期') > -1) {
                let obj: any = {}
                obj[data.user_entry![0]] = format(value, 'yyyy-MM-dd')
                signUp.extra[data.user_entry!.indexOf('出生日期')] = obj
              }
            }}
            onCancel={() => setStartTime(false)}
          />
          <Popup
            visible={classPickSstatus}
            direction="bottom"
            mask={true}
            afterClose={() => console.log('关闭')}
            onMaskClick={() => setclassPickSstatus(false)}
          >
            <Radio.Group
              type="cell"
              value={signUp.baby_class}
              onChange={(e) => {
                if (pickClass.length === 0) {
                  setSignUp({ ...signUp, baby_class: '' })
                } else {
                  setSignUp({ ...signUp, baby_class: String(e) })
                }
                setclassPickSstatus(false)
              }}
              style={{ maxHeight: '60vh', overflowY: 'scroll' }}
            >
              {pickClass.map((v: { value: string }, index) => {
                return (
                  <Radio value={v.value} key={index}>
                    {v.value}
                  </Radio>
                )
              })}
            </Radio.Group>
          </Popup>
          <Popup
            visible={localAddress}
            direction="bottom"
            afterClose={() => console.log('关闭')}
            onMaskClick={() => set_local_address(false)}
          >
            <div
              style={{
                background: '#fff',
              }}
            >
              <Cascader
                activeColor="#00bc70"
                title="请选择所在地区"
                options={city}
                fieldNames={{
                  text: 'name',
                  value: 'name',
                  children: 'children',
                }}
                onChange={({ value, selectedOptions, tabIndex }) => {
                  console.log(value, selectedOptions, tabIndex)
                  if (tabIndex === 2) {
                    const d = selectedOptions
                      .map((v: any) => {
                        return v.name
                      })
                      .join(' ')
                    set_address({
                      ...address,
                      address: d,
                      province: selectedOptions[0].name,
                      city: selectedOptions[1].name,
                      area: selectedOptions[2].name,
                    })
                    set_local_address(false)
                  }
                }}
              />
            </div>
          </Popup>
        </div>
      )
    }
  )
)
