import React, { useEffect, useState } from 'react'
import style from './index.module.scss'
import { Cell, Button, Toast, ImagePreview, Modal } from 'zarm'
import { withRouter } from 'react-router-dom'
import { FetchRequest } from '../../utils/fetch'
import { store } from '../../store/store.root'
import { EnlargeImages } from '../../components/EnlargeImages'
import { observer } from 'mobx-react'

const styleBtn: React.CSSProperties = {
  position: 'fixed',
  width: '100%',
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export default observer(
  withRouter(
    (props: {
      match: {
        params: {
          id: string
        }
      }
    }) => {
      const [visible, setVisible] = useState(false)
      const [pictureIndex] = useState(0)

      useEffect(() => {
        document.title = '签约授权'
      }, [])

      useEffect(() => {
        if (store.userStore.token) {
          GetAuthorityInfo()
        }
      }, [store.userStore.token])

      const [data, setData] = useState<{
        createtime?: string
        id?: string
        ids?: string
        is_auth?: number
        name?: string
        treaty?: string[]
      }>({})

      async function GetAuthorityInfo() {
        const models_id = props.match.params.id
        if (!models_id) {
          store.routerStore.replace('/build')
          return
        }
        let res = await FetchRequest('/wap/models/getAuthority', {
          token: localStorage.getItem('token') || store.userStore.token,
          models_id,
        })
        if (res.code === 1) {
          // 判断是否授权过
          if (res.data.is_auth === 1) {
            // 同意过
            // 弹窗提示
            const modal = Modal.alert({
              title: '提示',
              content: '您已授权',
              onCancel: () => {
                modal.hide()
              },
            })
          }
          setData(res.data)
        } else {
          Toast.show(res.msg)
        }
      }

      async function handleClickAuthorization(status: 1 | 2) {
        const models_id = props.match.params.id
        let res = await FetchRequest('/wap/models/submitAuthority', {
          token: localStorage.getItem('token'),
          is_auth: status,
          models_id,
        })
        if (res.code === 1) {
          Toast.show(res.msg)
        } else {
          Toast.show(res.msg)
        }
        GetAuthorityInfo()
      }

      if (!data.id) {
        return <div className={style.data_loading}>数据加载中...</div>
      }

      return (
        <div className={style.Agreement}>
          <div className={style.Agreement_ID}>
            <p style={{ fontSize: 19, textAlign: 'center' }}>模特协议确认</p>
            <p style={{ fontSize: 16, paddingLeft: 5 }}>恭喜您成为签约模特</p>
            {/* <p>点击【确认】代表授权成功</p>
                <p>点击【取消】代表拒绝授权</p> */}
          </div>
          <Cell title="协议ID编号：">
            <span className={style.vlaue_color}>{data!.ids}</span>
          </Cell>
          <Cell title="客户姓名：">
            <span className={style.vlaue_color}>{data!.name}</span>
          </Cell>
          {data.treaty?.map((v, index: number) => {
            return (
              <Cell
                title="协议图片："
                key={index}
                help={
                  <img
                    src={v}
                    alt=""
                    style={{ width: '100%' }}
                    onClick={() => setVisible(true)}
                  />
                }
              ></Cell>
            )
          })}
          <Cell title="签署日期：">
            <span className={style.vlaue_color}>{data.createtime}</span>
          </Cell>
          {/* 按钮 */}
          {data.is_auth !== 1 && (
            <div style={styleBtn}>
              <Button
                block
                theme="primary"
                onClick={() => handleClickAuthorization(1)}
              >
                同意授权
              </Button>
              <Button
                block
                theme="danger"
                onClick={() => handleClickAuthorization(2)}
              >
                拒绝授权
              </Button>
            </div>
          )}
          {/* 放大 */}
          <ImagePreview
            visible={visible}
            images={data.treaty!}
            onClose={() => setVisible(false)}
            activeIndex={pictureIndex}
          />
        </div>
      )
    }
  )
)
