import React from "react";
import { observer } from "mobx-react";
import "./employee-preorder-clothe-brief-detail.scss";
import { EmployeePreorderClotheListDataInterface } from "../../store/employee-preorder-clothe-list/employee-preorder-clothe-list.interface";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";
import { store } from "../../store/store.root";
import { Cell, ImagePreview, Modal, Toast } from "zarm";
import { BangBangTangRequest } from "../../utils/request";
import { PreorderClotheStatusRender } from "../../components/preorder-clothe-status-render";
import { format } from "date-fns";
import { FooterButtons } from "../../components/footer-buttons/footer-buttons";
import { EmployeePreorderClotheBriefDetailEmployeeClotheShareModal } from "./employee-preorder-clothe-brief-detail.employee-clothe-share-modal";
import { preorderDetailFromEmployeeStore } from "../preorder-detail-from-employee/preorder-detail-from-employee.store";
import { PreorderDetailFromEmployeeSetPhotographerModal } from "../preorder-detail-from-employee/preorder-detail-from-employee.set-photographer-modal";
import { BangBangTangLayout } from "../../layout/layout";
import { EmployeePreorderClotheBriefDetailSetModal } from "./employee-preorder-clothe-brief-detail.set-modal";
import { employeePreorderClotheBriefDetailStore } from "./employee-preorder-clothe-brief-detail.store";

@observer
export class EmployeePreorderClotheBriefDetail extends React.Component<
  any,
  {
    preview: undefined | number;
    copy_modal_visible: boolean;
    set_modal_visible: boolean;
    data:
      | undefined
      | {
          id: number;
          staff_id: number;
          username: string;
          mobile: string;
          create_time: number;
          schedule_date_text: string;
          schedule_date: number;
          expire_time: number;
          user_submit_time: number;
          typeids: string;
          standard_num_limit: number;
          list_source: string;
          store_id: number;
          status: string;
          user_id: number;
          selection_standard_ids: string;
          booking_id: number;
          source_type: string;
          server_staff_id: number;
          server_staff_user_id: string;
          create_time_date: string;
          server_staff_user_id_arr: number[];
          tags_text: string;
          share_staff: string;
          server_staff: string;
          status_text: string;
          age_type: string[];
          gender_type: string[];
          standardList: any[];
        };
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: undefined,
      preview: undefined,
      copy_modal_visible: false,
      set_modal_visible: false,
    };
  }
  refresh(id: number) {
    BangBangTangRequest({
      url: `/wap/clothing/getSelectionDetail?token=${store.userStore.token}&selection_id=${id}`,
      method: "GET",
      data: {},
    }).subscribe((value) => this.setState({ data: value }));
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      document.title = "预选单详情";
      const id = new URLSearchParams(location.search).get("id");
      if (!id) {
        alert("页面缺少关键参数,请从合法入口进入");
        store.routerStore.replace("/build");
      } else {
        this.refresh(parseInt(id, 10));
        if (employeePreorderClotheBriefDetailStore.servers === undefined) {
          employeePreorderClotheBriefDetailStore.getServers();
        }
      }
    });
  }

  render() {
    if (this.state.data === undefined) {
      return null;
    }
    const data = this.state.data;
    if (
      this.state.preview &&
      data.standardList.find((v) => v.id === this.state.preview)
    ) {
      return (
        <>
          <div className="employee-preorder-clothe-brief-detail-img">
            {data.standardList
              .find((v) => v.id === this.state.preview)!
              .showimages.map((value: any) => (
                <div key={value} className="img">
                  <img src={value} alt="" />
                </div>
              ))}
          </div>
          <FooterButtons
            buttons={[
              {
                label: "返回",
                color: "white",
                background: "#12C287",
                hidden: false,
                onClick: () => this.setState({ preview: undefined }),
              },
            ]}
          />
          {/*<ImagePreview*/}
          {/*  visible={!!this.state.preview}*/}
          {/*  onClose={() => this.setState({ preview: undefined })}*/}
          {/*  images={}*/}
          {/*/>*/}
        </>
      );
    } else {
    }
    return (
      <div className="employee-preorder-clothe-brief-detail">
        <Cell title={"客户姓名"} description={data.username} />
        <Cell title={"客户手机"} description={data.mobile} />
        <Cell title={"最大预选服装数"} description={data.standard_num_limit} />
        <Cell
          title={"性别标签"}
          description={
            <div className="tag-container">
              {data.gender_type.map((value) => (
                <span className="tag" key={value}>
                  {value}
                </span>
              ))}
            </div>
          }
        />
        <Cell
          title={"年龄标签"}
          description={
            <div className="tag-container">
              {data.age_type.map((value) => (
                <span className="tag" key={value}>
                  {value}
                </span>
              ))}
            </div>
          }
        />
        <Cell
          title={"提交状态"}
          description={<PreorderClotheStatusRender value={data.status} />}
        />
        <div className="divider" />
        <Cell
          title={"预选单拍摄日期"}
          description={
            data.schedule_date
              ? format(data.schedule_date * 1000, "yyyy-MM-dd")
              : "无生成时间你敢信不"
          }
        />
        <Cell
          title={"预选单生成时间"}
          description={
            data.create_time
              ? format(data.create_time * 1000, "yyyy-MM-dd")
              : "无生成时间你敢信不"
          }
        />
        {(data.status === "committed" || data.status === "cancel") &&
        data?.standardList &&
        data.standardList.length > 0 ? (
          <>
            {data?.standardList ? (
              <>
                <div className={"divider"} />
                <div className="standard-image-container">
                  {data.standardList.map((v) => (
                    <div
                      key={v.id}
                      className="row"
                      onClick={() => {
                        this.setState({ preview: v.id });
                      }}
                    >
                      <div>
                        <img alt="" src={v.showimages[0]} />
                      </div>
                      <div className="tags">
                        {v.costume?.map((costume: any) => (
                          <span key={costume.id}>
                            {costume.material_cat_name}:
                            {costume.storehouse_number}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </>
        ) : null}
        {data ? (
          <EmployeePreorderClotheBriefDetailEmployeeClotheShareModal
            visible={this.state.copy_modal_visible}
            preorder_id={data.id}
            limit={data.standard_num_limit}
            name={data.username}
            onCancel={() => this.setState({ copy_modal_visible: false })}
          />
        ) : null}
        <FooterButtons
          buttons={[
            {
              label: "分配",
              hidden: !(
                // data?.status === "uncommitted" &&
                store.websiteRuleStore.has_rule("select_selection_server_staff")
              ),
              color: "#FF5050",
              onClick: () => {
                this.setState({ set_modal_visible: true });
              },
            },
            {
              label: "编辑",
              hidden: !store.websiteRuleStore.has_rule(
                "edit_clothing_selection2"
              ),
              color: "#12C287",
              onClick: () => {
                store.routerStore.push(
                  "/build/employee-preorder-clothe-detail?id=" + data?.id
                );
              },
            },
            {
              label: "分享",
              hidden: data?.status !== "uncommitted",
              color: "#12C287",
              onClick: () => {
                this.setState({ copy_modal_visible: true });
              },
            },
            {
              label: "重新启用",
              hidden: !(
                data?.status === "expired" &&
                store.websiteRuleStore.has_rule("reset_clothing_selection2")
              ),
              color: "#12C287",
              onClick: () => {
                this.restart();
              },
            },
          ]}
        />
        {!this.state.set_modal_visible ? null : (
          <EmployeePreorderClotheBriefDetailSetModal
            onCancel={() => this.setState({ set_modal_visible: false })}
            origin_value={data?.server_staff_user_id_arr}
            onSubmit={(value) => {
              this.submitServerStaff(value);
              this.setState({ set_modal_visible: false });
            }}
          />
        )}
      </div>
    );
  }

  private restart() {
    BangBangTangRequest({
      url: "/wap/clothing/resetSelection",
      data: { token: store.userStore.token, selection_id: this.state.data!.id },
      method: "POST",
    }).subscribe(() => {
      store.employeePreorderClotheListStore.reset();
      this.refresh(this.state.data!.id);
    });
  }

  private cancel() {
    Modal.confirm({
      title: "警告",
      content: "您确定要取消这个预选单吗？",
      onOk: () => {
        BangBangTangRequest({
          url: "/wap/clothing/staffCancelSelection",
          data: {
            token: store.userStore.token,
            selection_id: this.state.data!.id,
          },
          method: "POST",
        }).subscribe(() => {
          store.employeePreorderClotheListStore.reset();
          this.refresh(this.state.data!.id);
        });
      },
    });
  }

  private submitServerStaff(value: number[]) {
    BangBangTangRequest({
      url: "/wap/clothing/updateSelectionServerStaff",
      method: "POST",
      data: {
        server_staff_user_id: value.join(","),
        selection_id: this.state.data?.id,
      },
    }).subscribe(() => {
      Toast.show("分配成功");
      this.refresh(this.state.data!.id);
    });
  }
}
