import React from "react";
import { store } from "../../store/store.root";
import { observer } from "mobx-react";

@observer
export class EmployeeClotheButtonRow extends React.Component {
  render() {
    if (store.employeePreorderClotheStore.preorder_id) {
      return (
        <div className="employee-preorder-clothe-button-row">
          {/*{store.employeePreorderClotheStore.expire_time * 1000 <*/}
          {/*  new Date().getTime() &&*/}
          {/*(store.employeePreorderClotheStore.status === "expired" ||*/}
          {/*  store.employeePreorderClotheStore.status === "uncommitted") ? (*/}
          {/*  <div*/}
          {/*    className="button"*/}
          {/*    style={{ background: "#FF9696" }}*/}
          {/*    onClick={() => store.employeePreorderClotheStore.restart()}*/}
          {/*  >*/}
          {/*    重启*/}
          {/*  </div>*/}
          {/*) : null}*/}
          <div
            className="button"
            style={{ background: "#71DAB7" }}
            onClick={() =>
              store.employeePreorderClotheStore.toggle_share_modal(true)
            }
          >
            分享
          </div>
          <div
            className="button"
            style={{ background: "#F4BD83" }}
            onClick={() =>
              store.employeePreorderClotheStore.toggle_save_modal_visible(true)
            }
          >
            信息
          </div>
          <div
            className="button"
            style={{ background: "#D8D8D8" }}
            onClick={() =>
              store.employeePreorderClotheStore.toggle_popup_visible(true)
            }
          >
            标签
          </div>
          <div
            className="button"
            style={{ background: "#a6ec96" }}
            onClick={() =>
              store.employeePreorderClotheStore.toggle_photo_modal_visible(true)
            }
          >
            查看
          </div>
        </div>
      );
    } else {
      return (
        <div className="employee-preorder-clothe-button-row">
          <div
            className="button"
            style={{ background: "#D8D8D8" }}
            onClick={() =>
              store.employeePreorderClotheStore.toggle_popup_visible(true)
            }
          >
            生成
          </div>
        </div>
      );
    }
  }
}
