import React, { useState, useEffect } from 'react'
import { Cell, Input, Button, Loading, Toast } from 'zarm'
import { store } from '../../store/store.root'
import { FetchRequest } from '../../utils/fetch'
import { getParamValue } from '../../utils/url'
import { observer } from 'mobx-react'

const styleBtnPay: React.CSSProperties = {
  position: 'fixed',
  width: '100%',
  left: 0,
  right: 0,
  bottom: 0,
}

export default observer(() => {
  const eroll_id = getParamValue('eroll_id')

  useEffect(() => {
    document.title = '订单确认'
    if (store.userStore.token) {
      GetPayInfo()
    }
  }, [store.userStore.token])

  const [payInfo, setPayInfo] = useState<{
    activity_name?: string
    baby_name?: string
    eroll_id?: string
    phone?: string
    price?: string
    meal?: string
    activity_id?: string
  }>({})

  async function GetPayInfo() {
    let res = await FetchRequest('/wap/graduation/getErollOrder', {
      eroll_id,
      token: localStorage.getItem('token'),
    })
    if (res.code === 1) {
      setPayInfo(res.data)
    } else {
      Toast.show(res.msg)
    }
  }

  async function handleClickPay() {
    Loading.show()
    let res = await FetchRequest('/wap/graduation/erollPay', {
      eroll_id,
      token: localStorage.getItem('token'),
      meal: payInfo.meal,
    })
    Loading.hide()
    if (res.code === 2) {
      store.routerStore.replace(
        `/build/graduation-select/${payInfo.activity_id}`
      )
      return
    }
    if (res.code === 1) {
      let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
        currenturl: window.location.href,
      })
      const { wx }: any = window
      const { appId, nonceStr, signature, timestamp, jsApiList } =
        SDKParams.data
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList, // 必填，需要使用的JS接口列表
      })

      wx.ready(function () {
        const { appId, nonceStr, order_id, paySign, signType, timestamp } =
          res.data.data
        wx.chooseWXPay({
          timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          package: res.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign: paySign, // 支付签名
          success: function (res: any) {
            // 支付成功后的回调函数
            //   alert("支付成功")
            store.routerStore.replace(`/build/graduation/${eroll_id}`)
          },
        })
      })

      wx.error(function (res: any) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        Toast.show('支付失败')
      })
    } else {
      Toast.show(res.msg)
    }
    Loading.hide()
  }

  return (
    <div>
      <Cell title="活动名称" description={payInfo.activity_name} />
      <Cell title="宝贝姓名" description={payInfo.baby_name} />
      <Cell title="报名电话" description={payInfo.phone} />
      <Cell title="套餐选择" description={`${payInfo.meal} 套餐`} />
      {/* <Cell title="原价" description="¥31.00" onClick={() => { }} /> */}
      <Cell
        title="实际支付"
        description={`¥${payInfo.price}`}
        onClick={() => {}}
      />
      <Button
        theme="primary"
        block
        style={styleBtnPay}
        onClick={handleClickPay}
      >
        立即支付
      </Button>
    </div>
  )
})
