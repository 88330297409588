import { format } from 'date-fns'
import React, { useState, useEffect } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  SwipeAction,
  Button,
  Cell,
  NoticeBar,
  Modal,
  Input,
  Toast,
  Loading,
  ActivityIndicator,
} from 'zarm'
import { FetchRequest } from '../../utils/fetch'
import './index.css'
import DownImages from '../../assets/down.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import CloseImages from '../../assets/close.png'
import { getParamValue } from '../../utils/url'
import { observer } from 'mobx-react'
import { store } from '../../store/store.root'
import { withRouter } from 'react-router-dom'

type selectType = {
  activity_id?: number
  activity_name?: string
  baby_name?: string
  createtime?: number
  createtime_text?: string
  data: {
    cut_price: number
    sum_num: number
    total_price: number
    images: Array<{
      id: number
      or_w_img: string
      origin_img: string
    }>
  }
  datalist?: string
  email: null
  email_status: null
  eroll_id?: number
  meal_type?: string
  order_id?: number
  order_number?: number
  order_type?: number
  lasttime?: string
  pay_status?: string
  pay_time?: number
  phone?: string
  prepay_id?: string
  price?: string
  transaction_id?: null
}

type imagesType = {
  id: number
  or_w_img: string
  origin_img: string
  object?: string[]
}

export default withRouter(
  observer((props: any) => {
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false)
    const [visibleHelp, setVisibleHelp] = useState(false)
    const [visibleItem, setVisibleItem] = useState(false)
    const [visibleConfirm, setVisibleConfirm] = useState(false)
    const [visibleCopyConfirm, setVisibleCopyConfirm] = useState(false)
    // http://localhost:8000/build/film-download-new
    // 存放用户点击列表的数据
    const [detailItem, setDetailItem] = useState<any>({})

    useEffect(() => {
      document.title = '底片下载'
      Loading.show({
        content: <ActivityIndicator size="lg" />,
        stayTime: 3000,
      })
      requestData()
    }, [])

    async function requestData() {
      if (props.match.params.uid) {
        await getPlateList()
      } else {
        Toast.show('缺少用户id')
      }
    }

    // 存放底片数据
    const [FilmList, setFilmList] = useState([])
    // 存放头部滑动数据
    const [warpString, setWarpString] = useState('')
    // 存放按钮显示
    const [isBtn, setIsBtn] = useState(false)
    const [isBtntext, setisBtntext] = useState('')
    // 存放选片数据
    const [selectPick, setselectPick] = useState<selectType[]>([])
    // 存放选片数据
    const [NewselectPick, setNewSelectPick] = useState<selectType[]>([])
    // 轮播展示
    const [SwiperStatus, setSwiperStatus] = useState(false)
    // 轮播数据
    const [SwiperData, setSwiperData] = useState<imagesType[]>([])
    // 轮播图当前索引
    const [activeIndex, setActiveIndex] = useState(0)

    /**
     * 底片下载列表
     */
    async function getPlateList() {
      let PlateRes = await FetchRequest('/wap/plate/getPlateListByUid', {
        uid: props.match.params.uid,
      })
      if (PlateRes.code === 1) {
        if (PlateRes.data === null) {
          setFilmList([])
          setWarpString('')
          setIsBtn(false)
        } else {
          setFilmList(PlateRes.data.plate_list)
          setWarpString(PlateRes.data.notice)
          setIsBtn(PlateRes.data.is_show === 0 ? false : true)
          setisBtntext(PlateRes.data.button_name)
          /////////
          localStorage.setItem('token', PlateRes.data.token)
          store.userStore.changeToken(PlateRes.data.token)
          store.userStore.getUserInfo()
          await getNewPlateList()
          await getExchangeDescribtion()
          await getHelp()
        }
      } else {
        Toast.show('底片列表请求失败')
      }
      Loading.hide()
    }

    /**
     * 选片
     */
    async function getNewPlateList() {
      // 权限判断
      // if (store.websiteRuleStore.has_module("film-selection")) {
      let res = await FetchRequest('/wap/selectioneroll/getFilmSelection', {
        token: localStorage.getItem('token'),
      })
      if (res.code === 1) {
        setselectPick(res.data)
        // 路由获取判定参数
        const name = getParamValue('name')
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i]
          if (item.baby_name === name) {
            setSwiperStatus(true)
            setSwiperData(item.data.images)
          }
        }
      } else {
        Toast.show('选片列表请求失败')
      }
      getNewgraduation()
    }

    async function getNewgraduation() {
      let res = await FetchRequest('/wap/graduation/getFilmSelection', {
        token: localStorage.getItem('token'),
      })
      if (res.code === 1) {
        setNewSelectPick(res.data)
        // 路由获取判定参数
        const name = getParamValue('name')
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i]
          if (item.activity_name === name) {
            setSwiperStatus(true)
            setSwiperData(item.data.images)
          }
        }
      } else {
        Toast.show('选片列表请求失败')
      }
    }

    const [alertDesc, setAlertDesc] = useState('')

    /**
     * 获取弹窗说明
     */
    async function getExchangeDescribtion() {
      let DescribtionRes = await FetchRequest(
        '/wap/plate/getExchangeDescribtion',
        {
          token: localStorage.getItem('token'),
        }
      )
      if (DescribtionRes.code === 1) {
        setAlertDesc(DescribtionRes.data)
      } else {
        Toast.show('获取弹窗说明失败')
      }
    }

    const [HelpDesc, setHelpDesc] = useState('')

    /**
     * 获取帮助
     */
    async function getHelp() {
      let HelpRes = await FetchRequest('/wap/plate/getHelp', {
        token: localStorage.getItem('token'),
      })
      if (HelpRes.code === 1) {
        setHelpDesc(HelpRes.data)
      } else {
        Toast.show('获取帮助失败')
      }
    }

    // 存放提取码
    const [FilmString, setFilmString] = useState('')

    /**
     * 提取码下载
     */
    async function getExchange() {
      if (!FilmString) {
        Toast.show('请填写提取码')
        return
      }
      let ExchangeRes = await FetchRequest('/wap/plate/getExchange', {
        token: localStorage.getItem('token'),
        plate_code: FilmString,
      })
      if (ExchangeRes.code === 1) {
        Toast.show(ExchangeRes.msg)
        // 刷新列表
        getPlateList()
        // 清空输入框
        setFilmString('')
        // 关闭弹窗
        setVisible(false)
      } else {
        Toast.show(ExchangeRes.msg)
      }
    }

    // 存放待删除的id
    const [deleteID, setDeleteID] = useState<number | undefined>(undefined)

    /**
     * 确认删除
     */
    async function submitPlateDelete() {
      let deleteRes = await FetchRequest('/wap/plate/submitPlateDelete', {
        token: localStorage.getItem('token'),
        plate_id: deleteID,
      })
      if (deleteRes.code === 1) {
        Toast.show(deleteRes.msg)
        // 刷新列表
        getPlateList()
        // 关闭
        setVisibleConfirm(false)
      } else {
        Toast.show(deleteRes.msg)
      }
    }

    function handleGetImages(SwiperData: imagesType[]): imagesType[] {
      const list: imagesType[] = []
      const theNumberOfTheNeedToDisplay = activeIndex + 2
      for (let i = 0; i < SwiperData.length; i++) {
        if (i < theNumberOfTheNeedToDisplay) {
          list[i] = SwiperData[i]
          // list.push(SwiperData[i])
        } else {
          list.push({
            id: 0,
            or_w_img: '',
            origin_img: '',
          })
        }
      }
      return list
    }

    if (SwiperStatus) {
      return (
        <div className="fixBgP">
          <Swiper
            className="main-container"
            // effect="coverflow"
            pagination={{
              clickable: true,
              type: 'fraction',
            }}
            scrollbar={{ draggable: true }}
            coverflowEffect={{ slideShadows: false }}
            onSlideChange={function (swiper) {
              if (activeIndex < swiper.activeIndex) {
                setActiveIndex(swiper.activeIndex)
              }
            }}
          >
            {handleGetImages(SwiperData).map((value, index) => (
              <SwiperSlide key={index} className="swiper-item">
                <div className="select_text">
                  <div className="text_tag">{value.object?.join(',')}</div>
                  <div className="text_tipes">长按保存图片</div>
                </div>
                <img src={value.origin_img} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
          <img
            src={CloseImages}
            alt=""
            className="fixClose"
            onClick={() => setSwiperStatus(false)}
          />
        </div>
      )
    }

    return (
      <div className="film_download">
        {/* 头部滚动 */}
        {warpString && (
          <NoticeBar className="NoticeBar_warp">{warpString}</NoticeBar>
        )}
        {/* 无底片提示信息 */}
        {FilmList.length === 0 &&
          selectPick.length === 0 &&
          NewselectPick.length === 0 && (
            <div className="message_null">
              您没有可查看的底片,请查看帮助或联系客服
            </div>
          )}

        {/* 笑脸墙 */}
        {selectPick.map((v) => {
          return (
            <Cell
              key={v.order_number}
              style={{
                width: '100%',
                marginTop: 10,
              }}
              title={
                <div className="box-content">
                  <div className="box-title">活动名称: {v.activity_name}</div>
                  <div className="box-description">宝宝姓名: {v.baby_name}</div>
                  <div
                    className="box-description-text"
                    style={{ color: 'red' }}
                  >
                    {v.lasttime} 此时间后系统将自动删除底片
                  </div>
                </div>
              }
              icon={
                <div>
                  <img src={DownImages} alt="" style={{ width: 45 }} />
                  <div style={{ fontSize: 12 }}>点击下载</div>
                </div>
              }
              onClick={() => {
                if (v.data.images.length === 0) {
                  Toast.show('暂无图片')
                  return
                }
                setSwiperStatus(true)
                setSwiperData(v.data.images)
              }}
            />
          )
        })}

        {/* 毕业季 */}
        {NewselectPick.map((v) => {
          return (
            <Cell
              key={v.order_number}
              style={{
                width: '100%',
                marginTop: 10,
              }}
              title={
                <div className="box-content">
                  <div className="box-title">活动名称: {v.activity_name}</div>
                  <div className="box-description">宝宝姓名: {v.baby_name}</div>
                  <div
                    className="box-description-text"
                    style={{ color: 'red' }}
                  >
                    {v.lasttime} 此时间后系统将自动删除底片
                  </div>
                </div>
              }
              icon={
                <div>
                  <img src={DownImages} alt="" style={{ width: 45 }} />
                  <div style={{ fontSize: 12 }}>点击下载</div>
                </div>
              }
              onClick={() => {
                if (v.data.images.length === 0) {
                  Toast.show('暂无图片')
                  return
                }
                setSwiperStatus(true)
                setSwiperData(v.data.images)
              }}
            />
          )
        })}

        {/* 列表 */}
        {FilmList.map(
          (v: {
            id: number
            create_time: null | number
            url: string
            username: string
          }) => {
            return (
              <SwipeAction
                className="swipe_action"
                key={v.id}
                right={[
                  <Button
                    size="lg"
                    key={v.id}
                    shape="rect"
                    theme="danger"
                    style={{ height: '100%' }}
                    onClick={() => {
                      setDeleteID(v.id)
                      setVisibleConfirm(true)
                    }}
                  >
                    确认删除
                  </Button>,
                ]}
              >
                <Cell
                  style={{
                    width: '100%',
                  }}
                  title={
                    <div className="box-content">
                      <div className="box-title">客户姓名: {v.username}</div>
                      {v.create_time && (
                        <div className="box-description">
                          上传时间: {format(v.create_time * 1000, 'yyyy-MM-dd')}
                        </div>
                      )}
                    </div>
                  }
                  icon={
                    <div>
                      <img src={DownImages} alt="" style={{ width: 45 }} />
                      <div style={{ fontSize: 12 }}>点击下载</div>
                    </div>
                    // <AliIcon type="iconpicture" style={{ fontSize: "3em" }} />
                  }
                  onClick={() => {
                    setDetailItem(v)
                    setVisibleItem(true)
                  }}
                />
              </SwipeAction>
            )
          }
        )}

        {/* 展示图 */}
        {/* {SwiperStatus && (
        <div className="fixBgP">
          <Swiper
            className="main-container"
            effect="coverflow"
            pagination={{
              clickable: true,
              type: 'fraction',
            }}
            scrollbar={{ draggable: true }}
            coverflowEffect={{ slideShadows: false }}
          >
            {SwiperData.map((value, index) => (
              <SwiperSlide key={index} className="swiper-item">
                <div className="select_text">
                  <div className="text_tag">{value.object?.join(',')}</div>
                  <div className="text_tipes">长按保存图片</div>
                </div>
                <ImageVant lazyload src={value.origin_img} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
          <img
            src={CloseImages}
            alt=""
            className="fixClose"
            onClick={() => setSwiperStatus(false)}
          />
        </div>
      )} */}

        <div className="filmDownload_btn">
          {isBtn && (
            <Button theme="primary" onClick={() => setVisible(true)}>
              {isBtntext}
            </Button>
          )}
          <Button
            onClick={() => setVisibleHelp(true)}
            style={!isBtn ? { width: '100%' } : {}}
          >
            帮助
          </Button>
        </div>
        {/* 输入码弹窗 */}
        <Modal
          title="请输入提取码"
          visible={visible}
          footer={
            <div className="moda_btn">
              <Button
                block
                onClick={() => {
                  setVisible(false)
                  setFilmString('')
                }}
              >
                取消
              </Button>
              <Button block theme="primary" onClick={getExchange}>
                确认
              </Button>
            </div>
          }
        >
          <Input
            placeholder="请输入提取码"
            value={FilmString}
            onChange={(e: any) => setFilmString(e)}
          />
        </Modal>
        {/* 帮助文档 */}
        <Modal
          title="帮助"
          visible={visibleHelp}
          footer={
            <Button block theme="primary" onClick={() => setVisibleHelp(false)}>
              确认
            </Button>
          }
        >
          <div style={{ overflowX: 'scroll' }}>
            <div
              className="help_content"
              dangerouslySetInnerHTML={{ __html: HelpDesc }}
            ></div>
          </div>
        </Modal>
        {/* 点击底片列表 */}
        <Modal
          title={'底片下载'}
          visible={visibleItem}
          maskClosable={true}
          width={'75%'}
          onCancel={() => setVisibleItem(false)}
          // className={"photographic-plate-download-modal"}
          closable={true}
        >
          <div>
            <p
              style={{ paddingBottom: 15 }}
              dangerouslySetInnerHTML={{ __html: alertDesc }}
            ></p>
            <div className="button-row-down">
              <Button
                block
                theme="primary"
                onClick={() => {
                  window.open(detailItem.url)
                }}
              >
                单张下载
              </Button>
              <Button
                block
                onClick={() => {
                  setVisibleCopyConfirm(true)
                }}
              >
                批量下载
              </Button>
            </div>
          </div>
        </Modal>
        {/* 批量删除弹窗 */}
        <Modal
          title={'批量下载'}
          visible={visibleCopyConfirm}
          maskClosable={true}
          width={'80%'}
          onCancel={() => setVisibleCopyConfirm(false)}
          closable={true}
        >
          <div className="photographic-plate-download-modal">
            <p>请复制云盘地址通过电脑浏览器打开</p>
            <p>可进行批量操作</p>
            <p>云盘地址:</p>
            <p id="url">{detailItem.url}</p>
            <CopyToClipboard
              text={detailItem.url}
              onCopy={() => {
                Toast.show('复制成功')
                setVisibleCopyConfirm(false)
                setVisibleItem(false)
              }}
            >
              <Button theme="primary" className="button">
                点击复制
              </Button>
            </CopyToClipboard>
          </div>
        </Modal>
        {/* 删除确认 */}
        <Modal
          title="确认删除该底片吗?"
          visible={visibleConfirm}
          footer={
            <div className="moda_btn">
              <Button
                block
                className="delete_film_button"
                onClick={() => {
                  setVisibleConfirm(false)
                  setDeleteID(undefined)
                }}
              >
                取消
              </Button>
              <Button
                block
                theme="primary"
                className="delete_film_button"
                onClick={submitPlateDelete}
              >
                确认
              </Button>
            </div>
          }
        />
      </div>
    )
  })
)
