import React, { useState, useEffect } from "react";
import { Button, Modal, Tabs, Toast } from "zarm";
import "./style.css";
import style from "./index.module.scss";
import HotImages from "../../assets/hot.png";
import { BangBangTangLayout } from "../../layout/layout";
import { store } from "../../store/store.root";
import { FetchRequest } from "../../utils/fetch";
import EwmImages from "../../assets/ewm.png";
import { getParamValue } from "../../utils/url";
import { handleOnChangShare } from "../../utils";
import { AppletGift } from "../../utils/AppletGift";
import { observer } from "mobx-react"

const { Panel } = Tabs;

export default observer(() => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    document.title = "积分兑换礼品中心"
    store.globalUiStore.changeCurrentActive("gift-center");
    // if (store.userStore.token) {
    //   GetGiftInitDate();
    //   GetCashList();
    // }
  }, []);

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (data.length === 0 && store.userStore.token && !isLoading) {
      setIsLoading(true)
      GetGiftInitDate();
      GetCashList();
    }
  }, [store.userStore.token])

  useEffect(() => {
    if ((store.websiteRuleStore.staff_auth || []).indexOf("staff_center") > -1 ) {
      GetCodeQR()
    }
  },[store.websiteRuleStore.staff_auth])

  const [data, setData] = useState([]);

  const [SharImages, setSharImages] = useState("")

  const [share_url, setshare_url] = useState(location.href)

  const [select_index, setSelect_index] = useState<number>(0);
  const [Cash, setCash] = useState<{
    can_exchange_cash?: number;
    exchange_cash_explain?: string;
    exchange_cash_gear?: string[];
    total_point?: number;
  }>({});

  async function GetGiftInitDate() {
    if (data.length > 0) return
    let result = await FetchRequest(
      `/wap/marketing/transfermall/Goods/getLists?token=${localStorage.getItem(
        "token"
      ) || store.userStore.token}&saleman_id=${getParamValue("saleman_id") || 0}`,
      {},
      "GET"
    );
    if (result.code === 1) {
      setData(result.data);
      if (getParamValue("giftpoint") === "true" && !isLoading) {
        handlePoints()
      }
    }
  }

  async function GetCodeQR() {
    const images_Code = await FetchRequest(
      "/wap/marketing/Transfer/getStaffShareQr",
      {
        token: localStorage.getItem("token"),
      }
    );
    if (images_Code.code === 1) {
      setSharImages(images_Code.data.qrimage)
      setshare_url(images_Code.data.share_url)
    } else {
      Toast.show(images_Code.msg);
    }
  }

  async function GetCashList() {
    let result = await FetchRequest(
      `/wap/marketing/transfermall/cash/getCashList?token=${localStorage.getItem(
        "token"
      )}`,
      {},
      "GET"
    );
    if (result.code === 1) {
      setCash(result.data);
    }
  }

  async function handlePoints() {
    let result = await FetchRequest(
      `/wap/marketing/transfer/ifNoticeGiftPoints?token=${localStorage.getItem(
        "token"
      )}`,
      {},
      "GET"
    );
    if (result.code === 1) {
      if (result.data.need_notice === "yes") {
        const modal = Modal.confirm({
          content: `您可领取${result.data.points}积分`,
          onCancel: () => {
            console.log('点击cancel');
          },
          onOk: async () => {
            let res = await FetchRequest(
              `/wap/marketing/transfer/getNoticeGiftPoints?token=${localStorage.getItem(
                "token"
              )}`,
              {},
              "GET"
            );
            if (res.code === 1) {
              Toast.show(res.msg)
            } else {
              Toast.show(res.msg)
            }
          },
        });
      }
    }
  }

  function handleClickSubFrom() {
    const modal = Modal.confirm({
      title: "确认信息",
      content: "确认兑换吗？",
      onCancel: () => {
        console.log("点击cancel");
      },
      onOk: async () => {
        let result = await FetchRequest(
          "/wap/marketing/transfermall/cash/exchangeCash",
          {
            cash: Cash.exchange_cash_gear![select_index],
            token: localStorage.getItem("token"),
          }
        );
        Toast.show(result.msg);
      },
    });
  }
  if (data.length === 0) {
    return (
      <BangBangTangLayout showTabBar={true}>
        <div style={{ textAlign: "center", fontSize: 15, marginTop: 30 }}>
          加载中
        </div>
      </BangBangTangLayout>
    );
  }
  return (
    <BangBangTangLayout showTabBar={true}>
      <Tabs scrollable value={value} onChange={(e) => setValue(Number(e))}>
        {data?.map(
          (
            v: {
              cat_id: number;
              cat_name: string;
              is_cash_cat: "no" | "yes";
              lists: {
                id: number;
                show_image: string;
                gift_name: string;
                coupon: {
                  validity: string;
                };
                stock: number;
                heats: number;
                points: number;
              }[];
            },
            index
          ) => {
            if (v.cat_name === "兑换现金" && v.is_cash_cat === "yes") {
              return (
                <Panel title={v.cat_name} key={index}>
                  <div className={style.Content_tab}>
                    <div className={style.content_b}>
                      <div className={style.list_count}>
                        <div className={style.row_n}>
                          <span>我的积分:</span>
                          <span className={style.num}>{Cash.total_point}</span>
                          <span>分</span>
                        </div>
                        <div className={style.row_n}>
                          <span>可以兑换:</span>
                          <span className={style.num}>
                            {Cash.can_exchange_cash}
                          </span>
                          <span>元</span>
                        </div>
                      </div>

                      <div className={style.exchange_content}>
                        <div className={style.title}>请选择兑换金额</div>
                        <div className={style.list_amount}>
                          {Cash.exchange_cash_gear?.map((v, index) => {
                            return (
                              <div
                                key={index}
                                className={style.item}
                                style={
                                  select_index === index
                                    ? {
                                        border: "1px solid #00B26A",
                                        color: "#00B26A",
                                      }
                                    : {}
                                }
                                onClick={() => setSelect_index(index)}
                              >
                                {v}元
                              </div>
                            );
                          })}
                        </div>
                        <div className={style.list_desc}>
                          <div className={style.label_text}>兑换说明: </div>
                          <div className={style.text_desc}>
                            {Cash.exchange_cash_explain}
                          </div>
                        </div>
                      </div>

                      <div className={style.btn_f}>
                        <Button
                          className={style.btn_grif}
                          theme="primary"
                          size="sm"
                          onClick={handleClickSubFrom}
                        >
                          立即兑换
                        </Button>
                      </div>
                    </div>
                  </div>
                </Panel>
              );
            }
            return (
              <Panel title={v.cat_name} key={index}>
                <div className={style.Content_tab}>
                  <div className={style.list}>
                    {v.lists.map((s, s_index) => {
                      return (
                        <div
                          className={style.item}
                          key={s_index}
                          onClick={() => {
                            store.routerStore.push(
                              `/build/gift-center/${s.id}`
                            );
                          }}
                        >
                          <div className={style.images_title}>
                            <img src={s.show_image} alt="" />
                          </div>
                          <div className={style.info_item}>
                            <div className={style.title}>{s.gift_name}</div>
                            {!Array.isArray(s.coupon) && (
                              <div
                                className={style.desc_info}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    width: 60,
                                    textAlignLast: "justify",
                                    display: "inline-block",
                                  }}
                                >
                                  有效期:
                                </div>
                                <div
                                  style={{
                                    width: "60%",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {s.coupon.validity}
                                </div>
                              </div>
                            )}

                            <div className={style.desc_info}>
                              <span
                                style={{
                                  width: 60,
                                  textAlignLast: "justify",
                                  display: "inline-block",
                                }}
                              >
                                剩余数量:
                              </span>
                              <span
                                style={{
                                  width: 60,
                                  textAlignLast: "justify",
                                  display: "inline-block",
                                }}
                              >
                                {s.stock}
                              </span>
                            </div>
                            <div
                              className={style.desc_info}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                style={{
                                  width: 60,
                                  textAlignLast: "justify",
                                  display: "inline-block",
                                }}
                              >
                                热度:
                              </span>
                              {Array.from(Array(s.heats), (v, k) => k).map(
                                (_) => {
                                  return (
                                    <img
                                      key={_}
                                      src={HotImages}
                                      style={{ width: 15, height: 15 }}
                                      alt=""
                                    />
                                  );
                                }
                              )}
                            </div>
                            <div className={style.desc_integral}>
                              需要积分
                              <span className={style.action_size_font}>
                                {s.points}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Panel>
            );
          }
        )}
      </Tabs>

      { (store.websiteRuleStore.staff_auth || []).indexOf("staff_center") > -1 ? (
        share_url && (
          <AppletGift shar={{
            title: "积分兑换礼品中心",
            link: share_url,
            desc: "感谢您的信任与支持，千元礼包等您来取！",
          }} />
        )
      ) : (
        <AppletGift shar={{
          title: "积分兑换礼品中心",
          link: share_url,
          desc: "感谢您的信任与支持，千元礼包等您来取！",
        }} />
      ) }
      {/* 获取二维码 */}
      {(store.websiteRuleStore.staff_auth || []).indexOf("staff_center") > -1 && (
        <div
          className={style.get_points_code}
          onClick={async () => {
            const modal = Modal.alert({
              title: "礼品中心",
              content: (
                <div>
                  <img
                    src={SharImages}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              ),
              onCancel: () => {
                modal.hide();
              },
            });
          }}
        >
          <img src={EwmImages} alt="" style={{ width: "100%" }} />
        </div>
      )}
      {/* 获取积分 */}
      <div
        className={style.get_points}
        onClick={() => {
          store.routerStore.push("/build/mall");
        }}
      >
        积分
        <br />
        规则
      </div>
    </BangBangTangLayout>
  );
});
