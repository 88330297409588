import React from "react";

export class PreorderClotheStatusRender extends React.Component<
  { value: string },
  any
> {
  render() {
    let str: string;
    let font_color: string = "";
    switch (this.props.value) {
      case "committed":
        str = "已提交";
        font_color = "#349FEB";
        break;
      case "cancel":
        str = "已取消";
        font_color = "#BABABA";
        break;
      case "uncommitted":
        str = "未提交";
        font_color = "#FF5050";
        break;
      case "expired":
        str = "已过期";
        font_color = "#BABABA";
        break;
      default:
        font_color = "black";
        str = this.props.value;
    }
    return <span style={{ color: font_color }}>{str}</span>;
  }
}
