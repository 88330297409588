import React, { useEffect, useState } from "react";
import { BangBangTangRequest } from "../../../utils/request";
import { store } from "../../../store/store.root";
import { Button, Calendar, Cell, DatePicker, Input, Modal } from "zarm";
import { finalize } from "rxjs/operators";
import format from "date-fns/format";
import { differenceInMilliseconds } from "date-fns";
import { toast } from "react-toastify";
import { appIsFirstReadySubject } from "../../../store/login-complete-subject";

interface Props {
  feedback_id: number;
}
interface State {
  res: {
    feedback_id: number;
    service_telephone: string;
  };
  note_value: string;
  disabled: boolean;
  datetime: Date | null;
  picker_visible: boolean;
}

export class RetrieveItem extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      disabled: false,
      note_value: "",
      datetime: null,
      picker_visible: false,
      res: {
        feedback_id: 0,
        service_telephone: "",
      },
    };
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      if (this.props.feedback_id) {
        this.setState({ disabled: true });
        BangBangTangRequest(
          {
            url: "/wap/data/getPickupNotice",
            data: {
              token: store.userStore.token,
              feedback_id: this.props.feedback_id,
            },
            method: "POST",
          },
          { disableErrorHandler: true }
        )
          .pipe(finalize(() => this.setState({ disabled: false })))
          .subscribe(
            (value) => {
              this.setState({ res: value });
            },
            () => setTimeout(() => store.routerStore.replace("/build"), 1)
          );
      }
    });
  }

  render() {
    return (
      <div className="content arrive-shop-confirm">
        <Modal
          visible={this.state.picker_visible}
          width={"90%"}
          onCancel={() => this.setState({ picker_visible: false })}
          maskClosable={true}
        >
          <Calendar
            className="calendar"
            multiple={false}
            value={this.state.datetime || new Date()}
            min={new Date()}
            onChange={(value) => {
              if (value) {
                this.setState({ datetime: value[0], picker_visible: false });
              }
            }}
          />
        </Modal>
        <div
          className="date-picker-row"
          onClick={() => this.setState({ picker_visible: true })}
        >
          <span className="picker-text">请选择预计到店取件时间</span>
          <span className="picker">
            {this.state.datetime
              ? format(this.state.datetime, "yyyy-MM-dd")
              : "点我选择"}
          </span>
        </div>
        <p className="message-title-retr">系统在您选择的预计到店时间之前不再发送提醒</p>
        <p className="textarea-note">给客服留言</p>
        <Input
          className="textarea"
          autoHeight
          type="text"
          rows={3}
          placeholder="请在此输入您想交代客服的话，可不填"
          value={this.state.note_value}
          onChange={this.changeTextArea}
          maxLength={190}
        />
        <a
          className="phone-row"
          href={`tel:${this.state.res.service_telephone}`}
        >
          <span className="iconfont">&#xe601;</span>拨打客服电话
        </a>
        <div className="button-row">
          <Button
            theme="primary"
            onClick={this.confirm}
            disabled={this.state.disabled || !this.dataIsValid()}
          >
            提交
          </Button>
        </div>
      </div>
    );
  }

  dataIsValid = (): boolean => {
    console.log(
      differenceInMilliseconds(this.state.datetime || new Date(), new Date())
    );
    return (
      this.state.note_value.length < 190 &&
      !!this.state.datetime &&
      differenceInMilliseconds(this.state.datetime, new Date()) > 0
    );
  };

  private readonly confirm = () => {
    this.setState({ disabled: true });
    BangBangTangRequest({
      url: "/wap/data/submitPickupNotice",
      method: "POST",
      data: {
        token: store.userStore.token,
        feedback_id: this.props.feedback_id,
        feedback_word: this.state.note_value,
        feedback_date: format(this.state.datetime!, "yyyy-MM-dd"),
      },
    })
      .pipe(finalize(() => this.setState({ disabled: false })))
      .subscribe(() => {
        toast("反馈成功,谢谢!");
        store.routerStore.replace("/build");
      });
  };

  private readonly changeTextArea = (value?: string) => {
    this.setState({
      note_value: value || "",
      disabled: !!value && value.length > 190,
    });
  };
}
