import React from "react";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";
import { FunctionalComponentHeader } from "../../pages/index/components/functional-component-header/functional-component-header";
import "./customer-query-preorder-clothe-list-widget.scss";
import { Button } from "zarm";
import { preorderScheduleActions } from "../../store/preorder-schedule/preorder-schedule.action";

@observer
export class CustomerQueryPreorderClotheWidget extends React.Component<
  any,
  any
> {
  componentDidMount() {
    if (
      store.preorderScheduleStore.schedule_already_preorder.choices === null
    ) {
      preorderScheduleActions.getUserScheduleData();
    } else {
    }
  }

  render() {
    if (
      store.preorderScheduleStore.schedule_already_preorder.choices &&
      store.preorderScheduleStore.schedule_already_preorder.choices.length > 0
    ) {
      return (
        <div className={"customer-query-preorder-clothe-list-widget"}>
          <div className="divider" />
          <div>
            <FunctionalComponentHeader title={"我的预约安排"} />
            {store.preorderScheduleStore.schedule_already_preorder.choices!.map(
              (value) => {
                return (
                  <div key={value.id} className="card">
                    <div
                      className="left"
                      onClick={() =>
                        store.routerStore.push(
                          "/build/my-preorder-detail/" + value.id
                        )
                      }
                    >
                      <div className="col">
                        <div>{value.store_name}</div>
                        <div>{value.type_name}</div>
                      </div>
                      <div className="col">
                        <div>{value.schedule_date}</div>
                        <div>{value.schedule_time}</div>
                      </div>
                    </div>
                    {value.selection_id ? (
                      <Button
                        theme="primary"
                        onClick={() =>
                          store.routerStore.push(
                            `/build/customer-preorder-clothe-detail?id=${value.selection_id}`
                          )
                        }
                      >
                        点击选服装
                      </Button>
                    ) : null}
                  </div>
                );
              }
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
