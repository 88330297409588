import React from "react";
import { BangBangTangLayout } from "../../layout/layout";
import style from "./index.module.scss";
import { Tabs, Badge, Cell, Pull, ActivityIndicator } from "zarm";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";
import { finalize } from "rxjs/operators";
import { RuleListRequired } from "../../components/module-required/rule-list-required";
import { ValidWebsiteRuleListString } from "../../store/website-rule/website-rule.store";
import { AliIcon } from "../../layout/bangbangtang-tab-bar";
const { Panel } = Tabs;

export class ExecutiveManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reformListCount: 0,
      reformAdjustList: 0,
    };
  }

  static propTypes = {
    history: PropTypes.object,
  };

  titleShow = (title, count) => {
    return (
      <div className={style.comments_tab_title}>
        <span className="tab-title">{title}</span>
        {count !== 0 && <Badge shape="round" text={count} />}
      </div>
    );
  };
  componentDidMount() {
    window.document.title = "执行管理";
    this.getReformList();
    if (store.websiteRuleStore.has_rule("sanction_adjust_reform")) {
    this.getReformAdjustList();
    }
  }

  getReformList = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getReformList",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        page: 1,
        reform_status: 0,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        console.log(result.total);
        that.setState({
          reformListCount: result.total[0],
        });
      });
  };

  getReformAdjustList = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getReformAdjustList",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        page: 1,
        reform_status: 0,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        console.log(result.total);
        that.setState({
          reformAdjustList: result.total[0],
        });
      });
  };

  render() {
    const { reformListCount, reformAdjustList } = this.state;
    return (
      <BangBangTangLayout title={"执行管理"}>
        <div className={style.container}>
          <RuleListRequired name={"sanction_award"}>
          <Cell
            hasArrow
            title="开奖励单"
            icon={<AliIcon type="iconquanbudingdan" />}
            onClick={() => {
              this.props.history.push("./draw-award");
            }}
          />
          </RuleListRequired>
          <RuleListRequired name={"sanction_reform"}>
           <Cell
            hasArrow
            title="开工作单"
            icon={<AliIcon type="iconquanbudingdan" />}
            onClick={() => {
              this.props.history.push("./draw-rectification");
            }}
          />
          </RuleListRequired>
          <RuleListRequired name={"sanction_adjust_award"}>
          <Cell
            hasArrow
            title="审核奖励单"
            icon={<AliIcon type="icondingdanwancheng" />}
            onClick={() => {
              this.props.history.push("./bonus-penalty");
            }}
          />
          </RuleListRequired>
          <RuleListRequired name={"sanction_adjust_reform"}>
            <Cell
              hasArrow
              title={
                <div className={style.badge}>
                  <span className="box-text">审核工作单</span>
                  {reformAdjustList > 0 ? (
                    <Badge shape="round" text={reformAdjustList}></Badge>
                  ) : null}
                </div>
              }
              icon={<AliIcon type="icondingdanwancheng" />}
              onClick={() => {
                this.props.history.push("./rectification-audit");
              }}
            />
          </RuleListRequired>
          <Cell
            hasArrow
            title={
              <div className={style.badge}>
                <span className="box-text">我的工作单</span>
                {reformListCount > 0 ? (
                  <Badge shape="round" text={reformListCount}></Badge>
                ) : null}
              </div>
            }
            icon={<AliIcon type="icondingdan1" />}
            onClick={() => {
              this.props.history.push("./rectification");
            }}
          />
          <Cell
            hasArrow
            title="执行力排行"
            icon={<AliIcon type="iconjiangtai" />}
            onClick={() => {
              this.props.history.push("./executive-force");
            }}
          />
        </div>
      </BangBangTangLayout>
    );
  }
}
export default withRouter(ExecutiveManagement);
