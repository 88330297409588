import React, { useEffect, useState } from 'react'
import { FetchRequest } from '../../utils/fetch'
import {
  Toast,
  Tabs,
  Cell,
  Pull,
  Modal,
  Loading,
  ActivityIndicator,
  Popup,
} from 'zarm'
import styles from './index.module.scss'

const { Panel } = Tabs

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
}

export default () => {
  useEffect(() => {
    document.title = '档期预约'
    // handleGetUserBookingList()
  }, [])

  const [isContent, setIsContent] = useState(false)

  const [page, setPage] = useState(1) // 分页
  const [status, setStatus] = useState(0) // 1=当前申请,2=预约成功,3=未拍完,4=已拍完
  const [userBookingList, setUserBookingList] = useState([])

  const [loading, setLoading] = useState(LOAD_STATE.normal)

  async function handleGetUserBookingList() {
    setLoading(LOAD_STATE.loading)
    const res = await FetchRequest('/wap/schedule/getUserBookingList', {
      token: localStorage.getItem('token'),
      page,
      status: status + 1,
    })
    setLoading(LOAD_STATE.success)
    if (res.code === 1) {
      if ((res.data as any[]).length === 0) {
        setLoading(LOAD_STATE.complete)
        return
      }
      setPage(page + 1)
      setUserBookingList(userBookingList.concat(res.data))
    } else {
      Toast.show(res.msg)
    }
  }

  function handleCancelBooking(item: any) {
    const modal = Modal.confirm({
      title: '提水',
      content: `确认取消 ${item.schedule_date} ${item.schedule_time} 的档期吗？`,
      onCancel: () => {
        console.log('点击cancel')
      },
      onOk: async () => {
        Loading.show({
          content: <ActivityIndicator size="lg" />,
          stayTime: 3000,
        })
        const res = await FetchRequest(
          '/wap/schedule/cancelScheduleApplication',
          {
            token: localStorage.getItem('token'),
            booking_id: item.id,
          }
        )
        Loading.hide()
        if (res.code === 1) {
          Toast.show(res.msg)
          setPage(1)
          setUserBookingList([])
          handleGetUserBookingList()
        } else {
          Toast.show(res.msg)
        }
      },
    })
  }

  const [itemContent, setItemContent] = useState<any>({})

  async function handleGetScheduleContent(item: any) {
    Loading.show({
      content: <ActivityIndicator size="lg" />,
      stayTime: 3000,
    })
    const res = await FetchRequest('/wap/schedule/getUserBookingDetails', {
      token: localStorage.getItem('token'),
      booking_id: item.id,
    })
    Loading.hide()
    if (res.code === 1) {
      setItemContent(res.data)
      setIsContent(true)
    } else {
      Toast.show(res.msg)
    }
  }

  useEffect(() => {
    // setPage(1)
    setUserBookingList([])
    handleGetUserBookingList()
  }, [status])

  return (
    <div>
      <Tabs
        value={status}
        onChange={(i) => {
          setStatus(i || 0)
          setPage(1)
        }}
      >
        <Panel title="当前申请">
          <div className={styles.content}>
            <Pull
              load={{
                state: loading,
                distance: 200,
                handler: () => {
                  handleGetUserBookingList()
                },
              }}
            >
              {userBookingList.map((v: any) => {
                return (
                  <Cell
                    key={v.id}
                    title={
                      <div className={styles.item_title}>
                        <div>
                          宝宝姓名: {v.baby_name} / {v.baby_gender}
                        </div>
                        <div>宝宝生日: {v.baby_birthday}</div>
                        <div>
                          预约日期: {v.schedule_date} {v.schedule_time}
                        </div>
                      </div>
                    }
                    description={
                      <div className={styles.desc_info}>
                        <div>{v.status}</div>
                      </div>
                    }
                    onClick={() => handleGetScheduleContent(v)}
                  />
                )
              })}
            </Pull>
          </div>
        </Panel>
        <Panel title="预约成功">
          <div className={styles.content}>
            <Pull
              load={{
                state: loading,
                distance: 200,
                handler: () => {
                  handleGetUserBookingList()
                },
              }}
            >
              {userBookingList.map((v: any) => {
                return (
                  <Cell
                    key={v.id}
                    title={
                      <div className={styles.item_title}>
                        <div>
                          宝宝姓名: {v.baby_name} / {v.baby_gender}
                        </div>
                        <div>宝宝生日: {v.baby_birthday}</div>
                        <div>
                          预约日期: {v.schedule_date} {v.schedule_time}
                        </div>
                      </div>
                    }
                    // description={
                    //   <div className={styles.desc_info}>
                    //     <div
                    //       className={styles.Cancel_btn}
                    //       onClick={() => handleCancelBooking(v)}
                    //     >
                    //       取消预约
                    //     </div>
                    //   </div>
                    // }
                    onClick={() => handleGetScheduleContent(v)}
                  />
                )
              })}
            </Pull>
          </div>
        </Panel>
        <Panel title="未拍完">
          <div className={styles.content}>
            <Pull
              load={{
                state: loading,
                distance: 200,
                handler: () => {
                  handleGetUserBookingList()
                },
              }}
            >
              {userBookingList.map((v: any) => {
                return (
                  <Cell
                    key={v.id}
                    title={
                      <div className={styles.item_title}>
                        <div>
                          宝宝姓名: {v.baby_name} / {v.baby_gender}
                        </div>
                        <div>宝宝生日: {v.baby_birthday}</div>
                        <div>
                          预约日期: {v.schedule_date} {v.schedule_time}
                        </div>
                      </div>
                    }
                    description={
                      <div className={styles.desc_info}>
                        <div>{v.status}</div>
                      </div>
                    }
                    onClick={() => handleGetScheduleContent(v)}
                  />
                )
              })}
            </Pull>
          </div>
        </Panel>
        <Panel title="已拍完">
          <div className={styles.content}>
            <Pull
              load={{
                state: loading,
                distance: 200,
                handler: () => {
                  handleGetUserBookingList()
                },
              }}
            >
              {userBookingList.map((v: any) => {
                return (
                  <Cell
                    key={v.id}
                    title={
                      <div className={styles.item_title}>
                        <div>
                          宝宝姓名: {v.baby_name} / {v.baby_gender}
                        </div>
                        <div>宝宝生日: {v.baby_birthday}</div>
                        <div>
                          预约日期: {v.schedule_date} {v.schedule_time}
                        </div>
                      </div>
                    }
                    description={
                      <div className={styles.desc_info}>
                        <div>{v.status}</div>
                      </div>
                    }
                    onClick={() => handleGetScheduleContent(v)}
                  />
                )
              })}
            </Pull>
          </div>
        </Panel>
      </Tabs>
      <Popup
        visible={isContent}
        mask={true}
        onMaskClick={() => setIsContent(false)}
        direction="bottom"
        afterClose={() => {}}
      >
        <div className={styles.schedule_content_}>
          <div className={styles.schedule_content_close}>
            <div></div>
            <div
              className={styles.close_btn}
              onClick={() => setIsContent(false)}
            >
              关闭
            </div>
          </div>
          <div>
            <Cell
              title={
                <div className={styles.item_title}>
                  <div>
                    宝宝姓名: {itemContent.baby_name} /{' '}
                    {itemContent.baby_gender}
                  </div>
                  <div>宝宝生日: {itemContent.baby_birthday}</div>
                  <div>
                    预约日期: {itemContent.schedule_date}{' '}
                    {itemContent.schedule_time}
                  </div>
                </div>
              }
              description={
                <div className={styles.desc_info}>
                  <div>{itemContent.status}</div>
                </div>
              }
            />
          </div>
        </div>
      </Popup>
    </div>
  )
}
