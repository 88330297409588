import React, { useEffect, useState } from 'react'
import { Modal, Popup, Toast } from 'zarm'
import { store } from '../../store/store.root'
import { FetchRequest } from '../../utils/fetch'
import { getParamValue } from '../../utils/url'
import style from './style.module.scss'

export function VoucherList(props: { eroll_id: number; meal_type: string }) {
  // const eroll_id = getParamValue('eroll_id')
  // const meal_type = getParamValue('meal_type')
  const eroll_id = props.eroll_id
  const meal_type = props.meal_type

  const [menOpen, setMenOpen] = useState(false)

  const [data, setData] = useState<any>({})
  const [selectList, setselectList] = useState<any[]>([])

  const [detaileOpen, setDetaileOpen] = useState(false)
  const [detaile, setDetaile] = useState<any>({})

  useEffect(() => {
    handleGetData()
  }, [])

  async function handleGetData() {
    let res = await FetchRequest('/wap/selectioneroll/settle', {
      token: localStorage.getItem('token'),
      eroll_id,
      meal_type,
    })
    if (res.code === 1) {
      setData(res.data)
    } else {
      Toast.show(res.msg)
    }
  }

  function handleAddCoup(s: any) {
    if (selectList.filter((v) => v.id === s.id).length > 0) {
      Toast.show('请勿重复添加')
      return
    }
    if (selectList.length === data.voucher_max) {
      Toast.show(`最多只能选择${data.voucher_max}张卡券`)
      return
    }
    setselectList([...selectList, s])
    Toast.show('添加成功')
  }

  return (
    <div>
      <div className={style.title}>
        您选择的套餐可享受领取<span>{data.voucher_max}张</span>福利券的权益
      </div>
      <div className={style.list}>
        {(data.voucher || []).map((v: any, index: number) => {
          return (
            <div
              className={style.item}
              key={index}
              onClick={async () => {
                let res = await FetchRequest(
                  '/wap/coupon/getCouponDetailFilm',
                  {
                    token: localStorage.getItem('token'),
                    coupon_id: v.id,
                  }
                )
                if (res.code === 1) {
                  setDetaile(res.data)
                  setDetaileOpen(true)
                } else {
                  Toast.show(res.msg)
                }
              }}
            >
              <div className={style.left}>
                <img src={v.card_image} alt="" />
              </div>
              <div className={style.right}>
                <div className={style.title_d}>{v.card_name}</div>
                <div>{v.card_intro}</div>
                {/* <div>123123123123</div> */}
              </div>
              <div
                className={style.icon_add}
                onClick={(e) => {
                  e.stopPropagation()
                  handleAddCoup(v)
                }}
              >
                +
              </div>
            </div>
          )
        })}
      </div>
      <div className={style.menu_footer}>
        {/* <div
          className={style.left_footer}
          onClick={() =>
            store.routerStore.push(
              `/build/selection-activities-pay?eroll_id=${eroll_id}&meal_type=${meal_type}`
            )
          }
        >
          放弃领取
        </div> */}
        <div onClick={() => setMenOpen(true)}>
          已领 {selectList.length} / 可领 {data.voucher_max}
        </div>
        <div
          className={style.right_footer}
          onClick={() => {
            if (selectList.length === 0) {
              const modal = Modal.confirm({
                content: '您还有福利权益没有领取，是否放弃领取？',
                okText: '去领取',
                cancelText: '放弃领取',
                onCancel: () => {
                  store.routerStore.push(
                    `/build/selection-activities-pay?eroll_id=${eroll_id}&meal_type=${meal_type}&voucher=${selectList
                      .map((v) => v.id)
                      .join(',')}`
                  )
                },
                onOk: () => {},
              })
              return
            }
            store.routerStore.push(
              `/build/selection-activities-pay?eroll_id=${eroll_id}&meal_type=${meal_type}&voucher=${selectList
                .map((v) => v.id)
                .join(',')}`
            )
          }}
        >
          下一步
        </div>
      </div>
      <Popup
        visible={menOpen}
        onMaskClick={() => setMenOpen(false)}
        direction="bottom"
        afterClose={() => setMenOpen(false)}
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          <div className={style.close_icon} onClick={() => setMenOpen(false)}>
            关闭
          </div>
          <div className={style.Popup_Content}>
            <div className={style.list}>
              {(selectList || []).map((v: any, index: number) => {
                return (
                  <div className={style.item} key={index}>
                    <div className={style.left}>
                      <img src={v.card_image} alt="" />
                    </div>
                    <div className={style.right}>
                      <div className={style.title_d}>{v.card_name}</div>
                      <div>{v.card_intro}</div>
                    </div>
                    <div
                      className={style.icon_close}
                      onClick={() => {
                        const d: React.SetStateAction<any[]> = []
                        selectList.forEach((s) => {
                          if (s.id !== v.id) {
                            d.push(s)
                          }
                        })
                        setselectList(d)
                      }}
                    >
                      x
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        visible={detaileOpen}
        onMaskClick={() => setDetaileOpen(false)}
        direction="bottom"
        afterClose={() => setDetaileOpen(false)}
      >
        <div className={style.content_info}>
          <div className={style.title_c}>{detaile.card_name}</div>
          <img src={detaile.card_image} alt="" style={{ width: '100%' }} />
          {(detaile.shop_image || []).map((v: string, index: number) => {
            return <img key={index} src={v} alt="" style={{ width: '100%' }} />
          })}
          <div className={style.info_list}>
            <p>卡券介绍</p>
            {(detaile.intro || []).map((v: string, index: number) => {
              return (
                <div className={style.inter} key={index}>
                  *{v}
                </div>
              )
            })}
          </div>
          <div className={style.info_list}>
            <p>使用要求</p>
            <div>
              <span>使用次数:</span> {detaile.writeoff_count}
            </div>
            <div>
              <span>使用频率:</span> {detaile.rate}
            </div>
            <div>
              <span>有效期:</span> {detaile.validity}
            </div>
            <div>
              <span>核销方式:</span> {detaile.writeOffMethod}
            </div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: detaile.remark }}></div>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  )
}
