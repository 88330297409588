import React, { useState, useEffect, useRef } from "react";
import {
  Tabs,
  Button,
  Pull,
  Badge,
  Modal,
  Toast,
  ImagePreview,
  NoticeBar,
  Loading,
} from "zarm";
import { store } from "../../store/store.root";
import { FetchRequest } from "../../utils/fetch";
import style from "./index.module.scss";
import "./style.css";
import { withRouter } from "react-router-dom";
import { getParamValue } from "../../utils/url";
import { observer } from "mobx-react";

const { Panel } = Tabs;

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
};

type resListType = {
  id?: number;
  page_status?: string;
  upload_time_text?: string;
  is_overdue_upload_text?: string;
  create_time_text?: string;
  update_time_text?: string;
  auto_delete_time_text?: string;
  page_status_text?: string;
  notice_status_text?: string;
};

type resItemlistsType = Array<{
  id?: number;
  file_name?: string;
  page_image?: string;
  sign_image?: string;
  customer_suggestion?: string;
  page_status?: string;
  admin_id?: number;
  lists_id?: number;
  wait_time?: number;
  page_status_text?: string;
}>;

type resCountType = {
  wait_for_confirm?: number;
  confirmed?: number;
  revising?: number;
  total?: number;
  wait_for_revising?: number;
};

let scroll = 0;

export default withRouter(
  (props: {
    match: {
      params: {
        photopage_id: string;
      };
    };
  }) => {
    const baseParmas = {
      token: localStorage.getItem("token") || "",
      photopage_id: props.match.params.photopage_id,
    };

    const [value, setValue] = useState<number>(0);

    const [isBtnSub, setisBtnSub] = useState(false);

    const [needSubmit, setNeed_submit] = useState(false);

    useEffect(() => {
      document.title = "确认版面";
      setTimeout(() => {
        if (scrollCount) {
          document.documentElement.scrollTop = Number(scrollCount);
        }
      }, 1000);
      window.addEventListener("scroll", handleScroll);
    }, []);

    const tab = getParamValue("tab");

    const scrollCount = getParamValue("scroll");

    useEffect(() => {
      if (tab) {
        console.log("检测到有tab");
        setValue(Number(tab));
      }
    }, [tab]);

    const [count, setCount] = useState<resCountType>({
      confirmed: 0,
      revising: 0,
      total: 0,
      wait_for_confirm: 0,
      wait_for_revising: 0,
    });

    const [page_status, setpPage_status] = useState("");

    const [kefu_phone, setKefu_phone] = useState(null);

    function handleScroll() {
      //滚动条高度
      let scrollTop = document.documentElement.scrollTop; //滚动条滚动高度
      scroll = scrollTop;
    }

    /**
     * 送厂制作
     */
    async function handleClickSendFactoryManufacturing() {
      const modal = Modal.confirm({
        title: "确认信息",
        content: (
          <div style={{ textAlign: "center" }}>
            <div>
              您的设计稿已全部确认
              <br />
            </div>
            <div>
              点击确定后产品将送厂制作
              <br />
            </div>
            <div>
              送厂后设计稿将<span style={{ color: "red" }}>无法再次修改</span>
            </div>
          </div>
        ),
        onCancel: () => {
          console.log("点击cancel");
        },
        onOk: async () => {
          Loading.show();
          let res = await FetchRequest("/wap/Photopage/songchang", {
            token: localStorage.getItem("token"),
            lists_id: baseParmas.photopage_id,
          });
          if (res.code === 1) {
            Toast.show(res.msg);
          } else {
            Toast.show(res.msg);
          }
          Loading.hide();
          location.reload();
        },
      });
    }

    async function handleClickSubForm() {
      let res = await FetchRequest("/wap/Photopage/submitAll", {
        token: localStorage.getItem("token"),
        lists_id: baseParmas.photopage_id,
      });
      if (res.code) {
        Toast.show(res.msg);
        history.pushState("", "", `${location.pathname}?tab=0`);
        location.reload();
      } else {
        Toast.show(res.msg);
      }
    }

    return (
      <div className="page_list">
        <NoticeBar theme="danger">
          点击【提交本次意见】按钮，才能提交成功；点击图片可查看原图
        </NoticeBar>
        <Tabs
          value={value}
          onChange={(e) => {
            setValue(Number(e));
          }}
        >
          <Panel
            title={
              <div className={style.title_text}>
                全部
                {count.total! > 0 && (
                  <Badge
                    shape="circle"
                    text={count.total}
                    style={{ background: "#909090" }}
                  />
                )}
              </div>
            }
          >
            <div className={style.content}>
              <All
                photopage_id={props.match.params.photopage_id}
                page_status="all"
                page_statusText={(e) => {
                  setpPage_status(e!);
                }}
              />
            </div>
          </Panel>

          <Panel
            title={
              <div className={style.title_text}>
                等待确认
                {count.wait_for_confirm! > 0 && (
                  <Badge
                    shape="circle"
                    text={count.wait_for_confirm}
                    style={{ background: "#909090" }}
                  />
                )}
              </div>
            }
          >
            <div className={style.content}>
              <WaitForConfirm
                photopage_id={props.match.params.photopage_id}
                page_status="wait_for_confirm"
                onchang={(e, status, kefu_phone, need_submit) => {
                  setCount(e);
                  setisBtnSub(status);
                  setNeed_submit(need_submit);
                  if (status) {
                    handleClickSendFactoryManufacturing();
                    console.log("开始弹窗1");
                    // setisBtnSub(false);
                  }
                  if (need_submit) {
                    const modal = Modal.confirm({
                      title: (
                        <div style={{ color: '#00bc70' }}>是否提交本次意见</div>
                      ),
                      content: (
                        <div style={{ textAlign: "center" }}>
                          <div>
                            您已保存所有修改意见
                            <br />
                          </div>
                          <div>
                            是否立刻提交
                            <br />
                          </div>
                        </div>
                      ),
                      onCancel: () => {
                        console.log("点击cancel");
                      },
                      onOk: async () => {
                        handleClickSubForm();
                      },
                    });
                  }
                  setKefu_phone(kefu_phone!);
                }}
              />
            </div>
          </Panel>

          {/* <Panel title={
                    <div className={style.title_text}>
                        等待修改
                        {count.wait_for_revising! > 0 && <Badge shape="circle" text={count.wait_for_revising} />}
                    </div>
                }>
                    <div className={style.content}>
                        <UpdateForConfirm photopage_id={props.match.params.photopage_id} page_status="wait_for_revising" />
                    </div>
                </Panel> */}

          {/* <Panel title={
                    <div className={style.title_text}>
                        修改中
                        {count.revising! > 0 && <Badge shape="circle" text={count.revising} />}
                    </div>
                }>
                    <div className={style.content}>
                        <Modification photopage_id={props.match.params.photopage_id} page_status="revising" />
                    </div>
                </Panel> */}
          <Panel
            title={
              <div className={style.title_text}>
                已确认
                {count.confirmed! > 0 && (
                  <Badge
                    shape="circle"
                    text={count.confirmed}
                    style={{ background: "#909090" }}
                  />
                )}
              </div>
            }
          >
            <div className={style.content}>
              <Confirmed
                photopage_id={props.match.params.photopage_id}
                page_status="confirmed"
              />
            </div>
          </Panel>
        </Tabs>
        <div className={style.btn_aciton}>
          {/* <Button block>联系客服</Button> */}
          {isBtnSub ? (
            // page_status
            <Button
              shape="round"
              block
              theme="primary"
              onClick={handleClickSendFactoryManufacturing}
            >
              送厂制作
            </Button>
          ) : page_status === "wait_for_confirm" ? (
            <Button
              shape="round"
              block
              theme="primary"
              onClick={handleClickSubForm}
            >
              提交本次意见
            </Button>
          ) : (
            ""
          )}
        </div>
        {/* 联系客服 */}
        <div className={style.Contact_service}>
          <a href={`tel:${kefu_phone}`}>
            联系
            <br />
            客服
          </a>
        </div>
      </div>
    );
  }
);

function CardPage(props: {
  data: {
    id?: number;
    file_name?: string;
    page_image?: string;
    sign_image?: string;
    customer_suggestion?: string;
    page_status?: string;
    admin_id?: number;
    lists_id?: number;
    wait_time?: number;
    page_status_text?: string;
  };
  tab: 0 | 1 | 2 | 3 | 4;
  kefu_phone: number;
  listPage_status: string;
}) {
  const [visible, setVisible] = useState(false);

  const { tab, kefu_phone } = props;

  const { page_image, page_status, file_name } = props.data;

  /**
   * 无需修改
   */
  async function handleClickNoModification() {
    let tipe: any = "是否确认无需修改";
    if (props.data.customer_suggestion) {
      tipe = (
        <div>
          <div style={{ color: "red" }}>
            {props.data.customer_suggestion! !== "" && (
              <div>“{props.data.customer_suggestion!}”</div>
            )}
          </div>
          <div>是否清除这些修改意见</div>
        </div>
      );
    }
    const modal = Modal.confirm({
      title: "确认信息",
      content: tipe,
      onCancel: () => {
        console.log("点击cancel");
      },
      onOk: async () => {
        console.log("点击ok");
        let res = await FetchRequest("/wap/Photopage/confirmPage", {
          token: localStorage.getItem("token"),
          item_id: props.data.id,
        });
        if (res.code === 1) {
          Toast.show(res.msg);
          history.pushState("", "", `${location.pathname}?tab=${tab}`);
          location.reload();
        } else {
          Toast.show(res.msg);
        }
      },
    });
  }

  return (
    <>
      <div className={style.CardPage}>
        <div className={style.CardPage_title}>
          <div className={style.file_fix}>文件名:</div>
          <div className={style.name_file}>{file_name}</div>
        </div>
        <div className={style.CardPage_img}>
          <img src={page_image} alt="" onClick={() => setVisible(true)} />
        </div>
        <div className={style.CardPage_btn}>
          {props.listPage_status === "wait_for_confirm" && (
            <>
              <Button
                block
                className={style.Need_modification}
                onClick={() =>
                  store.routerStore.push(
                    `/build/confirmation-page/edit?id=${props.data.id}&tab=${tab}&kefu_phone=${kefu_phone}&photopage_id=${props.data.lists_id}&scroll=${scroll}`
                  )
                }
              >
                需要修改
              </Button>
              <Button
                block
                className={style.No_modification_required}
                onClick={handleClickNoModification}
              >
                无需修改
              </Button>
            </>
          )}
        </div>
        <div
          className={`${style.triangle_topright} ${
            page_status === "wait_for_confirm" ? style.WaitingConfirmation : ""
          } ${page_status === "confirmed" ? style.Confirmed : ""} ${
            page_status === "revising" ? style.wait_for_revising : ""
          } ${page_status === "wait_for_revising" ? style.updateing : ""}`}
        ></div>
        <span
          className={`${style.triangle_text} ${
            page_status === "wait_for_confirm"
              ? `${style.wait_for_confirm_text}`
              : ""
          }`}
        >
          {page_status === "wait_for_confirm" && "等待确认"}
          {page_status === "confirmed" && "已确认"}
          {page_status === "revising" && "修改中"}
          {page_status === "wait_for_revising" && "等待修改"}
        </span>
      </div>
      <ImagePreview
        visible={visible}
        images={[page_image!]}
        onClose={() => setVisible(false)}
      />
    </>
  );
}

// 等待确认
function WaitForConfirm(props: {
  photopage_id: string;
  page_status: "wait_for_confirm" | "confirmed" | "revising" | "all";
  onchang: (
    e: resCountType,
    status: boolean,
    kefu_phone: any,
    need_submit: boolean
  ) => void;
}) {
  const pullRef = useRef();

  const [loading, setLoading] = useState(LOAD_STATE.normal);

  const [page, setPage] = useState(1);

  const [kefu_phone, setKefu_phone] = useState(0);

  const [data, setData] = useState<any>({
    lists: {},
    itemlists: [],
    count: {},
  });

  useEffect(() => {
    loadData();
  }, []);

  // 模拟加载更多数据
  async function loadData() {
    setLoading(LOAD_STATE.loading);

    let result: {
      code: number;
      data: {
        lists: resListType;
        itemlists: resItemlistsType;
        count: resCountType;
        songchang_status: boolean;
        kefu_phone: number;
        need_submit: boolean;
      };
      msg: string;
    } = await FetchRequest(
      `/wap/Photopage/getLists?token=${localStorage.getItem(
        "token"
      ) || store.userStore.token}&photopage_id=${props.photopage_id}&page=${page}&page_status=${
        props.page_status
      }`,
      {},
      "GET"
    );
    if (result.code === 1) {
      props.onchang(
        result.data.count,
        result.data.songchang_status,
        result.data.kefu_phone,
        result.data.need_submit
      );
      setKefu_phone(result.data.kefu_phone);
      if ((result.data.itemlists as Array<any>).length === 0) {
        setLoading(LOAD_STATE.complete);
        return;
      }
      setData({
        lists: result.data.lists,
        itemlists: data.itemlists.concat(result.data.itemlists),
        count: result.data.count,
      });
      setPage(page + 1);
      setLoading(LOAD_STATE.normal);
    } else {
      setLoading(LOAD_STATE.failure);
    }
  }

  return (
    <Pull
      ref={pullRef}
      load={{
        state: loading,
        distance: 200,
        handler: loadData,
      }}
    >
      {data.itemlists.map(
        (
          v: {
            id?: number;
            file_name?: string;
            page_image?: string;
            sign_image?: string;
            customer_suggestion?: string;
            page_status?: string;
            admin_id?: number;
            lists_id?: number;
            wait_time?: number;
            page_status_text?: string;
          },
          index: number
        ) => {
          return (
            <CardPage
              key={index}
              data={v}
              tab={1}
              kefu_phone={kefu_phone}
              listPage_status={data.lists.page_status}
            />
          );
        }
      )}
    </Pull>
  );
}

// 等待修改
function UpdateForConfirm(props: {
  photopage_id: string;
  page_status:
    | "wait_for_confirm"
    | "confirmed"
    | "revising"
    | "all"
    | "wait_for_revising";
}) {
  const pullRef = useRef();

  const [loading, setLoading] = useState(LOAD_STATE.normal);

  const [page, setPage] = useState(1);

  const [kefu_phone, setKefu_phone] = useState(0);

  const [data, setData] = useState<any>({
    lists: {},
    itemlists: [],
    count: {},
  });

  useEffect(() => {
    loadData();
  }, []);

  // 模拟加载更多数据
  async function loadData() {
    setLoading(LOAD_STATE.loading);

    let result: {
      code: number;
      data: {
        lists: resListType;
        itemlists: resItemlistsType;
        count: resCountType;
        kefu_phone: number;
      };
      msg: string;
    } = await FetchRequest(
      `/wap/Photopage/getLists?token=${localStorage.getItem(
        "token"
      )}&photopage_id=${props.photopage_id}&page=${page}&page_status=${
        props.page_status
      }`,
      {},
      "GET"
    );
    if (result.code === 1) {
      setKefu_phone(result.data.kefu_phone);
      if ((result.data.itemlists as Array<any>).length === 0) {
        setLoading(LOAD_STATE.complete);
        return;
      }
      setData({
        lists: result.data.lists,
        itemlists: data.itemlists.concat(result.data.itemlists),
        count: result.data.count,
      });
      setPage(page + 1);
      setLoading(LOAD_STATE.normal);
    } else {
      setLoading(LOAD_STATE.failure);
    }
  }

  return (
    <Pull
      ref={pullRef}
      load={{
        state: loading,
        distance: 200,
        handler: loadData,
      }}
    >
      {data.itemlists.map(
        (
          v: {
            id?: number;
            file_name?: string;
            page_image?: string;
            sign_image?: string;
            customer_suggestion?: string;
            page_status?: string;
            admin_id?: number;
            lists_id?: number;
            wait_time?: number;
            page_status_text?: string;
          },
          index: number
        ) => {
          return (
            <CardPage
              key={index}
              data={v}
              tab={1}
              kefu_phone={kefu_phone}
              listPage_status={data.lists.page_status}
            />
          );
        }
      )}
    </Pull>
  );
}

// 修改中
function Modification(props: {
  photopage_id: string;
  page_status: "wait_for_confirm" | "confirmed" | "revising" | "all";
}) {
  const pullRef = useRef();

  const [loading, setLoading] = useState(LOAD_STATE.normal);

  const [page, setPage] = useState(1);

  const [kefu_phone, setKefu_phone] = useState(0);

  const [data, setData] = useState<any>({
    lists: {},
    itemlists: [],
    count: {},
  });

  useEffect(() => {
    loadData();
  }, []);

  // 模拟加载更多数据
  async function loadData() {
    setLoading(LOAD_STATE.loading);

    let result: {
      code: number;
      data: {
        lists: resListType;
        itemlists: resItemlistsType;
        count: resCountType;
        kefu_phone: number;
      };
      msg: string;
    } = await FetchRequest(
      `/wap/Photopage/getLists?token=${localStorage.getItem(
        "token"
      )}&photopage_id=${props.photopage_id}&page=${page}&page_status=${
        props.page_status
      }`,
      {},
      "GET"
    );
    if (result.code === 1) {
      setKefu_phone(result.data.kefu_phone);
      if ((result.data.itemlists as Array<any>).length === 0) {
        setLoading(LOAD_STATE.complete);
        return;
      }
      setData({
        lists: result.data.lists,
        itemlists: data.itemlists.concat(result.data.itemlists),
        count: result.data.count,
      });
      setPage(page + 1);
      setLoading(LOAD_STATE.normal);
    } else {
      setLoading(LOAD_STATE.failure);
    }
  }

  return (
    <Pull
      ref={pullRef}
      load={{
        state: loading,
        distance: 200,
        handler: loadData,
      }}
    >
      {data.itemlists.map(
        (
          v: {
            id?: number;
            file_name?: string;
            page_image?: string;
            sign_image?: string;
            customer_suggestion?: string;
            page_status?: string;
            admin_id?: number;
            lists_id?: number;
            wait_time?: number;
            page_status_text?: string;
          },
          index: number
        ) => {
          return (
            <CardPage
              key={index}
              data={v}
              tab={2}
              kefu_phone={kefu_phone}
              listPage_status={data.lists.page_status}
            />
          );
        }
      )}
    </Pull>
  );
}

// 已确认
function Confirmed(props: {
  photopage_id: string;
  page_status: "wait_for_confirm" | "confirmed" | "revising" | "all";
}) {
  const pullRef = useRef();

  const [loading, setLoading] = useState(LOAD_STATE.normal);

  const [page, setPage] = useState(1);

  const [kefu_phone, setKefu_phone] = useState(0);

  const [data, setData] = useState<any>({
    lists: {},
    itemlists: [],
    count: {},
  });

  useEffect(() => {
    loadData();
  }, []);

  // 模拟加载更多数据
  async function loadData() {
    setLoading(LOAD_STATE.loading);

    let result: {
      code: number;
      data: {
        lists: resListType;
        itemlists: resItemlistsType;
        count: resCountType;
        kefu_phone: number;
      };
      msg: string;
    } = await FetchRequest(
      `/wap/Photopage/getLists?token=${localStorage.getItem(
        "token"
      )}&photopage_id=${props.photopage_id}&page=${page}&page_status=${
        props.page_status
      }`,
      {},
      "GET"
    );
    if (result.code === 1) {
      setKefu_phone(result.data.kefu_phone);
      if ((result.data.itemlists as Array<any>).length === 0) {
        setLoading(LOAD_STATE.complete);
        return;
      }
      setData({
        lists: result.data.lists,
        itemlists: data.itemlists.concat(result.data.itemlists),
        count: result.data.count,
      });
      setPage(page + 1);
      setLoading(LOAD_STATE.normal);
    } else {
      setLoading(LOAD_STATE.failure);
    }
  }

  return (
    <Pull
      ref={pullRef}
      load={{
        state: loading,
        distance: 200,
        handler: loadData,
      }}
    >
      {data.itemlists.map(
        (
          v: {
            id?: number;
            file_name?: string;
            page_image?: string;
            sign_image?: string;
            customer_suggestion?: string;
            page_status?: string;
            admin_id?: number;
            lists_id?: number;
            wait_time?: number;
            page_status_text?: string;
          },
          index: number
        ) => {
          return (
            <CardPage
              key={index}
              data={v}
              tab={2}
              kefu_phone={kefu_phone}
              listPage_status={data.lists.page_status}
            />
          );
        }
      )}
    </Pull>
  );
}

// 全部
function All(props: {
  photopage_id: string;
  page_status: "wait_for_confirm" | "confirmed" | "revising" | "all";
  page_statusText: (e: string | undefined) => void;
}) {
  const pullRef = useRef();

  const [loading, setLoading] = useState(LOAD_STATE.normal);

  const [page, setPage] = useState(1);

  const [kefu_phone, setKefu_phone] = useState(0);

  const [data, setData] = useState<{
    lists: any;
    itemlists: any[];
    count: resCountType;
  }>({
    lists: {},
    itemlists: [],
    count: {},
  });

  useEffect(() => {
    loadData();
  }, []);

  // 模拟加载更多数据
  async function loadData() {
    setLoading(LOAD_STATE.loading);

    let result: {
      code: number;
      data: {
        lists: resListType;
        itemlists: resItemlistsType;
        count: resCountType;
        kefu_phone: number;
      };
      msg: string;
    } = await FetchRequest(
      `/wap/Photopage/getLists?token=${localStorage.getItem(
        "token"
      )}&photopage_id=${props.photopage_id}&page=${page}&page_status=${
        props.page_status
      }`,
      {},
      "GET"
    );
    if (result.code === 1) {
      setKefu_phone(result.data.kefu_phone);
      if ((result.data.itemlists as Array<any>).length === 0) {
        setLoading(LOAD_STATE.complete);
        return;
      }
      setData({
        lists: result.data.lists,
        itemlists: data.itemlists.concat(result.data.itemlists),
        count: result.data.count,
      });
      props.page_statusText(result.data.lists.page_status);
      setPage(page + 1);
      setLoading(LOAD_STATE.normal);
    } else {
      setLoading(LOAD_STATE.failure);
    }
  }

  return (
    <Pull
      ref={pullRef}
      load={{
        state: loading,
        distance: 200,
        handler: loadData,
      }}
    >
      {data.itemlists.map(
        (
          v: {
            id?: number;
            file_name?: string;
            page_image?: string;
            sign_image?: string;
            customer_suggestion?: string;
            page_status?: string;
            admin_id?: number;
            lists_id?: number;
            wait_time?: number;
            page_status_text?: string;
          },
          index: number
        ) => {
          return (
            <CardPage
              key={index}
              data={v}
              tab={0}
              kefu_phone={kefu_phone}
              listPage_status={data.lists.page_status}
            />
          );
        }
      )}
    </Pull>
  );
}
