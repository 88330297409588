import React from 'react'
import { BangBangTangLayout } from '../../layout/layout'
import './customer-preorder-clothe-detail.scss'
import { store } from '../../store/store.root'
import { observer } from 'mobx-react'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'
import { CustomerClotheButtonRow } from './customer-clothe-button-row'
import { CustomerClotheSaveModal } from './customer-clothe-save-modal'
import { PreorderClotheTagModal } from '../employee-preorder-clothe/preorder-clothe-tag-modal'
import classNames from 'classnames'
import { Pull, Popup, Toast, Modal } from 'zarm'
import { toJS } from 'mobx'
import ScrImages from '../../assets/screen.png'
import { getParamValue } from '../../utils/url'
import { handleIFGoToSelect } from '../../utils'
import {
  EmployeePreorderClotheDetail,
  EmployeePreorderClotheStandardNumTag,
} from '../../store/customer-preorder-clothe/customer-preorder-clothe.api'

type LOAD_STATE = {
  normal: 0 // 普通
  abort: 1 // 中止
  loading: 2 // 加载中
  success: 3 // 加载成功
  failure: 4 // 加载失败
  complete: 5 // 加载完成（无新数据）
}

const LOAD_STATE_VALUE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
}

let scroll = 0

@observer
export class CustomerPreorderClotheDetail extends React.Component<any, any> {
  containerRef = React.createRef<HTMLDivElement>()
  timer: any

  componentWillUnmount() {
    // store.customerPreorderClotheStore.clearOtherData()
    window.addEventListener('scroll', this.handleScroll)
    // window.removeEventListener('scroll', this.handleScroll)
  }

  componentDidMount() {
    handleIFGoToSelect(`/build/customer-preorder-clothe-detail`)
    //////////////////////
    //////////////////////
    //////////////////////
    //////////////////////
    const store_id = new URLSearchParams(location.search).get('store_id') || ''
    localStorage.setItem('store_id', store_id)
    if (scroll > 0) {
      document.getElementsByClassName('za-pull')[0].scrollTop = scroll
    }
    if (document.getElementsByClassName('za-pull').length > 0) {
      document
        .getElementsByClassName('za-pull')[0]
        .addEventListener('scroll', this.handleScroll)
    }
    window.addEventListener('popstate', this.hadnlePop)
    // 如果用户选择了标签则不重复渲染
    const userSelectTag = new Set([
      ...Array.from(store.customerPreorderClotheStore.type_selected),
      ...Array.from(store.customerPreorderClotheStore.gender_selected),
      ...Array.from(store.customerPreorderClotheStore.region_selected),
      ...Array.from(store.customerPreorderClotheStore.clothe_type_selected),
      ...Array.from(store.customerPreorderClotheStore.color_type_selected),
      ...Array.from(store.customerPreorderClotheStore.color_style_selected),
    ])
    if (userSelectTag.size > 0) {
      return
    }
    appIsFirstReadySubject.subscribe(() => {
      const id = new URLSearchParams(location.search).get('id')
      const store_id = new URLSearchParams(location.search).get('store_id')
      if (!id) {
        alert('页面缺少关键参数,请从合法入口进入')
        store.routerStore.replace('/qiye')
      } else {
        // store.customerPreorderClotheStore
        //   .customerGetPreorderClotheData(store_id)
        //   .then(() => {
        //     store.customerPreorderClotheStore.set_preorder_id(parseInt(id, 10))
        //   })
        if (store.customerPreorderClotheStore.isEmpty) {
          store.customerPreorderClotheStore
            .customerGetPreorderClotheData(store_id, parseInt(id, 10))
            .then(() => {
              store.customerPreorderClotheStore.set_preorder_id(
                parseInt(id, 10)
              )
            })
        } else {
          if (!store.customerPreorderClotheStore.detail) {
            store.customerPreorderClotheStore.set_preorder_id(parseInt(id, 10))
          }
        }
      }
    })
  }

  hadnlePop = (e: any) => {
    if (location.pathname === '/build/customer-preorder-clothe-detail') {
    } else {
      store.customerPreorderClotheStore.clearOtherData()
    }
  }

  handleScroll = () => {
    //滚动条高度
    let scrollTop = document.getElementsByClassName('za-pull')[0].scrollTop
    scroll = scrollTop
  }

  handleClickClotheTag = (
    item: EmployeePreorderClotheStandardNumTag,
    index: number
  ) => {
    store.customerPreorderClotheStore.clear_options()
    // 记录用户点击的是哪一个tag
    store.customerPreorderClotheStore.user_select_index = index
    // 关闭当前弹窗
    store.customerPreorderClotheStore.toggle_photo_modal_status(false)
    // 回到顶部
    document.getElementsByClassName('za-pull')[0].scrollTop = 0
  }

  handleGetUserSelectImages = (
    item: EmployeePreorderClotheStandardNumTag
  ): EmployeePreorderClotheDetail[] => {
    if (item.user_select_ids.length === 0) {
      return []
    }
    const images: EmployeePreorderClotheDetail[] = []
    // 查询
    for (let i = 0; i < item.user_select_ids.length; i++) {
      const id = item.user_select_ids[i]
      const clothe_info = store.customerPreorderClotheStore.images_id_find_clothe_info(
        id
      )
      if (clothe_info) {
        images.push(clothe_info)
      }
    }
    return images
  }

  handleClickCloseClothe = (index: number, id: number) => {
    store.customerPreorderClotheStore.user_images_id_close_clothe(index, id)
  }

  handleGoToView = (value: EmployeePreorderClotheDetail) => {
    store.customerPreorderClotheStore.toggle_show_large(value.id)
    store.routerStore.push('/build/employee-preorder-clothe-detail-preview')
  }

  render() {
    return (
      <>
        {/* <BangBangTangLayout
        title={"预选服装"}
        maskLoading={store.customerPreorderClotheStore.loading}
      > */}
        <Pull
          style={{ overflow: 'auto', maxHeight: '100vh' }}
          load={{
            state: LOAD_STATE_VALUE.normal,
            distance: 200,
            handler: store.customerPreorderClotheStore.loadMore,
          }}
        >
          <div
            className="customer-preorder-clothe"
            ref={this.containerRef}
            onScroll={this.handleScroll}
          >
            <div className="first_col">
              {store.customerPreorderClotheStore.data
                ?.filter((value, index) => index % 2 === 0)
                .map((value) => {
                  const d: string[] = []

                  toJS(value).tags.forEach((item) => {
                    item.type.forEach((s) => {
                      if (s.is_show === 'yes') {
                        d.push(s.name)
                      }
                    })
                  })
                  // 过滤 lockStandardId
                  if (
                    store.customerPreorderClotheStore.lockStandardId.indexOf(
                      value.id
                    ) > -1
                  ) {
                    return
                  }
                  if (
                    store.customerPreorderClotheStore.selected_photos_repeat.indexOf(
                      value.id
                    ) > -1
                  ) {
                    return
                  }
                  return (
                    <div key={value.id} className="img-container">
                      <img
                        src={
                          value.clothingimages
                            ? value.clothingimages[0] +
                              '?imageMogr2/thumbnail/!50p'
                            : ''
                        }
                        alt="该图片神秘消失了"
                        key="img"
                        onClick={() => {
                          store.customerPreorderClotheStore.toggle_show_large(
                            value.id
                          )
                          store.routerStore.push(
                            '/build/employee-preorder-clothe-detail-preview'
                          )
                        }}
                        style={{ display: 'block' }}
                        className={classNames({
                          'active-photo': store.customerPreorderClotheStore.selected_photos.has(
                            value.id
                          ),
                        })}
                      />

                      <div className="customer_tag">
                        {d.map((v, s) => {
                          return <div key={s}>{v}</div>
                        })}
                      </div>

                      {!store.customerPreorderClotheStore.is_images_user_select(
                        value.id
                      ) && (
                        <div
                          className="iconfont button"
                          onClick={() => {
                            store.customerPreorderClotheStore.toggle_selected_photo(
                              value.id
                            )
                          }}
                        >
                          &#xe71c;
                        </div>
                      )}
                    </div>
                  )
                })}
            </div>
            <div className="second_col">
              {store.customerPreorderClotheStore.data
                ?.filter((value, index) => index % 2 === 1)
                .map((value) => {
                  const d: string[] = []

                  toJS(value).tags.forEach((item) => {
                    item.type.forEach((s) => {
                      if (s.is_show === 'yes') {
                        d.push(s.name)
                      }
                    })
                  })
                  // 过滤 lockStandardId
                  if (
                    store.customerPreorderClotheStore.lockStandardId.indexOf(
                      value.id
                    ) > -1
                  ) {
                    return
                  }
                  if (
                    store.customerPreorderClotheStore.selected_photos_repeat.indexOf(
                      value.id
                    ) > -1
                  ) {
                    return
                  }
                  return (
                    <div key={value.id} className="img-container">
                      <img
                        src={
                          value.clothingimages
                            ? value.clothingimages[0] +
                              '?imageMogr2/thumbnail/!50p'
                            : ''
                        }
                        alt=""
                        key={value.id}
                        onClick={() => {
                          store.customerPreorderClotheStore.toggle_show_large(
                            value.id
                          )
                          store.routerStore.push(
                            '/build/employee-preorder-clothe-detail-preview'
                          )
                        }}
                        style={{ display: 'block' }}
                        className={classNames({
                          'active-photo': store.customerPreorderClotheStore.selected_photos.has(
                            value.id
                          ),
                        })}
                      />

                      <div className="customer_tag">
                        {d.map((v, s) => {
                          return <div key={s}>{v}</div>
                        })}
                      </div>

                      {!store.customerPreorderClotheStore.is_images_user_select(
                        value.id
                      ) && (
                        <div
                          className="iconfont button"
                          onClick={() =>
                            store.customerPreorderClotheStore.toggle_selected_photo(
                              value.id
                            )
                          }
                        >
                          &#xe71c;
                        </div>
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
        </Pull>
        <CustomerClotheButtonRow />
        <CustomerClotheSaveModal />
        <Popup
          visible={store.customerPreorderClotheStore.photo_modal_visible}
          direction="bottom"
          onMaskClick={() =>
            store.customerPreorderClotheStore.toggle_photo_modal_visible(false)
          }
          destroy={
            store.customerPreorderClotheStore.user_standard_num_tag.length === 1
              ? true
              : false
          }
          mountContainer={() => document.body}
        >
          <div className="customer-clothe-popup-container">
            {store.customerPreorderClotheStore.user_standard_num_tag.map(
              (v, i) => {
                return (
                  <div className="customer_clothe_content" key={i}>
                    <div className="customer_clothe_content_top">
                      <div className="customer_clothe_content_top_tag">
                        {v.standard_text
                          .split(',')
                          .filter((v) => v)
                          .map((s, i) => {
                            return (
                              <span key={i} className="tag_item_new">
                                {s}
                              </span>
                            )
                          })}
                      </div>
                      <div className="customer_clothe_content_top_size">
                        已选 {v.user_select_ids.length} / 可选 {v.standard_num}
                      </div>
                    </div>

                    <div className="customer_clothe_content_img">
                      {v.user_select_ids.length > 0 && (
                        <div className="customer_clothe_content_img_content">
                          {this.handleGetUserSelectImages(v).map((s) => {
                            let shopImages = ''
                            if (s.clothingimages.length > 0) {
                              shopImages = s.clothingimages[0]
                            }
                            return (
                              <div
                                className="customer_clothe_content_img_list"
                                key={s.id}
                              >
                                <img
                                  src={shopImages}
                                  alt=""
                                  onClick={() => this.handleGoToView(s)}
                                />
                                <div className="customer_clothe_content_img_close_btn">
                                  <span
                                    onClick={() =>
                                      this.handleClickCloseClothe(i, s.id)
                                    }
                                  >
                                    取消
                                  </span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )}
                      <div
                        className="customer_clothe_content_tipes"
                        onClick={() => this.handleClickClotheTag(v, i)}
                      >
                        <div
                          className={`customer_clothe_btn ${
                            store.customerPreorderClotheStore
                              .user_select_index === i &&
                            'customer_clothe_btn_action'
                          }`}
                        >
                          点我选择服装~
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            )}
            <div
              className="close_btn"
              onClick={() => {
                const limit = store.customerPreorderClotheStore.user_standard_num_tag
                  .map((v) => v.user_select_ids)
                  .map((v) => v.length)
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  )

                const total = store.customerPreorderClotheStore.user_standard_num_tag
                  .map((v) => parseInt(v.standard_num))
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  ) // 必须要选完

                if (
                  store.customerPreorderClotheStore.detail.schedule_type_data
                    .submit_selection_status === 'must'
                ) {
                  if (total != limit) {
                    Toast.show(`您还可选择 ${total - limit} 套， 请继续选择`)
                  } else {
                    store.customerPreorderClotheStore.submit()
                  }
                } else {
                  if (total != limit) {
                    const modal = Modal.confirm({
                      title: '确认信息',
                      content: `您还可选 ${total - limit} 套， 是否提交？`,
                      confirmText: '提交',
                      onCancel: () => {},
                      onOk: async () => {
                        store.customerPreorderClotheStore.submit()
                      },
                    })
                  } else {
                    store.customerPreorderClotheStore.submit()
                  }
                }
                store.customerPreorderClotheStore.toggle_photo_modal_status(
                  false
                )
              }}
            >
              提交
            </div>

            {/* {store.customerPreorderClotheStore.selected_photos_flatten.map(
              (value) => (
                <div className="image" key={value.id}>
                  <img
                    onClick={() => {
                      store.customerPreorderClotheStore.toggle_show_large(
                        value.id
                      )
                      store.routerStore.push(
                        '/build/employee-preorder-clothe-detail-preview'
                      )
                    }}
                    src={value.pic && value.pic.length > 0 ? value.pic[0] : ''}
                    alt="图片找不到了"
                  />
                  <div
                    className="button"
                    onClick={() =>
                      store.customerPreorderClotheStore.toggle_selected_photo(
                        value.id
                      )
                    }
                  >
                    取消
                  </div>
                </div>
              )
            )} */}
          </div>
        </Popup>

        {/* <CustomerLargePicDisplay /> */}
        <div
          className={'customer-preorder-clothe-filter-button'}
          onClick={() =>
            store.customerPreorderClotheStore.toggle_popup_visible(true)
          }
        >
          <img src={ScrImages} alt="" />
        </div>
        <PreorderClotheTagModal
          visible={store.customerPreorderClotheStore.popup_visible}
          selected_options={
            new Set([
              ...Array.from(store.customerPreorderClotheStore.type_selected),
              ...Array.from(store.customerPreorderClotheStore.gender_selected),
              ...Array.from(store.customerPreorderClotheStore.region_selected),
              ...Array.from(
                store.customerPreorderClotheStore.clothe_type_selected
              ),
              ...Array.from(
                store.customerPreorderClotheStore.color_type_selected
              ),
              ...Array.from(
                store.customerPreorderClotheStore.color_style_selected
              ),
            ])
          }
          onCancel={() =>
            store.customerPreorderClotheStore.toggle_popup_visible(false)
          }
          onSave={() =>
            store.customerPreorderClotheStore.toggle_popup_visible(false)
          }
          onGenderCheck={(value) =>
            store.customerPreorderClotheStore.toggle_gender_selected(value)
          }
          onTypeCheck={(value) =>
            store.customerPreorderClotheStore.toggle_type_selected(value)
          }
          onClotheTypeCheck={(value) =>
            store.customerPreorderClotheStore.toggle_clothe_type_selected(value)
          }
          onColorTypeCheck={(value) =>
            store.customerPreorderClotheStore.toggle_color_type_selected(value)
          }
          onStyleTypeCheck={(value) =>
            store.customerPreorderClotheStore.toggle_style_type_selected(value)
          }
          onRegionTypeCheck={(value) =>
            store.customerPreorderClotheStore.toggle_clothe_region_selected(
              value
            )
          }
          onClear={() => store.customerPreorderClotheStore.clear_options()}
          // options={store.customerPreorderClotheStore.options}
          options={store.customerPreorderClotheStore.options_tag}
          saveButtonDisabled={false}
        />
        {/* </BangBangTangLayout> */}
      </>
    )
  }
}
