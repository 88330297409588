import { makeAutoObservable, runInAction } from "mobx";
import { MyPreorderPanelEnum } from "../pages/my-preorder/my-preorder";
import { MyPreorderDetailInterface } from "../pages/my-preorder/my-preorder.api";
import { MyPreorderRequest } from "../pages/my-preorder/my-preorder.request";


 const LOAD_STATE_VALUE = {
  normal: 0,
  abort: 1,
  loading: 2,
  success: 3,
  failure: 4,
  complete: 5
}

declare enum LOAD_STATE {
  normal = 0,
  abort = 1,
  loading = 2,
  success = 3,
  failure = 4,
  complete = 5
}

export class MyPreorderStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentPage: number = 0;
  selectedPanel: MyPreorderPanelEnum = MyPreorderPanelEnum.全部;
  loading: LOAD_STATE = LOAD_STATE_VALUE.normal;
  data: MyPreorderDetailInterface[] | null = null;
  set_selected_panel(value: MyPreorderPanelEnum) {
    this.currentPage = 0;
    this.data = [];
    this.selectedPanel = value;
    this.loadData();
  }
  loadData = () => {
    this.loading = LOAD_STATE_VALUE.loading;
    MyPreorderRequest(this.currentPage + 1, this.selectedPanel).subscribe(
      (value: string | any[]) => {
        if (value.length === 0) {
          runInAction(() => {
            this.loading = LOAD_STATE_VALUE.complete;
          });
        } else {
          runInAction(() => {
            setTimeout(() => {
              runInAction(() => {
                this.loading = LOAD_STATE_VALUE.success;
              });
            }, 10);
            this.data = ([] as any[]).concat(this.data).concat(value);
            this.currentPage += 1;
          });
        }
      }
    );
  };
}
