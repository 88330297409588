import React, { useEffect, useState } from 'react'
import { Cell, Pull, Toast } from 'zarm'
import { FetchRequest } from '../../utils/fetch'

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
}

let mounted = true

export function TheCommissionSubsidiary() {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(LOAD_STATE.normal)
  const [pageList, setPageList] = useState<any[]>([])

  useEffect(() => {
    handleGetCommissionList()
    document.title = '佣金明细'
    return () => {
      mounted = false
      document.body.style.overflow = 'auto'
    }
  }, [])

  const handleGetCommissionList = async () => {
    let loadingState = LOAD_STATE.success
    loadingState = LOAD_STATE.loading
    let res = await FetchRequest(
      `/wap/marketing/Redpacket/getCommissionList?page=${page}&token=${localStorage.getItem(
        'token'
      )}`,
      {}
    )
    loadingState = LOAD_STATE.success
    if (res.code === 1) {
      setPageList(pageList.concat(res.data))
      setPage(page + 1)
      if (res.data.length === 0) {
        mounted = false
        loadingState = LOAD_STATE.complete
      }
    } else {
      loadingState = LOAD_STATE.failure
      Toast.show(res.msg)
    }
    setLoading(loadingState)
  }

  // 模拟加载更多数据
  const loadData = async () => {
    if (!mounted) return
    handleGetCommissionList()
  }

  return (
    <div>
      <Pull
        style={{
          overflowY: 'auto',
          height: '100vh',
        }}
        load={{
          state: loading,
          distance: 300,
          handler: loadData,
        }}
      >
        {pageList.map((v, index) => {
          return (
            <Cell
              key={index}
              title={
                <>
                  <div
                    style={{
                      fontSize: 13,
                    }}
                  >
                    {v.description}
                  </div>
                </>
              }
              description={
                <>
                  <span
                    style={{
                      color: '#00bc70',
                    }}
                  >
                    {v.commission}
                  </span>
                </>
              }
            />
          )
        })}
      </Pull>
    </div>
  )
}

export function TheWithdrawalOfSubsidiary() {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(LOAD_STATE.normal)
  const [pageList, setPageList] = useState<any[]>([])

  useEffect(() => {
    handleGetCommissionList()
    document.title = '提现明细'
    return () => {
      mounted = false
      document.body.style.overflow = 'auto'
    }
  }, [])

  const handleGetCommissionList = async () => {
    let loadingState = LOAD_STATE.success
    loadingState = LOAD_STATE.loading
    let res = await FetchRequest(
      `/wap/marketing/Redpacket/getWithdrawalList?page=${page}&token=${localStorage.getItem(
        'token'
      )}`,
      {}
    )
    loadingState = LOAD_STATE.success
    if (res.code === 1) {
      setPageList(pageList.concat(res.data))
      setPage(page + 1)
      if (res.data.length === 0) {
        mounted = false
        loadingState = LOAD_STATE.complete
      }
    } else {
      loadingState = LOAD_STATE.failure
      Toast.show(res.msg)
    }
    setLoading(loadingState)
  }

  // 模拟加载更多数据
  const loadData = async () => {
    if (!mounted) return
    handleGetCommissionList()
  }

  return (
    <div>
      <Pull
        style={{
          overflowY: 'auto',
          height: '100vh',
        }}
        load={{
          state: loading,
          distance: 300,
          handler: loadData,
        }}
      >
        {pageList.map((v, index) => {
          return (
            <Cell
              key={index}
              title={
                <>
                  <div
                    style={{
                      fontSize: 13,
                    }}
                  >
                    <span>提现时间: {v.create_time_text}</span>
                  </div>
                </>
              }
              description={
                <>
                  <span
                    style={{
                      color: '#00bc70',
                    }}
                  >
                    提现金额: {v.money}
                  </span>
                </>
              }
            />
          )
        })}
      </Pull>
    </div>
  )
}
