import { action, makeAutoObservable, runInAction } from "mobx";
import { BangBangTangRequest } from "../utils/request";
import { store } from "./store.root";
import { finalize } from "rxjs/operators";

export class SliderShowStore {
  updateTime: Date | null = null;
  data: Array<{ id: number; pic: string; link: string }> = [];
  cdnurl: string = "";
  constructor() {
    makeAutoObservable(this);
  }

  setUpdated() {
    this.updateTime = new Date();
  }

  update() {
    if (this.updateTime !== null) return;
    store.globalUiStore.startPageLoading();
    return BangBangTangRequest({
      url: "/wap/data/getSlideshowData",
      method: "POST",
      data: { token: store.userStore.token },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result: Array<{ id: number; pic: string; link: string }>) => {
        runInAction(() => {
          this.setUpdated();
          if (!result) return;
          this.data = result;
        });
      });
  }
}
