import React, { useState, useEffect } from 'react'
import style from './index.module.scss'
import { withRouter } from 'react-router-dom'
import { Toast, Modal, Button, Input, Cell } from 'zarm'
import { FetchRequest } from '../../utils/fetch'
import { observer } from 'mobx-react'
import { store } from '../../store/store.root'
import { Steps } from 'react-vant'

type getCouponDetailType = {
  card_image?: string
  card_name?: string
  code?: string
  coupon_type?: number
  id?: number
  intro?: string[]
  qrcode?: string
  remark?: null
  shop_image?: string[]
  validity?: string
  writeOffMethod?: string
  writeoff_count?: string
  writeoff_count_use?: number
  card_type?: string
  card_code?: string
  card_image_desc2?: string
  card_image_desc3?: string
}

export default observer(
  withRouter(
    (props: {
      match: {
        params: {
          id: string
        }
      }
    }) => {
      useEffect(() => {
        document.title = '卡券详情'
      }, [])

      useEffect(() => {
        if (store.userStore.token) {
          getCouponDetail()
        }
      }, [store.userStore.token])

      const [complaint, setComplaint] = useState(false)

      const [Detaile, setDetaile] = useState<getCouponDetailType>({})

      const [record, setRecord] = useState([])

      const [QRCode, setQRCode] = useState({
        code: '',
        qrcode: '',
      })

      const [is_showPro, setShowPro] = useState(false)

      const [complaintContent, setComplaintContent] = useState('')

      async function getCouponDetail() {
        let res: {
          code: number
          data: getCouponDetailType
          msg: null | string
        } = await FetchRequest('/wap/coupon/getCouponDetail', {
          token: localStorage.getItem('token') || store.userStore.token,
          coupon_id: props.match.params.id,
        })
        if (res.code === 1) {
          setDetaile(res.data)
          setQRCode({
            code: res.data.code || '',
            qrcode: res.data.qrcode || '',
          })
          getWriteOffByMyCoupon(res.data.coupon_type, res.data.id)
        } else {
          Toast.show({
            content: res.msg,
            stayTime: 5000,
          })
        }
      }

      async function getWriteOffByMyCoupon(
        coupon_type?: number,
        coupon_id?: number
      ) {
        let res = await FetchRequest('/wap/coupon/getWriteOffByMyCoupon', {
          token: localStorage.getItem('token'),
          coupon_id,
          coupon_type,
        })
        if (res.code === 1) {
          setRecord(res.data)
        }
      }

      async function getgetMyCodeScanning() {
        let res = await FetchRequest('/wap/coupon/getMyCodeScanning', {
          token: localStorage.getItem('token'),
          coupon_id: Detaile.id,
        })
        if (res.code === 1) {
          setQRCode({
            code: res.data.code,
            qrcode: res.data.qrcode,
          })
        } else {
          Toast.show({
            content: res.msg,
            stayTime: 5000,
          })
        }
      }

      async function handleClickComplaint() {
        let res = await FetchRequest('/wap/coupon/submitShopComplaint', {
          token: localStorage.getItem('token'),
          coupon_id: Detaile.id,
          content: complaintContent,
        })
        Toast.show({
          content: res.msg,
          stayTime: 5000,
        })
        setComplaintContent('')
        setComplaint(false)
      }

      const {
        card_name,
        validity,
        card_image,
        qrcode,
        code,
        writeOffMethod,
        writeoff_count_use,
        writeoff_count,
        remark,
        intro,
        shop_image,
        card_code,
        card_image_desc2,
        card_image_desc3,
      } = Detaile

      if (Detaile.card_type === 'code') {
        return (
          <div className={style.content}>
            <div className={style.title}>{card_name}</div>

            <div className={style.exchange_code}>
              <div className={style.exchange_code_content}>
                <div className={style.left}>兑换码:</div>
                <div className={style.right} id="card_code">
                  {card_code}
                </div>
              </div>
              <div className={style.tipes}>请按以下步骤操作</div>
            </div>

            <div className={style.step_item}>
              <div className={style.title}>
                <div className={style.size}>1</div>
                <div>点击下面【复制兑换码】按钮</div>
              </div>
              <div
                className={style.btn_code}
                onClick={() => {
                  const copyDOM = document.getElementById('card_code') // 获取将要复制的dom
                  if (!copyDOM) {
                    return
                  }
                  // 创建text area
                  const textArea = document.createElement('textarea')
                  textArea.value = copyDOM.innerText
                  // 使text area不在viewport，同时设置不可见
                  textArea.style.position = 'absolute'
                  textArea.style.opacity = '0'
                  textArea.style.left = '-999999px'
                  textArea.style.top = '-999999px'
                  document.body.appendChild(textArea)
                  textArea.focus()
                  textArea.select() // 选中文本
                  const successful = document.execCommand('copy') // 执行 copy 操作
                  if (successful) {
                    Toast.show('复制成功！')
                  } else {
                    Toast.show('复制失败，请手动复制！')
                  }
                  textArea.remove()
                }}
              >
                复制兑换码
              </div>
            </div>

            <div className={style.step_item}>
              <div className={style.title}>
                <div className={style.size}>2</div>
              </div>
              <div className={style.images_content}>
                <img src={card_image_desc2} />
              </div>
            </div>

            <div className={style.step_item}>
              <div className={style.title}>
                <div className={style.size}>3</div>
              </div>
              <div className={style.images_content}>
                <img src={card_image_desc3} />
              </div>
            </div>

            {/* 投诉 */}
            <div className={style.complaint} onClick={() => setComplaint(true)}>
              <div>投诉</div>
              <div>商家</div>
            </div>
            <div style={{ height: 20 }}></div>
            <Modal
              title="投诉"
              visible={complaint}
              footer={
                <>
                  <Button
                    block
                    onClick={() => {
                      setComplaint(false)
                      setComplaintContent('')
                    }}
                    style={{ marginRight: 5 }}
                  >
                    取消
                  </Button>
                  <Button block theme="primary" onClick={handleClickComplaint}>
                    确认
                  </Button>
                </>
              }
            >
              <Input
                rows={3}
                type="text"
                placeholder="请输入投诉内容"
                value={complaintContent}
                onChange={(e: any) => setComplaintContent(e)}
              />
            </Modal>
          </div>
        )
      }

      return (
        <div className={style.Content}>
          {/* 头部标题 */}
          <div className={style.warp_title}>{card_name}</div>
          {/* 二维码 */}
          <div className={style.box_code}>
            <img src={QRCode.qrcode} alt="" onClick={getgetMyCodeScanning} />
          </div>
          {/* 数值 */}
          <p className={style.code_number}>{QRCode.code}</p>
          {/* 提示文字 */}
          <div className={style.tipes_text}>券码定时更新，请勿截屏</div>
          {/* 商家介绍 */}
          {is_showPro && (
            <div>
              <div className={style.shop_image} style={{ marginTop: 10 }}>
                <img src={card_image} alt="" style={{ width: '100%' }} />
              </div>
              <div className={style.shop_image}>
                {shop_image?.map((v, index) => {
                  return (
                    <img key={index} src={v} alt="" style={{ width: '100%' }} />
                  )
                })}
              </div>
            </div>
          )}
          <div>
            {is_showPro ? (
              <div
                className={style.text_show}
                onClick={() => setShowPro(false)}
              >
                关闭商家介绍
              </div>
            ) : (
              <div className={style.text_show} onClick={() => setShowPro(true)}>
                显示商家介绍
              </div>
            )}
          </div>
          {/* 列表 */}
          <div className={style.info_list}>
            <Cell title="核销方式" description={writeOffMethod} />
            <Cell
              title="核销次数"
              description={`${writeoff_count_use}/${writeoff_count}`}
            />
            <Cell title="有效期" description={validity} />
            <Cell title="卡券简介" />
            <div className={style.instructions}>
              <ul>
                {intro?.map((v: string, index: number) => {
                  return <li key={index}>{v}</li>
                })}
              </ul>
            </div>
            <Cell title="使用须知" />
            <div className={style.instructions}>
              <div dangerouslySetInnerHTML={{ __html: remark || '' }}></div>
            </div>
            {record.length != 0 && (
              <>
                <Cell title="核销记录" />
                <div className={style.instructions}>
                  <Steps direction="vertical" active={0}>
                    {record.map(
                      (v: {
                        id: number
                        wechat_name: string
                        writeoff_time: string
                      }) => {
                        ;<Steps.Item>
                          <h3>{v.wechat_name}</h3>
                          <p>{v.writeoff_time}</p>
                        </Steps.Item>
                      }
                    )}
                  </Steps>
                </div>
              </>
            )}
          </div>
          {/* 投诉 */}
          <div className={style.complaint} onClick={() => setComplaint(true)}>
            <div>投诉</div>
            <div>商家</div>
          </div>
          <div style={{ height: 20 }}></div>
          <Modal
            title="投诉"
            visible={complaint}
            footer={
              <>
                <Button
                  block
                  onClick={() => {
                    setComplaint(false)
                    setComplaintContent('')
                  }}
                  style={{ marginRight: 5 }}
                >
                  取消
                </Button>
                <Button block theme="primary" onClick={handleClickComplaint}>
                  确认
                </Button>
              </>
            }
          >
            <Input
              rows={3}
              type="text"
              placeholder="请输入投诉内容"
              value={complaintContent}
              onChange={(e: any) => setComplaintContent(e)}
            />
          </Modal>
        </div>
      )
    }
  )
)
