import React from 'react'
import './customer-query-preorder-clothe-list.scss'
import { BangBangTangLayout } from '../../layout/layout'
import { CustomerQueryPreorderClotheListHeaderComponent } from './customer-query-preorder-clothe-list-header.component'
import { ActivityIndicator, Button, Pull, Toast } from 'zarm'
import { store } from '../../store/store.root'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { CustomerQueryPreorderClotheCard } from './customer-query-preorder-clothe-card'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'
import { getParamValue, getUtilParams } from '../../utils/url'
import { handleIFGoToSelect } from '../../utils'

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
}

@observer
export class CustomerQueryPreorderClotheList extends React.Component<any, any> {
  componentDidMount() {
    // 如果路由有token
    const url_token = getParamValue('token')
    if (url_token) {
      store.userStore.changeToken(url_token)
      localStorage.setItem('token', url_token)
    }
    appIsFirstReadySubject.subscribe(() => {
      store.customerPreorderClotheListStore.reset()
      store.customerPreorderClotheListStore.load_more()
      document.title = '顾客预选单列表'
    })
    // 如果登录了
    handleIFGoToSelect('/build/customer-query-preorder-clothe-list')
  }

  render() {
    if (
      store.customerPreorderClotheListStore.current_active.data?.length === 0
    ) {
      store.routerStore.replace(
        '/build/customer-preorder-clothe-detail?id=' +
          store.customerPreorderClotheListStore.current_active.data[0].id
      )
    }
    return (
      <BangBangTangLayout>
        <Pull
          style={{ maxHeight: '100vh', overflow: 'auto' }}
          load={{
            state:
              store.customerPreorderClotheListStore.current_active.load_state,
            handler: store.customerPreorderClotheListStore.load_more,
            distance: 200,
            render: (loadState: any) => {
              const cls = 'custom-control'
              switch (loadState) {
                case LOAD_STATE.loading:
                  return (
                    <div className={cls}>
                      <ActivityIndicator type="spinner" />
                    </div>
                  )

                case LOAD_STATE.failure:
                  return <div className={cls}>加载失败</div>

                case LOAD_STATE.complete:
                  return <div className={cls}>您还没有预选单，请联系客服</div>
              }
            },
          }}
        >
          {store.customerPreorderClotheListStore.current_active.data?.map(
            (value) => (
              <CustomerQueryPreorderClotheCard key={value.id} data={value} />
            )
          )}
        </Pull>
      </BangBangTangLayout>
    )
  }
}
