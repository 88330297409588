import { AjaxResponse, ajax } from 'rxjs/ajax'
import { catchError, finalize, switchMap } from 'rxjs/operators'
import { EMPTY, ObservableInput, of, throwError } from 'rxjs'
import { Toast } from 'zarm'
import { store } from '../store/store.root'

export interface BangBangTangRequestRequest {
  url: string
  method: 'GET' | 'POST'
  data: any
}
export interface BangBangTangRequestOption {
  disableErrorHandler: boolean
}
const defaultOption: BangBangTangRequestOption = { disableErrorHandler: false }
export const BangBangTangRequest = <T = any>(
  _request: BangBangTangRequestRequest,
  _option?: BangBangTangRequestOption
) => {
  const option: Required<BangBangTangRequestOption> = {
    disableErrorHandler:
      (_option && _option.disableErrorHandler) ||
      defaultOption.disableErrorHandler,
  }
  const data = { ..._request.data }
  if (data.token === undefined && store.userStore.token) {
    data.token = store.userStore.token
  }
  if (data.store_id == undefined && store.storeInfoStore.value) {
    data.store_id = store.storeInfoStore.value
  }
  return ajax({
    url: _request.url,
    method: _request.method,
    body: data,
    withCredentials: true,
    crossDomain: true,
    responseType: 'json',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
  }).pipe(
    switchMap<AjaxResponse, ObservableInput<T>>((value) => {
      if (value.status === 200) {
        const resultCode = value.response.code
        if (resultCode === 1 || resultCode === '1') {
          return of(value.response.data)
        }

        return throwError(value)
      }
      return throwError(value)
    }),
    catchError((err: AjaxResponse) => {
      if (option.disableErrorHandler) return throwError(err)
      if (err.status === 200) {
        const data = err.response
        switch (data.code) {
          case 4003:
          case '4003':
            // window.localStorage.clear()
            localStorage.removeItem('token')
            location.href = '/build'
            break
          case 4004:
            localStorage.removeItem('token')
            location.href = '/build'
            break

          default:
            alert(data.msg)
          // console.log(data.msg)
        }
      } else Toast.show('请求错误,请重新尝试') //alert("请求错误,请重新尝试");
      return EMPTY
    })
  )
}
