import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { store } from '../../store/store.root'
import { Loading } from 'zarm'

export default observer(() => {
  useEffect(() => {
    document.title = '版面'
    Loading.show()
    if (store.userStore.token) {
      window.location.href = `http://page.mjz365.top/page/list?token=${store.userStore.token}&origin=${window.location.origin}`
      Loading.hide()
    }
  }, [store.userStore.token])

  return null
})
