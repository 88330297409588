import React from "react";
import "./close-relationship-number-modal-line.css";

export class CloseRelationshipNumberModalLine extends React.Component<
  {
    name: string;
    value: string | null;
    onSetClicked?: () => void;
  },
  any
> {
  render() {
    return (
      <div className="close-relationship-number-modal-line">
        <div>{this.props.name}</div>
        <div className="button" onClick={this.props.onSetClicked}>
          {this.props.value
            ? this.props.value
            : this.props.onSetClicked
            ? "点击设置"
            : ""}
        </div>
      </div>
    );
  }
}
