import React from "react";
import { BangBangTangLayout } from "../../layout/layout";
import style from "./index.module.scss";
import { ImagePreview, Button, Modal } from "zarm";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";
import { finalize } from "rxjs/operators";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";
import { RuleListRequired } from "../../components/module-required/rule-list-required";

export class ExecutiveForceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      pictureIndex: 0,
      id: "",
      detail_type: 2,
      detail: {},
      images: [],
      result: "",
      resultModal: false,
    };
  }

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
  };

  type = {
    2: {
      url: "/wap/sanction/getAwardDetail",
      type_name: "奖励类别",
      admin_text: "受奖人",
      reason: "奖励备注",
      integral: "增加执行力",
      money: "奖励金额",
      updater: "开单人",
      createtime: "创建时间",
    },
    3: {
      url: "/wap/sanction/getThumbsDetail",
      type_name: "处罚类别",
      admin_text: "受罚人",
      reason: "处罚备注",
      integral: "扣除执行力",
      money: "处罚金额",
      updater: "开单人",
      createtime: "创建时间",
    },
    4: {
      url: "/wap/sanction/getReformDetail",
      type_name: "工单类别",
      admin_text: "执行人",
      reason: "执行备注",
      integral: "扣除执行力",
      money: "处罚金额",
      updater: "开单人",
      createtime: "创建时间",
    },
  };

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      // console.log(this.props)
      const {
        match: {
          params: { id, type },
        },
      } = this.props;
      this.setState(
        {
          id: id,
          detail_type: type,
        },
        () => {
          switch (type) {
            case "2":
              this.getAwardDetail();
              break;
            case "3":
              this.getThumbsDetail();
              break;
            case "4":
              this.getReformDetail();
              break;
            default:
              break;
          }
        }
      );
      window.document.title = "执行力详情";
    });
  }

  getAwardDetail = () => {
    let that = this;
    BangBangTangRequest({
      url: that.type[2].url,
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        award_id: that.state.id,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        that.setState({
          detail: result,
          images: result.images,
        });
      });
  };

  getThumbsDetail = () => {
    let that = this;
    BangBangTangRequest({
      url: that.type[3].url,
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        thumbs_id: that.state.id,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        that.setState({
          detail: result,
          images: result.images,
        });
      });
  };

  getReformDetail = () => {
    let that = this;
    BangBangTangRequest({
      url: that.type[4].url,
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        reform_id: that.state.id,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        that.setState({
          detail: result,
          images: result.images,
        });
      });
  };

  show = (index) => {
    this.setState({
      visible: true,
      pictureIndex: index,
    });
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  revoke = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/submitCloseOff",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        order_id: that.state.id,
        order_type: that.state.detail_type - 1,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        this.showResult(result);
      });
  };

  showResult = (result) => {
    this.setState({
      result: result,
      resultModal: true,
    });
  };

  closeResultModal = () => {
    this.setState({
      result: "",
      resultModal: false,
    });
  };

  render() {
    const {
      visible,
      pictureIndex,
      images,
      detail,
      detail_type,
      result,
      resultModal,
    } = this.state;
    return (
      <BangBangTangLayout title={"执行力详情"}>
        <div className={style.container}>
          <div className="detai-container">
            <div className="section">
              <div className="k">{this.type[detail_type].type_name}</div>
              <div className="v">{detail.type_name}</div>
            </div>
            <div className="section">
              <div className="k">{this.type[detail_type].admin_text}</div>
              <div className="v">{detail.admin_text}</div>
            </div>
            <div className="section">
              <div className="k">{this.type[detail_type].reason}</div>
              <div className="v">{detail.reason}</div>
            </div>
            <div className="section">
              <div className="k">{this.type[detail_type].integral}</div>
              <div className="v">{detail.integral}</div>
            </div>
            <div className="section">
              <div className="k">{this.type[detail_type].money}</div>
              <div className="v">{detail.money}</div>
            </div>
          </div>

          <div className="detai-container">
            <div className="section">
              <div className="k">{this.type[detail_type].updater}</div>
              <div className="v">{detail.updater}</div>
            </div>
            <div className="section">
              <div className="k">{this.type[detail_type].createtime}</div>
              <div className="v">{detail.createtime}</div>
            </div>
          </div>
          {images.length > 0 ? (
            <div className="photo-list">
              {images.map((pic, index) => (
                <div
                  className="picture-item"
                  onClick={() => this.show(index)}
                  key={+index}
                >
                  <img src={pic} />
                </div>
              ))}
            </div>
          ) : null}
          {images.length > 0 ? (
            <ImagePreview
              visible={visible}
              images={images}
              onClose={this.hide}
              activeIndex={pictureIndex}
            />
          ) : null}

          <RuleListRequired name={"sanction_award_close"}>
            <div className="revoke">
              <Button
                size="sm"
                style={{ width: "128px" }}
                theme="danger"
                className="revoke-btn"
                onClick={this.revoke}
              >
                撤销
              </Button>
            </div>
          </RuleListRequired>

          <Modal
            className={style.detailModal}
            visible={resultModal}
            footer={
              <Button block className="sure" onClick={this.closeResultModal}>
                知道了
              </Button>
            }
          >
            <div className="t-c">{result}</div>
          </Modal>
        </div>
      </BangBangTangLayout>
    );
  }
}
export default withRouter(ExecutiveForceDetail);
