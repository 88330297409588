import React, { useState, useEffect, useRef } from "react";
import style from "./index.module.scss";
import { Tabs, Toast, Pull, Loading } from "zarm";
import { FetchRequest } from "../../utils/fetch";
import { store } from "../../store/store.root";

const { Panel } = Tabs;

type Datatype = {
  card_image?: string;
  card_name?: string;
  createtime?: string;
  id?: number;
  rate_count?: string;
  sum?: number;
  usage_type?: number;
  validity?: string;
  writeoff_count?: number;
};

export default () => {
  const [value, setValue] = useState(0);

  const [beUsed, setBeUsed] = useState<Datatype[]>([]);
  const [beUsedPage, setbeUsedPage] = useState(1);
  const [isBeUsedOff, setIsBeUsedOff] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "我的卡券";
    // myCouponListByUsing();
  }, []);

  useEffect(() => {
    myCouponListByUsing();
    console.log("监听");
  }, [value]);

  // 待使用
  async function myCouponListByUsing() {
    if (loading) return;
    if (isBeUsedOff) return;
    Loading.show();
    setLoading(true);
    let res = await FetchRequest("/wap/coupon/getCouponUsage", {
      token: localStorage.getItem("token"),
      page: beUsedPage,
      usageType: value,
    });
    if (res.code === 1) {
      if ((res.data as Datatype[]).length === 0) {
        setIsBeUsedOff(true);
      } else {
        setBeUsed(beUsed.concat(res.data));
        setbeUsedPage(beUsedPage + 1);
      }
    } else {
      setIsBeUsedOff(true);
    }
    setLoading(false);
    Loading.hide();
  }

  return (
    <Tabs
      value={value}
      onChange={(e) => {
        setValue(Number(e));
        setBeUsed([]);
        setbeUsedPage(1);
        setIsBeUsedOff(false);
        console.log("切换");
      }}
      scrollable
    >
      <Panel title="待使用">
        <div className={style.content}>
          <TicketList
            value={value}
            data={beUsed}
            isBeUsedOff
            isNum
            handleClickIsPage={myCouponListByUsing}
          />
        </div>
      </Panel>
      <Panel title="已核销">
        <div className={style.content}>
          <TicketList
            value={value}
            data={beUsed}
            isBeUsedOff
            isNum
            handleClickIsPage={myCouponListByUsing}
          />
        </div>
      </Panel>
      <Panel title="已过期">
        <div className={style.content}>
          <TicketList
            value={value}
            data={beUsed}
            isBeUsedOff
            isNum
            handleClickIsPage={myCouponListByUsing}
          />
        </div>
      </Panel>
    </Tabs>
  );
};

const REFRESH_STATE = {
  normal: 0, // 普通
  pull: 1, // 下拉刷新（未满足刷新条件）
  drop: 2, // 释放立即刷新（满足刷新条件）
  loading: 3, // 加载中
  success: 4, // 加载成功
  failure: 5, // 加载失败
};

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
};

function TicketList(props: {
  data: Datatype[];
  isNum?: boolean;
  value: number;
  isBeUsedOff?: boolean;
  handleClickIsPage?: () => void;
}) {
  const pullRef = useRef();

  const [refreshing, setRefreshing] = useState(REFRESH_STATE.normal);
  const [loading, setLoading] = useState(LOAD_STATE.normal);

  return (
    <>
      <div style={{ height: 42 }}></div>
      <div className={style.collect_content}>
        <Pull
          ref={pullRef}
          // refresh={{
          //     state: refreshing,
          //     handler: props.handleClickIsPage,
          // }}
          load={{
            state: loading,
            distance: 200,
            handler: props.handleClickIsPage,
          }}
        >
          {props.data.map((v: Datatype, index: number) => {
            return (
              <view
                className={style.card_item}
                key={index}
                onClick={() => {
                  if (v.id) {
                    store.routerStore.push(`/build/my-ticket-detaile/${v.id}`);
                  }
                }}
              >
                <view className={style.card_img}>
                  <img src={v.card_image} alt="" style={{ width: "100%" }} />
                </view>
                <view className={style.card_info}>
                  <view style={{ marginBottom: 10 }}>
                    {v.card_name} {v.sum! > 0 && (<span className={style.count}>x{v.sum}</span>)}
                  </view>
                  <view className={style.botm_text} style={{ bottom: 15 }}>
                    领取时间: {v.createtime}
                  </view>
                  <view className={style.botm_text}>有效期: {v.validity}</view>
                </view>
                {props.value === 1 && (
                  <view className={style.tips_text}>已核销</view>
                  )}
                {props.value === 2 && (
                  <view className={style.tips_text}>已过期</view>
                )}
              </view>
              //   <div
              //     className={style.collect_item}
              //     key={index}
              //     onClick={() => {
              //       if (v.id) {
              //         store.routerStore.push(`/build/my-ticket-detaile/${v.id}`);
              //       }
              //     }}
              //   >
              //     <div className={style.collect_img}>
              //       <img src={v.card_image} alt="" />
              //     </div>
              //     <div className={style.collect_desc}>
              //       <p className={style.collect_title}>
              //         <span style={{ color: "#000" }}>{v.card_name}</span>
              //         {props.isNum && v.sum && (
              //           <span className={style.collect_icon_close}>
              //             <span style={{ fontSize: 12 }}>X</span>
              //             <span className={style.collect_icon_num}>
              //               {v.sum}
              //               <span style={{ fontSize: 12 }}>张</span>
              //             </span>
              //           </span>
              //         )}
              //       </p>
              //       <div className={style.collect_time}>
              //         <p>领取时间: {v.createtime}</p>
              //       </div>
              //       <div className={style.collect_desc_text}>{v.validity}</div>
              //     </div>
              //   </div>
            );
          })}
          {props.isBeUsedOff! && (
            <div
              style={{ textAlign: "center", fontSize: 13, color: "#b3b3b3" }}
            >
              暂无更多哦~
            </div>
          )}
        </Pull>
      </div>
    </>
  );
}
