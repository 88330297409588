import React from "react";
import "./employee-preorder-search.scss";
import { Button, Cell, Input, Picker, Radio } from "zarm";
import { PHONE_RE } from "../../utils";
import { EmployeeQueryPreorderClotheCard } from "../employee-query-preorder-clothe-list/employee-query-preorder-clothe-card";
import { BangBangTangRequest } from "../../utils/request";
import { store } from "../../store/store.root";
import { observer } from "mobx-react";
import { runInAction } from "mobx";

const PICKER_DATA = [
  {
    value: "",
    label: "全部",
  },
  {
    value: "committed",
    label: "已提交",
  },
  {
    value: "uncommitted",
    label: "未提交",
  },
  {
    value: "canceled",
    label: "已取消",
  },
  {
    value: "expired",
    label: "过期",
  },
];

@observer
export class EmployeePreorderSearch extends React.Component<any, {}> {
  constructor(props: any) {
    super(props);
  }

  search = () => {
    BangBangTangRequest({
      url: `/wap/clothing/getAllSelectionList?token=${store.userStore.token}&page=1&type=${store.employeePreorderClotheSearchStore.type}&user_mobile=${store.employeePreorderClotheSearchStore.phone}`,
      data: {},
      method: "GET",
    }).subscribe((value) =>
      runInAction(() => {
        store.employeePreorderClotheSearchStore.setData(value);
      })
    );
  };

  render() {
    return (
      <div className="employee-preorder-search">
        <Cell title={"用户手机"}>
          <Input
            placeholder={"请输入用户手机号"}
            value={store.employeePreorderClotheSearchStore.phone}
            onChange={(value: any) =>
              store.employeePreorderClotheSearchStore.setPhone(value)
            }
          />
        </Cell>
        <Cell
          onClick={() =>
            store.employeePreorderClotheSearchStore.setPickerVisible(true)
          }
          title={"状态"}
          description={
            PICKER_DATA.find(
              (value) =>
                value.value === store.employeePreorderClotheSearchStore.type
            )!.label
          }
        />
        <Picker
          destroy
          itemRender={(data: any) => data.label}
          value={store.employeePreorderClotheSearchStore.type}
          visible={store.employeePreorderClotheSearchStore.picker_visible}
          dataSource={PICKER_DATA}
          onOk={(selected: any) => {
            store.employeePreorderClotheSearchStore.setType(
              selected[0].value as any
            );
            store.employeePreorderClotheSearchStore.setPickerVisible(false);
          }}
        />
        <Button
          block
          theme="primary"
          className="search-button"
          disabled={
            !store.employeePreorderClotheSearchStore.phone.match(PHONE_RE)
          }
          onClick={this.search}
        >
          搜索
        </Button>
        {store.employeePreorderClotheSearchStore.data.map((value) => (
          <EmployeeQueryPreorderClotheCard data={value} key={value.id} />
        ))}
      </div>
    );
  }
}
