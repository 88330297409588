import React, { useEffect, useState, useRef } from "react"
import style from "./index.module.scss"
import { Button, Input, Modal, Toast, Popup, ImagePreview, NoticeBar, Loading, ActivityIndicator } from "zarm"
import { BangBangTangLayout } from "../../layout/layout"
import { getParamValue } from "../../utils/url"
import { FetchRequest } from "../../utils/fetch"
import OSS from "ali-oss";
import COS from "cos-js-sdk-v5";
import { store } from "../../store/store.root"

export default () => {

  useEffect(() => {
    document.title = "版面编辑"
    Overscroll()
    return () => {
      console.log("页面卸载")
      location.reload();
    }
  }, []);

  function Overscroll() {
    // 让指定的元素可以滚动
    let overscroll = function (el: any) {
      el.addEventListener('touchstart', function () {
        let top = el.scrollTop;
        let totalScroll = el.scrollHeight;
        let currentScroll = top + el.offsetHeight;
        //If we're at the top or the bottom of the containers
        //scroll, push up or down one pixel.
        //
        //this prevents the scroll from "passing through" to
        //the body.
        if (top === 0) {
          el.scrollTop = 1;
        } else if (currentScroll === totalScroll) {
          el.scrollTop = top - 1;
        }
      });
      el.addEventListener('touchmove', function (evt: any) {
        //if the content is actually scrollable, i.e. the content is long enough
        //that scrolling can occur
        if (el.offsetHeight < el.scrollHeight) evt._isScroller = true;
      });
    };
    overscroll(document.querySelector('#root'));
    document.body.addEventListener(
      'touchmove',
      function (evt: any) {
        // console.log(evt._isScroller);
        //In this case, the default behavior is scrolling the body, which
        //would result in an overflow.  Since we don't want that, we preventDefault.
        if (!evt._isScroller) {
          evt.preventDefault();
        }
      },
      { passive: false },
    );
  }
  return <CanvasImages />
}

function CanvasImages() {

  const ref = useRef()

  const id = getParamValue("id")

  const photopage_id = getParamValue("photopage_id")

  const scrollCount = getParamValue("scroll")

  const [canvas, setCanvas] = useState<any>(null)

  const [ctx, setCtx] = useState<any>()

  // 添加图片修改意见状态
  const [addImagesTextStatus, setaddImagesTextStatus] = useState(false)

  // 查看修改意见状态
  const [lookUpdateTextStatus, setlookUpdateTextStatus] = useState(false)

  // 存放临时的修改意见
  const [updateContent, setupdateContent] = useState("")

  const [itemData, setItemData] = useState<{
    itemData: {
      admin_id?: number;
      customer_suggestion?: string;
      file_name?: string;
      id?: number;
      lists_id?: number;
      page_image?: string;
      page_status?: string;
      page_status_text?: string;
      sign_image?: string;
      wait_time?: number;
    },
    itemLog: any[]
  }>({
    itemData: {},
    itemLog: []
  })

  const [isuUpdate, setisuUpdate] = useState(false)

  const [CanvasPath, setCanvasPath] = useState<any>([])

  useEffect(() => {
    loadingServiceDate()
    const newCanvas: any = document.getElementById("canvas");
    let newCtx = newCanvas.getContext("2d")
    setCanvas(newCanvas)
    setCtx(newCtx)
    newCtx.lineWidth = 1.0;
    newCtx.strokeStyle = 'red'
    newCanvas?.addEventListener('touchstart', function (event: { targetTouches: string | any[]; }) {//触摸点按下事件
      if (event.targetTouches.length == 1) {
        var touch = event.targetTouches[0];
        newCtx.beginPath();
        newCtx.moveTo(touch.clientX - newCanvas!.offsetLeft, touch.clientY - newCanvas!.offsetTop);
        newCanvas?.addEventListener('touchmove', function (event: { targetTouches: any[]; }) {//手机拖动触摸点事件
          if (!isuUpdate) {
            setisuUpdate(true)
          }
          var touche = event.targetTouches[0];
          newCtx.lineTo(touche.clientX - newCanvas!.offsetLeft, touche.clientY - newCanvas!.offsetTop);
          let newArray: any = [].concat(CanvasPath)
          newArray.push(`${touche.clientX - newCanvas!.offsetLeft},${touche.clientY - newCanvas!.offsetTop}`)
          setCanvasPath(newArray)
          newCtx.stroke();
        }, false)
        newCanvas?.addEventListener('touchend', function () {//手机离开屏幕的事件
          newCtx.closePath();
        }, false)
      }
    }, false)
  }, [])

  useEffect(() => {
    loadingImages()
  }, [itemData.itemData.page_image])

  async function loadingServiceDate() {
    let res = await FetchRequest(`/wap/Photopage/getDetail?token=${localStorage.getItem("token")}&item_id=${id}`, {}, "GET")
    if (res.code === 1) {
      setItemData(res.data)
    } else {
      Toast.show(res.msg)
    }
  }

  function drawImgToCanvas(url: string, rotate: number): Promise<string> {
    return new Promise((res, rej) => {
      let canvas = document.createElement("canvas");
      let context: any = canvas.getContext('2d')

      var img = new Image();
      img.src = url + `?time=${new Date().getTime()}`;
      img.crossOrigin = 'anonymous';
      img.setAttribute("cache-Control", "no-cache")
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = function () {
        console.log("图片绘制完成")
        canvas.width = img.height;
        canvas.height = img.width;

        context.translate(canvas.width / 2, canvas.height / 2)
        context.rotate(rotate * Math.PI / 180)
        context.translate(-canvas.width / 2, -canvas.height / 2)
        context.drawImage(img, canvas.width / 2 - img.width / 2, canvas.height / 2 - img.height / 2)
        context.translate(canvas.width / 2, canvas.height / 2)
        context.rotate(-rotate * Math.PI / 180)
        context.translate(-canvas.width / 2, -canvas.height / 2)
        context.restore()
        var base64 = canvas.toDataURL("image/jpeg", 0.9);

        if (img.width > img.height) {
          console.log("横图准备输出base")
          res(base64)
        } else {
          console.log("竖图准备输出普通链接")
          res(url + `?time=${new Date().getTime()}`)
        }
      };

      img.onerror = function (err) {
        console.log(err, "<---错误---")
        console.log(img)
      }

    })
  }

  async function loadingImages() {
    if (!itemData.itemData.page_image!) return
    // 插入图片
    var img = new Image()
    img.src = await drawImgToCanvas(itemData.itemData.page_image!, 90)
    //图片加载完后，将其显示在canvas中
    img.crossOrigin = 'anonymous';
    img.setAttribute("Cache-Control", "no-cache")
    img.onload = function () {
      img.style.transform = 'rotateZ(87deg)'
      let w = img.width;	// 图片宽度
      let h = img.height;	// 图片高度
      // let conW = document.body.clientWidth;	// 页面宽度
      // let conH = document.body.clientHeight * 0.65;	// 页面高度
      /// 画布宽度
      let conW = window.screen.width - 20;	// 页面宽度
      let conH = window.screen.height * 0.65;	// 页面高度

      // const widthCanvas = window.screen.width - 20
      // const heightCanvas = window.screen.height * 0.65
      let dw = conW / w;	//canvas与图片的宽高比
      let dh = conH / h;

      // let dw = widthCanvas / w;	//canvas与图片的宽高比
      // let dh = heightCanvas / h;

      if (img.width > img.height) {
        console.log("横图")
        ctx.drawImage(this, 0, 0, conW, conW / dh)
      } else {
        console.log("竖图", conW, dw)

        let shorPicture = 0; // 图片短边
        let longPicture = 0; // 图片长边

        let shorCanvas = 0; // 画布短边
        let longCanvas = 0; // 画布长边

        // 比较图片
        if (w > h) {
          // 宽大于高
          console.log("图片 宽大于高")
          shorPicture = h
          longPicture = w
        } else {
          // 高大于宽
          console.log("图片 高大于宽")
          shorPicture = w
          longPicture = h
        }

        // 比较画布
        if (conW > conH) {
          console.log("画布 宽大于高")
          shorCanvas = conH
          longCanvas = conW
        } else {
          console.log("画布 高大于宽")
          shorCanvas = conW
          longCanvas = conH
        }

        // shorPicture = shorPicture * 0.5
        // longPicture = longPicture * 0.5

        let shor = 0;
        let long = 0;

        if (longCanvas > (shorCanvas / shorPicture) * longPicture) {
          // 图片宽撑满
          // 图片高比例伸缩
          // ctx.drawImage(this, 0, 0, shorCanvas, (shorCanvas / shorPicture) * longPicture)
          shor = shorCanvas
          long = (shorCanvas / shorPicture) * longPicture
          console.log(1)
        } else {
          console.log(2)
          // 图片高撑满
          // 图片宽比例伸缩
          shor = (longCanvas / longPicture) * shorPicture
          long = longCanvas
        }
        shor = shor * 1
        long = long * 1

        console.log(shor, long)
        // 如果版宽度小于画布宽度居中显示
        if (conW > shor) {
          ctx.drawImage(this, (conW - shor) / 2, 0, shor, long)
        } else {
          ctx.drawImage(this, 0, 0, shor, long)
        }
        // if (shorPicture < shorCanvas) {
        //   shorPicture = shorPicture * 0.65
        //   longPicture = longPicture * 0.65
        //   if (longCanvas > (shorCanvas / shorPicture) * longPicture ) {
        //     // 图片宽撑满
        //     // 图片高比例伸缩
        //     ctx.drawImage(this, 0, 0, shorCanvas, (shorCanvas / shorPicture) * longPicture)
        //   } else {
        //     // 图片高撑满
        //     // 图片宽比例伸缩
        //     ctx.drawImage(this, 0, 0, (longCanvas / longPicture) * shorPicture, longCanvas)
        //   }
        // } else {
        //   shorPicture = shorPicture * 0.65
        //   longPicture = longPicture * 0.65
        //   // if (shorCanvas > (longCanvas / longPicture) * shorPicture ) {
        //   //   // 图片高撑满
        //   //   // 图片宽比例伸缩
        //   //   ctx.drawImage(this, 0, 0, longCanvas, (longCanvas / longPicture) * shorPicture)
        //   // } else {
        //   //   // 图片宽撑满
        //   //   // 图片高比例伸缩
        //   //   ctx.drawImage(this, 0, 0, (shorCanvas / shorPicture) * longPicture, shorCanvas)
        //   // }
        // }


        // ctx.drawImage(this, 0, 0, conW, conW / dw)
        // ctx.drawImage(this, 0, 0, conW / dw, conH)
        // ctx.drawImage(this, 0, 0, conW, conH / (dh / dw))
        // ctx.drawImage(this, 0, 0, conW / (dw / dh), conH)

      }
      console.log("------------")
      console.log("图片宽:", w)
      console.log("图片高:", h)
      console.log("------------")
      console.log("页面宽:", window.screen.width)
      console.log("页面高:", window.screen.height)
      console.log("------------")
      console.log("宽比:", dw)
      console.log("高比:", dh)
      console.log("------------")
      console.log("画布高:", conH)
      console.log("画布宽:", conW)
      console.log("------------")
    }
    img.onerror = function (e) {
      console.log(e,"<-----发生错误")
    }
  }

  function clear() {
    setisuUpdate(false)
    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, window.screen.width - 20, window.screen.height * 0.65);
    loadingImages()
  }

  // console.log(CanvasPath)
  function revoke() {
    ctx.getImageData(0,0, Number((CanvasPath[0] as string).split(",")[0]), Number((CanvasPath[0] as string).split(",")[1]))
  }

  function exportImages() {
    var base64Img = canvas.toDataURL('image/jpg');
    return base64Img
  }

  // base64转blob
  const base64ToBlob = function (base64Data: string) {
    let arr: any = base64Data.split(','),
      fileType = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      l = bstr.length,
      u8Arr = new Uint8Array(l);

    while (l--) {
      u8Arr[l] = bstr.charCodeAt(l);
    }
    return new Blob([u8Arr], {
      type: fileType
    });
  };

  /**
   * 提交修改意见
   */
  function handleClickSubForm() {
    // 判空
    if (updateContent === "") {
      Toast.show("请输入修改意见")
      return
    }
    const modal = Modal.confirm({
      title: '确认信息',
      content: '确认提交修改意见吗?',
      onCancel: () => {
        console.log('点击cancel');
      },
      onOk: async () => {
        console.log('点击ok');
        Loading.show({
          content: <ActivityIndicator size="lg" />,
          stayTime: 3000,
        });
        let sign_image = ""
        
        if (isuUpdate) {
          // 修改了图片
          // 开始上传修改后的图片
          // 判断上传 存储
          const configOOS = await FetchRequest("/wap/sanction/getOssConfig", {
            token: localStorage.getItem("token") || store.userStore.token
          })

          // 阿里云
          if (configOOS.data.storage === "alioss") {
            const base64 = exportImages()
            sign_image = await upload(itemData.itemData.file_name!, base64ToBlob(base64)) || itemData.itemData.page_image!  
          } 
          // 腾讯云
          if (configOOS.data.storage === "cos") {
              // 开始请求oos配置
              const base64 = exportImages()
              sign_image = await cosUpload(itemData.itemData.file_name!, base64ToBlob(base64))
          }

        } else {
          // 没修改
          sign_image = itemData.itemData.page_image!
        }

        let res = await FetchRequest("/wap/Photopage/modifyCommnt", {
          token: localStorage.getItem("token"),
          item_id: id,
          customer_suggestion: updateContent,
          sign_image
        })
        Loading.hide();
        if (res.code === 1) {
          Toast.show(res.msg)
          setaddImagesTextStatus(false)
          loadingServiceDate()
          clear()
          /// 跳转回去
          store.routerStore.replace(`/build/confirmation-page/detaile/${photopage_id}?scroll=${scrollCount}&tab=${getParamValue("tab")}`)
        } else {
          Toast.show(res.msg)
        }
      },
    });
  }

  // 阿里云
  async function upload(fileName: string, lrzFile: any) {
    // 开始请求oos配置
    let resOOS = await FetchRequest("/wap/sanction/getOssConfig", {
      token: localStorage.getItem("token"),
    })
    if (resOOS.code === 1) {
      let client = new OSS({
        endpoint: "https://" + resOOS.data.endpoint, //本地使用"http://",线上使用"https://",地域（在创建 Bucket 的时候指定的中心位置），这里可能不知道具体地域怎么填其实就是 oss-cn-中心位置 ，例：region:'oss-cn-chengdu'，chengdu则是创建bucket是指定的位置成都。
        accessKeyId: resOOS.data.app_id, //阿里云产品的通用id
        accessKeySecret: resOOS.data.app_key, //密钥
        bucket: resOOS.data.bucket, //OSS 存储区域名
      });
      const today = timestampToTime();
      const uploadFileName = random_20();
      const suffix = fileName.substring(fileName.lastIndexOf(".") + 1);
      const objectName = `uploads/${localStorage.getItem("admin_store_id") || "1"}/img/photopage/${today}/${uploadFileName}.${suffix}`
      console.log(objectName, "<---上传文件路径")

      try {
        let result = await client.put(objectName, lrzFile);
        return result.url
      } catch (err) {
        console.log(err);
        throw new Error("阿里云oos上传失败");
      }
    } else {
      Toast.show("获取阿里云oos配置失败")
    }
  };

  // 腾讯云
  async function cosUpload(fileName: string, ImagesBlob: any) {
    
    const configOOS = await FetchRequest("/wap/sanction/getOssConfig", {
      token: localStorage.getItem("token") || store.userStore.token
    })

    const cos = new COS({
      getAuthorization: async function (options, callback) {
          // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
          // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
          // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
          const data = await FetchRequest("/addons/cos/index/getSTS", {
            token: localStorage.getItem("token") || store.userStore.token
          })
          callback({
            TmpSecretId: data.data.credentials.tmpSecretId,
            TmpSecretKey: data.data.credentials.tmpSecretKey,
            SecurityToken: data.data.credentials.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: data.data.expiredTime, // 时间戳，单位秒，如：1580000900
        });
          }
    });

    const today = timestampToTime();
    const uploadFileName = random_20();
    const suffix = fileName.substring(fileName.lastIndexOf(".") + 1);

    const objectName = `/uploads/${
      localStorage.getItem("admin_store_id") || "1"
    }/img/photopage/${today}/${uploadFileName}.${suffix}`;

    cos.putObject(
        {
          Bucket: configOOS.data.bucket,
          Region: configOOS.data.region,
          Key: objectName,
          StorageClass: "STANDARD",
          Body: ImagesBlob, // 上传文件对象
          onProgress: function (progressData) {
            console.log(JSON.stringify(progressData));
          },
        },
        function (err, data) {
          if (err) {
            alert("图片上传失败")
          }
        }
      );
    return objectName
  }

  function timestampToTime() {
    var date = new Date(); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear();
    var M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var D = date.getDate();
    return `${Y}${M}${D}`;
  };

  function random_20() {
    var data = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
    ];
    var result = "";
    for (var i = 0; i < 20; i++) {
      const r = Math.floor(Math.random() * 16);
      result += data[r];
    }
    return result;
  };

  const [visible, setVisible] = useState(false);

  const tab = getParamValue("tab")

  const kefu_phone = getParamValue("kefu_phone")

  const u = navigator.userAgent;

  const isAudroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;

  const isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

  return (
    <>
      <div>
        <div className="page_list">
          <NoticeBar theme="danger">可以直接在图片上涂鸦做好需要修改的位置标记</NoticeBar>
        </div>
        <canvas height={window.screen.height * 0.65} width={window.screen.width - 20} id="canvas" className={style.canvas}>
          <span>亲，您的浏览器不支持canvas，换个浏览器试试吧！</span>
        </canvas>
        <div style={{ padding: '0 10px' }}>

          {isAudroid && (
            <>
              <textarea placeholder="请输入您的修改意见" value={updateContent} onChange={(e: any) => setupdateContent(e.target.value)} style={{ fontSize: 14, border: '1px solid rgb(179 179 179)', marginBottom: 5, padding: 5, borderRadius: 8, width: '100%', height: (window.screen.height * 0.2) - 70 }}></textarea>
              <Button shape="round" block theme="primary" style={{ height: 35 }} onClick={handleClickSubForm}>保存</Button>
            </>
          )}

          {isIos && (
            <Button shape="round" block theme="primary" style={{ height: 35 }} onClick={() => setaddImagesTextStatus(true)}>点击输入修改意见</Button>
          )}

        </div>

        {/* <div className={style.action_canvas} style={{ bottom: "42%" }} onClick={revoke}>
          撤销
        </div> */}
        
        <div className={style.action_canvas} style={{ bottom: "32%" }} onClick={clear}>
          清除<br />涂鸦
        </div>
        {/* <div className={style.action_canvas} style={{ bottom: "20%" }} onClick={() => setaddImagesTextStatus(true)}>
          添加<br />意见
        </div> */}
        <div className={style.action_canvas} style={{ bottom: "22%" }} onClick={() => setlookUpdateTextStatus(true)}>
          查看<br />意见
        </div>
        <div className={style.Contact_service} style={{ bottom: '13%' }}>
          <a href={`tel:${kefu_phone}`}>联系<br />客服</a>
        </div>
        <div className={style.action_canvas} style={{ bottom: "5%", lineHeight: '25px' }} onClick={() => store.routerStore.replace(`/build/confirmation-page/detaile/${photopage_id}?tab=${tab}&scroll=${scrollCount}`)}>
          返回
        </div>

      </div>
      {/* 添加历史记录 */}
      <Modal
        closable
        title="添加修改意见"
        visible={addImagesTextStatus}
        onCancel={() => setaddImagesTextStatus(false)}
        footer={
          <Button block theme="primary" onClick={handleClickSubForm}>
            确认
          </Button>
        }
      >
        <div>
          <Input
            autoHeight
            rows={3}
            type="text"
            placeholder="请输入修改意见"
            onChange={(e: any) => setupdateContent(String(e))}
          />
        </div>
      </Modal>
      {/* 查看历史记录弹窗 */}
      <Popup
        visible={lookUpdateTextStatus}
        onMaskClick={() => setlookUpdateTextStatus(false)}
        direction="bottom"
        afterClose={() => console.log('关闭')}
      >
        <div className={style.look_content}>
          <div className={style.look_warp_right_close}>
            <Button size="xs" onClick={() => setlookUpdateTextStatus(false)}>
              关闭
            </Button>
          </div>
          <div style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
            <div className={style.update_info}>
              <div>修改图片: </div>
              <div style={{ paddingLeft: 67 }}>
                <img src={itemData.itemData.sign_image!} alt="" style={{ width: 100 }} onClick={() => setVisible(true)} />
              </div>
              <div>修改意见: {itemData.itemData.customer_suggestion}</div>
            </div>
          </div>
          <ImagePreview
            visible={visible}
            images={[itemData.itemData.sign_image!]}
            onClose={() => setVisible(false)}
          />
        </div>
      </Popup>
    </>
  )
}
