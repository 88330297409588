import React from "react";
import { observer } from "mobx-react";
import { Button, Cell, Modal } from "zarm";
import { preorderScheduleActions } from "../../store/preorder-schedule/preorder-schedule.action";
import { store } from "../../store/store.root";
import { format } from "date-fns";

@observer
export class CustomerConfirmModal extends React.Component<any, any> {
  render() {
    let prefix: "备档" | "预约" | "";
    if (store.preorderScheduleStore.selected_schedule_inquiry) {
      if (store.preorderScheduleStore.selected_schedule_inquiry.status === 1) {
        prefix = "预约";
      } else {
        prefix = "备档";
      }
    } else {
      prefix = "";
    }
    return (
      <Modal
        onCancel={() =>
          preorderScheduleActions.changeScheduleConfirmModalDisplay(false)
        }
        visible={
          store.preorderScheduleStore.schedule_inquiry.display_confirm_modal
        }
        closable
        width={"95%"}
        title={"确认"}
        mask
      >
        {store.preorderScheduleStore.selected_schedule_inquiry &&
        store.preorderScheduleStore.schedule_inquiry.display_confirm_modal ? (
          <>
            <Cell
              title={prefix + "门店"}
              description={
                store.preorderScheduleStore.selectedStoreCellDisplayValue
              }
              key={1}
            />
            <Cell
              title={prefix + "档期类型"}
              description={
                store.preorderScheduleStore.selectedScheduleTypeCellDisplayValue
              }
              key={2}
            />
            <Cell
              title={prefix + "日期"}
              description={format(
                store.preorderScheduleStore.available_date!.value!,
                "yyyy-MM-dd"
              )}
              key={3}
            />
            <Cell
              title={prefix + "时间"}
              description={
                store.preorderScheduleStore.selected_schedule_inquiry!
                  .schedule_time
              }
              key={4}
            />
            <Cell
              title={"孕妇/宝宝姓名"}
              description={store.preorderScheduleStore.username}
              key={5}
            />
            <Cell
              title={"预产期/宝宝生日"}
              description={store.preorderScheduleStore.birthday}
              key={6}
            />
            <Cell
              title={"宝宝性别"}
              description={
                store.preorderScheduleStore.gender === "male" ? "男孩" : "女孩"
              }
              key={6}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                justifyContent: "space-around",
              }}
            >
              <Button
                block
                style={{ width: "40%" }}
                onClick={() =>
                  preorderScheduleActions.changeScheduleConfirmModalDisplay(
                    false
                  )
                }
              >
                我再想想
              </Button>
              <Button
                style={{ width: "40%" }}
                block
                theme="primary"
                onClick={() =>
                  preorderScheduleActions.submitScheduleApplication(
                    store.preorderScheduleStore.selected_schedule_inquiry!.id,
                    prefix === "备档" ? prefix : "库存"
                  )
                }
              >
                确认{prefix}
              </Button>
            </div>
          </>
        ) : null}
      </Modal>
    );
  }
}
