import { makeAutoObservable, runInAction } from 'mobx'
import { BangBangTangRequest } from '../../utils/request'
import {
  DateDataInterface,
  PreorderScheduleSTypeInterface,
  StoreInterface,
} from './preorder-schedule'
import { add, format } from 'date-fns'
import { store } from '../store.root'
import { delay, finalize } from 'rxjs/operators'
import { PreorderScheduleStore } from './preorder-schedule.store'
import { toast } from 'react-toastify'
import { Toast } from 'zarm'

export class PreorderScheduleActions {
  constructor(public store: PreorderScheduleStore) {
    makeAutoObservable(this)
  }

  toggleDisplayMoreAlreadyPreorderCard(value: boolean) {
    this.store.display_more_already_preorder_card = value
  }

  changeScheduleAlreadyPreorderDisplayId(value: number | null) {
    this.store.schedule_already_preorder.value = value
  }

  getScheduleOptions() {
    this.store.schedule_already_preorder.loading = true
    BangBangTangRequest({
      url: '/wap/schedule/getScheduleOptions',
      data: { token: store.userStore.token },
      method: 'POST',
    })
      .pipe(
        finalize(() =>
          runInAction(
            () => (this.store.schedule_already_preorder.loading = false)
          )
        )
      )
      .subscribe((value) => {
        // 门店数据
        runInAction(() => {
          this.store.store.choices = value.storeList
          if (value.storeList.length === 1) {
            this.setStoreValue(value.storeList[0].id)
          }
        })
        // 档期数据
        runInAction(() => {
          this.store.schedule_type.choices = value.schedule_type
          if (value.schedule_type.length === 1) {
            this.setScheduleTypeValue(value.schedule_type[0].id)
          }
        })
        // 形式
        runInAction(() => {
          this.store.form_type.list = value.shootform
        })

        // runInAction(
        //   () =>
        //     (this.store.schedule_already_preorder.choices =
        //       value.schedule_type || [])
        // )

        // if (value) {
        //   runInAction(() => {
        //     this.store.schedule_already_preorder.choices = value;
        //   });
        // } else {
        //   runInAction(
        //     () => (this.store.schedule_already_preorder.choices = [])
        //   );
        // }
      })
  }

  getUserScheduleData() {
    this.store.schedule_already_preorder.loading = true
    BangBangTangRequest({
      url: '/wap/schedule/getUserEffectiveBookingData',
      data: { token: store.userStore.token, status: 'all', page: 1 },
      method: 'POST',
    })
      .pipe(
        finalize(() =>
          runInAction(
            () => (this.store.schedule_already_preorder.loading = false)
          )
        )
      )
      .subscribe((value) => {
        if (value) {
          runInAction(() => {
            this.store.schedule_already_preorder.choices = value
          })
        } else {
          runInAction(() => (this.store.schedule_already_preorder.choices = []))
        }
      })
  }

  cancelPreorder(id: number) {
    this.store.schedule_already_preorder.cancel_pending = true
    BangBangTangRequest({
      method: 'POST',
      data: { token: store.userStore.token, booking_id: id },
      url: '/wap/schedule/cancelScheduleApplication',
    })
      .pipe(
        finalize(() =>
          runInAction(
            () => (this.store.schedule_already_preorder.cancel_pending = false)
          )
        )
      )
      .subscribe(() => {
        runInAction(() => {
          this.store.schedule_already_preorder.cancel_modal_display = false
          this.store.schedule_already_preorder.value = null
          this.getUserScheduleData()
          if (
            this.store.store.value &&
            this.store.schedule_type.value &&
            this.store.available_date.value
          ) {
            this.getScheduleInquiryData()
          }
        })
      })
  }

  changeScheduleAlreadyPreorderCancelModalDisplay(value: boolean) {
    this.store.schedule_already_preorder.cancel_modal_display = value
  }

  changeCurrentMonth(type: 'next' | 'prev' | 'no_change') {
    if (type === 'next') {
      if (this.store.current_selected_month === 11) {
        this.store.current_selected_month = 0
        this.store.current_selected_year += 1
      } else {
        this.store.current_selected_month += 1
      }
    } else if (type === 'prev') {
      if (this.store.current_selected_month === 0) {
        this.store.current_selected_month = 11
        this.store.current_selected_year -= 1
      } else {
        this.store.current_selected_month -= 1
      }
    }
    this.getCurrentMonthData()
  }

  public getStoreData() {
    this.store.store.loading = true
    BangBangTangRequest({
      url: '/wap/rule/getStoreData',
      data: { token: store.userStore.token },
      method: 'POST',
    })
      .pipe(
        finalize(() => runInAction(() => (this.store.store.loading = false)))
      )
      .subscribe((value: StoreInterface[]) => {
        runInAction(() => {
          this.store.store.choices = value
          if (value.length === 1) {
            this.setStoreValue(value[0].id)
          }
        })
      })
  }

  public getScheduleTypeData() {
    this.store.schedule_type.loading = true
    BangBangTangRequest<{ id: string; name: string }[]>({
      url: '/wap/schedule/getScheduleType',
      data: {
        token: store.userStore.token,
        store_id: store.preorderScheduleStore.store.value,
      },
      method: 'POST',
    })
      .pipe(
        finalize(() =>
          runInAction(() => (this.store.schedule_type.loading = false))
        )
      )
      .subscribe((value: PreorderScheduleSTypeInterface[]) => {
        runInAction(() => {
          this.store.schedule_type.choices = value
          if (value.length === 1) {
            this.setScheduleTypeValue(value[0].id)
          }
        })
      })
  }

  public togglePickerVisible = (
    type:
      | 'date'
      | 'store'
      | 'scheduleType'
      | 'package'
      | 'package_age_tag'
      | 'from'
  ) => {
    let current: boolean
    switch (type) {
      case 'date':
        current = this.store.available_date.picker_visible
        break
      case 'scheduleType':
        current = this.store.schedule_type.picker_visible
        break
      case 'store':
        current = this.store.store.picker_visible
        break
      case 'package':
        current = this.store.schedule_package.picker_visible
        break
      case 'package_age_tag':
        current = this.store.package_age_tag.modal_visible
        break
      case 'from':
        current = this.store.form_type.modal_visible
        break
    }
    this.store.schedule_type.picker_visible =
      type === 'scheduleType' ? !current : false
    this.store.available_date.picker_visible =
      type === 'date' ? !current : false
    this.store.store.picker_visible = type === 'store' ? !current : false
    this.store.schedule_package.picker_visible =
      type === 'package' ? !current : false
    this.store.package_age_tag.modal_visible =
      type === 'package_age_tag' ? !current : false
    this.store.form_type.modal_visible = type === 'from' ? !current : false
  }

  public handleSelectAgeTag = (item: { id: number; name: string }) => {
    this.store.package_age_tag.value = item.id.toString()
    return
    let ArrayAgeTag: any[] = (this.store.package_age_tag.value as string)
      .split(',')
      .filter((v) => parseInt(v) > 0)
    let isContain = this.store.package_age_tag.value.indexOf(item.id.toString())
    if (isContain > -1) {
      let n: any[] = []
      for (let i = 0; i < ArrayAgeTag.length; i++) {
        const element = ArrayAgeTag[i]
        if (element !== item.id.toString()) {
          n.push(element)
        }
      }
      this.store.package_age_tag.value = n.join(',')
    } else {
      ArrayAgeTag.push(item.id)
      this.store.package_age_tag.value = ArrayAgeTag.join(',')
    }
  }

  public setStoreValue = (value: number | null) => {
    if (value !== this.store.store.value) {
      runInAction(() => {
        this.store.store.value = value
        this.store.store.picker_visible = false
        // 清空选项
        this.store.schedule_package.value = ''
        this.store.package_age_tag.value = ''
        this.store.schedule_package.value = ''
        this.store.form_type.value = []
      })
      // this.getScheduleTypeData()
    } else {
      runInAction(() => {
        this.store.store.picker_visible = false
      })
    }
  }

  public setScheduleTypeValue = (value: string | null) => {
    if (value !== this.store.schedule_type.value) {
      runInAction(() => {
        this.store.schedule_type.value = value
        this.store.schedule_type.picker_visible = false
      })
      // this.changeCurrentMonth('no_change')
    } else {
      runInAction(() => (this.store.schedule_type.picker_visible = false))
    }
    this.store.package_age_tag.value = ''
  }

  public setSchedulePackageValue = (value: string) => {
    runInAction(() => {
      this.store.schedule_package.value = value
      this.store.schedule_package.picker_visible = false
    })
  }

  public setDateValue = (value: Date | null) => {
    this.store.available_date.value = value
    this.store.available_date.picker_visible = false
    this.getScheduleInquiryData()
  }

  private getCurrentMonthData() {
    runInAction(() => (this.store.available_date.loading = true))
    BangBangTangRequest({
      url: '/wap/schedule/getScheduleDate',
      data: {
        token: store.userStore.token,
        start: format(
          new Date(
            this.store.current_selected_year,
            this.store.current_selected_month,
            this.store.current_selected_year === new Date().getFullYear() &&
            this.store.current_selected_month === new Date().getMonth()
              ? new Date().getDate()
              : 1
          ),
          'yyyy-MM-dd'
        ),
        type: this.store.schedule_type.value,
        store_id: this.store.store.value,
        end: format(
          add(
            add(
              new Date(
                this.store.current_selected_year,
                this.store.current_selected_month,
                1
              ),
              { months: 1 }
            ),
            { days: -1 }
          ),
          'yyyy-MM-dd'
        ),
      },
      method: 'POST',
    })
      .pipe(
        finalize(() =>
          runInAction(() => {
            this.store.available_date.loading = false
            this.store.available_date.picker_visible = true
          })
        )
      )
      .subscribe((obj: DateDataInterface[]) => {
        runInAction(() => {
          this.store.available_date.choices = Object.values(obj)
            .map((value) => {
              const split = value.schedule_date.split('-')
              return {
                day: parseInt(split[2], 10),
                status: value.status,
                month: parseInt(split[1], 10) - 1,
                year: parseInt(split[0], 10),
              }
            })
            .sort((a, b) => a.day - b.day)
        })
      })
  }

  public submitScheduleApplication(id: number, type: '库存' | '备档') {
    runInAction(() => {
      this.store.submit_pending = true
      this.store.schedule_inquiry.display_confirm_modal = false
    })
    BangBangTangRequest(
      {
        url: '/wap/schedule/submitScheduleApplication',
        method: 'POST',
        data: {
          token: store.userStore.token,
          inquiry_id: id,
          application_type: type === '备档' ? 3 : 1,
          store_id: this.store.store.value,
          baby_name: this.store.username,
          baby_birthday: this.store.birthday,
          baby_gender: this.store.gender === 'female' ? 2 : 1,
        },
      },
      { disableErrorHandler: true }
    )
      .pipe(
        finalize(() => {
          runInAction(() => {
            this.store.submit_pending = false
          })
        })
      )
      .subscribe(
        () => {
          if (type === '备档') {
            toast('备档排队成功。')
          } else {
            toast('预约申请成功，请等待审核。')
          }
          this.getUserScheduleData()
          store.routerStore.replace('/build')
        },
        (error) => {
          alert('预约失败,因为' + error?.response?.msg || JSON.stringify(error))
        }
      )
  }

  public changeScheduleConfirmModalDisplay(value: boolean) {
    this.store.schedule_inquiry.display_confirm_modal = value
  }

  public getScheduleInquiryData() {
    this.store.schedule_inquiry.loading = true
    BangBangTangRequest<
      {
        background: string
        id: number
        remark: string
        schedule_time: string
        status: number
        status_text: string
      }[]
    >({
      url: '/wap/schedule/getScheduleInquiry',
      method: 'POST',
      data: {
        schedule_date: format(this.store.available_date.value!, 'yyyy-MM-dd'),
        token: store.userStore.token,
        type: this.store.schedule_type.value,
        store_id: this.store.store.value,
      },
    })
      .pipe(
        finalize(() =>
          runInAction(() => {
            this.store.schedule_inquiry.loading = false
          })
        )
      )
      .subscribe((value) => {
        runInAction(() => {
          this.store.schedule_inquiry.choices = value
          this.store.schedule_inquiry.picker_visible = true
        })
      })
  }
}

export const preorderScheduleActions = new PreorderScheduleActions(
  store.preorderScheduleStore
)
