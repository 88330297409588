import React, { useState, useEffect, useRef } from 'react'
import { FetchRequest } from '../../utils/fetch'
import { withRouter } from 'react-router-dom'
import { Popup, ImagePreview, Pull, Toast } from 'zarm'
import CloseImages from '../../assets/close.png'
import style from './index.module.scss'
import 'swiper/swiper-bundle.min.css'
import { observer } from 'mobx-react'

import SwiperCore, {
  A11y,
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { contentRender } from './graduationMenu'

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
}

const styleContent: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-around',
}

const styleContentItem: React.CSSProperties = {
  width: '50%',
}

const styleItem: React.CSSProperties = {
  // width: "45%",
  padding: 10,
  position: 'relative',
  overflow: 'hidden',
}

export default (props: {
  eroll_id: string | number
  isAll: boolean
  watermark_settings?: string
  watermark_pellucidity: number
}) => {
  const { isAll = false } = props
  const pullRef = useRef()
  const [imagesList, setImagesList] = useState<string[]>([]) /// 存放 图片所有链接
  const [dataLeft, setDataLeft] = useState<
    { origin_img: string; url: string; originUrl: string; or_w_img: string }[]
  >([])
  const [dataRight, setDataRight] = useState<
    { origin_img: string; url: string; originUrl: string; or_w_img: string }[]
  >([])

  const [loading, setLoading] = useState(LOAD_STATE.normal)

  const [page, setPage] = useState(1)

  const [type, settype] = useState<'left' | 'right'>('left')
  const [isImagePreview, setImagePreview] = useState(false)
  const [pictureIndex, setPictureIndex] = useState(0)

  useEffect(() => {
    // document.title = '图片预览'
    GetPageGraduation()
  }, [])

  async function GetPageGraduation() {
    setLoading(LOAD_STATE.loading)
    let result = await FetchRequest('/wap/graduation/getAlbum/', {
      eroll_id: props.eroll_id,
      page,
      limit: isAll ? 300 : 10,
    })
    if (result.code === 1) {
      if (result.data.length === 0) {
        setLoading(LOAD_STATE.complete)
        return
      }
      let left = ([] as {
        origin_img: string
        url: string
        originUrl: string
        or_w_img: string
      }[]).concat(dataLeft)
      let right = ([] as {
        origin_img: string
        url: string
        originUrl: string
        or_w_img: string
      }[]).concat(dataRight)

      let images: string[] = ([] as string[]).concat(imagesList)

      for (let i = 0; i < result.data.length; i++) {
        const element = result.data[i]
        images.push(element.or_w_img)
        if (i % 2 === 0) {
          left.push({
            ...element,
            url: element.origin_img,
            originUrl: element.origin_img,
            or_w_img: element.or_w_img,
          })
        } else {
          right.push({
            ...element,
            url: element.origin_img,
            originUrl: element.origin_img,
            or_w_img: element.or_w_img,
          })
        }
      }

      setImagesList(images)

      setDataLeft(left)
      setDataRight(right)

      setPage(page + 1)
      setLoading(LOAD_STATE.success)
    } else {
      Toast.show(result.msg)
    }
  }

  return (
    <div style={{ background: '#eee' }}>
      <Pull
        ref={pullRef}
        load={{
          state: loading,
          distance: 200,
          handler: GetPageGraduation,
        }}
      >
        <div style={styleContent}>
          <div style={styleContentItem}>
            {dataLeft.map((v, i) => {
              return (
                <div
                  style={styleItem}
                  key={i}
                  onClick={() => {
                    settype('left')
                    setPictureIndex(imagesList.indexOf(v.or_w_img))
                    setImagePreview(true)
                  }}
                >
                  <img
                    src={v.or_w_img}
                    alt=""
                    style={{ width: '100%', border: '5px solid #fff' }}
                  />
                  <div
                    className={style.watermarkText}
                    style={{
                      opacity: props.watermark_pellucidity,
                    }}
                  >
                    {Array.from(Array(60), (v, k) => props.watermark_settings)}
                  </div>
                </div>
              )
            })}
          </div>
          <div style={styleContentItem}>
            {dataRight.map((v, i) => {
              return (
                <div
                  style={styleItem}
                  key={i}
                  onClick={() => {
                    settype('right')
                    setPictureIndex(imagesList.indexOf(v.or_w_img))
                    setImagePreview(true)
                  }}
                >
                  <img
                    src={v.or_w_img}
                    alt=""
                    style={{ width: '100%', border: '5px solid #fff' }}
                  />
                  <div
                    className={style.watermarkText}
                    style={{
                      opacity: props.watermark_pellucidity,
                    }}
                  >
                    {Array.from(Array(60), (v, k) => props.watermark_settings)}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Pull>

      <Popup
        visible={isImagePreview}
        direction="center"
        mask={true}
        afterClose={() => setImagePreview(false)}
      >
        <div style={{ height: '76vh', position: 'relative' }}>
          <Swiper
            className="main-container"
            effect="coverflow"
            initialSlide={pictureIndex}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            coverflowEffect={{ slideShadows: false }}
          >
            {imagesList.map((v, i) => {
              return (
                <SwiperSlide key={i} className="swiper-item">
                  <div
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      fontSize: 0,
                    }}
                  >
                    <img src={v} alt="失效" />
                    <div
                      className={style.watermarkText}
                      style={{
                        padding: 0,
                        opacity: props.watermark_pellucidity,
                      }}
                    >
                      {Array.from(
                        Array(130),
                        (v, k) => props.watermark_settings
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </Popup>

      {/* <ImagePreview
        visible={isImagePreview}
        images={imagesList}
        onClose={() => setImagePreview(false)}
        activeIndex={pictureIndex}
      /> */}
      {isImagePreview && (
        <div
          style={{ position: 'fixed', zIndex: 10000, top: 10, right: 10 }}
          onClick={() => setImagePreview(false)}
        >
          <img src={CloseImages} alt="" style={{ width: 50 }} />
        </div>
      )}
    </div>
  )
}
