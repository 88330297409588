import React from "react";
import "./employee-preorder-list.scss";
import { EmployeePreorderListDateSelectComponent } from "./components/employee-preorder-list-date-select.component";
import { add, format, isBefore, isSameDay, parseISO } from "date-fns";
import { BangBangTangLayout } from "../../layout/layout";
import { store } from "../../store/store.root";
import { observer } from "mobx-react";
import { EmployeePreorderCardListComponent } from "./components/employee-preorder-card-list.component";
import { EmployeePreorderChangeStatusModalComponent } from "./components/employee-preorder-change-status-modal.component";
import { Button, Calendar, NoticeBar, Picker, Popup } from "zarm";
import { combineLatest } from "rxjs";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";
import { EmployeePreorderListScheduleType } from "./components/employee-preorder-list.schedule-type";
import { MonthCalendar } from "../preorder-schedule/calendar-month";
import { preorderScheduleActions } from "../../store/preorder-schedule/preorder-schedule.action";
import { EmployeePreorderListCalendar } from "./components/employee-preorder-list.calendar/employee-preorder-list.calendar";

interface State {}

@observer
export class EmployeePreorderList extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    // 恶心吗?恶心就对了
    combineLatest([
      appIsFirstReadySubject,
      store.storeInfoStore.storeSelectObservable,
    ]).subscribe(() => {
      document.title = "预约单管理";
      store.employeePreorderListStore.startListen();
      if (store.employeePreorderListStore.schedule_type === null) {
        store.employeePreorderListStore.updateScheduleTypeFromServer();
      }
      const date = new URLSearchParams(location.search).get("date");
      if (date) {
        store.employeePreorderListStore.changeDate(parseISO(date));
      } else {
        if (store.employeePreorderListStore.date === null) {
          store.employeePreorderListStore.changeDate(new Date());
        }
      }
    });
  }

  render() {
    if (
      store.employeePreorderListStore.date === null ||
      store.employeePreorderListStore.schedule_type === null
    ) {
      return null;
    }
    return (
      <BangBangTangLayout>
        <div className="employee-preorder-list">
          {store.employeePreorderListStore.currentNotice &&
          store.employeePreorderListStore.currentNotice.length > 0 ? (
            <NoticeBar className={"notice-bar"}>
              {store.employeePreorderListStore.currentNotice
                .map((value) => value.notice)
                .join(",")}
            </NoticeBar>
          ) : null}
          <EmployeePreorderListScheduleType />
          <EmployeePreorderListDateSelectComponent
            date={store.employeePreorderListStore.date}
            onNext={() =>
              store.employeePreorderListStore.changeDate(
                add(store.employeePreorderListStore.date as Date, { days: 1 })
              )
            }
            onDisplayClick={() => {
              store.employeePreorderListStore.toggleDisplayCalendar();
            }}
            onPrev={() =>
              store.employeePreorderListStore.changeDate(
                add(store.employeePreorderListStore.date as Date, { days: -1 })
              )
            }
          />
          <EmployeePreorderCardListComponent
            key={format(store.employeePreorderListStore.date, "yyyy-MM-dd")}
          />
        </div>
        <EmployeePreorderListCalendar />
      </BangBangTangLayout>
    );
  }
}
