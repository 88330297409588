import React from "react";
import { Modal } from "zarm";
import { CloseRelationshipNumberModalLine } from "../close-relationship-number-modal-line/close-relationship-number-modal-line";
import { store } from "../../../store/store.root";
import { ValidCloseRelationship } from "../../../store/close-relationship-number.store";

interface Props {
  display: boolean;
  onCancel: () => void;
  onSelected: (value: ValidCloseRelationship) => void;
}

export class CloseRelationshipSelectModal extends React.Component<Props, {}> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Modal
        visible={this.props.display}
        maskClosable={true}
        closable={true}
        title="亲情号码"
        width={"80%"}
        onCancel={this.props.onCancel}
      >
        <div className="relationship-modal-tooltip">
          绑定亲情号码后,其他亲人通过亲情号码登陆后，也可以方便的看到孩子的照片啦
        </div>
        <CloseRelationshipNumberModalLine
          name={"爸爸"}
          onSetClicked={this.props.onSelected.bind(this, "father")}
          value={store.closeRelationshipNumberStore.father}
        />
        <CloseRelationshipNumberModalLine
          name={"妈妈"}
          onSetClicked={this.props.onSelected.bind(this, "mother")}
          value={store.closeRelationshipNumberStore.mother}
        />
        <CloseRelationshipNumberModalLine
          name={"爷爷"}
          onSetClicked={this.props.onSelected.bind(this, "father_s_father")}
          value={store.closeRelationshipNumberStore.father_s_father}
        />
        <CloseRelationshipNumberModalLine
          name={"奶奶"}
          onSetClicked={this.props.onSelected.bind(this, "father_s_mother")}
          value={store.closeRelationshipNumberStore.father_s_mother}
        />
        <CloseRelationshipNumberModalLine
          name={"外公"}
          onSetClicked={this.props.onSelected.bind(this, "mother_s_father")}
          value={store.closeRelationshipNumberStore.mother_s_father}
        />
        <CloseRelationshipNumberModalLine
          name={"外婆"}
          onSetClicked={this.props.onSelected.bind(this, "mother_s_mother")}
          value={store.closeRelationshipNumberStore.mother_s_mother}
        />
      </Modal>
    );
  }
}
