import React, { useState, useEffect, useRef, useCallback } from 'react'
import style from './index.module.scss'
import {
  Button,
  Cell,
  Input,
  Marquee,
  Popup,
  Toast,
  Radio,
  Checkbox,
  Modal,
  Loading,
  DatePicker,
} from 'zarm'
import MusicIamges from '../../assets/music.png'
import { store } from '../../store/store.root'
import { withRouter } from 'react-router-dom'
import { FetchRequest } from '../../utils/fetch'
import './test.css'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import { handleOnChangShare } from '../../utils'

type resultType = {
  consult_phone?: string
  details?: string[]
  end_time?: number
  head_diagram?: string
  kindergart?: string[]
  music?: string
  partake?: number
  partake_user?: Array<{ avatar: string; openname: string; id: number }>
  start_time?: number
  user_entry?: string[]
  views?: number
  xy?: string
  wechat_sharing_instructions?: string
  wechat_sharing_pic?: string
  wechat_sharing_title?: string
  user?: {
    baby_class: string
    baby_name: string
    is_eroll: number
    extra: any
    order_status: 1 | 2
    is_subscribe: 'yes' | 'no'
    num: string
    baby: {
      baby_class: string
      baby_name: string
      eroll_id: number
      extra: any
    }[]
  }
  qrcode_image?: string
}

export default observer(
  withRouter(
    (props: {
      match: {
        params: {
          id: string
        }
      }
    }) => {
      const parent = useRef()
      // 页面详情数据
      const [data, setData] = useState<resultType>({})

      useEffect(() => {
        document.title = '活动报名'
        if (store.userStore.token) {
          // handleOnChangShare()
          GetErollDetailMethod()
          GetCode()
          // 延时等待缓存写入
          setTimeout(() => {
            setInterval(ActionCountTime, 1000)
          }, 1000)
        }
      }, [store.userStore.token])

      // useEffect(() => {
      //   if (!store.userStore.token) {
      //     store.userStore.loginFromWechat(true)
      //   }
      // }, [store.userStore.token])

      // useEffect(() => {
      //   const locaToken = localStorage.getItem('token')
      //   if (!locaToken) {
      //     setTimeout(() => {
      //       const locaTokenNew = localStorage.getItem('token')
      //       if (locaTokenNew) {
      //         location.reload()
      //       }
      //     }, 3000)
      //   }
      // }, [])

      const [visible, setvisible] = useState(false)
      // 验证码
      const [CountDownSecond, setCountDownSecond] = useState(60)
      // 活动倒计时
      const [actionTime, setactionTime] = useState('-天-时-分-秒')
      // 播放
      const [musicPlay, setMusicPlay] = useState(false)
      // 活动状态 0 未开始 1 结束 2 开放
      const [actionStatus, setactionStatus] = useState<0 | 1 | 2>(0)
      // 出生日期状态
      const [startTime, setStartTime] = useState(false)
      // 出生日期值
      const [startTimeValue, setStartTartTimeValue] = useState('')
      // 音乐实例
      const [AudioInit, setAudioInit] = useState<any>(null)

      const [isSubscribe, setIsSubscribe] = useState(false)

      // useEffect(() => {
      //     // var mp3: any  = new Audio(data.music);
      //     if (data.music) {
      //         var mp3: any = document.getElementById("myAudio");
      //         mp3.loop = true
      //         if (musicPlay) {
      //             mp3.play(); //播放 mp3这个音频对象
      //         } else {
      //             console.log("停止")
      //             mp3.pause();
      //             // mp3.load();
      //         }
      //     }

      // }, [data.music, musicPlay])

      useEffect(() => {
        if (AudioInit) {
          if (musicPlay) {
            AudioInit.play()
            console.log('开始')
          } else {
            AudioInit.pause()
            console.log('关闭')
          }
        }
      }, [musicPlay])

      //60s倒计时
      const handleCountDown = async (seconds = 60) => {
        // 判断是否存在手机
        var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
        if (!myreg.test(signUp.phone)) {
          Toast.show('手机号码格式不正确')
          return
        }
        // 发送请求
        let resultCode = await FetchRequest('/api/sms/send', {
          mobile: signUp.phone,
          event: 'register',
        })
        if (resultCode.code !== 1) {
          Toast.show(resultCode.msg)
          return
        }
        Toast.show(resultCode.msg)
        // let second = 60;
        let second = seconds
        const countDown = () => {
          // timer && clearTimeout(timer); //清除计时器
          if (second > 0) {
            // let second =
            // countDownSecond--;
            // let a  = countDownSecond - 1;
            second--
            setCountDownSecond(second)
          }
          if (second === 0) {
            /*
                    _this.countDown = 60;//恢复60s设定
                    _this.isVerify = false;//不显示60s计时器
                    clearTimeout(count60Timeout)//清除计时器
                    return //结束方法的调用
                */
            second = 60
            setCountDownSecond(second)
            // clearTimeout( timer ); //清除计时器
            return
          }
          // let timer = setTimeout( countDown,1000 );
          setTimeout(countDown, 1000)
        }
        setTimeout(countDown, 1000)
      }

      function ActionCountTime() {
        var oDate = new Date() //获取日期对象
        var oldTime = oDate.getTime() //现在距离1970年的毫秒数

        var newDate = new Date('2021/10/1 00:00:00')
        // var newTime = newDate.getTime();//2019年距离1970年的毫秒数
        // let newTime = data.start_time! * 1000
        let newTime = Number(localStorage.getItem('action_time')) || 0
        var second = Math.floor((newTime - oldTime) / 1000) //未来时间距离现在的秒数
        var day = Math.floor(second / 86400) //整数部分代表的是天；一天有24*60*60=86400秒 ；
        second = second % 86400 //余数代表剩下的秒数；
        var hour = Math.floor(second / 3600) //整数部分代表小时；
        second %= 3600 //余数代表 剩下的秒数；
        var minute = Math.floor(second / 60)
        second %= 60
        setactionTime(
          `${tow(day)}天${tow(hour)}时${tow(minute)}分${tow(second)}秒`.replace(
            /-/g,
            ''
          )
        )
        // console.log(tow(day),tow(hour),tow(minute),tow(second))
      }

      function tow(n: string | number) {
        return n >= 0 && n < 10 ? '0' + n : '' + n
      }

      const [pickClass, setPickClass] = useState([])

      const [NewListS, setNewListS] = useState<any>([])

      async function GetErollDetailMethod() {
        // if (Object.keys(data).length > 0) return
        Loading.show()
        // try {
        let result = await FetchRequest('/wap/selectioneroll/getErollDetail', {
          id: props.match.params.id,
          token: localStorage.getItem('token') || store.userStore.token,
        })
        if (result.code === 1) {
          setData(result.data)

          let newArray = []

          for (let i = 0; i < result.data.partake_user!.length; i++) {
            if (result.data.partake_user!.length >= 5) {
              newArray.push(result.data.partake_user!.splice(0, 5))
            } else {
              newArray.push(result.data.partake_user)
              break
            }
          }
          setNewListS(newArray)
          if (new Date().getTime() > result.data.start_time * 1000) {
            if (new Date().getTime() < result.data.end_time * 1000) {
              // 已经开始了
              console.log('已经开始了')
              setactionStatus(2)
              localStorage.setItem(
                'action_time',
                `${result.data.end_time! * 1000}`
              )
            } else {
              // 活动结束了
              console.log('活动结束了')
              setactionStatus(1)
              return
            }
          } else {
            // 未开始
            console.log('未开始')
            setactionStatus(0)
            localStorage.setItem(
              'action_time',
              `${result.data.start_time! * 1000}`
            )
          }
          let arr: any = []
          const list = result.data.kindergart || []
          for (let i = 0; i < list.length; i++) {
            const element = list[i]
            arr.push({
              value: element,
              label: element,
            })
          }
          setPickClass(arr)
          // 生日
          if (result.data.user_entry) {
            if (result.data.user_entry.indexOf('出生日期') > -1) {
              if (result.data.user.extra) {
                setStartTartTimeValue(result.data.user.extra['出生日期'])
              }
            }
          }
          if (!AudioInit) {
            let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
              currenturl: window.location.href,
            })

            if (SDKParams.code === 1) {
              const { wx }: any = window
              const {
                appId,
                nonceStr,
                signature,
                timestamp,
                jsApiList,
              } = SDKParams.data
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId, // 必填，公众号的唯一标识
                timestamp, // 必填，生成签名的时间戳
                nonceStr, // 必填，生成签名的随机串
                signature, // 必填，签名
                jsApiList, // 必填，需要使用的JS接口列表
              })

              wx.ready(function () {
                //需在用户可能点击分享按钮前就先调用
                console.log('开始播放音乐')

                var mp3: any = new Audio(data.music)
                mp3.src = result.data.music
                mp3.loop = true
                // mp3.play()
                setAudioInit(mp3)
                setMusicPlay(true)

                wx.updateAppMessageShareData({
                  title: result.data.wechat_sharing_instructions, // 分享标题
                  desc: result.data.wechat_sharing_title, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: result.data.wechat_sharing_pic, // 分享图标
                  success: function (res: any) {
                    // 设置成功
                  },
                })
              })

              wx.error(function (res: any) {
                // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
              })
            } else {
              Toast.show('JsSdk获取失败')
            }
          }
        } else {
          Toast.show(result.msg)
        }
        // } catch (error) {
        //     // location.reload();
        //     const modal = Modal.alert({
        //         title: '发生错误',
        //         content: error,
        //         onCancel: () => {
        //             location.reload()
        //         },
        //     });
        // }
        Loading.hide()
      }

      const [signUp, setSignUp] = useState<{
        act_id: string
        baby_name: string
        phone: string
        extra: string[]
        baby_class?: string
        code: string
      }>({
        act_id: props.match.params.id,
        baby_name: '',
        phone: '',
        extra: [],
        baby_class: '',
        code: '',
      })

      useEffect(() => {
        if (data.user?.is_eroll === 1) {
          setSignUp({
            ...signUp,
            baby_name: data.user.baby_name,
            baby_class: data.user.baby_class,
          })
        }
      }, [data.user])

      const [classPickSstatus, setclassPickSstatus] = useState(false)

      const [checked, setChecked] = useState(false)

      const [IamgesBase64, setIamgesBase64] = useState('')

      const [
        registrationInformationSwitch,
        setRegistrationInformationSwitch,
      ] = useState(false)

      const [theEchoData, setTheEchoData] = useState<any>({})

      async function GetCode() {
        let res = await FetchRequest('/wap/selectioneroll/getImage', {})
        if (res.code === 1) {
          setIamgesBase64(res.data)
        }
      }

      function handleSuccessAlertTips() {
        setvisible(false)
        GetErollDetailMethod()
        // const modal = Modal.alert({
        //   title: '您已报名成功',
        //   content: (
        //     <div>
        //       <div>
        //         <img src={IamgesBase64} style={{ width: '60%' }} alt="" />
        //       </div>
        //       <div style={{ color: 'red', textAlign: 'center' }}>
        //         长按关注公众号可下载底片
        //       </div>
        //     </div>
        //   ),
        //   onCancel: () => {
        //     modal.hide()
        //   },
        // })
        // return
      }

      async function handleClickSunForm() {
        if (!checked) {
          Toast.show('请先同意用户协议')
          return
        }
        let isOk = false
        let str = ''
        for (let i = 0; i < signUp.extra.length; i++) {
          const item: any = signUp.extra[i]
          if (!item) continue
          for (let j = 0; j < Object.keys(item).length; j++) {
            const element = Object.keys(item)[j]
            if (!item[element]) {
              Toast.show(`请填写${element}`)
              isOk = true
              break
            }
            str += `"${element}":"${item[element]}",`
          }
        }

        if (isOk) return

        // 参数校验
        if (!signUp.baby_name) {
          Toast.show('请输入宝贝名称')
          return
        }

        if (!startTimeValue) {
          Toast.show('请选择出生年月')
          return
        }

        if (!signUp.baby_class && pickClass.length > 0) {
          Toast.show('请选择班级')
          return
        }
        if (!store.userStore.mobile && data.user?.is_eroll !== 1) {
          // 没有报过名
          if (!signUp.phone) {
            Toast.show('请输入手机')
            return
          }
          // 验证码
          let resultCodeStaus = await FetchRequest('/api/sms/check', {
            mobile: signUp.phone,
            event: 'register',
            captcha: signUp.code,
          })
          if (resultCodeStaus.code === 0) {
            Toast.show(resultCodeStaus.msg)
            return
          }
          // 保存手机号
          // if (!store.userStore.mobile) {
          //   await FetchRequest('/wap/wap2/saveMobile', {
          //     mobile: signUp.phone,
          //   })
          // }
        }

        // 提交
        let result = await FetchRequest('/wap/selectioneroll/submitEroll', {
          ...signUp,
          birthday: new Date(startTimeValue).getTime() / 1000,
          act_id: props.match.params.id,
          extra: `{${str}}`.replace(/,}/, '}'),
          // wap_openid: localStorage.getItem('wap_openid') || '',
          // baby: JSON.stringify([
          //   {
          //     baby_class: '大班',
          //     baby_name: '2132132',
          //     eroll_id: 54,
          //     extra: `{}`,
          //   },
          //   {
          //     baby_class: '大班',
          //     baby_name: '123123123123123123',
          //     eroll_id: 55,
          //     extra: `{}`,
          //   },
          // ]),
        })
        Loading.hide()
        setvisible(false)
        GetErollDetailMethod()
        if (result.code === 1) {
          if (!store.userStore.mobile) {
            await FetchRequest('/wap/wap2/saveMobile', {
              mobile: signUp.phone,
              token: localStorage.getItem('token') || store.userStore.token,
            })
          }
          localStorage.setItem('token', result.data.token || '')
          if (!store.userStore.mobile) {
            // 空
            if (result.data.price === '0.00') {
              let res = await FetchRequest('/wap/selectioneroll/erollPay', {
                eroll_id: result.data.eroll_id,
                // token: localStorage.getItem('token'),
              })
              if (res.code === 1) {
                Toast.show('报名成功')
                // store.routerStore.replace(`/build/selection-activities/${result.data.act_id}`)
                handleSuccessAlertTips()
              }
            } else {
              store.routerStore.push(
                `/build/activity-registration-pay?eroll_id=${result.data.eroll_id}&id=${props.match.params.id}`
              )
            }
          } else {
            // 不为空
            if (data.user?.is_eroll === 0) {
              // 未支付
              if (result.data.price === '0.00') {
                let res = await FetchRequest('/wap/selectioneroll/erollPay', {
                  eroll_id: result.data.eroll_id,
                  // token: localStorage.getItem('token'),
                })
                if (res.code === 1) {
                  Toast.show('报名成功')
                  // store.routerStore.replace(`/build/selection-activities/${result.data.act_id}`)
                  handleSuccessAlertTips()
                }
              } else {
                store.routerStore.push(
                  `/build/activity-registration-pay?eroll_id=${result.data.eroll_id}&id=${props.match.params.id}`
                )
              }
            } else {
              if (result.data.price === '0.00') {
                let res = await FetchRequest('/wap/selectioneroll/erollPay', {
                  eroll_id: result.data.eroll_id,
                  // token: localStorage.getItem('token'),
                })
                if (res.code === 1) {
                  Toast.show('报名成功')
                  // store.routerStore.replace(`/build/selection-activities/${result.data.act_id}`)
                  handleSuccessAlertTips()
                }
              } else {
                store.routerStore.push(
                  `/build/activity-registration-pay?eroll_id=${result.data.eroll_id}&id=${props.match.params.id}`
                )
              }
            }
          }
          setStartTartTimeValue('')
          // Toast.show('报名成功')
        } else {
          Toast.show(result.msg)
        }
      }

      if (data.end_time! <= 0) {
        return null
      }

      return (
        <div className={style.ActivityRegistration}>
          <div className={style.ActivityRegistration_warp}>
            <img src={data.head_diagram} alt="" />
          </div>

          <div className="bodyBox">
            <div className="title_count">已有 {data.partake} 人参与</div>
            <div className="title_count">浏览量 {data.views} 人</div>
            <Marquee direction="up" width={'100%'} height={180}>
              {NewListS.map((v: any[], index: React.Key | null | undefined) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignContent: 'center',
                      padding: 5,
                    }}
                    key={index}
                  >
                    {v?.map((j, index_son) => {
                      return (
                        <img
                          src={j.avatar}
                          key={index_son}
                          alt=""
                          style={{ width: 60, height: 60, borderRadius: '50%' }}
                        />
                      )
                    })}
                  </div>
                )
              })}
              <div style={{ width: '100%', height: 120 }}></div>
            </Marquee>
            {/* <div id="parent" className="parent">
                    <ul id="child1" className="child comminChild" style={{ display: 'flex' }}>
                    
                        {data.partake_user?.map((v, index) => {
                            return (
                                <li key={v.id + Math.floor(Math.random() * (1000 - 100)) + 100}>
                                    <div>
                                        <img src={v.avatar} alt="" />
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <div id="child2" className="child comminChild"></div>
                </div> */}
            {/* <Button block theme="primary" className={style.regist_btn}>已有 {data.partake} 人参与/浏览量 {data.views} 人</Button> */}
          </div>
          <div style={{ paddingBottom: 100 }}>
            {data.details?.map((v, index) => {
              return (
                <div className={style.images_list} key={index}>
                  <img src={v} alt="" />
                </div>
              )
            })}
          </div>
          <div className={style.btn_bottom}>
            <a href={`tel:${data.consult_phone}`} className={style.btn_left}>
              立即咨询
            </a>
            <div
              className={style.btn_right}
              style={
                data.user?.is_eroll === 1
                  ? { background: 'hsl(156deg 100% 33%)' }
                  : {}
              }
              onClick={() => {
                /// 调用登录
                // if (!store.userStore.token) {
                //   localStorage.setItem('scope', 'snsapi_userinfo')
                //   store.userStore.loginFromWechat(true)
                // }
                if (actionStatus === 2) {
                  if (data.user?.is_subscribe === 'yes') {
                    setvisible(true)
                    // if (data.user.num !== '大宝' || data.user.is_eroll === 0) {
                    //   // 判断是否存在已经报名的
                    //   // if (data.user.baby.length > 0) {
                    //   //   //// 已经有宝宝报名了
                    //   //   setRegistrationInformationSwitch(true)
                    //   // } else {
                    //   //   //// 第一次报名
                    //   //   setvisible(true)
                    //   // }
                    // } else {
                    //   Toast.show('已给三个宝宝报名 无法再次报名')
                    // }
                  } else {
                    setIsSubscribe(true)
                  }
                }
              }}
            >
              <p>
                {data.user?.is_eroll === 1
                  ? data.user.num === '大宝'
                    ? '我已报名'
                    : `继续给${data.user.num}报名`
                  : '我要报名'}
              </p>
              {actionStatus === 0 && <p>活动未开始: {actionTime}</p>}
              {actionStatus === 1 && <p>活动已结束</p>}
              {actionStatus === 2 && <p>{actionTime} 后结束</p>}
            </div>
          </div>
          <div
            className={style.Music_icon}
            onClick={(e) => {
              e.stopPropagation()
              setMusicPlay(!musicPlay)
            }}
          >
            <img
              src={MusicIamges}
              alt=""
              style={!musicPlay ? { animation: 'none' } : {}}
            />
          </div>

          <audio loop={true} src={data.music} id="myAudio" />

          <Popup
            visible={visible}
            direction="bottom"
            mask={true}
            afterClose={() => console.log('关闭')}
            onMaskClick={() => setvisible(false)}
          >
            <div className={style.popuo_Content}>
              {(data.user?.baby || []).map((v, i) => {
                return (
                  <div className={style.theRegistrationContent} key={i}>
                    <div className={style.lable_left}>
                      <div className={style.lable_name}>姓名:</div>
                      <div className={style.lable_value}>
                        {v.baby_name}
                        <span>( 已报名 )</span>
                      </div>
                    </div>
                    <div className={style.lable_left}>
                      <div className={style.lable_name}>班级:</div>
                      <div className={style.lable_value}>{v.baby_class}</div>
                    </div>
                    {data.user_entry?.map((s, i) => {
                      return (
                        <div className={style.lable_left} key={i}>
                          <div className={style.lable_name}>{s}:</div>
                          <div className={style.lable_value}>{v.extra[s]}</div>
                        </div>
                      )
                    })}
                    <div className={style.atTheBottomOfTheInstructions}>
                      {/* <div>【{v.baby_name}】已报名</div> */}
                      <div
                        className={style.restUpdate}
                        onClick={() => {
                          setTheEchoData(v)
                          setRegistrationInformationSwitch(true)
                        }}
                      >
                        重新编辑信息
                      </div>
                    </div>
                  </div>
                )
              })}
              {data.user?.num !== '大宝' || data.user?.is_eroll === 0 ? (
                <div onClick={() => setTheEchoData({})}>
                  <Cell
                    title={
                      <div>
                        宝贝姓名
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                  >
                    <Input
                      type="text"
                      placeholder="请输入宝贝姓名"
                      value={signUp.baby_name}
                      onChange={(e: any) =>
                        setSignUp({ ...signUp, baby_name: e })
                      }
                    />
                  </Cell>
                  {data.user_entry?.map((v, index: number) => {
                    return (
                      <Cell
                        key={index}
                        title={
                          <div>
                            {v}
                            <span style={{ color: 'red' }}>*</span>
                          </div>
                        }
                      >
                        {v.indexOf('出生') > -1 ? (
                          <div
                            onClick={() => {
                              setStartTime(true)
                            }}
                          >
                            <Input
                              type="text"
                              placeholder="请选择出生日期"
                              value={startTimeValue}
                            />
                          </div>
                        ) : (
                          <Input
                            type="text"
                            placeholder={`请输入${v}`}
                            defaultValue={
                              data.user?.extra ? data.user?.extra[v] : ''
                            }
                            onChange={(e: any) => {
                              let obj: any = {}
                              obj[v] = e
                              signUp.extra[index] = obj
                            }}
                          />
                        )}
                      </Cell>
                    )
                  })}
                  {pickClass.length > 0 && (
                    <Cell
                      title={
                        <div>
                          班级
                          <span style={{ color: 'red' }}>*</span>
                        </div>
                      }
                      onClick={() => setclassPickSstatus(true)}
                    >
                      <div>
                        {signUp.baby_class ? signUp.baby_class : '请选择班级'}
                      </div>
                      {/* <Input type="text" placeholder="请输入班级" value={signUp.baby_class} /> */}
                    </Cell>
                  )}
                  {!store.userStore.mobile && data.user?.is_eroll !== 1 && (
                    <>
                      <Cell
                        title={
                          <div>
                            手机
                            <span style={{ color: 'red' }}>*</span>
                          </div>
                        }
                      >
                        <Input
                          type="text"
                          placeholder="请输入手机号码"
                          value={signUp.phone}
                          onChange={(e: any) =>
                            setSignUp({ ...signUp, phone: e })
                          }
                        />
                        {CountDownSecond !== 60 ? (
                          <Button
                            theme="primary"
                            size="xs"
                            style={{ width: 120 }}
                          >
                            {CountDownSecond}s
                          </Button>
                        ) : (
                          <Button
                            theme="primary"
                            size="xs"
                            style={{ width: 120 }}
                            onClick={() => handleCountDown()}
                          >
                            发送验证码
                          </Button>
                        )}
                      </Cell>
                      <Cell title="验证码">
                        <Input
                          type="text"
                          placeholder="请输入验证码"
                          value={signUp.code}
                          onChange={(e: any) =>
                            setSignUp({ ...signUp, code: e })
                          }
                        />
                      </Cell>
                    </>
                  )}
                  <Cell>
                    <Checkbox
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e!.target.checked)
                      }}
                    />
                    <label htmlFor="agreement" style={{ marginLeft: 5 }}>
                      阅读并同意
                      <a
                        href="/#"
                        onClick={(e) => {
                          e.preventDefault()
                          const modal = Modal.alert({
                            title: '用户协议条款',
                            content: (
                              <div
                                dangerouslySetInnerHTML={{ __html: data.xy! }}
                              ></div>
                            ),
                            onCancel: () => {
                              modal.hide()
                            },
                          })
                        }}
                      >
                        《用户协议条款》
                      </a>
                      中的相关规定
                    </label>
                  </Cell>
                  <Button
                    block
                    theme="primary"
                    style={{ marginTop: 50, position: 'fixed', bottom: 0 }}
                    onClick={handleClickSunForm}
                  >
                    立即报名
                  </Button>
                </div>
              ) : (
                <Button
                  block
                  theme="danger"
                  style={{ marginTop: 50, position: 'fixed', bottom: 0 }}
                  onClick={() => setvisible(false)}
                >
                  关闭
                </Button>
              )}
            </div>
          </Popup>

          <Popup
            visible={registrationInformationSwitch}
            direction="bottom"
            mask={true}
            afterClose={() => console.log('关闭')}
            onMaskClick={() => setRegistrationInformationSwitch(false)}
          >
            <div
              className={style.popuo_Content}
              style={{
                height: '40vh',
              }}
            >
              <Cell
                title={
                  <div>
                    宝贝姓名
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Input
                  type="text"
                  placeholder="请输入宝贝姓名"
                  value={theEchoData.baby_name}
                  onChange={(e: any) =>
                    setTheEchoData({ ...theEchoData, baby_name: e })
                  }
                />
              </Cell>
              {data.user_entry?.map((v, index: number) => {
                return (
                  <Cell
                    key={index}
                    title={
                      <div>
                        {v}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                  >
                    {v.indexOf('出生') > -1 ? (
                      <div
                        onClick={() => {
                          setStartTime(true)
                        }}
                      >
                        <Input
                          type="text"
                          placeholder="请选择出生日期"
                          value={
                            registrationInformationSwitch
                              ? theEchoData.extra['出生年月']
                              : ''
                          }
                        />
                      </div>
                    ) : (
                      <Input
                        type="text"
                        placeholder={`请输入${v}`}
                        defaultValue={
                          // data.user?.extra ? data.user?.extra[v] : ''
                          theEchoData.extra ? theEchoData.extra[v] : ''
                        }
                        onChange={(e: any) => {
                          let obj: any = Object.assign(theEchoData.extra, {})
                          obj[v] = e
                          setTheEchoData({
                            ...theEchoData,
                            extra: {
                              ...obj,
                            },
                          })
                        }}
                      />
                    )}
                  </Cell>
                )
              })}
              {pickClass.length > 0 && (
                <Cell
                  title={
                    <div>
                      班级
                      <span style={{ color: 'red' }}>*</span>
                    </div>
                  }
                  onClick={() => setclassPickSstatus(true)}
                >
                  <div>
                    {theEchoData.baby_class
                      ? theEchoData.baby_class
                      : '请选择班级'}
                  </div>
                  {/* <Input type="text" placeholder="请输入班级" value={signUp.baby_class} /> */}
                </Cell>
              )}
              {!store.userStore.mobile && data.user?.is_eroll !== 1 && (
                <>
                  <Cell
                    title={
                      <div>
                        手机
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                  >
                    <Input
                      type="text"
                      placeholder="请输入手机号码"
                      value={signUp.phone}
                      onChange={(e: any) => setSignUp({ ...signUp, phone: e })}
                    />
                    {CountDownSecond !== 60 ? (
                      <Button theme="primary" size="xs" style={{ width: 120 }}>
                        {CountDownSecond}s
                      </Button>
                    ) : (
                      <Button
                        theme="primary"
                        size="xs"
                        style={{ width: 120 }}
                        onClick={() => handleCountDown()}
                      >
                        发送验证码
                      </Button>
                    )}
                  </Cell>
                  <Cell title="验证码">
                    <Input
                      type="text"
                      placeholder="请输入验证码"
                      value={signUp.code}
                      onChange={(e: any) => setSignUp({ ...signUp, code: e })}
                    />
                  </Cell>
                </>
              )}
              <Button
                block
                theme="primary"
                style={{ marginTop: 50, position: 'fixed', bottom: 0 }}
                onClick={async () => {
                  let d = Object.assign(data, {})
                  for (let i = 0; i < (d.user?.baby || []).length; i++) {
                    const element = (d.user?.baby || [])[i]
                    if (element.eroll_id === theEchoData.eroll_id) {
                      d.user?.baby.splice(i, 1)
                      d.user?.baby.push(theEchoData)
                    }
                  }
                  setData(d)
                  Loading.show()
                  const res = await FetchRequest(
                    '/wap/Selectioneroll/editEroll',
                    {
                      ...theEchoData,
                      token: store.userStore.token,
                      extra: JSON.stringify(theEchoData.extra),
                      birthday:
                        new Date(theEchoData.extra['出生年月']).getTime() /
                        1000,
                    }
                  )
                  Loading.hide()
                  if (res.code === 1) {
                    setStartTartTimeValue('')
                    Toast.show('更新成功')
                  } else {
                    // Toast.show('更新失败')
                  }
                  setRegistrationInformationSwitch(false)
                }}
              >
                编辑完成
              </Button>
            </div>
          </Popup>

          {/* <Popup
            visible={registrationInformationSwitch}
            direction="bottom"
            mask={true}
            afterClose={() => console.log('关闭')}
            onMaskClick={() => setRegistrationInformationSwitch(false)}
          >
            <div className={style.popuo_Content}>
              {data.user?.baby.map((v, i) => {
                return (
                  <div className={style.theRegistrationContent} key={i}>
                    <Cell title="姓名" description={v.baby_name} />
                    <Cell title="班级" description={v.baby_class} />
                    {data.user_entry?.map((v, i) => {
                      return <Cell key={i} title={v} description="描述文字" />
                    })}
                    <div className={style.atTheBottomOfTheInstructions}>
                      <div>【{v.baby_name}】已报名</div>
                      <div className={style.restUpdate}>重新编辑信息</div>
                    </div>
                  </div>
                )
              })}
              <Button
                block
                theme="primary"
                style={{ marginTop: 50 }}
                onClick={() => {
                  setRegistrationInformationSwitch(false)
                  setvisible(true)
                }}
              >
                继续报名
              </Button>
            </div>
          </Popup> */}

          {/* 班级 */}
          <DatePicker
            visible={startTime}
            mode="date"
            value={startTimeValue}
            onOk={(value: any) => {
              if (Object.keys(theEchoData).length > 0) {
                // 编辑
                let obj = Object.assign(theEchoData.extra, {})
                obj[
                  Object.keys(theEchoData.extra).filter(
                    (v) => v.indexOf('出生') > -1
                  )[0]
                ] = format(value, 'yyyy-MM-dd')

                setTheEchoData({
                  ...theEchoData,
                  extra: obj,
                })
                setStartTime(false)
              } else {
                setStartTartTimeValue(format(value, 'yyyy-MM-dd'))
                setStartTime(false)

                if (data.user_entry!.indexOf('出生日期') > -1) {
                  let obj: any = {}
                  obj[data.user_entry![0]] = format(value, 'yyyy-MM-dd')
                  signUp.extra[data.user_entry!.indexOf('出生日期')] = obj
                }
              }
            }}
            onCancel={() => setStartTime(false)}
          />

          <Popup
            visible={classPickSstatus}
            direction="bottom"
            mask={true}
            afterClose={() => console.log('关闭')}
            onMaskClick={() => setclassPickSstatus(false)}
          >
            <Radio.Group
              type="cell"
              value={signUp.baby_class}
              onChange={(e) => {
                if (Object.keys(theEchoData).length > 0) {
                  setTheEchoData({
                    ...theEchoData,
                    baby_class: String(e),
                  })
                } else {
                  if (pickClass.length === 0) {
                    setSignUp({ ...signUp, baby_class: '' })
                  } else {
                    setSignUp({ ...signUp, baby_class: String(e) })
                  }
                }
                setclassPickSstatus(false)
              }}
              style={{ maxHeight: '60vh', overflowY: 'scroll' }}
            >
              {pickClass.map((v: { value: string }, index) => {
                return (
                  <Radio value={v.value} key={index}>
                    {v.value}
                  </Radio>
                )
              })}
            </Radio.Group>
          </Popup>

          <Popup
            visible={isSubscribe}
            direction="center"
            width="70%"
            afterClose={() => console.log('关闭')}
          >
            <div className={style.center_alert}>
              <div className="qrm">
                <div className="img">
                  <img
                    src={data.wechat_sharing_pic}
                    style={{
                      width: '100%',
                    }}
                  />
                </div>
                <div
                  style={{
                    position: 'relative',
                    fontSize: 13,
                    padding: 10,
                  }}
                >
                  <div style={{ padding: '10px 0' }}>
                    {data.wechat_sharing_title}
                  </div>
                  <div className="ewm">
                    <div className="gzh">
                      报名流程：
                      <br />
                      ① 长按或扫描识别二维码
                      <br />
                      ② 关注公众号
                      <br />③ 点击报名链接~
                    </div>
                    <div className="eph">
                      <img
                        src={data.qrcode_image}
                        style={{
                          position: 'absolute',
                          right: 11,
                          bottom: 2,
                          width: 110,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      )
    }
  )
)
