import React, { useState, useEffect } from 'react'
import { FetchRequest } from '../../utils/fetch'
import { withRouter } from 'react-router-dom'
import {
  ActivityIndicator,
  Button,
  ImagePreview,
  Loading,
  Modal,
  Progress,
  Toast,
} from 'zarm'
import style from './index.module.scss'
import { store } from '../../store/store.root'
import './style.css'

export type IresultType = {
  code: number
  data: {
    complete: number
    is_submit: number
    modes: {
      combineUrl?: string
      fileType?: number
      name?: string
      modeUrl?: string
      width?: number
      height?: number
    }[]
    plate: number
    tips: string[]
  }
  msg: string
}

export default withRouter(
  (props: {
    match: {
      params: {
        activity_id: string
        eroll_id: string
      }
    }
  }) => {
    useEffect(() => {
      document.title = '图片预览'
      getModel()
      GetProgress()
      GetCode()
    }, [])

    const [CombineUrlList, setCombineUrlList] = useState<
      {
        url: string
        fileType: number
        name: string
        baseImages: string
        width: number
        height: number
      }[]
    >([])

    const [complete, setComplete] = useState(0)
    const [is_submit, setis_submit] = useState(0)
    const [plate, setPlate] = useState(0)

    const [tipes, setTipes] = useState<string[]>([])

    const [imagesView, setimagesView] = useState<string[]>([])

    const [actionIndex, setActionIndex] = useState(0)

    const [ImagePreviewStatus, setImagePreviewStatus] = useState(false)

    const [ProgressSize, setProgress] = useState(0)

    const [timer, setTimer] = useState<any>(null)

    const [IamgesBase64, setIamgesBase64] = useState('')

    async function GetCode() {
      let res = await FetchRequest('/wap/graduation/getImage', {
        token: localStorage.getItem('token'),
      })
      if (res.code === 1) {
        setIamgesBase64(res.data)
      }
    }

    async function getModel() {
      Loading.show({
        content: <ActivityIndicator size="lg" />,
      })
      let result: IresultType = await FetchRequest('/wap/graduation/getMode/', {
        token: localStorage.getItem('token'),
        activity_id: props.match.params.activity_id,
        eroll_id: props.match.params.eroll_id,
      })
      if (result.code === 1) {
        setPlate(result.data.plate)
        setis_submit(result.data.is_submit)
        setComplete(result.data.complete)
        setTipes(result.data.tips)
        if (result.data.complete !== 1) {
          // const theTimer = setTimeout(() => {
          //   GetProgress()
          // }, 5000)
          // setTimer(theTimer)
        } else {
          if (CombineUrlList.length > 0) return
          const list: {
            url: string
            fileType: number
            name: string
            baseImages: string
            width: number
            height: number
          }[] = []
          const listView: string[] = []
          for (let i = 0; i < result.data.modes.length; i++) {
            const element = result.data.modes[i]
            if (
              element.fileType === 4 ||
              element.combineUrl ||
              element.name === '相册封面' ||
              element.name === '固定版面'
            ) {
              list.push({
                url: element.combineUrl || element.modeUrl!,
                fileType: element.fileType!,
                name: element.name!,
                baseImages: element.modeUrl!,
                width: element.width!,
                height: element.height!,
              })
              listView.push(element.combineUrl || element.modeUrl!)
            } else {
              // if (element.name === '相册封面' || element.name === '固定版面') {
              //   list.push({
              //     url: element.modeUrl!,
              //     fileType: element.fileType!,
              //   })
              // }
            }
          }
          setCombineUrlList(list)
          setimagesView(listView)
        }
      } else {
        Toast.show(result.msg)
      }
      Loading.hide()
    }

    async function GetProgress(is_ten: number = 0) {
      let result = await FetchRequest('/wap/graduation/jindutiao', {
        token: localStorage.getItem('token'),
        activity_id: props.match.params.activity_id,
        eroll_id: props.match.params.eroll_id,
        is_ten,
      })

      if (result.code === 1) {
        if (result.data !== 100) {
          setProgress(result.data)
          if (!timer) {
            const theTimer = setTimeout(() => {
              store.userStore.isReqSize += 1
              if (store.userStore.isReqSize % 2 === 1) {
                GetProgress(1)
              } else {
                GetProgress()
              }
            }, 5000)
            setTimer(theTimer)
          }
        } else {
          // location.reload()
          clearTimeout(timer)
          setProgress(result.data)
          getModel()
        }
      }
    }

    async function handleClickSave() {
      const modal = Modal.confirm({
        title: '提示',
        content: (
          <>
            提交后将不可修改，确认提交？
            {plate === 2 && (
              <div>
                {tipes.map((v, i) => {
                  return <div key={i}>{v}</div>
                })}
              </div>
            )}
          </>
        ),
        onCancel: () => {
          console.log('点击cancel')
        },
        onOk: async () => {
          Loading.show({
            content: <ActivityIndicator size="lg" />,
          })
          let result = await FetchRequest('/wap/graduation/submitMode', {
            token: localStorage.getItem('token'),
            activity_id: props.match.params.activity_id,
            eroll_id: props.match.params.eroll_id,
          })
          Loading.hide()
          if (result.code === 1) {
            if (plate === 2) {
            } else {
              const modal = Modal.alert({
                title: '制作成功',
                content: (
                  <div>
                    <div>
                      <img src={IamgesBase64} style={{ width: '60%' }} alt="" />
                    </div>
                    <div style={{ color: 'red', textAlign: 'center' }}>
                      【下载底片】需长按关注此公众号
                    </div>
                  </div>
                ),
                onCancel: () => {
                  modal.hide()
                  if (plate === 1) {
                    // 跳转下载页
                    store.routerStore.replace('/build/film-download')
                  }
                  // store.routerStore.replace(
                  //   `/build/graduation-select/${props.match.params.activity_id}?eroll_id=${props.match.params.eroll_id}`
                  // )
                },
              })
            }
            getModel()
            Loading.hide()
          } else {
            // Toast.show(result.msg)
            const modal = Modal.alert({
              title: '提示',
              content: result.msg,
              onCancel: () => {
                modal.hide()
              },
            })
          }
        },
      })
    }

    if (
      ProgressSize !== 100
      // CombineUrlList.filter((v) => v.fileType === 1).length === 0
    ) {
      return (
        <div style={{ marginTop: 50, textAlign: 'center' }}>
          <div className={style.tipes_null} style={{ marginTop: '50%' }}>
            {tipes.map((v, i) => {
              return <div key={i}>{v}</div>
            })}
          </div>
          <div className={style.progress_tipes}>
            <div className={style.tipes_pro}>合成中请耐心等待...</div>
            <Progress
              shape="line"
              percent={ProgressSize}
              theme={'primary'}
              strokeWidth={10}
            />
          </div>
        </div>
      )
    }

    return (
      <div className={style.pageBackground}>
        <div className={style.box_content}>
          {CombineUrlList.map((value, i) => (
            <div
              key={i}
              className={style.selectView_list}
              style={
                value.fileType === 2 || value.fileType === 3
                  ? {
                      width: '40%',
                      background: '#ececec',
                      position: 'relative',
                    }
                  : {
                      width: '40%',
                      position: 'relative',
                    }
              }
            >
              {value.fileType === 1 ? (
                <div>第{i}页</div>
              ) : (
                <div>
                  {/* {value.fileType === 1 && '固定版面'} */}
                  {value.fileType === 2 && '相册封面'}
                  {value.fileType === 3 && <div>第{i}页</div>}
                  {value.fileType === 4 && <div>{value.name}</div>}
                </div>
              )}
              <img
                style={
                  value.fileType === 2 || value.fileType === 3
                    ? !value.width && !value.height
                      ? {
                          position: 'absolute',
                          top: '25%',
                        }
                      : {}
                    : {}
                }
                src={value.url}
                alt=""
                onClick={() => {
                  // setActionIndex(i)
                  // setImagePreviewStatus(true)
                }}
              />
              {value.fileType === 4 && (
                <img src={value.baseImages} style={{ width: '70%' }} />
              )}
              {value.fileType === 2 && value.width && value.height && (
                <img src={value.baseImages} style={{ width: '70%' }} />
              )}
            </div>
          ))}
        </div>
        <ImagePreview
          visible={ImagePreviewStatus}
          images={imagesView}
          onClose={() => setImagePreviewStatus(false)}
          activeIndex={actionIndex}
        />

        <div className={style.menu_list}>
          <div
            className={style.back_btn_sub}
            onClick={() => {
              store.routerStore.replace(
                `/build/graduation-select/${props.match.params.activity_id}?eroll_id=${props.match.params.eroll_id}`
              )
            }}
          >
            返回
            <br />
            首页
          </div>
          {is_submit === 0 ? (
            <div
              style={{ background: '#fff', color: 'rgb(64 64 64)' }}
              className={style.back_btn_update}
              onClick={() => {
                store.routerStore.push(
                  `/build/selection/${props.match.params.activity_id}?eroll_id=${props.match.params.eroll_id}&is_update=true`
                )
              }}
            >
              重新设计
            </div>
          ) : (
            <div className={style.null_ele}></div>
          )}

          {is_submit === 0 ? (
            <>
              <div style={{ width: 10, height: 10 }}></div>
              <Button
                block
                theme="danger"
                className={style.back_btn}
                onClick={handleClickSave}
              >
                送厂制作
              </Button>
            </>
          ) : (
            <div className={style.null_ele}></div>
          )}
        </div>

        {ImagePreviewStatus && (
          <Button
            theme="primary"
            size="sm"
            className={style.back_btn_sub_btn}
            style={{ zIndex: 10000 }}
            onClick={() => {
              setImagePreviewStatus(false)
            }}
          >
            关闭
            <br />
            图片
          </Button>
        )}
      </div>
    )
  }
)
