import { action, autorun, makeAutoObservable, observable } from "mobx";

export class SelectionActivities {

    selectAction: "original" | "package" | "wrap" = "original"

    constructor() {
        makeAutoObservable(this);
    }

    // 写入服装列表
    setSelectAction(selectAction: "original" | "package" | "wrap") {
        this.selectAction = selectAction
    }
}
