import { autorun, computed, makeAutoObservable, runInAction, toJS } from "mobx";
import { format } from "date-fns";
import { BangBangTangRequest } from "../../utils/request";
import { store } from "../store.root";
import {
  catchError,
  debounceTime,
  delay,
  filter,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";
import { EmployeePreorderDetail } from "./employee-preorder-list.api";
// import { LOAD_STATE } from "zarm/es/pull/PropsType";
import { toStream } from "mobx-utils";
import { from, of } from "rxjs";

declare enum LOAD_STATE {
  normal = 0,
  abort = 1,
  loading = 2,
  success = 3,
  failure = 4,
  complete = 5
}

interface dataSetInterface {
  data: EmployeePreorderDetail[];
  page: number;
  loading: LOAD_STATE;
}
export const PREORDER_STATUS = [
  {
    value: "1",
    label: "已审核",
  },
  {
    value: "0",
    label: "未审核",
  },
  {
    value: "2",
    label: "备档",
  },
  {
    value: "3",
    label: "取消",
  },
  { value: "all", label: "全部" },
];

export class EmployeePreorderListStore {
  public schedule_type: { id: number; name: string }[] | null = null; // 合适的档期类型
  public schedule_type_num: Map<number, string> = new Map();
  listening: boolean = false;
  public notice_data: Map<
    string,
    { id: number; notice: string }[] | null
  > = new Map();
  public schedule_type_value: number | null | "all" = null;
  @computed get schedule_type_index(): number | undefined {
    if (this.schedule_type_value === "all") {
      return 0;
    }
    const index = this.schedule_type?.findIndex(
      (value) => value.id === this.schedule_type_value
    );
    if (index !== null) {
      return (index || 0) + 1;
    } else {
      return undefined;
    }
  }
  change_schedule_type_value_by_index(value: number) {
    if (value === 0) {
      this.schedule_type_value = "all";
    } else {
      this.schedule_type_value = this.schedule_type
        ? this.schedule_type[value - 1].id
        : null;
    }
  }
  public schedule_status = PREORDER_STATUS; // 合适的档期类型
  public schedule_status_num: Map<string, number> = new Map();
  public schedule_status_value: string = "1";
  change_schedule_status_value_by_index(value: number) {
    this.schedule_status_value = this.schedule_status[value].value;
  }
  @computed get schedule_status_index(): number | undefined {
    const index = this.schedule_status.findIndex(
      (value) => value.value === this.schedule_status_value
    );
    if (index !== null) {
      return index;
    } else {
      return undefined;
    }
  }
  public date: Date = new Date();
  public displayCalendar: boolean = false;
  // 修改预约单状态弹窗
  public modalSelected: number | null = null;
  // 当前天的数据,不对其他天数做缓存
  private _data: Map<string, dataSetInterface> = new Map(); // 第一个是日期，第二个是档期类型，第三个是档期状态
  // {
  //   [date: string]: dataSetInterface;
  // } = {};
  updateScheduleTypeFromServer() {
    BangBangTangRequest<{ id: number; name: string }[]>({
      url: "/wap/schedule/getScheduleType",
      data: {
        token: store.userStore.token,
        store_id: store.storeInfoStore.value,
      },
      method: "POST",
    }).subscribe((value) =>
      runInAction(() => {
        this.schedule_type = value;
        this.schedule_type_value = "all";
      })
    );
  }
  @computed get currentNotice() {
    return this.notice_data.get(format(this.date, "yyyy-MM-dd")) || [];
  }
  startListen() {
    // 当前日期变更时，请求档期类型接口
    if (this.listening) {
      return;
    }
    runInAction(() => (this.listening = true));
    from(toStream(() => this.date))
      .pipe(
        startWith(new Date()),
        debounceTime(100),
        switchMap((value) => {
          return BangBangTangRequest({
            url: "/wap/schedule/getScheduleTypeStatistics",
            method: "POST",
            data: { schedule_date: format(value, "yyyy-MM-dd") },
          });
        })
      )
      .subscribe((value: { id: number; name: string; num: string }[]) =>
        runInAction(() => {
          this.schedule_type_num.clear();
          value.forEach((v) => this.schedule_type_num.set(v.id, v.num));
        })
      );
    // 当前档期类型变更时，请求档期类型接口
    from(toStream(() => [this.date, this.schedule_type_value]))
      .pipe(
        debounceTime(100),
        filter((value) => !!value[1]),
        switchMap((value: any) => {
          return BangBangTangRequest({
            url: "/wap/schedule/getScheduleStatusStatistics",
            method: "POST",
            data: {
              schedule_date: format(value[0], "yyyy-MM-dd"),
              schedule_type: value[1],
            },
          });
        })
      )
      .subscribe(
        (value: {
          status_all: number;
          status_approved: number;
          status_cancel: number;
          status_queue: number;
          status_unapproved: number;
        }) =>
          runInAction(() => {
            this.schedule_status_num.clear();
            this.schedule_status_num.set("1", value.status_approved);
            this.schedule_status_num.set("0", value.status_unapproved);
            this.schedule_status_num.set("2", value.status_queue);
            this.schedule_status_num.set("3", value.status_cancel);
            this.schedule_status_num.set("all", value.status_all);
          })
      );
    // 监听变化
    from(
      toStream(() => [
        this.schedule_status_value,
        this.schedule_type_value,
        this.date,
      ])
    )
      .pipe(debounceTime(50))
      .subscribe((value) => {
        if (value[0] && value[1] !== null && value[2]) {
          this.getNextData();
        } else {
        }
      });
  }
  constructor() {
    makeAutoObservable(this);
  }
  public toggleDisplayCalendar() {
    this.displayCalendar = !this.displayCalendar;
  }
  public changeModalSelected(value: number | null) {
    this.modalSelected = value;
  }
  changeDate(newDate: Date) {
    runInAction(() => {
      this.date = newDate;
      if (location.pathname.match("employee-preorder-list")) {
        store.routerStore.replace(
          location.pathname + "?date=" + format(newDate, "yyyy-MM-dd")
        );
      }
    });
  }

  @computed get displayData(): EmployeePreorderDetail[] {
    if (this.currentDataSet) {
      return this.currentDataSet.data;
    } else {
      return [];
    }
    // if (
    //   this.date === null ||
    //   this.schedule_status_value === null ||
    //   this.schedule_type_value === null
    // ) {
    //   return [];
    // }
    // const dateString = format(this.date, "yyyy-MM-dd");
    // return (
    //   this._data.get([
    //     dateString,
    //     this.schedule_type_value,
    //     this.schedule_status_value,
    //   ])?.data || []
    // );
  }
  @computed get dumpCurrentForm(): string {
    const dateString = format(this.date, "yyyy-MM-dd");
    return JSON.stringify([
      dateString,
      this.schedule_type_value!,
      this.schedule_status_value,
    ]);
  }
  @computed get currentDataSet(): dataSetInterface | undefined {
    if (this.form_is_not_valid) {
      return undefined;
    }
    return this._data.get(this.dumpCurrentForm) || undefined;
  }
  @computed get form_is_not_valid(): boolean {
    return (
      this.date === null ||
      this.schedule_status_value === null ||
      this.schedule_type_value === null
    );
  }
  getNextData = () => {
    if (this.form_is_not_valid) {
      console.warn("缺少必须的参数");
      return;
    }
    if (this.currentDataSet) {
      this.getDataFromServer(this.date!, this.currentDataSet.page + 1);
    } else {
      this._data.set(this.dumpCurrentForm, {
        page: 0,
        data: [],
        loading: 0,
      });
      setTimeout(() => {
        this.getDataFromServer(this.date!, 1);
        if (
          this.notice_data.get(format(this.date, "yyyy-MM-dd")) === null ||
          this.notice_data.get(format(this.date, "yyyy-MM-dd")) === undefined
        ) {
          this.getNoticeFromServer(this.date!);
        }
      }, 10);
    }
  };

  private getDataFromServer(date: Date, page: number) {
    if (!this.currentDataSet) {
      console.warn("当前无数据");
      console.log(toJS(this._data));
      return;
    }
    if (this.currentDataSet.loading === 5) {
      return;
    }
    const dateString = format(date, "yyyy-MM-dd");
    this.currentDataSet.loading = 2;
    BangBangTangRequest<EmployeePreorderDetail[]>({
      url: "/wap/schedule/adminGetUserBookingData",
      data: {
        token: store.userStore.token,
        page,
        schedule_type: this.schedule_type_value,
        schedule_status: this.schedule_status_value,
        schedule_date: dateString,
        store_id: store.storeInfoStore.value,
      },
      method: "POST",
    })
      .pipe(map((value) => value || []))
      .subscribe((value) => {
        runInAction(() => {
          if (this.currentDataSet) {
            if (value.length === 0) {
              this.currentDataSet.loading = 5;
            } else {
              value.forEach((_value) => this.currentDataSet!.data.push(_value));
              this.currentDataSet.page += 1;
              this.currentDataSet.loading = LOAD_STATE.success;
            }
          } else {
            console.warn("请求被抛弃");
          }
        });
      });
  }

  private getNoticeFromServer(date: Date) {
    BangBangTangRequest({
      url: "wap/schedule/getScheduleNotice",
      method: "POST",
      data: {
        token: store.userStore.token,
        store_id: store.storeInfoStore.value,
        schedule_date: format(date, "yyyy-MM-dd"),
      },
    }).subscribe((value) => {
      runInAction(() => {
        this.notice_data.set(format(date, "yyyy-MM-dd"), value);
      });
    });
  }
}
