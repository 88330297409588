import React, {
  ReactComponentElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { withRouter } from 'react-router-dom'
// import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper } from 'react-vant'

import {
  ImagePreview,
  Carousel,
  NoticeBar,
  Popup,
  Pull,
  Toast,
  Button,
  Loading,
  Modal,
} from 'zarm'
import AddImages from '../../assets/add.png'
import CloseImages from '../../assets/close.png'
import PackageImages from '../../assets/package.png'
import ServiceImages from '../../assets/service.png'
import ColseImages from '../../assets/x.png'
import XlImages from '../../assets/xl.png'
import YesImages from '../../assets/yes.png'
import { store } from '../../store/store.root'
import { FetchRequest } from '../../utils/fetch'
import { getParamValue } from '../../utils/url'
import style from './index.module.scss'
import './style.css'
import { observer } from 'mobx-react'
import { VoucherList } from './voucher'

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
}

type resType = {
  activity_name?: null
  consultation_telephone?: null
  eroll_id?: number
  link?: string
  watermark_settings?: string
  pellucidity: number
  images?: {
    id: number
    is_order: 0 | 1
    is_selection: number
    o_object: string
    or_w_img: string
    original: number
    p_object: string
    package: number
    w_object: string
    wrap: number
  }[]
  origimages?: {
    id: number
    is_selection: number
    o_object: string
    or_w_img: string
    original: number
    p_object: string
    is_order: 0 | 1
    package: number
    w_object: string
    wrap: number
  }[]
  is_select?: number
  meal?: Array<{
    alreadynum: number
    cutprice: number
    free: string
    name: string
    price: number
    type: 'original' | 'package' | 'wrap'
    detail: {
      all: Array<string>
      object: Array<{ alreadynum: number; name: string; num: number }>
      desc: string[]
    }
    wrap_image?: string[]
  }> // Array<{ type: "original" | "package" | "wrap"; name: string; detail: Array<string> }>;
  msg?: string
  order_number?: null | number
  rotation?: Array<{ link: string; img: string }>
  select_time?: string
  meal_payed?: number
  views?: Array<{
    id: number
    openname: string
    detail: string
    avatar: string
  }>
  sum_images?: number
  meal_type?: 'original' | 'package' | 'wrap'
}

const Context = React.createContext({}) // 初始化

export default observer(
  withRouter(
    (props: {
      match: {
        params: {
          eroll_id: string
        }
      }
    }) => {
      let eroll_id: null | number = parseInt(
        localStorage.getItem('eroll_id') || '0'
      )

      useEffect(() => {
        document.title = '选片活动'
        if (store.userStore.token) {
          console.log('初始化请求数据')
          handleGetBabySelect()
        }
      }, [store.userStore.token])

      // const [IamgesBase64, setIamgesBase64] = useState('')

      async function GetCode() {
        let res = await FetchRequest('/wap/selectioneroll/getImage', {})
        if (res.code === 1) {
          if (store.userStore.token) {
            GetSubscribe(res.data)
          }
        }
      }

      const [babyList, setBabyList] = useState([])
      const [chooseTheBaby, setChooseTheBaby] = useState(false)

      async function handleGetBabySelect() {
        let res = await FetchRequest('/wap/selectioneroll/babySelect', {
          activity_id: props.match.params.eroll_id,
        })
        Loading.hide()
        if (res.code === 1) {
          if (res.data.is_go === 0) {
            eroll_id = res.data.erollId
            localStorage.setItem('eroll_id', res.data.erollId)
            GetCode()
            const packageName: any =
              localStorage.getItem('packageName') || 'original'
            if (packageName) {
              getActivitiesList(false, packageName)
            } else {
              getActivitiesList()
            }
          } else {
            setBabyList(res.data.erollId)
            const d = localStorage.getItem('eroll_id')
            eroll_id = parseInt(d || '0')
            if (!d) {
              setChooseTheBaby(true)
            } else {
              //// 判断eroll_id是否过期
              if (
                (res.data.erollId as any[]).filter((v) => v.id === parseInt(d))
                  .length > 0
              ) {
                GetCode()
                const packageName: any =
                  localStorage.getItem('packageName') || 'original'
                if (packageName) {
                  getActivitiesList(false, packageName)
                } else {
                  getActivitiesList()
                }
              } else {
                localStorage.removeItem('eroll_id')
                setChooseTheBaby(true)
              }
              Loading.hide()
            }
          }
        } else {
          // Toast.show(res.msg)
          const modal = Modal.alert({
            title: '提示',
            content: res.msg,
            cancelText: '去报名',
            onCancel: () => {
              location.href = res.data
              // modal.hide()
            },
          })
        }
      }

      async function GetSubscribe(IamgesBase64: string) {
        let res = await FetchRequest('/wap/selectioneroll/is_subscribe', {
          token: store.userStore.token,
        })
        if (res.data.is_subscribe === 0) {
          const modal = Modal.alert({
            title: '提示',
            content: (
              <div>
                <div>
                  <img src={IamgesBase64} style={{ width: '60%' }} alt="" />
                </div>
                <div style={{ color: 'red', textAlign: 'center' }}>
                  请先关注公众号后再制作相册
                  <br />
                  否则无水印底片无法下载。
                </div>
              </div>
            ),
            cancelText: '请先关注公众号',
            onCancel: () => {
              return false
            },
          })
        }
      }

      const [CarouselList, setCarouselList] = useState<
        Array<{ link: string; img: string }>
      >([]) // 轮播图

      const [data, setData] = useState<resType>({
        images: [],
        origimages: [],
        is_select: 1,
        pellucidity: 0.5,
      }) // 页面数据

      const [IsListNull, setIsListNull] = useState(false) // 是否无数据

      const [IsPackageShow_3, setIsPackageShow_3] = useState(false)

      const [componentDate, setcomponentDate] = useState<{
        IsListNull: boolean
        alreadynum?: number
        cutprice?: number
        free?: string
        name?: string
        price?: number
        type: 'original' | 'package' | 'wrap'
        detail?: {
          all: Array<string>
          object: Array<{ alreadynum: number; name: string; num: number }>
        }
      }>({
        type: 'original',
        detail: {
          all: [],
          object: [],
        },
        IsListNull: IsListNull,
      })

      useEffect(() => {
        if (IsListNull) {
          setcomponentDate({
            ...componentDate,
            IsListNull: true,
          })
        }
      }, [IsListNull])

      // 数据分页
      const [page, setPage] = useState(1)

      async function setComponentDate(data: any) {
        setcomponentDate({
          ...componentDate,
          ...data,
        })
        console.log('1准备请求数据套餐参数为:', data.type)
        await getActivitiesList(false, data.type)
      }

      async function isRefresh(t: number) {
        setIsListNull(false)
        console.log('2准备请求数据套餐参数为:', componentDate.type)
        // await getActivitiesList(true, componentDate.type)
        Loading.show()
        let res = await FetchRequest('/wap/selectioneroll/selectionFilmIndex', {
          token: store.userStore.token,
          page: 1,
          limit: data.images!.length,
          eroll_id,
          meal_type: componentDate.type,
        })
        Loading.hide()
        if (res.code === 1) {
          setData(res.data)
          // 套餐选择
          // if (res.data.meal) {
          for (let i = 0; i < res.data.meal.length; i++) {
            const item = res.data.meal[i]
            if (item.type === res.data.meal_type) {
              setcomponentDate({
                ...componentDate,
                ...item,
              })
              break
            }
          }
        }
      }

      async function isRefreshPack(type: 'original' | 'package' | 'wrap') {
        // setData({
        //     origimages: [],
        //     images: []
        // })
        // setIsListNull(false)
        // setPage(1)
        // document.getElementById("content")!.scrollTop = 0
        // console.log("3准备请求数据套餐参数为:", type)
        // await getActivitiesList(true, type)
        localStorage.setItem('packageName', type)
        Toast.show('正在为您切换套餐中...')
        setTimeout(() => {
          location.reload()
        }, 500)
      }

      // false正常请求 true 代表刷新不进行图片控制判断
      async function getActivitiesList(
        type: boolean = false,
        packageName: 'original' | 'package' | 'wrap' = 'original'
      ) {
        // 相同说明更换了套餐则不走分页校验
        if (packageName === componentDate.type) {
          if (!type) {
            if (IsListNull) return
          }
        }
        Loading.show()
        let res = await FetchRequest('/wap/selectioneroll/selectionFilmIndex', {
          token: store.userStore.token,
          page: type ? 1 : page,
          limit: 50,
          eroll_id,
          meal_type: packageName,
        })
        Loading.hide()
        if (res.code === 1) {
          setPage(page + 1)
          localStorage.setItem(
            'watermark_settings',
            res.data.watermark_settings
          )
          localStorage.setItem('watermark_pellucidity', res.data.pellucidity)

          localStorage.setItem('packageName', packageName)
          if (res.data.is_select !== 0) {
            if (res.data.images.length === 0) {
              setIsListNull(true)
              return
            }
          }

          if (!type) {
            if (res.data.is_select !== 0) {
              if (res.data.images.length === 0) {
                setIsListNull(true)
                setPage(1)
                return
              } else {
                setIsListNull(false)
              }
            } else {
              setData(res.data)
              return
            }
          }

          if (res.data.images.length > 0) {
            if (page === 1) {
              setData(res.data)
            } else {
              // 分页加
              let newArratData = { ...data }
              newArratData.images = newArratData.images?.concat(res.data.images)
              // origimages 分页
              newArratData.origimages = newArratData.origimages?.concat(
                res.data.origimages
              )
              setData(newArratData)
            }
          }

          setCarouselList(res.data.rotation)
          selectTime(res.data.select_time)
          // 套餐选择
          if (res.data.meal) {
            for (let i = 0; i < res.data.meal.length; i++) {
              const item = res.data.meal[i]
              if (item.type === res.data.meal_type) {
                setcomponentDate({
                  ...componentDate,
                  ...item,
                })
                break
              }
            }
            if (
              (res.data.images as Array<{
                id: number
                is_selection: number
                or_w_img: string
              }>).length === 0
            ) {
              setIsListNull(true)
            } else {
              setIsListNull(false)
            }
          }
        } else {
          Toast.show(res.msg)
        }
      }

      // false正常请求 true 代表刷新不进行图片控制判断
      // async function getActivitiesList(type: boolean = false, packageName: "original" | "package" | "wrap" = "original") {
      //     // if (IsListNull) return
      //     // 相同说明更换了套餐则不走分页校验
      //     if (packageName === componentDate.type) {
      //         if (!type) {
      //             if (IsListNull) return
      //         }
      //     }

      //     let res = await FetchRequest("/wap/selectioneroll/selectionFilmIndex", {
      //         token: store.userStore.token,
      //         page: type ? 1 : page,
      //         limit: 20,
      //         eroll_id,
      //         meal_type: packageName
      //     })
      //     if (res.code === 1) {
      //         localStorage.setItem("packageName", packageName)
      //         if (!type) {
      //             if (res.data.is_select !== 0) {
      //                 if (res.data.images.length === 0) {
      //                     setIsListNull(true)
      //                     setPage(1)
      //                     return
      //                 } else {
      //                     setIsListNull(false)
      //                 }
      //             } else {
      //                 setData(res.data)
      //                 return
      //             }
      //         }
      //         if (page > 1) { // !type && Object.keys(data).length > 0
      //             // 分页加
      //             console.log("分页加")
      //             let newArratData = { ...data }
      //             newArratData.images = newArratData.images?.concat(res.data.images)
      //             // origimages 分页
      //             newArratData.origimages = newArratData.origimages?.concat(res.data.origimages)
      //             setData(newArratData)
      //         } else {
      //             // 第一页
      //             console.log("第一页")
      //             setData(res.data)
      //         }
      //         setPage(page + 1)
      //         // 处理轮播图数据
      //         // let CarouseNew: string[] = []
      //         // for (let i = 0; i < res.data.rotation.length; i++) {
      //         //     const item = res.data.rotation[i];
      //         //     CarouseNew.push(item.img)
      //         // }
      //         setCarouselList(res.data.rotation)
      //         selectTime(res.data.select_time)
      //         // 套餐选择
      //         if (res.data.meal) {
      //             for (let i = 0; i < res.data.meal.length; i++) {
      //                 const item = res.data.meal[i];
      //                 if (item.type === res.data.meal_type) {
      //                     setcomponentDate(item)
      //                     break
      //                 }
      //             }
      //             if ((res.data.images as Array<{ id: number; is_selection: number; or_w_img: string }>).length === 0) {
      //                 setIsListNull(true)
      //             } else {
      //                 setIsListNull(false)
      //             }
      //         }
      //     } else {
      //         Toast.show(res.msg)
      //     }
      // }

      return (
        <div id="content" style={{ overflowY: 'auto', maxHeight: '100vh' }}>
          <Context.Provider value={componentDate}>
            <>
              {/* {data.views && (
                <NoticeBar className={style.NoticeBar}>
                  {data.views?.map((v) => {
                    return (
                      <span key={v.id}>
                        <img
                          src={v.avatar}
                          alt=""
                          style={{
                            width: 20,
                            borderRadius: '50%',
                            margin: '0 10px',
                          }}
                        />
                        <span style={{ fontSize: 14 }}>{v.detail}</span>
                      </span>
                    )
                  })}
                </NoticeBar>
              )} */}

              <CarouselCompnent list={CarouselList} />
              <ISSelect data={data}>
                <>
                  <HelpInstructions />
                  <div
                    style={{
                      height: '80vh',
                      overflowY: 'scroll',
                      background: 'rgb(219, 219, 219)',
                    }}
                  >
                    <WaterfallFlow
                      watermark_settings={data.watermark_settings}
                      watermark_pellucidity={data.pellucidity}
                      list={data.images!}
                      origimages={data.origimages!}
                      onChang={(e, s) =>
                        getActivitiesList(
                          e,
                          s as 'original' | 'package' | 'wrap'
                        )
                      }
                      IsListNull={IsListNull}
                      isRefresh={isRefresh}
                      eroll_id={data.eroll_id!}
                    />
                  </div>

                  <CustomerService
                    consultation_telephone={data.consultation_telephone!}
                  />
                  <PackageBtn
                    meal={data.meal! || []}
                    setComponentDate={setComponentDate}
                    isRefresh={isRefreshPack}
                    IsPackageShow_3={IsPackageShow_3}
                  />
                  <Settlement
                    meal_payed={data.meal_payed!}
                    list={data.images!}
                    sum_images={data.sum_images!}
                    eroll_id={data.eroll_id!}
                    priceOnchang={(type: boolean) => setIsPackageShow_3(type)}
                  />
                </>
              </ISSelect>
            </>
          </Context.Provider>

          {babyList.length > 0 && (
            <div
              className={style.PackageBtn}
              style={{
                bottom: '41%',
              }}
              onClick={() => setChooseTheBaby(true)}
            >
              切换
              <br />
              宝宝
            </div>
          )}

          <Modal
            // closable
            // onCancel={() => setChooseTheBaby(false)}
            visible={chooseTheBaby}
            title="选择宝宝"
          >
            <div className={style.select_baby}>
              {babyList.map(
                (v: { id: number; name: string; orderNum: number }) => {
                  return (
                    <div key={v.id}>
                      <Button
                        theme="primary"
                        block
                        // disabled={v.orderNum > 0}
                        onClick={() => {
                          // if (v.orderNum > 0) {
                          //   Toast.show('该宝宝已经支付，请不要再次选择')
                          //   return
                          // }
                          if (
                            parseInt(
                              localStorage.getItem('eroll_id') || '0'
                            ) === v.id
                          ) {
                            setChooseTheBaby(false)
                            return
                          }
                          Loading.show()
                          localStorage.setItem('eroll_id', v.id + '')
                          location.reload()
                        }}
                      >
                        {v.name}
                        {parseInt(localStorage.getItem('eroll_id') || '0') ===
                          v.id && '( 当前宝宝 )'}
                        {v.orderNum > 0 && '( 已付款 )'}
                      </Button>
                    </div>
                  )
                }
              )}
            </div>
          </Modal>
        </div>
      )
    }
  )
)

//// 帮助
function HelpInstructions() {
  const [accordingToTheIndex, setAccordingToTheIndex] = useState(0)
  return (
    <>
      <div
        className={style.toHelpGuide}
        onClick={() => setAccordingToTheIndex(1)}
      >
        帮助
      </div>
      {accordingToTheIndex !== 0 && (
        <>
          {accordingToTheIndex === 1 && (
            <img
              className={style.accord}
              onClick={() => setAccordingToTheIndex(2)}
              src="https://basic-1307618371.cos.ap-nanjing.myqcloud.com/filmselection/help/20220331141359.jpg"
              alt=""
            />
          )}
          {accordingToTheIndex === 2 && (
            <img
              className={style.accord}
              onClick={() => setAccordingToTheIndex(3)}
              src="https://basic-1307618371.cos.ap-nanjing.myqcloud.com/filmselection/help/20220331141205.jpg"
              alt=""
            />
          )}
          {accordingToTheIndex === 3 && (
            <img
              className={style.accord}
              onClick={() => setAccordingToTheIndex(4)}
              src="https://basic-1307618371.cos.ap-nanjing.myqcloud.com/filmselection/help/20220331141405.jpg"
              alt=""
            />
          )}
          {accordingToTheIndex === 4 && (
            <img
              className={style.accord}
              onClick={() => setAccordingToTheIndex(0)}
              src="https://basic-1307618371.cos.ap-nanjing.myqcloud.com/filmselection/help/20220331141352.jpg"
              alt=""
            />
          )}
        </>
      )}
    </>
  )
}

//// 轮播图
function CarouselCompnentS(props: { list: { img: string; link: string }[] }) {
  const [index, setIndex] = useState(0)
  return (
    <Carousel
      autoPlay
      loop
      onChange={(e) => {
        setIndex(Number(e))
      }}
    >
      {props.list.map((item, index_) => {
        return (
          <div
            className="carousel__item__pic"
            style={{
              height: 160,
            }}
            key={index_}
            onClick={() => {
              store.globalUiStore.changeCurrentPopup(true)
            }}
          >
            <img
              src={item.img}
              alt=""
              draggable={false}
              style={{ height: 'auto' }}
            />
          </div>
        )
      })}
    </Carousel>
  )
}

const CarouselCompnent = observer(CarouselCompnentS)

//// 瀑布流
function WaterfallFlow({
  list,
  origimages,
  onChang,
  isRefresh,
  IsListNull,
  eroll_id,
  watermark_settings,
  watermark_pellucidity,
}: {
  list: {
    id: number
    is_order: 0 | 1
    is_selection: number
    o_object: string
    or_w_img: string
    original: number
    p_object: string
    package: number
    w_object: string
    wrap: number
  }[]
  origimages: {
    id: number
    is_order: 0 | 1
    is_selection: number
    o_object: string
    or_w_img: string
    original: number
    p_object: string
    package: number
    w_object: string
    wrap: number
  }[]
  onChang: (e: boolean, s: string) => void
  isRefresh: (e: number) => void
  IsListNull: boolean
  eroll_id: number
  watermark_settings?: string
  watermark_pellucidity: number
}) {
  const pullRef = useRef()

  const data = useContext<any>(Context)

  const data_new = useCallback(() => {
    return data
  }, [data])

  const [loading, setLoading] = useState(LOAD_STATE.normal)

  const newList = useMemo(() => {
    let left = []
    let right = []
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      if (i % 2 === 0) {
        left.push(item)
      } else {
        right.push(item)
      }
    }
    return [left, right]
  }, [list])

  useEffect(() => {
    if (data.IsListNull) {
      setLoading(LOAD_STATE.complete)
    }
  }, [data.IsListNull])

  // 加载数据
  function loadData() {
    if (data.IsListNull) {
      setLoading(LOAD_STATE.complete)
    } else {
      // setLoading(LOAD_STATE.loading)
      onChang(false, data.type)
      setLoading(LOAD_STATE.success)
    }
  }

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    settopSelectType('')
  }, [data.type])

  useEffect(() => {
    // console.log("套餐发生变化")
    if (data.detail.object.length > 0) {
      if (topSelectType.length == 0) {
        settopSelectType(data.detail.object[0].name)
      }
    }
  }, [data.detail.object])

  useEffect(() => {
    if (origimages.length > 0) {
      for (let i = 0; i < origimages.length; i++) {
        const item = origimages[i]
        if (item.id === ImagesItem.id) {
          setImagesItem(item)
          console.log('找到上次所选！')
          // setTnitialSlideCount(i)
        }
      }
    }
  }, [origimages])

  useEffect(() => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      if (item.id === ImagesItem.id) {
        setImagesItem(item)
        console.log('找到上次所选！')
      }
    }
  }, [list])

  const [popupState, setpopupState] = useState(false) // 图片预览开关

  const [initialSlideCount, setTnitialSlideCount] = useState(0)

  const [ImagesItem, setImagesItem] = useState<{
    id?: number
    is_selection?: number
    o_object?: string
    or_w_img?: string
    original?: number
    p_object?: string
    package?: number
    w_object?: string
    wrap?: number
  }>({})

  const { SetSettlement } = useData()

  const [fillData, setFaillDate] = useState<any>({})

  useEffect(() => {
    if (Object.keys(fillData).length > 0) {
      const filterArray = origimages.filter((v) => {
        return v.id === fillData.id
      })
      if (filterArray.length > 0) {
        handleClickLookImages(filterArray[0])
        setFaillDate({})
      } else {
        setTimeout(() => {
          Toast.show('请稍后，正在查找数据')
          loadData()
        }, 1000)
      }
    }
  }, [origimages])

  function handleClickLookImages(item: {
    id: number
    is_selection: number
    or_w_img: string
  }) {
    // handleOnChangImages(item.id)
    setImagesItem(item)
    SetSettlement(false)
    // 筛选
    const filterArray = origimages.filter((v) => {
      return v.id === item.id
    })
    if (filterArray.length === 0) {
      setFaillDate(item)
      loadData()
      Toast.show('正在加载该图片资源...')
    } else {
      setpopupState(true)
      for (let i = 0; i < origimages.length; i++) {
        const itemSon = origimages[i]
        if (itemSon.id === item.id) {
          // console.log("索引为:", i)
          setTnitialSlideCount(i)
        }
      }
    }
  }

  const [topSelectType, settopSelectType] = useState('')

  // 选择照片
  async function handleClickSelectPhotos(name: string) {
    Loading.show()
    let res = await FetchRequest('/wap/selectioneroll/filmSelect', {
      token: store.userStore.token,
      image_id: ImagesItem.id,
      meal_type: data.type,
      object: name, // topSelectType,
      eroll_id,
    })
    if (res.code === 1) {
      isRefresh(1)
      Toast.show(`选择 ${name} 成功`)
    } else {
      Toast.show(res.msg)
    }
  }

  // 取消照片
  async function handleClickCancelPhotos(name: string) {
    Loading.show()
    let res = await FetchRequest('/wap/selectioneroll/filmQuit', {
      token: store.userStore.token,
      eroll_id,
      image_id: ImagesItem.id,
      meal_type: data.type,
      object: name, // topSelectType
    })
    if (res.code === 1) {
      isRefresh(0)
      Toast.show(`取消 ${name} 成功`)
    } else {
      Toast.show(res.msg)
    }
  }

  // 文字动态更改
  function handleChangText(type: string, name: string) {
    switch (type) {
      case 'original':
        if (String(ImagesItem.o_object).split(',').indexOf(name) > -1) {
          return '取消'
        }
        return '选为'

      case 'package':
        if (String(ImagesItem.p_object).split(',').indexOf(name) > -1) {
          return '取消'
        }
        return '选为'

      case 'wrap':
        if (String(ImagesItem.w_object).split(',').indexOf(name) > -1) {
          return '取消'
        }
        return '选为'

      default:
        return ''
    }
  }

  const [ImagesSize, setImagesSize] = useState<{
    width?: number | string
    height?: number | string
  }>({
    width: '100%',
  })

  return (
    <>
      <Pull
        ref={pullRef}
        load={{
          state: loading,
          distance: 30,
          handler: loadData,
        }}
      >
        <div className={style.list_Content}>
          <div className={style.list_item}>
            {newList[0].map((v: any, index) => {
              return (
                <div
                  key={v.id}
                  className={style.images_content}
                  onClick={() => handleClickLookImages(v)}
                >
                  <div>
                    <img src={v.origin_img} alt="" className="img_show_d" />
                    <div
                      className={style.watermarkText}
                      style={{ opacity: watermark_pellucidity }}
                    >
                      {Array.from(Array(60), (v, k) => watermark_settings)}
                    </div>
                  </div>
                  {data.type === 'original' ? (
                    v.original === 1 ? (
                      <img
                        src={YesImages}
                        alt=""
                        className={style.add_images}
                      />
                    ) : (
                      <img
                        src={AddImages}
                        alt=""
                        className={style.add_images}
                      />
                    )
                  ) : (
                    ''
                  )}
                  {data.type === 'package' ? (
                    v.package === 1 ? (
                      <img
                        src={YesImages}
                        alt=""
                        className={style.add_images}
                      />
                    ) : (
                      <img
                        src={AddImages}
                        alt=""
                        className={style.add_images}
                      />
                    )
                  ) : (
                    ''
                  )}
                  {data.type === 'wrap' ? (
                    v.wrap === 1 ? (
                      <img
                        src={YesImages}
                        alt=""
                        className={style.add_images}
                      />
                    ) : (
                      <img
                        src={AddImages}
                        alt=""
                        className={style.add_images}
                      />
                    )
                  ) : (
                    ''
                  )}
                  {data.type === 'original' &&
                    v.o_object?.split(',').length > 0 && (
                      <span className={style.select_tag_text}>
                        {v.o_object}
                      </span>
                    )}
                  {data.type === 'package' &&
                    v.p_object?.split(',').length > 0 && (
                      <span className={style.select_tag_text}>
                        {v.p_object}
                      </span>
                    )}
                  {data.type === 'wrap' &&
                    v.w_object?.split(',').length > 0 && (
                      <span className={style.select_tag_text}>
                        {v.w_object}
                      </span>
                    )}
                </div>
              )
            })}
          </div>
          <div className={style.list_item}>
            {newList[1].map((v: any, index) => {
              return (
                <div
                  key={index}
                  className={style.images_content}
                  onClick={() => handleClickLookImages(v)}
                >
                  <div>
                    <img src={v.origin_img} alt="" className="img_show_d" />
                    <div
                      className={style.watermarkText}
                      style={{ opacity: watermark_pellucidity }}
                    >
                      {Array.from(Array(60), (v, k) => watermark_settings)}
                    </div>
                  </div>
                  {data.type === 'original' ? (
                    v.original === 1 ? (
                      <img
                        src={YesImages}
                        alt=""
                        className={style.add_images}
                      />
                    ) : (
                      <img
                        src={AddImages}
                        alt=""
                        className={style.add_images}
                      />
                    )
                  ) : (
                    ''
                  )}
                  {data.type === 'package' ? (
                    v.package === 1 ? (
                      <img
                        src={YesImages}
                        alt=""
                        className={style.add_images}
                      />
                    ) : (
                      <img
                        src={AddImages}
                        alt=""
                        className={style.add_images}
                      />
                    )
                  ) : (
                    ''
                  )}
                  {data.type === 'wrap' ? (
                    v.wrap === 1 ? (
                      <img
                        src={YesImages}
                        alt=""
                        className={style.add_images}
                      />
                    ) : (
                      <img
                        src={AddImages}
                        alt=""
                        className={style.add_images}
                      />
                    )
                  ) : (
                    ''
                  )}

                  {data.type === 'original' &&
                    v.o_object?.split(',').length > 0 && (
                      <span className={style.select_tag_text}>
                        {v.o_object}
                      </span>
                    )}
                  {data.type === 'package' &&
                    v.p_object?.split(',').length > 0 && (
                      <span className={style.select_tag_text}>
                        {v.p_object}
                      </span>
                    )}
                  {data.type === 'wrap' &&
                    v.w_object?.split(',').length > 0 && (
                      <span className={style.select_tag_text}>
                        {v.w_object}
                      </span>
                    )}
                </div>
              )
            })}
          </div>
          {/* <div className={style.more_text}>
            {data.IsListNull ? '暂无更多' : '加载中...'}
          </div> */}
        </div>
      </Pull>

      <Popup
        className={style.PopupSwiper}
        visible={popupState}
        direction="center"
        mask={true}
        afterClose={() => setpopupState(false)}
      >
        <div style={{ height: '76vh', position: 'relative' }}>
          <Swiper
            className="my-swipe"
            indicator={() => {
              return null
            }}
            initialSwipe={initialSlideCount}
            onChange={(e) => {
              handleClickLookImages(origimages[e])
            }}
          >
            {origimages.map((s: any, index) => {
              if (!popupState) {
                return <></>
              }
              if (!s.origin_img) {
                return <></>
              }
              return (
                <Swiper.Item key={index}>
                  <div className={style.warp_images} style={{ height: '10vh' }}>
                    <div className={style.warp_btn}>
                      {s.is_order === 1 ? (
                        <div className={style.null_text}>
                          您已经购买过此张照片
                        </div>
                      ) : (
                        <>
                          {data.detail.object.map(
                            (
                              v: {
                                name: string
                                alreadynum: number
                                num: number
                                o_object: string
                                p_object: string
                                w_object: string
                              },
                              index: number
                            ) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    settopSelectType(v.name)
                                  }}
                                >
                                  <div
                                    className={`${style.tag_name} ${
                                      data.type === 'original' &&
                                      String(ImagesItem.o_object || '')
                                        .split(',')
                                        .indexOf(v.name) > -1 &&
                                      style.tag_name_select
                                    } ${
                                      data.type === 'package' &&
                                      String(ImagesItem.p_object || '')
                                        .split(',')
                                        .indexOf(v.name) > -1 &&
                                      style.tag_name_select
                                    } ${
                                      data.type === 'wrap' &&
                                      String(ImagesItem.w_object || '')
                                        .split(',')
                                        .indexOf(v.name) > -1 &&
                                      style.tag_name_select
                                    }
                                                                `}
                                    onClick={async () => {
                                      Loading.show()
                                      switch (data.type) {
                                        case 'original':
                                          if (
                                            String(ImagesItem.o_object)
                                              .split(',')
                                              .indexOf(v.name) > -1
                                          ) {
                                            handleClickCancelPhotos(v.name)
                                          } else {
                                            handleClickSelectPhotos(v.name)
                                          }
                                          break
                                        case 'package':
                                          if (
                                            String(ImagesItem.p_object)
                                              .split(',')
                                              .indexOf(v.name) > -1
                                          ) {
                                            handleClickCancelPhotos(v.name)
                                          } else {
                                            handleClickSelectPhotos(v.name)
                                          }
                                          break

                                        case 'wrap':
                                          if (
                                            String(ImagesItem.w_object)
                                              .split(',')
                                              .indexOf(v.name) > -1
                                          ) {
                                            await handleClickCancelPhotos(
                                              v.name
                                            )
                                          } else {
                                            await handleClickSelectPhotos(
                                              v.name
                                            )
                                          }
                                          break
                                        default:
                                          break
                                      }
                                      Loading.hide()
                                      return
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: 12,
                                        width: 52,
                                        textAlign: 'center',
                                      }}
                                    >
                                      点击{handleChangText(data.type, v.name)}
                                    </div>
                                    <div
                                      style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: 80,
                                      }}
                                    >
                                      {v.name}
                                    </div>
                                  </div>
                                  <div>
                                    {v.alreadynum}/{v.num}
                                  </div>
                                </div>
                              )
                            }
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div style={{ position: 'relative' }}>
                    <img
                      src={s.origin_img}
                      alt=""
                      style={{
                        width: '80%',
                        display: 'block',
                        margin: '0 auto',
                        border: '8px solid #fff',
                      }}
                    />
                    <div
                      className={style.watermarkText}
                      style={{
                        width: '80%',
                        margin: '0 auto',
                        overflow: 'hidden',
                        padding: 9,
                        opacity: watermark_pellucidity,
                      }}
                    >
                      {Array.from(Array(150), (v, k) => watermark_settings)}
                    </div>
                  </div>
                </Swiper.Item>
              )
            })}
          </Swiper>
          <img
            src={ColseImages}
            alt=""
            className={style.closeIcon}
            onClick={() => setpopupState(false)}
          />

          {/* <Swiper
                        className="main-container"
                        effect="coverflow"
                        scrollbar={{ draggable: true }} 
                        coverflowEffect={{ slideShadows: false }}
                        initialSlide={initialSlideCount} // 初始值
                        onSlideChange={(e: any) => {
                            if (origimages.length > e.activeIndex) {
                                handleClickLookImages(origimages[e.activeIndex])
                            }
                        }}
                    >
                        {origimages.map((s) => {
                            if (!popupState) {
                                return null
                            }
                            if (!s.or_w_img) {
                                return null
                            }
                            return (
                                <SwiperSlide className="swiper-item" key={s.id} style={{ position: 'relative' }}>
                                    <div className={style.warp_images}>
                                        <div className={style.warp_btn}>
                                            {s.is_order === 1 ? (
                                                <div className={style.null_text}>您已经购买过此张照片</div>
                                            ) : (
                                                <>
                                                    {data.detail.object.map((v: { name: string; alreadynum: number; num: number; o_object: string; p_object: string; w_object: string }, index: number) => {
                                                    return (
                                                        <div key={index} onClick={() => {
                                                            settopSelectType(v.name)
                                                        }}>
                                                            <div
                                                                className={`${style.tag_name} ${
                                                                    data.type === "original" && String(ImagesItem.o_object || "").split(",").indexOf(v.name) > -1 && style.tag_name_select} ${data.type === "package" && String(ImagesItem.p_object  || "").split(",").indexOf(v.name) > -1 && style.tag_name_select} ${data.type === "wrap" && String(ImagesItem.w_object  || "").split(",").indexOf(v.name) > -1 && style.tag_name_select}
                                                                `}
                                                                onClick={async () => {
                                                                    Loading.show();
                                                                    switch (data.type) {
                                                                        case "original":
                                                                            if (String(ImagesItem.o_object).split(",").indexOf(v.name) > -1) {
                                                                                handleClickCancelPhotos(v.name)
                                                                            } else {
                                                                                handleClickSelectPhotos(v.name)
                                                                            }
                                                                            break;
                                                                        case "package":
                                                                            if (String(ImagesItem.p_object).split(",").indexOf(v.name) > -1) {
                                                                                handleClickCancelPhotos(v.name)
                                                                            } else {
                                                                                handleClickSelectPhotos(v.name)
                                                                            }
                                                                            break;

                                                                        case "wrap":
                                                                            if (String(ImagesItem.w_object).split(",").indexOf(v.name) > -1) {
                                                                                await handleClickCancelPhotos(v.name)
                                                                            } else {
                                                                                await handleClickSelectPhotos(v.name)
                                                                            }
                                                                            break;
                                                                        default:
                                                                            break;
                                                                    }
                                                                    Loading.hide();
                                                                    return
                                                                }}
                                                            >
                                                                <div style={{ fontSize: 12, width: 52, textAlign: 'center' }}>
                                                                    点击{handleChangText(data.type, v.name)}
                                                                </div>
                                                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 80 }}>{v.name}</div>
                                                            </div>
                                                            <div>{v.alreadynum}/{v.num}</div>
                                                        </div>
                                                    )
                                                    })}
                                                </>   
                                            )}
                                            
                                        </div>
                                        <div className={style.images_content}>
                                        
                                            <img className={`${style.images_content_img} imagesContent`} style={{ width: "100%" }} id={`img_${s.id}`}  src={s.or_w_img} alt="" />
                                            {data.type === "original" && String(s.o_object).split(",").indexOf(topSelectType) > -1 && (<img src={YesImages} alt="" className={style.yesIcon} />)}
                                            {data.type === "package" && String(s.p_object).split(",").indexOf(topSelectType) > -1 && (<img src={YesImages} alt="" className={style.yesIcon} />)}
                                            {data.type === "wrap" && String(s.w_object).split(",").indexOf(topSelectType) > -1 && (<img src={YesImages} alt="" className={style.yesIcon} />)}
                                        </div>
                                        <img src={ColseImages} alt="" className={style.closeIcon} onClick={() => setpopupState(false)} />
                                    </div>
                                </SwiperSlide>
                            )
                        })}

                    </Swiper> */}
        </div>
      </Popup>
    </>
  )
}

//// 底部结算
function Settlement(props: {
  meal_payed: number
  sum_images: number
  list: Array<{
    id: number
    is_selection: number
    o_object: string
    or_w_img: string
    original: number
    p_object: string
    package: number
    w_object: string
    wrap: number
  }>
  eroll_id: number
  priceOnchang: (type: boolean) => void
}) {
  const data = useContext<any>(Context)

  const eroll_id = getParamValue('eroll_id')

  const { Settlement, SetSettlement } = useData()

  const [popupState, setpopupState] = useState(false)

  useEffect(() => {
    if (popupState) {
      SetSettlement(false)
    } else {
      SetSettlement(true)
    }
  }, [popupState])

  const [shopCat, setShopCat] = useState<
    Array<{
      or_w_img: string
      is_selection: number
      id: number
    }>
  >([])

  useEffect(() => {
    if (props.list.length > 0) {
      let newArray = []
      for (let i = 0; i < props.list.length; i++) {
        const item = props.list[i]
        if (item.is_selection === 1) {
          newArray.push(item)
        }
      }
      setShopCat(newArray)
    }
  }, [props.list])

  useEffect(() => {
    if (data.alreadynum === 3 && data.type !== 'wrap') {
      props.priceOnchang(true)
    } else {
      props.priceOnchang(false)
    }
  }, [data.alreadynum])

  const [upPromptCardVouchers, setUpPromptCardVouchers] = useState(false)

  // 取消照片
  async function handleClickCancelPhotos(item: {
    or_w_img: string
    is_selection: number
    id: number
  }) {
    let res = await FetchRequest('/wap/selectioneroll/filmQuit', {
      token: store.userStore.token,
      eroll_id,
      image_id: item.id,
      meal_type: data.type,
    })
    if (res.code === 1) {
      // isRefresh()
      // Toast.show(`取消 ${topSelectType} 成功`)
    } else {
      Toast.show(res.msg)
    }
  }

  async function handleClickSubform() {
    if (props.meal_payed > 0) {
      const modal = Modal.alert({
        title: '提示',
        content: '此套参已购买， 点击【套餐选择】按钮，可升级套餐',
        cancelText: '确定',
        onCancel: () => {
          modal.hide()
        },
      })
      return
    }
    let res = await FetchRequest('/wap/selectioneroll/settle', {
      token: localStorage.getItem('token'),
      eroll_id: props.eroll_id,
      meal_type: data.type,
    })
    if (res.code === 1) {
      if (res.data.voucher_need === 1 && res.data.voucher_max > 0) {
        // store.routerStore.push(
        //   `/build/selection-activities/voucher/list?eroll_id=${props.eroll_id}&meal_type=${data.type}`
        // )
        // 弹窗提示
        // <VoucherList />
        setUpPromptCardVouchers(true)
      } else {
        store.routerStore.push(
          `/build/selection-activities-pay?eroll_id=${props.eroll_id}&meal_type=${data.type}`
        )
      }
    } else {
      Toast.show({
        content: res.msg,
        stayTime: 5000,
      })
    }
  }

  if (!props.eroll_id) {
    return <div></div>
  }

  return (
    <>
      <div
        className={style.Settlement}
        style={!Settlement ? { zIndex: 10 } : {}}
      >
        {/* onClick={() => setpopupState(!popupState)} */}
        <div className={style.Settlement_left}>
          <div>
            <span className={style.Settlement_left_select}>
              {data.alreadynum}
            </span>
            <span className={style.Settlement_left_all}>
              /{props.sum_images}
            </span>
          </div>
          <div className={style.Settlement_left_text}>已选择</div>
        </div>
        <div className={style.Settlement_center}>
          <div className={style.Settlement_center_price}>¥{data.price}元</div>
          <div className={style.Settlement_center_price_info}>
            {/* <div>已有1452位家长选择</div> */}
            {data.type === 'original' && (
              <div className={style.action_color}>
                已为您节省{data.cutprice}元
              </div>
            )}
          </div>
        </div>
        <div
          className={style.Settlement_right_text}
          onClick={handleClickSubform}
        >
          {props.meal_payed > 0 ? '已付款' : '下一步'}
        </div>
        {data.type === 'original' && (
          <div className={style.Settlement_top_tipe}>{data.free}</div>
        )}
      </div>

      <Popup
        visible={upPromptCardVouchers}
        direction="center"
        mask={true}
        onMaskClick={() => setUpPromptCardVouchers}
        afterClose={() => setUpPromptCardVouchers}
      >
        <div className={style.tipe_content}>
          <VoucherList eroll_id={props.eroll_id} meal_type={data.type} />
        </div>
      </Popup>
    </>
  )
}

//// 悬浮客服按钮
function CustomerService(props: { consultation_telephone: string }) {
  return (
    <a href={`tel:${props.consultation_telephone}`} className={style.service}>
      <img src={ServiceImages} alt="" />
    </a>
  )
}

//// 悬浮套餐按钮
function PackageBtnS(props: {
  meal: Array<{
    alreadynum: number
    cutprice: number
    free: string
    name: string
    price: number
    type: 'original' | 'package' | 'wrap'
    detail: {
      all: Array<string>
      object: Array<{ alreadynum: number; name: string; num: number }>
      desc: string[]
    }
    wrap_image?: string[]
  }>
  setComponentDate: (e: object) => void
  isRefresh: (type: 'original' | 'package' | 'wrap') => void
  IsPackageShow_3: boolean
}) {
  const data = useContext<any>(Context)

  // const [popupState, setpopupState] = useState(false)

  useEffect(() => {
    if (props.IsPackageShow_3) {
      // setpopupState(true)
      store.globalUiStore.changeCurrentPopup(true)
    }
  }, [props.IsPackageShow_3])

  async function handleClickSelect(item: {
    alreadynum: number
    cutprice: number
    free: string
    name: string
    price: number
    type: 'original' | 'package' | 'wrap'
    detail: {
      all: Array<string>
      object: Array<{ alreadynum: number; name: string; num: number }>
    }
  }) {
    props.setComponentDate(item)
    props.isRefresh(item.type)
  }

  const [visible_Images, setVisible_Images] = useState(false)
  const [imgList, setImgList] = useState<string[]>([])
  const [indexImg, setIndexImg] = useState(0)

  function handleClickPreview(list: string[], index: number) {
    setIndexImg(index)
    setImgList(list)
    setVisible_Images(true)
  }
  if (visible_Images) {
    return (
      <ImagePreview
        visible={visible_Images}
        images={imgList}
        onClose={() => setVisible_Images(false)}
        activeIndex={indexImg}
      />
    )
  }

  return (
    <>
      <div
        className={style.PackageBtn}
        onClick={() =>
          store.globalUiStore.changeCurrentPopup(
            !store.globalUiStore.popupState
          )
        }
      >
        {/* <img src={PackageImages} alt="" /> */}
        套餐
        <br />
        选择
      </div>
      <Popup
        className={style.Popup_box}
        visible={store.globalUiStore.popupState}
        direction="bottom"
        mask={true}
        onMaskClick={() => store.globalUiStore.changeCurrentPopup(false)}
        afterClose={() => store.globalUiStore.changeCurrentPopup(false)}
      >
        <div className={style.popupState_content}>
          <div className={style.close_btn}>
            <Button
              theme="primary"
              size="sm"
              style={{ float: 'right' }}
              onClick={() => store.globalUiStore.changeCurrentPopup(false)}
            >
              关闭
            </Button>
          </div>
          {props.meal.map((v, index: number) => {
            return (
              <div className={style.item} key={index}>
                <h4>{v.name}</h4>
                {v.detail.all.map((j, index_j) => {
                  return (
                    <>
                      <p
                        key={index_j}
                        dangerouslySetInnerHTML={{ __html: j }}
                      ></p>
                      {v.detail.desc && j !== '' && (
                        <p>{v.detail.desc[index_j]}</p>
                      )}
                    </>
                  )
                })}
                <div
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    {v.wrap_image &&
                      v.wrap_image!.map((s, index_1) => {
                        return (
                          <img
                            src={s}
                            alt=""
                            key={index_1}
                            style={{ width: 50, margin: '0 15px' }}
                            onClick={() =>
                              handleClickPreview(v.wrap_image!, index_1)
                            }
                          />
                        )
                      })}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    {v.wrap_image && (
                      <>
                        <div>实物图片</div>
                        <div>点击可放大</div>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className={style.select_action}
                  onClick={() => handleClickSelect(v)}
                >
                  {data.type === v.type ? (
                    <>
                      <img src={YesImages} alt="" style={{ width: '100%' }} />
                    </>
                  ) : (
                    <>
                      点我
                      <br />
                      选择
                    </>
                  )}{' '}
                </div>
              </div>
            )
          })}
        </div>
      </Popup>
    </>
  )
}

const PackageBtn = observer(PackageBtnS)

// is_select 权限
function ISSelect(props: {
  children: ReactComponentElement<any>
  data: resType
}) {
  function handleClickSkipResg() {
    if (props.data.link) {
      store.routerStore.replace(props.data.link)
    }
  }

  if (Object.keys(props.data).length === 0) {
    return null
  }
  if (props.data.is_select === 1) {
    return props.children
  }
  return (
    <div className={style.null_content} onClick={handleClickSkipResg}>
      <h3>{props.data.activity_name}</h3>
      {props.data.select_time && <p>选片时间: {props.data.select_time}</p>}
      {props.data.order_number && <p>订单编号: {props.data.order_number}</p>}
      <p style={{ fontSize: 25 }}>{props.data.msg}</p>
      {props.data.link && (
        <div style={{ textAlign: 'right', fontSize: 12 }}>点我去报名</div>
      )}
      <div className={style.nul_warp_top}>
        <img src={XlImages} alt="" />
        <span>需要我选择的照片</span>
      </div>
    </div>
  )
}

function useData() {
  // 在遮罩情况下是否显示底部计算
  const [Settlement, setSettlement] = useState(true)

  const SetSettlement = useCallback((e) => setSettlement((c) => e), [])

  return { Settlement, SetSettlement }
}

// 1970-01-01 08:00:00 ~ 1970-01-01 08:00:00
function selectTime(time: string): boolean {
  try {
    const start = time.split(' ~ ')[0]
    const end = time.split(' ~ ')[1]

    const startTime = new Date(start).getTime()
    const endTime = new Date(end).getTime()

    const currTime = new Date().getTime() // 当前时间

    if (startTime < currTime) {
      if (endTime > currTime) {
        // 正常活动
        return true
      } else {
        // 活动结束
        return false
      }
    } else {
      // 活动暂未开始
      return false
    }
  } catch (error) {
    Toast.show('选片时间解析失败')
    return false
  }
}
