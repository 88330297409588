import React, { useState } from 'react'
import style from "./index.module.scss"
import { BangBangTangRequest } from '../../utils/request';
import { useMount } from '@umijs/hooks';

export default () => {

    const [feedback, setfeedback] = useState(null);

    const [Notice, setNotice] = useState({
        service_content: null,
        service_telephone: null,
    }); // 获取客服电话 显示提示信息

    useMount(
        () => {
            // 获取预约提醒信息
            getInvitationNotice()
        }
    );

    /**
     * 获取反馈ID
     */
    function getFeedbackId() {

    }

    /**
     * 获取预约提醒信息
     */
    function getInvitationNotice() {
        BangBangTangRequest({
            url: "/wap/data/getInvitationNotice",
            method: "POST",
            data: { token: localStorage.getItem("token") }
        }).subscribe(value => {
            console.log((value), '<---9')
            setNotice(value)
        });
    }


    return (
        <div>
            {/* 头部 */}
            <div className={style.warp_Title}>预约提醒</div>
            {/* 主体 */}
            <div className={style.body_content}>
                <p className={style.body_content_title}>{Notice.service_content}</p>
                <div className={style.input_text}>
                    <div className={style.input_title}>给客服留言</div>
                    <textarea className={style.input_textarea} placeholder="请在此处输入您想交代客服的话 可不填" />
                    <button className={style.appointment_now}>立即预约</button>
                    <button className={style.appointment_now} style={{ background: "#ccd2d2" }}>暂不预约</button>
                </div>
            </div>
            {/* 拨打电话 */}
            <a href={`tel:${Notice.service_telephone}`} className={style.tel_Service}>拨打客服电话</a>
        </div>
    )
}