import React from "react";
import "./photo-family-collection.css";
import { BangBangTangLayout } from "../../layout/layout";
import { store } from "../../store/store.root";
import "swiper/swiper-bundle.min.css";
import { observer } from "mobx-react";

import SwiperCore, {
  A11y,
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";
import { NoticeBar, Toast } from "zarm";
import { FetchRequest } from "../../utils/fetch";

interface State {
  previewPx: number; // 上下比例
  translatePercent: number; // 左右比例
  notice_date: string;
}

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCoverflow]);

@observer
export class PhotoFamilyCollection extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      previewPx: 0,
      translatePercent: 0,
      notice_date: ''
    };
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      this.GetSetTime()
      store.photoFamilyCollection.update();
      window.document.title = "影像家谱";
    });
  }

  
  GetSetTime = async () => {
    let result = await FetchRequest(
      `/wap/data/getGenealogyNoticeData?token=${store.userStore.token || localStorage.getItem("token")}`,
      {},
      "GET"
    );
    if (result.code === 1) {
      this.setState({
        notice_date: result.data.notice_date
      })
    } else {
      Toast.show(result.msg);
    }
  }

  render() {
    if (store.photoFamilyCollection.data.length === 0) {
      return (
        <BangBangTangLayout
          hideMarginBottom={true}
          showTabBar={false}
          maskLoading={store.photoFamilyCollection.loading}
        >
          <div className="photo-family-collection">暂无数据</div>
        </BangBangTangLayout>
      );
    }

    return (
      <BangBangTangLayout
        hideMarginBottom={true}
        showTabBar={false}
        maskLoading={store.photoFamilyCollection.loading}
      >
        <NoticeBar className="warp_notice">
        您的影像家谱会在公众号内永久保存,以便您随时查看，长按图片可下载。
        </NoticeBar>
        <div className="photo-family-collection">
          {/* <div className="photo-family-collection-tooltip">
            <p>您的影像家谱会在公众号内永久保存,以便您随时查看，长按图片可下载。</p>
          </div> */}
          <Swiper
            className="main-container"
            effect="coverflow"
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            coverflowEffect={{ slideShadows: false }}
          >
            {store.photoFamilyCollection.data_flatten.map((value) => (
              <SwiperSlide key={value.id} className="swiper-item">
                <p style={{ fontSize: 15, paddingBottom: 10}}>拍摄日期： {value.takeTime}</p>
                <img src={value.pic} alt="1" />
                {/* <span>{value.takeTime}</span> */}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="message_text">下次拍摄提醒时间: {this.state.notice_date}</div>
        <div style={{
          position: 'fixed',
          width: 187,
          // height: 57,
          borderRadius: '8px',
          // lineHeight: '57px',
          bottom: '3%',
          right: 0,
          left: 0,
          margin: '0 auto',
          background: '#00bc70',
          fontSize: 13,
          textAlign: 'center',
          padding: "8px 10px",
          color: '#fff',
          zIndex: 100
        }} onClick={() => {
          store.routerStore.push("/build/genealogical-reminder")
        }}>
          <span style={{ fontSize: 14 }}>修改提醒时间</span>
        </div>
      </BangBangTangLayout>
    );
  }
}
