import { store } from '../store/store.root'

export function FetchRequest(url: string, op: {}, type = 'POST'): Promise<any> {
  if (type === 'POST') {
    return new Promise((res, rej) => {
      fetch(url, {
        method: type,
        body: formdataDispose(op, url),
      })
        .then((res) => {
          return res.json()
        })
        .then((r) => {
          res(r)
        })
        .catch((e) => {
          rej(e)
        })
    })
  } else {
    return new Promise((res, rej) => {
      fetch(url, {
        method: type,
      })
        .then((res) => {
          return res.json()
        })
        .then((r) => {
          res(r)
        })
        .catch((e) => {
          rej(e)
        })
    })
  }
}

function formdataDispose(obj: Object, u: string): FormData {
  let formdata = new FormData()

  const pass = [
    '/wap/wap2/getJsSdk',
    '/wap/graduation/getErollDetail',
    '/wap/graduation/submitEroll',
    '/wap/plate/getPlateListByUid',
    // '/wap/selectioneroll/getErollDetail',
    // '/wap/selectioneroll/getImage',
    // '/wap/selectioneroll/submitEroll',
    // '/wap/selectioneroll/erollPay',
  ]

  let objectKeys = Object.keys(
    Object.assign(
      obj,
      pass.indexOf(u) > -1
        ? {}
        : {
            token: localStorage.getItem('token') || store.userStore.token,
          }
    )
  )
  let ObjectValue = Object.values(obj)

  for (let i = 0; i < objectKeys.length; i++) {
    formdata.append(objectKeys[i], ObjectValue[i])
  }
  return formdata
}
