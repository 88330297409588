import React from "react";
import { format } from "date-fns";
import { Button } from "zarm";
import { observer } from "mobx-react";

export function EmployeePreorderListDateSelectComponent(props: {
  date: Date;
  onPrev: () => void;
  onNext: () => void;
  onDisplayClick: () => void;
}) {
  return (
    <div className="page-header">
      <Button className="prev" onClick={props.onPrev}>
        上一天
      </Button>
      <div className="display" onClick={props.onDisplayClick}>
        <p>{format(props.date, "yyyy-MM-dd")}</p>
        <p>
          {"周" +
            ["日", "一", "二", "三", "四", "五", "六"][props.date.getDay()]}
        </p>
      </div>
      <Button className="next" onClick={props.onNext}>
        下一天
      </Button>
    </div>
  );
}
