import { BangBangTangRequest } from "../../utils/request";
import { store } from "../store.root";
import { EmployeePreorderClotheResponse } from "./employee-preorder-clothe.api";
import { from, Observable, of } from "rxjs";
import cache from "./result.json";

export const EmployeeGetPreorderClothe = (): Observable<EmployeePreorderClotheResponse> => {
  // return of(cache) as any;
  return BangBangTangRequest<EmployeePreorderClotheResponse>({
    url: "/wap/clothing/getStandardListNew",
    data: { token: store.userStore.token },
    method: "POST",
  });
};
