import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'zarm'
import style from './index.module.scss'
import { withRouter } from 'react-router-dom'
import { BangBangTangRequest } from '../../utils/request'
import { FetchRequest } from '../../utils/fetch'
import { store } from '../../store/store.root'

// verification-voucher
export default withRouter((props: any) => {
  const [code] = useState(props.match.params.code)

  const [data, setData] = useState<any>({})

  const [list, setList] = useState([])

  useEffect(() => {
    document.title = '卡券核销'
    if (store.userStore.token) {
      // 获取优惠券详情
      getCouponDetail()
    }
  }, [store.userStore.token])

  /**
   * 获取详情列表数据
   */
  async function getCouponDetaileList(id: number) {
    let result = await FetchRequest('/wap/coupon/getWriteOffByMyCoupon', {
      token: localStorage.getItem('token') || store.userStore.token,
      coupon_id: id,
      coupon_type: 1,
    })
    if (result.code === 1) {
      setList(result.data)
    }
  }

  /**
   * 获取优惠券详情
   */
  async function getCouponDetail() {
    let result = await FetchRequest(
      `/wap/coupon/scanCouponDetail?token=${
        localStorage.getItem('token') || store.userStore.token
      }&code=${code}`,
      {},
      'GET'
    )
    if (result.code === 1) {
      setData(result.data)
      // 获取优惠券详情列表
      getCouponDetaileList(result.id)
    } else {
      // 券查询失败处理
      const modal = Modal.alert({
        title: '提示',
        content: result.msg,
        onCancel: () => {
          modal.hide()
          props.history.push('/build/my-info')
        },
      })
    }
  }

  /**
   * 确定核销
   */
  async function handleClickWriteOffCode() {
    let result = await FetchRequest(
      `/wap/coupon/submitWriteOff?token=${
        localStorage.getItem('token') || store.userStore.token
      }&code=${code}`,
      {},
      'GET'
    )
    const modal = Modal.alert({
      title: '核销卡券',
      content: result.msg,
      onCancel: () => {
        modal.hide()
        props.history.push('/build/my-info')
      },
    })

    // 核销
    // BangBangTangRequest({
    //     url: `/wap/coupon/submitWriteOff?token=${localStorage.getItem("token")}&code=${code}`,
    //     data: {
    //         token: localStorage.getItem("token"),
    //     },
    //     method: "GET",
    // }).subscribe((result) => {
    //     const modal = Modal.alert({
    //         title: '核销成功',
    //         content: result.msg,
    //         onCancel: () => {
    //             modal.hide();
    //             props.history.push("/build/my-info")
    //         },
    //     });
    // })
  }

  return (
    <div className={style.voucher_content_f}>
      <div className={style.voucher_content}>
        <div className={style.voucher_warp}>
          <img src={data.card_image} alt="" />
        </div>
        <h1 className={style.voucher_title}>卡券核销</h1>
        <div className={style.voucher_cell}>
          <div className={style.voucher_item}>
            <div>卡券名称</div>
            <div>{data.card_name}</div>
          </div>
          <div className={style.voucher_item}>
            <div>核销方式</div>
            <div>{data.writeOffMethod}</div>
          </div>
          <div className={style.voucher_item}>
            <div>核销次数</div>
            <div className={style.voucher_text}>{data.writeoff_count_use}</div>
          </div>
          <div className={style.voucher_item}>
            <div>剩余核销次数</div>
            <div className={style.voucher_text}>{data.writeoff_count}</div>
          </div>
          <div className={style.voucher_item}>
            <div>有效期</div>
            <div>{data.validity}</div>
          </div>
          <div className={style.voucher_item}>
            <div>使用须知</div>
          </div>
          <div className={style.voucher_item}>{data.remark}</div>
        </div>
      </div>
      <div className={style.voucher_step}>
        {list.map(
          (v: { id: number; wechat_name: string; writeoff_time: string }) => {
            return (
              <div className={style.step_item} key={v.id}>
                <div className={style.left_icon}>
                  <div>
                    <div className={style.read_icon}></div>
                    <div className={style.link_cion}></div>
                  </div>
                  <span style={{ marginLeft: 10 }}>核销次数 - 1</span>
                </div>
                <div style={{ padding: '5px 0' }}>
                  <div>核销员: {v.wechat_name}</div>
                  <div>{v.writeoff_time}</div>
                </div>
              </div>
            )
          }
        )}
      </div>
      <div className={style.voucher_btn_cation}>
        <button
          style={{ color: '#ff5050' }}
          onClick={() => props.history.push('/build/my-info')}
        >
          返回
        </button>
        <div className={style.voucher_icon_btn}></div>
        <button style={{ color: '#00bc71' }} onClick={handleClickWriteOffCode}>
          确认核销
        </button>
      </div>
    </div>
  )
})
