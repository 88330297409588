import React from 'react'
import { Button, Cell, Input, Modal } from 'zarm'
import { observer } from 'mobx-react'
import { store } from '../../store/store.root'

@observer
export class CustomerClotheSaveModal extends React.Component<any, {}> {
  constructor(props: any) {
    super(props)
  }
  render() {
    return (
      <Modal
        visible={store.employeePreorderClotheStore.save_modal_visible}
        onCancel={() =>
          store.employeePreorderClotheStore.toggle_save_modal_visible(false)
        }
        width="90%"
        title="输入用户信息"
      >
        <Cell title="用户姓名">
          <Input
            placeholder={'输入用户姓名'}
            value={store.employeePreorderClotheStore.customer_name}
            onChange={(value: string | undefined) =>
              store.employeePreorderClotheStore.set_customer_name(value || '')
            }
          />
        </Cell>
        <Cell title="用户手机">
          <Input
            placeholder={'输入用户手机'}
            onChange={(value: string | undefined) =>
              store.employeePreorderClotheStore.set_mobile(value || '')
            }
            type="number"
            value={store.employeePreorderClotheStore.mobile}
          />
        </Cell>
        <Cell title={'最大可选'}>
          <Input
            placeholder={'最大的服装可选数量'}
            type="number"
            value={store.employeePreorderClotheStore.limit}
            onChange={(value: string | undefined) =>
              store.employeePreorderClotheStore.set_limit(value || '')
            }
          />
        </Cell>
        <div className="employee-clothe-save-modal-button-row">
          <Button
            block
            onClick={() =>
              store.employeePreorderClotheStore.toggle_save_modal_visible(false)
            }
          >
            返回
          </Button>
          <Button
            block
            theme={'primary'}
            disabled={!store.employeePreorderClotheStore.form_data_is_valid}
            loading={store.employeePreorderClotheStore.submit_loading}
            onClick={() => {
              store.employeePreorderClotheStore.submit()
            }}
          >
            提交
          </Button>
        </div>
      </Modal>
    )
  }
}
