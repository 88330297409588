import React from 'react'
import { BangBangTangLayout } from '../../layout/layout'
import style from './index.module.scss'
import { Tabs, Cell, Button, Modal, Input, Toast, DatePicker } from 'zarm'
import { store } from '../../store/store.root'
import { BangBangTangRequest } from '../../utils/request'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
// import { toast } from "react-toastify";
import { FetchRequest } from '../../utils/fetch'
import { finalize } from 'rxjs/operators'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'

const { Panel } = Tabs

@observer
export class MyDistribution extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      list: [],
      saleman_name: '',
      industry: '',
      birthday: '',
      is_show: false,
      loading: false,
      visible_DatePicker: false,
      is_apply: 0,
    }
  }

  static propTypes = {
    history: PropTypes.object,
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      window.document.title = '我的分销活动'
      this.getList()
    })
  }

  getList = async () => {
    let that = this
    BangBangTangRequest({
      url: '/wap/marketing/Base/getStaffActivityList',
      method: 'POST',
      data: {
        token: localStorage.getItem('token'),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        that.setState(
          {
            list: result,
          },
          () => {
            // if (result.length === 1) {
            //   that.props.history.push("/build/my-settlement/" + result[0].key);
            // }
          }
        )
      })

    let res = await FetchRequest('/wap/marketing/Saleman/getApplyStatus', {
      token: localStorage.getItem('token'),
    })
    if (res.code === 1) {
      this.setState({
        is_apply: res.data.is_apply,
      })
    }
  }

  handleSubm = async () => {
    if (
      !this.state.saleman_name ||
      !this.state.industry ||
      !this.state.birthday
    ) {
      Toast.show('信息请填写完整')
      return
    }
    this.setState({ loading: true })
    let res = await FetchRequest('/wap/marketing/Saleman/addApply', {
      token: localStorage.getItem('token'),
      saleman_name: this.state.saleman_name,
      industry: this.state.industry,
      birthday: this.state.birthday,
    })
    if (res.code === 1) {
    }
    Toast.show(res.msg)
    this.setState({
      loading: false,
      is_show: false,
      saleman_name: '',
      industry: '',
    })
  }

  render() {
    const { list } = this.state
    return (
      <BangBangTangLayout title={'我的分销活动'}>
        {this.props.isshow && (
          <Button
            theme="primary"
            disabled={this.state.is_apply === 1}
            className={style.container_btn}
            onClick={() => this.setState({ is_show: true })}
          >
            {this.state.is_apply === 1 ? '审核中' : '申请加入'}
          </Button>
        )}
        <Modal
          visible={this.state.is_show}
          title="申请加入"
          closable
          onCancel={() => {
            this.setState({
              is_show: false,
            })
          }}
        >
          <div className={style.input_con}>
            <Cell title="姓名">
              <Input
                clearable
                type="text"
                placeholder="请输入姓名"
                defaultValue={this.state.saleman_name}
                onChange={(value: any) => {
                  this.setState({ saleman_name: value })
                }}
              />
            </Cell>
            <Cell title="所属行业">
              <Input
                clearable
                type="text"
                placeholder="请输入所属行业"
                // value={title}
                defaultValue={this.state.industry}
                onChange={(value: any) => {
                  this.setState({ industry: value })
                }}
              />
            </Cell>
            <Cell
              title="出生日期"
              onClick={() => this.setState({ visible_DatePicker: true })}
            >
              <Input
                disabled
                clearable
                type="text"
                placeholder="请选择出生日期"
                value={this.state.birthday}
              />
            </Cell>
            <div>
              <Button
                className={style.form_sub_btn}
                loading={this.state.loading}
                block
                theme="primary"
                onClick={this.handleSubm}
              >
                提交
              </Button>
            </div>
          </div>
        </Modal>
        {/* 日期 */}
        <DatePicker
          visible={this.state.visible_DatePicker}
          mode="date"
          value={this.state.birthday}
          onOk={(value: number | Date) => {
            this.setState({
              birthday: format(value, 'yyyy-MM-dd'),
              visible_DatePicker: false,
            })
          }}
          onCancel={() => this.setState({ visible_DatePicker: false })}
        />
      </BangBangTangLayout>
    )
  }
}
export default withRouter(MyDistribution)
