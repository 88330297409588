// import { LOAD_STATE } from "zarm/types/pull/PropsType";
import { autorun, computed, makeAutoObservable, runInAction, toJS } from "mobx";
import { BangBangTangRequest } from "../../utils/request";
import { store } from "../store.root";
import {
  CustomerPreorderClotheListDataInterface,
  CustomerPreorderClotheListResponse,
} from "./customer-preorder-clothe-list.interface";

const LOAD_STATE_VALUE = {
  normal: 0,
  abort: 1,
  loading: 2,
  success: 3,
  failure: 4,
  complete: 5
}

declare enum LOAD_STATE {
  normal = 0,
  abort = 1,
  loading = 2,
  success = 3,
  failure = 4,
  complete = 5
}

export type CustomerPreorderClotheListStatusString =
  | "未提交"
  | "已提交"
  | "取消"
  | "全部";
export const CUSTOMER_PREORDER_CLOTHE_LIST_STATUS_STRING: CustomerPreorderClotheListStatusString[] = [
  "未提交",
  "已提交",
  "全部",
];

interface CustomerPreorderClotheListStoreDataDic {
  page: number;
  data: CustomerPreorderClotheListDataInterface[] | null;
  load_state: LOAD_STATE;
}
export class CustomerPreorderClotheListStore {
  data: Map<
    CustomerPreorderClotheListStatusString,
    CustomerPreorderClotheListStoreDataDic
  > = new Map([
    ["未提交", { page: 0, data: null, load_state: LOAD_STATE_VALUE.normal }],
    ["已提交", { page: 0, data: null, load_state: LOAD_STATE_VALUE.normal }],
    ["取消", { page: 0, data: null, load_state: LOAD_STATE_VALUE.normal }],
    ["全部", { page: 0, data: null, load_state: LOAD_STATE_VALUE.normal }],
  ]);
  @computed get active_panel_index(): number {
    return CUSTOMER_PREORDER_CLOTHE_LIST_STATUS_STRING.findIndex(
      (value) => value === this.active_panel_name
    );
  }
  @computed get un_commit_data():
    | CustomerPreorderClotheListDataInterface[]
    | null {
    return this.data.get("未提交")!.data;
  }
  @computed get un_commit_data_length(): number {
    return this.un_commit_data ? this.un_commit_data.length : 0;
  }
  active_panel_name: CustomerPreorderClotheListStatusString = "全部";
  load_more = () => {
    let type;
    switch (this.active_panel_name) {
      case "未提交":
        type = "uncommitted";
        break;
      case "已提交":
        type = "committed";
        break;
      case "取消":
        type = "canceled";
        break;
      case "全部":
        type = "";
        break;
    }
    this.requestDataFromServer(this.current_active.page + 1, type as any, () =>
      runInAction(() => (this.current_active.load_state = LOAD_STATE_VALUE.loading))
    );
  };
  @computed get current_active(): CustomerPreorderClotheListStoreDataDic {
    return this.data.get(this.active_panel_name)!;
  }

  constructor() {
    makeAutoObservable(this);
  }

  change_active_panel_index(index: number | undefined) {
    if (index !== undefined) {
      this.active_panel_name =
        CUSTOMER_PREORDER_CLOTHE_LIST_STATUS_STRING[index];
      if (this.current_active.page === 0) {
        this.load_more();
      }
    }
  }
  requestDataFromServer(
    page: number,
    type: "uncommitted" | "committed" | "canceled" | "",
    onBefore?: () => void,
    onAfter?: () => void
  ) {
    if (onBefore) {
      onBefore();
    }
    BangBangTangRequest<CustomerPreorderClotheListResponse>({
      url: `/wap/clothing/getUserSelectionList.html?token=${
        store.userStore.token
      }&page=${page}${type === "" ? "" : `&type=${type}`}`,
      data: {},
      method: "GET",
    }).subscribe((value) => {
      let panel_name: CustomerPreorderClotheListStatusString;
      switch (type) {
        case "":
          panel_name = "全部";
          break;
        case "canceled":
          panel_name = "取消";
          break;
        case "committed":
          panel_name = "已提交";
          break;
        case "uncommitted":
          panel_name = "未提交";
          break;
      }
      const origin = this.data.get(panel_name)!;
      if (value.length === 0) {
        runInAction(() => {
          origin.load_state = LOAD_STATE_VALUE.complete;
        });
      } else {
        runInAction(() => {
          origin.load_state = LOAD_STATE_VALUE.normal;
          origin.page += 1;
        });
        if (origin.data !== null) {
          runInAction(() => {
            origin.data = [...origin.data!, ...value];
          });
        } else {
          runInAction(() => {
            origin.data = value;
          });
        }
      }
    });
  }

  reset() {
    // 清空所有内容
    this.data = new Map([
      ["未提交", { page: 0, data: null, load_state: LOAD_STATE_VALUE.normal }],
      ["已提交", { page: 0, data: null, load_state: LOAD_STATE_VALUE.normal }],
      ["取消", { page: 0, data: null, load_state: LOAD_STATE_VALUE.normal }],
      ["全部", { page: 0, data: null, load_state: LOAD_STATE_VALUE.normal }],
    ]);
    this.active_panel_name = "全部";
  }
}
