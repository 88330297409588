import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Loading, Modal } from 'zarm'
import BGImages from '../../assets/success.png'
import { FetchRequest } from '../../utils/fetch'
import { getParamValue } from '../../utils/url'
import style from './index.module.scss'

const styleBox: React.CSSProperties = {
  position: 'relative',
  height: '100vh',
}

const styleImage: React.CSSProperties = {
  width: '100%',
  height: '100%',
}

const styleCode: React.CSSProperties = {
  position: 'absolute',
  width: 235,
  top: '15%',
  left: 0,
  right: 0,
  margin: '0 auto',
}

export default () => {
  const eroll_id = getParamValue('eroll_id')
  useEffect(() => {
    document.title = '支付成功'
    GetCode()
  }, [])
  const [IamgesBase64, setIamgesBase64] = useState('')

  const [babyList, setBabyList] = useState([])
  const [chooseTheBaby, setChooseTheBaby] = useState(false)
  const [activity_id, setActivity_id] = useState('')

  async function GetCode() {
    let res = await FetchRequest('/wap/selectioneroll/filmSuccess', {
      token: localStorage.getItem('token'),
      eroll_id,
    })
    if (res.code === 1) {
      setIamgesBase64(res.data.image)
      setActivity_id(res.data.activity_id)
      if (res.data.is_go === 1) {
        setBabyList(res.data.erollId)
        setChooseTheBaby(true)
      }
    }
  }
  return (
    <div style={styleBox}>
      <img src={BGImages} alt="" style={styleImage} />
      <div style={styleCode}>
        <img src={IamgesBase64} alt="" style={{ width: '100%' }} />
        <p style={{ textAlign: 'center', fontSize: 15 }}>
          长按识别上方二维码关注公众号
        </p>
        <p style={{ textAlign: 'center', fontSize: 15 }}>
          进入首页即可{' '}
          <Link
            to={'/build/film-download'}
            style={{ textDecoration: 'underline', color: '#fff' }}
          >
            下载底片
          </Link>
        </p>
      </div>
      <Modal visible={chooseTheBaby} title="继续为宝宝选片">
        <div className={style.select_baby}>
          {babyList.map((v: { id: number; name: string; orderNum: number }) => {
            return (
              <div key={v.id}>
                <Button
                  theme="primary"
                  block
                  disabled={v.orderNum > 0}
                  onClick={() => {
                    localStorage.setItem('eroll_id', v.id + '')
                    location.href = `/build/selection-activities/${activity_id}`
                  }}
                >
                  {v.name}
                </Button>
              </div>
            )
          })}
        </div>
      </Modal>
    </div>
  )
}
