import React, { useState, useEffect, useRef } from "react";
import { Cell, Pull } from "zarm";
import { store } from "../../store/store.root";
import { FetchRequest } from "../../utils/fetch";

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
};

const style: React.CSSProperties = {
  textAlign: "center",
  fontSize: 15,
  color: "#9d9d9d",
};

export default () => {
  useEffect(() => {
    document.title = "积分管理";
    GetPointsListsList();
    GetUserPointData()
  }, []);

  const pullRef = useRef();

  const [page, setPage] = useState(1);

  const [integralList, setIntegralList] = useState([]);

  const [total_point, setTotal_point] = useState(0)

  const [loading, setLoading] = useState(LOAD_STATE.normal);

  // 所有记录
  async function GetPointsListsList() {
    setLoading(LOAD_STATE.loading);
    let exchange = await FetchRequest(`/wap/marketing/transfermall/User/getPointsLists?token=${localStorage.getItem("token")}&page=${page}`,{},"GET");
    setIntegralList(exchange.data);

    setLoading(LOAD_STATE.success);
    setPage(page + 1);
  }

  async function GetUserPointData() {
    const res = await FetchRequest(`/wap/marketing/transfer/getUserPointData?token=${localStorage.getItem("token")}`,{},"GET");
    if (res.code === 1) {
      setTotal_point(res.data.total_point)
    }
  }

  return (
    <div>
      <Pull
        ref={pullRef}
        load={{
          state: loading,
          distance: 200,
          handler: () => {
            GetPointsListsList();
          },
        }}
      >

        <Cell title={store.userStore.username} description={`当前积分: ${total_point}`} style={{ borderBottom: '5px solid #eee' }} />

        {integralList.map(
          (v: { id: number; description: string; points: number; option_type: "reduce"  | "add"; create_time_text: string; avatar: string  }) => {
            return (
              <Cell key={v.id}  icon={(
                <div>
                  {v.avatar && (<img alt="" src={v.avatar} style={{ width: 40, height: 40, borderRadius: '50%' }} />)}                  
                </div>
              )} title={
                <div className="box">
                  <div className="box-title">{v.description}</div>
                  <div className="box-description">{v.create_time_text}</div>
                </div>
              } description={
                <div>
                  {v.option_type === "add" && (<div style={{ color: "#00bc70" }}>+ {v.points}</div>)}
                  {v.option_type === "reduce" && (<div style={{ color: "red" }}>- {v.points}</div>)}
                </div>
              } />
            );
          }
        )}
        
      </Pull>
      {integralList.length === 0 &&  LOAD_STATE.loading !== loading && <div style={style}>暂无数据哦~</div>}
    </div>
  );
};
