import React from "react";

export class PreorderStatusRender extends React.Component<
  { value: string },
  any
> {
  render() {
    let color;
    switch (this.props.value) {
      case "未审核":
        color = "#FF5050";
        break;
      case "已审核":
        color = "#309EEB";
        break;
      case "备档":
        color = "#EC9131";
        break;
      case "取消":
        color = "#999999";
        break;
      default:
        color = "black";
    }
    return <span style={{ color }}>{this.props.value}</span>;
  }
}
