import React, { useEffect, useState } from 'react'
import './my-preorder-detail.scss'
import { store } from '../../store/store.root'
import { BangBangTangRequest } from '../../utils/request'
import { MyPreorderDetailInterface } from '../my-preorder/my-preorder.api'
import { Button, Cell, ImagePreview } from 'zarm'
import { BangBangTangLayout } from '../../layout/layout'
import { MyPreorderCancelModal } from '../my-preorder/components/my-preorder-cancel-modal'
import { readAuth } from '../../utils/readAuth'
import { add, format, isAfter, isBefore, parse } from 'date-fns'
import * as _ from 'lodash'
import {
  appIsFirstReadySubject,
  appIsReadySubject,
} from '../../store/login-complete-subject'
import { withRouter } from 'react-router-dom'
import { FooterButtons } from '../../components/footer-buttons/footer-buttons'
import { observer } from 'mobx-react'
import { PreorderStatusRender } from '../../components/preorder-status-render'

@observer
class IMyPreorderDetail extends React.Component<
  { params?: any; location: any; history: any; match: any },
  {
    id: string
    data: MyPreorderDetailInterface | null
    preview: number | null
    modalVisible: boolean
    now: Date
    preorderDate: Date | null
  }
> {
  constructor(props: any) {
    super(props)
    this.state = {
      id: '',
      data: null,
      preorderDate: null,
      now: new Date(),
      preview: null,
      modalVisible: false,
    }
  }
  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      window.document.title = '我的预约详情'
      const id = this.props.match.params.id
      this.setState({ id })
      BangBangTangRequest({
        url: '/wap/schedule/getUserBookingDetails',
        data: { token: store.userStore.token, booking_id: id },
        method: 'POST',
      }).subscribe((value) => this.setState({ data: value }))
    })
  }

  cancelPreorder = (id: number | string) => {
    BangBangTangRequest({
      url: '/wap/schedule/cancelScheduleApplication',
      method: 'POST',
      data: {
        token: store.userStore.token,
        booking_id: id,
      },
    }).subscribe(() => {
      alert('取消成功')
      window.location.reload()
    })
  }
  render() {
    const data = this.state.data
    const preorderDate =
      data?.schedule_time && data?.schedule_date
        ? parse(
            `${data!.schedule_date} ${data!.schedule_time}`,
            'yyyy-MM-dd HH:mm:ss',
            new Date()
          )
        : ''
    const images: string[] =
      data?.standard && data.standard.length > 0
        ? data.standard.map((v) => v.showimages[0])
        : []
    return (
      <BangBangTangLayout title={'预约详情'}>
        <div className="my-preorder-detail">
          <Cell title={'预约门店'} description={data?.store_name} />
          <Cell title={'档期类型'} description={data?.type_name} />
          <Cell title={'档期日期'} description={data?.schedule_date} />
          <Cell title={'档期时间'} description={data?.schedule_time} />
          <Cell
            title={'预约单状态'}
            description={<PreorderStatusRender value={data?.status!} />}
          />

          <div className="clothe">
            <Cell title="预选服装" description={`${images.length}`} />
            <div className="clothe-container">
              {images && images.length > 0 ? (
                <ImagePreview
                  onClose={() => this.setState({ preview: null })}
                  images={images}
                  activeIndex={this.state.preview || 0}
                  visible={this.state.preview !== null}
                />
              ) : null}
              {images.map((value, index) => (
                <img
                  src={value}
                  key={value + ''}
                  onClick={() => this.setState({ preview: index })}
                  alt={''}
                />
              ))}
            </div>
          </div>
          <FooterButtons
            buttons={[
              // {
              //   label: "取消档期",
              //   onClick: () => {
              //     this.setState({ modalVisible: true });
              //   },
              //   hidden: !(
              //     preorderDate &&
              //     isBefore(this.state.now, preorderDate) &&
              //     this.state.data?.status !== "取消"
              //   ),
              // },
              {
                label: '预选服装',
                hidden: !(
                  data?.selection_id && this.state.data?.status !== '取消'
                ),
                color: '#12C287',
                onClick: () =>
                  store.routerStore.push(
                    `/build/customer-preorder-clothe-detail?id=${data?.selection_id}&max=40`
                  ),
              },
            ]}
          />
          {/*{data?.status !== "客户已取消" ? (*/}
          {/*  <div className="footer-button-row">*/}
          {/*    {preorderDate && isBefore(this.state.now, preorderDate) ? (*/}
          {/*      <Button*/}
          {/*        theme="danger"*/}
          {/*        onClick={() => this.setState({ modalVisible: true })}*/}
          {/*      >*/}
          {/*        取消档期*/}
          {/*      </Button>*/}
          {/*    ) : null}*/}
          {/*    {preorderDate &&*/}
          {/*    isBefore(this.state.now, preorderDate) &&*/}
          {/*    isAfter(this.state.now, add(preorderDate, { days: -1 })) ? (*/}
          {/*      <Button>确认到店</Button>*/}
          {/*    ) : null}*/}
          {/*  </div>*/}
          {/*) : null}*/}
          <MyPreorderCancelModal
            visible={this.state.modalVisible}
            onConfirmCancel={() => this.cancelPreorder(data!.id)}
            onCancel={() => this.setState({ modalVisible: false })}
            cancelPending={false}
          />
        </div>
      </BangBangTangLayout>
    )
  }
}

export const MyPreorderDetail = withRouter(IMyPreorderDetail)
