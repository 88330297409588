import React from "react";
import {
  Button,
  Calendar,
  Cell,
  DatePicker,
  Input,
  Modal,
  Popup,
  Radio,
} from "zarm";
import { observer } from "mobx-react";
import "./preorder-schedule.scss";
import { store } from "../../store/store.root";
import { format } from "date-fns";
import { BangBangTangRequest } from "../../utils/request";
import { catchError, finalize } from "rxjs/operators";
import { preorderScheduleActions } from "../../store/preorder-schedule/preorder-schedule.action";
import { of } from "rxjs";

@observer
export class CustomerInfoModal extends React.Component<
  any,
  { loading: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { loading: false };
  }
  submit = () => {
    this.setState({ loading: true });
    BangBangTangRequest({
      url: "/wap/schedule/submitScheduleUserInfo",
      method: "POST",
      data: {
        token: store.userStore.token,
        name: store.preorderScheduleStore.username,
        birthday: store.preorderScheduleStore.birthday,
      },
    })
      .pipe(
        catchError(() => of(1)),
        finalize(() => this.setState({ loading: false }))
      )
      .subscribe(() => {
        store.preorderScheduleStore.change_customer_info_modal_visible(false);
        preorderScheduleActions.changeScheduleConfirmModalDisplay(true);
      });
  };
  close() {
    store.preorderScheduleStore.changeUsername("");
    store.preorderScheduleStore.changeBirthday("");
    store.preorderScheduleStore.change_customer_info_modal_visible(false);
  }
  render() {
    return (
      <Modal
        title="拍摄者信息"
        visible={store.preorderScheduleStore.customer_info_modal_visible}
        onCancel={() => this.close()}
        className="customer-info-modal"
        width="90%"
        footer={
          <div style={{ width: "100%" }}>
            <Button
              block
              style={{ marginTop: ".5em" }}
              disabled={this.state.loading}
              onClick={() => this.close()}
            >
              取消
            </Button>
            <Button
              block
              style={{ marginTop: ".5em" }}
              theme="primary"
              onClick={this.submit}
              disabled={
                this.state.loading ||
                !store.preorderScheduleStore.username ||
                !store.preorderScheduleStore.birthday ||
                !store.preorderScheduleStore.gender
              }
            >
              确定
            </Button>
          </div>
        }
      >
        <Input
          placeholder={"请输入孕妇/宝宝姓名"}
          value={store.preorderScheduleStore.username}
          onChange={(value: any) =>
            store.preorderScheduleStore.changeUsername(value)
          }
        />
        <div
          className="input-like"
          onClick={() =>
            store.preorderScheduleStore.changeBirthDayDatePickVisible(true)
          }
        >
          {store.preorderScheduleStore.birthday || "请输入预产期/宝宝生日"}
        </div>
        <Cell
          description={
            <Radio.Group
              type="button"
              value={store.preorderScheduleStore.gender || ""}
              onChange={(value: any) => {
                store.preorderScheduleStore.changeGender(value);
              }}
            >
              <Radio value="female">女孩</Radio>
              <Radio value="male">男孩</Radio>
            </Radio.Group>
          }
          title={"宝宝性别"}
        />
        <DatePicker
          visible={store.preorderScheduleStore.birthDayDatePickVisible}
          mode={"date"}
          min={"1970-01-01"}
          max={"2099-12-31"}
          defaultValue={store.preorderScheduleStore.birthday || new Date()}
          onOk={(value: any) => {
            store.preorderScheduleStore.changeBirthday(
              format(value, "yyyy-MM-dd")
            );
            store.preorderScheduleStore.changeBirthDayDatePickVisible(false);
          }}
          onCancel={() => {
            store.preorderScheduleStore.changeBirthDayDatePickVisible(false);
          }}
        />
      </Modal>
    );
  }
}
