import React, { useEffect, useState } from 'react'
import { Button, Input, Modal, Toast } from 'zarm'
import { store } from '../../store/store.root'
import { FetchRequest } from '../../utils/fetch'
import styles from './index.module.scss'
/**
 * desc_image 规则图片
 * commission 用户佣金
 * remain_money 结余佣金
 * withdrawal 累计体现
 */
export default () => {
  useEffect(() => {
    document.title = '我的佣金'
    handleGetUserData()
  }, [])

  const [toApplyForCashWithdrawals, setToApplyForCashWithdrawals] =
    useState(false)
  const [withdrawalAmount, setWithdrawalAmount] = useState('')

  const [myUserComm, setmyUserComm] = useState<{
    desc_image?: string[]
    commission?: string
    remain_money?: string
    withdrawal?: string
  }>({})

  async function handleGetUserData() {
    let res = await FetchRequest('/wap/marketing/Redpacket/getUserData', {})
    if (res.code === 1) {
      setmyUserComm({
        desc_image: res.data.setting.desc_image,
        commission: res.data.userData.commission,
        remain_money: res.data.userData.remain_money,
        withdrawal: res.data.userData.withdrawal,
      })
      setWithdrawalAmount(res.data.userData.commission)
    } else {
      Toast.show(res.msg)
    }
  }

  return (
    <div>
      <div className={styles.content}>
        <div>
          用户佣金: <span>{myUserComm.commission}</span>
        </div>
        <div>
          结余佣金: <span>{myUserComm.remain_money}</span>
        </div>
        <div>
          累计提现: <span>{myUserComm.withdrawal}</span>
        </div>
      </div>
      <div className={styles.rulesOfThePicture}>
        {myUserComm.desc_image?.map((v, index) => {
          return <img src={v} alt="" key={index} />
        })}
      </div>
      <div
        className={styles.withdrawalStyles}
        onClick={() => setToApplyForCashWithdrawals(true)}
      >
        申请提现
      </div>
      <div
        className={styles.theCommissionSubsidiaryStyles}
        onClick={() => store.routerStore.push('/build/commission/subsidiary')}
      >
        佣金
        <br />
        明细
      </div>
      <div
        className={styles.theWithdrawalOfSubsidiaryStyles}
        onClick={() => store.routerStore.push('/build/withdrawal/subsidiary')}
      >
        提现
        <br />
        明细
      </div>
      <Modal
        title="申请提现"
        visible={toApplyForCashWithdrawals}
        closable
        onCancel={() => setToApplyForCashWithdrawals(false)}
        footer={
          <Button
            block
            theme="primary"
            onClick={async () => {
              if (Number.isNaN(parseFloat(withdrawalAmount))) {
                Toast.show('输入金额错误!')
              } else {
                let res = await FetchRequest(
                  '/wap/marketing/Redpacket/applyWithdrawal',
                  {
                    token: localStorage.getItem('token'),
                    money: parseFloat(withdrawalAmount),
                  }
                )
                if (res.code === 1) {
                  handleGetUserData()
                  setToApplyForCashWithdrawals(false)
                  Toast.show(res.msg)
                } else {
                  Toast.show(res.msg)
                }
              }
            }}
          >
            确认
          </Button>
        }
      >
        <Input
          type="text"
          placeholder="请输入提现金额"
          value={withdrawalAmount}
          onChange={(value: any) => {
            setWithdrawalAmount(value)
          }}
        />
      </Modal>
    </div>
  )
}
