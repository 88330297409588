import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Modal } from 'zarm'
import { store } from '../../store/store.root'

export default observer(({ children }: any) => {
  return (
    <div>
      {children}
      {/* <Modal
        visible={store.userStore.need_info}
        title="标题"
        closable
        onCancel={() => {}}
      >
        模态框内容
      </Modal> */}
    </div>
  )
})
