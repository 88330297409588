import React, { useState, useEffect } from 'react'
import { DatePicker, Loading, Pull, Switch, Tabs, Toast } from 'zarm'
import { FetchRequest } from '../../utils/fetch'
import { withRouter } from 'react-router-dom'
import style from './index.module.scss'
import { format } from 'date-fns'
import { getParamValue, getUtilParams } from '../../utils/url'
import CloseY from '../../assets/close_y.png'
import OpenY from '../../assets/open_y.png'
import dayjs from 'dayjs'

const { Panel } = Tabs

export default withRouter((props: any) => {
  const [tabsValue, setTabsValue] = useState(0)

  const [page, setPage] = useState(1)

  const [startTime, setStartTime] = useState(false)
  const [endTime, setEndTime] = useState(false)

  const [startTimeValue, setStartTimeValue] = useState('')
  const [endTimeValue, setEndTimeValue] = useState('')
  const [checked, setChecked] = useState(false)

  const [data, setData] = useState<{ total: number }>({
    total: 0,
  })
  const [tab, setTab] = useState<
    {
      key: string
      title: string
    }[]
  >([])
  const [list, setList] = useState<
    {
      avatar: string
      saleman: string
      mobile: string
      user_name: string
      is_mobile_show: boolean
      create_time_text: string
    }[]
  >([])

  useEffect(() => {
    // 默认三十天
    const startDayjs = dayjs().subtract(30, 'days').format('YYYY-MM-DD')
    setStartTimeValue(startDayjs)
    setEndTimeValue(dayjs().format('YYYY-MM-DD'))
  }, [])

  useEffect(() => {
    setData({
      total: 0,
    })
    setTimeout(() => {
      toGetTheDataList()
    }, 500)
  }, [startTimeValue, endTimeValue, tabsValue, checked])

  async function toGetTheDataList() {
    Loading.show()
    const res = await FetchRequest(`/wap/marketing/base/getUserrecord`, {
      activity_type: getParamValue('activity_type'),
      token: localStorage.getItem('token'),
      startTime: startTimeValue
        ? startTimeValue
        : format(new Date(), 'yyyy-MM-dd'),
      endTime: endTimeValue ? endTimeValue : format(new Date(), 'yyyy-MM-dd'),
      activity_id: getParamValue('id'),
      tab: tab.length === 0 ? '' : tab[tabsValue].key,
      only:
        getParamValue('is_leader') === 'no' ? 'yes' : checked ? 'yes' : 'no',
      page,
    })
    if (res.code === 1) {
      setTab(res.data.tab)
      setList(
        Array.from(new Set(list.concat(res.data.list))).map((v) => {
          return {
            ...v,
            is_mobile_show: false,
          }
        })
      )
      setData(res.data)
      setPage(page + 1)
    } else {
      Toast.show(res.msg)
    }
    Loading.hide()
  }

  function handleIsMobile(index: number) {
    let newArrayList = ([] as any[]).concat(list)
    newArrayList[index].is_mobile_show = !newArrayList[index].is_mobile_show
    setList(newArrayList)
  }

  function handleDesensitization(
    mobile: string,
    is_mobile_show: boolean
  ): string {
    if (mobile.length !== 11) {
      return '错误的手机号位数'
    }
    if (is_mobile_show) {
      return mobile
    }
    return `${mobile.substring(0, 3)}****${mobile.substring(7, 11)}`
  }

  return (
    <div className={style.set_body}>
      {tab.length > 0 && (
        <Tabs
          value={tabsValue}
          onChange={(e: any) => {
            setList([])
            setPage(1)
            setTabsValue(e)
          }}
        >
          {tab.map(
            (
              v: {
                key: string
                title: string
              },
              i
            ) => {
              return (
                <Panel title={v.title} key={i}>
                  <div></div>
                </Panel>
              )
            }
          )}
        </Tabs>
      )}

      <div
        className={style.timeSelect}
        style={tab.length === 0 ? { marginTop: 0 } : {}}
      >
        <div className={style.left_count_size}>
          合计:
          <div className={style.total}>{data.total}</div>
        </div>
        <div
          onClick={() => {
            setPage(1)
            setList([])
            setStartTime(true)
          }}
        >
          {startTimeValue ? startTimeValue : '点击选择开始时间'}
        </div>

        <div
          onClick={() => {
            setPage(1)
            setList([])
            setEndTime(true)
          }}
        >
          {endTimeValue ? endTimeValue : '点击选择结束时间'}
        </div>
      </div>

      {getParamValue('is_leader') === 'yes' && (
        <div className={style.switc_my}>
          是否只看自己:{' '}
          <Switch
            checked={checked}
            onChange={(e: any) => {
              setList([])
              setPage(1)
              setChecked(e)
            }}
          />
        </div>
      )}

      <div className={style.list_data}>
        <Pull
          load={{
            state: 0,
            distance: 200,
            handler: () => {
              toGetTheDataList()
            },
          }}
        >
          {list.map((v, i) => {
            return (
              <div className={style.item_set} key={i}>
                <div className={style.user_ava}>
                  <img src={v.avatar} alt="" />
                </div>
                <div className={style.user_info}>
                  <div>微信昵称: {v.user_name}</div>
                  <div>业务员名称: {v.saleman}</div>
                  <div className={style.mobile_min}>
                    客户手机:{' '}
                    {v.is_mobile_show ? (
                      <a href={`tel:${v.mobile}`}>{v.mobile}</a>
                    ) : (
                      <span>
                        {handleDesensitization(v.mobile, v.is_mobile_show)}
                      </span>
                    )}
                    {v.is_mobile_show ? (
                      <img
                        src={OpenY}
                        alt=""
                        onClick={() => handleIsMobile(i)}
                      />
                    ) : (
                      <img
                        src={CloseY}
                        alt=""
                        onClick={() => handleIsMobile(i)}
                      />
                    )}
                  </div>
                  <div>下单时间: {v.create_time_text}</div>
                </div>
              </div>
            )
          })}
        </Pull>
      </div>

      {/* <div className={style.back_my_user}>返回</div> */}

      <DatePicker
        visible={startTime}
        mode="date"
        // value={state.date.value}
        onOk={(value: any) => {
          setStartTimeValue(format(value || new Date(), 'yyyy-MM-dd'))
          setStartTime(false)
          setList([])
          setPage(1)
        }}
        onCancel={() => setStartTime(false)}
      />

      <DatePicker
        visible={endTime}
        mode="date"
        // value={state.date.value}
        onOk={(value: any) => {
          setEndTimeValue(format(value || new Date(), 'yyyy-MM-dd'))
          setEndTime(false)
          setList([])
          setPage(1)
        }}
        onCancel={() => setEndTime(false)}
      />
    </div>
  )
})
