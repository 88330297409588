import React from "react";
import "./info-report-widget.css";
import { FunctionalComponentHeader } from "../../pages/index/components/functional-component-header/functional-component-header";
import { Button } from "zarm";
import { BangBangTangRequest } from "../../utils/request";
import { store } from "../../store/store.root";
import { finalize } from "rxjs/operators";
import { FetchRequest } from "../../utils/fetch";

export class InfoReportWidget extends React.Component<
  any,
  { loading: boolean; value: string, pleText: string; }
> {
  constructor(props: any) {
    super(props);
    this.state = { loading: false, value: "", pleText: "" };
  }

  componentDidMount() {
    this.getTextInfo()
  }

  getTextInfo = async () => {
    let result = await FetchRequest("/wap/data/getTs", {})
    if (result.code === 1) {
      this.setState({
        pleText: result.data
      })
    }
  }

  submit = () => {
    this.setState({ loading: true }, () => {
      BangBangTangRequest({
        url: "/wap/data/submitCustomerSuggestions",
        data: {
          token: store.userStore.token,
          feedback_describe: this.state.value,
        },
        method: "POST",
      })
        .pipe(finalize(() => this.setState({ loading: false })))
        .subscribe(() => {
          alert("已经收到，感谢您的反馈");
          this.setState({ value: "" });
          store.routerStore.history.replace("/build");
        });
    });
  };

  render() {
    return (
      <div className="info-report-widget">
        <FunctionalComponentHeader title={"投诉建议"} />
        <textarea
          placeholder={this.state.pleText}
          className="textarea"
          style={{ fontSize: 12, minHeight: 100 }}
          value={this.state.value}
          onChange={(value) => this.setState({ value: value.target.value })}
        />
        <div className="button-row">
          <Button
            className="button"
            onClick={() => this.setState({ value: "" })}
            size="sm"
          >
            取消
          </Button>
          <Button
            theme="danger"
            className="button"
            onClick={this.submit}
            loading={this.state.loading}
            size="sm"
            disabled={!this.state.value || this.state.value.length > 180}
          >
            发送
          </Button>
        </div>
      </div>
    );
  }
}
