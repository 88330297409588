import React from "react";
import { observer } from "mobx-react";
import { store } from "../../../store/store.root";
import { Panel, Picker, Tabs } from "zarm";

@observer
export class EmployeePreorderListScheduleType extends React.Component<
  any,
  any
> {
  render() {
    const employeePreorderListStore = store.employeePreorderListStore;
    return (
      <>
        <Tabs
          className={"employee-preorder-list-header-tab"}
          scrollable
          value={store.employeePreorderListStore.schedule_type_index}
          onChange={(value) => {
            store.employeePreorderListStore.change_schedule_type_value_by_index(
              value === undefined ? 0 : value
            );
          }}
        >
          <Panel title={"全部"} key={"all"} />
          {employeePreorderListStore.schedule_type?.map((value) => {
            return (
              <Panel
                title={
                  value.name +
                  " " +
                  (store.employeePreorderListStore.schedule_type_num.get(
                    value.id
                  ) || "0")
                }
                key={value.id}
              />
            );
          })}
        </Tabs>
        <Tabs
          className={"employee-preorder-list-header-tab"}
          value={store.employeePreorderListStore.schedule_status_index}
          onChange={(value) => {
            store.employeePreorderListStore.change_schedule_status_value_by_index(
              value || 0
            );
          }}
        >
          {store.employeePreorderListStore.schedule_status.map((value) => {
            return (
              <Panel
                title={
                  value.label +
                  " " +
                  (store.employeePreorderListStore.schedule_status_num.get(
                    value.value
                  ) || "")
                }
                key={value.value}
              />
            );
          })}
        </Tabs>
      </>
    );
  }
}
