import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { Carousel, Button, Toast, NoticeBar, Popup, Modal } from 'zarm'
import { FetchRequest } from '../../utils/fetch'
import style from './index.module.scss'
import { Link, withRouter } from 'react-router-dom'
import { store } from '../../store/store.root'
import { getParamValue } from '../../utils/url'
import GraduationMenuAlbum from './graduationMenuAlbum'
import DHImages from '../../assets/dh.png'
import { handleOnChangShare } from '../../utils'
import { observer } from 'mobx-react'

// const ITEMS = [
//   "https://static.zhongan.com/website/health/zarm/images/banners/1.png",
//   "https://static.zhongan.com/website/health/zarm/images/banners/2.png",
//   "https://static.zhongan.com/website/health/zarm/images/banners/3.png",
// ];

export const contentRender = (ITEMS: { img: string }[]) => {
  return ITEMS.map((item, i) => {
    return (
      <div className="carousel__item__pic" key={+i}>
        <img src={item.img} alt="" draggable={false} />
      </div>
    )
  })
}

export default observer(
  withRouter(
    (props: {
      match: {
        params: {
          id: string
        }
      }
    }) => {
      const [graduation, setGraduation] = useState<any>({
        is_select: 0,
      })

      const [packageStatus, setPackageStatus] = useState(false)
      const [meal, setMeal] = useState<
        {
          c_price?: number
          detail?: null | string[]
          info?: string
          pinfo?: string
          price?: string
          meal?: string
        }[]
      >([])

      const [isPageLoading, setIsPageLoading] = useState(false)

      useEffect(() => {
        document.title = '毕业季制作相册'
        if (store.userStore.token && !isPageLoading) {
          setIsPageLoading(true)
          GetInit()
        }
      }, [store.userStore.token])

      async function GetInit() {
        await handleGetBabySelect()
        await GetCode()
        await GetPageGraduation()
        await handleOnChangShare({
          title: '毕业季制作相册',
          desc: '您的毕业季照片已上传，请尽快挑选您喜爱的照片制作相册',
        })
      }

      const [babyList, setBabyList] = useState([])
      const [chooseTheBaby, setChooseTheBaby] = useState(false)

      async function handleGetBabySelect() {
        let res = await FetchRequest('/wap/graduation/babySelect', {
          token: localStorage.getItem('token'),
          activity_id: props.match.params.id,
        })
        if (typeof res.data.erollId !== 'number') {
          setBabyList(res.data.erollId || [])
        }
        if (res.code === 1 && res.data.is_go === 1) {
          // setChooseTheBaby(true)
          if (!localStorage.getItem('eroll_id_gra')) {
            setChooseTheBaby(true)
          }
        } else {
          localStorage.setItem('eroll_id_gra', res.data.erollId)
          // location.reload()
        }
      }

      async function GetCode() {
        let res = await FetchRequest('/wap/graduation/filmSuccess', {
          token: localStorage.getItem('token'),
        })
        if (res.code === 1) {
          GetSubscribe(res.data)
        }
      }

      async function GetSubscribe(IamgesBase64: string) {
        let res = await FetchRequest('/wap/selectioneroll/is_subscribe', {
          token: store.userStore.token,
        })
        if (res.data.is_subscribe === 0) {
          const modal = Modal.alert({
            title: '提示',
            content: (
              <div>
                <div>
                  <img src={IamgesBase64} style={{ width: '60%' }} alt="" />
                </div>
                <div style={{ color: 'red', textAlign: 'center' }}>
                  请先关注公众号后再制作相册
                  <br />
                  否则无水印底片无法下载。
                </div>
              </div>
            ),
            cancelText: '长按二维码关注公众号',
            onCancel: () => {
              return false
            },
          })
        }
      }

      async function GetPageGraduation() {
        let result = await FetchRequest('/wap/graduation/graduationIndex/', {
          token: localStorage.getItem('token'),
          activity_id: props.match.params.id,
          eroll_id: localStorage.getItem('eroll_id_gra'),
        })
        if (result.code === 1) {
          setGraduation(result.data)
          setMeal(result.data.meal)
          if (result.data.is_select === 0) {
            // setTimeout(() => {
            //   location.href = '/build'
            // }, 2000)
          }
          if (result.data.watermark_settings) {
            localStorage.setItem(
              'watermark_settings',
              result.data.watermark_settings
            )
          }
          if (result.data.pellucidity) {
            localStorage.setItem(
              'watermark_pellucidity',
              result.data.pellucidity
            )
          }
        } else {
          Toast.show(result.msg)
        }
      }

      async function upgradePackage(type: string, price: number) {
        let result = await FetchRequest('/wap/graduation/mealPay', {
          token: localStorage.getItem('token'),
          // activity_id: props.match.params.id,
          eroll_id: graduation.eroll_id,
          meal: type,
          price,
        })
        if (result.code === 1 && result.data.data !== 'success') {
          let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
            currenturl: window.location.href,
          })
          const { wx }: any = window
          const {
            appId,
            nonceStr,
            signature,
            timestamp,
            jsApiList,
          } = SDKParams.data
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId, // 必填，公众号的唯一标识
            timestamp, // 必填，生成签名的时间戳
            nonceStr, // 必填，生成签名的随机串
            signature, // 必填，签名
            jsApiList, // 必填，需要使用的JS接口列表
          })

          wx.ready(function () {
            const {
              appId,
              nonceStr,
              order_id,
              paySign,
              signType,
              timestamp,
            } = result.data.data
            wx.chooseWXPay({
              timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
              package: result.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: paySign, // 支付签名
              success: function (res: any) {
                // 支付成功后的回调函数
                location.reload()
              },
            })
          })

          wx.error(function (res: any) {
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            Toast.show('支付失败')
          })
        } else {
          Toast.show(result.msg)
        }
      }

      if (graduation.is_select === 0) {
        // location.href = '/build'
        return (
          <HandleSelectBabys
            chooseTheBaby={chooseTheBaby}
            babyList={babyList}
            activity_id={props.match.params.id}
          >
            <div
              className={style.is_select_null}
              onClick={() => {
                if (graduation.link) {
                  ///// 没报名
                  store.routerStore.push(graduation.link)
                } else {
                  ///// 没传照片
                }
              }}
            >
              <p>{graduation.msg}</p>
              {graduation.link && (
                <div className={style.tipes}>点击跳转报名</div>
              )}
            </div>
            {babyList.length > 0 && (
              <div
                className={style.customerServiceTelephone}
                style={{
                  top: '18%',
                  fontSize: 12,
                  padding: 10,
                  color: '#fff',
                }}
                onClick={() => setChooseTheBaby(true)}
              >
                切换
                <br />
                宝宝
              </div>
            )}
          </HandleSelectBabys>
        )
      }

      if (packageStatus) {
        return (
          <Popup
            visible={packageStatus}
            direction="center"
            width={'80%'}
            onMaskClick={() => setPackageStatus(false)}
            afterOpen={() => console.log('打开')}
            afterClose={() => console.log('关闭')}
            destroy={true}
            // mountContainer={() => document.body}
          >
            <div className={style.upgradePackageContent}>
              <div className={style.thePackageList}>
                {meal.map((v, i) => {
                  return (
                    <>
                      <div className={style.packageContent} key={i}>
                        <img src={v.info} alt="" />
                        <div className={style.thatList}>
                          {v.detail &&
                            (v.detail || []).map((v, index) => {
                              if (v) {
                                return <div key={index}>{v}</div>
                              }
                            })}
                        </div>
                        <Button
                          shape="round"
                          theme="danger"
                          size="xs"
                          block
                          disabled={v.pinfo === '您已购买此套餐'}
                          className={style.paymentButton}
                          onClick={() =>
                            upgradePackage(v.meal!, Number(v.price))
                          }
                        >
                          {v.price}套餐 / {v.pinfo}
                        </Button>
                      </div>
                    </>
                  )
                })}
              </div>
              <div className={style.no_design}>
                <Button
                  theme="primary"
                  block
                  shape="round"
                  onClick={() => setPackageStatus(false)}
                >
                  返回
                </Button>
                <div style={{ width: 20 }}></div>
                <Button
                  theme="primary"
                  block
                  shape="round"
                  onClick={() => {
                    store.routerStore.push(
                      `/build/selection/${props.match.params.id}?eroll_id=${graduation.eroll_id}`
                    )
                  }}
                >
                  进入相册
                </Button>
              </div>
            </div>
          </Popup>
        )
      }

      return (
        <HandleSelectBabys
          chooseTheBaby={chooseTheBaby}
          babyList={babyList}
          activity_id={props.match.params.id}
        >
          <div>
            {graduation.views && (
              <NoticeBar className={style.NoticeBar}>
                {graduation.views?.map(
                  (v: {
                    id: React.Key | null | undefined
                    avatar: string | undefined
                    detail:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined
                  }) => {
                    return (
                      <span key={v.id}>
                        <img
                          src={v.avatar}
                          alt=""
                          style={{
                            width: 25,
                            borderRadius: '50%',
                            margin: '0 10px',
                          }}
                        />
                        <span style={{ fontSize: 17 }}>{v.detail}</span>
                      </span>
                    )
                  }
                )}
              </NoticeBar>
            )}

            <Carousel
              loop
              direction="left"
              onChange={(index) => {
                console.log(`onChange: ${index}`)
              }}
              // style={{ marginTop: 50 }}
            >
              {contentRender(graduation.rotation)}
            </Carousel>

            <div>
              <GraduationMenuAlbum
                eroll_id={graduation.eroll_id}
                isAll
                watermark_settings={graduation.watermark_settings}
                watermark_pellucidity={graduation.pellucidity}
              />
            </div>

            <a
              href={`tel:${graduation.consultation_telephone}`}
              className={style.customerServiceTelephone}
            >
              <img src={DHImages} alt="" />
            </a>

            <div className={style.designAlbum}>
              <Button
                theme="primary"
                block
                // className={style.designAlbum}
                shape="round"
                onClick={() => {
                  if (graduation.is_submit === 1) {
                    store.routerStore.push(
                      `/build/selection/${props.match.params.id}?eroll_id=${graduation.eroll_id}`
                    )
                  } else {
                    if (graduation.is_meal === 0) {
                      store.routerStore.push(
                        `/build/selection/${props.match.params.id}?eroll_id=${graduation.eroll_id}`
                      )
                    } else {
                      setPackageStatus(true)
                    }
                  }
                }}
              >
                {graduation.is_submit === 0 ? '设计相册' : '查看相册'}
                <span
                  style={{
                    fontSize: 12,
                  }}
                >
                  (当前宝宝：{graduation.baby_name})
                </span>
              </Button>
            </div>
            {babyList.length > 0 && (
              <div
                className={style.customerServiceTelephone}
                style={{
                  top: '18%',
                  fontSize: 12,
                  padding: 10,
                  color: '#fff',
                }}
                onClick={() => setChooseTheBaby(true)}
              >
                切换
                <br />
                宝宝
              </div>
            )}
            {/* {graduation.is_meal !== 0 && graduation.is_submit !== 1 && (
            <div
              className={style.designAlbum_btb}
              onClick={() => setPackageStatus(true)}
            >
              升级
              <br />
              套餐
            </div>
          )} */}

            {/* 升级套餐 */}
            <Popup
              visible={false || packageStatus}
              direction="center"
              width={'80%'}
              onMaskClick={() => setPackageStatus(false)}
              afterOpen={() => console.log('打开')}
              afterClose={() => console.log('关闭')}
              destroy={true}
              // mountContainer={() => document.body}
            >
              <div className={style.upgradePackageContent}>
                <div className={style.thePackageList}>
                  {meal.map((v, i) => {
                    return (
                      <>
                        <div className={style.packageContent} key={i}>
                          {/* <span className={style.packageName}>{v.price}套餐</span> */}
                          <img src={v.info} alt="" />
                          <div className={style.thatList}>
                            {v.detail &&
                              (v.detail || []).map((v, index) => {
                                if (v) {
                                  return <div key={index}>{v}</div>
                                }
                              })}
                          </div>
                          <Button
                            shape="round"
                            theme="danger"
                            size="xs"
                            block
                            disabled={v.pinfo === '您已购买此套餐'}
                            className={style.paymentButton}
                            onClick={() =>
                              upgradePackage(v.meal!, Number(v.price))
                            }
                          >
                            {v.price}套餐 / {v.pinfo}
                          </Button>
                        </div>
                        {/* {v.pinfo === '您已购买此套餐' && (
                        <div className={style.text_tipes}>
                          您还可以升级以下套餐
                        </div>
                      )} */}
                      </>
                    )
                  })}
                  {meal.map((v, i) => {
                    return (
                      <>
                        <div className={style.packageContent} key={i}>
                          {/* <span className={style.packageName}>{v.price}套餐</span> */}
                          <img src={v.info} alt="" />
                          <Button
                            shape="round"
                            theme="danger"
                            size="xs"
                            block
                            disabled={v.pinfo === '您已购买此套餐'}
                            className={style.paymentButton}
                            onClick={() =>
                              upgradePackage(v.meal!, Number(v.price))
                            }
                          >
                            {v.price}套餐 / {v.pinfo}
                          </Button>
                        </div>
                        {/* {v.pinfo === '您已购买此套餐' && (
                        <div className={style.text_tipes}>
                          您还可以升级以下套餐
                        </div>
                      )} */}
                      </>
                    )
                  })}
                </div>
                <div className={style.no_design}>
                  <Button
                    theme="primary"
                    block
                    shape="round"
                    onClick={() => setPackageStatus(false)}
                  >
                    返回
                  </Button>
                  <div style={{ width: 20 }}></div>
                  <Button
                    theme="primary"
                    block
                    shape="round"
                    onClick={() => {
                      store.routerStore.push(
                        `/build/selection/${props.match.params.id}?eroll_id=${graduation.eroll_id}`
                      )
                    }}
                  >
                    设计相册
                  </Button>
                </div>
              </div>
            </Popup>
          </div>
        </HandleSelectBabys>
      )
    }
  )
)

function HandleSelectBabys(props: {
  chooseTheBaby: boolean
  babyList: any[]
  activity_id: number | string
  children: any
}) {
  return (
    <>
      {props.children}
      <Modal visible={props.chooseTheBaby} title="请选择宝宝">
        <div className={style.select_baby}>
          {props.babyList.map(
            (v: { id: number; name: string; orderNum: number }) => {
              return (
                <div style={{ padding: '5px 0' }} key={v.id}>
                  <Button
                    theme="primary"
                    block
                    disabled={v.orderNum > 0}
                    onClick={() => {
                      localStorage.setItem('eroll_id_gra', v.id + '')
                      location.reload()
                    }}
                  >
                    {v.name}
                  </Button>
                </div>
              )
            }
          )}
        </div>
      </Modal>
    </>
  )
}
