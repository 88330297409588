import React, { useState } from "react";
import { Cell, List } from "react-vant";
import style from "./style.module.scss";

export default function SelectionActivitiesTest() {
  const [list, setList] = useState<any[]>([]);
  const [finished, setFinished] = useState(false);

  const onLoad = async () => {
    // 异步更新数据
    const data = await getData();
    setList((v) => [...v, ...data]);
    if (list.length >= 30) {
      setFinished(true);
    }
  };

  async function getData(throwError?: undefined) {
    return new Promise<number[]>((resolve, reject) => {
      setTimeout(() => {
        if (throwError) {
          reject(new Error("error"));
        }
        resolve(Array.from({ length: 10 }, (_, i) => i));
      }, 1000);
    });
  }

  return (
    <div>
      <List finished={finished} onLoad={onLoad}>
        <div className={style.cntent_list}>
          {list.length
            ? list.map((item, index) => {
                return (
                  <div key={index} className={style.item_images}>
                    <img
                      src="https://test-1307618371.cos.ap-nanjing.myqcloud.com/filmselectionUnZip/film-test/3/王文荟13271578282安琪马悦/AYG_7857-water.JPG"
                      alt=""
                    />
                  </div>
                );
              })
            : null}
        </div>
      </List>
    </div>
  );
}
