import React from "react";
import { Cell, DatePicker, Panel, SearchBar, Tabs } from "zarm";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";
import { format } from "date-fns";

@observer
export class EmployeeQueryPreorderClotheListHeaderComponent extends React.Component<
  any,
  any
> {
  divRef: HTMLDivElement | null = null;
  componentDidMount() {
    if (this.divRef) {
      store.employeePreorderClotheListStore.setHeaderHeight(
        this.divRef.clientHeight
      );
    }
  }

  render() {
    return (
      <div
        ref={(ref) => (this.divRef = ref)}
        style={{ position: "fixed", top: 0, width: "100%", zIndex: 1 }}
      >
        <SearchBar
          showCancel={false}
          placeholder={"使用手机号搜索"}
          value={store.employeePreorderClotheListStore.search_text}
          onCancel={() =>
            store.employeePreorderClotheListStore.setSearchText("")
          }
          onSubmit={() => {
            store.employeePreorderClotheListStore.resetData();
            store.employeePreorderClotheListStore.load_more();
          }}
          onClear={() =>
            store.employeePreorderClotheListStore.setSearchText("")
          }
          onChange={(value) =>
            store.employeePreorderClotheListStore.setSearchText(value || "")
          }
        />
        <Cell
          title={"选择拍摄日期"}
          onClick={() =>
            store.employeePreorderClotheListStore.setDatePickerVisible(true)
          }
          description={
            store.employeePreorderClotheListStore.search_time || "点我选择"
          }
        />
        {store.employeePreorderClotheListStore.schedule_type &&
        store.employeePreorderClotheListStore.schedule_type.length > 0 ? (
          <Tabs
            value={
              store.employeePreorderClotheListStore
                .active_schedule_type_index || 0
            }
            className="bangbangtang-tabs"
            onChange={(index) =>
              store.employeePreorderClotheListStore.change_active_schedule_type(
                index
              )
            }
            scrollable
          >
            {store.employeePreorderClotheListStore.schedule_type?.map(
              (value) => {
                return (
                  <Panel title={value.name} className="panel" key={value.id} />
                );
              }
            )}
          </Tabs>
        ) : (
          <Tabs value={0} className="bangbangtang-tabs" swipeable={true}>
            <Panel title={"载入中"} className="panel" />
          </Tabs>
        )}
        <DatePicker
          mode={"date"}
          defaultValue={store.employeePreorderClotheListStore.search_time}
          onOk={(value: any) => {
            store.employeePreorderClotheListStore.setSearchTime(
              format(value || new Date(), "yyyy-MM-dd")
            );
            store.employeePreorderClotheListStore.resetData();
            store.employeePreorderClotheListStore.load_more();
            store.employeePreorderClotheListStore.setDatePickerVisible(false);
          }}
          onCancel={() => {
            store.employeePreorderClotheListStore.setDatePickerVisible(false);
          }}
          visible={store.employeePreorderClotheListStore.date_picker_visible}
        />
      </div>
    );
  }
}
