import React from "react";
import { ValidWebsiteRuleListString } from "../../store/website-rule/website-rule.store";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";
interface IProps {
  name: ValidWebsiteRuleListString;
}

@observer
export class RuleListRequired extends React.Component<IProps, any> {
  render() {
    if (store.websiteRuleStore.has_rule(this.props.name)) {
      return this.props.children;
    } else {
      return null;
    }
  }
}
