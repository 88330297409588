import { autorun, computed, makeAutoObservable, toJS } from 'mobx'
import {
  DateParsedInterface,
  PreorderScheduleSTypeInterface,
  ScheduleAlreadyPreorderInterface,
  ScheduleInquiryInterface,
  StoreInterface,
} from './preorder-schedule'

interface FormValueType<T = any, V = string | number> {
  choices: T[] | null
  loading: boolean
  picker_visible: boolean
  value: V | null
}

export class PreorderScheduleStore {
  // 过往档期相关
  display_more_already_preorder_card = false
  schedule_already_preorder: FormValueType<ScheduleAlreadyPreorderInterface> & {
    cancel_modal_display: boolean
    cancel_pending: boolean
  } = {
    choices: null,
    loading: false,
    picker_visible: false,
    value: null,
    cancel_modal_display: false,
    cancel_pending: false,
  }
  @computed
  get scheduleAlreadyPreorderCurrentDisplayInModal():
    | null
    | ScheduleAlreadyPreorderInterface
    | undefined {
    return this.schedule_already_preorder.choices?.find(
      (value) => value.id === this.schedule_already_preorder.value
    )
  }
  // 选择门店相关
  public store: FormValueType<StoreInterface> = {
    choices: null,
    loading: false,
    picker_visible: false,
    value: null,
  }
  // 选档期类型相关
  public schedule_type: FormValueType<PreorderScheduleSTypeInterface> = {
    choices: null,
    loading: false,
    picker_visible: false,
    value: null,
  }
  // 套餐选择
  public schedule_package: {
    picker_visible: boolean
    value: string
  } = {
    picker_visible: false,
    value: '',
  }

  public package_age_tag: {
    modal_visible: boolean
    value: string
  } = {
    modal_visible: false,
    value: '',
  }

  public form_type: {
    modal_visible: boolean
    value: number[]
    list: { id: number; name: string }[]
  } = {
    modal_visible: false,
    value: [],
    list: [],
  }

  // 选择门店按钮是否禁用
  @computed get selectedStoreCellDisabled() {
    return (
      this.store.loading ||
      this.schedule_inquiry.loading ||
      this.store.choices === null
    )
  }
  // 选择门店按钮当前展示内容
  @computed get selectedStoreCellDisplayValue() {
    if (this.store.loading) {
      return '载入中'
    } else {
      if (this.store.choices === null) {
        return ''
      } else {
        return this.store.value === null
          ? '点我选择'
          : this.store.choices?.find((value) => value.id === this.store.value)
              ?.name
      }
    }
  }
  customer_info_modal_visible: boolean = false
  change_customer_info_modal_visible(value: boolean) {
    this.customer_info_modal_visible = value
  }
  username: string = ''
  changeUsername(value: string) {
    this.username = value
  }
  birthday: string | null = null
  changeBirthday(value: string) {
    this.birthday = value
  }
  gender: 'male' | 'female' | undefined = undefined
  changeGender(value: 'male' | 'female' | undefined) {
    this.gender = value
  }
  birthDayDatePickVisible: boolean = false
  changeBirthDayDatePickVisible(v: boolean) {
    this.birthDayDatePickVisible = v
  }

  // zarm 使用的选择门店Picker的值
  @computed get selectedStoreCellDataSource(): Array<{
    value: string
    label: string
  }> {
    if (this.store.choices === null) {
      return []
    } else {
      return this.store.choices.map((value) => ({
        value: value.id as any,
        label: value.name,
      }))
    }
  }

  // 选择排挡类型按钮是否禁用
  @computed get selectedScheduleTypeCellIsDisabled() {
    return (
      this.store.loading ||
      this.schedule_inquiry.loading ||
      this.schedule_type.loading ||
      this.schedule_type.choices === null
    )
  }
  // 正在提交中
  public submit_pending: boolean = false

  @computed get pageLoading(): boolean {
    return this.schedule_already_preorder.loading || this.submit_pending
  }

  // zarm 使用的选择档期类型Picker的值
  @computed get selectedScheduleTypeCellDataSource(): Array<{
    value: string
    label: string
  }> {
    if (this.schedule_type.choices === null) {
      return []
    } else {
      return this.schedule_type.choices.map((value) => ({
        value: value.id,
        label: value.name,
      }))
    }
  }

  // 选择档期类型展示的值
  @computed get selectedScheduleTypeCellDisplayValue() {
    if (this.schedule_type.loading) {
      return '载入中'
    }
    if (this.store.value === null) {
      return ''
    }
    return this.schedule_type.value === null
      ? '点我选择'
      : this.schedule_type.choices?.find(
          (value) => value.id === this.schedule_type.value
        )?.name
  }
  // 选档期日期相关
  public available_date: FormValueType<DateParsedInterface, Date> = {
    choices: null,
    loading: false,
    picker_visible: false,
    value: null,
  }
  // 选择日期是否禁用
  @computed get selectedDateCellIsDisabled(): boolean {
    return (
      this.store.loading ||
      this.schedule_inquiry.loading ||
      this.schedule_type.loading ||
      this.available_date.loading
    )
  }
  public schedule_inquiry: FormValueType<ScheduleInquiryInterface, number> & {
    display_confirm_modal: boolean
  } = {
    picker_visible: false,
    loading: false,
    choices: null,
    value: null,
    display_confirm_modal: false,
  }
  set_schedule_inquiry_selected(value: number | null) {
    this.schedule_inquiry.value = value
  }
  @computed get selected_schedule_inquiry():
    | ScheduleInquiryInterface
    | undefined {
    return this.schedule_inquiry.choices?.find(
      (v) => v.id === this.schedule_inquiry.value
    )
  }
  @computed get calendarDataSource(): { start: Date; end: Date } | void {
    if (this.available_date.choices) {
      const start = new Date(
        this.available_date.choices[0].year,
        this.available_date.choices[0].month,
        this.available_date.choices[0].day
      )
      const end = new Date(
        this.available_date.choices[
          this.available_date.choices.length - 1
        ].year,
        this.available_date.choices[
          this.available_date.choices.length - 1
        ].month,
        this.available_date.choices[this.available_date.choices.length - 1].day
      )
      return { start, end }
    }
  }
  public current_selected_month: number = new Date().getMonth()
  public current_selected_year: number = new Date().getFullYear()
  constructor() {
    makeAutoObservable(this)
    this.initial()
    autorun(() => {
      if (this.available_date.picker_visible) {
        this.display_more_already_preorder_card = false
      }
    })
  }
  // 更改日期页,选择上一页是否禁用
  @computed get changeCurrentMonthToPrevDisabled(): boolean {
    console.log(this.current_selected_year, this.current_selected_month)
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    if (this.current_selected_year < currentYear) {
      return true
    } else if (currentYear === this.current_selected_year) {
      return currentMonth >= this.current_selected_month
    } else {
      return false
    }
  }
  public initial() {
    this.store = {
      loading: false,
      picker_visible: false,
      choices: null,
      value: null,
    }
    this.schedule_type = {
      loading: false,
      picker_visible: false,
      choices: null,
      value: null,
    }
    this.schedule_inquiry = {
      loading: false,
      picker_visible: false,
      choices: null,
      value: null,
      display_confirm_modal: false,
    }
    this.available_date = {
      loading: false,
      picker_visible: false,
      choices: null,
      value: null,
    }
    this.submit_pending = false
  }
}
