import React, { useState, useRef } from 'react';
import { useMount } from '@umijs/hooks';
import CarouselCom from './Carousel';
import Img from './img';
import style from './index.module.scss'
import Menu from './Menu';
import { BangBangTangRequest } from '../../utils/request';
import { getParamValue } from '../../utils/url';
import { Icon, Toast } from 'zarm';


export default (props: any): JSX.Element => {

    const pchildref = useRef<any>();

    const [Exhibition, setExhibition] = useState<boolean>(false) // 控制轮播弹窗
    const [dataSource, setdataSource] = useState<any>({
        dataSource_left: [],
        dataSource_right: [],
    }) // 存放瀑布流数据 服装列表
    const [pageParam, setpageParam] = useState(1) // 请求列表参数
    const [bookingID, setbookingID] = useState<string | null>(getParamValue("booking_id") || null) // 存放预约单号
    const [maxClothing, setMaxClothing] = useState<string | null>(getParamValue("max") || null) // 存放最大服装
    const [oosURL, setoosURL] = useState("") // 存放oos图片前缀
    const [imgDrawings, setImgDrawings] = useState<string[]>([]) // 存放用户点击的附图

    // 判断第一次请求的服装是否存在
    const [oneBookingListState, setOneBookingListState] = useState(true);

    // 初始化数据
    useMount(
        (): void => {
            document.title = "服装预选"
            // 判断路由是否存在预约单 ID
            if (bookingID) {
                // 获取阿里云oos
                // getAliYunOOS()
                // 有值
                getScheduleClothingList()
                window.scrollTo(0, 0);
            }
        }
    );

    //滚动条在Y轴上的滚动距离
    function getScrollTop() {
        var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
        if (document.body) {
            bodyScrollTop = document.body.scrollTop;
        }
        if (document.documentElement) {
            documentScrollTop = document.documentElement.scrollTop;
        }
        scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
        return scrollTop;
    }

    //文档的总高度
    function getScrollHeight() {
        var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
        if (document.body) {
            bodyScrollHeight = document.body.scrollHeight;
        }
        if (document.documentElement) {
            documentScrollHeight = document.documentElement.scrollHeight;
        }
        scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
        return scrollHeight;
    }

    //浏览器视口的高度
    function getWindowHeight() {
        var windowHeight = 0;
        if (document.compatMode == "CSS1Compat") {
            windowHeight = document.documentElement.clientHeight;
        } else {
            windowHeight = document.body.clientHeight;
        }
        return windowHeight;
    }

    window.onscroll = function () {
        // if (getScrollTop() + getWindowHeight() == getScrollHeight()) {
        //     console.log("已经到底部")
        //     getScheduleClothingList()
        // }
        if (getScrollTop() + getWindowHeight() > getScrollHeight() - 1000) {
            if (oneBookingListState) {
                getScheduleClothingList()    
            }
        }
    };
    console.log(dataSource)
    if (!bookingID) {
        return <div className={style.Selection}>暂无预约服装</div>
    }

    /**
     * 获取阿里云配置
     */
    function getAliYunOOS() {
        BangBangTangRequest({
            url: "/wap/data/getAliOssConfig",
            method: "POST",
            data: {
                token: localStorage.getItem("token")
            }
        }).subscribe(result => {
            setoosURL(result.cdnurl)
        });
    }

    /**
     * 给menu调用方法
     * @param prama 
     */
    function selectImgList(prama: string) {
        setdataSource({
            dataSource_left: [],
            dataSource_right: []
        })
        getScheduleClothingList(prama)
    }

    /**
     * 获取服装列表
     */
    function getScheduleClothingList(prama?: string) {
        console.log(`请求...`)
        // if (!oneBookingListState) return
        if (prama) {
            setpageParam(1)
        } else {
            setpageParam(pageParam + 1)
        }
        BangBangTangRequest({
            url: "/wap/schedule/getStandardByBooking",
            method: "POST",
            data: prama ? {
                token: localStorage.getItem("token"),
                booking_id: bookingID,
                page: pageParam,
                tag: prama
            } : Object.assign({
                token: localStorage.getItem("token"),
                booking_id: bookingID,
                page: pageParam
            })
        }).subscribe(result => {
            if (result.length === 0) {
                setOneBookingListState(false)
                if (prama) {
                    Toast.show('暂无更多');
                }
            } else {

                let newLeft = []

                for (let i = 0; i < dataSource.dataSource_left.length; i++) {
                    const element = dataSource.dataSource_left[i];
                    newLeft.push(element)
                }

                let newRight = []

                for (let i = 0; i < dataSource.dataSource_right.length; i++) {
                    const element = dataSource.dataSource_right[i];
                    newRight.push(element)
                }

                for (let i = 0; i < result.length; i++) {
                    const element = result[i];
                    if (i % 2 === 0) {
                        newLeft.push(element)
                    } else {
                        newRight.push(element)
                    }
                }

                setdataSource({
                    dataSource_left: newLeft,
                    dataSource_right: newRight,
                })
            }

        });
    }

    /**
     * 预选服装提交选中的服装
     * @param standard_id  标准片id
     */
    function sendUserSubmitStandard(standard_id: number) {
        BangBangTangRequest({
            url: "/wap/schedule/userSubmitStandard",
            method: "POST",
            data: {
                token: localStorage.getItem("token"),
                booking_id: bookingID,
                standard_id
            }
        }).subscribe(result => {
            if (!result) {
                Toast.show('添加成功');
                pchildref.current._childFn()
            }
        });
    }

    /**
     * 分割数组
     * @param array
     * @param subGroupLength
     */
    function group<T>(array: Array<T>, subGroupLength: number): Array<T[]> {
        let index = 0;
        let newArray = [];
        while (index < array.length) {
            newArray.push(array.slice(index, index += subGroupLength));
        }
        return newArray;
    }

    /**
     * 点击获取附图详情
     * @param id @description 图片id
     * @param position @description 瀑布流位置 
     */
    function handleClickImgItem(id: number, position: 'left' | 'right') {
        setExhibition(true)
        let data = position === 'left' ? dataSource.dataSource_left : dataSource.dataSource_right

        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (item.id === id) {
                let newArray = []
                // newArray.push(item.clothingimage)
                for (let j = 0; j < String(item.clothingimages).split(',').length; j++) {
                    const urlJoin = String(item.clothingimages).split(',')[j];
                    newArray.push(urlJoin)
                }
                setImgDrawings(newArray)
                break
            }
        }
    }

    /**
     * 预选服装
     * @param id 
     */
    function handleClickItemSelection(id: number) {
        sendUserSubmitStandard(id)
    }

    return (
        <div className="list-view">
            {/* 轮播图 */}
            {
                Exhibition && (
                    <div onClick={() => setExhibition(false)}>
                        <CarouselCom
                            details={imgDrawings}
                        />
                    </div>
                )
            }
            {/* 搜索弹窗 */}
            <Menu
                ref={pchildref}
                selectImgList={selectImgList}
                bookingID={bookingID}
                oosURL={oosURL}
                maxClothing={maxClothing}
            />
            {/* 瀑布流 */}
            <div className={style.content}>
                <div>
                    {
                        dataSource.dataSource_left.map((v: { id: number; clothingimages: string; }): any => {
                            return (
                                <div key={v.id} style={{
                                    position: 'relative'
                                }}>
                                    <Img url={v.clothingimages} onClick={() => handleClickImgItem(v.id, 'left')} />
                                    <Icon
                                        type="add-round"
                                        theme="primary"
                                        className={style.img_text}
                                        onClick={() => handleClickItemSelection(v.id)}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        dataSource.dataSource_right.map((v: { id: number; clothingimages: string; }) => {
                            return (
                                <div key={v.id} style={{
                                    position: 'relative'
                                }}>
                                    <Img url={v.clothingimages} onClick={() => handleClickImgItem(v.id, 'right')} />
                                    <Icon
                                        type="add-round"
                                        theme="primary"
                                        className={style.img_text}
                                        onClick={() => handleClickItemSelection(v.id)}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div>
                <div id="bot_text" style={{
                    textAlign: 'center'
                }} onClick={() => getScheduleClothingList()}>已经到底了~</div>
            </div>
        </div>

    );
};