import React, { useEffect, useState } from "react"
import { Tabs, Button, Toast } from 'zarm';
import { FetchRequest } from "../../utils/fetch";
import style from "./index.module.scss"
import { withRouter } from "react-router-dom"
import { getParamValue } from "../../utils/url";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";

const { Panel } = Tabs;

type getCouponDetailType = {
    act_id?: string;
    card_image?: string;
    card_name?: string;
    intro?: string[];
    remark?: string;
    shop_image?: string[];
    validity?: string;
    writeOffMethod?: string;
    writeoff_count?: string;
}

export default observer(withRouter((props: {
    match: {
        params: {
            cardId: string;
        }
    }
}) => {

    const [value, setValue] = useState(0);

    useEffect(() => {
        document.title = "卡券详情"
    }, [])

    useEffect(() => {
        if (store.userStore.token) {
            getCouponDetail()
        }
    }, [store.userStore.token])

    const [Detaile, setDetaile] = useState<getCouponDetailType>({})

    async function getCouponDetail() {
        let res: {
            code: number;
            data: getCouponDetailType;
            msg: null | string;
        } = await FetchRequest("/wap/evaluate/getCouponDetail", {
            token: localStorage.getItem("token") || store.userStore.token,
            activity_id: props.match.params.cardId,
        })
        if (res.code === 1) {
            setDetaile(res.data)
        } else {
            Toast.show({
                content: res.msg,
                stayTime: 5000,
            });
        }
    }

    if (true) {
        return (
            <div className={style.content}>
                <CardDetails data={Detaile} />
            </div>
        )
    }

    return (
        <div>
            <Tabs value={value} onChange={(e) => setValue(Number(e))}>
                <Panel title="卡券详情">
                    <div className={style.content}>
                        {/* <CardDetails /> */}
                    </div>
                </Panel>
                <Panel title="商家详情">
                    <div className={style.content}>
                        <MerchantDetails />
                    </div>
                </Panel>
                <Panel title="商家其他卡券">
                    <OtherCard />
                </Panel>
            </Tabs>
        </div>
    )
}))

// 卡券详情
function CardDetails(props: {
    data: getCouponDetailType
}) {

    async function handleClickSubForm() {
        let res = await FetchRequest("/wap/evaluate/drawCoupon", {
            token: localStorage.getItem("token"),
            activity_ids: props.data.act_id,
            relation_id: getParamValue("relation_id"),
        })
        if (res.code === 1) {
            Toast.show(res.msg)
        } else {
            Toast.show({
                content: res.msg,
                stayTime: 5000,
            });
        }
    }

    const {
        act_id,
        card_name,
        validity,
        card_image,
        shop_image,
        writeOffMethod,
        writeoff_count,
        remark = "",
        intro
    } = props.data;
    return (
        <div className={style.CardDetails}>
            {/* 图片 */}
            <div className={style.shop_image}>
                {shop_image?.map((v, index: number) => {
                    return <img key={index} src={v} alt="" style={{ width: '100%' }} />
                })}
            </div>
            {/* 详情 */}
            <p className={style.CardDetails_title}>{card_name}</p>
            <div className={style.CardDetails_warp}>
                <img src={card_image} alt="" />
            </div>
            <div className={style.CardDetails_list}>
                <div className={style.CardDetails_item} style={{ alignItems: 'start' }}>
                    <div className={style.title} style={{ marginTop: 3 }}>卡券简介</div>
                    <div className={style.card_list}>
                        <ul>
                            {intro?.map((v: string, index: number) => {
                                return <li key={index}>{v}</li>
                            })}
                        </ul>
                    </div>
                </div>
                <div className={style.CardDetails_item}>
                    <div className={style.title}>核销方式</div>
                    <div>{writeOffMethod}</div>
                </div>
                <div className={style.CardDetails_item}>
                    <div className={style.title}>核销次数</div>
                    <div className={style.title}>{writeoff_count}</div>
                </div>
                <div className={style.CardDetails_item}>
                    <div className={style.title}>有效期</div>
                    <div>{validity}</div>
                </div>
                <div className={style.CardDetails_desc}>
                    <div className={style.title}>使用须知</div>
                    <div className={style.CardDetails_text} dangerouslySetInnerHTML={{ __html: remark }}></div>
                </div>
            </div>
            <Button block theme="primary" className={style.btn_form_CardDetails} onClick={handleClickSubForm}>立即领取</Button>
        </div>
    )
}

// 商家详情
function MerchantDetails() {
    return (
        <div>
            1231
        </div>
    )
}

// 商家其他卡券
function OtherCard() {
    return (
        <div className={style.collect_content}>
            <div className={style.collect_item}>
                <div className={style.collect_img}>
                    <img src="https://img1.baidu.com/it/u=2192265457,2884791613&fm=26&fmt=auto&gp=0.jpg" alt="" />
                </div>
                <div className={style.collect_desc}>
                    <p className={style.collect_title}>标题</p>
                    <div className={style.collect_desc_text}>描述描述描述描述描述描述描述描述描述描述描述</div>
                    <div className={style.collect_time}>
                        <p>开始时间: 2021-10-10 15:12:33</p>
                        <p>开始时间: 2021-10-10 15:12:33</p>
                    </div>
                    <span className={style.sub_Form}>立即领取</span>
                </div>
            </div>
        </div>
    )
}