import React from 'react'
import { observer } from 'mobx-react'
import { Icon, TabBar } from 'zarm'
import { store } from '../store/store.root'
import { StaffAuthRequired } from '../components/module-required/staff-auth-required'
import { RouterPage } from '../pages'
import { ValidWebsiteStaffAuthString } from '../store/website-rule/website-rule.store'
import LwhImages from '../assets/lwh.png'

interface IProps {
  showTabBar: boolean
}
export const AliIcon = Icon.createFromIconfont(
  '//at.alicdn.com/t/font_2391557_at0opghw8cn.js'
)
@observer
export class BangbangtangTabBar extends React.Component<IProps, any> {
  private readonly navigate = (value: string | number | undefined) => {
    switch (value as RouterPage) {
      case 'home':
        store.routerStore.replace('/build')
        break
      case 'person-center':
        store.routerStore.replace('/build/person-center')
        break
      case 'my-info':
        store.routerStore.replace('/build/my-info')
        break
      case 'gift-center':
        store.routerStore.replace('/build/gift-center')
        break
    }
  }
  render() {
    if (!this.props.showTabBar) {
      return null
    }
    const staff_auth: ValidWebsiteStaffAuthString[] = [
      'different_industry',
      'staff_center',
      'gift-center',
    ].filter((value) =>
      store.websiteRuleStore.has_staff_auth(
        value as ValidWebsiteStaffAuthString
      )
    ) as ValidWebsiteStaffAuthString[]
    // 转介绍
    const res = store.websiteRuleStore.has_module('marketing-transfer')
      ? 'gift-center'
      : ''

    const required: Array<
      ValidWebsiteStaffAuthString | 'home' | 'my-info' | 'gift-center'
    > = ['home', ...staff_auth, res, 'my-info']
    return (
      <TabBar
        activeKey={store.globalUiStore.currentActive!}
        onChange={this.navigate}
      >
        {required.map((value) => {
          switch (value) {
            case 'home':
              return (
                <TabBar.Item
                  key={value}
                  itemKey="home"
                  title="首页"
                  icon={<AliIcon type="iconhome" />}
                />
              )
            case 'different_industry':
              return (
                <TabBar.Item
                  key={value}
                  itemKey="different_industry"
                  title="异业中心"
                  icon={<AliIcon type="icontrust" />}
                />
              )
            case 'staff_center':
              return (
                <TabBar.Item
                  key={value}
                  itemKey="person-center"
                  title="员工中心"
                  icon={<AliIcon type="iconattachent" />}
                />
              )
            case 'gift-center':
              return (
                <TabBar.Item
                  key={value}
                  style={{ position: 'relative' }}
                  itemKey="gift-center"
                  title="礼品中心"
                  icon={
                    <div className="gift_center_icon">
                      <img src={LwhImages} alt="" style={{ width: 50 }} />
                    </div>
                  }
                />
              )
            case 'my-info':
              return (
                <TabBar.Item
                  itemKey="my-info"
                  key={value}
                  title="个人中心"
                  icon={<AliIcon type="iconaccount" />}
                />
              )
          }
        })}
      </TabBar>
    )
  }
}
