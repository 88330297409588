import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { ActivityIndicator, Loading, Toast } from "zarm";
import { FetchRequest } from "../../utils/fetch";
import "./index.css"

export default withRouter(function ExamPreview(props: {
    match: {
        params: {
            id: number
        }
    };
    history: any
}) {

    const question_id = props.match.params.id;

    useEffect(() => {
        document.title = "考题预览"
        getPresentationTopic()
    }, [])

    // 存放
    const [TopicList, setTopicList] = useState("")

    const [Answer, setAnswer] = useState("")

    const [Answer_analysis, setAnswer_analysis] = useState("")
    
    /**
     * 获取题目实例
     */
    async function getPresentationTopic() {
        Loading.show({
            content: <ActivityIndicator size="lg" />,
            stayTime: 3000,
        });
        let res = await FetchRequest("/wap/exam/getExamPreview", {
            // token: "ae1a826c-dbff-4383-b66d-882ef3a1e981",
            question_id
        })
        if (res.code === 1) {
            setTopicList(res.data.question)
            setAnswer(res.data.answer)
            setAnswer_analysis(res.data.answer_analysis)
            Loading.hide();
        } else {
            // 提示并且返回
            Toast.show(res.msg);
            props.history.push("/build")
        }
    }

    return (
        <div className="Topic_Content">
            <div dangerouslySetInnerHTML={{ __html: TopicList }}></div>
            <hr/>
            <div>正确答案:
                <span style={{ marginLeft: 5, color: "red" }} dangerouslySetInnerHTML={{ __html: Answer }}></span>
            </div>
            <hr />
            <div>答案解析:</div>
            <div dangerouslySetInnerHTML={{ __html: Answer_analysis }}></div>
        </div>
    )
})