import React, { useState, useEffect } from 'react'
import style from './index.module.scss'
import XlImages from '../../assets/xl.png'
import { FetchRequest } from '../../utils/fetch'
import { getParamValue } from '../../utils/url'
import { Checkbox, Loading, Toast } from 'zarm'
import { store } from '../../store/store.root'
import YesRedImages from '../../assets/yes_red.png'

export default () => {
  useEffect(() => {
    document.title = '订单'
    handleGetDetaile()
  }, [])

  const eroll_id = getParamValue('eroll_id')
  const meal_type = getParamValue('meal_type')
  const voucher = getParamValue('voucher') || ''

  const [data, setData] = useState<{
    activity_name?: string
    cut_price?: number
    detail?: string[]
    free?: string
    images?: Array<{
      id: number
      or_w_img: string
      origin_img: string
      object?: string[]
      is_order?: 0 | 1
    }>
    sum_num?: number
    total_price?: number
    voucher?: any[]
    voucher_max?: number
    voucher_need?: number
  }>({})

  async function handleGetDetaile() {
    let result = await FetchRequest('/wap/selectioneroll/settle', {
      token: localStorage.getItem('token'),
      eroll_id,
      meal_type,
    })
    if (result.code === 1) {
      if (result.data.voucher) {
        result.data.voucher = result.data.voucher.map((v: any) => {
          return {
            ...v,
            check: false,
          }
        })
      }
      setData(result.data)
    } else {
      Toast.show(result.msg)
    }
  }

  async function handleClickPay() {
    Loading.show()
    let result = await FetchRequest('/wap/selectioneroll/settlePay', {
      token: localStorage.getItem('token'),
      eroll_id,
      meal_type,
      voucher,
    })
    if (result.code === 1) {
      Loading.hide()
      if (result.data.data === 'success') {
        store.routerStore.replace(
          `/build/selection-successful?eroll_id=${eroll_id}`
        )
        return
      }
      let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
        currenturl: window.location.href,
      })
      const { wx }: any = window
      const { appId, nonceStr, signature, timestamp, jsApiList } =
        SDKParams.data
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList, // 必填，需要使用的JS接口列表
      })

      wx.ready(function () {
        const { appId, nonceStr, order_id, paySign, signType, timestamp } =
          result.data.data
        wx.chooseWXPay({
          timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          package: result.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign: paySign, // 支付签名
          success: function (res: any) {
            // 支付成功后的回调函数
            // alert("支付成功")
            store.routerStore.replace(
              `/build/selection-successful?eroll_id=${eroll_id}`
            )
          },
        })
      })

      wx.error(function (res: any) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        Toast.show('支付失败')
      })
    } else {
      Toast.show(result.msg)
    }
    Loading.hide()
  }

  return (
    <div className={style.selection_pay}>
      <div className={style.selection_warp}>
        <p>
          <img src={XlImages} alt="" />
          <span>{data.activity_name}</span>
        </p>
        {/* <div className={style.selection_text}>
                    <div>免费选片一张</div> 
                    <div>用于接送卡片4张</div>
                </div> */}
      </div>
      <div className={style.selection_list}>
        <div className={style.selection_list_warp}>
          {data.detail?.map((v, index) => {
            return <div key={index}>{v}</div>
          })}
        </div>
        <div className={style.selection_list_warp}>
          <div>{data.free}</div>
        </div>
        <div className={style.selection_list_warp}>
          <div>已选择照片{data.sum_num}张</div>
        </div>
        <div className={style.selection_list_images}>
          {data.images?.map((v) => {
            return (
              <div key={v.id}>
                <div
                  style={{
                    height: 85,
                    border: '1px solid #eee',
                    position: 'relative',
                  }}
                >
                  <div>
                    <img
                      src={v.origin_img}
                      alt=""
                      style={{
                        width: '80%',
                        display: 'block',
                        margin: '0 auto',
                        border: '8px solid #fff',
                      }}
                    />
                    <div
                      className={style.watermarkText}
                      style={{
                        width: '80%',
                        margin: '0 auto',
                        overflow: 'hidden',
                        padding: 9,
                      }}
                    >
                      {Array.from(Array(150), (v, k) => (
                        <div
                          style={{
                            lineHeight: 1,
                            opacity: parseFloat(
                              localStorage.getItem('watermark_pellucidity') ||
                                '0.5'
                            ),
                          }}
                          key={k}
                        >
                          {localStorage.getItem('watermark_settings')}
                        </div>
                      ))}
                    </div>
                  </div>
                  {v.is_order == 1 && (
                    <img
                      src={YesRedImages}
                      alt=""
                      style={{
                        position: 'absolute',
                        width: 20,
                        right: 0,
                        bottom: 0,
                      }}
                    />
                  )}
                  {/*  */}
                </div>
                {v.object?.map((j, index) => {
                  return (
                    <div
                      key={index}
                      style={{ lineHeight: 1, padding: '7px 0' }}
                    >
                      {j}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        {voucher && (
          <div
            style={{
              borderTop: '1px dashed rgb(238, 238, 238)',
            }}
          >
            <div>您已经领取卡券：</div>
            {data.voucher
              ?.filter((v) => {
                if (voucher.indexOf(String(v.id)) > -1) {
                  return v
                }
              })
              .map((v, index) => {
                return <span key={index}>【{v.card_name}】</span>
              })}
          </div>
        )}

        <div className={style.selection_price}>
          {data.cut_price !== 0 && (
            <div style={{ borderTop: '1px dashed #eee' }}>
              <div style={{ marginRight: 5 }}>已为您节省</div>
              <div>¥ {data.cut_price}</div>
            </div>
          )}
          <div>
            <div style={{ marginRight: 15 }}>总价</div>
            <div style={{ color: '#f7c324', fontSize: 20 }}>
              ¥ {data.total_price}
            </div>
          </div>
        </div>
      </div>
      <div className={style.btn_sub} onClick={handleClickPay}>
        立即支付¥ {data.total_price}
      </div>
    </div>
  )
}
