// import { LOAD_STATE } from "zarm/es/pull/PropsType";
import { computed, makeAutoObservable, runInAction, toJS } from "mobx";
import { BangBangTangRequest } from "../../utils/request";
import { store } from "../store.root";
import {
  EmployeePreorderClotheListDataInterface,
  EmployeePreorderClotheListResponse,
} from "./employee-preorder-clothe-list.interface";
import { finalize } from "rxjs/operators";

import { format, parse } from "date-fns";

export type EmployeePreorderClotheListStatusString =
  | "未提交"
  | "已提交"
  | "取消"
  | "已过期"
  | "全部";

export const EMPLOYEE_PREORDER_CLOTHE_LIST_STATUS_STRING: EmployeePreorderClotheListStatusString[] = [
  "全部",
  "未提交",
  "已提交",
  "已过期",
  "取消",
];

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
};

interface EmployeePreorderClotheListStoreDataDic {
  page: number;
  data: EmployeePreorderClotheListDataInterface[] | null;
  load_state: number;
}

export class EmployeePreorderClotheListStore {
  search_text: string = "";
  search_time: string = format(new Date(), "yyyy-MM-dd");
  date_picker_visible: boolean = false;
  header_height: number = 0;
  schedule_type: { id: number; name: string }[] | undefined = undefined;
  active_schedule_type_index: number | undefined = undefined;
  setHeaderHeight(v: number) {
    this.header_height = v;
  }
  setDatePickerVisible(v: boolean) {
    this.date_picker_visible = v;
  }
  setSearchTime(value: string) {
    this.search_time = value;
    this.reset();
  }

  setSearchText(value: string) {
    this.search_text = value;
    this.reset();
  }

  searchFromServer = () => {
    let type;
    switch (this.active_panel_name) {
      case "未提交":
        type = "uncommitted";
        break;
      case "已过期":
        type = "expired";
        break;
      case "已提交":
        type = "committed";
        break;
      case "取消":
        type = "canceled";
        break;
      case "全部":
        type = "";
        break;
    }
    let url = `/wap/clothing/getAllSelectionList?token=${
      store.userStore.token
    }&page=${this.data.page + 1}&store_id=${store.storeInfoStore.value}`;
    if (type) {
      url += `&type=${type}`;
    }
    if (
      this.active_schedule_type_index !== undefined &&
      this.schedule_type &&
      this.schedule_type[this.active_schedule_type_index].id > 0
    ) {
      url +=
        "&schedule_type=" +
        this.schedule_type[this.active_schedule_type_index].id;
    }
    if (this.search_text) {
      url += "&user_mobile=" + this.search_text;
    }
    if (this.search_time) {
      url += `&schedule_date=${
        parse(this.search_time, "yyyy-MM-dd", new Date()).getTime() / 1000
      }`;
    }
    return BangBangTangRequest({
      url,
      data: {},
      method: "GET",
    }).pipe(finalize(() => runInAction(() => (this.searchLoading = false))));
  };

  searchLoading: boolean = false;
  data: EmployeePreorderClotheListStoreDataDic = {
    page: 0,
    data: null,
    load_state: LOAD_STATE.normal,
  };

  // @computed get active_panel_index(): number {
  //   return EMPLOYEE_PREORDER_CLOTHE_LIST_STATUS_STRING.findIndex(
  //     (value) => value === this.active_panel_name
  //   );
  // }
  get_schedule_type() {
    BangBangTangRequest({
      url: "/wap/clothing/getScheduleType",
      method: "POST",
      data: {},
    }).subscribe((value: any) =>
      runInAction(() => {
        this.schedule_type = [{ id: -1, name: "全部" }, ...value];
        this.active_schedule_type_index = 0;
        setTimeout(() => this.load_more());
      })
    );
  }

  active_panel_name: EmployeePreorderClotheListStatusString = "全部";
  resetData() {
    this.data = { data: null, load_state: LOAD_STATE.normal, page: 0 };
  }
  load_more = () => {
    if (this.schedule_type) {
      this.data.load_state = LOAD_STATE.loading;
      this.searchFromServer().subscribe((value) => {
        runInAction(() => {
          if (value.length > 0) {
            if (this.data.data) {
              this.data.data = [...this.data.data, ...value];
              this.data.page += 1;
              this.data.load_state = LOAD_STATE.normal;
            } else {
              this.data = {
                data: value,
                page: 1,
                load_state: LOAD_STATE.normal,
              };
            }
          } else {
            this.data.load_state = LOAD_STATE.complete;
          }
        });
      });
    } else {
      // this.requestDataFromServer(() =>
      //   runInAction(() => (this.current_active.load_state = LOAD_STATE.loading))
      // );
    }
  };

  @computed get current_active(): EmployeePreorderClotheListStoreDataDic {
    return this.data;
  }

  constructor() {
    makeAutoObservable(this);
    // autorun(() => {
    //   from(toStream(() => [this.search_time, this.search_text]))
    //     .pipe(
    //       debounceTime(500),
    //       tap(() => runInAction(() => (this.active_panel_name = "全部"))),
    //       tap(() =>
    //         runInAction(
    //           () =>
    //             (this.data = {
    //               data: null,
    //               load_state: LOAD_STATE.loading,
    //               page: 0,
    //             })
    //         )
    //       ),
    //       delay(1),
    //       switchMap(() => {
    //         return this.searchFromServer();
    //       })
    //     )
    //     .subscribe((value) => {
    //       runInAction(() => {
    //         this.data = {
    //           data: value,
    //           page: 1,
    //           load_state:
    //             value.length === 0 ? LOAD_STATE.complete : LOAD_STATE.normal,
    //         };
    //       });
    //     });
    // });
  }

  change_active_panel_index(index: number | undefined) {
    if (index !== undefined) {
      this.active_panel_name =
        EMPLOYEE_PREORDER_CLOTHE_LIST_STATUS_STRING[index];
      setTimeout(() => {
        this.resetData();
        this.load_more();
      });
    }
  }

  requestDataFromServer(onBefore?: () => void, onAfter?: () => void) {
    if (onBefore) {
      onBefore();
    }
    let type: string;
    switch (this.active_panel_name) {
      case "未提交":
        type = "uncommitted";
        break;
      case "已过期":
        type = "expired";
        break;
      case "已提交":
        type = "committed";
        break;
      case "取消":
        type = "canceled";
        break;
      case "全部":
        type = "";
        break;
    }
    this.data.load_state = LOAD_STATE.loading;
    BangBangTangRequest<EmployeePreorderClotheListResponse>({
      url: `/wap/clothing/getAllSelectionList.html?token=${
        store.userStore.token
      }&page=${this.data.page + 1}${
        type === "" ? "" : `&type=${type}`
      }&store_id=${store.storeInfoStore.value}`,
      data: {},
      method: "GET",
    }).subscribe((value) => {
      let panel_name: EmployeePreorderClotheListStatusString;
      switch (type) {
        case "":
          panel_name = "全部";
          break;
        case "expired":
          panel_name = "已过期";
          break;
        case "canceled":
          panel_name = "取消";
          break;
        case "committed":
          panel_name = "已提交";
          break;
        case "uncommitted":
          panel_name = "未提交";
          break;
      }
      const origin = this.data;
      if (value.length === 0) {
        runInAction(() => {
          this.data.load_state = LOAD_STATE.complete;
        });
      } else {
        runInAction(() => {
          origin.load_state = LOAD_STATE.normal;
          origin.page += 1;
        });
        if (origin.data !== null) {
          runInAction(() => {
            origin.data = [...origin.data!, ...value];
          });
        } else {
          runInAction(() => {
            origin.data = value;
          });
        }
      }
    });
  }

  reset() {
    // 清空所有内容
    this.data = { data: null, load_state: LOAD_STATE.normal, page: 0 };
    // this.active_panel_name = "未提交";
  }

  change_active_schedule_type(index: number | undefined) {
    this.active_schedule_type_index = index;
    this.reset();
    this.load_more();
  }
}
