import React, { useEffect, useState } from 'react'
import './register.css'
import { handleOnChangShare, PHONE_RE } from '../../utils'
import classNames from 'classnames'
import { store } from '../../store/store.root'
import { BangBangTangRequest } from '../../utils/request'
import { observer } from 'mobx-react'
import { FetchRequest } from '../../utils/fetch'
import { RegisterJump } from './RegisterJump.jsx'
import { Button, Loading, Modal, Toast } from 'zarm'

interface State {
  phoneValue: string
  sendTime: Date | null
  identifyCode: string
  state: 'register' | 'change'
  register_method: 'sms' | 'mini'
  gh_appid: string
  isClickBtn: boolean
}

@observer
export class Register extends React.Component<any, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      phoneValue: '',
      sendTime: null,
      identifyCode: '',
      state: 'register',
      register_method: 'sms',
      gh_appid: '',
      isClickBtn: false,
    }
  }

  startSendTimeCountDown() {
    setTimeout(
      () =>
        this.forceUpdate(() => {
          if (this.getCountDown() > 0) this.startSendTimeCountDown()
          else this.setState({ sendTime: null })
        }),
      1000
    )
  }

  getCountDown(): number {
    return (
      60 +
      Math.ceil((this.state.sendTime!.getTime() - new Date().getTime()) / 1000)
    )
  }

  async componentDidMount() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('type') || 'register'
    if (code === 'register' || code === 'change') {
      this.setState({ state: code })
    } else this.setState({ state: 'register' })
    if (code === 'register') {
      document.title = '绑定手机号'
      this.handleGetConfig()
    } else {
      document.title = '更换手机号'
    }
  }

  handleGetConfig = async () => {
    const _this = this
    let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
      currenturl: window.location.origin + window.location.pathname,
    })
    if (SDKParams.code === 1) {
      if (SDKParams.data.register_method === 'mini') {
        if (parseInt(localStorage.getItem('subscribe') || '0') === 0) {
          this.handleGetShowAlert()
          return
        }
      }

      const { wx }: any = window
      const { appId, nonceStr, signature, timestamp, jsApiList, openTagList } =
        SDKParams.data
      wx.config({
        debug: false, // 开启调试模式
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList, // 必填，需要使用的JS接口列表
        openTagList,
      })

      wx.ready(() => {
        localStorage.removeItem('register_size')
        this.setState({
          register_method: SDKParams.data.register_method,
          gh_appid: SDKParams.data.gh_appid,
        })
        if (SDKParams.data.register_method === 'mini') {
          console.log('开始监听页面出现')
          document.addEventListener('visibilitychange', async () => {
            if (document.visibilityState == 'visible') {
              // alert('即将跳转' + localStorage.getItem('login_url_redri'))
              localStorage.removeItem('token')
              localStorage.setItem('scope', 'snsapi_userinfo')
              location.href = localStorage.getItem('redirect_url') || '/build'
              // store.routerStore.replace(
              //   localStorage.getItem('redirect_url') || '/build'
              // )
            } else {
              // alert('浏览器消失用户视野中')
            }
          })
        }
      })

      wx.error((res: any) => {
        console.log('失败:', res)
        if (SDKParams.data.register_method === 'mini') {
          const s = parseInt(localStorage.getItem('register_size') || '1')

          // || parseInt(localStorage.getItem('subscribe') || '0') === 1
          if (s <= 5) {
            localStorage.setItem('register_size', (s + 1).toString())
            location.href = '/build/register'
          } else {
            this.handleGetShowAlert()
          }
        }
      })
    } else {
      Toast.show('JsSdk获取失败')
    }
  }

  handleGetShowAlert = () => {
    const modal = Modal.alert({
      title: '提示',
      content: (
        <div>
          <img
            src={localStorage.getItem('follow_qr') || ''}
            alt=""
            style={{ width: 150 }}
          />
          <p>长按识别关注公众号, 若已关注请点击【关闭】</p>
          <p>或点击右下角【使用完整服务】</p>
        </div>
      ),
      onCancel: () => {
        localStorage.removeItem('token')
        setTimeout(() => {
          location.href = '/build'
        }, 400)
        modal.hide()
      },
    })
  }

  navigate = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const next = urlParams.get('next') || '/build'
    location.href = next
    // store.routerStore.replace(next)
  }

  render() {
    // 注释
    // if (this.state.register_method) {
    //   return (
    //     <div>
    //       <div className="crazyInTheLoad">疯狂加载中....</div>
    //     </div>
    //   )
    // }
    if (this.state.register_method === 'mini' && !!this.state.gh_appid) {
      return (
        <div>
          <div className="register">
            <div className="register-content">
              <RegisterJump gh_appid={this.state.gh_appid} />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="register">
        <div className="register-content">
          <div
            className="avatar"
            style={{ backgroundImage: `url(${store.userStore.avatar})` }}
          />
          <div className="username">{store.userStore.username}</div>
          <div className="input-row">
            <input
              className="phone-input"
              placeholder={
                this.state.state === 'register'
                  ? '输入注册手机号'
                  : '输入更换的手机号'
              }
              type="number"
              pattern="[0-9]*"
              onChange={this.phoneNumberChange}
              value={this.state.phoneValue}
            />
            <span className="iconfont">&#xe608;</span>
          </div>
          <div className="input-row">
            <input
              className="phone-input"
              onChange={this.identifyCodeChange}
              placeholder="验证码"
              type="number"
              pattern="[0-9]*"
              value={this.state.identifyCode}
            />
            <span className="iconfont">&#xe60a;</span>
            {this.state.sendTime ? (
              <span
                className={classNames({
                  more: true,
                  disabled: true,
                })}
              >
                {this.getCountDown()}秒
              </span>
            ) : (
              <span
                onClick={this.requestPhoneCode}
                className={classNames({
                  more: true,
                  disabled: !this.phoneNumberIsValid(),
                })}
              >
                发送验证码
              </span>
            )}
          </div>
          <div
            className={classNames({
              button: true,
              'disabled-button': !this.formIsValid(),
            })}
            onClick={this.register}
          >
            {this.state.state === 'register' ? '确认注册' : '更改手机号'}
          </div>
        </div>
      </div>
    )
  }

  phoneNumberIsValid() {
    return this.state.phoneValue.match(PHONE_RE)
  }

  formIsValid() {
    return (
      this.phoneNumberIsValid() &&
      this.state.phoneValue &&
      this.state.identifyCode.length >= 4
    )
  }

  private readonly requestPhoneCode = () => {
    if (this.phoneNumberIsValid()) {
      if (this.state.state === 'register') {
        BangBangTangRequest({
          url: '/api/sms/send',
          method: 'POST',
          data: { mobile: this.state.phoneValue, event: 'register' },
        }).subscribe(() => {
          this.setState({ sendTime: new Date() }, () =>
            this.startSendTimeCountDown()
          )
        })
      } else {
        BangBangTangRequest({
          url: '/api/sms/send',
          method: 'POST',
          data: { mobile: this.state.phoneValue, event: 'changemobile' },
        }).subscribe(() => {
          this.setState({ sendTime: new Date() }, () =>
            this.startSendTimeCountDown()
          )
        })
      }
    }
  }

  private readonly phoneNumberChange = (event: any) => {
    this.setState({ phoneValue: event.target.value })
  }

  private readonly identifyCodeChange = (event: any) => {
    this.setState({ identifyCode: event.target.value })
  }

  private readonly register = () => {
    if (this.formIsValid()) {
      BangBangTangRequest({
        url: '/api/sms/check',
        method: 'POST',
        data: {
          mobile: this.state.phoneValue,
          event: this.state.state === 'register' ? 'register' : 'changemobile',
          captcha: this.state.identifyCode,
        },
      }).subscribe(() => {
        BangBangTangRequest({
          url:
            this.state.state === 'register'
              ? '/wap/wap2/saveMobile'
              : '/wap/wap2/changeMobile',
          method: 'POST',
          data: { mobile: this.state.phoneValue, token: store.userStore.token },
        }).subscribe((res) => {
          store.userStore.changePhone(this.state.phoneValue)
          this.navigate()
        })
      })
    }
  }
}
