import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { store } from "../../store/store.root";
import { ImagePreview, NoticeBar } from "zarm";
import { getParamValue } from "../../utils/url";
import { toJS } from "mobx"

const styleNotic: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  zIndex: 10,
  background: 'rgb(255 252 247)'
}

const styleBackBtn: React.CSSProperties = {
  position: 'fixed',
  width: 50,
  height: 50,
  background: '#fff',
  borderRadius: '50%',
  bottom: '3%',
  right: '3%',
  textAlign: "center",
  fontSize: 13,
  lineHeight: "50px",
  zIndex: 10,
  boxShadow: 'rgb(155 155 155) -1px 1px 6px'
}


export default () => {
  const [isImagesPreview, setisImagesPreview] = useState(false);
  const [originImages, setoriginImages] = useState("");

  const [type] = useState(getParamValue("type"));

  if (
    store.customerPreorderClotheStore.show_large !== null ||
    store.employeePreorderClotheStore.show_large !== null
  ) {
    let list: any[] = [];

    if (type === "Employee") {
      list = store.employeePreorderClotheStore.data.find(
        (value) => value.id === store.employeePreorderClotheStore.show_large!
      )!.clothingimages;
    } else {
      list = store.customerPreorderClotheStore.__data.find(
        (value) => value.id === store.customerPreorderClotheStore.show_large!
      )!.clothingimages;
    }

    return (
      <>
        {store.customerPreorderClotheStore.show_message_notic && (
          <div style={styleNotic}>
            <NoticeBar>{store.customerPreorderClotheStore.show_message_notic}</NoticeBar>
          </div>
        )}
        <div style={{ background: "#434f7d" }}>
          <Swiper
            className="main-container"
            effect="coverflow"
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            coverflowEffect={{ slideShadows: false }}
          >
            {list.map((value, index) => (
              <SwiperSlide key={index} className="swiper-item">
                <div
                  style={{
                    maxHeight: "83vh",
                    overflow: "hidden",
                    outline: "15px solid white",
                  }}
                >
                  <img
                    src={value}
                    alt="预选服装"
                    onClick={() => {
                      setoriginImages(value);
                      // setisImagesPreview(true)
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <ImagePreview
            visible={isImagesPreview}
            images={[originImages]}
            onClose={() => setisImagesPreview(false)}
            maxScale={5}
          />
        </div>
        <div style={styleBackBtn} onClick={() => window.history.back()}>返回</div>
      </>
    );
  } else {
    return null;
  }
};
