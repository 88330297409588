import React from "react";
import { BangBangTangLayout } from "../../layout/layout";
import style from "./index.module.scss";
import { Tabs, Badge, Cell, Pull, ActivityIndicator } from "zarm";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";
import { finalize } from "rxjs/operators";
import Default from "../../assets/default-list-image.png";
const { Panel } = Tabs;

const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
};

export class BonusPenalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // BonusPenaltyList: [
      //   {
      //     title: "奖励单",
      //     count: 0,
      //   },
      //   {
      //     title: "处罚单",
      //     count: 0,
      //   },
      // ],
      // tabValue: 0,
      load_state: LOAD_STATE.normal,
      list: [],
      currentPage: 1,
      // hasMore: false,
      result: "",
      resultModal: false,
    };
  }

  static propTypes = {
    history: PropTypes.object,
  };

  // titleShow = (title, count) => {
  //   return (
  //     <div className={style.comments_tab_title}>
  //       <span className="tab-title">{title}</span>
  //       {count !== 0 && <Badge shape="round" text={count} />}
  //     </div>
  //   );
  // };
  componentDidMount() {
    window.document.title = "奖励单";
    this.getRewardList();
  }

  getRewardList = (page = 1) => {
    this.setState({
      load_state: LOAD_STATE.loading,
    });
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getAwardList",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        page: page,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        let ListClone = that.state.list;
        if (page == 1) {
          ListClone = result;
        } else {
          ListClone = ListClone.concat(result);
        }
        that.setState({
          list: ListClone,
          currentPage: page,
          load_state:
            result.length < 10 ? LOAD_STATE.complete : LOAD_STATE.normal,
        });
      });
  };

  // getThumbsList = (page = 1) => {
  //   let that = this;
  //   BangBangTangRequest({
  //     url: "/wap/sanction/getThumbsList",
  //     method: "POST",
  //     data: {
  //       token: localStorage.getItem("token"),
  //       page: page,
  //       store_id: localStorage.getItem("admin_store_id"),
  //     },
  //   })
  //     .pipe(finalize(() => store.globalUiStore.hideLoading()))
  //     .subscribe((result) => {
  //       let ListClone = that.state.list;
  //       if (page == 1) {
  //         ListClone = result;
  //       } else {
  //         ListClone = ListClone.concat(result);
  //       }
  //       that.setState({
  //         list: ListClone,
  //         currentPage: page,
  //         load_state:
  //           result.length < 10 ? LOAD_STATE.complete : LOAD_STATE.normal,
  //       });
  //     });
  // };

  // tabValueChange = (value) => {
  //   // this.getList(value, 1);
  //   this.setState({
  //     tabValue: value,
  //   });
  //   if (value == 0) {
  //     this.getRewardList();
  //   } else {
  //     this.getThumbsList();
  //   }
  // };

  getListMore = (tabValue, page) => {
    // if (tabValue == 0) {
      this.getRewardList(page);
    // } else if (tabValue == 1) {
    //   this.getThumbsList(page);
    // }
  };

  render() {
    const {
      BonusPenaltyList,
      tabValue,
      load_state,
      list,
      currentPage,
    } = this.state;
    return (
      <BangBangTangLayout title={"奖励单"}>
        <div className={style.container}>

          <div className="list">
            <Pull
              className="list-container"
              refresh={{ state: 0 }}
              load={{
                state: load_state,
                handler: () => {
                  this.getListMore(tabValue, currentPage + 1);
                },
                distance: 200,
                render: (loadState) => {
                  const cls = "custom-control";
                  switch (loadState) {
                    case LOAD_STATE.loading:
                      return (
                        <div className={cls}>
                          <ActivityIndicator type="spinner" />
                        </div>
                      );
                    case LOAD_STATE.failure:
                      return <div className={cls}>加载失败</div>;
                    case LOAD_STATE.complete:
                      return <div className={cls}>没有更多数据了</div>;
                  }
                },
              }}
            >
              {list.map((item, index) => {
                return (
                  <Cell
                    key={index}
                    title={
                      <div className="list_item">
                        <div className="title">
                          <span className="syllable">受奖人：{item.admin_name}</span>
                          <span>奖励类型：{item.type_name}</span>
                        </div>
                        <div className="description">
                          <span className="syllable">奖励积分{item.integral}</span>
                          <span>奖励金额：{item.money}</span>
                        </div>
                        <div className="description">详情：{item.reason}</div>
                        <div className="description">
                          <span className="syllable">
                            开单时间：{item.createtime}
                          </span>
                          <span>开单人：{item.updater}</span>
                        </div>
                      </div>
                    }
                    icon={
                      <img
                        alt=""
                        src={item.image || Default}
                        style={{ width: 48, height: 48 }}
                      />
                    }
                    onClick={() => {
                      this.props.history.push(
                        "./executive-force-detail/" + item.id + "/2"
                      );
                    }}
                  />
                );
              })}
            </Pull>
          </div>
        </div>
      </BangBangTangLayout>
    );
  }
}
export default withRouter(BonusPenalty);
