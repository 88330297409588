import { BangBangTangRequest } from "../../utils/request";
import { store } from "../../store/store.root";
import { MyPreorderDetailInterface } from "./my-preorder.api";
import { MyPreorderPanelEnum } from "./my-preorder";

export const MyPreorderRequest = (page: number, schedule_status: number) => {
  return BangBangTangRequest<MyPreorderDetailInterface[]>({
    url: "/wap/schedule/getUserBookingData",
    data: {
      token: store.userStore.token,
      page: page,
      status: "all",
    },
    method: "POST",
  });
};
