import React from "react";
import { ValidWebsiteModuleString } from "../../store/website-rule/website-rule.store";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";
interface IProps {
  name: ValidWebsiteModuleString;
}

@observer
export class ModuleRequired extends React.Component<IProps, any> {
  render() {
    if (store.websiteRuleStore.has_module(this.props.name)) {
      return this.props.children;
    } else {
      return null;
    }
  }
}
