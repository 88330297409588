import React from "react";
import { Button, Cell, Checkbox, Modal, Panel, SearchBar, Tabs } from "zarm";
import { observer } from "mobx-react";
import { employeePreorderClotheBriefDetailStore } from "./employee-preorder-clothe-brief-detail.store";
import { runInAction } from "mobx";

@observer
export class EmployeePreorderClotheBriefDetailSetModal extends React.Component<
  {
    origin_value: number[];
    onSubmit: (value: number[]) => void;
    onCancel: () => void;
  },
  {}
> {
  constructor(props: any) {
    super(props);
  }
  componentDidMount() {
    employeePreorderClotheBriefDetailStore.clearValue();
    if (this.props.origin_value && this.props.origin_value.length > 0) {
      this.props.origin_value.forEach((v) =>
        employeePreorderClotheBriefDetailStore.toggleValue(v)
      );
    }

    // if (preorderDetailFromEmployeeStore.data?.makeup_guide_list) {
    //   runInAction(() => {
    //     preorderDetailFromEmployeeStore.data?.makeup_guide_list.forEach(
    //       (value: any) =>
    //         preorderDetailFromEmployeeStore.markup_value.add(
    //           parseInt(value, 10)
    //         )
    //     );
    //   });
    // }
    // if (preorderDetailFromEmployeeStore.data?.photographer_list) {
    //   runInAction(() => {
    //     preorderDetailFromEmployeeStore.data?.photographer_list.forEach(
    //       (value: any) =>
    //         preorderDetailFromEmployeeStore.photographer_value.add(
    //           parseInt(value, 10)
    //         )
    //     );
    //   });
    // }
  }

  render() {
    if (
      employeePreorderClotheBriefDetailStore.servers &&
      employeePreorderClotheBriefDetailStore.servers.length > 0
    ) {
    } else {
      return null;
    }
    return (
      <Modal
        destroy={true}
        visible={true}
        width={"90%"}
        footer={
          <div className="modal-buttons">
            <Button onClick={() => this.props.onCancel()}>取消</Button>
            <Button
              theme="primary"
              onClick={() =>
                this.props.onSubmit(
                  Array.from(employeePreorderClotheBriefDetailStore.value)
                )
              }
              disabled={employeePreorderClotheBriefDetailStore.value.size === 0}
            >
              确定
            </Button>
          </div>
        }
      >
        <div className="preorder-detail-from-employee-photographer-modal-checkbox">
          <SearchBar
            value={employeePreorderClotheBriefDetailStore.search_value}
            onChange={(v) =>
              employeePreorderClotheBriefDetailStore.setSearchValue(v || "")
            }
            onClear={() => {
              employeePreorderClotheBriefDetailStore.setSearchValue("");
            }}
            onCancel={() => {
              employeePreorderClotheBriefDetailStore.setSearchValue("");
            }}
          />
          {employeePreorderClotheBriefDetailStore.valid_servers.map((value) => (
            <Cell
              key={value.id}
              onClick={() =>
                employeePreorderClotheBriefDetailStore.toggleValue(value.id)
              }
            >
              <Checkbox
                checked={employeePreorderClotheBriefDetailStore.value.has(
                  value.id
                )}
              >
                {value.name}
              </Checkbox>
            </Cell>
          ))}
        </div>
      </Modal>
    );
  }
}
