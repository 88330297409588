import React from "react";
import {
  Button,
  Cell,
  Checkbox,
  Modal,
  Panel,
  Radio,
  SearchBar,
  Tabs,
} from "zarm";
import { observer } from "mobx-react";
import { preorderDetailFromEmployeeStore } from "./preorder-detail-from-employee.store";
import { runInAction } from "mobx";

@observer
export class PreorderDetailFromEmployeeSetPhotographerModal extends React.Component<{}> {
  constructor(props: any) {
    super(props);
    this.state = { currentSelect: null };
  }
  componentDidMount() {
    preorderDetailFromEmployeeStore.photographer_value.clear();
    preorderDetailFromEmployeeStore.markup_value.clear();
    if (preorderDetailFromEmployeeStore.data?.makeup_guide_list) {
      runInAction(() => {
        preorderDetailFromEmployeeStore.data?.makeup_guide_list.forEach(
          (value: any) =>
            preorderDetailFromEmployeeStore.markup_value.add(
              parseInt(value, 10)
            )
        );
      });
    }
    if (preorderDetailFromEmployeeStore.data?.photographer_list) {
      runInAction(() => {
        preorderDetailFromEmployeeStore.data?.photographer_list.forEach(
          (value: any) =>
            preorderDetailFromEmployeeStore.photographer_value.add(
              parseInt(value, 10)
            )
        );
      });
    }
  }

  render() {
    if (!preorderDetailFromEmployeeStore.photographer_data) {
      return null;
    }
    return (
      <Modal
        destroy={true}
        visible={
          preorderDetailFromEmployeeStore.set_photographer_modal_visible !==
          "none"
        }
        width={"90%"}
        footer={
          <div className="modal-buttons">
            <Button
              onClick={() =>
                preorderDetailFromEmployeeStore.change_set_photographer_modal_visible(
                  "none"
                )
              }
              disabled={
                preorderDetailFromEmployeeStore.set_photographer_loading
              }
            >
              取消
            </Button>
            <Button
              theme="primary"
              onClick={() =>
                preorderDetailFromEmployeeStore.submitScheduleStaff()
              }
              // disabled={this.props.loading || !this.state.currentSelect}
            >
              确定
            </Button>
          </div>
        }
      >
        <Tabs
          value={
            preorderDetailFromEmployeeStore.set_photographer_modal_visible ===
            "photographer"
              ? 0
              : 1
          }
          onChange={(value) =>
            value === 0
              ? preorderDetailFromEmployeeStore.change_set_photographer_modal_visible(
                  "photographer"
                )
              : preorderDetailFromEmployeeStore.change_set_photographer_modal_visible(
                  "markup_guide"
                )
          }
        >
          <Panel title="摄影师" />
          <Panel title="妆引师" />
        </Tabs>
        <SearchBar
          value={preorderDetailFromEmployeeStore.photographer_search_text}
          onChange={(value) => {
            preorderDetailFromEmployeeStore.set_photographer_search_text(
              value || ""
            );
          }}
          onClear={() => {
            preorderDetailFromEmployeeStore.set_photographer_search_text("");
          }}
        />
        <div className="preorder-detail-from-employee-photographer-modal-checkbox">
          {preorderDetailFromEmployeeStore.set_photographer_modal_visible ===
          "photographer"
            ? preorderDetailFromEmployeeStore.valid_photographer.map(
                (value) => (
                  <Cell key={value.id}>
                    <Checkbox
                      checked={preorderDetailFromEmployeeStore.photographer_value.has(
                        value.id
                      )}
                      onChange={() =>
                        preorderDetailFromEmployeeStore.toggle_photographer_value(
                          value.id
                        )
                      }
                    >
                      {value.nickname}
                    </Checkbox>
                  </Cell>
                )
              )
            : preorderDetailFromEmployeeStore.valid_markup_guide.map(
                (value) => (
                  <Cell key={value.id}>
                    <Checkbox
                      checked={preorderDetailFromEmployeeStore.markup_value.has(
                        value.id
                      )}
                      onChange={() =>
                        preorderDetailFromEmployeeStore.toggle_markup_value(
                          value.id
                        )
                      }
                    >
                      {value.nickname}
                    </Checkbox>
                  </Cell>
                )
              )}
          {/*<Cell>*/}
          {/*  <Checkbox defaultChecked>默认选中</Checkbox>*/}
          {/*</Cell>*/}
          {/*<Cell>*/}
          {/*  <Checkbox defaultChecked>默认选中</Checkbox>*/}
          {/*</Cell>*/}
          {/*<Cell>*/}
          {/*  <Checkbox defaultChecked>默认选中</Checkbox>*/}
          {/*</Cell>*/}
          {/*<Cell>*/}
          {/*  <Checkbox defaultChecked>默认选中</Checkbox>*/}
          {/*</Cell>*/}
          {/*<Cell>*/}
          {/*  <Checkbox defaultChecked>默认选中</Checkbox>*/}
          {/*</Cell>*/}
          {/*<Cell>*/}
          {/*  <Checkbox defaultChecked>默认选中</Checkbox>*/}
          {/*</Cell>*/}
          {/*<Cell>*/}
          {/*  <Checkbox defaultChecked>默认选中</Checkbox>*/}
          {/*</Cell>*/}
          {/*<Cell>*/}
          {/*  <Checkbox defaultChecked>默认选中</Checkbox>*/}
          {/*</Cell>*/}
        </div>
      </Modal>
    );
  }
}
