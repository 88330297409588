import React, { useEffect, useState } from 'react'
import { Carousel, Cell } from 'zarm'
import style from './index.module.scss'
import DHImages from '../../assets/dh.png'
import KFImages from '../../assets/kf.png'
import TJImages from '../../assets/tj.png'

export default () => {
  useEffect(() => {
    document.title = '芜湖~'
  }, [])

  return (
    <div style={{ paddingBottom: 60 }}>
      <div className={style.headerImage}>
        <img
          src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fgss0.baidu.com%2F-vo3dSag_xI4khGko9WTAnF6hhy%2Fzhidao%2Fpic%2Fitem%2Fcf1b9d16fdfaaf51fe8735738e5494eef11f7a91.jpg&refer=http%3A%2F%2Fgss0.baidu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651310380&t=e573892ae90f3b9b493d5005acf47514"
          alt=""
        />
      </div>
      <div className={style.goodsInDetail}>
        <div className={style.priceOfTheTitle}>现时抢购价88.00</div>
        <div className={style.inventoryInformation}>库存: 8单</div>
      </div>
      <div className={style.theCountdownActivity}>
        <div className={style.theCountdownToTheTitle}>活动倒计时</div>
        <div className={style.theCountdownActivity}>
          <div>00</div>
          <span>天</span>
          <div>00</div>
          <span>时</span>
          <div>00</div>
          <span>分</span>
          <div>00</div>
          <span>秒</span>
        </div>
      </div>
      <div className={style.theReservationNumber}>
        <div>已有 10021 人预约</div>
      </div>
      <div>
        <Carousel
          autoPlay
          loop
          autoPlayIntervalTime={6000}
          showPagination={false}
          direction="left"
          onChangeEnd={(index) => {
            console.log(`onChangeEnd: ${index}`)
          }}
        >
          <div className={style.byChildren}>
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
          </div>
          <div className={style.byChildren}>
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
            <img
              src="https://img2.baidu.com/it/u=2380536699,452586890&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400"
              alt=""
            />
          </div>
        </Carousel>
      </div>
      <div className={style.participation}>活动参与人数: 1008569</div>
      <div className={style.headerImage}>
        <img
          src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fgss0.baidu.com%2F-vo3dSag_xI4khGko9WTAnF6hhy%2Fzhidao%2Fpic%2Fitem%2Fcf1b9d16fdfaaf51fe8735738e5494eef11f7a91.jpg&refer=http%3A%2F%2Fgss0.baidu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651310380&t=e573892ae90f3b9b493d5005acf47514"
          alt=""
        />
      </div>
      <div className={style.getTheInformation}>
        <Cell
          title={
            <div style={{ color: '#a1a1a1' }}>
              <div>标题文字</div>
              <div>描述文字</div>
            </div>
          }
          description={<div style={{ color: 'red' }}>+31.21</div>}
          icon={
            <img
              alt=""
              src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fgss0.baidu.com%2F-vo3dSag_xI4khGko9WTAnF6hhy%2Fzhidao%2Fpic%2Fitem%2Fcf1b9d16fdfaaf51fe8735738e5494eef11f7a91.jpg&refer=http%3A%2F%2Fgss0.baidu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651310380&t=e573892ae90f3b9b493d5005acf47514"
              style={{ width: 48, height: 48, borderRadius: '50%' }}
            />
          }
          onClick={() => {}}
        />
        <Cell
          title={
            <div style={{ color: '#a1a1a1' }}>
              <div>标题文字</div>
              <div>描述文字</div>
            </div>
          }
          description={<div style={{ color: 'red' }}>+31.21</div>}
          icon={
            <img
              alt=""
              src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fgss0.baidu.com%2F-vo3dSag_xI4khGko9WTAnF6hhy%2Fzhidao%2Fpic%2Fitem%2Fcf1b9d16fdfaaf51fe8735738e5494eef11f7a91.jpg&refer=http%3A%2F%2Fgss0.baidu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651310380&t=e573892ae90f3b9b493d5005acf47514"
              style={{ width: 48, height: 48, borderRadius: '50%' }}
            />
          }
          onClick={() => {}}
        />
        <Cell
          title={
            <div style={{ color: '#a1a1a1' }}>
              <div>标题文字</div>
              <div>描述文字</div>
            </div>
          }
          description={<div style={{ color: 'red' }}>+31.21</div>}
          icon={
            <img
              alt=""
              src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fgss0.baidu.com%2F-vo3dSag_xI4khGko9WTAnF6hhy%2Fzhidao%2Fpic%2Fitem%2Fcf1b9d16fdfaaf51fe8735738e5494eef11f7a91.jpg&refer=http%3A%2F%2Fgss0.baidu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651310380&t=e573892ae90f3b9b493d5005acf47514"
              style={{ width: 48, height: 48, borderRadius: '50%' }}
            />
          }
          onClick={() => {}}
        />
      </div>

      <div className={style.theRightSideOfTheFixedFunction}>
        <div
          className={style.aCustomerServicePhone}
          style={{ background: '#f36b2b' }}
        >
          <div className={style.icon}>
            <img src={DHImages} alt="" />
          </div>
          <div className={style.customerServiceTelephone}>客服电话</div>
        </div>
        <div
          className={style.aCustomerServicePhone}
          style={{ background: 'rgb(43 151 243)' }}
        >
          <div className={style.icon}>
            <img src={KFImages} alt="" />
          </div>
          <div className={style.customerServiceTelephone}>活动咨询</div>
        </div>
        <div
          className={style.aCustomerServicePhone}
          style={{ background: 'hsl(156deg 100% 33%)' }}
        >
          <div className={style.icon}>
            <img src={TJImages} alt="" />
          </div>
          <div className={style.customerServiceTelephone}>创建活动</div>
        </div>
      </div>

      <div className={style.suspendedButtonAtTheBottomOfThe}>
        <div>分享领取红包</div>
        <div className={style.endOfWords}>活动已结束</div>
      </div>
    </div>
  )
}
