import React from "react";
import { InfoReportWidget } from "../../widgets/info-report-widget/info-report-widget";
import { BangBangTangLayout } from "../../layout/layout";
import "./info-report.css";
import { observer } from "mobx-react";
import { store } from "../../store/store.root";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";

@observer
export class InfoReport extends React.Component<any, any> {
  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      window.document.title = "投诉建议";
    });
  }

  render() {
    return (
      <BangBangTangLayout>
        <div className="info-report">
          <InfoReportWidget />
        </div>
      </BangBangTangLayout>
    );
  }
}
