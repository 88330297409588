import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Area } from "react-vant";
import { Button, Cell, Input, Modal, Popup, Toast } from "zarm";
import { FetchRequest } from "../../utils/fetch";
import style from "./index.module.scss";
import { areaList } from "@vant/area-data";

export default withRouter((props: any) => {
  const id = props.match.params.id;

  useEffect(() => {
    GetGifData();
  }, []);

  const [data, setData] = useState<{
    address: {
      address: string;
      consignee_mobile: string;
      consignee: string;
    };
    coupons?: {
      writeoff_count: number;
      rate_count: string;
      validity: string;
      remark: string;
      shop_image: string[];
    };
    cat_id?: number;
    cat_name?: string;
    coupon_id?: number;
    create_time?: number;
    create_time_text?: string;
    detail_image?: string[];
    exchange_count?: number;
    exchange_limit?: number;
    gift_intro?: string[];
    gift_name?: string;
    heats?: number;
    id?: number;
    points?: number;
    if_delivery?: "yes" | "no";
    show_image?: string;
    stock?: number;
    user?: { total_point: number };
    viewtimes?: number;
  }>({
    address: {
      address: "",
      consignee_mobile: "",
      consignee: "",
    },
  });

  async function GetGifData() {
    let result = await FetchRequest(
      `/wap/marketing/transfermall/Goods/getGoods?token=${localStorage.getItem(
        "token"
      )}&gift_id=${id}`,
      {},
      "GET"
    );
    if (result.code === 1) {
      setData(result.data);
      if ((result.data.address.address as string).split("/")) {
        setReceiving(
          (result.data.address.address as string).split("/")[0] || ""
        );
      }
    } else {
      Toast.show(result.msg);
    }
  }

  const [addressState, setAddressState] = useState(false);

  const [AreaState, setAreaState] = useState(false);

  const [Receiving, setReceiving] = useState("");

  function handleClickSubForn() {
    // 判断积分是否够
    if (data.points! > data.user!.total_point) {
      Toast.show("积分不足");
      return;
    }
    // 判断是否为 实体
    if (data.if_delivery === "yes") {
      // 录入收货地址
      setAddressState(true);
    } else {
      // 确认框
      const modal = Modal.confirm({
        title: "确认信息",
        content: "确认兑换后可在个人中心得【我的卡券】内查看",
        onCancel: () => {
          console.log("点击cancel");
        },
        onOk: () => {
          // 发送请求
          handSub();
        },
      });
    }
  }

  async function handSub() {
    let params = {
      token: localStorage.getItem("token"),
      gift_id: id,
    };
    if (data.if_delivery === "yes") {
      if (
        data.address.address &&
        data.address.consignee &&
        data.address.consignee_mobile &&
        Receiving !== ""
      ) {
        params = Object.assign(params, {
          ...data.address,
          address: Receiving + data.address.address,
        });
      } else {
        Toast.show("信息请填写完整");
        return;
      }
    } else {
      // 不需要
    }
    let result = await FetchRequest(
      "/wap/marketing/transfermall/Goods/exchangeGoods",
      params
    );
    if (result.code === 1) {
    } else {
    }
    setAddressState(false);
    Toast.show(result.msg);
  }

  return (
    <div>
      {/*  */}
      <Modal
        visible={addressState}
        maskClosable
        onCancel={() => setAddressState(false)}
      >
        <Cell title="收货地址" onClick={() => setAreaState(true)}>
          <Input
            clearable
            type="text"
            placeholder="请输入详细收货地址"
            value={Receiving.split("/")[0]}
          />
        </Cell>
        <Cell title="详细收货地址">
          <Input
            clearable
            type="text"
            placeholder="请输入详细收货地址"
            defaultValue={data.address.address}
            onChange={(e: any) => {
              setData({
                ...data,
                address: {
                  ...data.address,
                  address: e,
                },
              });
            }}
          />
        </Cell>
        <Cell title="收货人姓名">
          <Input
            clearable
            type="text"
            placeholder="请输入收货人姓名"
            defaultValue={data.address.consignee}
            onChange={(e: any) => {
              setData({
                ...data,
                address: {
                  ...data.address,
                  consignee: e,
                },
              });
            }}
          />
        </Cell>
        <Cell title="收货人手机">
          <Input
            clearable
            type="number"
            placeholder="请输入收货人手机"
            defaultValue={data.address.consignee_mobile}
            onChange={(e: any) => {
              setData({
                ...data,
                address: {
                  ...data.address,
                  consignee_mobile: e,
                },
              });
            }}
          />
        </Cell>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{ width: "49%" }}
            onClick={() => setAddressState(false)}
          >
            取消
          </Button>
          <Button style={{ width: "49%" }} theme="primary" onClick={handSub}>
            确认
          </Button>
        </div>
      </Modal>
      {/* 图 */}
      <img src={data.show_image} alt="" style={{ width: "100%" }} />
      {/* 积分 */}
      <div className={style.integral}>
        <div className={style.integral_row}>
          <span className={style.integral_number}>{data.points}</span>
          <span className={style.integral_text}>积分</span>
        </div>
      </div>
      {/* 商品信息 */}
      <div className={style.info}>
        <div className={style.title}>{data.gift_name}</div>
        <div className={style.info_cell}>
          <div className={style.cell}>
            {data.gift_intro?.map((v, index) => {
              return (
                <div key={index} className={style.left_text}>
                  {v}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* 详情图 */}
      <div className={style.detail_images}>
      {data.if_delivery === "yes" && (
          <div>
            <div className={style.desc_images}>
              {data.detail_image?.map((v, index) => {
                return (
                  <img key={index} src={v} alt="" style={{ width: "100%" }} />
                );
              })}
            </div>
          </div>
        )}

        {data.if_delivery === "no" && (
          <div>
            <div className={style.detaile_list}>
              <div className={style.taile_title}>使用要求:</div>
              <div>
                <div className="u-line-1" style={{ display: "flex" }}>
                  <span className={style.lable_text}>使用次数:</span>
                  {data.coupons?.writeoff_count}
                </div>
                {data.coupons?.rate_count !== "无限制" && (
                  <div className="u-line-1" style={{ display: "flex" }}>
                    <span className={style.lable_text}>使用频率:</span>
                    {data.coupons?.rate_count}
                  </div>
                )}
                <div className="u-line-1" style={{ display: "flex" }}>
                  <span className={style.lable_text}>有效期:</span>
                  {data.coupons?.validity}
                </div>
                <div className="remark" style={{ display: "flex" }}>
                  <span className={style.lable_text}>使用须知:</span>
                  <div style={{ width: "77%" }}>{data.coupons?.remark}</div>
                </div>
              </div>
            </div>

            <div className="desc_images">
              {data.coupons?.shop_image.map((v, index) => {
                return (
                  <img key={index} src={v} alt="" style={{ width: "100%" }} />
                );
              })}
            </div>
          </div>
        )}
      </div>
      {/* 底部结算 */}
      <div className={style.settlement}>
        <div style={{ fontSize: 14 }}>
          我的积分:{" "}
          <span style={{ width: "red" }}>{data.user?.total_point}</span>
        </div>
        <Button
          className={style.btn_sub}
          theme="primary"
          size="sm"
          onClick={handleClickSubForn}
        >
          立即兑换
        </Button>
      </div>
      {/* 省市区 */}
      <Popup
        visible={AreaState}
        direction="bottom"
        width="100%"
        afterClose={() => setAreaState(false)}
      >
        <div style={{ background: "#fff", padding: 10 }}>
          <Area
            title="收货地址"
            areaList={areaList}
            onConfirm={(e) => {
              let addr = [];
              for (let i = 0; i < e.length; i++) {
                const element = e[i];
                addr.push(element.name);
              }
              setReceiving(addr.join("") + "/");
              setAreaState(false);
            }}
            onCancel={() => {
              setAreaState(false);
            }}
          />
        </div>
      </Popup>
    </div>
  );
});
