import React from "react";
import "./photographic-plate-download.component.css";
import { IPhotographicPlate } from "../../store/photographic-plate.store";
import format from "date-fns/format";
import classNames from "classnames";
import { Button, Cell, Modal, Toast } from "zarm";
import { AliIcon } from "../../layout/bangbangtang-tab-bar";
import { FetchRequest } from "../../utils/fetch";
import CopyToClipboard from "react-copy-to-clipboard";
import XlImages from "../../assets/xl.png"
import { store } from "../../store/store.root";
import DownImages from "../../assets/down.png";

interface State {
  display_modal: boolean;
  Desc: string;
  copyMoadl: boolean;
}

export class PhotographicPlateDownloadComponent extends React.Component<
  {
    data: IPhotographicPlate;
    onCardSelected?: () => void;
    onMultiDownloadClicked?: () => void;
    onSingleDownloadClicked?: () => void;
    className?: string;
  },
  State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      display_modal: false,
      Desc: "",
      copyMoadl: false
    };
  }


  componentDidMount() {
    this.getExchangeDescribtion()
  }

  getExchangeDescribtion = async () => {
    let DescribtionRes = await FetchRequest("/wap/plate/getExchangeDescribtion", {
      token: localStorage.getItem("token"),
    })
    if (DescribtionRes.code === 1) {
      this.setState({ Desc: DescribtionRes.data })
    } else {
      Toast.show('获取弹窗说明失败')
    }
  }
  render() {
    if (this.props.data.activity_name) {
      return (
        <div
          className={classNames([
            "photographic-plate-download",
            this.props.className,
          ])}
        >
          <Cell
            style={{
              width: "100%",
            }}
            title={
              <div className="box">
                <div className="box-title" style={{ color: '#838383' }}>
                  活动名称: {this.props.data.activity_name}
                </div>
                <div className="box-description" style={{ color: '#838383' }}>
                  宝宝姓名: {this.props.data.baby_name}
                </div>
                <div className="box-description" style={{ color: 'red' }}>
                  {this.props.data.lasttime} 此时间后系统将自动删除底片
                </div>
              </div>
            }
            icon={
              // <div>
              //   <img src={DownImages} alt="" style={{ width: 45 }} />
              //   <div style={{ fontSize: 12 }}>点击下载</div>
              // </div>
              <img src={XlImages} alt="" style={{ width: 45 }} />
            }
            onClick={() => {
              if (localStorage.getItem("subscribe") === "0") {
                const modal = Modal.confirm({
                  title: '确认信息',
                  content: (
                    <div>
                      您还未关注公众号
                      <img src={localStorage.getItem("follow_qr") || ""} alt="" style={{ width: '100%' }} />
                    </div>
                  ),
                  onCancel: () => {
                    console.log('点击cancel');
                  },
                  okText: (
                    <span style={{ color: "#00bc70" }}>已关注</span>
                  ),
                  onOk: () => {
                    localStorage.clear();
                    window.location.replace(window.location.pathname + window.location.search)
                  },
                });
              } else {
                store.routerStore.push(`/build/film-download?name=${this.props.data.baby_name || this.props.data.activity_name}`)
                // this.setState({ display_modal: !this.state.display_modal });
              }
            }}
          />
        </div>
      );
    } else {
      return (
        <div
          className={classNames([
            "photographic-plate-download",
            this.props.className,
          ])}
        >
          <Cell
            style={{
              width: "100%",
            }}
            title={
              <div className="box">
                <div className="box-title">
                  客户姓名: {this.props.data.username}
                </div>
                <div className="box-description">
                  上传时间: {this.props.data.create_time}
                </div>
              </div>
            }
            icon={
              <div>
                <img src={DownImages} alt="" style={{ width: 45 }} />
                <div style={{ fontSize: 12 }}>点击下载</div>
              </div>
              // <AliIcon type="iconpicture" style={{ fontSize: "3em" }} />
            }
            onClick={() => {
              if (localStorage.getItem("subscribe") === "0") {
                const modal = Modal.confirm({
                  title: '确认信息',
                  content: (
                    <div>
                      您还未关注公众号
                      <img src={localStorage.getItem("follow_qr") || ""} alt="" style={{ width: '100%' }} />
                    </div>
                  ),
                  onCancel: () => {
                    console.log('点击cancel');
                  },
                  okText: (
                    <span style={{ color: "#00bc70" }}>已关注</span>
                  ),
                  onOk: () => {
                    localStorage.clear();
                    window.location.replace(window.location.pathname + window.location.search)
                  },
                });
              } else {
                this.setState({ display_modal: !this.state.display_modal });
              }
            }}
          />
          <Modal
            title={"底片下载"}
            visible={this.state.display_modal}
            maskClosable={true}
            width={"75%"}
            onCancel={() => this.setState({ display_modal: false })}
            closable={true}
          >
            <div >
              <p style={{ paddingBottom: 15 }} dangerouslySetInnerHTML={{ __html: this.state.Desc }}></p>
              <div className="button-row-down">
                <Button block theme="primary" onClick={() => window.open(this.props.data.url)}>单张下载</Button>
                <Button block onClick={() => this.setState({ copyMoadl: true })}>批量下载</Button>
              </div>
            </div>
          </Modal>
          {/* 批量删除弹窗 */}
          <Modal
            title={"批量下载"}
            visible={this.state.copyMoadl}
            maskClosable={true}
            width={"80%"}
            onCancel={() => this.setState({ copyMoadl: false })}
            closable={true}
          >
            <div className="photographic-plate-download-modal">
              <p>请复制云盘地址通过电脑浏览器打开</p>
              <p>可进行批量操作</p>
              <p>云盘地址:</p>
              <p id="url">{this.props.data.url}</p>
              <CopyToClipboard
                text={this.props.data.url!}
                onCopy={() => {
                  Toast.show("复制成功")
                  this.setState({
                    copyMoadl: false,
                    display_modal: false
                  })
                }}
              >
                <Button theme="primary" className="button">
                  点击复制
                </Button>
              </CopyToClipboard>
            </div>
          </Modal>
        </div>
      );
    }
  }
}
