import { Icon, Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { BangBangTangRequest } from "../../utils/request";
import style from "./index.module.scss";
import { store } from "../../store/store.root";

function EmployeeCenter(props: any) {
  // 存放 考试列表
  const [TestList, setTestList] = useState([]);
  // 存放 用户考试信息
  const [TestInfo, setTestInfo] = useState({
    totalSubjct: 0,
    passSubjct: 0,
  });
  // 存放基础培训详情
  const [trainInfo, setTrainInfo] = useState({
    id: null,
    document: "",
  });
  // subject_id
  const [subjectId, setSubject_id] = useState<number>(0);
  useEffect(() => {
    // 设置标题
    document.title = "员工中心";
    // 获取数据
    getBasicTrainingList();
    // 监听返回
    // window.addEventListener("popstate", function () {
    //     props.history.push('/build/person-center')
    // }, false);
    // return () => {
    //     window.removeEventListener("popstate", () => {
    //         props.history.push('/build/person-center')
    //     })
    // };
  }, []);

  /**
   * 获取基础培训列表
   */
  function getBasicTrainingList() {
    if (!localStorage.getItem("admin_store_id")) {
      window.history.back();
      return;
    }
    BangBangTangRequest({
      url: "/wap/exam/getBasicTraining",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        store_id: localStorage.getItem("admin_store_id"),
      },
    }).subscribe((result) => {
      setTestList(result.entrance);
      setTestInfo({
        passSubjct: result.passSubjct,
        totalSubjct: result.totalSubjct,
      });
      if (location.hash) {
        handleClickItem(parseInt(location.hash.replace("#", ""), 10));
      }
    });
  }

  /**
   * 开始考试
   */
  const handleClickItem = (subject_id: number) => {
    setSubject_id(subject_id);
    // 获取基础培训详情
    let formdata = new FormData();
    formdata.append("token", localStorage.getItem("token") || "");
    formdata.append("store_id", "1");
    formdata.append("subject_id", String(subject_id));
    fetch("/wap/exam/getBasicTrainingDocument", {
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.code === 1) {
          store.routerStore.replace(
            `${location.pathname}#${res.data.subject_id}`
          );
          setTrainInfo(res.data);
        } else {
          skipTestContent(subject_id);
        }
      });
  };

  /**
   * 跳转考试
   */
  function skipTestContent(subject_id: number) {
    // 跳转考试页
    props.history.push(`/build/test-examination?subject_id=${subject_id}`);
  }

  return (
    <div>
      {/* 搜索框 */}
      <div className={style.Search_warp}>
        <input className={style.Search_input} type="text" />
        <Icon type="search" className={style.Search_icon} />
      </div>
      {/* 列表 */}
      <div className={style.Test_content}>
        {TestList.map(
          (v: { subject: number; is_pass: number; subject_id: number }) => {
            return (
              <div
                className={style.Test_item}
                key={v.subject_id}
                onClick={() => handleClickItem(v.subject_id)}
              >
                <div className={style.Test_info}>
                  <div>科目名称: {v.subject}</div>
                </div>
                <div
                  className={`${style.Test_num} ${
                    v.is_pass === 0 ? style.Test_not_pass : ""
                  }`}
                >
                  <div className={style.Test_max_text}>
                    {v.is_pass === 0 ? "未合格" : "已合格"}
                  </div>
                </div>
              </div>
            );
          }
        )}
        {TestList.length === 0 && (
          <div className={style.Test_Text_null}>暂无考试~</div>
        )}
      </div>
      {/* 底部悬浮信息 */}
      <div className={style.Test_info_bottom}>
        <div>应考科目: {TestInfo.totalSubjct}</div>
        <div>已合格科目: {TestInfo.passSubjct}</div>
      </div>
      {/* 基础培训详情 */}
      {trainInfo.id && (
        <div className={style.Test_basics}>
          {/* <h4>{trainInfo.shop}</h4> */}
          <div
            className={style.Test_content}
            dangerouslySetInnerHTML={{ __html: trainInfo.document }}
          />
          <div style={{ height: 150 }} />
          <div className={style.Test_btn_examination}>
            <button
              onClick={() => {
                setTrainInfo({ id: null, document: "" });
                store.routerStore.replace(location.pathname);
              }}
              style={{ background: "#bfbfbf" }}
            >
              返回
            </button>
            <button
              onClick={() => skipTestContent(subjectId)}
              style={{ background: "#07c160", color: "#ffffff" }}
            >
              立即考试
            </button>
            <button onClick={() => props.history.push(`/build/test-records?subject_id=${subjectId}&type=subject_id`)}>查看错题</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(EmployeeCenter);
