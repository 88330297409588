import React from "react";
import { Button, Cell, Modal, Radio } from "zarm";

export class PreorderDetailFromEmployeeConfirmModal extends React.Component<
  {
    visible: boolean;
    onClick: (value: "已审核" | "未审核") => any;
    onCancel: () => any;
    default?: "已审核" | "未审核";
    loading: boolean;
  },
  {
    currentSelect: "已审核" | "未审核" | null;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { currentSelect: null };
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        title={"审核预约单"}
        width={"90%"}
        footer={
          <div className="modal-buttons">
            <Button
              onClick={() => this.props.onCancel()}
              disabled={this.props.loading}
            >
              取消
            </Button>
            <Button
              theme="primary"
              onClick={() => this.props.onClick(this.state.currentSelect!)}
              disabled={this.props.loading || !this.state.currentSelect}
            >
              确定
            </Button>
          </div>
        }
      >
        <Cell
          title={"选择状态"}
          description={
            <Radio.Group
              disabled={this.props.loading}
              type="button"
              value={this.state.currentSelect || this.props.default}
              onChange={(value) =>
                this.setState({ currentSelect: value as any })
              }
            >
              <Radio value="已审核">已审核</Radio>
              <Radio value="未审核">未审核</Radio>
            </Radio.Group>
          }
        />
      </Modal>
    );
  }
}
