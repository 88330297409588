import React, { useState } from 'react'
import './index.css'
import { IndexCarousel } from './components/index-carousel/index-carousel'
import { FunctionSelector } from './components/function-selector/function-selector'
import PhotographicPlateDownloadWidget from '../../widgets/photographic-plate-download-widget/photographic-plate-download-widget'
import { observer } from 'mobx-react'
import { store } from '../../store/store.root'
import { CloseRelationshipNumberWidget } from '../../widgets/close-relationship-number-widget/close-relationship-number-widget'
import { BangBangTangLayout } from '../../layout/layout'
import { VipCard } from '../../components/vip-card/vip-card'
import { InfoReportWidget } from '../../widgets/info-report-widget/info-report-widget'
import MakingArrangement from '../../components/MakingArrangement'
import { appIsFirstReadySubject } from '../../store/login-complete-subject'
import { CustomerQueryPreorderClotheWidget } from '../../widgets/customer-query-preorder-clothe-widget/customer-query-preorder-clothe-widget'
import { ModuleRequired } from '../../components/module-required/module-required'
import { FetchRequest } from '../../utils/fetch'
import { Modal, Toast } from 'zarm'
import Confirmation from '../confirmation-page/page'
import SelectionCard from '../selection/SelectionCard'
import SelectionCardXLQ from '../selection-activities/SelectionCard'
import { toJS } from 'mobx'

declare const window: any

@observer
export default class Index extends React.Component<any, any> {
  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      store.globalUiStore.changeCurrentActive('home')
      window.document.title = '首页'
      store.sliderShowStore.update()
      // store.userStore.getPageAuthInfo();
      this.handleOnChangShare()
    })
  }

  async handleOnChangShare() {
    let getShareInfo = await FetchRequest('/wap/plate/getShareInfo', {
      token: localStorage.getItem('token'),
    })

    if (getShareInfo.code === 1) {
      let SDKParams = await FetchRequest('/wap/wap2/getJsSdk', {
        currenturl: window.location.href,
      })
      if (SDKParams.code === 1) {
        const { wx }: any = window
        const {
          appId,
          nonceStr,
          signature,
          timestamp,
          jsApiList,
        } = SDKParams.data
        wx.config({
          debug: false, // 开启调试模式
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名
          jsApiList, // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-launch-weapp'],
        })

        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          wx.updateAppMessageShareData({
            title: getShareInfo.data.title, // 分享标题
            desc: getShareInfo.data.content, // 分享描述
            link: location.href, // getShareInfo.data.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: getShareInfo.data.image, // 分享图标
            success: function (res: any) {
              // 设置成功
              console.log('设置成功')
            },
          })
        })

        wx.error(function (res: any) {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          console.log(res, '<--res-')
        })
      } else {
        Toast.show('JsSdk获取失败')
      }
    } else {
      // Toast.show("分享参数获取失败")
    }
  }

  render() {
    function handleFilmdownload() {
      const d = toJS(store.websiteRuleStore.website_rule_module_all) || []
      const s = []
      for (let i = 0; i < d.length; i++) {
        const item = d[i]
        if (item.module_name === 'filmdownload') {
          if (item.module_status === 'open') {
            s.push('open')
          } else {
            s.push('close')
          }
        }
      }
      if (s.length === 1 && s[0] === 'close') {
        return false
      } else {
        return true
      }
    }

    return (
      <BangBangTangLayout showTabBar={true}>
        <div className="index">
          <IndexCarousel />
          <div className="divider" />
          {/* 菜单 */}
          <FunctionSelector />
          {/* 毕业季底片设计 */}
          <SelectionCard />
          {/* 笑脸墙 */}
          <SelectionCardXLQ />
          {/* 版面确认 */}
          <Confirmation />
          {/* 底片下载 */}
          <ModuleRequired name={'clothing'}>
            <CustomerQueryPreorderClotheWidget />
            <div className="divider" />
          </ModuleRequired>
          {handleFilmdownload() && (
            <PhotographicPlateDownloadWidget
              data={store.photographicPlateStore.data}
            />
          )}

          <div className="divider" />
          <CloseRelationshipNumberWidget display_whole={true} />
          {/* <div className="divider" />
          <VipCard /> */}
          <div className="divider" />
          <InfoReportWidget />
        </div>
      </BangBangTangLayout>
    )
  }
}
