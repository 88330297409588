import React from "react";
import "./layout.css";
import { store } from "../store/store.root";
import { observer } from "mobx-react";
import classNames from "classnames";
import { BangbangtangTabBar } from "./bangbangtang-tab-bar";
import {
  appIsFirstReadySubject,
  appIsReadySubject,
  pageChangedSubject,
} from "../store/login-complete-subject";
import { Subject, Subscription } from "rxjs";
import { Toast } from "zarm";

@observer
export class BangBangTangLayout extends React.Component<
  {
    maskLoading?: boolean;
    showTabBar?: boolean;
    hideMarginBottom?: boolean;
    title?: string;
  },
  { complete: boolean }
> {
  public sub: Subscription | null = null;
  constructor(props: any) {
    super(props);
    this.state = { complete: false };
  }
  componentWillUnmount() {
    pageChangedSubject.next();
    if (this.sub) this.sub.unsubscribe();
  }

  componentDidMount() {
    this.sub = appIsReadySubject.subscribe((value) =>
      this.setState({ complete: value })
    );
    if (this.props.title) {
      window.document.title = this.props.title;
    }
  }

  render() {
    if (!store.userStore.token) return null;
    if (!this.state.complete)
      return (
        <div className="page">
          <div className="loading iconfont">
            <span className="iconfont">&#xe7da;</span>
          </div>
        </div>
      );
    return (
      <div className="page">
        <div
          className={classNames({
            "content-with-padding": this.props.showTabBar,
          })}
        >
          {store.globalUiStore.pageLoading ? (
            <div className="loading iconfont">
              <span className="iconfont">&#xe7da;</span>
            </div>
          ) : (
            <div>
              {store.globalUiStore.maskLoading || this.props.maskLoading ? (
                <div className="loading iconfont">
                  <span className="iconfont">&#xe7da;</span>
                </div>
              ) : null}
              {this.props.children}
            </div>
          )}
        </div>
        <BangbangtangTabBar showTabBar={this.props.showTabBar || false} />
      </div>
    );
  }
}
