import { PhotographicPlateStore } from "./photographic-plate.store";
import { CloseRelationshipNumberStore } from "./close-relationship-number.store";
import { GlobalUiStore } from "./global-ui.store";
import { UserStore } from "./user.store";
import { PersonStore } from "./person.store";
import { PhotoFamilyCollectionStore } from "./photo-family-collection.store";
import { SliderShowStore } from "./slider-show.store";
import { RouterStore } from "@superwf/mobx-react-router";
import { PreorderScheduleStore } from "./preorder-schedule/preorder-schedule.store";
import { EmployeePreorderListStore } from "./employee-preorder-list/employee-preorder-list.store";

import { Contrarytest } from "./contrarytest";
import { StoreInfoStore } from "./store-info.store";
import { MyPreorderStore } from "./my-preorder.store";
import { clothingStore } from "./store-clothing.store";
import { WebsiteRuleStore } from "./website-rule/website-rule.store";
import { EmployeePreorderClotheStore } from "./empolyee-preorder-clothe/employee-preorder-clothe.store";
import { EmployeePreorderClotheListStore } from "./employee-preorder-clothe-list/employee-preorder-clothe-list.store";
import { CustomerPreorderClotheStore } from "./customer-preorder-clothe/customer-preorder-clothe.store";
import { CustomerPreorderClotheListStore } from "./customer-preorder-clothe-list/customer-preorder-clothe-list.store";
import { EmployeeQueryPreorderClotheSearchStore } from "./employee-query-preorder-clothe-search/employee-query-preorder-clothe-search.store";
import { SelectionActivities } from "./selection-activities";
export const store = {
  photographicPlateStore: new PhotographicPlateStore(),
  routerStore: new RouterStore(),
  Contrarytest: new Contrarytest(),
  closeRelationshipNumberStore: new CloseRelationshipNumberStore(),
  globalUiStore: new GlobalUiStore(),
  userStore: new UserStore(),
  personStore: new PersonStore(),
  photoFamilyCollection: new PhotoFamilyCollectionStore(),
  sliderShowStore: new SliderShowStore(),
  preorderScheduleStore: new PreorderScheduleStore(),
  employeePreorderListStore: new EmployeePreorderListStore(),
  storeInfoStore: new StoreInfoStore(),
  myPreorderStore: new MyPreorderStore(),
  clothingStore: new clothingStore(),
  websiteRuleStore: new WebsiteRuleStore(),
  employeePreorderClotheStore: new EmployeePreorderClotheStore(),
  customerPreorderClotheStore: new CustomerPreorderClotheStore(),
  employeePreorderClotheListStore: new EmployeePreorderClotheListStore(),
  customerPreorderClotheListStore: new CustomerPreorderClotheListStore(),
  employeePreorderClotheSearchStore: new EmployeeQueryPreorderClotheSearchStore(),
  SelectionActivities: new SelectionActivities()
  // bangbangtangRouterStore: new BangBangTangRouterStore(),
};
