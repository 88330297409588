import React, { useEffect, useState } from "react";
import { Button, Cell, Input, Toast } from "zarm";
import { FetchRequest } from "../../utils/fetch";
import { withRouter } from "react-router-dom"
import { store } from "../../store/store.root";

export default withRouter((props: any) => {
  useEffect(() => {
    document.title = "确认地址";
  }, []);

  const [value, setValue] = useState("");

  async function handlClickSubAddr() {
    if (value === "") {
      Toast.show("收货地址请填写完整");
      return;
    }
    const res = await FetchRequest("/wap/express/confirmAddress", {
      id: props.match.params.id,
      address: value,
    });

    if (res.code === 1) {
        setValue("")
        store.routerStore.replace("/build")
    }
    Toast.show(res.msg);
  }

  return (
    <div style={{ padding: "20px 0" }}>
      <Cell title="收货地址">
        <Input
          clearable
          type="text"
          placeholder="请输入收货地址"
          value={value}
          onChange={(e: any) => {
            setValue(e);
          }}
        />
      </Cell>
      <Cell>
        <Button theme="primary" disabled={value.length > 0 ? false : true} block onClick={handlClickSubAddr}>
          提交
        </Button>
      </Cell>
    </div>
  );
});
