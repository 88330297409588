import React from "react";
import { BangBangTangLayout } from "../../layout/layout";
import style from "./index.module.scss";
import { Tabs, ImagePreview, Button, Modal } from "zarm";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";
import { finalize } from "rxjs/operators";
import { RuleListRequired } from "../../components/module-required/rule-list-required";
const { Panel } = Tabs;

export class RectificationAuditDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reform_id: "",
      detail_type: 0,
      visible: false,
      reformVisible: false,
      pictureIndex: 0,
      reformIndex: 0,
      detail: {},
      images: [],
      reform_images: [],
      steps: [],
      result: "",
      resultModal: false,
      confirmModal: false,
      reform_status: 2,
      showBtn: true,
    };
  }

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
  };

  componentDidMount() {
    console.log(this.props);
    const {
      match: {
        params: { id, type },
      },
    } = this.props;
    this.setState(
      {
        reform_id: id,
        detail_type: type,
      },
      () => {
        this.getReformAdjustDetail(id);
        this.getSteps(id);
      }
    );
    window.document.title = "工作单详情";
  }

  getReformAdjustDetail = (id) => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getReformAdjustDetail",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        reform_id: id,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        that.setState({
          detail: result,
          images: result.images || [],
          reform_images: result.reform_images || [],
        });
      });
  };

  getSteps = (id) => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/getReformLog",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        reform_id: id,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        const journal = result.reverse();
        that.setState({
          steps: journal || [],
        });
      });
  };

  show = (index) => {
    this.setState({
      visible: true,
      pictureIndex: index,
    });
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  reformShow = (index) => {
    this.setState({
      reformVisible: true,
      reformIndex: index,
    });
  };

  reformHide = () => {
    this.setState({
      reformVisible: false,
    });
  };

  showConfirm = (type) => {
    this.setState({
      confirmModal: true,
      reform_status: type,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      confirmModal: false,
    });
  };

  submit = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/submitReformOnAdjust",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        reform_id: that.state.reform_id,
        reform_status: that.state.reform_status,
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        console.log(result);
        if (result == "审核成功") {
          that.setState({
            showBtn: false,
          });
          this.closeConfirmModal();
          this.showResult(result);
        }else{
          this.closeConfirmModal();
          this.showResult(result);
        }
      });
  };

  showResult = (result) => {
    this.setState({
      result: result,
      resultModal: true,
    });
  };

  closeResultModal = () => {
    this.setState({
      result: "",
      resultModal: false,
    });
  };

  revoke = () => {
    let that = this;
    BangBangTangRequest({
      url: "/wap/sanction/submitCloseOff",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        order_id: that.state.id,
        order_type: 3,
        store_id: localStorage.getItem("admin_store_id"),
      },
    })
      .pipe(finalize(() => store.globalUiStore.hideLoading()))
      .subscribe((result) => {
        this.showResult(result);
      });
  };

  AuthButPass = () => {
    if (store.websiteRuleStore.has_rule("sanction_reform_all")) {
      return (
        <div className="button">
          <Button
            style={{ width: "88px" }}
            theme="primary"
            size="sm"
            onClick={() => {
              this.showConfirm(2);
            }}
          >
            审核通过
          </Button>
        </div>
      )
    } else {
      if (store.websiteRuleStore.has_rule("sanction_reform_self") && this.state.detail.updater_id === store.userStore.admin_id) {
        return (
          <div className="button">
            <Button
              style={{ width: "88px" }}
              theme="primary"
              size="sm"
              onClick={() => {
                this.showConfirm(2);
              }}
            >
              审核通过
            </Button>
          </div>
        )
      }
    }
  }

  render() {
    const {
      detail_type,
      visible,
      reformVisible,
      pictureIndex,
      reformIndex,
      detail,
      images,
      reform_images,
      steps,
      resultModal,
      confirmModal,
      result,
      showBtn,
    } = this.state;
    return (
      <BangBangTangLayout title={"工作单详情"}>
        <div className={style.container}>
          <div className="detai-container">
            <div className="section">
              <div className="k">工单类别</div>
              <div className="v">{detail.type_name}</div>
            </div>
            <div className="section">
              <div className="k">工单描述</div>
              <div className="v">{detail.reason}</div>
            </div>
          </div>
          {images.length > 0 ? (
            <div className="photo-list">
              {images.map((pic, index) => (
                <div
                  className="picture-item"
                  onClick={() => this.show(index)}
                  key={+index}
                >
                  <img src={pic} />
                </div>
              ))}
            </div>
          ) : null}
          {images.length > 0 ? (
            <ImagePreview
              visible={visible}
              images={images}
              onClose={this.hide}
              activeIndex={pictureIndex}
            />
          ) : null}
          <div className="detai-container">
            <div className="section">
              <div className="k">扣除执行力</div>
              <div className="v">{detail.integral}</div>
            </div>
            <div className="section">
              <div className="k">处罚金额</div>
              <div className="v">{detail.money}</div>
            </div>
          </div>

          {steps.length > 0 ? (
            <div className="journal-steps step-vertical">
              {steps.map((item, index) => {
                return (
                  <div
                    className={
                      index == 0
                        ? "step-container step-doing"
                        : "step-container step-finished"
                    }
                    key={index}
                  >
                    <div className="step-line"></div>
                    <div className="step-dot">
                      <div className="dot"></div>
                    </div>
                    <div className="step-content">
                      <div className="content-section">
                        <div className="k">操作日志：</div>
                        <div className="v ellipsis ellipsis-3">{item.log}</div>
                      </div>
                      <div className="handle-section">
                        <div className="k">{item.createtime}</div>
                        <div className="v">{item.updater}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          <div className="detai-container">
            <div className="section">
              <div className="k">整改完成</div>
              <div className="v">{detail.reform_desc}</div>
            </div>
          </div>
          {reform_images.length > 0 ? (
            <div className="photo-list">
              {reform_images.map((pic, index) => (
                <div
                  className="picture-item"
                  onClick={() => this.reformShow(index)}
                  key={+index}
                >
                  <img src={pic} />
                </div>
              ))}
            </div>
          ) : null}
          {reform_images.length > 0 ? (
            <ImagePreview
              visible={reformVisible}
              images={reform_images}
              onClose={this.reformHide}
              activeIndex={reformIndex}
            />
          ) : null}
          {showBtn && detail_type != 0 ? (
            <div className="foot-option">
              <div className="button">
                <Button
                  style={{
                    borderColor: "#00bc71",
                    color: "#00bc71",
                    width: "88px",
                  }}
                  size="sm"
                  onClick={() => {
                    this.showConfirm(3);
                  }}
                >
                  重新整改
                </Button>
              </div>
              <RuleListRequired name={"sanction_reform_close"}>
                <div className="button">
                  <Button
                    style={{ width: "88px" }}
                    theme="danger"
                    size="sm"
                    onClick={this.revoke}
                  >
                    撤销
                  </Button>
                </div>
              </RuleListRequired>
              {detail_type != 2 && (
                <>{this.AuthButPass()}</>
              )}
            </div>
          ) : null}
        </div>

        <Modal
          className={style.detailModal}
          visible={resultModal}
          footer={
            <Button block className="sure" onClick={this.closeResultModal}>
              知道了
            </Button>
          }
        >
          <div className="t-c">{result}</div>
        </Modal>

        <Modal
          className={style.detailModal}
          visible={confirmModal}
          footer={
            <div className="confirmBtns t-c">
              <div className="btn" onClick={this.closeConfirmModal}>
                取消
              </div>
              <div className="btn" onClick={this.submit}>
                确认
              </div>
            </div>
          }
        >
          <div className="t-c">
            {this.state.reform_status == 2
              ? "确认审核通过？"
              : "确认重新整改？"}
          </div>
        </Modal>
      </BangBangTangLayout>
    );
  }
}
export default withRouter(RectificationAuditDetail);
