import { autorun, computed, makeAutoObservable, runInAction, toJS } from 'mobx'
import * as requests from './employee-preorder-clothe.request'
import {
  EmployeePreorderClotheDetail,
  EmployeePreorderClotheTag,
} from './employee-preorder-clothe.api'
import { PreorderClotheTagModalOptions } from '../../pages/employee-preorder-clothe/preorder-clothe-tag-modal'
import { BangBangTangRequest } from '../../utils/request'
import { store } from '../store.root'
import {
  EmployeePreorderClotheListDataInterface,
  EmployeePreorderClotheListResponse,
} from '../employee-preorder-clothe-list/employee-preorder-clothe-list.interface'
import { Modal, Toast } from 'zarm'
import { finalize } from 'rxjs/operators'
import * as _ from 'lodash'
import { List } from 'antd-mobile'
import { format, parse } from 'date-fns'
export interface EmployeePreorderClotheFlattedDataInterface {
  id: number
  clothingimages: string
  tags: number[]
}
const PHONE_RE = /^1[3456789]\d{9}$/

export class EmployeePreorderClotheStore {
  private _data: EmployeePreorderClotheDetail[] | null = null
  __data: EmployeePreorderClotheDetail[] = []
  schedule_type: { name: string; id: number }[] | undefined = undefined
  schedule_type_value: number | undefined = undefined
  set_schedule_type_value(v: number) {
    this.schedule_type_value = v
  }
  expire_time: number = 0
  raw_selected_options: Set<number> = new Set()
  gender_selected: Set<number> = new Set<number>()
  toggle_gender_selected(value: number) {
    if (this.gender_selected.has(value)) {
      this.gender_selected.delete(value)
    } else {
      this.gender_selected.add(value)
    }
  }
  clothe_type_selected: Set<number> = new Set<number>()
  toggle_clothe_type_selected(value: number) {
    if (this.clothe_type_selected.has(value)) {
      this.clothe_type_selected.delete(value)
    } else {
      this.clothe_type_selected.add(value)
    }
  }
  type_selected: Set<number> = new Set<number>()
  toggle_type_selected(value: number) {
    if (this.type_selected.has(value)) {
      this.type_selected.delete(value)
    } else {
      this.type_selected.add(value)
    }
  }

  color_type_selected: Set<number> = new Set<number>()
  valid_color_type_options: Set<number> = new Set<number>()
  toggle_color_type_selected(value: number) {
    if (this.color_type_selected.has(value)) {
      this.color_type_selected.delete(value)
    } else {
      this.color_type_selected.add(value)
    }
  }
  // end
  valid_style_type_options: Set<number> = new Set<number>()
  color_style_selected: Set<number> = new Set<number>()
  toggle_style_type_selected(value: number) {
    console.log(value, '<-----style----', this.color_style_selected.has(value))
    if (this.color_style_selected.has(value)) {
      this.color_style_selected.delete(value)
    } else {
      this.color_style_selected.add(value)
    }
  }

  current_displayed: number = 14
  loading: boolean = true
  popup_visible: boolean = false
  save_modal_visible: boolean = false
  share_modal_visible: boolean = false
  show_share_notice: boolean = true

  date: string = format(new Date(), 'yyyy-MM-dd')
  date_visible: boolean = false
  getScheduleType() {
    if (this.schedule_type === undefined) {
      BangBangTangRequest({
        url: '/wap/clothing/getScheduleType',
        method: 'POST',
        data: {},
      }).subscribe((value: any) =>
        runInAction(() => {
          this.schedule_type = value
        })
      )
    } else {
      return
    }
  }
  setDateVisible(v: boolean) {
    this.date_visible = v
  }
  setDate(v: string) {
    this.date = v
  }
  mobile: string = ''
  customer_name: string = ''
  limit: string = ''
  preorder_id: number | null = null
  status: 'uncommitted' | 'committed' | 'canceled' | 'expired' | null = null
  @computed get status_readable(): string {
    switch (this.status) {
      case 'canceled':
        return '已取消'
      case 'expired':
        return '已过期'
      case 'committed':
        return '已提交'
      case 'uncommitted':
        return '未提交'
      default:
        return ''
    }
  }
  toggle_share_notice(value: boolean) {
    this.show_share_notice = value
  }

  @computed get form_data_is_valid(): boolean {
    if (this.preorder_id) {
      return !!(parseInt(this.limit, 10) > 0 && this.schedule_type_value)
    } else {
      return !!(
        this.customer_name.length > 0 &&
        this.mobile.match(PHONE_RE) &&
        parseInt(this.limit, 10) > 0 &&
        this.schedule_type_value
      )
    }
  }
  set_preorder_id(value: number | null) {
    // 设置预约单id,会从服务器获取预约单的其他信息
    this.preorder_id = value
    this.loading = true

    BangBangTangRequest<EmployeePreorderClotheListDataInterface>({
      url: `/wap/clothing/getSelectionDetail?token=${store.userStore.token}&selection_id=${value}`,
      data: {},
      method: 'GET',
    }).subscribe((res) => {
      runInAction(() => {
        this.loading = false
        this.preorder_id = res.id
        this.mobile = res.mobile
        this.customer_name = res.username
        this.status = res.status as any
        this.expire_time = res.expire_time
        this.schedule_type_value = res.schedule_type
        this.date = format(new Date(res.schedule_date * 1000), 'yyyy-MM-dd')
        this.gender_selected.clear()
        this.type_selected.clear()
        this.color_style_selected.clear()
        res.gender_type_id_arr.forEach((v) => this.gender_selected.add(v))
        res.age_type_id_arr.forEach((v) => this.type_selected.add(v))
        res.color_type_id_arr.forEach((v) => this.color_style_selected.add(v))
        res.selection_standard_ids
          .split(',')
          .filter((value) => !!value)
          .map((value) => parseInt(value, 10))
          .forEach((value) => this.selected_photos.add(value))
        res.typeids
          .split(',')
          .forEach((type) => this.raw_selected_options.add(parseInt(type, 10)))
        this.limit = res.standard_num_limit.toString()
      })
    })
  }
  set_mobile(value: string) {
    this.mobile = value
  }
  set_customer_name(value: string) {
    this.customer_name = value
  }
  set_limit(value: string) {
    this.limit = value
  }

  submit_loading: boolean = false
  photo_modal_visible: boolean = false
  selected_photos: Set<number> = new Set<number>()
  @computed get selected_photos_flatten(): { id: number; pic: string[] }[] {
    return Array.from(this.selected_photos).map((value) => ({
      id: value,
      pic: this._data?.find((v) => v.id === value)?.clothingimages || [],
    }))
  }
  toggle_photo_modal_visible(value: boolean) {
    if (this.selected_photos.size === 0) {
      Toast.show('用户暂时没有选择任何套片')
    } else {
      this.photo_modal_visible = value
    }
  }
  onlySaveTags() {
    if (this.preorder_id) {
      // 修改
      BangBangTangRequest({
        url: '/wap/clothing/updateClothingSelection',
        method: 'POST',
        data: {
          token: store.userStore.token,
          selection_id: this.preorder_id,
          tag_type: Array.from(
            new Set([
              ...Array.from(this.type_selected),
              ...Array.from(this.gender_selected),
              ...Array.from(this.color_style_selected),
            ])
          ).join(','),
        },
      })
        .pipe(finalize(() => runInAction(() => (this.submit_loading = false))))
        .subscribe(() => {
          runInAction(() => {
            this.toggle_popup_visible(false)
            alert('保存成功')
          })
        })
    } else {
      this.toggle_save_modal_visible(true)
    }
  }
  toggle_popup_visible(value: boolean) {
    this.popup_visible = value
  }
  toggle_save_modal_visible(value: boolean) {
    this.save_modal_visible = value
  }
  @computed get type_clothes(): { tag_id: number; data_id: number[] }[] {
    let types: { tag_id: number; data_id: number[] }[] = []
    this.options.forEach((option) => {
      option.types.forEach((type) =>
        types.push({ tag_id: type.id, data_id: [] })
      )
    })
    if (this._data) {
      this._data.forEach((data) => {
        data.tags.forEach((tag) =>
          tag.type.forEach((type) => {
            types.find((v) => v.tag_id === type.id)?.data_id.push(data.id)
          })
        )
      })
    }
    return types
  }
  @computed get options(): PreorderClotheTagModalOptions {
    let type_obj: { id: number; name: string; is_show: 'yes' | 'no' }[] = []
    let tag_obj: {
      id: number
      name: string
      is_user_search: 'yes' | 'no'
      type: Set<number>
    }[] = []
    if (this._data) {
      this._data.forEach((value) => {
        value.tags.forEach((tag) => {
          tag.type.forEach((type) => {
            if (type_obj.find((v) => v.id === type.id)) {
            } else {
              type_obj.push({
                id: type.id,
                name: type.name,
                is_show: type.is_show,
              })
            }
          })
          const i = tag_obj.find((v) => v.id === tag.id)
          if (i) {
            tag.type.forEach((v) => i.type.add(v.id))
          } else {
            tag_obj.push({
              id: tag.id,
              name: tag.name,
              is_user_search: tag.is_user_search,
              type: new Set<number>(tag.type.map((v) => v.id)),
            })
          }
        })
      })
      return tag_obj
        .map((value) => ({
          id: value.id,
          name: value.name,
          is_user_search: value.is_user_search,
          types: Array.from(value.type).map(
            (v) => type_obj.find((type) => type.id === v)!
          ),
        }))
        .filter(
          (value) =>
            value.name === '年龄' ||
            value.name === '性别' ||
            value.name === '尺码' ||
            value.name === '色系' ||
            value.name === '风格'
        )
    } else {
      return []
    }
  }
  @computed private get filterData(): EmployeePreorderClotheDetail[] {
    const age_options = this.options.find((value) => value.name === '年龄')
    const gender_options = this.options.find((value) => value.name === '性别')
    const style_options = this.options.find((value) => value.name === '风格')
    const valid_age_dataset: Set<number> = new Set<number>()
    const valid_gender_dataset: Set<number> = new Set<number>()
    if (age_options && gender_options) {
    } else {
      return []
    }
    const valid_age_options: Set<number> = new Set<number>()
    const valid_gender_options: Set<number> = new Set<number>()
    const valid_style_options: Set<number> = new Set<number>()
    if (this.type_selected.size === 0) {
      age_options.types.forEach((v) => valid_age_options.add(v.id))
    } else {
      this.type_selected.forEach((v) => valid_age_options.add(v))
    }
    if (this.gender_selected.size === 0) {
      gender_options.types.forEach((v) => valid_gender_options.add(v.id))
    } else {
      this.gender_selected.forEach((v) => valid_gender_options.add(v))
    }
    if (this.color_style_selected.size === 0) {
      style_options?.types.forEach((v) => valid_style_options.add(v.id))
    } else {
      this.color_style_selected.forEach((v) => valid_style_options.add(v))
    }
    this.type_clothes?.forEach((value) => {
      if (valid_age_options.has(value.tag_id)) {
        value.data_id.forEach((v) => valid_age_dataset.add(v))
      }
      if (valid_gender_options.has(value.tag_id)) {
        value.data_id.forEach((v) => valid_gender_dataset.add(v))
      }
    })
    const intersection: Set<number> = new Set(
      _.intersection(
        Array.from(valid_age_dataset),
        Array.from(valid_gender_dataset)
      )
    )
    return this._data?.filter((v) => intersection.has(v.id)) || []
    // this.options.forEach((tag) => {
    //   const intersection = _.intersection<number>(
    //     tag.types.map((v) => v.id),
    //     Array.from(
    //       new Set([
    //         ...Array.from(this.gender_selected),
    //         ...Array.from(this.type_selected),
    //       ])
    //     )
    //   );
    //   if (intersection.length === 0) {
    //     valid_options.set(
    //       tag.id,
    //       tag.types.map((v) => v.id)
    //     );
    //   } else {
    //     valid_options.set(tag.id, intersection);
    //   }
    // });
    // _.intersection<number>(
    //   ...Array.from(valid_options.keys())
    //     .map((key) => valid_options.get(key)!) // all type
    //     .map((types) =>
    //       _.flatten(
    //         types.map(
    //           (type) =>
    //             this.type_clothes.find((v) => v.tag_id === type)!.data_id
    //         )
    //       )
    //     )
    // ).forEach((v) => filterDataSet.add(v));
    // console.log(filterDataSet);

    // if (this._data) {
    //   this._data.forEach((v) => console.log(toJS(v)));
    //   return this._data.filter((value) => {
    //     return filterDataSet.has(value.id);
    //   });
    // } else {
    //   return [];
    // }
  }
  show_large: number | null = null
  toggle_show_large(value: number | null) {
    this.show_large = value
  }
  @computed get data(): EmployeePreorderClotheDetail[] {
    return this.filterData.slice(0, this.current_displayed)
  }
  constructor() {
    makeAutoObservable(this)
  }
  @computed get isEmpty(): boolean {
    return this._data === null
  }
  loadMore = () => {
    this.current_displayed += 14
  }
  employeeGetPreorderClotheData() {
    this.loading = true
    requests.EmployeeGetPreorderClothe().subscribe((value) => {
      runInAction(() => {
        this._data = value
        this.__data = value
        this.loading = false
      })
    })
  }

  clear_options() {
    this.gender_selected.clear()
    this.type_selected.clear()
    this.color_style_selected.clear()
    this.current_displayed = 14
  }
  submit() {
    this.submit_loading = true
    if (this.preorder_id) {
      // 修改
      BangBangTangRequest({
        url: '/wap/clothing/updateClothingSelection',
        method: 'POST',
        data: {
          token: store.userStore.token,
          tag_type: Array.from(
            new Set([
              ...Array.from(this.type_selected),
              ...Array.from(this.gender_selected),
              ...Array.from(this.color_style_selected),
            ])
          ).join(','),
          limit: this.limit,
          selection_id: this.preorder_id,
          schedule_type: this.schedule_type_value,
        },
      })
        .pipe(finalize(() => runInAction(() => (this.submit_loading = false))))
        .subscribe(() => {
          runInAction(() => {
            this.submit_loading = false
            this.toggle_save_modal_visible(false)
            alert('保存成功')
          })
        })
    } else {
      // 创建
      BangBangTangRequest({
        url: '/wap/clothing/createClothingSelection',
        method: 'POST',
        data: {
          token: store.userStore.token,
          tag_type: Array.from(
            new Set([
              ...Array.from(this.type_selected),
              ...Array.from(this.gender_selected),
              ...Array.from(this.color_style_selected),
            ])
          ),
          username: this.customer_name,
          mobile: this.mobile,
          limit: this.limit,
          schedule_type: this.schedule_type_value,
          schedule_date:
            parse(this.date, 'yyyy-MM-dd', new Date()).getTime() / 1000,
        },
      })
        .pipe(finalize(() => runInAction(() => (this.submit_loading = false))))
        .subscribe((res) =>
          runInAction(() => {
            this.clearOtherData()
            store.routerStore.replace(
              '/qiye/employee-preorder-clothe-brief-detail?id=' +
                res.selection_id
            )
            this.set_preorder_id(res.selection_id)
          })
        )
    }
  }

  // 清除不必要的信息,一般用于页面退出时使用
  clearOtherData() {
    this.customer_name = ''
    this.preorder_id = null
    this.type_selected.clear()
    this.gender_selected.clear()
    this.color_style_selected.clear()
    this.mobile = ''
    this.limit = ''
    this.share_modal_visible = false
    this.save_modal_visible = false
    this.popup_visible = false
    this.submit_loading = false
    this.selected_photos.clear()
    this.photo_modal_visible = false
  }
  toggle_share_modal(value: boolean) {
    this.share_modal_visible = value
  }
  restart() {
    BangBangTangRequest({
      url: '/wap/clothing/resetSelection',
      method: 'POST',
      data: { token: store.userStore.token, selection_id: this.preorder_id },
    }).subscribe(() => window.location.reload())
  }

  cancel_preorder() {
    Modal.confirm({
      title: '警告',
      content: '是否确定取消预选单',
      onOk: () => {
        this.loading = true
        BangBangTangRequest({
          url: '/wap/clothing/staffCancelSelection',
          data: {
            token: store.userStore.token,
            selection_id: this.preorder_id,
          },
          method: 'POST',
        })
          .pipe(finalize(() => runInAction(() => (this.loading = false))))
          .subscribe((value) => {
            this.loading = false
            alert('取消成功')
            store.employeePreorderClotheListStore.reset()
            store.routerStore.replace(
              '/qiye/employee-query-preorder-clothe-list'
            )
          })
      },
    })
  }
}
