import React from "react";
import "./photographic-plate-download-widget.css";
import { IPhotographicPlate } from "../../store/photographic-plate.store";
import { PhotographicPlateDownloadComponent } from "../../components/photographic-plate-download/photographic-plate-download.component";
import { FunctionalComponentHeader } from "../../pages/index/components/functional-component-header/functional-component-header";
import { store } from "../../store/store.root";
import { Button, Modal, Cell } from "zarm";
import CopyToClipboard from "react-copy-to-clipboard";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { appIsFirstReadySubject } from "../../store/login-complete-subject";
import { AliIcon } from "../../layout/bangbangtang-tab-bar";

interface State {
  display_whole: boolean;
  current_selected?: number;
  modal_visible_url: string;
}

interface Iprops extends RouteComponentProps {
  data: IPhotographicPlate[];
}

@observer
class PhotographicPlateDownloadWidget extends React.Component<Iprops, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      modal_visible_url: "",
      display_whole: true,
    };
  }

  componentDidMount() {
    appIsFirstReadySubject.subscribe(() => {
      store.photographicPlateStore.update();
    });
  }

  handleClickAttention(e: any) {
    if (localStorage.getItem("subscribe") === "0") {
      const modal = Modal.confirm({
        title: '确认信息',
        content: (
          <div>
            您还未关注公众号
            <img src={localStorage.getItem("follow_qr") || ""} alt="" style={{ width: '100%' }} />
          </div>
        ),
        onCancel: () => {
          console.log('点击cancel');
        },
        okText: (
          <span style={{ color: "#00bc70" }}>已关注</span>
        ),
        onOk: () => {
          localStorage.clear();
          window.location.replace(window.location.pathname + window.location.search)
        },
      });
      e.preventDefault()
      return false
    }
  }
  render() {
    if (store.photographicPlateStore.data.length === 0) return null;
    return (
      <div className="photographic-plate-download-widget">
        {/* <FunctionalComponentHeader
          title={"底片下载"}
          more={this.state.display_whole ? "更少" : "更多"}
          onMoreClicked={this.more}
        /> */}
        <Cell
          title="底片下载"
          icon={
            <AliIcon type="icondipian" style={{ color: "##6c9dc4" }} />
          }
          description={
            <div
              onClick={(e) => {
                // this.props.history.push("/build/photo-graph-plate-download");
                if (typeof this.handleClickAttention(e) !== "boolean") {
                  this.props.history.push("/build/film-download");
                }
              }}
            >
              更多
            </div>
          }
        />
        <Modal
          title={"批量下载"}
          visible={!!this.state.modal_visible_url}
          maskClosable={true}
          width={"80%"}
          onCancel={() => this.setState({ modal_visible_url: "" })}
          closable={true}
        >
          <div className="photographic-plate-download-modal">
            <p>请复制云盘地址通过电脑浏览器打开</p>
            <p>可进行批量操作</p>
            <p>云盘地址:</p>
            <p id="url">{this.state.modal_visible_url}</p>
            <CopyToClipboard
              text={this.state.modal_visible_url}
              onCopy={() => {
                alert("复制成功");
                this.setState({ modal_visible_url: "" });
              }}
            >
              <Button theme="primary" className="button">
                点击复制
              </Button>
            </CopyToClipboard>
          </div>
        </Modal>
        <div className="_content">
          {this.state.display_whole
            ? this.props.data.slice(0, 2).map(this.renderComponent)
            : this.props.data.slice(0, 2).map(this.renderComponent)}
            {/* {this.props.data.slice(0, 2).map(this.renderComponent)} */}
          {/* 这里需要新增个 */}
        </div>
      </div>
    );
  }

  renderComponent = (value: IPhotographicPlate) => (
    <PhotographicPlateDownloadComponent
      onCardSelected={this.handleSelected.bind(this, value.id!)}
      data={value}
      key={value.id}
      onMultiDownloadClicked={() =>
        this.setState({ modal_visible_url: value.url! })
      }
      onSingleDownloadClicked={() => {
        window.open(value.url);
      }}
    />
  );

  private readonly handleSelected = (id: number) => {
    this.setState({
      current_selected: this.state.current_selected === id ? undefined : id,
    });
  };

  private readonly more = () => {
    this.setState({ display_whole: !this.state.display_whole });
  };
}

export default withRouter(PhotographicPlateDownloadWidget);
