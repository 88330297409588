import React from "react";
import "./close-relationship-number-edit-modal.css";
import { Button, Input, Modal, Toast } from "zarm";
import { ValidCloseRelationship } from "../../../store/close-relationship-number.store";
import { PHONE_RE } from "../../../utils";
import { store } from "../../../store/store.root";
import { FetchRequest } from "../../../utils/fetch";

interface Props {
  display: ValidCloseRelationship | null;
  onCancel: () => void;
}

const StyleTips: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: '#000000',
  opacity: 0.8,
  zIndex: 1001,
  textAlign: 'center',
  color: '#ffffff',
  paddingTop: 300
}

export class CloseRelationshipNumberEditModal extends React.Component<
  Props,
  { currentValue: string; initial: string | null; Isshar: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { currentValue: "", initial: "", Isshar: false };
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.display) {
      // 关闭
      if (!this.props.display) this.setState({ currentValue: "", initial: "" });
    }
    // 初始化
    else if (this.props.display) {
      const initial = store.closeRelationshipNumberStore.getValue(
        this.props.display
      );
      if (initial) this.setState({ currentValue: initial, initial });
    }
  }

  renderIcon = () => {
    switch (this.props.display) {
      case "father":
        return <use xlinkHref="#iconbaba" />;
      case "mother":
        return <use xlinkHref="#iconmama" />;
      case "father_s_father":
      case "mother_s_father":
        return <use xlinkHref="#iconyeye" />;
      case "father_s_mother":
      case "mother_s_mother":
        return <use xlinkHref="#iconnainai" />;
    }
  };

  render() {
    return (
      <>
        <Modal
          visible={!!this.props.display}
          title={"绑定亲情号-" + this.renderName()}
          closable={true}
          animationDuration={0}
          onCancel={this.props.onCancel}
          width={"80%"}
          maskClosable={true}
          className="close-relationship-number-edit-modal"
        >
          <div className="content">
            <svg className="iconfont-svg">{this.renderIcon()}</svg>
            <div className="input-row">
              <Input
                className="input-component"
                onFocus={this.onBlur}
                clearable={false}
                value={this.state.currentValue}
                onChange={this.currentValueChange}
              />
              <Button
                className="button-component"
                theme={
                  this.state.currentValue
                    ? this.state.initial
                      ? this.state.initial === this.state.currentValue
                        ? "danger"
                        : "primary"
                      : "primary"
                    : this.state.initial
                      ? "danger"
                      : "primary"
                }
                disabled={
                  this.state.currentValue
                    ? !this.currentValueIsValid() ||
                    store.closeRelationshipNumberStore.loading
                    : false
                }
                onClick={this.submit}
              >
                {this.state.initial
                  ? this.state.currentValue
                    ? this.state.currentValue === this.state.initial
                      ? "清除绑定"
                      : "重新绑定"
                    : "清除绑定"
                  : this.state.currentValue
                    ? "绑定"
                    : "绑定"}
              </Button>
            </div>
          </div>
        </Modal>
        {this.state.Isshar && (<div style={StyleTips} onClick={() => this.setState({ Isshar: false })}>点击右上角分享按钮，即可分享给亲友</div>)}
      </>
    );
  }

  private readonly renderName = (): string => {
    switch (this.props.display) {
      case "father":
        return "爸爸";
      case "mother":
        return "妈妈";
      case "father_s_father":
        return "爷爷";
      case "mother_s_father":
        return "外公";
      case "father_s_mother":
        return "奶奶";
      case "mother_s_mother":
        return "外婆";
      default:
        return "";
    }
  };

  currentValueIsValid = (): boolean =>
    !!this.state.currentValue.match(PHONE_RE);

  private readonly currentValueChange = (event?: string): void => {
    this.setState({ currentValue: event || "" });
  };

  private readonly submit = () => {
    if (this.state.currentValue) {
      if (this.state.currentValue === this.state.initial) {
        store.closeRelationshipNumberStore
          .clear(this.props.display!)
          .then(() => this.props.onCancel());
      } else {
        // 绑定
        store.closeRelationshipNumberStore
          .update(this.props.display!, this.state.currentValue)
          .then(() => {
            const modal = Modal.confirm({
              title: '分享',
              content: '点击分享即可分享给亲友',
              onCancel: () => {
                console.log('点击cancel');
                this.props.onCancel()
              },
              onOk: () => {
                this.setState({ Isshar: true })
                this.props.onCancel()
              },
            });
          });
      }
    } else {
      store.closeRelationshipNumberStore
        .clear(this.props.display!)
        .then(() => this.props.onCancel());
    }
  };

  private readonly onBlur = () => {
    if (this.state.currentValue === this.state.initial) {
      this.setState({ currentValue: "" });
    }
  };
}
