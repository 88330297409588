import { action, makeAutoObservable } from 'mobx'
import { RouterPage } from '../pages'

export class GlobalUiStore {
  pageLoading: boolean = false
  maskLoading: boolean = false
  currentActive: RouterPage | null = null

  popupState: boolean = false

  changeCurrentPopup(value: boolean) {
    console.log(value, ':ddd')
    this.popupState = value
  }

  changeCurrentActive(value: RouterPage | null) {
    this.currentActive = value
  }

  startMaskLoading() {
    this.maskLoading = true
  }

  hideLoading() {
    this.pageLoading = false
    this.maskLoading = false
  }

  startPageLoading() {
    this.pageLoading = true
  }

  constructor() {
    makeAutoObservable(this)
  }
}
