import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { store } from "../../store/store.root";
import { BangBangTangRequest } from "../../utils/request";
import { getParamValue } from "../../utils/url";
import style from "./index.module.scss";

function TestRecords(props: any) {
  const [type, setType] = useState(getParamValue("type"));

  // 获取失败或者获取内容为空
  const [questions, setQuestions] = useState(false);

  // 得分
  const [score, seScore] = useState(0);

  // 错题列表
  const [mistake, mistakeList] = useState([]);

  useEffect(() => {
    score;
    // 错误考题
    document.title = "错误考题";
    // 获取路由参数 /build/test-center?plan_id=11
    // plan_id -> 查看错误考题 subject_id -> 查看基础培训考试错误考题
    if (location.search.indexOf("plan_id=") > -1) {
      let plan_id = getParamValue("plan_id");
      getWrongQuestionList(plan_id);
    } else if (location.search.indexOf("subject_id=") > -1) {
      let subject_id = getParamValue("subject_id");
      getBasicTrainingWrongQuestionList(subject_id);
    }
  }, []);

  /**
   * 查看错误考题
   */
  function getWrongQuestionList(plan_id: string | null) {
    BangBangTangRequest({
      url: "/wap/exam/getWrongQuestion",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        plan_id,
        store_id: localStorage.getItem("admin_store_id"),
      },
    }).subscribe((result) => {
      console.log(result);
      if (result.code === 0) {
        setQuestions(true);
      } else {
        seScore(result.score);
        mistakeList(result.questionList);
        if (result.questionList === null || result.questionList.length === 0) {
          setQuestions(true);
        }
      }
    });
  }

  /**
   * 查看基础培训考试错误考题
   */
  function getBasicTrainingWrongQuestionList(subject_id: string | null) {
    BangBangTangRequest({
      url: "/wap/exam/getBasicTrainingWrongQuestion",
      method: "POST",
      data: {
        token: localStorage.getItem("token"),
        subject_id,
        store_id: localStorage.getItem("admin_store_id"),
      },
    }).subscribe((result) => {
      if (result.code === 0) {
        alert(result.msg);
      } else {
        seScore(result.score);
        mistakeList(result.questionList);
        if (result.questionList === null || result.questionList.length === 0) {
          setQuestions(true);
        }
      }
    });
  }

  // 返回考试列表
  function handleClickBackToTheExamList() {
    if (type === "plan_id") {
      props.history.push("/build/test-center");
    } else if (type === "subject_id") {
      props.history.push("/build/employee-center");
    }
  }

  // 立即重考
  function handleClickRepetition() {
    if (type === "plan_id") {
      props.history.push(
        `/build/test-examination?plan_id=${getParamValue(
          "plan_id"
        )}&type=plan_id`
      );
    } else if (type === "subject_id") {
      props.history.push(
        `/build/test-examination?subject_id=${getParamValue(
          "subject_id"
        )}&type=subject_id`
      );
    }
  }

  return (
    <div
      style={{
        padding: 10,
      }}
    >
      <div className={style.Warp_top_info}>
        <div className={style.rec_num}>
          <span>{questions ? "-" : score}</span>
          <span className={style.rec_text}>分</span>
        </div>
        <div className={style.rec_btn}>
          <button onClick={handleClickBackToTheExamList}>返回考试列表</button>
          <button onClick={handleClickRepetition}>立即重考</button>
        </div>
      </div>
      {/* 列表 */}
      <div className={style.rec_content}>
        <div>
          {mistake.map(
            (
              v: {
                question: string;
                youranswer: string;
                answer: string;
                answer_analysis: string;
              },
              index: number
            ) => {
              return (
                <div key={index}>
                  <p>第 {index + 1} 题</p>
                  <div
                    dangerouslySetInnerHTML={{ __html: v.question }}
                    className={style.Content_Item}
                  ></div>
                  <div className={style.op_item}>
                    您的答案: <span>{v.youranswer}</span>
                  </div>
                  <div className={style.op_item}>
                    正确答案: <span style={{ color: "red" }}>{v.answer}</span>
                  </div>
                  <div className={style.op_item}>
                    答案解析: <span>{v.answer_analysis}</span>
                  </div>
                </div>
              );
            }
          )}
        </div>
        {mistake.length === 0 && (
          <div className={style.Test_Null}>暂无错误考题~</div>
        )}
      </div>
    </div>
  );
}

export default withRouter(TestRecords);
