import COS from 'cos-js-sdk-v5'
import React, { useState, useRef, useEffect } from 'react'
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PercentCrop,
  PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { store as store_tmp } from './SelectionImages'
import { ActivityIndicator, Button, Loading, Toast } from 'zarm'
import { store } from '../../store/store.root'
import { FetchRequest } from '../../utils/fetch'
import { random_20 } from '../../utils/upload'
import { getParamValue } from '../../utils/url'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvasPreview'
import { Dialog, ImagePreview } from 'react-vant'
import WaterMaker from './water-maker'
////////
////////
///////

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  let width = document.body.clientWidth * 0.6
  if (mediaWidth > mediaHeight) {
    width = document.body.clientWidth * 0.4
  }
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export function ImagesUpdateNew(props: {
  isok: (
    e: string,
    p: {
      aspect?: number
      height?: number
      unit?: string
      width?: number
      x?: number
      y?: number
    }
  ) => void
  fillImagesUrl: string
  // ImagesProportion: number;
  ImagesProportionSize: {
    width: number
    height: number
  }
  originImages: string
}) {
  const [imgSrc, setImgSrc] = useState(props.fillImagesUrl)
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [CompletedCropPercentage, setCompletedCropPercentage] =
    useState<PercentCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(
    props.ImagesProportionSize.height / props.ImagesProportionSize.width
  )
  const [ImagePreviewStatus, setImagePreviewStatus] = useState(false)

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        )
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  return (
    <div>
      <WaterMaker text={localStorage.getItem('watermark_settings') || ''} />
      {Boolean(imgSrc) && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c, d) => {
            setCompletedCrop(c)
            setCompletedCropPercentage(d)
          }}
          aspect={aspect}
          keepSelection
          minWidth={document.body.clientWidth * 0.25} // document.body.clientWidth * 0.3
          // maxWidth={document.body.clientWidth * 0.7}
        >
          <img
            crossOrigin="anonymous"
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              border: 0,
              left: 0,
              // background: red;
              width: '100%',
              height: '100%',
              fontSize: 13,
              color: '#d5d5d5',
              lineHeight: 2,
              // opacity: 0.4,
              overflow: 'hidden',
              padding: 10,
              opacity: parseFloat(
                localStorage.getItem('watermark_pellucidity') || '0.5'
              ),
            }}
          >
            {Array.from(Array(500), (v, k) =>
              localStorage.getItem('watermark_settings')
            )}
          </div>
        </ReactCrop>
      )}
      <div
        style={{
          fontSize: 16,
          color: '#626262',
          textAlign: 'center',
          marginTop: 16,
        }}
      >
        点击屏幕移动裁剪框可选择合适位置
      </div>
      <div>
        {Boolean(completedCrop) && (
          <canvas
            id="canvas_cat"
            ref={previewCanvasRef}
            style={{
              visibility: 'hidden',
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop?.width,
              height: completedCrop?.height,
            }}
          />
        )}
      </div>
      <div
        style={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          width: '65%',
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <Button
          block
          theme="primary"
          disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() => {
            previewCanvasRef.current?.toBlob(
              async (blob: any) => {
                if (CompletedCropPercentage) {
                  if (
                    CompletedCropPercentage?.x < 0 ||
                    CompletedCropPercentage?.y < 0
                  ) {
                    Toast.show('您裁剪的参数超出规定范围,请重新裁剪')
                  } else {
                    props.isok(
                      // await cosUpload(canvas.toDataURL("image/png", 0.5)),
                      await cosUpload(blob),
                      CompletedCropPercentage || {}
                      // completedCrop || {} // px
                    )
                    //// 更改状态
                    for (let i = 0; i < store_tmp.dataLeft.length; i++) {
                      const v = store_tmp.dataLeft[i]
                      if (v.origin_img === props.originImages) {
                        delete v.is_select
                      }
                    }
                    for (let i = 0; i < store_tmp.dataRight.length; i++) {
                      const v = store_tmp.dataRight[i]
                      if (v.origin_img === props.originImages) {
                        delete v.is_select
                      }
                    }
                  }
                } else {
                  Toast.show('未检测到裁剪参数')
                }
              },
              'image/jpeg',
              0.4
            )
          }}
          style={{
            width: '48%',
          }}
        >
          确认裁剪
        </Button>
        <Button
          block
          theme="primary"
          disabled={!completedCrop?.width || !completedCrop?.height}
          style={{
            width: '48%',
          }}
          onClick={() => {
            Dialog.alert({
              title: '图片预览',
              closeable: true,
              width: '100%',
              children: (
                <div style={{ position: 'relative' }}>
                  <img
                    src={props.fillImagesUrl}
                    alt=""
                    style={{ width: '100%' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      border: 0,
                      left: 0,
                      // background: red;
                      width: '100%',
                      height: '100%',
                      fontSize: 13,
                      color: '#d5d5d5',
                      lineHeight: 2,
                      // opacity: 0.4,
                      overflow: 'hidden',
                      opacity: parseFloat(
                        localStorage.getItem('watermark_pellucidity') || '0.5'
                      ),
                    }}
                  >
                    {Array.from(Array(500), (v, k) =>
                      localStorage.getItem('watermark_settings')
                    )}
                  </div>
                </div>
              ),
            })
            // ImagePreview.open({
            //   images: [props.fillImagesUrl],
            //   // 开启懒加载
            //   lazyload: true,
            //   closeable: true,
            //   overlayStyle: {
            //     opacity: 1,
            //     zIndex: 100,
            //   },
            // })
          }}
        >
          预览大图
        </Button>
      </div>
    </div>
  )
}

// 上传到cos
async function cosUpload(ImagesBlob: string): Promise<string> {
  // const base = await rotateBase64Img(base64, 90)
  return new Promise(async (res, rej) => {
    // const ImagesBlob = dataURLtoBlob(base)

    Loading.show({
      content: <ActivityIndicator size="lg" />,
    })

    const configOOS = await FetchRequest('/wap/graduation/getCosConfig', {
      token: localStorage.getItem('token') || store.userStore.token,
    })

    const cos = new COS({
      getAuthorization: async function (options, callback) {
        // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
        // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
        // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
        const data = await FetchRequest('/addons/cos/index/getSTS', {
          token: localStorage.getItem('token') || store.userStore.token,
        })
        callback({
          TmpSecretId: data.data.credentials.tmpSecretId,
          TmpSecretKey: data.data.credentials.tmpSecretKey,
          SecurityToken: data.data.credentials.sessionToken,
          // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
          StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: data.data.expiredTime, // 时间戳，单位秒，如：1580000900
        })
      },
    })

    const uploadFileName = random_20()
    const suffix = 'png'

    const objectName = `/biyej/${getParamValue(
      'eroll_id'
    )}/${uploadFileName}.${suffix}`

    cos.putObject(
      {
        Bucket: configOOS.data.bucket,
        Region: configOOS.data.region,
        Key: objectName,
        // Headers: {
        //   'Pic-Operations': '{"is_pic_info": 1, "rules": [{"fileid": "' + objectName + '", "rule": "imageMogr2/rotate/90"}]}',
        // },
        // StorageClass: "STANDARD",
        Body: ImagesBlob, // 上传文件对象
        onProgress: function (progressData) {
          console.log(JSON.stringify(progressData))
        },
      },
      async function (err, data_push) {
        if (err) {
          Toast.show('图片上传失败')
          rej(`file: 失败: ${err}`)
          Loading.hide()
          return
        }
        if (data_push.statusCode === 200) {
          //// 存入
          store_tmp.tmpImages.push({ Key: objectName.substring(1) })
          cos.request(
            {
              Bucket: configOOS.data.bucket,
              Region: configOOS.data.region,
              Key: objectName,
              Method: 'POST',
              Action: 'image_process',
              Headers: {
                'Pic-Operations':
                  '{"is_pic_info": 1, "rules": [{"fileid": "' +
                  uploadFileName +
                  '.png", "rule": "imageMogr2/rotate/90"}]}',
                'Cache-Controll': 'no-cache',
              },
            },
            function (err, data) {
              if (err) {
                Toast.show('图片处理失败')
                rej('file: 失败')
                Loading.hide()
                return
              }
              if (data.statusCode === 200) {
                res('https://' + data_push.Location)
              }
              Loading.hide()
            }
          )
        }
        if (err) {
          rej('file: 失败')
          Loading.hide()
        }
      }
    )
    return objectName
  })
}
