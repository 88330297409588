import React from 'react'
import { observer } from 'mobx-react'
import { Button, Cell, Collapse, Modal } from 'zarm'
import { store } from '../../store/store.root'
import { preorderScheduleActions } from '../../store/preorder-schedule/preorder-schedule.action'
import classNames from 'classnames'
import { ScheduleAlreadyPreorderInterface } from '../../store/preorder-schedule/preorder-schedule'

@observer
export class ScheduleAlreadyPreorder extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
  }
  render() {
    const renderedData: ScheduleAlreadyPreorderInterface[] = store
      .preorderScheduleStore.display_more_already_preorder_card
      ? store.preorderScheduleStore.schedule_already_preorder.choices
      : store.preorderScheduleStore.schedule_already_preorder.choices &&
        store.preorderScheduleStore.schedule_already_preorder.choices.length > 0
      ? ([
          store.preorderScheduleStore.schedule_already_preorder.choices[0],
        ] as ScheduleAlreadyPreorderInterface[])
      : ([] as any)

    return (
      <>
        <div className="my-already-preorder">
          {renderedData?.map((value) => {
            return (
              <div
                className="already-preorder-card"
                title={
                  value.type_name +
                  ' ' +
                  value.schedule_date +
                  ' ' +
                  value.schedule_time
                }
                // key={value.id}
                key={Math.random()}
              >
                <div className="content">
                  <div
                    className="left"
                    onClick={() =>
                      store.routerStore.push(
                        '/build/my-preorder-detail/' + value.id
                      )
                    }
                  >
                    <p>预约门店: {value.store_name}</p>
                    <p>档期类型: {value.type_name}</p>
                    <p>
                      档期日期:{' '}
                      <span style={{ color: 'red' }}>
                        {value.schedule_date}
                      </span>
                    </p>
                    <p>档期时间: {value.schedule_time}</p>
                  </div>
                  {/* <div className="button">
                    <Button
                      onClick={() => {
                        preorderScheduleActions.changeScheduleAlreadyPreorderDisplayId(
                          value.id
                        );
                        setTimeout(
                          () =>
                            preorderScheduleActions.changeScheduleAlreadyPreorderCancelModalDisplay(
                              true
                            ),
                          0
                        );
                      }}
                    >
                      取消档期
                    </Button>
                  </div> */}
                </div>
                <div className="more">
                  <div
                    className={classNames({
                      active: value.status === '备档',
                    })}
                  >
                    排队备档
                  </div>
                  <div
                    className={classNames({
                      active: value.status === '未审核',
                    })}
                  >
                    预约待审核
                  </div>
                  <div
                    className={classNames({
                      active:
                        value.status === '审核通过' ||
                        value.status === '全未拍' ||
                        value.status === '未拍完' ||
                        value.status === '已拍完' ||
                        value.status === '已审核',
                    })}
                  >
                    预约成功
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {store.preorderScheduleStore.schedule_already_preorder.choices &&
        store.preorderScheduleStore.schedule_already_preorder.choices.length >
          1 ? (
          !store.preorderScheduleStore.display_more_already_preorder_card ? (
            <div
              className="more-button"
              onClick={() =>
                preorderScheduleActions.toggleDisplayMoreAlreadyPreorderCard(
                  true
                )
              }
            >
              <span className="iconfont">&#xe65e;</span>
            </div>
          ) : (
            <div
              className="more-button"
              onClick={() =>
                preorderScheduleActions.toggleDisplayMoreAlreadyPreorderCard(
                  false
                )
              }
            >
              <span className="iconfont">&#xe654;</span>
            </div>
          )
        ) : null}
        {store.preorderScheduleStore.schedule_already_preorder.choices &&
        store.preorderScheduleStore.schedule_already_preorder.choices.length >
          0 ? (
          <div className="blank-divider" />
        ) : null}
        {/*<Modal*/}
        {/*  width={"95%"}*/}
        {/*  closable={true}*/}
        {/*  onCancel={() =>*/}
        {/*    preorderScheduleActions.changeScheduleAlreadyPreorderDisplayId(null)*/}
        {/*  }*/}
        {/*  visible={*/}
        {/*    !!store.preorderScheduleStore.schedule_already_preorder.value &&*/}
        {/*    !store.preorderScheduleStore.schedule_already_preorder*/}
        {/*      .cancel_modal_display*/}
        {/*  }*/}
        {/*  title={"我的预约详情"}*/}
        {/*  footer={*/}
        {/*    <Button*/}
        {/*      theme={"danger"}*/}
        {/*      block*/}
        {/*      onClick={() => {*/}
        {/*        preorderScheduleActions.changeScheduleAlreadyPreorderCancelModalDisplay(*/}
        {/*          true*/}
        {/*        );*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      取消档期*/}
        {/*    </Button>*/}
        {/*  }*/}
        {/*>*/}
        {/*  <Cell*/}
        {/*    title={"预约门店"}*/}
        {/*    description={*/}
        {/*      store.preorderScheduleStore*/}
        {/*        .scheduleAlreadyPreorderCurrentDisplayInModal?.store_name*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <Cell*/}
        {/*    title={"预约档期类型"}*/}
        {/*    description={*/}
        {/*      store.preorderScheduleStore*/}
        {/*        .scheduleAlreadyPreorderCurrentDisplayInModal?.type_name*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <Cell*/}
        {/*    title={"预约日期"}*/}
        {/*    description={*/}
        {/*      store.preorderScheduleStore*/}
        {/*        .scheduleAlreadyPreorderCurrentDisplayInModal?.schedule_date*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <Cell*/}
        {/*    title={"预约时间"}*/}
        {/*    description={*/}
        {/*      store.preorderScheduleStore*/}
        {/*        .scheduleAlreadyPreorderCurrentDisplayInModal?.schedule_time*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Modal>*/}
        <Modal
          visible={
            store.preorderScheduleStore.schedule_already_preorder
              .cancel_modal_display
          }
          title={'警告'}
        >
          取消档期后，不可恢复，但不影响您继续选择档期,是否确认取消?
          <Button
            theme={'primary'}
            block
            onClick={() => {
              preorderScheduleActions.changeScheduleAlreadyPreorderCancelModalDisplay(
                false
              )
            }}
            style={{ marginTop: '35px' }}
          >
            我点错了
          </Button>
          <Button
            theme={'danger'}
            block
            style={{ marginTop: '15px' }}
            onClick={() => {
              preorderScheduleActions.cancelPreorder(
                store.preorderScheduleStore
                  .scheduleAlreadyPreorderCurrentDisplayInModal!.id
              )
            }}
          >
            确认取消
          </Button>
        </Modal>
      </>
    )
  }
}
